import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import SmallLoaderComponent from '../helper/SmallLoaderComponent';
import { Formik, Field } from 'formik';

const MilestoneCardComponent = (props) => {

    const [isLoader, setIsLoader] = useState(false);
    const [actions, setActions] = useState(null);

    useEffect(() => {
        setIsLoader(props.isLoader)
    }, [props.isLoader])
    useEffect(() => {
        if (props.show) {
            actions.resetForm({
                values: {
                    cardNumber: '',
                    expiryDate: '',
                    cvc: '',
                    holderName: '',
                    milestoneAmount: '',
                    milestoneDescription:''
                }
            })
            setActions(null);
        }
    }, [props.show])


    const {
        meta,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
        wrapperProps
    } = usePaymentInputs();



    const paymentSubmit = async (values, actions) => {
        const expiryDate = values.expiryDate.split('/')
        // const date = new Date();
        const year = new Date().getFullYear().toString();
        const year2_digit = year.slice(0, -2);
        const card_number = values.cardNumber.toString().trim();
        // console.log(card_number)
        const exp_year = `${year2_digit}${expiryDate[1].trim()}`


        const formData = {
            number: card_number,
            cvc: parseInt(values.cvc),
            exp_month: parseInt(expiryDate[0].trim()),
            exp_year: parseInt(exp_year),
            name: values.holderName,
            milestoneAmount: values.milestoneAmount,
            milestoneDescription:values.milestoneDescription
        }
        props.onSubmit(formData);
        setActions(actions);

    }

    return (
        <div className="cards">
            {/* <span>Credit Or Debit Card</span> */}
            <Formik
                initialValues={{
                    cardNumber: '',
                    expiryDate: '',
                    cvc: '',
                    holderName: '',
                    milestoneAmount: '',
                    milestoneDescription:''
                }}
                onSubmit={(values, actions) => paymentSubmit(values, actions)}
                validate={(values) => {
                    // console.log(values)
                    let errors = {};
                    if (meta.erroredInputs.cardNumber) {
                        errors.cardNumber = meta.erroredInputs.cardNumber;
                    }
                    if (meta.erroredInputs.expiryDate) {
                        errors.expiryDate = meta.erroredInputs.expiryDate;
                    }
                    if (meta.erroredInputs.cvc) {
                        errors.cvc = meta.erroredInputs.cvc;
                    }
                    if (values.holderName === '') {
                        errors.holderName = 'Card holder name must be required!'
                    }
                    if (values.milestoneAmount === '') {
                        errors.milestoneAmount = 'Milestone amount must be required!'
                    }
                    if (values.milestoneAmount === 0) {
                        errors.milestoneAmount = 'Milestone amount not valid'
                    }
                    return errors;
                }}
            >
                {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (

                    <form action="/" method="POST" onSubmit={handleSubmit}>
                        <div className="row mt-3">

                            <>
                                <div className="col-sm-12">
                                    <div className="form-group mb-4">
                                        <label className="form-label required">Card Number</label>
                                        <div className="number-with-image">
                                            <svg className="icon" {...getCardImageProps({ images })} />
                                            <Field name="cardNumber">
                                                {({ field, form, meta }) => (
                                                    <>
                                                        <input
                                                            className={`form-control ps-5 ${errors.cardNumber && touched.cardNumber ? 'error' : ''}`}
                                                            {...getCardNumberProps({ onBlur: field.onBlur, onChange: field.onChange })}
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </div>
                                        {errors.cardNumber && touched.cardNumber && <p className="error-text">{errors.cardNumber}</p>}
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="form-label required">Expiry Date</label>
                                        <Field name="expiryDate">
                                            {({ field, form, meta }) => (
                                                <>
                                                    <input
                                                        className={`form-control ${errors.expiryDate && touched.expiryDate ? 'error' : ''}`}
                                                        {...getExpiryDateProps({ onBlur: field.onBlur, onChange: field.onChange })} />
                                                </>
                                            )}
                                        </Field>
                                        {errors.expiryDate && touched.expiryDate && <p className="error-text">{errors.expiryDate}</p>}
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="form-label required">CVV</label>
                                        <Field name="cvc">
                                            {({ field, form, meta }) => (
                                                <>
                                                    <input
                                                        className={`form-control ${errors.cvc && touched.cvc ? 'error' : ''}`}
                                                        {...getCVCProps({ onBlur: field.onBlur, onChange: field.onChange })} />
                                                </>
                                            )}
                                        </Field>
                                        {errors.cvc && touched.cvc && <p className="error-text">{errors.cvc}</p>}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group mt-4">
                                        <label className="form-label required">Card Holder Name</label>
                                        <Field name="holderName">
                                            {({ field, form, meta }) => (
                                                <>
                                                    <input
                                                        name='holderName'
                                                        className={`form-control ${errors.holderName && touched.holderName ? 'error' : ''}`}
                                                        placeholder="Card Holder Name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    // value={holderName} 
                                                    />
                                                </>
                                            )}
                                        </Field>
                                        {errors.holderName && touched.holderName && <p className="error-text">{errors.holderName}</p>}
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="form-group mt-4">
                                        <label className="form-label required">Milestone Amount</label>
                                        <Field name="holderName">
                                            {({ field, form, meta }) => (
                                                <>
                                                    <input
                                                        name='milestoneAmount'
                                                        className={`form-control ${errors.milestoneAmount && touched.milestoneAmount ? 'error' : ''}`}
                                                        placeholder="Milestone amount"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    // value={holderName} 
                                                    />
                                                </>
                                            )}
                                        </Field>
                                        {errors.milestoneAmount && touched.milestoneAmount && <p className="error-text">{errors.milestoneAmount}</p>}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group mt-4">
                                        <label className="form-label">Milestone Amount</label>
                                        <Field name="holderName">
                                            {({ field, form, meta }) => (
                                                <>
                                                    <textarea
                                                        name='milestoneDescription'
                                                        className={`form-control ${errors.milestoneDescription && touched.milestoneDescription ? 'error' : ''}`}
                                                        placeholder="Milestone description"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        rows={5}
                                                    // value={holderName} 
                                                    ></textarea>
                                                </>
                                            )}
                                        </Field>
                                        {errors.milestoneDescription && touched.milestoneDescription && <p className="error-text">{errors.milestoneDescription}</p>}
                                    </div>
                                </div>

                            </>
                            <div className="col-sm-12 mt-5">
                                <button type="submit" className="btn btn-success" style={{ minWidth: '80px' }}>{isLoader ? <SmallLoaderComponent color="white" /> : `Submit`}</button>
                                <button type="button" className="btn bg-transparent border-0" onClick={props.onCancel}>Cancel</button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>

        </div>
    )
}


export default withRouter(connect(null)(MilestoneCardComponent));
