import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IoChatbubbles } from 'react-icons/io5';
import { MdDelete, MdInsertDriveFile, MdVerticalAlignBottom } from 'react-icons/md';
// IoCheckmarkDoneOutline
import moment from 'moment';
// import InfiniteScroll from 'react-infinite-scroll-component';
import MainLoaderComponent from '../../helper/MainLoaderComponent';

const MessageBox = (props) => {

    const [typingID, setTypingID] = useState(props.typingID);
    const [profilePath, setProfilePath] = useState(props.profilePath);
    const [messages, setMessages] = useState(props.messages);
    const [isTyping, setIsTyping] = useState(props.isTyping);
    const [user, setUser] = useState(props.user);
    const [isLoader, setIsLoader] = useState(props.isMessageLoader);

    useEffect(() => {
        setTypingID(props.typingID)
        setMessages(props.messages)
        setIsTyping(props.isTyping)
        setUser(props.user)
        setIsLoader(props.isMessageLoader);

        const boxMessage = document.getElementById('messageWrap');
        boxMessage.scrollTop = boxMessage.scrollHeight

    }, [isLoader, props.messages, props])

    const downloadImage = (imageUrl) => {
        const fileName = imageUrl.split('/').pop();
        fetch(imageUrl).then(function (t) {
            t.blob().then(b => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(b);
                link.download = fileName;
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
        })

    }

    const renderFiles = (files, info, index) => {
        // console.log(files)
        const fileExt = files ? files.file ? files.file.split('.').pop() : "" : '';
        let icon = '';
        if (fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png') {
            icon = <MdInsertDriveFile />
        } else {
            icon = <MdInsertDriveFile />
        }

        return <div className={info.sender_id !== props.user.id ? "files-block" : "files-block sender"} key={index}>
            <div className="fileandName">
                <div className="image_wra">
                    <span className="icon">{icon}</span>
                </div>
                <div className="text-wrap">
                    <p>{files.file}</p>

                </div>
            </div>
            {info.sender_id !== props.user.id ?
                <div className="files-btns">
                    <button type="button" onClick={() => downloadImage(`${props.filePath}${files.file}`)} ><MdVerticalAlignBottom /> Download</button>
                </div>
                :
                <div className="files-btns">
                    <button type="button" onClick={() => props.deleteFile(info, files)}><MdDelete /> Delete</button>
                </div>
            }
        </div>
    }
    // console.log(messages)
    return (
        <div className={'msg_history'} id="messageWrap" onScroll={props.onFetch}>
            {/* <InfiniteScroll
                dataLength={messages.length}
                next={props.onFetch}
                hasMore={props.hasMore}
                loader={<h4 className="loader-message">Loading...</h4>}
                style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                inverse={true}
                scrollableTarget="messageWrap"
            > */}
            {props.hasMore ? <h4 className="loader-message">Loading...</h4> : null}
            {isLoader ?
                <div className="no-chat-wrap inside">
                    <div className="text-chatmessage">
                        <MainLoaderComponent color="black" />
                    </div>
                </div>
                :
                (Array.isArray(messages) && messages.length > 0) && user ?
                    messages.map((info, i) =>
                        user.id !== info.sender_id ?
                            info.message !== "" || (Array.isArray(info.fileArray) && info.fileArray.length > 0) ?
                                <div className={'incoming_msg'} key={i}>
                                    <div className={'incoming_msg_img'}>
                                        {info.profilePic && profilePath ?
                                            <div className="name_or_img">
                                                <img className={'img'} src={`${profilePath}${info.profilePic}`} alt="user" />
                                            </div>
                                            :
                                            <div className="name_or_img">
                                                <div className="shape">{info.name ? info.name.charAt(0).toUpperCase() : ''}</div>
                                            </div>
                                        }
                                    </div>
                                    <div className={'received_msg'}>
                                        <div className={'received_withd_msg'}>
                                            {info.is_deleted === 1 ?
                                                <p>This message is deleted</p>
                                                :
                                                <>
                                                    {Array.isArray(info.fileArray) && info.fileArray.length > 0 ?
                                                        info.fileArray.map((file, index) => renderFiles(file, info, index))
                                                        :
                                                        null
                                                    }
                                                    {info.message !== "" ?
                                                        <p>{info.message}</p>
                                                        :
                                                        null
                                                    }
                                                    <span className={'time_date'}>{moment(info.created_at).format('HH:MM A | MMM DD')}</span>
                                                    {/* <span className={'time_date'}> 11:01 AM    |    June 9</span> */}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            :
                            info.message !== "" || (Array.isArray(info.fileArray) && info.fileArray.length > 0) ?
                                <div className={'our_mesag'} key={i}>
                                    <div className={'outgoing_msg'}>
                                        <div className={'div_limit'}>
                                            <div className={'sent_msg'}>
                                                {info.is_deleted === 0 ? <button type="button" className="btn-delete-message" onClick={() => props.deleteMessage(info)}><MdDelete /><span>Delete message</span></button> : null}
                                                {info.is_deleted === 1 ?
                                                    <p>This message is deleted</p>
                                                    :
                                                    <>
                                                        {Array.isArray(info.fileArray) && info.fileArray.length > 0 ?
                                                            info.fileArray.map((file, index) => renderFiles(file, info, index))
                                                            :
                                                            null
                                                        }
                                                        {info.message !== "" ?
                                                            <p> {info.message}</p>
                                                            :
                                                            null
                                                        }
                                                    </>
                                                }
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end">
                                                <span className={'time_date'}>{moment(info.created_at).format('HH:MM A | MMM DD')}</span>
                                                {/* <span className={parseInt(info.message_seen_status) > 0 ? "message-seen seen" : "message-seen"}><IoCheckmarkDoneOutline /></span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                    )
                    :
                    <div className="container">
                        <div className="no-chat-wrap inside">
                            <div className="text-chatmessage">
                                <span className="icon"><IoChatbubbles /></span>
                                <p>No Chat available</p>
                            </div>
                        </div>
                    </div>
            }
            {
                typingID > 0 && user.id !== typingID ?
                    isTyping ?

                        <div className="chat-bubble">
                            <div className="typing">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                        </div>
                        :
                        null
                    :
                    null
            }
            {/* <div className={'incoming_msg'}>
                <div className={'incoming_msg_img'}> <img className={'img'} src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                <div className={'received_msg'}>
                    <div className={'received_withd_msg'}>
                        <p>Test which is a new approach to have all
                            solutions</p>
                        <span className={'time_date'}> 11:01 AM    |    Yesterday</span></div>
                </div>
            </div>
            <div className={'our_mesag'}>
                <div className={'outgoing_msg'}>
                    <div className={'div_limit'}>
                        <div className={'sent_msg'}>
                            <p>Test which is a new approach to have all
                                solutions</p>
                            <span className={'time_date'}> 11:01 AM    |    Today</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'incoming_msg'}>
                <div className={'incoming_msg_img'}> <img className={'img'} src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                <div className={'received_msg'}>
                    <div className={'received_withd_msg'}>
                        <p>We work directly with our designers and suppliers,
                            and sell direct to you, which means quality, exclusive
                            products, at a price anyone can afford.</p>
                        <span className={'time_date'}> 11:01 AM    |    Today</span></div>
                </div>
            </div> */}
            {/* </InfiniteScroll> */}
        </div >
    )
}

// class MessageBox extends Component {

//     _unmounted = false;

//     constructor(props) {
//         super(props);
//         this.state = {
//             messageList: [],
//             isMe: false,
//         }
//     }

//     componentDidMount() {
//         this._unmounted = true;
//         const boxMessage = document.getElementById('messageWrap');
//         boxMessage.scrollTop = boxMessage.scrollHeight
//     }

//     // componentDidUpdate(prevProps) {
//     //     const { messages } = this.props;
//     //     if (messages.length !== prevProps.messages.length) {
//     //         const boxMessage = document.getElementById('messageWrap');
//     //         boxMessage.scrollTop = boxMessage.scrollHeight
//     //     }
//     // }

//     componentWillUnmount() {
//         this._unmounted = false
//     }

//     downloadImage = (imageUrl) => {
//         const fileName = imageUrl.split('/').pop();
//         fetch(imageUrl).then(function (t) {
//             t.blob().then(b => {
//                 const link = document.createElement('a')
//                 link.href = URL.createObjectURL(b);
//                 link.download = fileName;
//                 document.body.appendChild(link)
//                 link.click()
//                 document.body.removeChild(link)
//             })
//         })

//     }

//     renderFiles = (files, info, index) => {
//         // console.log(files)
//         const fileExt = files.file.split('.').pop();
//         let icon = '';
//         if (fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png') {
//             icon = <MdInsertDriveFile />
//         } else {
//             icon = <MdInsertDriveFile />
//         }

//         return <div className={info.sender_id !== this.props.user.id ? "files-block" : "files-block sender"} key={index}>
//             <div className="fileandName">
//                 <div className="image_wra">
//                     <span className="icon">{icon}</span>
//                 </div>
//                 <div className="text-wrap">
//                     <p>{files.file}</p>

//                 </div>
//             </div>
//             {info.sender_id !== this.props.user.id ?
//                 <div className="files-btns">
//                     <button type="button" onClick={() => this.downloadImage(`${this.props.filePath}${files.file}`)} ><MdVerticalAlignBottom /> Download</button>
//                 </div>
//                 :
//                 <div className="files-btns">
//                     <button type="button" onClick={() => this.props.deleteFile(info, files)}><MdDelete /> Delete</button>
//                 </div>
//             }
//         </div>
//     }

//     render() {

//         const { messages, user, isTyping, typingID } = this.props;
//         // console.log(messages)

//         return (
//             <div className={'msg_history'} id="messageWrap">
//                 {(Array.isArray(messages) && messages.length > 0) && user ?
//                     messages.map((info, i) =>
//                         user.id !== info.sender_id ?
//                             info.message !== "" || (Array.isArray(info.fileArray) && info.fileArray.length > 0) ?
//                                 <div className={'incoming_msg'} key={i}>
//                                     <div className={'incoming_msg_img'}>
//                                         <div className="name_or_img">
//                                             {info.name ? info.name.charAt(0).toUpperCase() : ''}
//                                         </div>
//                                         {/* <img className={'img'} src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> */}
//                                     </div>
//                                     <div className={'received_msg'}>
//                                         <div className={'received_withd_msg'}>
//                                             {info.is_deleted === 1 ?
//                                                 <p>This message is deleted</p>
//                                                 :
//                                                 <>
//                                                     {Array.isArray(info.fileArray) && info.fileArray.length > 0 ?
//                                                         info.fileArray.map((file, index) => this.renderFiles(file, info, index))
//                                                         :
//                                                         null
//                                                     }
//                                                     {info.message !== "" ?
//                                                         <p>{info.message}</p>
//                                                         :
//                                                         null
//                                                     }
//                                                     <span className={'time_date'}>{moment(info.created_at).format('HH:MM A | MMM DD')}</span>
//                                                     {/* <span className={'time_date'}> 11:01 AM    |    June 9</span> */}
//                                                 </>
//                                             }
//                                         </div>
//                                     </div>
//                                 </div>
//                                 :
//                                 null
//                             :
//                             info.message !== "" || (Array.isArray(info.fileArray) && info.fileArray.length > 0) ?
//                                 <div className={'our_mesag'} key={i}>
//                                     <div className={'outgoing_msg'}>
//                                         <div className={'div_limit'}>
//                                             <div className={'sent_msg'}>
//                                                 <button type="button" className="btn-delete-message"><MdDelete /><span>Delete message</span></button>
//                                                 {info.is_deleted === 1 ?
//                                                     <p>This message is deleted</p>
//                                                     :
//                                                     <>
//                                                         {Array.isArray(info.fileArray) && info.fileArray.length > 0 ?
//                                                             info.fileArray.map((file, index) => this.renderFiles(file, info, index))
//                                                             :
//                                                             null
//                                                         }
//                                                         {info.message !== "" ?
//                                                             <p> {info.message}</p>
//                                                             :
//                                                             null
//                                                         }
//                                                     </>
//                                                 }
//                                             </div>
//                                             <div className="d-flex align-items-center justify-content-end">
//                                                 <span className={'time_date'}>{moment(info.created_at).format('HH:MM A | MMM DD')}</span>
//                                                 {/* <span className={parseInt(info.message_seen) > 0 ? "message-seen seen" : "message-seen"}><IoCheckmarkDoneOutline /></span> */}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 :
//                                 null
//                     )
//                     :
//                     <div className="container">
//                         <div className="no-chat-wrap inside">
//                             <div className="text-chatmessage">
//                                 <span className="icon"><IoChatbubbles /></span>
//                                 <p>No Chat available</p>
//                             </div>
//                         </div>
//                     </div>
//                 }
//                 {
//                     user.id !== typingID ?
//                         isTyping ?
//                             <div className="chat-bubble">
//                                 <div className="typing">
//                                     <div className="dot"></div>
//                                     <div className="dot"></div>
//                                     <div className="dot"></div>
//                                 </div>
//                             </div>
//                             :
//                             null
//                         :
//                         null
//                 }
//                 {/* <div className={'incoming_msg'}>
//                     <div className={'incoming_msg_img'}> <img className={'img'} src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
//                     <div className={'received_msg'}>
//                         <div className={'received_withd_msg'}>
//                             <p>Test which is a new approach to have all
//                                 solutions</p>
//                             <span className={'time_date'}> 11:01 AM    |    Yesterday</span></div>
//                     </div>
//                 </div>
//                 <div className={'our_mesag'}>
//                     <div className={'outgoing_msg'}>
//                         <div className={'div_limit'}>
//                             <div className={'sent_msg'}>
//                                 <p>Test which is a new approach to have all
//                                     solutions</p>
//                                 <span className={'time_date'}> 11:01 AM    |    Today</span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div className={'incoming_msg'}>
//                     <div className={'incoming_msg_img'}> <img className={'img'} src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
//                     <div className={'received_msg'}>
//                         <div className={'received_withd_msg'}>
//                             <p>We work directly with our designers and suppliers,
//                                 and sell direct to you, which means quality, exclusive
//                                 products, at a price anyone can afford.</p>
//                             <span className={'time_date'}> 11:01 AM    |    Today</span></div>
//                     </div>
//                 </div> */}
//             </div >
//         )
//     }
// }

export default withRouter(connect(null)(MessageBox))
