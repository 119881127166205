import { apiHelper } from "../helper/api.helper"
import { jwtDecode } from "../helper/jwt.helper"

// client module
const addPayment = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { jobPostID, freelancerProfileID, ContractAmount, MilestonesAmount, number, cvc, exp_month, exp_year, name } = data;
        let formData = new FormData();
        formData.append('jobPostID', jobPostID)
        formData.append('freelancerProfileID', freelancerProfileID)
        formData.append('ContractAmount', ContractAmount)
        formData.append('MilestonesAmount', MilestonesAmount)
        formData.append('number', number)
        formData.append('cvc', cvc)
        formData.append('exp_month', exp_month)
        formData.append('exp_year', exp_year)
        formData.append('name', name)

        const response = await apiHelper('contract/create', 'POST', formData, header);
        return response.data;
    } catch (err) {
        return { status: false, message: err.message }
    }

}

// client milestone create
const createMilestone = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { contractID, amount, milestoneDescription, number, cvc, exp_month, exp_year, name } = data;
        let formData = new FormData();
        formData.append('contractID', contractID)
        formData.append('amount', amount)
        formData.append('number', number)
        formData.append('cvc', cvc)
        formData.append('exp_month', exp_month)
        formData.append('exp_year', exp_year)
        formData.append('name', name)
        formData.append('milestoneDescription', milestoneDescription)

        const response = await apiHelper('contract/milestones/create', 'POST', formData, header);
        return response.data;
    } catch (err) {
        return { status: false, message: err.message }
    }

}

// get client transaction list
const getClientTransaction = async () => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper('client/transtion/get', 'GET', null, header);
        const res = response.data;

        if (!res.status) {
            return { status: false, message: res.message }
        }

        const data = jwtDecode(res.data);

        // return { status: true, data: data.data, message: res.message }
        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// get freelancer transaction list
const getFreelancerTransaction = async () => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper('freelancer/transtion/get', 'GET', null, header);
        const res = response.data;
        // console.log(res)
        if (!res.status) {
            return { status: false, message: res.message }
        }

        const data = jwtDecode(res.data);

        // return { status: true, data: data.data, message: res.message }
        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// client refund request to freelancer
const refundApi = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { jobPostID, reason, milestoneID } = data;

        let formData = new FormData();
        formData.append('jobPostID', jobPostID)
        formData.append('reason', reason)
        formData.append('milestoneID', milestoneID)

        const response = await apiHelper('client/milestone/refund', 'POST', formData, header);
        return response.data;
    } catch (err) {
        return { status: false, message: err.message }
    }

}

// withdraw amount request to admin
const sendWithdrawRequest = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { requestedAmount, accountId } = data;

        let formData = new FormData();
        formData.append('requestedAmount', requestedAmount)
        formData.append('accountId', accountId)

        const response = await apiHelper('freelancer/getpaid/request', 'POST', formData, header);
        return response.data;
    } catch (err) {
        return { status: false, message: err.message }
    }

}

// Get Payment account list
const getPaymentAccountList = async () => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper('freelancer/paymentAccount/list', 'GET', null, header);
        const res = response.data;
        if (!res.status) return { status: false, message: res.message }

        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// add Payment account
const addPaymentAccount = async (email) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        let formData = new FormData();
        formData.append('paymentAccount', email)

        const response = await apiHelper('freelancer/paymentAccount/add', 'POST', formData, header);
        const res = response.data;
        if (!res.status) return { status: false, message: res.message }

        return { status: true, message: res.message }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// add Payment account
const deletePaymentAccount = async (id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`freelancer/paymentAccount/delete/${id}`, 'DELETE', null, header);
        const res = response.data;
        if (!res.status) return { status: false, message: res.message }

        return { status: true, message: res.message }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// Get Payment account list
const clientMilestoneReleased = async ({ contractID, milestoneID }) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        let formData = new FormData();
        formData.append('milestoneID', milestoneID)
        formData.append('contractID', contractID)

        const response = await apiHelper('contract/milestones/release', 'POST', formData, header);
        const res = response.data;
        if (!res.status) return { status: false, message: res.message }

        return { status: true, message: res.message }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// milestone request freelancer to client
const freelancerMilestoneRequest = async (milestoneID, contractID) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        let formData = new FormData();
        formData.append('milestoneID', milestoneID)
        formData.append('contractID', contractID)

        const response = await apiHelper('freelancer/milestone/request', 'POST', formData, header);
        const res = response.data;
        if (!res.status) return { status: false, message: res.message }

        return { status: true, message: res.message }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// Get Balace
const getFreelanceBalance = async () => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper('freelancer/getPaid/getBalance', 'GET', null, header);
        const res = response.data;
        if (!res.status) return { status: false, message: res.message }

        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

const payment = {
    addPayment,
    getClientTransaction,
    getFreelancerTransaction,
    sendWithdrawRequest,
    getPaymentAccountList,
    addPaymentAccount,
    deletePaymentAccount,
    clientMilestoneReleased,
    refundApi,
    freelancerMilestoneRequest,
    getFreelanceBalance,
    createMilestone
}

export default payment