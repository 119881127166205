export const topSkills = [
    { label: 'Data Entry Specialists' },
    { label: 'JavaScript Developer' },
    { label: 'Video Editors' },
    { label: 'Logo Designer' },
    { label: 'Data Analyst' },
    { label: 'Mobile App Developer' },
    { label: 'Shopify Developer' },
    { label: 'PHP Developer' },
    { label: 'Ruby on Rails Developer' },
    { label: 'Python Developer' },
    { label: 'Android Developer' },
    { label: 'Resume Writer' },
    { label: 'Bookkeeper' },
    { label: 'SEO Expert' },
    { label: 'Content Writer' },
    { label: 'Social Media Manager' },
    { label: 'Copywriter' },
    { label: 'Software Developer' },
    { label: 'Database Administrator' },
    { label: 'Software Engineer' },
    { label: 'Data Scientist' },
    { label: 'Technical Writer' },
    { label: 'Front-End Developer' },
    { label: 'UI Designer' },
    { label: 'Game Developer' },
    { label: 'UX Designer' },
    { label: 'Graphic Designer' },
    { label: 'Virtual Assistant' },
    { label: 'iOS Developer' },
    { label: 'Web Designer' },
    { label: 'Java Developer' },
    { label: 'Wordpress Developer' },
]

export const trandingSkills = [
    { label: 'Blockchain' },
    { label: 'Illustrators' },
    { label: 'Go development' },
    { label: 'Google AdWords Experts' },
    { label: 'Node.js' },
    { label: 'Digital Marketers' },
    { label: 'Vue.js' },
    { label: 'Project Managers' },
    { label: 'HR consulting' },
    { label: 'Arduino Programmers' },
    { label: 'Microsoft Power BI' },
    { label: 'Ruby Developers' },
    { label: 'Instructional design' },
    { label: 'AngularJS Devleopers' },
    { label: 'React.js' },
    { label: 'Full Stack Developers' },
    { label: 'Videographers' },
    { label: 'Email Marketing Consultants' },
    { label: 'HTML5 Developers' },
    { label: 'React Native Developers' },
    { label: 'Ghostwriters' },
    { label: 'Swift Developers' },
    { label: 'Unity 3D Developers' },
    { label: 'CSS Developers' },
    { label: 'Business Consultants' },
    { label: 'Google Sketchup Freelancers' },
    { label: 'Coders' },
    { label: 'Back End Developers' },
    { label: 'Marketing Consultants' },
    { label: 'Smartsheet Freelancers' },
    { label: 'Web Developers' },
    { label: 'Zoom Video Conferencing Freelancers' },
]

export const topSkillsUs = [
    { label: 'Graphic Designers in US' },
    { label: 'Atlanta, GA Freelancers' },
    { label: 'Web Designers in US' },
    { label: 'Austin, TX Freelancers' },
    { label: 'WordPress Developers in US' },
    { label: 'Brooklyn, NY Freelancers' },
    { label: 'Writers in US' },
    { label: 'Charlotte, NC Freelancers' },
    { label: 'Curriculum Developer in US' },
    { label: 'Chicago, IL Freelancers' },
    { label: 'Fashion Designers in US' },
    { label: 'Dallas, TX Freelancers' },
    { label: 'WooCommerce Developers in US' },
    { label: 'Denver, CO Freelancers' },
    { label: 'd3.js Developers in US' },
    { label: 'Houston, TX Freelancers' },
    { label: 'SquareSpace Developers in US' },
    { label: 'Las Vegas, NV Freelancers' },
    { label: 'Ghostwriters in US' },
    { label: 'Los Angeles, CA Freelancers' },
    { label: 'Shopify Developers in US' },
    { label: 'Miami, FL Freelancers' },
    { label: 'Technical Support Agents in US' },
    { label: 'New York, NY Freelancers' },
    { label: 'Accountants in US' },
    { label: 'Orlando, FL Freelancers' },
    { label: 'Virtual Assistants in US' },
    { label: 'Philadelphia, PA Freelancers' },
    { label: 'Product Developers in US' },
    { label: 'Phoenix, AZ Freelancers' },
    { label: 'CAD Designers in US' },
    { label: 'Portland, OR Freelancers' },
    { label: 'Tax Preparers in US' },
    { label: 'San Diego, CA Freelancers' },
    { label: 'Ebook Designers in US' },
    { label: 'San Francisco, CA Freelancers' },
    { label: 'Zoho CRM Specialists in US' },
    { label: 'San Jose, CA Freelancers' },
    { label: 'Google Adwords Experts in US' },
    { label: 'Seattle, WA Freelancers' }
]

export const projectCatalog = [
    { label: 'Resume Writing Services' },
    { label: 'Survey Services' },
    { label: 'SEO Services' },
    { label: 'Landscape Design Services' },
    { label: 'Translation Services' },
    { label: 'Photoshop Services' },
    { label: 'Transcription Services' },
    { label: 'Mobile App Development Services' },
    { label: 'Virtual Assistant Services' },
    { label: 'Data Entry Services' },
    { label: 'Email Marketing Services' },
    { label: 'Building Information Modeling Services' },
    { label: 'Web Design Services' },
    { label: 'Podcast Editing Services' },
    { label: 'Proofreading Services' },
    { label: 'Wellness Services' },
    { label: 'Business Consulting Services' },
    { label: 'HR Consulting Services' },
    { label: 'Logo Design Services' },
    { label: 'Video Marketing Services' },
    { label: 'Architecture/Interior Design Services' },
    { label: 'WordPress Development Services' },
    { label: 'Branding Services' },
    { label: 'Ecommerce Services' },
    { label: 'Social Media Management Services' },
    { label: 'Influencer Marketing Services' },
    { label: 'Video Editing Services' },
    { label: 'Public Relations Services' },
    { label: 'Lead Generation Services' },
    { label: 'QA Services' },
    { label: 'Content Marketing Services' },
    { label: 'Podcast Marketing Services' }
]