import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import * as ACTION_TYPES from '../../../redux/actions/type';
import StartImage from '../../../assets/images/start-image.svg'

class GettingStarted extends Component {

    componentDidMount() {
        // this._unmounted = true;
        window.scrollTo(0, 0)
        // this.fillData()
    }

    prevHandler = () => {
        const { activeStep, stepHandler } = this.props;
        stepHandler(activeStep - 1)
    }

    nextHandler = async () => {

        const { activeStep, stepHandler } = this.props;
        stepHandler(activeStep + 1);

    }

    render() {

        const { userName } = this.props;

        return (
            <div className={'tabs_wrap p-0'}>
                <div className={'tab_header pb-0 border-0'}>
                    {/* <h1 className={'tabs_title'}>{`Profile Photo`}</h1> */}
                    <div className="bg-start">
                        <img src={StartImage} alt="Getting Started" />
                    </div>
                </div>
                <div className={'tab_body p-4'}>
                    <h2 className={`body_title fs-6`}>Join Vedain as a freelancer</h2>
                    <div className={`form_group`}>
                        <p>Hi {userName},
                            <br />
                            <br />
                            Thanks for your interest in Vedain! As the world's largest talent platform, we connect millions of businesses with independent professionals like you.
                            <br />
                            <br />
                            To get started, all you need to do is fill out a profile
                        </p>
                    </div>
                </div>
                <div className={'tab_footer p-3 pe-4 ps-4'}>
                    <button type="button" className={'btn btn-dark'} style={{ minWidth: '100px' }} onClick={this.nextHandler}>Continue</button>
                    {this.props.activeStep > 0 ? <button type="button" className="btn btn-outline-dark" style={{ minWidth: '100px' }} onClick={this.prevHandler}>Back</button> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    activeStep: state.pinfo.activeStep,
    userName:state.user.userName
});

const mapDispatchToProps = dispatch => ({
    stepHandler: (val) => dispatch({ type: ACTION_TYPES.STEP_INFO_FREELANCER, payload: val })
    // isCallProfile: (bool) => dispatch({ type: ACTION_TYPES.PROFILE_SETP_API_GET, payload: bool })
    // expertiseSubmit:(obj)=>dispatch({type:ACTION_TYPES.EXPERTISE_MODULE, payload:obj})
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GettingStarted));