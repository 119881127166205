import React, { Component } from 'react';
// import { FaPaperPlane,  } from 'react-icons/fa';
import { MdAttachFile, MdInsertDriveFile } from 'react-icons/md';
import { io } from "socket.io-client";
import { CHAT_URL } from '../../controller/chat.controller';

const SERVER = CHAT_URL;

class MessagePanel extends Component {

    _unmounted = false;

    socket;

    constructor(props) {
        super(props);
        this.state = {
            inputMessage: '',
            files: [],
            fileDisplay: []
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.configureSocket();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps) {
        const { activedId } = this.props;
        if (prevProps.activedId !== activedId) {
            if (this._unmounted) {
                this.setState({
                    inputMessage: '',
                    files: [],
                    fileDisplay: []
                })
            }
        }
    }

    configureSocket = () => {
        var socket = io(SERVER,{ secure: true, rememberUpgrade: true });

        socket.on('connection', () => {
            // console.log('socket is connected!')
        });

        socket.on('errorWhileSendmessage', message => {
            // console.log(message);
        });
        this.socket = socket;
    }

    fileInputHandler = (e) => {
        const files = e.target.files;
        let filesArr = [];
        if (files.length > 0) {
            if (this.props.user) {
                var msg = {
                    from: this.props.user.id,
                    conversationId: this.props.activedId
                };
                // console.log(msg);
                this.socket.emit('typingSendEmit', msg)
            }
        }
        for (let i = 0; i < files.length; i++) {
            filesArr.push(files[i]);
        }
        this.displayFiles(filesArr);
        this.props.onTyping(true);
        if (this._unmounted) {
            this.setState({
                files: filesArr
            })
        }
    }

    displayFiles = (files) => {
        let fileArray = [];
        for (let f = 0; f < files.length; f++) {
            const fileType = files[f].name.split('.')[1];
            const url = URL.createObjectURL(files[f]);
            fileArray.push({ fileUrl: url, fileName: files[f].name, fileType: fileType })
        }

        if (this._unmounted) {
            this.setState({
                fileDisplay: fileArray
            })
        }
    }

    deletefile = (file) => {
        let files = this.state.files;
        for (let i = 0; i < files.length; i++) {
            if (file.fileName === files[i].name) {
                files.splice(i, 1)
            }
        }

        this.displayFiles(files);
        if (this._unmounted) {
            this.setState({
                files: files
            })
        }
    }

    messageHandler = (e) => {
        const { value } = e.target;
        if (this._unmounted) {
            this.setState({
                inputMessage: value
            })
        }
        if (value !== '') {
            this.props.onTyping(true);
        } else {
            this.props.onTyping(false);
        }
        // setInputMessage(value);
    }

    messageKeyUp = (e) => {
        const value = e.target.value;
        if (value !== '') {
            if (this.props.user) {
                var msg = {
                    from: this.props.user.id,
                    conversationId: this.props.activedId
                };
                // console.log(msg);
                this.socket.emit('typingSendEmit', msg)
            }
            // if(this.props.typingID > 0 && this.props.user.id !== this.props.typingID){
            this.props.onTyping(true);
            // }
        } else {
            this.props.onTyping(false);
        }
    }

    // messageBlur = () =>{
    //     this.props.onTyping(false);
    // }

    submit = async (e) => {
        e.preventDefault();
        const { inputMessage, files } = this.state;
        if (inputMessage !== '' || files.length > 0) {
            // console.log('send')
            this.props.onsendmessage('', inputMessage, files);
            this.setState({
                inputMessage: '',
                files: [],
                fileDisplay: []
            })
            // this.props.onTyping(false);
        }
    }

    render() {

        const { inputMessage, fileDisplay } = this.state;

        return (
            <div className={'type_msg'}>
                {fileDisplay.length > 0 ?
                    <ul className="file_array_ul">
                        {fileDisplay.map((file, i) =>
                            <li key={i}>

                                <div className={`file_box`}>
                                    {file.fileType === 'jpg' || file.fileType === 'png' || file.fileType === 'jpeg' ?
                                        <img className="image" src={file.fileUrl} alt="file" />
                                        :
                                        <span className="icon"><MdInsertDriveFile /></span>
                                    }

                                </div>
                                <div className="overlay_img">
                                    <p className="file-nam">{file.fileName}</p>
                                    <button type="button" className="delete-file-button" onClick={() => this.deletefile(file)}>Delete</button>
                                </div>

                            </li>
                        )}
                    </ul>
                    :
                    null
                }
                <div className={'input_msg_write'}>
                    <form action="/" method="POST" encType="multipart/form-data" onSubmit={this.submit}>
                        <input type="text" className={'write_msg'} placeholder="Type a message" value={inputMessage} onKeyUp={this.messageKeyUp} onChange={this.messageHandler} />
                        <div className="files_and_buttons">
                            <label className="files_input_label" htmlFor="files">
                                <input type="file" name="files" accept="*" id="files" multiple onChange={this.fileInputHandler} />
                                <span className="file_input_icon"><MdAttachFile /></span>
                            </label>
                            <button className={'msg_send_btn rounded-pill ps-3 pe-3'} type="submit">Send</button>
                            {/* <button className={'msg_send_btn'} type="button" onClick={this.submit}><i><FaPaperPlane /></i></button> */}
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default MessagePanel
