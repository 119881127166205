import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import moment from 'moment';

class BudegtComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            budget: props.budgetVal,
            date: props.deadlineVal,
            error: {
                budget: '',
                date: ''
            },
            valid: {
                budget: props.budgetVal !== '',
                date: props.deadlineVal !== ''
            }

        }
    }

    componentDidMount(){
        window.scroll(0, 0)
    }

    inputHandler = (e) => {
        const { value } = e.target;
        let error = '';
        let valid = false;
        if (value.length > 0) {
            error = '';
            valid = true;
        } else {
            error = 'Budget is required.';
            valid = false;
        }

        this.setState({
            budget: value,
            error: {
                ...this.state.error,
                budget: error
            },
            valid: {
                ...this.state.valid,
                budget: valid
            }
        })
    }

    // dateHandler = (date) => {
    //     // const selectDate = new Date(date);
    //     const duration = moment().duration(moment(date).diff(new Date))
    //     const diffInHours = duration.asHours();
    //     let error = '';
    //     let valid = false;

    //     if (diffInHours > 2) {
    //         error = '';
    //         valid = true;
    //     } else {
    //         error = 'Deadline select after 2 hours ago';
    //         valid = false;
    //     }

    //     this.setState({
    //         date,
    //         error: {
    //             ...this.state.error,
    //             date: error
    //         },
    //         valid: {
    //             ...this.state.valid,
    //             date: valid
    //         }
    //     })
    // }

    nextStepFun = () => {
        // if (this.state.valid.budget && this.state.valid.date) {
        if (this.state.valid.budget) {
            if (this.props.isBackReview) {

                this.props.stepFun(5);
                this.props.budgetFun(this.state.budget);
                this.props.deadlineFun(this.state.date);
                this.props.backReviewFun(false);

            } else {

                const step = this.props.currentStep + 1;
                this.props.stepFun(step);
                this.props.budgetFun(this.state.budget);
                this.props.deadlineFun(this.state.date);
                this.props.backReviewFun(false);
            }
        } else {

            if (this.state.budget === '') {
                // this.setState({
                //     error: {
                //         ...this.state.error,
                //         budget: 'Budget is required.'
                //     }
                // })
                // console.log('if')
                this.setState({
                    error: {
                        ...this.state.error,
                        budget: 'Budget is required.'
                    }
                })
            } 
            // else {
            //     const duration = moment.duration(moment(this.state.date).diff(new Date))
            //     const diffInHours = duration.asHours();

            //     if (diffInHours < 2) {
            //         this.setState({
            //             error: {
            //                 ...this.state.error,
            //                 date: 'Deadline select after 2 hours ago'
            //             }
            //         })
            //     } else {
            //         this.setState({
            //             error: {
            //                 ...this.state.error,
            //                 date: '',
            //                 budget: ''
            //             }
            //         })
            //     }
            // }
            // else {
            //     this.setState({
            //         error: {
            //             ...this.state.error,
            //             budget: ''
            //         }
            //     })
            // }

            // const duration = moment.duration(moment(this.state.date).diff(new Date))
            // const diffInHours = duration.asHours();

            // if (diffInHours < 2) {
            //     this.setState({
            //         error: {
            //             ...this.state.error,
            //             date: 'Deadline select after 2 hours ago'
            //         }
            //     })
            // } else {
            //     this.setState({
            //         error: {
            //             ...this.state.error,
            //             date: ''
            //         }
            //     })
            // }
        }
    }

    prevStepFun = () => {
        const step = this.props.currentStep - 1;
        this.props.stepFun(step);
    }

    render() {

        const { error } = this.state;
        // console.log(error)

        return (
            <div className={'content_steps_wrap'}>
                <div className={'step_body'}>
                    <div className={'header_step'}>
                        {/* <h2 className={Header_Step_title}>{`Budget & Deadline`}</h2> */}
                        <h2 className={'Header_Step_title'}>{`Budget`}</h2>

                    </div>
                    <div className={'wrap_steps'}>
                        <label className={'label_postJOb'}>What budget do you have in mind?</label>
                        <div className={`budget_wrap mb-2`}>
                            <input type="number" name="budget" className="form-control max-200" value={this.state.budget} onChange={this.inputHandler} placeholder="Budget" />
                            <span className={'input_icons'}>$</span>
                        </div>
                        {error.budget !== "" ?
                            <div className="d-flex mb-2">
                                <p className="error-text">{error.budget}</p>
                            </div>
                            :
                            null
                        }

                        {/* <div className={deadline_wrap}>
                            <label className={label_postJOb}>What’s your deadline?</label>
                            <div className={`${dead_wrap} mb-2`}>
                                <DatePicker className="form-control" timeInputLabel="Time" showTimeInput selected={this.state.date} dateFormat={'MMMM d, yyyy - h:mm aa'} minDate={new Date()} onChange={this.dateHandler} />
                            </div>
                            {error.date !== "" ?
                                <div className="d-flex">
                                    <p className="error-text">{error.date}</p>
                                </div>
                                :
                                null
                            }
                            <p className={date_Describ}>{`Job will be ready before ${moment(this.state.date, 'dddd, D MMMM YYYY').format('dddd, D MMMM YYYY')} at ${moment(this.state.date, 'h:mm:ss a').format('h:mm:ss a')}`}</p>
                            <div className={diff_DateWrap}>
                                <p>{`${moment(this.state.date).days()} Day & ${moment(this.state.date).hours()} Hours & ${moment(this.state.date).minutes()} Minutes`} <span>left</span></p>
                            </div>
                        </div> */}

                    </div>
                </div>
                <div className={'step_footer'}>
                    <button type="button" className="btn btn-primary min-200" onClick={this.nextStepFun}>Next</button>
                    <button type="button" className="btn btn-outline-dark min-200" onClick={this.prevStepFun}>Prev</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentStep: state.editJob.currentStep,
    budgetVal: state.editJob.budgetVal,
    deadlineVal: state.editJob.deadlineVal,
    isBackReview: state.editJob.isBackReview
})

const mapDispatchToProps = dispatch => ({
    stepFun: (step) => dispatch({ type: ACTION_TYPES.STEP_EDIT_JOBS, payload: step }),
    budgetFun: (val) => dispatch({ type: ACTION_TYPES.BUDGET_EDIT_JOB, payload: val }),
    deadlineFun: (date) => dispatch({ type: ACTION_TYPES.DEADLINE_EDIT_JOB, payload: date }),
    backReviewFun: (bool) => dispatch({ type: ACTION_TYPES.BACK_REVIEW_EDITJOB_STEPS, payload: bool })
})

export default connect(mapStateToProps, mapDispatchToProps)(BudegtComponent);