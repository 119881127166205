import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DataTable from '../../components/DataTable';
import { getFreelancers, updateFreelancersStatus } from '../../controller/freelancer.controller';
import { Dropdown } from 'react-bootstrap';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import DummyImage from '../../assets/img/dummy-userImg.jpg';
import { FiEye } from 'react-icons/fi';
import Modal from 'react-bootstrap/Modal';

class FreelancerList extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            freelancerList: [],
            status: '',
            path: '',
            details: null,
            descModal: false
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);

        this.getFreelancersList();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getFreelancersList = async () => {
        const res = await getFreelancers();
        // console.log(res);
        if (this._unmounted) {
            this.setState({
                freelancerList: res.freelancers,
                path: res.path
            })
        }
    }

    pageHandler = (page, sizePerPage) => {
        console.log(page);
    }

    sizePerPageChange = (sizePerPage, page) => {
        console.log(sizePerPage);
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Table is Empty</p>
        </div>
    }

    openModalDescription = (row) => {
        if (this._unmounted) {
            this.setState({
                details: row,
                descModal: true
            })
        }
    }

    closeModalDescription = () => {
        if (this._unmounted) {
            this.setState({
                details: null,
                descModal: false
            })
        }
    }

    statusHandler = async (event, val, row) => {
        event.preventDefault();
        if (row.status !== val) {
            const res = await updateFreelancersStatus({ userID: row.profileID, status: val })
            if (res.status) {
                notifySuccess(res.message)
                this.getFreelancersList()
            } else {
                notifyError(res.message)
            }
        }
    }

    headerCenterFormatter = (column) => {
        return <div className="d-flex justify-content-center">{column.text}</div>
    }

    errorImage = (e) => {
        e.target.src = DummyImage
    }

    nameFormatter = (cell, row) => {
        return <div className="d-flex align-items-center">
            {row.profilePic ?
                <img className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} src={`${this.state.path}${row.profilePic}`} onError={this.errorImage} alt="freelancer" />
                :
                <img className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} src={DummyImage} alt="freelancer" />
            }
            <span>{row.name}</span>
        </div>
    }

    statusFormatter = (cell, row) => {
        // console.log(row);
        let classsName = '';
        let statusName = '';
        switch (row.status) {
            case 0:
                classsName = 'warning';
                statusName = 'Pending';
                break;
            case 1:
                classsName = 'success';
                statusName = 'Active';
                break;
            default:
                classsName = 'secondary';
                statusName = 'none'
                break;
        }

        return <div className="d-flex justify-content-center">
            <Dropdown>
                <Dropdown.Toggle variant={classsName} id="dropdown-basic" className="rounded-pill" style={{ fontSize: '.775rem', minWidth: '100px' }}>{statusName}</Dropdown.Toggle>

                <Dropdown.Menu bsPrefix="dropdown-menu dropdown-menu-end drop_menu">
                    <Dropdown.Item href="#/action-1" onClick={(e) => this.statusHandler(e, 1, row)}>Active</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" onClick={(e) => this.statusHandler(e, 0, row)}>Pending</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    }

    actionFormatter = (cell, row) => {
        return <div className="d-flex justify-content-center">
            <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.openModalDescription(row)}><FiEye /></button>
        </div>
    }

    modalStatusDisplay = (status) => {

        let classsName = '';
        let statusName = '';
        switch (status) {
            case 0:
                classsName = 'text-warning';
                statusName = 'Pending';
                break;
            case 1:
                classsName = 'text-success';
                statusName = 'Active';
                break;
            default:
                classsName = 'text-secondary';
                statusName = 'none'
                break;
        }
        return <p className="fs-5 mt-3">
            <b> Status:
                <span className={`${classsName} ms-2`}>{statusName}</span>
            </b>
        </p>
    }

    render() {

        const columns = [
            {
                dataField: 'name',
                text: 'Full Name',
                sort: true,
                formatter: this.nameFormatter
            },
            {
                dataField: 'categoryName',
                text: 'Service',
                sort: true
                // formatter: this.nameFormatter
            },
            {
                dataField: 'email',
                text: 'Email',
                sort: true
            },
            {
                dataField: 'mobile',
                text: 'Mobile Number',
                sort: true
            },
            {
                dataField: 'countryName',
                text: 'Country',
                sort: true
            },
            {
                dataField: 'status',
                text: 'Status',
                sort: true,
                formatter: this.statusFormatter,
                headerFormatter: this.headerCenterFormatter
            },
            {
                dataField: 'action',
                text: 'Action',
                sort: false,
                formatter: this.actionFormatter,
                headerFormatter: this.headerCenterFormatter
            }
        ];

        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3"><strong>Freelancer List</strong></h1>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <DataTable
                                keyField="profileID"
                                data={this.state.freelancerList}
                                columns={columns}
                                // onSizePerPageChange={this.sizePerPageChange} 
                                // onPageChange={this.pageHandler} 
                                noDataIndication={this.emptyRender}
                            />

                        </div>
                    </div>
                </div>
                {this.state.descModal ?
                    <Modal show={this.state.descModal} onHide={this.closeModalDescription} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >

                        <Modal.Body className="p-4">
                            <div className="d-flex align-items-center">
                                {this.state.details.profilePic ?
                                    <img className="rounded-circle me-2" style={{ width: '50px', height: '50px' }} src={`${this.state.path}${this.state.details.profilePic}`} onError={this.errorImage} alt="freelancer" />
                                    :
                                    <img className="rounded-circle me-2" style={{ width: '50px', height: '50px' }} src={DummyImage} alt="freelancer" />
                                }
                                <span>
                                    <p className="fs-5 mb-0"><b>{this.state.details.name}</b></p>
                                    <p className="fs-5 mb-0"><small className="fs-6">{this.state.details.email}</small></p>
                                </span>
                            </div>
                            <h6 className="fs-3 mb-3 mt-5"><b>{this.state.details.bio}</b></h6>
                            <p className="fs-5">{this.state.details.overview}</p>
                            {this.state.details.city ? <p className="fs-5 mt-3"><b> Address: </b> {this.state.details.city ? `${this.state.details.city},` : ''}{this.state.details.stateName ? ` ${this.state.details.stateName},` : ''} {this.state.details.countryName ? ` ${this.state.details.countryName}` : ''}</p> : null}
                            {this.state.details.mobile ? <p className="fs-5 mt-3"><b> Phone: </b> {this.state.details.mobile}</p> : null}
                            {this.state.details.status ?
                                this.modalStatusDisplay(this.state.details.status)
                                :
                                null}
                            <div className="d-flex align-items-start mt-4">
                                {this.state.details.categoryName ?
                                    <div className="fs-5 me-5">
                                        <p className={"fs-5"}>
                                            <b className="fs-5">Service </b>
                                        </p>
                                        <div>
                                            <span className="badge bg-secondary rounded-pill p-2">{this.state.details.categoryName}</span>
                                        </div>
                                    </div>

                                    :
                                    null
                                }
                                {Array.isArray(this.state.details.skills) && this.state.details.skills.length > 0 ?
                                    <div className="fs-5 ">
                                        <p className={"fs-5"}>
                                            <b className="fs-5">Skills </b>
                                        </p>
                                        <div>
                                            {this.state.details.skills.map((info, i) =>
                                                <span key={i} className="badge bg-secondary rounded-pill p-2 me-2">{info.skillName}</span>
                                            )}
                                        </div>
                                    </div>

                                    :
                                    null
                                }
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary border-0" onClick={this.closeModalDescription}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    :
                    null
                }
            </div>
        )
    }
}

export default withRouter(connect(null)(FreelancerList))
