import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactStars from "react-rating-stars-component";
import '../../../assets/css/profile.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { MdCreate, MdClear, MdAccountCircle } from 'react-icons/md';
import moment from 'moment';
// import IntlTelInput from 'react-intl-tel-input';
// import 'react-intl-tel-input/dist/main.css';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
// import flags from 'react-phone-number-input/flags'
import 'react-phone-number-input/style.css'
import Select from 'react-select'
import { emailValidation, nameValidation } from '../../../helper/formValidation.helper';
import Modal from 'react-bootstrap/Modal';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { getCountryList, getStateList } from '../../../controller/common.controller';
import { getUserProfile, updateUserProfile, updateUserProfilePicture } from '../../../controller/profile.controller';
import { changePassword } from '../../../controller/auth.controller';
import DummyUser from '../../../assets/images/dummy-userImg.jpg';
import BGImage from '../../../assets/images/client-p-banner.png';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { withRouter, Redirect } from 'react-router-dom';
import { jwtDecode } from '../../../helper/jwt.helper';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import review from '../../../controller/review.controller';
// import Image from 'next/image';

class ProfileComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            isPassLoader: false,
            isUserLoader: false,
            profileModal: false,
            isProfileLoader: false,
            countryLoading: false,
            stateLoading: false,
            details: null,
            countryOption: [],
            stateOption: [],
            profileURL: '',
            imageFile: '',
            imageUrl: '',
            countrySelect: null,
            stateSelect: null,
            reviewList: [],
            rate: 0,
            form: {
                fname: '',
                email: '',
                phone: '',
                countryCode: null,
                phoneErr: '',
                phoneValid: '',
                countrySelect: null,
                stateSelect: null,
                // citySelect: null,
                city: '',
                errors: {
                    fname: '',
                    email: '',
                    city: ''
                },
                valid: {
                    fname: false,
                    email: false,
                    city: false
                }
            },
            passForm: {
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
            }
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getProfileInfo();
        this.getReviews();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getProfileInfo = async () => {
        if (this._unmounted) {
            this.setState({
                loader: false
            })
        }
        const res = await getUserProfile();
        // console.log(res);
        if (res) {
            this.props.updateUserName(res.name);
            this.getCountrys(res.countryID);
            if (res.stateID) {
                this.getStates(res.countryID, res.stateID)
            }
            let mobileNumber = '';
            let mobilePreFix = '';
            if (res.mobile && res.mobilePrefix) {
                mobileNumber = res.mobile
                mobilePreFix = res.mobilePrefix
            }

            if (this._unmounted) {
                if (res.profilePic) {
                    const imageU = `${res.profileURL}${res.profilePic}`;
                    this.props.updateUserImage(imageU)
                }
                // console.log(res.totalRate);
                this.setState({
                    loader: false,
                    details: res,
                    profileURL: res.profilePic ? (res.profileURL + res.profilePic) : DummyUser,
                    rate: res.totalRate? res.totalRate : 0,
                    form: {
                        ...this.state.form,
                        fname: res.name ? res.name : '',
                        email: res.email ? res.email : '',
                        phone: mobileNumber,
                        countryCode: mobilePreFix,
                        phoneErr: '',
                        phoneValid: '',
                        city: res.city ? res.city : '',
                        errors: {
                            fname: '',
                            email: '',
                            city: ''
                        },
                        valid: {
                            fname: res.name ? true : false,
                            email: res.email ? true : false,
                            city: res.city ? true : false
                        }
                    }
                })
            }
        }
    }

    getReviews = async () => {
        const res = await review.getClientAllReview();
        if (res.status) {
            this.setState({
                reviewList: res.data
            })
        } else {
            this.setState({
                reviewList: []
            })
        }
    }

    getCountrys = async (countryId) => {
        if (this._unmounted) {
            this.setState({
                countryLoading: true
            })
        }
        const res = await getCountryList();
        if (res && res.length > 0) {

            const options = res.map((info) => ({ label: info.name, value: info.id }));
            if (countryId !== null) {
                const selectedCountry = options.filter(info => info.value === countryId);
                // console.log(selectedCountry[0]);
                if (selectedCountry.length > 0) {
                    // if (this._unmounted) {
                    this.setState({
                        countrySelect: selectedCountry[0],
                        form: {
                            ...this.state.form,
                            countrySelect: selectedCountry[0]
                        }

                    })
                    // }
                }
            }
            if (this._unmounted) {
                this.setState({
                    countryLoading: false,
                    countryOption: options
                })
            }
        }

        if (this._unmounted) {
            this.setState({
                countryLoading: false
            })
        }
    }

    getStates = async (countryID, stateID) => {
        if (this._unmounted) {
            this.setState({
                stateLoading: true
            })
        }

        const res = await getStateList(countryID);
        // console.log(res);
        if (res && res.length > 0) {

            const options = res.map((info) => ({ label: info.name, value: info.id, countryID: info.countryID }));
            if (stateID !== null) {
                const selectedState = options.length > 0 ? options.filter(info => info.value === stateID) : null;
                if (selectedState.length > 0) {
                    if (this._unmounted) {
                        this.setState({
                            stateSelect: selectedState[0],
                            form: {
                                ...this.state.form,
                                stateSelect: selectedState[0]
                            }
                        })
                    }
                }
            }
            if (this._unmounted) {
                this.setState({
                    stateLoading: false,
                    stateOption: options
                })
            }
        }

        if (this._unmounted) {
            this.setState({
                stateLoading: false
            })
        }
    }

    openProfileModal = () => {
        this.setState({
            profileModal: true,
            imageFile: ''
        })
    }

    modalClose = () => {
        this.setState({
            profileModal: false
        })
    }

    fileHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function (event) {
                this.setState({
                    imageUrl: event.target.result
                })
            }.bind(this);

            this.setState({
                imageFile: file
            })

        }
        // console.log(file);
    }

    deleteImg = () => {
        this.setState({
            imageFile: ''
        })
    }

    inputhandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        }, () => this.formValidation(name, value))
    }

    formValidation = (name, value) => {
        let error = this.state.form.errors;
        let valid = this.state.form.valid;
        switch (name) {
            case 'fname':
                const fres = nameValidation(value);
                valid.fname = fres.status;
                error.fname = fres.message;
                break;
            case 'email':
                const eres = emailValidation(value);
                valid.email = eres.status;
                error.email = eres.message;
                break;
            case 'city':
                const res = new RegExp(/^([a-zA-Z_$][a-zA-Z\\d_$]*)$/);
                if (value.length > 0) {
                    if (res.test(value)) {
                        error.city = '';
                        valid.city = true;
                    } else {
                        error.city = 'City must be string.';
                        valid.city = false;
                    }
                } else {
                    error.city = 'City must be required.';
                    valid.city = false;
                }
                break;
            default:
                break;
        }

        this.setState({
            form: {
                ...this.state.form,
                errors: error,
                valid: valid
            }
        })
    }

    phoneHandler = (number) => {
        let error = '';
        let isValid = false;
        let countryCode = '';
        if (number) {

            if (isValidPhoneNumber(number) === false) {
                error = 'Invalid phone number';
                isValid = false;
                countryCode = ''
            } else {
                const coun = parsePhoneNumber(number);
                const code = getCountryCallingCode(coun.country);
                // console.log(code.country) 
                error = '';
                isValid = true;
                countryCode = code
            }

        } else {
            error = "Phone number required";
            isValid = false;
            countryCode = ''
        }

        this.setState({
            form: {
                ...this.state.form,
                phone: number,
                phoneErr: error,
                phoneValid: isValid,
                countryCode: countryCode
            }
        });

    }

    countryHandler = (selectedOption) => {
        // console.log(selectedOption);
        this.setState({
            countrySelect: selectedOption,
            stateSelect: null,
            stateOption: [],
            form: {
                ...this.state.form,
                countrySelect: selectedOption,
                stateSelect: null,
            }
        })

        if (selectedOption) {
            this.getStates(selectedOption.value)
        }
    }

    stateHandler = (selectedOption) => {
        // console.log(selectedOption);
        this.setState({
            stateSelect: selectedOption,
            form: {
                ...this.state.form,
                stateSelect: selectedOption
            }
        })
    }

    // cityHandler = (selectedOption) => {
    //     // console.log(selectedOption);
    //     this.setState({
    //         form: {
    //             ...this.state.form,
    //             citySelect: selectedOption
    //         }
    //     })
    // }

    userSubmit = async (e) => {
        e.preventDefault();

        const { form: { fname, phone, countryCode, city, valid }, countrySelect, stateSelect } = this.state;
        if (valid.fname === false) {
            return notifyError('Full Name must be required')
        } else if (valid.email === false) {
            return notifyError('Email must be required')
        } else if (phone === '') {
            return notifyError('Phone Number must be required')
        } else if (countrySelect === null) {
            return notifyError('Country must be required')
        } else if (stateSelect === null) {
            return notifyError('State must be required')
        } else if (valid.city === false) {
            return notifyError('City must be required')
        }
        // console.log(countryCode);
        this.setState({
            isUserLoader: true
        })
        const data = {
            name: fname,
            mobilePrefix: countryCode !== '' ? countryCode : '',
            mobile: phone,
            countryID: countrySelect.value,
            stateID: stateSelect.value,
            city: city
        }

        const res = await updateUserProfile(data);
        // console.log(res);
        if (res.status) {
            this.setState({
                isUserLoader: false
            })
            notifySuccess(res.message)
        } else {
            this.setState({
                isUserLoader: false
            })
            notifyError(res.message)
        }
    }

    formSubmit = async (e, actions) => {
        // console.log(e);
        this.setState({
            isPassLoader: true
        })
        const res = await changePassword(e);
        if (res.status) {
            actions.resetForm({
                e: {
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                }
            })
            if (this._unmounted) {
                this.setState({
                    isPassLoader: false,
                    passForm: {
                        ...this.state.passForm,
                        currentPassword: '',
                        newPassword: '',
                        confirmPassword: ''
                    }
                });
            }

            notifySuccess(res.message)
        } else {
            this.setState({
                isPassLoader: false
            })
            notifyError(res.message);
        }
    }

    uploadImgFun = async () => {
        this.setState({
            isProfileLoader: true
        })

        const res = await updateUserProfilePicture(this.state.imageFile);

        if (res.status) {
            this.setState({
                isProfileLoader: false
            })
            notifySuccess(res.message)
            this.modalClose();
            this.getProfileInfo();
        } else {
            this.setState({
                isProfileLoader: false
            })
            notifyError(res.message)
        }
    }

    render() {

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 1) {
                return <Redirect to="/freelancer/jobs" />
            }
        }

        const updatePasswordSchema = Yup.object().shape({
            currentPassword: Yup.string().required('Current Password must be required'),
            newPassword: Yup.string().required('New Password must be required'),
            confirmPassword: Yup.string().oneOf([Yup.ref("newPassword")], "Both password need to be the same").required('Confirm Password must be required')
        });

        const { details, reviewList, loader, form: { fname, email, phone, phoneErr, city, errors }, countrySelect, stateSelect } = this.state;

        return (
            <>
                <section className={`profile_wrap15`}>
                    <div className={'profile_banner'}>
                        <img src={BGImage} alt="banner" />
                    </div>
                    <div className="container">
                        {loader ?
                            <MainLoaderComponent color="black" />
                            :
                            <div className="row">
                                <div className="col-sm-12 col-md-4">
                                    <div className={`${'card_wrap'} mt_150`}>
                                        <div className={'img_wrap2'}>
                                            {this.state.profileURL ?
                                                <img src={`${this.state.profileURL}`} alt="User" />
                                                :
                                                <img src={DummyUser} alt="User" />
                                            }
                                        </div>
                                        <div className={'user_content'}>
                                            <h3>{this.state.form.fname}</h3>
                                            <p className={'nick_nm'}>{this.state.form.email}</p>
                                            <div className={'rating_block'}>
                                                <p>{this.state.rate}</p>
                                                {/* <StarRatings starRatedColor="#ffc12d" rating={3} numberOfStars={5} name={'userRating'} /> */}
                                                <div style={{ marginLeft: '10px' }} className={'rating_bl'}>
                                                    {/* {console.log(this.state.rate)} */}
                                                    {this.state.rate > 0 ?
                                                        <ReactStars count={5} isHalf={true} value={this.state.rate} edit={false} size={24} activeColor="#ffc12d" />
                                                        :
                                                        <ReactStars count={5} isHalf={true} value={0} edit={false} size={24} activeColor="#ffc12d" />
                                                    }

                                                </div>
                                                <span className={'review_text'}>({details?.totalReview ? details.totalReview : 0} reviews)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-8">
                                    <div className={`right_sec mt_150`}>
                                        <div className={'private_Info'}>
                                            <h4>Private Information</h4>
                                            <div className={'uploadImg'}>
                                                <button type="button" className={'btn_edit'} onClick={this.openProfileModal} ><MdCreate /></button>
                                                <div className={'client_profile'}>
                                                    <img src={this.state.profileURL} alt="user" />
                                                </div>
                                            </div>
                                            {/* <Formik initialValues={this.state.form} validationSchema={LoginSchema} onSubmit={(values) => this.formSubmit(values)}>
                                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => ( */}

                                            <form action="/" method="POST" onSubmit={this.userSubmit}>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-4">
                                                            <input type="text" name="fname" className={errors.fname !== "" ? "form-control error" : "form-control"} placeholder="Full Name" onChange={this.inputhandleChange} value={fname} />
                                                            {errors.fname !== "" ? <p className="error-text">{errors.fname}</p> : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-4">
                                                            <input type="email" name="email" className={errors.email !== '' ? "form-control error" : "form-control"} placeholder="Email" onChange={this.inputhandleChange} value={email} disabled />
                                                            {errors.email !== '' ? <p className="error-text">{errors.email}</p> : null}
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-sm-6">
                                                        <div className="form-group mb-4">
                                                            <input type="email" name="email" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder="Email" onChange={this.handleChange} onBlur={this.handleBlur} value={values.email} />
                                                            {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                                                        </div>
                                                    </div> */}
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-4">
                                                            <PhoneInput
                                                                // flags={flags}
                                                                // containerClassName={`intl-tel-input phone_container`}
                                                                // inputClassName="form-control"
                                                                placeholder="Enter phone number"
                                                                onChange={this.phoneHandler}
                                                                // onPhoneNumberChange={this.phoneHandler}
                                                                value={phone}
                                                            />
                                                            {phoneErr !== '' ? <p className="error-text">{phoneErr}</p> : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-4">
                                                            <Select instanceId="countrySelect" options={this.state.countryOption} isLoading={this.state.countryLoading} name="countrySelect" value={countrySelect} onChange={this.countryHandler} placeholder="Country" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-4">
                                                            <Select instanceId="stateSelect" options={this.state.stateOption} isLoading={this.state.stateLoading} name="stateSelect" value={stateSelect} onChange={this.stateHandler} placeholder="State" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-4">
                                                            <input type="text" name="city" className={errors.city !== '' ? "form-control error" : "form-control"} placeholder="City" onChange={this.inputhandleChange} value={city} />
                                                            {errors.city !== '' ? <p className="error-text">{errors.city}</p> : null}
                                                        </div>
                                                        {/* <div className="form-group mb-4">
                                                        <Select instanceId="citySelect" options={options} name="citySelect" defaultValue={citySelect} onChange={this.countryHandler} placeholder="City" />
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" aria-label="Save" className="btn btn-primary min-200 max-200 display-block ml-auto" disabled={this.state.isUserLoader}>{this.state.isUserLoader ? <SmallLoaderComponent /> : 'Save'}</button>
                                                </div>
                                            </form>
                                            {/* )}
                                    </Formik> */}
                                        </div>
                                        <div className={`private_Info mt-4`}>
                                            <h4>Update Password</h4>
                                            <Formik initialValues={this.state.passForm} validationSchema={updatePasswordSchema} onSubmit={(values, actions) => this.formSubmit(values, actions)}>
                                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (

                                                    <form action="/" method="POST" onSubmit={handleSubmit}>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="form-group mb-4">
                                                                    <input type="password" name="currentPassword" className={errors.currentPassword && touched.currentPassword ? "form-control error" : "form-control"} placeholder="Current Password" onChange={handleChange} onBlur={handleBlur} value={values.currentPassword} />
                                                                    {errors.currentPassword && touched.currentPassword ? <p className="error-text">{errors.currentPassword}</p> : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group mb-4">
                                                                    <input type="password" name="newPassword" className={errors.newPassword && touched.newPassword ? "form-control error" : "form-control"} placeholder="New Password" onChange={handleChange} onBlur={handleBlur} value={values.newPassword} />
                                                                    {errors.newPassword && touched.newPassword ? <p className="error-text">{errors.newPassword}</p> : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group mb-4">
                                                                    <input type="password" name="confirmPassword" className={errors.confirmPassword && touched.confirmPassword ? "form-control error" : "form-control"} placeholder="Confirm Password" onChange={handleChange} onBlur={handleBlur} value={values.confirmPassword} />
                                                                    {errors.confirmPassword && touched.confirmPassword ? <p className="error-text">{errors.confirmPassword}</p> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" aria-label="Update" className="btn btn-primary min-200 max-200 display-block ml-auto" disabled={this.state.isPassLoader}>{this.state.isPassLoader ? <SmallLoaderComponent /> : 'Update'}</button>
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                    {reviewList && reviewList.length > 0 ?
                                        <div className={`${'right_sec'} ${'mt_0'} ${'review_wrap'}`}>
                                            <h4>Reviews</h4>
                                            <ul className={'review_ul'}>
                                                {reviewList.map((info, i) =>
                                                    <li className={`${'review_div'} ${'bl'}`} key={i}>
                                                        <div className={'caption_wrap'}>
                                                            <h5 className={'review_title'}>{info?.jobTitle}</h5>
                                                            <span className={'time'}>{moment(info.createdAt).format('DD MMM YYYY')}</span>
                                                        </div>
                                                        <div className={'detail mb-0'}>
                                                            <div className={`${'rating'} ${'rvi'}`}>
                                                                <p className={`${'rating_badge'} mb-0`}>{info?.rate}</p>
                                                                <ReactStars count={5} value={info.rate} edit={false} size={15} activeColor="#ffc12d" />
                                                            </div>
                                                            <p>{info?.description}</p>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                            {/* <div className={'pagination_wrap'}>
                                                <ReactPaginate
                                                    initialPage={page}
                                                    // forcePage={this.state.page}
                                                    pageCount={1}
                                                    pageRangeDisplayed={4}
                                                    breakLabel={'...'}
                                                    onPageChange={this.pageHandler}
                                                    activeClassName={'active'}
                                                    previousLabel={<MdKeyboardArrowLeft />}
                                                    previousClassName={'previous'}
                                                    nextLabel={<MdKeyboardArrowRight />}
                                                    nextClassName={'next'}
                                                />
                                            </div> */}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <Modal show={this.state.profileModal} onHide={this.modalClose} className={'profile_modal'} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">Upload Profile Picture</Modal.Title>
                            <button type="button" className={'modal_close'} onClick={this.modalClose}><MdClear /></button>
                        </Modal.Header>
                        <Modal.Body>
                            <h2 className={`${'body_title'} fs-6`}>Please upload a professional portrait that clearly shows your face.</h2>
                            <div className={'Upload_wrap'}>
                                {this.state.imageFile === "" ?
                                    <>
                                        <div className={'file_wrap'}>
                                            <input id="profile" type="file" name="profile" onChange={this.fileHandler} />
                                            <label className={'file_label'} htmlFor="profile">
                                                <i><MdAccountCircle /></i>
                                                <p>Add Profile Photo</p>
                                            </label>
                                        </div>

                                        {/* <div className={'input_wrap'}>
                                            <input type="file" name="file" value={this.state.imageFile} onChange={this.fileHandler} id="inputFile" />
                                            <label htmlFor="inputFile">
                                                <span className={'icon_img'}><MdPhotoLibrary /></span>
                                                <p>Attach or Drop photo here, <br />or <span>Browse</span></p>
                                            </label>
                                        </div> */}
                                    </>
                                    :
                                    <>
                                        <div className={'image_wap'}>
                                            <div className={'image'}>
                                                <img src={this.state.imageUrl !== '' ? this.state.imageUrl : DummyUser} alt="user" />
                                                {/* : */}
                                                {/* <img src={'/assets/images/dummy-userImg.jpg'} alt="user" /> */}
                                            </div>
                                            <button type="delete" className={'delete_btn'} onClick={this.deleteImg}>Delete Photo</button>
                                        </div>
                                        {/* <div className={'uploaded_wrap'}>
                                            <div className={'image_wrap'}>
                                                <img src={this.state.imageUrl !== '' ? this.state.imageUrl : DummyUser} alt="user" />
                                            </div>
                                            <button type="button" onClick={this.deleteImg} className={'imgDelet'}><MdClear /></button>
                                        </div> */}
                                    </>
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn_cancle btn btn-outline-dark" onClick={this.modalClose}>Cancel</button>
                            <button type="button" className="btn btn-primary min-200 max-200 display-block ml-auto" onClick={this.uploadImgFun} disabled={this.state.isProfileLoader}>{this.state.isProfileLoader ? <SmallLoaderComponent /> : 'Upload Photo'}</button>
                        </Modal.Footer>
                    </Modal>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => ({
    userImage: state.user.userImage
})

const mapDipatchToProps = dispatch => ({
    updateUserImage: (url) => dispatch({ type: ACTION_TYPES.USER_IMAGE_UPDATE, payload: url }),
    updateUserName: (name) => dispatch({ type: ACTION_TYPES.UPDATE_USER_NAME, payload: name })
})

export default withRouter(connect(mapStateToProps, mapDipatchToProps)(ProfileComponent))
