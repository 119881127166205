import * as ACTION_TYPES from '../../actions/admin/type';

const initialState = {
    openSideBar:false
}

const layoutReducers = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.SIDEBAR_TOGGLE:
            return{
                ...state,
                openSideBar:action.payload
            }
        default:
            return state;
    }
}

export default layoutReducers;