import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { MdPhotoSizeSelectActual, MdClear } from 'react-icons/md';
import UploadImg from '../../../assets/images/upload-img.svg'
// import { DateTime } from 'react-datetime-bootstrap';

class DescriptionComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            description: '',
            files: [],
            imageArray: [],
            deleteImgIds: [],
            error: {
                description: '',
                files: ''
            },
            valid: {
                description: false,
                files: false
            }

        }
    }

    componentDidMount() {
        window.scroll(0, 0)
        this.setState({
            description: this.props.descriptionVal,
            files: this.props.uploadedFiles.length > 0 ? this.props.uploadedFiles.map(info => ({ ...info, name: info.fileName })) : [],
            error: {
                description: '',
                files: ''
            },
            valid: {
                ...this.state.valid,
                description: this.props.descriptionVal.length > 0 ? (this.props.descriptionVal.length >= 50 && this.props.descriptionVal <= 5000) ? true : false : false,
                files: true
            }
        });
        this.displayImageCreate(this.props.uploadedFiles, false)
    }

    displayImageCreate = (files, isInput) => {
        let image = [];
        if (isInput) {
            for (var h = 0; h < files.length; h++) {
                if (files[h].id) {
                    if(!files[h].name){
                        image.push({ ...files[h], name: files[h].name })
                    }else{
                        image.push(files[h])
                    }
                } else {
                    const url = URL.createObjectURL(files[h]);
                    image.push({ url, name: files[h].name })
                }
            }
            this.setState({
                imageArray: image
            })
        } else {
            this.setState({
                imageArray: files.length > 0 ? files.map(info => ({ ...info, name: info.fileName })) : []
            })
        }

    }

    fileHandler = (e) => {
        const { files } = e.target;
        let stateFiles = this.state.files;
        // let errorFile = this.state.error.files;
        for (var i = 0; i < files.length; i++) {
            //     // var allowedExtensions = new RegExp(/(\.jpg|\.jpeg|\.png|\.doc|\.xls|\.pdf|\.csv)$/i);
            //     // if (!allowedExtensions.exec(files[i])) {
            //     //     errorFile = "You have uploaded an jpg, jpeg, png, doc, xls, pdf and csv file type"
            //     // }
            //     errorFile = ''
            stateFiles.push(files[i]);
        }
        this.displayImageCreate(stateFiles, true)
        this.setState({
            files: stateFiles,
            error: {
                ...this.state.error,
                // files: errorFile
            }
        })
    }

    deleteImg = (file) => {
        let files = this.state.files;
        // console.log(file);
        const newArray = file.id ? files.filter(info => info.name.toLowerCase() !== file.fileName.toLowerCase()) : files.filter(info => info.name.toLowerCase() !== file.name.toLowerCase());
        let errorFile = this.state.error.files;
        for (var i = 0; i < newArray.length; i++) {
            var allowedExtensions = new RegExp(/(\.jpg|\.jpeg|\.png|\.doc|\.xls|\.pdf|\.csv)$/i);
            if (!allowedExtensions.exec(files[i])) {
                errorFile = "You have uploaded an jpg, jpeg, png, doc, xls, pdf and csv file type"
            }

            errorFile = ''
        }
        this.displayImageCreate(newArray, file.id ? false : true)
        if (file.id) {
            this.setState({
                deleteImgIds: [...this.state.deleteImgIds, file.id]
            })
        }
        this.setState({
            files: newArray,
            error: {
                ...this.state.error,
                files: errorFile
            }
        })
    }

    inputHandler = (e) => {
        const { value } = e.target;
        let error = '';
        let valid = false;
        if (value.length > 0) {
            if (value.length > 50 && value.length <= 5000) {
                error = '';
                valid = true;
            } else {
                error = 'Project Description must be between 50 to 5000 characters';
                valid = false;
            }
        } else {
            error = 'Job description is required.';
            valid = false
        }

        this.setState({
            description: value,
            error: {
                ...this.state.error,
                description: error
            },
            valid: {
                ...this.state.valid,
                description: valid
            }
        })
    }

    nextStepFun = () => {
        if (this.state.description.length > 0) {
            if (this.state.description.length >= 50 && this.state.description.length <= 5000) {
                // console.log(this.state.files);
                if (this.props.isBackReview) {
                    this.props.stepFun(5);
                    this.props.descriptionFun(this.state.description);
                    // localStorage.setItem('files', JSON.stringify(this.state.files))
                    this.props.filesFun(this.state.files);
                    this.props.backReviewFun(false);
                    this.props.DeletefileFun(this.state.deleteImgIds)
                } else {
                    const step = this.props.currentStep + 1;
                    this.props.stepFun(step);
                    this.props.descriptionFun(this.state.description);
                    this.props.filesFun(this.state.files);
                    // localStorage.setItem('files', JSON.stringify(this.state.files))
                    this.props.backReviewFun(false);
                }
            } else {
                this.setState({
                    error: {
                        ...this.state.error,
                        description: 'Job description minimum 50 or maximum 5000 characters long'
                    }
                })
            }

        } else {
            this.setState({
                error: {
                    ...this.state.error,
                    description: 'Job description is required.'
                }
            })
        }
    }

    prevStepFun = () => {
        const step = this.props.currentStep - 1;
        this.props.stepFun(step);
    }

    render() {

        const { error } = this.state;
        return (
            <div className={'content_steps_wrap'}>
                <div className={'step_body'}>
                    <div className={'header_step'}>
                        <h2 className={'Header_Step_title'}>Description</h2>

                    </div>
                    <div className={'wrap_steps'}>
                        <div className={`description_wrap mb-4`}>
                            <label className={'label_postJOb'}>Describe your project</label>
                            <textarea className="form-control" cols="8" minLength="50" maxLength="5000" placeholder={`Tell us more about your project. Start with a bit about yourself or your business & include an overview of what you need done.`} value={this.state.description} onChange={this.inputHandler}></textarea>
                            <div className="d-flex justify-content-end"><span className={'length_description'}>{this.state.description.length}/5000 characters (minimum 50)</span></div>
                        </div>
                        {error.description !== "" ?
                            <div className="d-flex">
                                <p className="error-text">{error.description}</p>
                            </div>
                            :
                            null
                        }
                        <div className={`description_wrap mb-4`}>
                            <label className={'label_postJOb'}>Addtitional files (optional)</label>
                            <div className={'file_wrap2'}>
                                <label htmlFor="jobFile" className={'file_label'}>
                                    <img src={UploadImg} alt="upload" />
                                    <p>Drop any images or documents here, or <b>Browse</b></p>
                                    <span>(Maximum file size: 8 MB)</span>
                                </label>
                                <input type="file" name="files[]" id="jobFile" onChange={this.fileHandler} multiple accept="image/png, image/jpeg, image/doc, image/pdf, image/xls, image/csv, image/jpg" />
                            </div>
                            {this.state.imageArray.length > 0 ?
                                <ul className={'ul_files'}>
                                    {this.state.imageArray.map((file, i) => <li key={i}>
                                        <span><MdPhotoSizeSelectActual /></span>
                                        <p>{file.name}</p>
                                        <button type="button" style={{ background: 'transparent', border: 0 }} onClick={() => this.deleteImg(file, i)}><MdClear /></button>
                                    </li>)}
                                </ul>
                                :
                                null
                            }
                        </div>

                        {error.files !== "" ?
                            <div className="d-flex">
                                <p className="error-text">{error.files}</p>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div className={'step_footer'}>
                    <button type="button" className="btn btn-primary min-200" onClick={this.nextStepFun}>Next</button>
                    <button type="button" className="btn btn-outline-dark min-200" onClick={this.prevStepFun}>Prev</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentStep: state.editJob.currentStep,
    uploadedFiles: state.editJob.uploadFiles,
    descriptionVal: state.editJob.description,
    isBackReview: state.editJob.isBackReview
})

const mapDispatchToProps = dispatch => ({
    stepFun: (step) => dispatch({ type: ACTION_TYPES.STEP_EDIT_JOBS, payload: step }),
    descriptionFun: (val) => dispatch({ type: ACTION_TYPES.DESCRIPTION_EDIT_JOB, payload: val }),
    filesFun: (files) => dispatch({ type: ACTION_TYPES.FILES_EDIT_JOB, payload: files }),
    DeletefileFun: (ids) => dispatch({ type: ACTION_TYPES.FILES_DELETE_ID_EDIT_JOB, payload: ids }),
    backReviewFun: (bool) => dispatch({ type: ACTION_TYPES.BACK_REVIEW_EDITJOB_STEPS, payload: bool })
})

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionComponent);