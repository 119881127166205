import { apiHelper } from "../helper/api.helper"
import { jwtDecode } from "../helper/jwt.helper"

export const findFreelancerList = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const { categoryID, pageNo, searchText, tab } = data;

    let formData = new FormData();
    formData.append('categoryID', categoryID)
    formData.append('pageNo', pageNo)
    formData.append('search', searchText)
    formData.append('tab', tab)

    const response = await apiHelper('client/freelancer/get', 'POST', formData, header);
    const res = response.data;

    if (!res.status) {
        return { status: false, data: [], message: res.message }
    }

    return { status: true, ...jwtDecode(res.data), message: res.message };

}

export const freelancerDetails = async (id) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await apiHelper(`client/freelancer/get/${id}`, 'GET', null, header);
    const res = response.data;
    // console.log(res);

    if (!res.status) {
        return { status: false, data: null, message: res.message }
    }

    return { status: true, data:jwtDecode(res.data), message: res.message };

}

export const freelancerSaveUnsave = async (id) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('freelancerID', id)

    const response = await apiHelper(`client/freelancer/saveunsave`, 'POST', formData, header);
    const res = response.data;
    // console.log(res);

    if (!res.status) {
        return { status: false, data: null, message: res.message }
    }

    return { status: true, data:null, message: res.message };

}