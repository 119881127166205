import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { jwtDecode } from '../../helper/jwt.helper';
import localStorage from 'local-storage';

const AdminPrivateRoute = ({component:Component, ...rest}) => {
    const token = localStorage.get('adminToken');
    let tokenDecode = token?jwtDecode(token):null;
    // console.log(Component);
    return <Route 
        {...rest}
        render={props=>{
            // console.log(tokenDecode[0]);
            return token?
            tokenDecode && tokenDecode.email?
                    <Component {...props} />
                    :
                    <Redirect to="/admin" />
            :
                <Redirect to="/admin" />
        }}
    />
}

export default AdminPrivateRoute;
