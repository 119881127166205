import React from 'react';
import { Switch, Route, withRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import PrivateRoute from './helper/PrivateRoute';
import AdminPrivateRoute from './admin/components/AdminPrivateRoute';
import { routes, adminRoutes } from './routes';
import Layout from './components/Layout'
import TheLayout from './admin/components/TheLayout';
// import AdminLoginComponent from './admin/views/login';
import { connect } from 'react-redux';
import { Component } from 'react';

class App extends Component {
  render() {
    const { location: { pathname } } = this.props
    // console.log();
    const isAdmin = pathname.split('/').includes('admin');
    return (
      <>
        <Switch>
          {/* <Route path={'/admin'} exact component={AdminLoginComponent} /> */}
          {!isAdmin ?
            routes.length > 0 ?
              routes.map((info, i) => {
                if (info.isPrivate) {
                  return <PrivateRoute key={i} path={info.path} exact={info.exact} component={props=><Layout {...props}><info.component /></Layout>} />
                } else {
                  return <Route key={i} path={info.path} exact component={props=><Layout {...props}><info.component /></Layout>} />
                }
              })
              :
              null
            :
            adminRoutes.length > 0 ?
              adminRoutes.map((route, idx) => {
                if (route.isPrivate) {
                  // console.log(route)
                  return <AdminPrivateRoute exact={route.exact} path={route.path} key={idx} component={props=><TheLayout {...props}><route.component /></TheLayout>} />
                  // return <AdminPrivateRoute exact={route.exact} path={route.path} key={idx} render={props => <TheLayout {...props}><route.component /></TheLayout>} />

                }
                else {
                  return <Route path={route.path} key={idx} exact component={route.component} />
                }
              })
              :
              null
          }

          {/* <Route path="/admin/dashboard" name="Dashboard" component={props => <TheLayout {...props} />} /> */}

        </Switch>
        <ToastContainer />
      </>
    );
  }
}

export default withRouter(connect(null)(App));
