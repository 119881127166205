import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { MdClear } from "react-icons/md";
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { editPortFolio } from '../../../controller/profile.controller';

class EditPortfolio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            title: '',
            url: '',
            files: [],
            imageArray: [],
            delete_to:[],
            fileError: '',
            fileValid: false,
            errors: {
                title: '',
                url: ''
            },
            valid: {
                title: false,
                url: false
            }
        }
    }

    componentDidMount() {
        const { userData } = this.props.data;
        if (userData) {
            this.setState({
                title: userData.title,
                url: userData.url,
                fileValid: (userData.attachments && userData.attachments.length > 0)?true:false,
                errors: {
                    title: '',
                    url: ''
                },
                valid: {
                    title: userData.title !== ''?true:false,
                    url: userData.url !== ''?true:false
                }
            })
            if(userData.attachments && userData.attachments.length > 0){

                this.setState({
                    files:userData.attachments.map(info=>({...info, name:`${this.props.data.path}${info.fileName}`})),
                    imageArray:userData.attachments.map(info=>({...info, name:`${this.props.data.path}${info.fileName}`}))
                })
            }
        }
    }

    inputhandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.errors;
        let valid = this.state.valid;
        const urlReg = new RegExp(/^(http:\/\/www\\.|https:\/\/www\\.|http:\/\/|https:\/\/)[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
        switch (name) {
            case 'title':
                valid.title = value.length > 0;
                error.title = valid.title ? '' : 'Portfolio title must be required!'
                break;
            case 'url':
                if (value.length > 0) {
                    if (urlReg.test(value)) {
                        valid.url = true;
                        error.url = ''
                    } else {
                        valid.url = false;
                        error.url = 'URL not valid'
                    }
                } else {
                    valid.url = false;
                    error.url = 'Portfolio URL must be required!'
                }
                break;
            default:
                break;
        }

        this.setState({
            errors: error,
            valid
        })
    }

    fileHandler = (e) => {
        const { files } = e.target;
        let uploadedFile = this.state.files;
        if (uploadedFile.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const match = uploadedFile.filter(info => info.name.toLowerCase() === files[i].name.toLowerCase())
                if (match.length === 0) {
                    uploadedFile.push(files[i])
                }
            }
        } else {
            uploadedFile = files;
        }
        this.displayImageCreate(uploadedFile, true);
        this.setState({
            files: uploadedFile
        })
    }


    displayImageCreate = (files, isInput) => {
        let image = [];
        if (isInput) {
            for (let h = 0; h < files.length; h++) {
                if (files[h].id) {
                    if (!files[h].name) {
                        image.push({ ...files[h], name: files[h].name })
                    } else {
                        image.push(files[h])
                    }
                } else {
                    const url = URL.createObjectURL(files[h]);
                    image.push({ url, name: files[h].name })
                }
            }
        } else {
            this.setState({
                imageArray: image
            })
        }
    }

    deleteFile = (file) => {
        const { files } = this.state;
        let newArray = file.id ? files.filter(info => info.fileName.toLowerCase() !== file.fileName.toLowerCase()) : files.filter(info => info.name.toLowerCase() !== file.name.toLowerCase());
        // for (let i = 0; i < file.length; i++) {
        //     if (file[i].name.toLowerCase() !== file.name.toLowerCase()) {
        //         newArray.push(file[i]);
        //     }
        // }
        console.log(newArray);
        this.displayImageCreate(newArray, file.id ? false : true);
        this.setState({
            files: newArray,
            delete_to:[...this.state.delete_to, file.id]
        })
    }

    userSubmit = async (e) => {

        e.preventDefault();

        const { title, valid, files, url } = this.state;
        if (valid.title === false) {
            return notifyError('Portfolio title must be required!')
        }

        if (valid.url === false) {
            return notifyError('Portfolio URL must be required!')
        } else {
            const urlReg = new RegExp(/^(http:\/\/www\\.|https:\/\/www\\.|http:\/\/|https:\/\/)[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
            if (!urlReg.test(url)) {
                return notifyError('Portfolio URL not valid')
            }
        }

        if (files.length === 0) {
            return notifyError('Portfolio Images must be required!')
        }
        const { userData } = this.props.data;
        const data = {
            title:title, 
            url, 
            files:files,
            to_delete:this.state.delete_to,
            portfolioID:userData.id, 
        }

        const res = await editPortFolio(data)
        if(res.status){
            notifySuccess(res.message)
            this.props.data.onHide();
            this.props.data.updateFunction();
        }else{
            notifyError(res.message);
        }
    }

    render() {

        const { show, onHide } = this.props.data;
        const { loader, title, url, imageArray, errors } = this.state;
        console.log(imageArray);
        return (
            <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <h6 className="h5 pb-3 border-bottom">Update Portfolio</h6>
                    <form action="/" method="POST" onSubmit={this.userSubmit} encType="multipart/form-data">
                        <div className="row pb-4 pt-4">
                            <div className="col-sm-12">
                                <div className="form-group mb-4">
                                    <label>Title</label>
                                    <input type="text" name="title" className={errors.title !== "" ? "form-control error" : "form-control"} placeholder="Ex. Portfolio" onChange={this.inputhandleChange} value={title} />
                                    {errors.title !== "" ? <p className="error-text">{errors.title}</p> : null}
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group mb-4">
                                    <label>URL</label>
                                    <input type="url" name="url" className={errors.url !== '' ? "form-control error" : "form-control"} placeholder="Ex. https://www.portfolio.com" onChange={this.inputhandleChange} value={url} />
                                    {errors.url !== '' ? <p className="error-text">{errors.url}</p> : null}
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <div className="form-group mb-3">
                                    <label >Attachments</label>
                                    <div className={'file_input_wrap'}>
                                        <input id="attachments" multiple type="file" onChange={this.fileHandler} />
                                        <label className={'file_label2'} htmlFor="attachments"><span>Drag or <b>upload</b> Portfolio images</span></label>
                                    </div>
                                    {imageArray.length > 0 ?
                                        <ul className={'fileDisplay'}>
                                            {imageArray.map((file, i) =>
                                                <li key={i}>
                                                    <div className={'img_ul_wrap'}>
                                                        <img src={file.name} alt="uploaded" />
                                                    </div>
                                                    <div className={'image_overlay'}>
                                                        <button type="button" className={'delete_btns'} onClick={() => this.deleteFile(file)}><MdClear /></button>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-group pt-3 border-top d-flex justify-content-between">
                            <button type="button" className="btn_cancle btn btn-outline-dark" onClick={onHide}>Cancel</button>
                            <button type="submit" aria-label="Update" className="btn btn-primary min-200 max-200" disabled={loader}>{loader ? <SmallLoaderComponent /> : 'Update'}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(connect(null)(EditPortfolio));