const initialState = {
    email:'',
    password:''
}

const loginReducers = (state = initialState, action) =>{
    switch(action.type){
        default:
            return state;
    }
}

export default loginReducers;