import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { MdClear } from "react-icons/md";
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { addPortFolio } from '../../../controller/profile.controller';

class AddPortfolio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            title: '',
            url: '',
            file: [],
            imageArray: [],
            fileError: '',
            fileValid: false,
            errors: {
                title: '',
                url: ''
            },
            valid: {
                title: false,
                url: false
            }
        }
    }

    inputhandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.errors;
        let valid = this.state.valid;
        const urlReg = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
        switch (name) {
            case 'title':
                valid.title = value.length > 0;
                error.title = valid.title ? '' : 'Portfolio title must be required!'
                break;
            case 'url':
                if (value.length > 0) {
                    if (urlReg.test(value)) {
                        valid.url = true;
                        error.url = ''
                    } else {
                        valid.url = false;
                        error.url = 'URL not valid'
                    }
                } else {
                    valid.url = false;
                    error.url = 'Portfolio URL must be required!'
                }
                break;
            default:
                break;
        }

        this.setState({
            errors: error,
            valid
        })
    }

    fileHandler = (e) => {
        const { files } = e.target;
        let uploadedFile = this.state.file;
        let Error = '';
        let Valid = false;
        if (uploadedFile.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const match = uploadedFile.filter(info => info.name.toLowerCase() === files[i].name.toLowerCase())
                if (match.length === 0) {
                    var t = files[i].type.split('/').pop().toLowerCase();
                    if (t === "jpeg" && t === "jpg" && t === "png") {
                        Error = '';
                        Valid = true;
                        uploadedFile.push(files[i])
                    }else{
                        Error = 'Image not valid';
                        Valid = false;
                    }
                }
            }
        } else {
            uploadedFile = files;
        }
        this.displayImageCreate(uploadedFile);
        this.setState({
            file: uploadedFile,
            fileError:Error,
            fileValid:Valid
        })
    }


    displayImageCreate = (files) => {
        let image = [];
        for (let h = 0; h < files.length; h++) {
            const url = URL.createObjectURL(files[h]);
            image.push({ url, name: files[h].name })
        }

        this.setState({
            imageArray: image
        })

    }

    deleteFile = (image) => {
        const { file } = this.state;
        let newArray = [];
        for (let i = 0; i < file.length; i++) {
            if (file[i].name.toLowerCase() !== image.name.toLowerCase()) {
                newArray.push(file[i]);
            }
        }
        this.displayImageCreate(newArray);
        this.setState({
            file: newArray
        })
    }

    userSubmit = async (e) => {

        e.preventDefault();

        const { valid, file, url, title } = this.state;
        if (valid.title === false) {
            return notifyError('Portfolio title must be required!')
        }

        if (valid.url === false) {
            return notifyError('Portfolio URL must be required!')
        } else {
            const urlReg = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
            if (!urlReg.test(url)) {
                return notifyError('Portfolio URL not valid')
            }
        }

        // if (file === 0) {
        //     return notifyError('Portfolio Images must be required!')
        // }

        const data = {
            title:title, 
            url, 
            files:file
        }

        const res = await addPortFolio(data)
        if(res.status){
            notifySuccess(res.message)
            this.props.data.onHide();
            this.props.data.updateFunction();
        }else{
            notifyError(res.message);
        }
    }

    render() {

        const { show, onHide } = this.props.data;
        const { loader, title, url, imageArray, errors } = this.state;

        return (
            <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <h6 className="h5 pb-3 border-bottom">Add Portfolio</h6>
                    <form action="/" method="POST" onSubmit={this.userSubmit} encType="multipart/form-data">
                        <div className="row pb-4 pt-4">
                            <div className="col-sm-12">
                                <div className="form-group mb-4">
                                    <label>Title</label>
                                    <input type="text" name="title" className={errors.title !== "" ? "form-control error" : "form-control"} placeholder="Ex. Portfolio" onChange={this.inputhandleChange} value={title} />
                                    {errors.title !== "" ? <p className="error-text">{errors.title}</p> : null}
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group mb-4">
                                    <label>URL</label>
                                    <input type="url" name="url" className={errors.url !== '' ? "form-control error" : "form-control"} placeholder="Ex. https://www.portfolio.com" onChange={this.inputhandleChange} value={url} />
                                    {errors.url !== '' ? <p className="error-text">{errors.url}</p> : null}
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <div className="form-group mb-3">
                                    <label >Attachments</label>
                                    <div className={'file_input_wrap'}>
                                        <input id="attachments" multiple type="file" onChange={this.fileHandler} />
                                        <label className={'file_label2'} htmlFor="attachments"><span>Drag or <b>upload</b> Portfolio images</span></label>
                                    </div>
                                    {imageArray.length > 0 ?
                                        <ul className={'fileDisplay'}>
                                            {imageArray.map((file, i) =>
                                                <li key={i}>
                                                    <div className={'img_ul_wrap'}>
                                                        <img src={file.url} alt="uploaded" />
                                                    </div>
                                                    <div className={'image_overlay'}>
                                                        <button type="button" className={'delete_btns'} onClick={() => this.deleteFile(file)}><MdClear /></button>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-group pt-3 border-top d-flex justify-content-between">
                            <button type="button" className="btn_cancle btn btn-outline-dark" onClick={onHide}>Cancel</button>
                            <button type="submit" aria-label="Update" className="btn btn-primary min-200 max-200" disabled={loader}>{loader ? <SmallLoaderComponent /> : 'Add Portfolio'}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(connect(null)(AddPortfolio));