import React, { Component } from 'react'
// import { connect } from 'react-redux';
import socketClient from "socket.io-client";
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { MdClose, MdPerson, MdPowerSettingsNew, MdLock, MdSettings } from "react-icons/md";
// MdFormatListBulleted
import { NavLink, Link } from 'react-router-dom';
import { jwtDecode } from '../../helper/jwt.helper';
import Logo from '../../assets/images/vedain-logo.png';
import DummyUser from '../../assets/images/dummy-userImg.jpg';
import '../../assets/css/header.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFreelancerProfile } from '../../controller/profile.controller';
import * as ACTION_TYPES from '../../redux/actions/type';
import { CHAT_URL } from '../../controller/chat.controller';
import { notifyError } from '../../helper/Alert';
// import Image from 'next/image';

const SERVER = CHAT_URL;

class FreelancerHeader extends Component {

    _unmounted = false;

    socket;

    constructor(props) {
        super(props);
        this.state = {
            isMessage: false,
            isSiderBar: false,
            isActiveClass: false,
            isActiveMyJobClass: false,
            isSmallWindow: false,
            user: null,
            name: '',
            isImage: false,
            userImg: '',
            nameFirstCharacter: '',
            menuDisplay: true,
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.addEventListener('resize', this.checkWindowWidth)
        const token = localStorage.getItem('token');
        if (!localStorage.getItem('token')) {
            this.props.history.push('/login');
            return true;
        }



        if (token) {
            const decode = jwtDecode(token);
            if (decode) {
                if (this._unmounted) {
                    this.setState({
                        user: decode
                    })
                }
                if (parseInt(decode.profileType) === 2) return this.props.history.push('/client/my-jobs')
                this.userProfile();
                if (this._unmounted) {
                    this.setState({
                        menuDisplay: decode.status === 0 ? false : true
                    })
                }
                // if (Number(decode.status) === 0) { return this.props.history.push('/profile/email-verify'); }



                if (parseInt(decode.profileType) === 1) {
                    if (parseInt(decode.status) === 2) {
                        if (this._unmounted) {
                            this.setState({
                                menuDisplay: false
                            })
                        }
                        if (this.props.location.pathname !== '/freelancer/professional-info') {
                            return this.props.history.push('/freelancer/professional-info');
                        }
                    }
                }

                this.configureSocket(decode);
            }
        }

        if (this._unmounted) {

            this.setState({
                isSmallWindow: window.innerWidth < 991
            });

            this.activeClassCheckForJobs();
        }
    }

    componentWillUnmounted() {
        this._unmounted = false;
        window.removeEventListener('resize', this.checkWindowWidth)
    }

    configureSocket = (tokenDecode) => {
        var socket = socketClient(SERVER, {
            secure: true
        });

        const { location } = this.props;
        this.props.updateURLPath(location.pathname)

        socket.on('connection', () => {
            socket.emit("userOnlineEmit", { profileId: tokenDecode.id })
        });

        if (tokenDecode) {
            const data = {
                profile_id: tokenDecode.id
            }

            socket.emit('getunseenMessageCount', data);
        }
        if (location.pathname !== '/message') {
            socket.on('countOfUnreadMessageOfUser', data => {
                // console.log(data);
                this.props.updateNotifyCount(data.count);
                if (data.count && data.count > 0) {
                    this.props.updateNotifyBool(true);
                } else {
                    if (!this.props.isHeaderNotify) {

                        this.props.updateNotifyBool(false);
                    }
                }
            })

            socket.on('messageSeenNotifyEmit', data => {
                console.log('messageSeenNotifyEmit');
                if (data && data.length > 0) {
                    data.forEach(element => {
                        if (element.profile_id === tokenDecode.id) {
                            if (this._unmounted) {
                                this.setState({
                                    isMessage: true
                                })
                            }
                        }
                    })
                    // if(tokenDecode.id === )
                }
                // console.log(data);
            })

            socket.on('messageSeenMultyNotifyEmit', data => {
                console.log('messageSeenMultyNotifyEmit');
                console.log(data);
                if (data && data.length > 0) {
                    data.forEach(element => {
                        if (element.profile_id === tokenDecode.id) {
                            if (this._unmounted) {
                                this.setState({
                                    isMessage: false
                                })
                            }
                            this.props.updateNotifyBool(false);
                        }
                    })
                }
            });
        }

        this.socket = socket;
    }

    userProfile = async () => {

        const { history } = this.props;
        // stepHandler

        const response = await getFreelancerProfile();
        // console.log(response)
        const res = jwtDecode(response.data)
        localStorage.setItem('token', response.data);
        // console.log(res);
        if (response.status) {
            if (res) {
                this.props.updateUserData(res);
                let isImageAvailable = false;
                let imageUrl = '';

                this.props.updateUserName(res.name)
                const firstCharacter = res.name.charAt(0)
                if (res.profilePic) {
                    isImageAvailable = true;
                    const imgUrl = `${res.profileURL}${res.profilePic}`;
                    this.props.updateUserImage(imgUrl)
                    imageUrl = this.props.userImage
                } else {
                    isImageAvailable = false;
                    imageUrl = ''
                }

                if (this._unmounted) {
                    this.setState({
                        isSmallWindow: window.innerWidth < 991,
                        name: res.name,
                        isImage: isImageAvailable,
                        userImg: imageUrl,
                        nameFirstCharacter: firstCharacter,
                        path: res.profileURL
                    })
                }

                // console.log(res);
                // if(Number(res.status) === 0) this.props.history.push('/profile/email-verify')
                if (parseInt(res.profileType) === 1) {
                    // if(parseInt(res.status))
                    if(parseInt(res.status) === 1 || parseInt(res.status) === 3 || parseInt(res.status) === 4){
                        if (this.props.location.pathname === '/freelancer/professional-info') {
                            return history.push('/freelancer/jobs');
                        }
                    }

                    if (parseInt(res.status) === 2) {
                        if (this.props.location.pathname !== '/freelancer/professional-info') {
                            return history.push('/freelancer/professional-info');
                        }
                        // if ((!res.serviceName || Array.isArray(res.skills)) && res.skills.length === 0) {
                        //     // history.push('/freelancer/professional-info');
                        //     return stepHandler(0)

                        // } else if (!res.bio || !res.overview) {

                        //     return stepHandler(1)

                        // } else if (res.profilePic === null) {

                        //     return stepHandler(2)

                        // } else if (!res.name || !res.countryName || !res.statesName || !res.city || !res.mobile) {
                        //     console.log('step - 3')
                        //     return stepHandler(3)


                        // } else if (res.examTried === 0) {
                        //     return stepHandler(4)

                        // } else if (Number(res.status) === 3) {

                        //     return stepHandler(5)
                        // } else {
                        //     stepHandler(0);
                        //     return history.push('/freelancer/jobs');
                        // }
                    }
                }
            }
        } else {
            notifyError(response.message)
        }
    }

    activeClassCheckForJobs = () => {
        const pathname = this.props.location.pathname;
        // let isMenu = false;
        // if (pathname !== '/profile/email-verify') {
        //     isMenu = true;
        // }
        // if (this._unmounted) {
        //     this.setState({ isActiveClass: isMenu })

        // }
        if (this._unmounted) {
            switch (pathname) {
                case '/freelancer/jobs':
                    return this.setState({ isActiveClass: true })
                case '/freelancer/my-proposals':
                    return this.setState({ isActiveClass: true })
                case '/freelancer/saved-jobs':
                    return this.setState({ isActiveClass: true })
                case '/freelancer/my-jobs':
                    return this.setState({ isActiveMyJobClass: true })
                case '/freelancer/all-contracts':
                    return this.setState({ isActiveMyJobClass: true })
                case '/freelancer/professional-info':
                    return this.setState({ menuDisplay: false })
                case '/profile/email-verify':
                    return this.setState({ menuDisplay: false })
                default:
                    return this.setState({ isActiveClass: false, isActiveMyJobClass:false })
            }
        }
    }


    checkWindowWidth = () => {
        // console.log(window.innerWidth < 767);
        if (this._unmounted) {
            this.setState({
                isSmallWindow: window.innerWidth < 991
            })
        }
    }

    openSideBar = () => {
        // console.log('working...')
        if (this._unmounted) {
            this.setState({
                isSiderBar: true
            })
        }
    }

    closeSideBar = () => {
        if (this._unmounted) {
            this.setState({
                isSiderBar: false
            })
        }
    }

    logout = (e) => {
        e.preventDefault();
        if (this.state.user) {
            this.socket.emit("userOfflineEmit", { profileId: this.state.user.id })
        }
        this.socket.disconnect();
        localStorage.removeItem('token');
        localStorage.removeItem('chatID');
        this.props.history.push('/login');
    }

    dropLinkRedireact = (e, url) => {
        // this.props.history.push(url);
        // e.preventDefault();
    }
    render() {

        const { menuDisplay, isSiderBar, isSmallWindow, isActiveClass, isActiveMyJobClass, isImage, nameFirstCharacter } = this.state;

        return (
            <>
                <header className="header_client">
                    <Navbar bg="light" expand="sm" fixed="top" className="client_header">
                        <div className="container">
                            <Link to="/freelancer/jobs">
                                <Navbar.Brand className="logo-web">
                                    <img src={Logo} alt="Vedain" />
                                </Navbar.Brand>
                            </Link>
                            {/* <Link href="/client/dashboard"><Navbar.Brand href="/client/dashboard">Vedain</Navbar.Brand></Link> */}
                            {isSiderBar ?
                                <Navbar.Toggle aria-controls="basic-navbar-nav" className="close_icon" children={<MdClose />} onClick={this.closeSideBar} />
                                :
                                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.openSideBar} />
                            }
                            {isSmallWindow ?
                                isSiderBar ?
                                    <div className="navbar_collapse justify-content-end navbar-collapse collapse show">
                                        <div className="justify-content-end navbar-nav">
                                            {menuDisplay ?
                                                <>
                                                    <NavLink exact to="/freelancer/jobs" activeClassName={'active'} className="nav-link">Find jobs</NavLink>
                                                    <NavLink exact to="/freelancer/saved-jobs" activeClassName={'active'} className="nav-link">Saved jobs</NavLink>
                                                    <NavLink exact to="/freelancer/my-proposals" activeClassName={'active'} className="nav-link">Proposals</NavLink>
                                                    <NavLink exact to="/freelancer/my-jobs" activeClassName={'active'} className="nav-link">My jobs</NavLink>
                                                    <NavLink exact to="/freelancer/all-contracts" activeClassName={'active'} className="nav-link">All Contracts</NavLink>
                                                    <NavLink exact to="/message" activeClassName={'active'} className="nav-link position-relative">
                                                        Message
                                                        {this.props.isHeaderNotify ? <span className="alert_notification translate-middle bg-danger rounded-circle">
                                                            <span className="visually-hidden">New alerts</span>
                                                        </span> : null}
                                                    </NavLink>
                                                    {/* <NavLink exact to="/client/find-freelancer" activeClassName={'active'}><Nav.Link href="/client/find-freelancer">Find Freelancer</Nav.Link></NavLink> */}
                                                    <NavLink exact to="/freelancer/profile" activeClassName={'active'} className="nav-link">My Profile</NavLink>
                                                    <NavLink exact to="/freelancer/profile" activeClassName={'active'} className="nav-link">Settings</NavLink>
                                                    <NavLink exact to="/freelancer/change-password" activeClassName={'active'} className="nav-link">Change Password</NavLink>
                                                    <NavLink exact to="/payment/transactions" activeClassName={'active'} className="nav-link">Reports</NavLink>
                                                    {/* <NavLink exact to="/client/post-job" activeClassName={'active'}><Nav.Link href="/client/post-job" >Post Job</Nav.Link></NavLink> */}
                                                </>
                                                :
                                                null
                                            }
                                            <Link to="/" className="nav-link" onClick={this.logout}>Logout</Link>

                                        </div>
                                    </div>
                                    :
                                    null
                                // <Navbar.Collapse id="basic-navbar-nav" className={`navbar_collapse justify-content-end`}>
                                //     <Nav className="mr-auto" className="justify-content-end" style={{ width: '100%' }}>
                                //         <NavLink exact to="/client/jobs" activeClassName={'active'}><NavLink href="/client/jobs">Jobs</NavLink></NavLink>
                                //         <NavLink exact to="/client/dashboard" activeClassName={'active'}><Nav.Link href="/client/dashboard">Find Freelancer</Nav.Link></NavLink>
                                //         <NavLink exact to="/client/profile" activeClassName={'active'}><Nav.Link href="/client/profile">My Profile</Nav.Link></NavLink>
                                //         <NavLink exact to="/client/post-job" activeClassName={'active'}><Nav.Link href="/client/post-job" >Post Job</Nav.Link></NavLink>
                                //         <Link href="/" onClick={logout}><Nav.Link href="#/" >Logout</Nav.Link></Link>
                                //     </Nav>
                                // </Navbar.Collapse>
                                :

                                <Navbar.Collapse id="basic-navbar-nav" className={`navbar_collapse justify-content-end`}>
                                    <Nav className="mr-auto justify-content-end" style={{ width: '100%' }}>
                                        {menuDisplay ?
                                            <>
                                                <Nav.Item className={'dropdown_li'}>
                                                    <Link to="/freelancer/jobs" className={isActiveClass ? 'nav-link active' : 'nav-link'}>Find jobs</Link>
                                                    {/* <NavLink exact to="/freelancer/jobs" activeClassName={'active'}><NavLink href="/freelancer/jobs">Find jobs</NavLink></NavLink> */}
                                                    <ul className={`${'dropdown_menu'} dropdown-menu`}>
                                                        <li className="nav-item"><NavLink exact to="/freelancer/jobs" activeClassName={'active'} className="nav-link">Find jobs</NavLink></li>
                                                        <li className="nav-item"><NavLink exact to="/freelancer/saved-jobs" activeClassName={'active'} className="nav-link">Saved jobs</NavLink></li>
                                                        <li className="nav-item"><NavLink exact to="/freelancer/my-proposals" activeClassName={'active'} className="nav-link">Proposals</NavLink></li>
                                                    </ul>
                                                </Nav.Item>
                                                <Nav.Item className={'dropdown_li'}>
                                                    <Link to="/freelancer/my-jobs" className={isActiveMyJobClass ? 'nav-link active' : 'nav-link'}>My jobs</Link>
                                                    {/* <NavLink exact to="/freelancer/jobs" activeClassName={'active'}><NavLink href="/freelancer/jobs">Find jobs</NavLink></NavLink> */}
                                                    <ul className={`${'dropdown_menu'} dropdown-menu`}>
                                                        <li className="nav-item"><NavLink exact to="/freelancer/my-jobs" activeClassName={'active'} className="nav-link">My jobs</NavLink></li>
                                                        <li className="nav-item"><NavLink exact to="/freelancer/all-contracts" activeClassName={'active'} className="nav-link">All Contracts</NavLink></li>
                                                    </ul>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <NavLink exact to="/message" activeClassName={'active'} className="nav-link position-relative">
                                                        Message
                                                        {this.props.isHeaderNotify ? <span className="alert_notification translate-middle bg-danger rounded-circle">
                                                            <span className="visually-hidden">New alerts</span>
                                                        </span> : null}
                                                    </NavLink>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <NavLink exact to="/payment/transactions" activeClassName={'active'} className="nav-link">Reports</NavLink>
                                                </Nav.Item>
                                            </>
                                            :
                                            null
                                        }
                                        {/* <NavLink exact to="/client/find-freelancer" activeClassName={'active'}><Nav.Link href="/client/find-freelancer">Find Freelancer</Nav.Link></NavLink> */}

                                        {/* <NavLink exact to="/client/post-job" activeClassName={'active'}><Nav.Link href="/client/post-job" className="btn_pJob">Post Job</Nav.Link></NavLink> */}
                                        <Nav.Item>
                                            <NavDropdown className={"user_pro"} title={
                                                <UserLink name={this.props.userName} isImage={isImage} userImg={this.props.userImage} nameFirstCharacter={nameFirstCharacter} />} id="basic-nav-dropdown"
                                            >
                                                {menuDisplay ?
                                                    <>
                                                        <Link to="/freelancer/profile" className="dropdown-item"><MdPerson /> Profile</Link>
                                                        <Link to="/freelancer/change-password" className="dropdown-item"><MdLock /> Change Password</Link>
                                                        <Link to="/freelancer/settings" className="dropdown-item"><MdSettings /> Settings</Link>
                                                        {/* <NavDropdown.Item href="/freelancer/profile" onClick={(e) => this.dropLinkRedireact(e, "/freelancer/profile")}><MdPerson /> Profile</NavDropdown.Item> */}
                                                        {/* <NavDropdown.Item href="/freelancer/change-password" onClick={(e) => this.dropLinkRedireact(e, "/freelancer/change-password")}><MdLock /> Change Password</NavDropdown.Item> */}
                                                        {/* <Link to="/payment/transactions" className="dropdown-item"><MdFormatListBulleted /> Transaction history</Link> */}
                                                        <NavDropdown.Divider />
                                                    </>
                                                    :
                                                    null
                                                }
                                                <NavDropdown.Item href="#/" onClick={this.logout}><MdPowerSettingsNew /> Logout</NavDropdown.Item>
                                            </NavDropdown>
                                        </Nav.Item>
                                    </Nav>
                                </Navbar.Collapse>
                            }
                        </div>
                    </Navbar>
                    {/* <nav className="navbar navbar-nav navbar-light">
                    <div className="container">
                        <Link href="/"><a className={navbar_brand}>Logo</a></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link href="/client/dashboard"><a className="nav-link active" aria-current="page">Home</a></Link>
                                </li>
                                <li className="nav-item">
                                    <Link href="/"><a className="nav-link">Link</a></Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link href="/"><a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Dropdown</a></Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link href="/"><a className="dropdown-item">Action</a></Link></li>
                                        <li><Link href="/"><a className="dropdown-item">Another action</a></Link></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><Link href="/"><a className="dropdown-item">Something else here</a></Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav> */}
                </header>
                {isSiderBar ? <div className="overlay_sidebar" onClick={this.closeSideBar}></div> : null}
            </>
        )
    }
}

const mapStateToProps = state => ({
    userImage: state.user.userImage,
    userName: state.user.userName,
    isHeaderNotify: state.chat.isHeaderNotify
})

const mapDipatchToProps = dispatch => ({
    updateUserImage: (url) => dispatch({ type: ACTION_TYPES.USER_IMAGE_UPDATE, payload: url }),
    stepHandler: (step) => dispatch({ type: ACTION_TYPES.STEP_INFO_FREELANCER, payload: step }),
    updateUserName: (name) => dispatch({ type: ACTION_TYPES.UPDATE_USER_NAME, payload: name }),
    updateNotifyCount: (count) => dispatch({ type: ACTION_TYPES.HEADER_NOTIFICATION_COUNT, payload: count }),
    updateNotifyBool: (bool) => dispatch({ type: ACTION_TYPES.HEADER_NOTIFICATION_BOOL, payload: bool }),
    updateURLPath: (path) => dispatch({ type: ACTION_TYPES.URL_PATH_HTTPS, payload: path }),
    updateUserData:(data) =>dispatch({type:ACTION_TYPES.FREELANCER_STEPS_DATA, payload:data})
})

export default withRouter(connect(mapStateToProps, mapDipatchToProps)(FreelancerHeader))

const imageChange = (e) => {
    e.target.src = DummyUser
}

const UserLink = ({ isImage, name, userImg, nameFirstCharacter }) => {
    return <>
        {isImage ?
            <span className={'user_img'}>
                <img src={userImg} onError={imageChange} alt="user" />
            </span>
            :
            <span className={`${'user_img'} ${'character'}`}>
                {nameFirstCharacter}
            </span>
        }
        {name}
    </>
}

// class HeaderComponent extends Component {

//     _unmounted = false;

//     constructor(props) {
//         super(props);
//         this.state = {
//             isSiderBar: false,
//             isSmallWindow: false,
//             name: '',
//             isImage: false,
//             userImg: '',
//             nameFirstCharacter: ''
//         }

//         // this.wrapperRef = React.createRef();
//         // this.setWrapperRef = this.setWrapperRef.bind(this);
//         // this.handleClickOutside = this.handleClickOutside.bind(this);
//     }

//     componentDidMount() {
//         this._unmounted = true;
//         window.addEventListener('resize', this.checkWindowWidth)
//         const token = localStorage.getItem('token');
//         const { router } = this.props;

//         if (token) {
//             const decode = jwtDecode(token);
//             if (decode) {

//                 let isImageAvailable = false;
//                 let imageUrl = '';

//                 const firstCharacter = decode.name.charAt(0)
//                 if (decode.img) {
//                     isImageAvailable = true;
//                     imageUrl = decode.img
//                 } else {
//                     isImageAvailable = false;
//                     imageUrl = ''
//                 }

//                 this.setState({
//                     name: decode.name,
//                     nameFirstCharacter: firstCharacter,
//                     isImage: isImageAvailable,
//                     userImg: imageUrl,
//                 })
//             }
//         } else {
//             router.push('/login');
//         }
//     }

//     componentWillUnmount() {
//         this._unmounted = false;
//         window.removeEventListener('resize', this.checkWindowWidth)
//     }

//     // handleClickOutside(event) {
//     //     if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {

//     //     }
//     // }

//     checkWindowWidth = () => {

//         if (this._unmounted) {
//             console.log(window.innerWidth < 767);
//             this.setState({
//                 isSmallWindow: window.innerWidth < 767
//             })
//         }
//     }

//     openSideBar = () => {
//         console.log('working...')
//         this.setState(prevState => ({
//             isSiderBar: !prevState.isSiderBar
//         }))
//     }

//     logout = (e) => {
//         e.preventDefault();
//         localStorage.removeItem('token');
//         this.props.router.push('/login');
//     }

//     render() {

//         const { isSmallWindow, isSiderBar } = this.state;

//         return (
//             <header className="header_client">
//                 <Navbar bg="light" expand="sm" fixed="top" className="client_header">
//                     <div className="container">
//                         <NavLink exact to="/client/dashboard" activeClassName={'active'}>
//                             <Navbar.Brand className="logo-web" href="/client/dashboard">
//                                 <img src="/assets/images/vedain-logo.png" alt="Vedain" />
//                             </Navbar.Brand>
//                         </NavLink>
//                         {/* <Link href="/client/dashboard"><Navbar.Brand href="/client/dashboard">Vedain</Navbar.Brand></Link> */}
//                         <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.openSideBar} />
//                         {isSmallWindow ?

//                             isSiderBar && <Navbar.Collapse id="basic-navbar-nav" className={`navbar_collapse justify-content-end`}>
//                                 <Nav className="mr-auto" className="justify-content-end" style={{ width: '100%' }}>
//                                     <NavLink exact to="/client/jobs" activeClassName={'active'}><NavLink href="/client/jobs">Jobs</NavLink></NavLink>
//                                     <NavLink exact to="/client/dashboard" activeClassName={'active'}><Nav.Link href="/client/dashboard">Find Freelancer</Nav.Link></NavLink>
//                                     <NavLink exact to="/client/profile" activeClassName={'active'}><Nav.Link href="/client/profile">My Profile</Nav.Link></NavLink>
//                                     <NavLink exact to="/client/post-job" activeClassName={'active'}><Nav.Link href="/client/post-job" >Post Job</Nav.Link></NavLink>
//                                     <Link href="/" onClick={this.logout}><Nav.Link href="#/" >Logout</Nav.Link></Link>
//                                 </Nav>
//                             </Navbar.Collapse>
//                             :

//                             <Navbar.Collapse id="basic-navbar-nav" className={`navbar_collapse justify-content-end`}>
//                                 <Nav className="mr-auto" className="justify-content-end" style={{ width: '100%' }}>
//                                     <NavLink exact to="/client/jobs" activeClassName={'active'}><NavLink href="/client/jobs">Jobs</NavLink></NavLink>
//                                     <NavLink exact to="/client/dashboard" activeClassName={'active'}><Nav.Link href="/client/dashboard">Find Freelancer</Nav.Link></NavLink>

//                                     <NavLink exact to="/client/post-job" activeClassName={'active'}><Nav.Link href="/client/post-job" className="btn_pJob">Post Job</Nav.Link></NavLink>
//                                     <NavDropdown className={"user_pro"} title={<UserLink {...this.state} />} id="basic-nav-dropdown">
//                                         <Link href="/client/profile"><NavDropdown.Item href="/client/profile"><MdPerson /> Profile</NavDropdown.Item></Link>
//                                         <NavDropdown.Divider />
//                                         <NavDropdown.Item href="#/" onClick={this.logout}><MdPowerSettingsNew /> Logout</NavDropdown.Item>
//                                     </NavDropdown>

//                                 </Nav>
//                             </Navbar.Collapse>
//                         }
//                     </div>
//                 </Navbar>
//                 {/* <nav className="navbar navbar-nav navbar-light">
//                     <div className="container">
//                         <Link href="/"><a className={navbar_brand}>Logo</a></Link>
//                         <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//                             <span className="navbar-toggler-icon"></span>
//                         </button>
//                         <div className="collapse navbar-collapse" id="navbarSupportedContent">
//                             <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//                                 <li className="nav-item">
//                                     <Link href="/client/dashboard"><a className="nav-link active" aria-current="page">Home</a></Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link href="/"><a className="nav-link">Link</a></Link>
//                                 </li>
//                                 <li className="nav-item dropdown">
//                                     <Link href="/"><a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Dropdown</a></Link>
//                                     <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
//                                         <li><Link href="/"><a className="dropdown-item">Action</a></Link></li>
//                                         <li><Link href="/"><a className="dropdown-item">Another action</a></Link></li>
//                                         <li><hr className="dropdown-divider" /></li>
//                                         <li><Link href="/"><a className="dropdown-item">Something else here</a></Link></li>
//                                     </ul>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </nav> */}
//             </header>
//         )
//     }
// }

// export default withRouter(connect(null)(HeaderComponent));