import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter, Redirect } from 'react-router-dom'
import { MdKeyboardBackspace } from 'react-icons/md';
import '../../../assets/css/deposit.css';
import CardComponent from './CardComponent';
import { jwtDecode } from '../../../helper/jwt.helper';

class Deposit extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            jobId: 0,
            freelancerId: 0,
            amount: 0,
            milestoneAmount:0,
            feeAmount: 0,
            totalAmount: 0
        }
    }

    componentDidMount() {
        this._unmounted = true;
        const { match: { params: { jobid } }, location: { search } } = this.props;
        const searchQuery = new URLSearchParams(search);
        const freelancer_id = searchQuery.get('f_id');
        const amount = JSON.parse(localStorage.getItem('amount'));
        // console.log(amount);
        const fixed_amount = parseInt(amount.FixedAmount);
        const mileston_amount = parseInt(amount.milestonAmount);
        const fee_per = 0;
        const fee_amount = (mileston_amount * fee_per) / 100;
        // console.log(typeof fixed_amount)
        const total_amount = mileston_amount + fee_amount;
        // console.log()
        if (this._unmounted) {
            this.setState({
                jobId: jobid,
                freelancerId: freelancer_id,
                amount: fixed_amount,
                feeAmount: fee_amount,
                totalAmount: total_amount,
                milestoneAmount:mileston_amount
            })
        }
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    backUrl = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    }

    tabFunction = (tab) => {
        if (this._unmounted) {
            this.setState({
                activeTab: tab
            })
        }
    }

    render() {

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 1) {
                return <Redirect to="/freelancer/jobs" />
            }
        }

        const { match: { params: { jobid } }, location: { search } } = this.props;
        const searchQuery = new URLSearchParams(search);
        const freelancer_id = searchQuery.get('f_id');
        if (!jobid || !freelancer_id) {
            return <Redirect to='/client/my-jobs' />
        }
        // console.log(this.props);

        // const { activeTab } = this.state;

        return (
            <section className={`section_job_details`}>
                <div className="container">
                    <div className={"d-flex justify-content-between mb-4"}>
                        <Link to={'/'} onClick={this.backUrl} className={'icon_back'}><MdKeyboardBackspace /></Link>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card shadow bg-white rounded border-0">
                                <div className="card-body p-4">
                                    <h1><strong>Deposit Amount</strong></h1>
                                    {/* <ul className={`tabs_list md-4 mt-4`}>
                                        <li className={activeTab === 0 ? 'active' : ''}><button type="button" onClick={() => this.tabFunction(0)}>Card</button></li>
                                        <li className={activeTab === 1 ? 'active' : ''}><button type="button" onClick={() => this.tabFunction(1)}>Paypal</button></li>
                                    </ul> */}
                                    <div className="row mt-4">
                                        <div className="col-sm-6 border-end">
                                            <CardComponent milestonAmount={this.state.totalAmount} price={this.state.amount}  jobid={jobid} fid={freelancer_id} />
                                            {/* {activeTab === 0?<CardComponent />:null} */}
                                        </div>
                                        <div className="col-sm-6 ps-4">
                                            <h5 className="h5 fw-bold">Summary</h5>
                                            <div className="border p-3 mt-4 mb-3">
                                                <p className="d-flex justify-content-between mb-4"><strong>Job ID</strong><span className="text-primary"><b>{this.state.jobId}</b></span></p>
                                                <p className="d-flex justify-content-between mb-4"><strong>Fixed Amount</strong><span className="text-dark"><b>$ {this.state.amount}</b></span></p>
                                                <p className="d-flex justify-content-between mb-4"><strong>Milestone Amount</strong><span className="text-dark"><b>$ {this.state.milestoneAmount}</b></span></p>
                                                <p className="d-flex justify-content-between mb-4"><strong>Production Fee</strong><span className="text-dark"><b>$ {this.state.feeAmount}</b></span></p>
                                                <hr className="border border-3" />
                                                <p className="d-flex justify-content-between h3 mt-2 mb-0"><strong>Total</strong><span className="text-dark"><b>$ {this.state.totalAmount}</b></span></p>
                                            </div>
                                            {/* <p className="terms mb-3">All deposit and fees are refundable subject to our <Link to="/" className="text-primary">Terms and Conditions</Link></p> */}

                                        </div>
                                        {/* <div className="col-sm-12">
                                            <div className="d-flex align-items-center justify-content-end mt-5">
                                                <button type="button" className="btn btn-success" style={{ minWidth: '140px' }}>$121.2 Deposit</button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(connect(null)(Deposit))
