import { apiHelper } from "../helper/api.helper"
import { jwtDecode } from "../helper/jwt.helper"


export const mainCategories = async () => {

    const header = {
        'Authorization':localStorage.getItem('token')
    }

    const response = await apiHelper('categories/get', 'GET', null, header);
    const res = response.data;

    if(!res.status){
        return []
    }

    return jwtDecode(res.data);
    
}

export const subCategories = async (IDs) => {

    const header = {
        'Authorization':localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('categoryIDs', IDs)

    const response = await apiHelper('categories/sub/get', 'POST', formData, header);
    const res = response.data;

    if(!res.status){
        return []
    }

    return jwtDecode(res.data);
    
}