import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import '../../assets/css/common.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { notifyError, notifySuccess } from '../../helper/Alert';
import SmallLoaderComponent from '../../helper/SmallLoaderComponent';
import { register } from '../../controller/auth.controller';
import Image1 from '../../assets/images/airbnb.png'
import Image2 from '../../assets/images/motorola.png'
import Image3 from '../../assets/images/bridgestone.png'
import Image4 from '../../assets/images/shopify.png'
import Image5 from '../../assets/images/usc.png'

class SignupComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader:false,
            form: {
                fname: '',
                email: '',
                password: '',
                cpassword: '',
                typeWork: ''
            }
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    formSubmit = async (e) => {
        // try {

            const { fname, email, password, cpassword, typeWork } = e;

            this.setState({
                loader: true
            });

            const res = await register({ fname, email, password, cpassword, typeWork });

            if (res.status) {

                const { history } = this.props;
                notifySuccess(res.message);
                
                this.setState({
                    loader: false,
                    form: {
                        ...this.state.form,
                        email: '',
                        password: '',
                        fname:'',
                        typeWork:'',
                        cpassword:''
                    }
                });

                history.push('/login')

            } else {
                this.setState({
                    loader: false
                });
                notifyError(res.message);
            }

        // } catch (err) {
        //     console.error(err.message);
        // }
    }

    render() {

        const SignupSchema = Yup.object().shape({
            typeWork: Yup.string().required('Work Type must be required'),
            fname: Yup.string().required('Full Name must be required'),
            email: Yup.string().email('Invalid email').required('Email must be required'),
            password: Yup.string().required('Password must be required'),
            cpassword: Yup.string().oneOf([Yup.ref("password")], "Both password need to be the same").required('Confirm Password must be required')
            // cpassword: Yup.string().when("password", {
            //     is: val => (val && val.length > 0 ? true : false),
            //     then: Yup.string().oneOf(
            //         [Yup.ref("password")],
            //         "Both password need to be the same"
            //     )
            // }).required('Confirm Password must be required')
        });

        return (
            <>
                <section className={`authWrap`}>
                    <div className={`container width_776`}>
                        <div className="row">
                            <div className={`col-sm-12 m-auto reset_bg`}>
                                <div className="row">
                                    <div className="col-sm-12 mb-5">
                                        <div className={'headerAuth'}>
                                            <h1 className={'authTitle'}>Apply to Join<br />the World's Top Freelancers Network</h1>
                                            <p className={'subtitleLog'}>Freelancer is an exclusive network of the world's top talent in business, design, and technology.<br />We provide access to top companies, a community of experts, and resources that can help accelerate your career.</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-8 pr-0">
                                        <div className={'form_wrap'}>
                                            <Formik initialValues={this.state.form} validationSchema={SignupSchema} onSubmit={(values) => this.formSubmit(values)}>
                                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                                    <form action="/" method="POST" onSubmit={handleSubmit}>
                                                        <div className="form-group mb-4">
                                                            <div className={'typeJob'}>
                                                                <input type="radio" name="typeWork" value={2} id="client" onChange={handleChange} />
                                                                <label htmlFor="client" className={errors.typeWork && touched.typeWork ? 'error' : ""}>Hire for a project</label>
                                                                <input type="radio" name="typeWork" value={1} id="freelancer" onChange={handleChange} />
                                                                <label htmlFor="freelancer" className={errors.typeWork && touched.typeWork ? 'error' : ""}>Work as a Freelancer</label>
                                                            </div>
                                                            {errors.typeWork && touched.typeWork ? <p className="error-text">{errors.typeWork}</p> : null}
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <input type="text" name="fname" className={errors.fname && touched.fname ? "form-control error" : "form-control"} placeholder="Full Name" onChange={handleChange} onBlur={handleBlur} value={values.fname} />
                                                            {errors.fname && touched.fname ? <p className="error-text">{errors.fname}</p> : null}
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <input type="email" name="email" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                                            {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <input type="password" name="password" className={errors.password && touched.password ? "form-control error" : "form-control"} placeholder="Password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                                            {errors.password && touched.password ? <p className="error-text">{errors.password}</p> : null}
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <input type="password" name="cpassword" className={errors.cpassword && touched.cpassword ? "form-control error" : "form-control"} placeholder="Confirm password" onChange={handleChange} onBlur={handleBlur} value={values.cpassword} />
                                                            {errors.cpassword && touched.cpassword ? <p className="error-text">{errors.cpassword}</p> : null}
                                                        </div>
                                                        <div className="form-group mb-5">
                                                            <p className={'terms'}>You acknowledge that the Toptal screening process is confidential and that you will not publicly disclose details about this process. By submitting, you acknowledge that you have read and agreed to our <Link to="/">Terms and Conditions</Link>, <Link to="/">Privacy Policy</Link>, and <Link to="/">Cookie Policy</Link>.</p>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" aria-label="Apply to Join" className="btn btn-primary btn-block" disabled={this.state.loader}>{this.state.loader? <SmallLoaderComponent />:'Apply to Join'}</button>
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 pl-0">
                                        <div className={'supportWrap'}>
                                            <p className={'titleTip'}>Work with top companies</p>
                                            <ul className={'ulWrap'}>
                                                <li><img src={Image1} width='144' height='36' alt="Airbnb" /></li>
                                                <li><img src={Image2} alt="motorola" width="144" height="36" /></li>
                                                <li><img src={Image3} alt="bridgestone" width="144" height="36" /></li>
                                                <li><img src={Image4} alt="shopify" width="144" height="36" /></li>
                                                <li><img src={Image5} alt="usc" width="144" height="36" /></li>
                                                {/* <li><img src="/assets/images/cavaliers.png" alt="cavaliers" width="144" height="36" /></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(connect(null)(SignupComponent));