import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DummyImage from '../../assets/img/dummy-userImg.jpg';
import { FiEye } from 'react-icons/fi';
import DataTable from '../../components/DataTable';
import { getRefundList, updateRefundStatus } from '../../controller/contract.controller';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import { notifyError, notifySuccess } from '../../../helper/Alert';
// import { Button } from 'react-bootstrap';
// import Select from 'react-select';

class AdminRefundRequest extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            list: [],
            path: '',
            selected: 0
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);
        this.getRefundArray();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getRefundArray = async () => {
        if (this._unmounted) {
            this.setState({
                loader: true
            })
        }
        const res = await getRefundList();
        // console.log(res);
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    list: res.data,
                    // path: res.data.profileURL,
                    loader: false
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    list: [],
                    loader: false
                })
            }
        }
    }

    pageHandler = (page, sizePerPage) => {
        console.log(page);
    }

    sizePerPageChange = (sizePerPage, page) => {
        console.log(sizePerPage);
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Table is Empty</p>
        </div>
    }

    loaderTd = () => {
        return <div className="d-flex justify-content-center align-items-center">
            <SmallLoaderComponent color="black" />
        </div>
    }

    headerCenterFormatter = (column) => {
        return <div className="d-flex justify-content-center">{column.text}</div>
    }

    statusHandler = (e, row) => {
        const { value } = e.target;
        const { list } = this.state;
        // console.log(id)
        // document.getElementById(id).disabled = true;
        if (Number(value) === 2 || Number(value) === 3) {
            updateRefundStatus(row.id, Number(value), row.milestoneID)
                .then(res => {
                    // console.log(res);
                    if (res.status) {
                        if (row && list && list.length > 0) {

                            const map = list.filter(info => info.id !== row.id)

                            this.setState({
                                list: [],
                                list: map,
                                selected: value
                            })
                        }
                        notifySuccess(res.message)
                        // document.getElementById(id).disabled = true;
                    } else {
                        // document.getElementById(id).disabled = false;
                        notifyError(res.message);
                    }
                })
                .catch(err => {
                    // document.getElementById(id).disabled = false;
                    console.log(err);

                })
        } else {

            console.log(row);
            // console.log(typeof value);
        }

    }

    statusFormatter = (cell, row) => {
        // console.log(row);
        const options = [
            { label: 'Approved', value: 3 },
            { label: 'Cancelled', value: 2 }
        ]

        // const selected = this.state.list.find(info=>)

        return <div className="d-flex justify-content-center">
            <select className="select-form warning" id={`status${row.id}`} selected={cell} onChange={(e) => this.statusHandler(e, row)}>
                <option value={0}>{'Select'}</option>
                {options.map((info, i) => <option key={i} value={info.value} selected={info.value === cell}>{info.label}</option>)}
            </select>
        </div>
    }

    errorImage = (e) => {
        e.target.src = DummyImage
    }

    nameFormatter = (cell, row) => {
        return <div className="d-flex align-items-center">
            {row.profilePic ?
                <img className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} src={`${this.state.path}${row.profilePic}`} onError={this.errorImage} alt="freelancer" />
                :
                <img className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} src={DummyImage} alt="freelancer" />
            }
            <span>{row.name}</span>
        </div>
    }

    amountFormatter = (cell, row) => {
        let dollarUSLocale = Intl.NumberFormat('en-US')
        return `$ ${dollarUSLocale.format(cell)}`
    }

    actionFormatter = (cell, row) => {
        return <div className="d-flex justify-content-center">
            <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/admin/contract/${row.id}`)}><FiEye /></button>
        </div>
    }

    render() {

        const columns = [
            {
                dataField: 'title',
                text: 'Job Title',
                sort: true,
                // formatter: this.nameFormatter
            },
            {
                dataField: 'clientName',
                text: 'Client',
                sort: true,
                // formatter: this.nameFormatter
            },
            {
                dataField: 'freelancerName',
                text: 'Freelancer',
                sort: true,
                // formatter: this.nameFormatter
            },
            {
                dataField: 'reason',
                text: 'Reason',
                sort: true,
                // formatter: this.nameFormatter
            },
            {
                dataField: 'amount',
                text: 'Refund Amount',
                sort: true,
                formatter: this.amountFormatter
            },
            {
                dataField: 'status',
                text: 'Status',
                sort: true,
                formatter: this.statusFormatter,
                headerFormatter: this.headerCenterFormatter
            }
        ];

        // console.log(this.state.list);

        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3"><strong>Refund Requests</strong></h1>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <DataTable
                                keyField="id"
                                data={this.state.list}
                                columns={columns}
                                // onSizePerPageChange={this.sizePerPageChange} 
                                // onPageChange={this.pageHandler} 
                                noDataIndication={this.state.loader ? this.loaderTd : this.emptyRender} />

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null)(AdminRefundRequest))
