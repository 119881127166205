import { apiHelper } from "../helper/api.helper";

export const login = async (data) => {

    const { email, password } = data;

    let formData = new FormData();
    formData.append('username', email)
    formData.append('password', password)

    const response = await apiHelper('auth/login', 'POST', formData, null);
    return response.data;
}

export const register = async (data) => {

    const { fname, email, password, cpassword, typeWork } = data;

    let formData = new FormData();
    formData.append('name', fname)
    formData.append('profileType', Number(typeWork))
    formData.append('email', email)
    formData.append('password', password)
    formData.append('confirmPassword', cpassword)

    const response = await apiHelper('auth/register', 'POST', formData, null);
    return response.data;
}

export const forgotPassword = async (data) => {

    const { email } = data;

    let formData = new FormData();
    formData.append('email', email)

    const response = await apiHelper('auth/password/reset', 'POST', formData, null);
    return response.data;
}

export const newPassword = async (data) => {

    const { email, password, cpassword, passwordToken } = data;

    let formData = new FormData();
    formData.append('email', email)
    formData.append('password', password)
    formData.append('confirmPassword', cpassword)
    formData.append('passwordToken', passwordToken)

    const response = await apiHelper('auth/password/resetNewPassword', 'POST', formData, null);
    return response.data;
}

export const changePassword = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const { currentPassword, confirmPassword, newPassword } = data;

    let formData = new FormData();
    formData.append('currentPassword', currentPassword)
    formData.append('newPassword', newPassword)
    formData.append('confirmPassword', confirmPassword)

    const response = await apiHelper('auth/password/change', 'PUT', formData, header);
    return response.data;
}