import { apiHelper } from "../helper/api.helper"
import { jwtDecode } from "../helper/jwt.helper"

export const getExam = async (ids) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }


        const response = await apiHelper(`freelancer/exam/get/${JSON.stringify(ids)}`, 'GET', null, header);
        const res = response.data;

        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, data: [], message: err.message }
    }

}

export const getExamResult = async (data) => {
    try {

        const { examArray, time, categoryID } = data;
        // console.log(data);

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        let formData = new FormData();
        formData.append('resultdata', JSON.stringify(examArray))
        formData.append('categoryID', JSON.stringify(categoryID))
        formData.append('examTime', time)

        const response = await apiHelper(`freelancer/exam/result/get`, 'POST', formData, header);
        const res = response.data;

        if (!res.status) {
            return { status: false, data: null, message: res.message }
        }

        return { status: true, data: jwtDecode(res.data), message: res.message };
        
    }catch(err){
        return { status: false, data: null, message: err.message }
    }

}

// freelancer add new skills result api
export const getNewSkillsExamResult = async (data) => {
    try {

        const { examArray, time, categoryID } = data;
        // console.log(data);

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        let formData = new FormData();
        formData.append('resultdata', JSON.stringify(examArray))
        formData.append('categoryID', JSON.stringify(categoryID))
        formData.append('examTime', time)

        const response = await apiHelper(`freelancer/exam-result/add-skill`, 'POST', formData, header);
        const res = response.data;

        if (!res.status) {
            return { status: false, data: null, message: res.message }
        }

        return { status: true, data: jwtDecode(res.data), message: res.message };
        
    }catch(err){
        return { status: false, data: null, message: err.message }
    }

}