import { apiHelper } from "../../helper/api.helper";
import { jwtDecode } from "../../helper/jwt.helper";

export const getFreelancers = async () => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }


    const response = await apiHelper('admin/freelancer/get', 'GET', null, header);
    const res =  response.data;
    if(res.status === false){
        return []
    }
    
    return jwtDecode(res.data);
}

export const updateFreelancersStatus = async (data) => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const { userID, status } = data;

    let formData = new FormData();
    formData.append('userID', userID)
    formData.append('status', status)

    const response = await apiHelper('admin/profile/status/update', 'PUT', formData, header);
    // const res =  response.data;
    
    return response.data;
}