import { jwtDecode } from "../helper/jwt.helper";
import axios from 'axios';

// export const CHAT_URL = 'http://43d139315231.ngrok.io/';
export const CHAT_URL = 'https://api.vedain.com';

export const getChannels = async () => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await axios({
            url: CHAT_URL + '/conversations/all',
            method: 'GET',
            data: null,
            headers: header
        });

        const res = response.data;
        // console.log(res);

        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, data: [], message: err.message };
    }

}

export const getSingleChat = async (id) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await axios({
            url: CHAT_URL + `/conversations/${id}`,
            method: 'GET',
            data: null,
            headers: header
        });

        const res = response.data;

        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, data: [], message: err.message }
    }

}

export const createChat = async (id) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('otherprofileId', id);

    const response = await axios({
        url: CHAT_URL + `/conversation/createConversation`,
        method: 'POST',
        data: formData,
        headers: header
    });

    const res = response.data;
    // console.log(res);

    if (!res.status) {
        return { status: false, data: [], message: res.message }
    }

    return { status: true, data: jwtDecode(res.data), message: res.message };

}

export const getHistoryMessage = async (data) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        let formData = new FormData();
        formData.append('conversationId', data.id);
        if (data.lastChatId) { formData.append('lastChatId', data.lastChatId); }


        const response = await axios({
            url: CHAT_URL + `/messages/history`,
            method: 'POST',
            data: formData,
            headers: header
        });

        const res = response.data;
        // console.log(res);

        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: jwtDecode(res.data), message: res.message };
    }catch(err){
        return { status: false, data: [], message: err.message }
    }

}

export const memberForAll = async () => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await axios({
        url: CHAT_URL + `/conversation/getAllMembers`,
        method: 'GET',
        data: null,
        headers: header
    });

    const res = response.data;
    // console.log(res);

    if (!res.status) {
        return { status: false, data: [], message: res.message }
    }

    return { status: true, data: jwtDecode(res.data), message: res.message };

}

export const memberForAdd = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('conversationId', data.conversationId);
    formData.append('profileIdForAdd', data.profileForAddID);
    // formData.append('profileId', data.profileId);

    const response = await axios({
        url: CHAT_URL + `/conversation/addMember`,
        method: 'POST',
        data: formData,
        headers: header
    });

    const res = response.data;
    // console.log(res);

    if (!res.status) {
        return { status: false, data: [], message: res.message }
    }

    return { status: true, data: jwtDecode(res.data), message: res.message };

}

export const createGroupForMembers = async (ids) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    // formData.append('conversationId', data.conversationId);
    formData.append('otherprofileIds', ids);
    // formData.append('profileId', data.profileId);

    const response = await axios({
        url: CHAT_URL + `/conversation/createGroupConversation`,
        method: 'POST',
        data: formData,
        headers: header
    });

    const res = response.data;
    // console.log(res);

    if (!res.status) {
        return { status: false, data: [], message: res.message }
    }

    return { status: true, data: jwtDecode(res.data), message: res.message };

}

export const removeMemberForGroup = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('conversationId', data.conversationId);
    formData.append('deleteProfileId', data.id);
    // formData.append('profileId', data.profileId);

    const response = await axios({
        url: CHAT_URL + `/conversation/remveMemberFromConversation`,
        method: 'POST',
        data: formData,
        headers: header
    });

    const res = response.data;
    // console.log(res);

    if (!res.status) {
        return { status: false, data: [], message: res.message }
    }

    return { status: true, data: jwtDecode(res.data), message: res.message };

}

export const fileUploading = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('message', data.message);
    formData.append('conversationId', data.conversationId);
    formData.append('from', data.from);
    for (let i = 0; i < data.file.length; i++) {
        formData.append('file', data.file[i]);
    }

    const response = await axios({
        url: CHAT_URL + `/chat/sendMessageFiles`,
        method: 'POST',
        data: formData,
        headers: header
    });

    const res = response.data;
    // console.log(res);

    if (!res.status) {
        return { status: false, data: [], message: res.message }
    }

    return { status: true, data: jwtDecode(res.data), message: res.message };

}

export const DeleteSingleFile = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('fileID', data.fileID);
    formData.append('messageID', data.messageID);
    formData.append('senderID', data.senderID);

    const response = await axios({
        url: CHAT_URL + `/conversations/deleteFiles`,
        method: 'PUT',
        data: formData,
        headers: header
    });

    const res = response.data;
    // console.log(res);

    if (!res.status) {
        return { status: false, data: [], message: res.message }
    }

    return { status: true, data: jwtDecode(res.data), message: res.message };

}

export const DeleteSingleMessage = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('conversationID', data.conversationID);
    formData.append('messageID', data.messageID);
    formData.append('senderID', data.senderID);

    const response = await axios({
        url: CHAT_URL + `/conversations/deleteMessage`,
        method: 'PUT',
        data: formData,
        headers: header
    });

    const res = response.data;
    // console.log(res);

    if (!res.status) {
        return { status: false, data: [], message: res.message }
    }

    return { status: true, data: jwtDecode(res.data), message: res.message };

}