import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { MdAdd } from 'react-icons/md'
import { FiEdit2, FiTrash } from 'react-icons/fi'
import * as ACTION_TYPES from '../../../redux/actions/type';
import AddEmploymentModal from '../../../components/EmploymentComponent/AddEmploymentModal';
import EditEmploymentModal from '../../../components/EmploymentComponent/EditEmploymentModal';
import { deleteExistEmployment, getEmployment } from '../../../controller/profile.controller';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { monthOption } from '../../../helper/array.helper';
import ConfirmModal from '../../../components/confirmModal';

class Employment extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isUpdate: false,
            employmentList: [],
            addEmploymentModal: false,
            editEmploymentModal: false,
            deleteEmploymentModal: false,
            singleData: null,
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getEmploymentList();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        const { isUpdate } = this.state;
        if (prevState.isUpdate !== isUpdate) {
            if (isUpdate) {
                this.getEmploymentList();
            }
        }
    }

    isUpdateFunction = (bool) => {
        if (this._unmounted) {
            this.setState({
                isUpdate: bool
            });
        }
    }

    getEmploymentList = async () => {
        const res = await getEmployment();
        // console.log(res);
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    employmentList: res.data
                });
                this.isUpdateFunction(false);
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    employmentList: []
                })
            }
        }
    }

    openAddEmploymentModal = () => {
        this.setState({
            addEmploymentModal: true
        })
    }

    closeAddEmploymentModal = () => {
        this.setState({
            addEmploymentModal: false
        })
    }

    openEditEmploymentModal = (row) => {
        this.setState({
            editEmploymentModal: true,
            singleData: row
        })
    }

    closeEditEmploymentModal = () => {
        this.setState({
            editEmploymentModal: false,
            singleData: null
        })
    }

    openDeleteEmploymentModal = (row) => {
        this.setState({
            deleteEmploymentModal: true,
            singleData: row
        })
    }

    closeDeleteModal = () => {
        this.setState({
            deleteEmploymentModal: false,
            singleData: null
        })
    }

    submitDeleteEmployment = async () => {
        const { singleData } = this.state;
        const res = await deleteExistEmployment(singleData.id);
        if (res.status) {
            this.isUpdateFunction(true);
            this.closeDeleteModal();
            notifySuccess(res.message);
        } else {
            notifyError(res.message);
        }
    }

    nextHandler = async () => {
        const { activeStep, stepHandler, isUpdateProfile } = this.props;
        const { employmentList } = this.state;
        if (employmentList && employmentList.length === 0) {
            return notifyError('Please add your last exprince');
        }
        stepHandler(activeStep + 1);
        isUpdateProfile(true)
    }

    renderList = (info, i) => {
        const monthObj = monthOption.find(month => month.value === info.startMonth)
        const endMonthObj = monthOption.find(month => month.value === info.endMonth)
        // console.log()
        return <li className="list-group-item p-3 mb-3" key={i}>

            <div className="row align-items-center">
                <div className="col-sm-10 col-xs-12">
                    <h6 className="fs-4 fw-bold mb-2">{info.title ? `${info.title}` : ''}</h6>
                    <p className="mb-0" style={{ fontSize: '13px', opacity: '0.7' }}>
                        {parseInt(info.present) === 1 ?
                            `${monthObj?.label} ${info.startYear} - Present`
                            :
                            `${monthObj?.label} ${info.startYear} - ${endMonthObj?.label} ${info.endYear}`
                        }
                    </p>
                    <p className="mb-2" style={{ fontSize: '13px', opacity: '1' }}>{info.company}</p>
                    {info.description ? <p className="mb-0 mw-100" style={{ fontSize: '13px', opacity: '1', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{info.description}</p> : null}
                </div>
                <div className="col-sm-2 col-xs-12" style={{ textAlign: 'right' }}>
                    <button type="button" className="bg-transparent border-0 text-secondary" onClick={() => this.openEditEmploymentModal(info)}><FiEdit2 /></button>
                    <button type="button" className="bg-transparent border-0 text-danger" onClick={() => this.openDeleteEmploymentModal(info)}><FiTrash /></button>
                </div>
            </div>
        </li>
    }

    render() {

        const { addEmploymentModal, employmentList, editEmploymentModal } = this.state;

        return (
            <>
                <div className={'tabs_wrap'}>
                    <div className={'tab_header'}>
                        <h1 className={'tabs_title'}>{`Employment`}</h1>
                    </div>
                    <div className={'tab_body pb-5'}>
                        {employmentList.length === 0 ?
                            <p className="o-75 mb-5">Add your past work experince
                                <span className="d-block" style={{ fontSize: '12px', opacity: '0.7' }}>Build your credibility by showcase the project or jobs you have completed.</span>
                            </p>
                            :
                            null
                        }
                        {employmentList && employmentList.length > 0 ?
                            <ul className="list-group">
                                {employmentList.map((info, i) => this.renderList(info, i))}
                            </ul>
                            :
                            null
                        }
                        <button type="button" onClick={this.openAddEmploymentModal} className="btn btn-outline-primary d-flex align-items-center lh-base"><MdAdd className="me-2" /> Add Employment</button>
                    </div>

                    <div className={'tab_footer'}>
                        <button type="button" className={'btn btn-dark'} style={{ minWidth: '100px' }} onClick={this.nextHandler}>Next</button>
                        {this.props.activeStep > 0 ? <button type="button" className="btn btn-outline-dark" style={{ minWidth: '100px' }} onClick={this.prevHandler}>Back</button> : null}
                    </div>

                </div>
                {addEmploymentModal ?
                    <AddEmploymentModal show={addEmploymentModal} onHide={this.closeAddEmploymentModal} onSubmit={this.isUpdateFunction} />
                    :
                    null
                }

                {editEmploymentModal ?
                    <EditEmploymentModal show={editEmploymentModal} onHide={this.closeEditEmploymentModal} data={this.state.singleData} onSubmit={this.isUpdateFunction} />
                    :
                    null
                }

                {this.state.deleteEmploymentModal ?
                    <ConfirmModal
                        text={'Are you sure you want to delete this employment?'}
                        show={this.state.deleteEmploymentModal}
                        onSubmit={this.submitDeleteEmployment}
                        onHide={this.closeDeleteModal}
                    />
                    :
                    null
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    contactDetails: state.pinfo.contactDetails,
    activeStep: state.pinfo.activeStep,
    isGetProfile: state.pinfo.isGetProfile,
    userImage: state.user.userImage
});

const mapDispatchToProps = dispatch => ({
    stepHandler: (val) => dispatch({ type: ACTION_TYPES.STEP_INFO_FREELANCER, payload: val }),
    contactDetailFunc: (obj) => dispatch({ type: ACTION_TYPES.CONTACT_DETAILS, payload: obj }),
    isUpdateProfile: (bool) => dispatch({ type: ACTION_TYPES.UPDATE_FREELANCER_PROFILE, payload: bool }),
    isCallProfile: (bool) => dispatch({ type: ACTION_TYPES.PROFILE_SETP_API_GET, payload: bool })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Employment));
