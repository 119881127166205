import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import { MdClose } from 'react-icons/md';
import Modal from 'react-bootstrap/Modal';
import '../../../assets/css/jobDetails.css';
import DummyUser from '../../../assets/images/dummy-user.jpg'
import { MdLocationOn } from 'react-icons/md';

function FreelancerSection({ freelancers, profilePath }) {

    const [modaldeposit, setModaldeposit] = useState(false)
    const [modalContract, setModalContract] = useState(false)

    const openDespositModal = () => {
        setModaldeposit(true)
    }
    const closeDespositModal = () => {
        setModaldeposit(false)
    }

    const openContractModal = () => {
        setModalContract(true)
    }
    const closeContractModal = () => {
        setModalContract(false)
    }

    const depositModalData = {
        show: modaldeposit,
        onHide: closeDespositModal
    }

    const cancleContractModalData = {
        show: modalContract,
        onHide: closeContractModal
    }

    return (
        <ul className={`freelancer_list_wrap pb-4 pt-5`}>
            {freelancers && freelancers.length > 0 ?
                freelancers.map((info, i) =>
                    <li key={i}>

                        <div className={'user_prof'}>
                            {info?.profilePic && profilePath ?
                                <img src={`${profilePath}${info.profilePic}`} alt="freelancer" />
                                :
                                <img src={DummyUser} alt="freelancer" />
                            }
                        </div>
                        <div className={'right_detail'}>
                            <div className={'profile_wrap'}>
                                <div className={'caption'}>
                                    <h5 className={'review_title'}>{info?.name}</h5>
                                    <div className={'user_r_detail'}>
                                        <i className={'location_icon me-1 text-dark fs-4'}><MdLocationOn /></i>
                                        {/* <img className={'flag'} src={DummyUser} alt="India" /> */}
                                        <p>{info?.countryName}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'detail'}>
                                <p>{info?.proposal}</p>
                            </div>
                        </div>

                    </li>
                )
                :
                null
            }
        </ul>
    )
}

export default FreelancerSection

function CancelContractModal({ data }) {
    return (
        <Modal show={data.show} style={{ fontFamily: 'Noto Sans JP, sans-serif' }} dialogClassName={'modal_closeJob'} onHide={data.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body style={{ textAlign: 'center' }}>
                <h4 style={{ padding: '50px 0 50px', margin: '0', fontWeight: '600', fontSize: '20px' }}>Do You want to cancel this freelancer from this job?</h4>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <button type="button" className={"btn btn-outline-dark"} style={{ minWidth: '100px' }} onClick={data.onHide}>Cancel</button>
                <button type="button" className={"btn btn-primary"} style={{ minWidth: '100px' }}>Confirm</button>
            </Modal.Footer>
        </Modal>
    )
}

function DepositModal({ data }) {
    return (
        <Modal show={data.show} style={{ fontFamily: 'Noto Sans JP, sans-serif' }} dialogClassName={'modal_hire'} onHide={data.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title style={{ fontSize: '20px', fontFamily: 'Noto Sans JP' }}>Deposit Payment</Modal.Title>
                <button type="button" onClick={data.onHide} style={{ background: 'transparent', border: '0', padding: '0', fontSize: '22px' }}><i><MdClose /></i></button>
            </Modal.Header>
            <Modal.Body className={'hire_modal_body'}>
                <div className={'profile_blo'}>
                    <div className={'pro_wra'}>
                        <div className={'profile_img'}>
                            <img src={DummyUser} alt="freelancer" />
                        </div>
                        <h5 className={'review_title'} style={{ marginBottom: '0', color: '#000', fontSize: '20px' }}>You've Hired deepak chitte</h5>
                    </div>
                    <p className={'price'}>
                        <span className={'pric'}>$1,254.00 / <small style={{ color: '#898989' }}>Project</small></span>
                        {/* <span className={deadline}>2 Days</span> */}
                    </p>
                </div>
                <div className={'m_payment'}>
                    <h5>Milestone Payment</h5>
                    <div className={'amt'}>
                        <b><small>$</small> 0</b>
                        <span>Deposit</span>
                    </div>
                    <div className={'amt'}>
                        <b><small>$</small> 0</b>
                        <span>Released</span>
                    </div>
                </div>
                <ul className={'deposit_div'}>
                    <li>Deposit is securely held by <a href="https://vedain.com/" rel="noopener noreferrer" target="_blank">Vedain.com</a></li>
                    <li>Once satisfied, pay <span style={{ color: 'rgb(26, 98, 245)' }}>deep123</span> by releasing Deposit</li>
                </ul>
                <div className={'gru_btn'}>
                    <p>All deposit and fees are refundable subject to our <Link to="/">Terms and Conditions</Link></p>
                    <button type="button" className={"btn btn-success"} style={{ minWidth: '160px' }}>$0 Deposit</button>
                </div>
            </Modal.Body>
        </Modal >
    )
}
