import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import * as ACTION_TYPES from '../../../redux/actions/admin/type';
import { mainCategories, updateCategories } from '../../controller/categories.controller';

class UpdateServiceModal extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            qLoader: false,
            mainServices: [],
            selectedService: null,
            serviceName: '',
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getMainCategories();

        if(this.props.data){
            if(this._unmounted){
                this.setState({
                    serviceName:this.props.data.categoryName
                })
            }
        }
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getMainCategories = async () => {
        const res = await mainCategories();
        if (Array.isArray(res) && res.length > 0) {
            // console.log(res);
            const options = res.map(info => ({ label: info.categoryName, value: info.id }))

            const selected = this.props.data?options.filter(info=>info.value === this.props.data.parentCategoryID):[];
            if (this._unmounted) {
                this.setState({
                    mainServices: options,
                    selectedService:selected.length > 0?selected[0]:null
                })
            }
        } else {
            this.setState({
                mainServices: []
            })
        }
    }

    serviceHandler = (selectedOption) => {
        if (this._unmounted) {
            this.setState({
                selectedService: selectedOption
            })
        }
    }

    inputHandler = (e) => {
        const { value } = e.target;
        if (this._unmounted) {
            this.setState({
                serviceName: value
            })
        }
    }

    formSubmit = async (e) => {
        // console.log(e);
        e.preventDefault();

        const { selectedService, serviceName } = this.state;

        if (serviceName === '') {
            return notifyError('Service Name must be required!')
        }

        if (this._unmounted) {
            this.setState({
                qLoader: true
            });
        }

        const res = await updateCategories({ categoryID:this.props.data.id, mainService: selectedService, serviceName, })
        if (res.status) {
            this.props.reloadApi(true);
            this.props.onHide();
            notifySuccess(res.message);
            if (this._unmounted) {
                this.setState({
                    qLoader: false,
                    selectedService: null,
                    serviceName: ''

                });
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    qLoader: false
                });
            }
            notifyError(res.message);
        }

        // console.log('submit question...')
    }


    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header>
                    <Modal.Title>Add Service</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form action="/" method="POST" onSubmit={this.formSubmit}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Main Services</label>
                                    <Select name="service" placeholder="Select service" options={this.state.mainServices} onChange={this.serviceHandler} value={this.state.selectedService} />

                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Service Name</label>
                                    <input type="text" name="serviceName" className="form-control" placeholder="Enter service name" onChange={this.inputHandler} value={this.state.serviceName} />

                                </div>
                            </div>

                        </div>
                        <div className="form-group d-flex justify-content-end border-top pt-4">
                            <button type="button" className="btn btn-secondary border-0 me-2" onClick={this.props.onHide}>Close</button>
                            <button type="submit" aria-label="Log in" className="btn btn-success" disabled={this.state.qLoader}>{this.state.qLoader ? <SmallLoaderComponent /> : 'Submit'}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    reloadApi: (bool) => dispatch({ type: ACTION_TYPES.RELOAD_GET_CATEGORIES, payload: bool })
})

export default withRouter(connect(null, mapDispatchToProps)(UpdateServiceModal));
