import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

class ConfirmModal extends Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} size="sm" className="confirm-modal" aria-labelledby="contained-modal-title-vcenter" centered >
                <div className="confirm-wrap p-4">
                    <h3 className="fw-bold fs-4 mt-4 mb-5 text-center lh-lg">{this.props.text}</h3>
                    <div className="d-flex justify-content-between">
                        <button type="button" className="btn btn-transparent fw-bold rounded-pill" style={{minWidth:'75px'}} onClick={this.props.onHide}>Cancel</button>
                        <button type="button" className="btn btn-danger rounded-pill" style={{minWidth:'75px'}} onClick={this.props.onSubmit}>Ok</button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withRouter(connect(null)(ConfirmModal))
