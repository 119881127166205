import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FiX } from 'react-icons/fi';
import { notifyError } from '../../../helper/Alert';
import { FormGroup, FormLabel, Row, Col } from 'react-bootstrap';

class EditAvailabilityModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            days: [],
            startTime: '',
            endTime: ''
        }
    }

    componentDidMount() {
        const { data } = this.props;
        // console.log(data?.day.split(',')?.map(x=>parseInt(x)));
        if (data) {
            this.setState({
                days: data?.day.split(',')?.map(x=>parseInt(x)),
                startTime: data.startTime,
                endTime: data.endTime
            })
        }
    }

    daysHandler = (info) => {
        let day = this.state.days;
        if (day.includes(info.value)) {
            day = day.filter(item => item !== info.value)
        } else {
            day.push(info.value)
        }
        // console.log(day.sort((a, b) => a - b))
        this.setState({
            days: day.sort((a, b) => a - b)
        })
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    submit = (e) => {
        const { days, startTime, endTime } = this.state;
        if (days.length === 0) {
            return notifyError('Please select Working Days')
        }

        if (startTime.length === 0) {
            return notifyError('Please select start time')
        }
        if (endTime.length === 0) {
            return notifyError('Please select end time')
        }

        const end = endTime.split(":");
        const start = startTime.split(":");
        var timefrom = new Date();
        timefrom.setHours((parseInt(start[0]) - 1 + 24) % 24);
        timefrom.setMinutes(parseInt(start[1]));

        var timeto = new Date();
        timeto.setHours((parseInt(end[0]) - 1 + 24) % 24);
        timeto.setMinutes(parseInt(end[1]));

        if (timeto < timefrom) {
            return notifyError('start time should be smaller than end time!')
        }
        const data = {
            day: days,
            startTime,
            endTime
        }

        this.props.onSubmit(data)

    }

    render() {

        const dayList = [
            { label: 'Sun', value: 0 },
            { label: 'Mon', value: 1 },
            { label: 'Tue', value: 2 },
            { label: 'Wed', value: 3 },
            { label: 'Thu', value: 4 },
            { label: 'Fri', value: 5 },
            { label: 'Sat', value: 6 },
            
        ]

        const lastDayindex = dayList.length - 1;


        const { days, startTime, endTime } = this.state;

        return (
            <Modal show={this.props.show} size="md" onHide={this.props.onHide} centered>
                <Modal.Header className="border-bottom-0">
                    <Modal.Title>Update Availability</Modal.Title>
                    <button type="button" className="bg-transparent border-0 fs-4" style={{ top: '8px', right: '8px' }} onClick={this.props.onHide}><FiX /></button>
                </Modal.Header>
                <Modal.Body className="ps-3 pe-3 position-relative">
                    <FormGroup className="row mb-4">
                        <FormLabel className='col-sm-12 '>Working Days</FormLabel>
                        <div className="col-sm-12 d-block">
                            {dayList.map((info, i) =>
                                <button key={i} type="button" className={`btn ${days.includes(info.value) ? 'btn-success' : 'btn-outline-secondary'} ${lastDayindex !== i ? 'me-2' : 'me-0'}`} onClick={() => this.daysHandler(info)}>{info.label}</button>
                            )}
                        </div>
                    </FormGroup>
                    <Row>
                        <Col sm="6" xs="12">
                            <FormGroup>
                                <FormLabel className='mb-2'>Start Time</FormLabel>
                                <input type="time" className="form-control" name="startTime" value={startTime} onChange={this.inputHandler} />
                            </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                            <FormGroup>
                                <FormLabel className='mb-2'>End Time</FormLabel>
                                <input type="time" className="form-control" name="endTime" value={endTime} onChange={this.inputHandler} />
                            </FormGroup>
                        </Col>
                    </Row>


                </Modal.Body>
                <Modal.Footer className="justify-content-start border-top-0 mt-3">
                    <button type="button" className="btn btn-dark" onClick={this.submit}>Update</button>
                    <button type="button" className="btn bg-transparent border-0" onClick={this.props.onHide}>Cancel</button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default EditAvailabilityModal
