import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiHelper } from '../../helper/api.helper';
import { notifySuccess } from '../../helper/Alert';

class VerifyComponent extends Component {

    static async getInitialProps({ query }) {

        return { id: query.id }
    }

    componentDidMount() {
        // const verifyKey = this.props.id;
        const verifyKey = this.props.match.params.id;
        // const verifyKey = window.location.href.split('/').pop();
        this.verifyAPi(verifyKey);
    }

    render() {
        return <></>
    }

    verifyAPi = async (key) => {
        console.log(key);
        const { history } = this.props;

        const response = await apiHelper(`user/verify/${key}`, 'GET', null, null);
        const res = response.data;
        notifySuccess(res.message);
        history.push('/login');
    }
}


export default withRouter(connect(null)(VerifyComponent));
