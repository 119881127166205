import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { MdClose, MdPerson, MdPowerSettingsNew, MdKeyboardArrowDown } from "react-icons/md";
import { withRouter, Link, NavLink } from 'react-router-dom';
import { jwtDecode } from '../../helper/jwt.helper';
import UserImg from '../../assets/images/dummy-user.jpg'
import Logo from '../../assets/images/vedain-logo.png'
import '../../assets/css/header.css';

// import Image from 'next/image';

const UserLink = ({ isImage, name, userImg, nameFirstCharacter }) => {
    return <>
        {isImage ?
            <span className={'user_img'}>
                <img src={UserImg} alt="user" />
            </span>
            :
            <span className={`user_img character`}>
                {nameFirstCharacter}
            </span>
        }
        {name}
    </>
}

function HeaderCommon(props) {



    const [isSiderBar, setIsSiderBar] = useState(false)
    const [isSmallWindow, setIsSmallWindow] = useState(false)
    const [name, setName] = useState('')
    const [isImage, setIsImage] = useState(false)
    const [userImg, setUserImg] = useState('')
    const [isAgentInfo, setIsAgentInfo] = useState(false)
    const [nameFirstCharacter, setNameFirstCharacter] = useState('')
    const [isActiveClass, setIsActiveClass] = useState(false);

    useEffect(() => {
        // console.log(props);
        const checkWindowWidth = () => {
            // console.log(window.innerWidth < 767);
            setIsSmallWindow(window.innerWidth < 991)
        }

        const activeClassCheckForJobs = () => {
            const pathname = props.location.pathname;
            switch (pathname) {
                case '/how-to-hire':
                    return setIsActiveClass(true)
                case '/how-to-find-work':
                    return setIsActiveClass(true)
                // case '/profile/email-verify':
                //     return this.setState({ menuDisplay: false })
                default:
                    return setIsActiveClass(false)
            }
        }

        activeClassCheckForJobs();

        window.addEventListener('resize', checkWindowWidth)
        const token = localStorage.getItem('token');
        if (props.location.pathname === '/freelancer/professional-info') {
            setIsAgentInfo(true);
        }



        setIsSmallWindow(window.innerWidth < 991);
        if (token) {
            const decode = jwtDecode(token);
            if (decode) {

                // console.log(decode)
                let isImageAvailable = false;
                let imageUrl = '';

                if (decode.name) {
                    const firstCharacter = decode.name.charAt(0)
                    if (decode.img) {
                        isImageAvailable = true;
                        imageUrl = decode.img
                    } else {
                        isImageAvailable = false;
                        imageUrl = ''
                    }
                    setNameFirstCharacter(firstCharacter)
                }
                setName(decode.name)
                setIsImage(isImageAvailable)
                setUserImg(imageUrl)

                // if (Number(decode.status) === 0) return props.history.push('/profile/email-verify');
                if (Number(decode.status) === 1) {
                    if (Number(decode.profileType) === 2) return props.history.push('/client/my-jobs');
                    if (Number(decode.profileType) === 1) return props.history.push('/freelancer/jobs');
                }

            }
        }
        // else {

        //     router.push('/login');
        //     return true
        // }

        return () => {
            window.removeEventListener('resize', checkWindowWidth)
        }
    }, [props])

    const openSideBar = () => {
        // console.log('working...')
        setIsSiderBar(true);
    }

    const closeSideBar = () => {
        setIsSiderBar(false)
    }

    const logout = (e) => {
        e.preventDefault();
        localStorage.removeItem('token');
        props.history.push('/login');
    }



    return (
        <>
            <header className="header_client ">
                <Navbar bg="light" expand="sm" fixed="top" className="client_header">
                    <div className="container">
                        <Link to="/">
                            <Navbar.Brand className="logo-web">
                                <img src={Logo} alt="Vedain" />
                            </Navbar.Brand>
                        </Link>
                        {/* <Link href="/client/dashboard"><Navbar.Brand href="/client/dashboard">Vedain</Navbar.Brand></Link> */}
                        {isSiderBar ?
                            <Navbar.Toggle aria-controls="basic-navbar-nav" className="close_icon" children={<MdClose />} onClick={closeSideBar} />
                            :
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={openSideBar} />
                        }
                        {isSmallWindow ?
                            isSiderBar && <div className="navbar_collapse justify-content-end navbar-collapse collapse show">
                                <div className="justify-content-end navbar-nav">
                                    {/* <ActiveLink href="/client/jobs" activeClassName={'active'}><NavLink href="/client/jobs">Jobs</NavLink></ActiveLink>
                                    <ActiveLink href="/client/find-freelancer" activeClassName={'active'}><Nav.Link href="/client/find-freelancer">Find Freelancer</Nav.Link></ActiveLink>
                                    <ActiveLink href="/client/profile" activeClassName={'active'}><Nav.Link href="/client/profile">My Profile</Nav.Link></ActiveLink>
                                    <ActiveLink href="/client/post-job" activeClassName={'active'}><Nav.Link href="/client/post-job" >Post Job</Nav.Link></ActiveLink> */}
                                    {isAgentInfo ?
                                        <Link to="/" onClick={logout} className="nav-link">Logout</Link>
                                        :
                                        <>
                                            <NavLink exact to="/find-talent" className="nav-link" activeClassName={'active'}>Find Talent</NavLink>
                                            <NavLink exact to="/find-work" className="nav-link" activeClassName={'active'}>Find Work</NavLink>
                                            <NavDropdown title={<><span className={isActiveClass ? 'active' : ''}>Why Vedain</span> <i><MdKeyboardArrowDown /></i></>} id="jobs-nav-dropdown" className="mobile-dropdown" >
                                                <NavLink to="/how-to-hire" className="nav-item" activeClassName="active">How to Hire</NavLink>
                                                <NavLink to="/how-to-find-work" className="nav-item" activeClassName="active">How to find work</NavLink>
                                            </NavDropdown>
                                            <NavLink exact to="/login" className="nav-link" activeClassName={'active'}>Log in</NavLink>
                                            <NavLink exact to="/signup" className="nav-link" activeClassName={'active'}>Sign up</NavLink>
                                        </>
                                    }
                                </div>
                            </div>
                            // <Navbar.Collapse id="basic-navbar-nav" className={`navbar_collapse justify-content-end`}>
                            //     <Nav className="mr-auto" className="justify-content-end" style={{ width: '100%' }}>
                            //         <ActiveLink href="/client/jobs" activeClassName={'active'}><NavLink href="/client/jobs">Jobs</NavLink></ActiveLink>
                            //         <ActiveLink href="/client/dashboard" activeClassName={'active'}><Nav.Link href="/client/dashboard">Find Freelancer</Nav.Link></ActiveLink>
                            //         <ActiveLink href="/client/profile" activeClassName={'active'}><Nav.Link href="/client/profile">My Profile</Nav.Link></ActiveLink>
                            //         <ActiveLink href="/client/post-job" activeClassName={'active'}><Nav.Link href="/client/post-job" >Post Job</Nav.Link></ActiveLink>
                            //         <Link href="/" onClick={logout}><Nav.Link href="#/" >Logout</Nav.Link></Link>
                            //     </Nav>
                            // </Navbar.Collapse>
                            :
                            isAgentInfo ?

                                <Navbar.Collapse id="basic-navbar-nav" className={`navbar_collapse justify-content-end`}>
                                    <Nav className="mr-auto justify-content-end" style={{ width: '100%' }}>
                                        {/* <ActiveLink href="/client/jobs" activeClassName={'active'}><NavLink href="/client/jobs">Jobs</NavLink></ActiveLink> */}
                                        {/* <ActiveLink href="/client/find-freelancer" activeClassName={'active'}><Nav.Link href="/client/find-freelancer">Find Freelancer</Nav.Link></ActiveLink> */}

                                        {/* <ActiveLink href="/client/post-job" activeClassName={'active'}><Nav.Link href="/client/post-job" className="btn_pJob">Post Job</Nav.Link></ActiveLink> */}
                                        <NavDropdown className={"user_pro"} title={
                                            <UserLink name={name} isImage={isImage} userImg={userImg} nameFirstCharacter={nameFirstCharacter} />} id="basic-nav-dropdown"
                                        >
                                            <Link to="/freelancer/profile"><NavDropdown.Item href="/freelancer/profile"><MdPerson /> Profile</NavDropdown.Item></Link>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item href="#/" onClick={logout}><MdPowerSettingsNew /> Logout</NavDropdown.Item>
                                        </NavDropdown>

                                    </Nav>
                                </Navbar.Collapse>
                                :
                                <Navbar.Collapse id="basic-navbar-nav" className={`navbar_collapse justify-content-end`}>
                                    <Nav className="mr-auto justify-content-end" style={{ width: '100%' }}>
                                        <NavLink exact to="/find-talent" className="nav-link" activeClassName={'active'}>Find Talent</NavLink>
                                        <NavLink exact to="/find-work" className="nav-link" activeClassName={'active'}>Find Work</NavLink>
                                        <Nav.Item className={'dropdown_li'}>
                                            <Link to="/how-to-hire" className={isActiveClass ? 'nav-link active' : 'nav-link'}>Why Vedain <i><MdKeyboardArrowDown /></i></Link>
                                            {/* <NavLink exact to="/freelancer/jobs" activeClassName={'active'}><NavLink href="/freelancer/jobs">Find jobs</NavLink></NavLink> */}
                                            <ul className={`${'dropdown_menu'} dropdown-menu`}>
                                                <li className="nav-item"><NavLink to="/how-to-hire" className="nav-link" activeClassName="active">How to Hire</NavLink></li>
                                                <li className="nav-item"><NavLink to="/how-to-find-work" className="nav-link" activeClassName="active">How to find work</NavLink></li>
                                            </ul>
                                        </Nav.Item>
                                        <NavLink exact className="nav-link" to="/login" activeClassName={'active'}>Log in</NavLink>
                                        <NavLink exact className="nav-link btn btn-primary rounded-pill ps-3 pe-3" to="/signup" activeClassName={'active'}>Sign up</NavLink>
                                    </Nav>
                                </Navbar.Collapse>
                        }
                    </div>
                </Navbar>
                {/* <nav className="navbar navbar-nav navbar-light">
                    <div className="container">
                        <Link href="/"><a className={headerStyles.navbar_brand}>Logo</a></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link href="/client/dashboard"><a className="nav-link active" aria-current="page">Home</a></Link>
                                </li>
                                <li className="nav-item">
                                    <Link href="/"><a className="nav-link">Link</a></Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link href="/"><a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Dropdown</a></Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link href="/"><a className="dropdown-item">Action</a></Link></li>
                                        <li><Link href="/"><a className="dropdown-item">Another action</a></Link></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><Link href="/"><a className="dropdown-item">Something else here</a></Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav> */}
            </header>
            {isSiderBar ? <div className="overlay_sidebar" onClick={closeSideBar}></div> : null}
        </>
    )
}

export default withRouter(connect(null)(HeaderCommon))