import React, { Component } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdCreate, MdAdd, MdDelete, MdPhotoCamera } from 'react-icons/md'
import ReactStars from "react-rating-stars-component";
// import InfiniteScroll from 'react-infinite-scroll-component';
import ReactPaginate from 'react-paginate';
import { deletPortFolio, getFreelancerProfile, getPortfolio, getEducation, deleteExistEducation, getEmployment, deleteExistEmployment } from '../../../controller/profile.controller';
import ProfileModal from './ProfileModal';
import AddPortfolio from './AddPortfolio';
import EditPortfolio from './EditPortfolio';
import moment from 'moment';
import TitleOverviewModal from './TitleOverviewModal';
import ServiceModal from './ServiceModal';
import PhotoModal from './PhotoModal';
import DummyImg from '../../../assets/images/dummy-user.jpg';
import BGImage from '../../../assets/images/client-p-banner.png';
import '../../../assets/css/profile.css';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { monthOption, nFormatter } from '../../../helper/array.helper';
import * as ACTION_TYPES from '../../../redux/actions/type';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import { jwtDecode } from '../../../helper/jwt.helper';
import ConfirmModal from '../../../components/confirmModal';
import { FiEdit2, FiTrash } from 'react-icons/fi'
import AddEducationModal from '../../../components/EducationComponent.js/AddEducationModal';
import EditEducationModal from '../../../components/EducationComponent.js/EditEducationModal';
import AddEmploymentModal from '../../../components/EmploymentComponent/AddEmploymentModal';
import EditEmploymentModal from '../../../components/EmploymentComponent/EditEmploymentModal';
import review from '../../../controller/review.controller';

class ProfileFreelancerComponent extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            hasMore: false,
            isSkillSMore: false,
            page: 0,
            userData: null,
            isPorfileUpdate: false,
            isProfileModal: false,
            isPortfolioUpdate: false,
            portfolioList: [],
            isPortfolioModal: false,
            isTitleOverviewModal: false,
            isServiceModal: false,
            isEditPortfolioModal: false,
            isPhotoModal: false,
            portfolioSingle: null,
            portFoiloUrl: '',
            educationList: [],
            addEducationModal: false,
            editEducationModal: false,
            deleteEducationModal: false,
            isEducationUpdate: false,
            employmentList: [],
            addEmploymentModal: false,
            editEmploymentModal: false,
            deleteEmploymentModal: false,
            isEmploymentUpdate: false,
            singleData: null,
            reviewList: []
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0)
        this.getUserProfileData();
        this.getPortfolioApi();
        this.getEducationList();
        this.getEmploymentList();
        this.getReviews();
    }
    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        const { isPorfileUpdate, isPortfolioUpdate, isEducationUpdate, isEmploymentUpdate } = this.state;


        if (prevState.isEducationUpdate !== isEducationUpdate) {
            if (isEducationUpdate) {
                this.getEducationList();
            }
        }

        if (prevState.isEmploymentUpdate !== isEmploymentUpdate) {
            if (isEmploymentUpdate) {
                this.getEmploymentList();
            }
        }

        if (prevState.isPorfileUpdate !== isPorfileUpdate) {
            if (isPorfileUpdate) {
                this.getUserProfileData();
            }
        }

        if (prevState.isPortfolioUpdate !== isPortfolioUpdate) {
            if (isPortfolioUpdate) {
                this.getPortfolioApi();
            }
        }
    }

    isEducationUpdateFunction = (bool) => {
        if (this._unmounted) {
            this.setState({
                isEducationUpdate: bool
            });
        }
    }

    isEmploymentUpdateFunction = (bool) => {
        if (this._unmounted) {
            this.setState({
                isEmploymentUpdate: bool
            });
        }
    }

    getEducationList = async () => {
        const res = await getEducation();
        // console.log(res);
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    educationList: res.data
                });
                this.isEducationUpdateFunction(false);
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    educationList: []
                })
            }
        }
    }

    getReviews = async () => {
        const res = await review.getFreelancerAllReview();
        if (res.status) {
            this.setState({
                reviewList: res.data
            })
        } else {
            this.setState({
                reviewList: res.data
            })
        }
    }

    openAddEducationModal = () => {
        this.setState({
            addEducationModal: true
        })
    }

    closeAddEducationModal = () => {
        this.setState({
            addEducationModal: false
        })
    }

    openEditEducationModal = (row) => {
        this.setState({
            editEducationModal: true,
            singleData: row
        })
    }

    closeEditEducationModal = () => {
        this.setState({
            editEducationModal: false,
            singleData: null
        })
    }

    openDeleteEducationModal = (row) => {
        this.setState({
            deleteEducationModal: true,
            singleData: row
        })
    }

    closeDeleteEducationModal = () => {
        this.setState({
            deleteEducationModal: false,
            singleData: null
        })
    }

    submitDeleteEducation = async () => {
        const { singleData } = this.state;
        const res = await deleteExistEducation(singleData.id);
        if (res.status) {
            this.isEducationUpdateFunction(true);
            this.closeDeleteEducationModal();
        } else {
            notifyError(res.message)
        }
    }

    getEmploymentList = async () => {
        const res = await getEmployment();
        // console.log(res);
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    employmentList: res.data
                });
                this.isEmploymentUpdateFunction(false);
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    employmentList: []
                })
            }
        }
    }

    openAddEmploymentModal = () => {
        this.setState({
            addEmploymentModal: true
        })
    }

    closeAddEmploymentModal = () => {
        this.setState({
            addEmploymentModal: false
        })
    }

    openEditEmploymentModal = (row) => {
        this.setState({
            editEmploymentModal: true,
            singleData: row
        })
    }

    closeEditEmploymentModal = () => {
        this.setState({
            editEmploymentModal: false,
            singleData: null
        })
    }

    openDeleteEmploymentModal = (row) => {
        this.setState({
            deleteEmploymentModal: true,
            singleData: row
        })
    }

    closeDeleteEmploymentModal = () => {
        this.setState({
            deleteEmploymentModal: false,
            singleData: null
        })
    }

    submitDeleteEmployment = async () => {
        const { singleData } = this.state;
        const res = await deleteExistEmployment(singleData.id);
        if (res.status) {
            this.isEmploymentUpdateFunction(true);
            this.closeDeleteEmploymentModal();
            notifySuccess(res.message);
        } else {
            notifyError(res.message);
        }
    }

    getPortfolioApi = async () => {
        const res = await getPortfolio();
        if (this._unmounted) {
            this.setState({
                isPortfolioUpdate: false,
                portfolioList: res.portfolio,
                portFoiloUrl: res.path
            })
        }
    }

    getUserProfileData = async () => {
        if (this._unmounted) {
            this.setState({
                isLoader: true
            })
        }
        const response = await getFreelancerProfile();
        if (response.status) {
            // console.log(res);
            const res = jwtDecode(response.data);
            if (res.profilePic) {
                const imgUrl = `${res.profileURL}${res.profilePic}`;
                this.props.updateUserImage(imgUrl)
            }

            if (res) {
                this.props.updateUserName(res.name);
            }

            if (this._unmounted) {
                this.setState({
                    isPorfileUpdate: false,
                    userData: res,
                    isLoader: false
                    // portfolioList: []
                })
            }
        } else {
            notifyError(response.message)
        }
    }

    pageHandler = (page) => {
        if (this._unmounted) {
            if (page.selected) {
                this.setState({
                    page: page.selected
                })
            } else {
                this.setState({
                    page: 0
                })
            }
        }
    }

    openProfileModal = () => {
        if (this._unmounted) {
            this.setState({
                isProfileModal: true
            });
        }
    }

    closeProfileModal = () => {
        if (this._unmounted) {
            this.setState({
                isProfileModal: false
            });
        }
    }

    openTitleOverviewModal = () => {
        if (this._unmounted) {
            this.setState({
                isTitleOverviewModal: true
            });
        }
    }

    closeTitleOverviewModal = () => {
        if (this._unmounted) {
            this.setState({
                isTitleOverviewModal: false
            });
        }
    }

    openServiceModal = () => {
        if (this._unmounted) {
            this.setState({
                isServiceModal: true
            });
        }
    }

    closeServiceModal = () => {
        if (this._unmounted) {
            this.setState({
                isServiceModal: false
            });
        }
    }

    openPortfolioModal = () => {
        if (this._unmounted) {
            this.setState({
                isPortfolioModal: true
            });
        }

    }

    closePortfolioModal = () => {
        if (this._unmounted) {
            this.setState({
                isPortfolioModal: false
            });
        }
    }


    openEditPortfolioModal = (data) => {
        if (this._unmounted) {
            this.setState({
                portfolioSingle: data,
                isEditPortfolioModal: true
            });
        }
    }

    closeEditPortfolioModal = () => {
        if (this._unmounted) {
            this.setState({
                isEditPortfolioModal: false,
                portfolioSingle: null
            });
        }
    }
    openPhotoModal = () => {
        if (this._unmounted) {
            this.setState({
                isPhotoModal: true
            });
        }
    }

    closePhotoModal = () => {
        if (this._unmounted) {
            this.setState({
                isPhotoModal: false
            });
        }
    }


    fetchMorePortfolio = () => {
        if (this._unmounted) {
            this.setState({
                hasMore: false
            });
        }
    }

    toggleSkillMore = () => {
        if (this._unmounted) {
            this.setState(prevState => ({
                isSkillSMore: !prevState.isSkillSMore
            }));
        }
    }

    updateProfileFunction = (data) => {
        if (this._unmounted) {
            this.setState({
                isPorfileUpdate: true
            });
        }
    }
    updatePortfolioFunction = (data) => {
        if (this._unmounted) {
            this.setState({
                isPortfolioUpdate: true
            });
        }
    }

    deletPortfolio = async (id) => {
        const res = await deletPortFolio(id)
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    isPortfolioUpdate: true
                });
                notifySuccess(res.message)
            } else {
                notifyError(res.message)
            }
        }
    }

    renderEmploymentList = (info, i) => {
        const monthObj = monthOption.find(month => month.value === info.startMonth)
        const endMonthObj = monthOption.find(month => month.value === info.endMonth)
        // console.log()
        return <li className="list-group-item p-3 mb-3" key={i}>

            <div className="row align-items-center">
                <div className="col-sm-10 col-xs-12">
                    <h6 className="fs-4 fw-bold mb-2">{info.title ? `${info.title}` : ''}</h6>
                    <p className="mb-0" style={{ fontSize: '13px', opacity: '0.7' }}>
                        {parseInt(info.present) === 1 ?
                            `${monthObj?.label} ${info.startYear} - Present`
                            :
                            `${monthObj?.label} ${info.startYear} - ${endMonthObj?.label} ${info.endYear}`
                        }
                    </p>
                    <p className="mb-2" style={{ fontSize: '13px', opacity: '1' }}>{info.company}</p>
                    {info.description ? <p className="mb-0 mw-100" style={{ fontSize: '13px', opacity: '1', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{info.description}</p> : null}
                </div>
                <div className="col-sm-2 col-xs-12" style={{ textAlign: 'right' }}>
                    <button type="button" className="bg-transparent border-0 text-secondary" onClick={() => this.openEditEmploymentModal(info)}><FiEdit2 /></button>
                    <button type="button" className="bg-transparent border-0 text-danger" onClick={() => this.openDeleteEmploymentModal(info)}><FiTrash /></button>
                </div>
            </div>
        </li>
    }

    render() {

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 2) {
                return <Redirect to="/client/my-jobs" />
            }
        }

        // console.log('profile page')

        const { isLoader, page, userData, portfolioList, isProfileModal, isPortfolioModal, isEditPortfolioModal, isTitleOverviewModal, isServiceModal, isPhotoModal, reviewList } = this.state;

        const modalData = {
            show: isProfileModal,
            onHide: this.closeProfileModal,
            userData: userData,
            updateFunction: this.updateProfileFunction
        }

        const portfolioModalData = {
            show: isPortfolioModal,
            onHide: this.closePortfolioModal,
            userData: userData,
            updateFunction: this.updatePortfolioFunction
        }

        const portfolioEditModalData = {
            show: isEditPortfolioModal,
            onHide: this.closeEditPortfolioModal,
            userData: this.state.portfolioSingle,
            updateFunction: this.updatePortfolioFunction,
            path: this.state.portFoiloUrl
        }
        const titleOverviewModalData = {
            show: isTitleOverviewModal,
            onHide: this.closeTitleOverviewModal,
            userData: userData,
            updateFunction: this.updateProfileFunction
        }

        const serviceModalData = {
            show: isServiceModal,
            onHide: this.closeServiceModal,
            userData: userData,
            updateFunction: this.updateProfileFunction
        }
        const photoModalData = {
            show: isPhotoModal,
            onHide: this.closePhotoModal,
            userData: userData,
            updateFunction: this.updateProfileFunction
        }


        return (
            <>
                <section className={`profile_wrap12`}>
                    <div className={'profile_banner'}>
                        <img src={BGImage} alt="banner" />
                    </div>
                    <div className="container">
                        {isLoader ?
                            <div className="no-chat-wrap inside">
                                <div className="text-chatmessage">
                                    <MainLoaderComponent color="black" />
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-sm-12 col-md-4">
                                    <div className={`${'card_wrap'} mt_150`}>
                                        <button type="button" className={'profileEditBtn'} onClick={this.openProfileModal}><MdCreate /> Edit</button>
                                        <div className={'img_wrap'}>
                                            <div className={'img_block'}>
                                                {userData && userData.profilePic ?
                                                    <img src={userData.profileURL + userData.profilePic} alt="User" />
                                                    :
                                                    <img src={DummyImg} alt="User" />
                                                }
                                            </div>
                                            <button type="button" className={'btn_fileUpload'} onClick={this.openPhotoModal}><MdPhotoCamera /></button>
                                        </div>
                                        <div className={'user_content'}>
                                            <h3>{userData && userData.name}</h3>
                                            <p className={'nick_nm'}>{userData && userData.email}</p>
                                            <div className={'rating_block'}>
                                                <p>{userData?.totalRate?userData.totalRate:0}</p>
                                                {/* <StarRatings starRatedColor="#ffc12d" rating={3} numberOfStars={5} name={'userRating'} /> */}
                                                <div style={{ marginLeft: '10px' }} className={'rating_bl'}>
                                                    <ReactStars count={5} isHalf={true} value={userData?.totalRate?userData.totalRate:0} edit={false} size={24} activeColor="#ffc12d" />
                                                </div>
                                                <span className={'review_text'}>({userData?.totalReview?userData.totalReview:0} reviews)</span>
                                            </div>
                                            {/* <p className={location}>
                                    <img src="https://s3.us-east-2.amazonaws.com/static.24task.com/images/flags/flags-medium/in.png" alt="India" />
                                    <span>Akasahebpet, Andhra Pradesh, India</span>
                                </p> */}
                                            <ul className={'activeted_ids'}>
                                                <li className={userData && userData.email ? 'active' : ''}>
                                                    <div className={'icon'}>
                                                        <svg id="email" xmlns="http://www.w3.org/2000/svg" width="17.684" height="12" viewBox="0 0 17.684 12.6">
                                                            <path id="Path_49" data-name="Path 49" d="M165.313,48.866c0-.02.013-.04.013-.06l-5.4,5.2,5.39,5.034c0-.036-.006-.072-.006-.109Z" transform="translate(-147.642 -47.598)" fill="#fff"></path>
                                                            <path id="Path_50" data-name="Path 50" d="M21.628,126.9l-2.2,2.12a.577.577,0,0,1-.792.006l-2.2-2.047-5.425,5.229a1.254,1.254,0,0,0,.422.084H26.618a1.248,1.248,0,0,0,.606-.167Z" transform="translate(-10.164 -119.693)" fill="#fff"></path>
                                                            <path id="Path_51" data-name="Path 51" d="M14.514,41.2l8.236-7.934a1.248,1.248,0,0,0-.636-.185H6.927a1.252,1.252,0,0,0-.8.3Z" transform="translate(-5.659 -33.085)" fill="#fff"></path>
                                                            <path id="Path_52" data-name="Path 52" d="M0,52.1v9.871a1.254,1.254,0,0,0,.054.327l5.358-5.16Z" transform="translate(0 -50.639)" fill="#fff"></path>
                                                        </svg>
                                                    </div>
                                                    <span>{'Email'}</span>
                                                </li>
                                                <li className={userData && userData.mobile ? 'active' : ''}>
                                                    <div className={'icon'}>
                                                        <svg id="phone-call" xmlns="http://www.w3.org/2000/svg" width="12.574" height="12.6" viewBox="0 0 12.574 12.6"><g id="Group_16" data-name="Group 16">
                                                            <path id="Path_61" data-name="Path 61" d="M12.783,9.247,11.025,7.489a1.17,1.17,0,0,0-1.947.44A1.2,1.2,0,0,1,7.7,8.682,5.422,5.422,0,0,1,4.431,5.416a1.138,1.138,0,0,1,.754-1.382,1.17,1.17,0,0,0,.44-1.947L3.866.33a1.254,1.254,0,0,0-1.7,0L.977,1.523C-.216,2.779,1.1,6.107,4.054,9.059s6.28,4.333,7.536,3.077l1.193-1.193A1.254,1.254,0,0,0,12.783,9.247Z" transform="translate(-0.539 0)" fill="#fff"></path> </g>
                                                        </svg>
                                                    </div>
                                                    <span>{'Mobile Number'}</span>
                                                </li>
                                            </ul>
                                            {/* <p className={'job_ratio'}><b>36.05%</b> Jobs Completed</p> */}
                                            <div className={'date_time'}>
                                                <p className={'meeting'}><Link to="/freelancer/profile/meeting">Reschedule Meeting</Link></p>
                                                <p className={'time'}>It’s Currently {moment().format('hh:mm A')} Here</p>
                                                <p className={'date'}>Joined {userData && userData.createdAt ? moment(userData.createdAt).format('MMM DD, YYYY') : null}</p>
                                                {userData && userData.email ? <p className={'email'}>{userData.email}</p> : null}
                                                {/* <p className={website}>hdjdkkdkd</p> */}
                                                {userData && userData.mobile ? <p className={'mobile'}>{userData.mobilePrefix} {userData.mobile}</p> : null}
                                                <p className={'address'}>{userData && userData.statesName ? `${userData.statesName}, ` : null}{userData && userData.countryName ? ` ${userData.countryName}` : null}</p>
                                            </div>
                                            <div className={'earning'}>
                                                <div>
                                                    <h5>${userData?.totalEarnings?nFormatter(userData.totalEarnings):0}</h5>
                                                    <span>Total Earnings</span>
                                                </div>
                                                <div>
                                                    <h5>{userData?.totalJobs?userData.totalJobs:0}</h5>
                                                    <span>Total Jobs</span>
                                                </div>
                                                <div>
                                                    {/* <h5>0</h5>
                                        <span>Total Hours</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-8">
                                    <div className={`right_sec mt_150`}>
                                        <div className={'partition'}>
                                            <div className={`${'cap_hire_div'} ${'hover_div'}`}>
                                                <h1 className={'title_cap fs-5 mb-1 pe-5'}>{userData && userData.bio}</h1>
                                                <button type="button" onClick={this.openTitleOverviewModal}><MdCreate /> Edit</button>
                                            </div>
                                            <div className={'description_ti'}>
                                                {userData && userData.overview}
                                            </div>
                                        </div>

                                        <div className={`${'partition'} ${'partition_hover'}`}>
                                            <h5>Main Experties <button type="button" className={'btn_added'} onClick={this.openServiceModal}><MdCreate /> Edit</button></h5>
                                            <ul id="skillDiv" className={`${'rounded'} ${'skill'}`}>
                                                {userData && userData.serviceID ?
                                                    <li>{userData.serviceName}</li>
                                                    :
                                                    <li>no found</li>
                                                }
                                            </ul>
                                        </div>
                                        <div className={`${'partition'} ${'partition_hover'}`}>
                                            <h5>All Skills <button type="button" className={'btn_added'} onClick={this.openServiceModal}><MdCreate /> Edit</button></h5>
                                            <ul className={`${'rounded'} ${'skill'} ${'more'}`}>
                                                {/* <ul className={`${'rounded'} ${'skill'} ${this.state.isSkillSMore ? 'more' : 'less'}`}> */}
                                                {(userData && userData.skills) && userData.skills.length > 0 ?
                                                    userData.skills.map((info, i) => <li key={i}>{info.skillName}</li>)
                                                    :
                                                    <li>no found</li>
                                                }
                                            </ul>
                                            {/* <button type="button" aria-label={`View ${this.state.isSkillSMore ? 'less' : 'more'}`} className={`${'btn_viewMore'} ${'skills_more'}`} onClick={this.toggleSkillMore}>View {this.state.isSkillSMore ? 'Less' : 'more'}</button> */}
                                        </div>
                                        <div className={`${'partition'} ${'partition_hover'}`}>
                                            <h5>Portfolio <button type="button" className={'btn_added'} onClick={this.openPortfolioModal}><MdAdd /> Add</button></h5>
                                            <ul className={`${'portfolio_listing'}`} id="scrollableDiv">
                                                {Array.isArray(portfolioList) && portfolioList.length > 0 ?
                                                    portfolioList.map((info, i) =>
                                                        <li className={'box'} key={i}>
                                                            <div className={`${'effect'} ${'freelancer_effect'}`}>
                                                                {info.attachments.length > 0 ?
                                                                    <img src={`${this.state.portFoiloUrl}${info.attachments[0].fileName}`} alt="test" />
                                                                    :
                                                                    <img src="https://s3.us-east-2.amazonaws.com/cdn.24task.com/agentApi/webroot/portfolios_files/5f7ee59c063cf.jpg" alt="test" />
                                                                }
                                                                <div className={'contentPart'}>
                                                                    <span className={'title'}>{info.title}</span>
                                                                    <div className="">
                                                                        <button type="button" onClick={() => this.openEditPortfolioModal(info)}><MdCreate /></button>
                                                                        <button type="button" className={'delet_icon'} onClick={() => this.deletPortfolio(info.id)}><MdDelete /></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>)
                                                    :
                                                    <li className={'noPortfolio'}>No Portfolio found</li>
                                                }
                                            </ul>

                                        </div>
                                        <div className={`${'partition'} partition_hover`}>
                                            <h5>Education <button type="button" className={'btn_added'} onClick={this.openAddEducationModal}><MdAdd /> Add</button></h5>
                                            {this.state.educationList.length === 0 ? <p className="o-75 mb-5">Add the schools you attended, areas of study, and degrees earned.</p> : null}
                                            {this.state.educationList && this.state.educationList.length > 0 ?
                                                <ul className="list-group list-group-flush">
                                                    {this.state.educationList.map((info, i) =>
                                                        <li className="list-group-item p-3 mb-3" key={i}>
                                                            <div className="row align-items-center">
                                                                <div className="col-sm-10 col-xs-12">
                                                                    <h6 className="fs-4 fw-bold mb-1">{info.degree ? `${info.degree} | ` : ''}{info.schoolName}</h6>
                                                                    {info.aeraOfStudy && info.aeraOfStudy !== '' ? <p className="mb-0" style={{ fontSize: '13px', opacity: '0.7' }}>{info.aeraOfStudy}</p> : null}
                                                                    <p className="mb-0" style={{ fontSize: '13px', opacity: '0.7' }}>{`${info.schoolStartYear} - ${info.schoolEndYear}`}</p>
                                                                </div>
                                                                <div className="col-sm-2 col-xs-12" style={{ textAlign: 'right' }}>
                                                                    <button type="button" className="bg-transparent border-0 text-secondary" onClick={() => this.openEditEducationModal(info)}><FiEdit2 /></button>
                                                                    <button type="button" className="bg-transparent border-0 text-danger" onClick={() => this.openDeleteEducationModal(info)}><FiTrash /></button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                                :
                                                null
                                            }

                                        </div>

                                        <div className={`${'partition'} partition_hover`}>
                                            <h5>Employment <button type="button" className={'btn_added'} onClick={this.openAddEmploymentModal}><MdAdd /> Add</button></h5>
                                            {this.state.employmentList.length === 0 ?
                                                <p className="o-75 mb-5">Add your past work experince
                                                    <span className="d-block" style={{ fontSize: '12px', opacity: '0.7' }}>Build your credibility by showcase the project or jobs you have completed.</span>
                                                </p>
                                                :
                                                null
                                            }
                                            {this.state.employmentList && this.state.employmentList.length > 0 ?
                                                <ul className="list-group list-group-flush">
                                                    {this.state.employmentList.map((info, i) => this.renderEmploymentList(info, i))}
                                                </ul>
                                                :
                                                null
                                            }

                                        </div>
                                        {/* <div className={partition}>
                                <h5>Languages</h5>
                                <ul className={`${rounded} ${language}`}>
                                    <li>American English: <span>Fluent</span></li>
                                    <li>Hindi: <span>Native</span></li>
                                    <li>Gujarati: <span>Native</span></li>
                                </ul>
                            </div> */}
                                    </div>
                                    {reviewList && reviewList.length > 0 ?
                                        <div className={`${'right_sec'} ${'mt_0'} ${'review_wrap'}`}>
                                            <h4>Reviews</h4>
                                            <ul className={'review_ul'}>
                                                {reviewList.map((info, i)=>
                                                <li className={`${'review_div'} ${'bl'}`} key={i}>
                                                    <div className={'caption_wrap'}>
                                                        <h5 className={'review_title'}>{info?.jobTitle}</h5>
                                                        <span className={'time'}>{moment(info.createdAt).format('DD MMM YYYY')}</span>
                                                    </div>
                                                    <div className={'detail mb-0'}>
                                                        <div className={`${'rating'} ${'rvi'}`}>
                                                            <p className={`${'rating_badge'} mb-0`}>{info?.rate}</p>
                                                            <ReactStars isHalf={true} count={5} value={info?.rate} edit={false} size={15} activeColor="#ffc12d" />
                                                        </div>
                                                        <p>{info.description}</p>
                                                    </div>
                                                </li>
                                                )}
                                            </ul>
                                            {/* <div className={'pagination_wrap'}>
                                                <ReactPaginate
                                                    initialPage={page}
                                                    // forcePage={this.state.page}
                                                    pageCount={1}
                                                    pageRangeDisplayed={4}
                                                    breakLabel={'...'}
                                                    onPageChange={this.pageHandler}
                                                    activeClassName={'active'}
                                                    previousLabel={<MdKeyboardArrowLeft />}
                                                    previousClassName={'previous'}
                                                    nextLabel={<MdKeyboardArrowRight />}
                                                    nextClassName={'next'}
                                                />
                                            </div> */}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    {isProfileModal ? <ProfileModal data={modalData} /> : null}
                    {isPortfolioModal ? <AddPortfolio data={portfolioModalData} /> : null}
                    {isEditPortfolioModal ? <EditPortfolio data={portfolioEditModalData} /> : null}
                    {isTitleOverviewModal ? <TitleOverviewModal data={titleOverviewModalData} /> : null}
                    {isServiceModal ? <ServiceModal data={serviceModalData} /> : null}
                    {isPhotoModal ? <PhotoModal data={photoModalData} /> : null}

                    {/* education modals */}
                    {this.state.addEducationModal ?
                        <AddEducationModal show={this.state.addEducationModal} onHide={this.closeAddEducationModal} onSubmit={this.isEducationUpdateFunction} />
                        :
                        null
                    }

                    {this.state.editEducationModal ?
                        <EditEducationModal show={this.state.editEducationModal} onHide={this.closeEditEducationModal} data={this.state.singleData} onSubmit={this.isEducationUpdateFunction} />
                        :
                        null
                    }

                    {this.state.deleteEducationModal ?
                        <ConfirmModal
                            text={'Are you sure you want to delete this education?'}
                            show={this.state.deleteEducationModal}
                            onSubmit={this.submitDeleteEducation}
                            onHide={this.closeDeleteEducationModal}
                        />
                        :
                        null
                    }
                    {/* education modals over */}

                    {/* employment modals */}
                    {this.state.addEmploymentModal ?
                        <AddEmploymentModal show={this.state.addEmploymentModal} onHide={this.closeAddEmploymentModal} onSubmit={this.isEmploymentUpdateFunction} />
                        :
                        null
                    }

                    {this.state.editEmploymentModal ?
                        <EditEmploymentModal show={this.state.editEmploymentModal} onHide={this.closeEditEmploymentModal} data={this.state.singleData} onSubmit={this.isEmploymentUpdateFunction} />
                        :
                        null
                    }

                    {this.state.deleteEmploymentModal ?
                        <ConfirmModal
                            text={'Are you sure you want to delete this employment?'}
                            show={this.state.deleteEmploymentModal}
                            onSubmit={this.submitDeleteEmployment}
                            onHide={this.closeDeleteEmploymentModal}
                        />
                        :
                        null
                    }
                    {/* employment modals over */}
                </section>
            </>
        )
    }
}
const mapDipatchToProps = dispatch => ({
    updateUserImage: (url) => dispatch({ type: ACTION_TYPES.USER_IMAGE_UPDATE, payload: url }),
    updateUserName: (name) => dispatch({ type: ACTION_TYPES.UPDATE_USER_NAME, payload: name })
})
export default withRouter(connect(null, mapDipatchToProps)(ProfileFreelancerComponent))