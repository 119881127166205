import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DataTable from '../../components/DataTable';
import { deleteExitAdmin, getAllAdmins, newAdminCreate, updateExitsAdmin } from '../../controller/admin.controller';
import { Dropdown } from 'react-bootstrap';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import DummyImage from '../../assets/img/dummy-userImg.jpg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FiEdit2, FiTrash } from 'react-icons/fi';
import Modal from 'react-bootstrap/Modal';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';

class SuperAdmin extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            adminList: [],
            status: '',
            path: '',
            details: null,
            descModal: false,
            updateLoader: false,
            isAddModal: false,
            isAddLoader: false,
            addForm: {
                fname: '',
                lname: '',
                email: '',
                password: ''
            },
            form: {
                fname: '',
                lname: '',
                email: '',
                password: '',
            }
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);

        this.allAdmins();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    allAdmins = async () => {
        const res = await getAllAdmins();
        // console.log(res);
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    adminList: res.data,
                    // path: res.path
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    adminList: [],
                    // path: res.path
                })
            }
        }
    }

    pageHandler = (page, sizePerPage) => {
        console.log(page);
    }

    sizePerPageChange = (sizePerPage, page) => {
        console.log(sizePerPage);
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Table is Empty</p>
        </div>
    }

    openModalAdmin = (row) => {
        if (this._unmounted) {
            this.setState({
                details: row,
                descModal: true,
                form: {
                    ...this.state.form,
                    fname: row.firstName,
                    lname: row.lastName,
                    email: row.email
                }
            })
        }
    }

    closeModalAdmin = () => {
        if (this._unmounted) {
            this.setState({
                details: null,
                descModal: false
            })
        }
    }

    addAdminModal = () => {
        if (this._unmounted) {
            this.setState({
                isAddModal: true,
                isAddLoader: false
            })
        }
    }

    closeAddAdminModal = () => {
        if (this._unmounted) {
            this.setState({
                isAddModal: false,
                isAddLoader: false
            })
        }
    }

    updateSubmit = async (values, actions) => {
        if(this._unmounted){
            this.setState({
                updateLoader:true,
            })
        }

        const data = {
            id:this.state.details.id,
            firstName: values.fname,
            lastName: values.lname,
            password: values.password
        }

        const res = await updateExitsAdmin(data);
        if(res.status){
            notifySuccess(res.message);
            actions.resetForm({
                values:{
                    fname:'',
                    lname:'',
                    email:'',
                    password:''
                }
            })
            if(this._unmounted){
                this.setState({
                    updateLoader:false,
                    details:null
                })
            }
            this.allAdmins();
            this.closeModalAdmin();
        }else{
            if(this._unmounted){
                this.setState({
                    updateLoader:false,
                })
            }
            notifyError(res.message)
        }
    }

    AddformSubmit = async (values, actions) => {
        // console.log(values);
        if(this._unmounted){
            this.setState({
                isAddLoader:true,
            })
        }

        const data = {
            firstName: values.fname,
            lastName: values.lname,
            email: values.email,
            password: values.password
        }
        const res = await newAdminCreate(data);
        if(res.status){
            notifySuccess(res.message);
            actions.resetForm({
                values:{
                    fname:'',
                    lname:'',
                    email:'',
                    password:''
                }
            })
            if(this._unmounted){
                this.setState({
                    isAddLoader:false,
                })
            }
            this.allAdmins();
            this.closeAddAdminModal();
        }else{
            if(this._unmounted){
                this.setState({
                    isAddLoader:false,
                })
            }
            notifyError(res.message)
        }
    }

    deleteAdmin = async (info) => {
        // console.log(info);
        const res = await deleteExitAdmin(info.id);
        if(res.status){
            let list = this.state.adminList;
            if(Array.isArray(list) && list.length > 0){
                list = list.filter(item=>item.id !== info.id);
            }

            if(this._unmounted){
                this.setState({
                    adminList:list
                })
            }
            notifySuccess(res.message);
        }else{
            notifyError(res.message)
        }
    }

    statusHandler = async (event, val, row) => {
        event.preventDefault();
        if (row.status !== val) {
            // const res = await updateFreelancersStatus({ userID: row.profileID, status: val })
            // if (res.status) {
            //     notifySuccess(res.message)
            //     this.getFreelancersList()
            // } else {
            //     notifyError(res.message)
            // }
        }
    }

    headerCenterFormatter = (column) => {
        return <div className="d-flex justify-content-center">{column.text}</div>
    }

    errorImage = (e) => {
        e.target.src = DummyImage
    }

    nameFormatter = (cell, row) => {
        return <div className="d-flex align-items-center">
            {row.profilePic ?
                <img className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} src={`${this.state.path}${row.profilePic}`} onError={this.errorImage} alt="freelancer" />
                :
                <img className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} src={DummyImage} alt="freelancer" />
            }
            <span>{row.name}</span>
        </div>
    }

    statusFormatter = (cell, row) => {
        // console.log(row);
        let classsName = '';
        let statusName = '';
        switch (row.status) {
            case 0:
                classsName = 'warning';
                statusName = 'Pending';
                break;
            case 1:
                classsName = 'success';
                statusName = 'Active';
                break;
            default:
                classsName = 'secondary';
                statusName = 'none'
                break;
        }

        return <div className="d-flex justify-content-center">
            <Dropdown>
                <Dropdown.Toggle variant={classsName} id="dropdown-basic" className="rounded-pill" style={{ fontSize: '.775rem', minWidth: '100px' }}>{statusName}</Dropdown.Toggle>

                <Dropdown.Menu bsPrefix="dropdown-menu dropdown-menu-end drop_menu">
                    <Dropdown.Item href="#/action-1" onClick={(e) => this.statusHandler(e, 1, row)}>Active</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" onClick={(e) => this.statusHandler(e, 0, row)}>Pending</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    }

    actionFormatter = (cell, row) => {
        return <div className="d-flex justify-content-center">
            {/* <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.openModalDescription(row)}><FiEye /></button> */}
            <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.openModalAdmin(row)}><FiEdit2 /></button>
            <button type="button" className="border-0 bg-transparent text-danger" style={{ cursor: 'pointer' }} onClick={() => this.deleteAdmin(row)}><FiTrash /></button>
        </div>
    }

    modalStatusDisplay = (status) => {

        let classsName = '';
        let statusName = '';
        switch (status) {
            case 0:
                classsName = 'text-warning';
                statusName = 'Pending';
                break;
            case 1:
                classsName = 'text-success';
                statusName = 'Active';
                break;
            default:
                classsName = 'text-secondary';
                statusName = 'none'
                break;
        }
        return <p className="fs-5 mt-3">
            <b> Status:
                <span className={`${classsName} ms-2`}>{statusName}</span>
            </b>
        </p>
    }

    render() {

        const columns = [
            {
                dataField: 'firstName',
                text: 'First Name',
                sort: true,
                // formatter: this.nameFormatter
            },
            {
                dataField: 'lastName',
                text: 'Last Name',
                sort: true
                // formatter: this.nameFormatter
            },
            {
                dataField: 'email',
                text: 'Email',
                sort: true
            },
            // {
            //     dataField: 'status',
            //     text: 'Status',
            //     sort: true,
            //     formatter: this.statusFormatter,
            //     headerFormatter: this.headerCenterFormatter
            // },
            {
                dataField: 'action',
                text: 'Action',
                sort: false,
                formatter: this.actionFormatter,
                headerFormatter: this.headerCenterFormatter
            }
        ];

        const adminFormSchema = Yup.object().shape({
            fname: Yup.string().required('First name must be required'),
            lname: Yup.string().required('Last name must be required'),
            email: Yup.string().email('Invalid email').required('Email must be required'),
            password: Yup.string()
        });
        const addFormSchema = Yup.object().shape({
            fname: Yup.string().required('First name must be required'),
            lname: Yup.string().required('Last name must be required'),
            email: Yup.string().email('Invalid email').required('Email must be required'),
            password: Yup.string().required('Password must be required!')
        });

        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3 d-flex align-items-start justify-content-between"><strong>Super Admin List</strong> <button type="button" className="btn btn-dark" onClick={this.addAdminModal}>Add Admin</button></h1>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <DataTable
                                keyField="id"
                                data={this.state.adminList}
                                columns={columns}
                                // onSizePerPageChange={this.sizePerPageChange} 
                                // onPageChange={this.pageHandler} 
                                noDataIndication={this.emptyRender}
                            />

                        </div>
                    </div>
                </div>
                {this.state.descModal ?
                    <Modal show={this.state.descModal} onHide={this.closeModalAdmin} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                        <Formik initialValues={this.state.form} validationSchema={adminFormSchema} onSubmit={(values, actions) => this.updateSubmit(values, actions)}>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (

                                <form action="/" method="POST" onSubmit={handleSubmit}>
                                    <Modal.Header>
                                        <Modal.Title>Admin details update</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="p-4">
                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-1">First Name</label>
                                                    <input type="text" name="fname" className={errors.fname && touched.fname ? "form-control error" : "form-control"} placeholder="First Name" onChange={handleChange} onBlur={handleBlur} value={values.fname} />
                                                    {errors.fname && touched.fname ? <p className="error-text">{errors.fname}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xs-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-1">Last Name</label>
                                                    <input type="text" name="lname" className={errors.lname && touched.lname ? "form-control error" : "form-control"} placeholder="Last Name" onChange={handleChange} onBlur={handleBlur} value={values.lname} />
                                                    {errors.lname && touched.lname ? <p className="error-text">{errors.lname}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xs-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-1">Email</label>
                                                    <input type="email" name="email" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} disabled />
                                                    {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xs-12">
                                                <div className="form-group d-block mb-5">
                                                    <label className="mb-1">Password</label>
                                                    <input type="password" name="password" className={errors.password && touched.password ? "form-control error" : "form-control"} placeholder="Password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                                    {errors.password && touched.password ? <p className="error-text">{errors.password}</p> : null}
                                                </div>
                                            </div>
                                        </div>


                                    </Modal.Body>
                                    <Modal.Footer className="d-flex justify-content-between">
                                        <button type="button" className="btn btn-outline-dark" onClick={this.closeModalAdmin}>Close</button>
                                        <button type="submit" className="btn btn-primary border-0" disabled={this.state.updateLoader}>{this.state.updateLoader ? <SmallLoaderComponent /> : 'Update'}</button>
                                    </Modal.Footer>
                                </form>
                            )}
                        </Formik>
                    </Modal>
                    :
                    null
                }
                {/* add admin mddal */}
                {this.state.isAddModal ?
                    <Modal show={this.state.isAddModal} onHide={this.closeAddAdminModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                        <Formik initialValues={this.state.addForm} validationSchema={addFormSchema} onSubmit={(values, actions) => this.AddformSubmit(values, actions)}>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (

                                <form action="/" method="POST" onSubmit={handleSubmit}>
                                    <Modal.Header>
                                        <Modal.Title>Add Admin</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="p-4">
                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-1">First Name</label>
                                                    <input type="text" name="fname" className={errors.fname && touched.fname ? "form-control error" : "form-control"} placeholder="First Name" onChange={handleChange} onBlur={handleBlur} value={values.fname} />
                                                    {errors.fname && touched.fname ? <p className="error-text">{errors.fname}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xs-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-1">Last Name</label>
                                                    <input type="text" name="lname" className={errors.lname && touched.lname ? "form-control error" : "form-control"} placeholder="Last Name" onChange={handleChange} onBlur={handleBlur} value={values.lname} />
                                                    {errors.lname && touched.lname ? <p className="error-text">{errors.lname}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xs-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-1">Email</label>
                                                    <input type="email" name="email" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                                    {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xs-12">
                                                <div className="form-group d-block mb-5">
                                                    <label className="mb-1">Password</label>
                                                    <input type="password" name="password" className={errors.password && touched.password ? "form-control error" : "form-control"} placeholder="Password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                                    {errors.password && touched.password ? <p className="error-text">{errors.password}</p> : null}
                                                </div>
                                            </div>
                                        </div>


                                    </Modal.Body>
                                    <Modal.Footer className="d-flex justify-content-between">
                                        <button type="button" className="btn btn-outline-dark" onClick={this.closeAddAdminModal}>Close</button>
                                        <button type="submit" className="btn btn-primary" style={{ minWidth: '100px' }} disabled={this.state.isAddLoader}>{this.state.isAddLoader ? <SmallLoaderComponent /> : 'Save'}</button>
                                    </Modal.Footer>
                                </form>
                            )}
                        </Formik>
                    </Modal>
                    :
                    null
                }
                {/* add admin mddal over */}
            </div>
        )
    }
}

export default withRouter(connect(null)(SuperAdmin))
