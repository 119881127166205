import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { MdAdd } from 'react-icons/md'
import { FiEdit2, FiTrash } from 'react-icons/fi'
import * as ACTION_TYPES from '../../../redux/actions/type';
import AddEducationModal from '../../../components/EducationComponent.js/AddEducationModal';
import EditEducationModal from '../../../components/EducationComponent.js/EditEducationModal';
import { deleteExistEducation, getEducation } from '../../../controller/profile.controller';
import { notifyError } from '../../../helper/Alert';
import ConfirmModal from '../../../components/confirmModal';

class Education extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isUpdate: false,
            educationList: [],
            addEducationModal: false,
            editEducationModal: false,
            deleteEducationModal: false,
            singleData: null,
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getEducationList();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        const { isUpdate } = this.state;
        if (prevState.isUpdate !== isUpdate) {
            if (isUpdate) {
                this.getEducationList();
            }
        }
    }

    isUpdateFunction = (bool) => {
        if (this._unmounted) {
            this.setState({
                isUpdate: bool
            });
        }
    }

    getEducationList = async () => {
        const res = await getEducation();
        // console.log(res);
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    educationList: res.data
                });
                this.isUpdateFunction(false);
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    educationList: []
                })
            }
        }
    }

    openAddEducationModal = () => {
        this.setState({
            addEducationModal: true
        })
    }

    closeAddEducationModal = () => {
        this.setState({
            addEducationModal: false
        })
    }

    openEditEducationModal = (row) => {
        this.setState({
            editEducationModal: true,
            singleData: row
        })
    }

    closeEditEducationModal = () => {
        this.setState({
            editEducationModal: false,
            singleData: null
        })
    }

    openDeleteEducationModal = (row) => {
        this.setState({
            deleteEducationModal: true,
            singleData: row
        })
    }

    closeDeleteModal = () => {
        this.setState({
            deleteEducationModal: false,
            singleData: null
        })
    }

    submitDeleteEducation = async () => {
        const { singleData } = this.state;
        const res = await deleteExistEducation(singleData.id);
        if (res.status) {
            this.isUpdateFunction(true);
            this.closeDeleteModal();
        } else {
            notifyError(res.message)
        }
    }

    nextHandler = async () => {
        const { activeStep, stepHandler, isUpdateProfile } = this.props;
        const { educationList } = this.state;
        if (educationList && educationList.length === 0) {
            return notifyError('Please add Education');
        }
        stepHandler(activeStep + 1);
        isUpdateProfile(true)
    }

    render() {

        const { addEducationModal, educationList, editEducationModal } = this.state;

        return (
            <>
                <div className={'tabs_wrap'}>
                    <div className={'tab_header'}>
                        <h1 className={'tabs_title'}>{`Education`}</h1>
                    </div>
                    <div className={'tab_body pb-5'}>
                        {educationList.length === 0 ? <p className="o-75 mb-5">Add the schools you attended, areas of study, and degrees earned.</p> : null}
                        {educationList && educationList.length > 0 ?
                            <ul className="list-group">
                                {educationList.map((info, i) =>
                                    <li className="list-group-item p-3 mb-3" key={i}>
                                        <div className="row align-items-center">
                                            <div className="col-sm-10 col-xs-12">
                                                <h6 className="fs-4 fw-bold mb-1">{info.degree ? `${info.degree} | ` : ''}{info.schoolName}</h6>
                                                {info.aeraOfStudy && info.aeraOfStudy !== '' ? <p className="mb-0" style={{ fontSize: '13px', opacity: '0.7' }}>{info.aeraOfStudy}</p> : null}
                                                <p className="mb-0" style={{ fontSize: '13px', opacity: '0.7' }}>{`${info.schoolStartYear} - ${info.schoolEndYear}`}</p>
                                            </div>
                                            <div className="col-sm-2 col-xs-12" style={{ textAlign: 'right' }}>
                                                <button type="button" className="bg-transparent border-0 text-secondary" onClick={() => this.openEditEducationModal(info)}><FiEdit2 /></button>
                                                <button type="button" className="bg-transparent border-0 text-danger" onClick={() => this.openDeleteEducationModal(info)}><FiTrash /></button>
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                            :
                            null
                        }
                        <button type="button" onClick={this.openAddEducationModal} className="btn btn-outline-primary d-flex align-items-center lh-base"><MdAdd className="me-2" /> Add Education</button>
                    </div>

                    <div className={'tab_footer'}>
                        <button type="button" className={'btn btn-dark'} style={{ minWidth: '100px' }} onClick={this.nextHandler}>Next</button>
                        {this.props.activeStep > 0 ? <button type="button" className="btn btn-outline-dark" style={{ minWidth: '100px' }} onClick={this.prevHandler}>Back</button> : null}
                    </div>

                </div>
                {addEducationModal ?
                    <AddEducationModal show={addEducationModal} onHide={this.closeAddEducationModal} onSubmit={this.isUpdateFunction} />
                    :
                    null
                }

                {editEducationModal ?
                    <EditEducationModal show={editEducationModal} onHide={this.closeEditEducationModal} data={this.state.singleData} onSubmit={this.isUpdateFunction} />
                    :
                    null
                }

                {this.state.deleteEducationModal ?
                    <ConfirmModal
                        text={'Are you sure you want to delete this education?'}
                        show={this.state.deleteEducationModal}
                        onSubmit={this.submitDeleteEducation}
                        onHide={this.closeDeleteModal}
                    />
                    :
                    null
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    contactDetails: state.pinfo.contactDetails,
    activeStep: state.pinfo.activeStep,
    isGetProfile: state.pinfo.isGetProfile,
    userImage: state.user.userImage
});

const mapDispatchToProps = dispatch => ({
    stepHandler: (val) => dispatch({ type: ACTION_TYPES.STEP_INFO_FREELANCER, payload: val }),
    contactDetailFunc: (obj) => dispatch({ type: ACTION_TYPES.CONTACT_DETAILS, payload: obj }),
    isUpdateProfile: (bool) => dispatch({ type: ACTION_TYPES.UPDATE_FREELANCER_PROFILE, payload: bool }),
    isCallProfile: (bool) => dispatch({ type: ACTION_TYPES.PROFILE_SETP_API_GET, payload: bool })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Education));
