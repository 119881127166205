import { apiHelper } from "../../helper/api.helper";
import { jwtDecode } from "../../helper/jwt.helper";

export const getClients = async () => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }


    const response = await apiHelper('admin/user/get', 'GET', null, header);
    const res =  response.data;
    if(res.status === false){
        return []
    }
    
    return jwtDecode(res.data);
}