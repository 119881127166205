import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import { MdDone } from 'react-icons/md';
// import 'rc-steps/assets/iconfont';
import { connect } from 'react-redux';
import TitleComponent from './TitleComponent';
import CategoryComponent from './CategoryComponent';
import SkillsComponent from './SkillsComponent';
import BudegtComponent from './BudegtComponent';
// import DeadlineComponent from './DeadlineComponent';
import DescriptionComponent from './DescriptionComponent';
import ReviewComponent from './ReviewComponent';
import BGImage from '../../../assets/images/job-post-background.png'
import { jwtDecode } from '../../../helper/jwt.helper';
import '../../../assets/css/postJob.css';

class PostJobComponent extends Component {

    render() {

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 1) {
                return <Redirect to="/freelancer/jobs" />
            }
        }

        // console.log(this.props.stepCurrent);

        return (
            <>
                <section className={`post_job_wrap`}>
                    <div className={'profile_banner'}>
                        <img src={BGImage} alt="banner" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className={`card_wrap p_30 mt_65`}>
                                    <div className={'steps_wrap'}>
                                        <Steps current={this.props.stepCurrent} labelPlacement="vertical" icons={{ finish: <MdDone /> }} >
                                            <Step title={'Title'} />
                                            <Step title={'Category'} />
                                            <Step title={'Skills'} />
                                            <Step title={'Budget'} />
                                            {/* <Step title={'Budget & Deadline'} /> */}
                                            {/* <Step title={'Deadline'} /> */}
                                            <Step title={'Description'} />
                                            <Step title={'Review'} />
                                        </Steps>
                                        {/* <p>active Step: {this.props.stepCurrent}</p> */}

                                        {/* {this.state.currentStep > 0?<button type="button" onClick={this.prevSteps}>Prev</button>:null}
                                    {this.state.currentStep < 6?<button type="button" onClick={this.nextSteps}>Next</button>:null} */}
                                    </div>
                                </div>
                                {this.props.stepCurrent === 0 ? <TitleComponent /> : null}
                                {this.props.stepCurrent === 1 ? <CategoryComponent /> : null}
                                {this.props.stepCurrent === 2 ? <SkillsComponent /> : null}
                                {this.props.stepCurrent === 3 ? <BudegtComponent /> : null}
                                {/* {this.props.stepCurrent === 4 ? <DeadlineComponent /> : null} */}
                                {this.props.stepCurrent === 4 ? <DescriptionComponent /> : null}
                                {this.props.stepCurrent === 5 ? <ReviewComponent /> : null}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => ({
    stepCurrent: state.postJob.currentStep
})

export default withRouter(connect(mapStateToProps)(PostJobComponent));
