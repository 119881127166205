// client
import EditJobComponent from "./views/Client/EditJobComponent";
import FindFreelancerComponent from "./views/Client/FindFreelancerComponent";
import FreelancerProfileComponent from "./views/Client/FreelancerProfileComponent";
import JobDetailsComponent from "./views/Client/JobDetailsComponent";
import MyJobs from "./views/Client/JobsComponent";
import AllJobs from "./views/Client/JobsComponent/AllJobs";
import PostJobComponent from "./views/Client/PostJobComponent";
import ProfileComponent from "./views/Client/ProfileComponent";
import AllContracts from "./views/Client/JobsComponent/AllContracts";
import HireFreelancer from "./views/Client/HireFreelancer";
import ReviewFreelancer from "./views/Client/ReviewFreelancer";
import Deposit from "./views/Client/Deposit";
import ClientContractDetails from "./views/Client/ContractDetails";
import RefundRequest from "./views/Client/RefundRequest";

// common
// auth
import ChangePassComponent from "./views/ChangePassComponent";
import EmailVerify from "./views/EmailVerify";
import ForgotComponent from "./views/ForgotComponent";
import LoginComponent from "./views/LoginComponent";
import SignupComponent from "./views/SignupComponent";
import VerifyComponent from "./views/VerifyComponent";

// pages
import Home from "./views/Home";

// freelancer
import ChangeFreelancerPassword from "./views/freelancer/ChangeFreelancerPassword";
import JobListingComponent from "./views/freelancer/JobListingComponent";
import MyProposalsComponent from "./views/freelancer/MyProposalsComponent";
import ReviewComponent from "./views/freelancer/ReviewComponent";
import MyJobsComponent from "./views/freelancer/MyJobsComponent";
import FreelancerContractDetails from "./views/freelancer/ContractDetails";
import AllContractsComponent from "./views/freelancer/AllContracts";
import ProfessionalInfoComponent from "./views/freelancer/ProfessionalInfoComponent";
import ProfileFreelancerComponent from "./views/freelancer/ProfileComponent";
import ExamSkills from "./views/freelancer/ProfileComponent/ExamSkills";
import RescheduleMeeting from "./views/freelancer/ProfileComponent/RescheduleMeeting";
import ProposalComponent from "./views/freelancer/ProposalComponent";
import SavedJobs from "./views/freelancer/SavedJobs";
import Settings from "./views/freelancer/Settings";
import OffersComponent from "./views/freelancer/Offers";
import OfferDetails from "./views/freelancer/Offers/OfferDetails";

// message
import MessageComponent from "./views/MessageComponent/index";

// TransactionsHistory
import TransactionsHistory from "./views/TransactionsHistory";

// admin
import AdminLoginComponent from './admin/views/login';
import Dashboard from './admin/views/dashboard';
import FreelancerList from "./admin/views/freelancer";
import ClientList from "./admin/views/client";
import JobPostList from "./admin/views/JobPostList";
import JobDetails from "./admin/views/JobDetails";
import Questions from "./admin/views/questions";
import Categories from "./admin/views/categories";
import SuperAdmin from "./admin/views/SuperAdmin";
import AdminProfile from "./admin/views/profile";
import Meetings from "./admin/views/meetings";
import AllContract from "./admin/views/AllContracts";
import ContractDetails from "./admin/views/ContractDetails";
import Withdrawal from "./admin/views/Withdrawal";
import AdminRefundRequest from "./admin/views/RefundRequest";
import Page404 from "./admin/views/404";



export const routes = [
    // common routes
    { path:'/', component:Home, exact:true, isPrivate:false, admin:false },
    { path:'/login', component:LoginComponent, exact:true, isPrivate:false, admin:false },
    { path:'/signup', component:SignupComponent, exact:true, isPrivate:false, admin:false },
    { path:'/forgot-password', component:ForgotComponent, exact:true, isPrivate:false, admin:false },
    { path:'/passwordReset/:id', component:ChangePassComponent, exact:true, isPrivate:false, admin:false },
    { path:'/profile/email-verify', component:EmailVerify, exact:true, isPrivate:true, admin:false },
    { path:'/auth/verify/:id', component:VerifyComponent, exact:true, isPrivate:false, admin:false },

    // client routes
    { path:'/client/my-jobs', component:MyJobs, exact:true, isPrivate:true, admin:false },
    { path:'/client/all-jobs', component:AllJobs, exact:true, isPrivate:true, admin:false },
    { path:'/client/my-contracts', component:AllContracts, exact:true, isPrivate:true, admin:false },
    { path:'/client/my-contracts/:contractID', component:ClientContractDetails, exact:true, isPrivate:true, admin:false },
    { path:'/client/my-jobs/:id', component:JobDetailsComponent, exact:true, isPrivate:true, admin:false },
    { path:'/client/all-jobs/:id', component:JobDetailsComponent, exact:true, isPrivate:true, admin:false },
    { path:'/client/find-freelancer', component:FindFreelancerComponent, exact:true, isPrivate:true, admin:false },
    { path:'/client/freelancer-profile/:id', component:FreelancerProfileComponent, exact:true, isPrivate:true, admin:false },
    { path:'/client/hire/:fid', component:HireFreelancer, exact:true, isPrivate:true, admin:false },
    { path:'/client/post-job', component:PostJobComponent, isPrivate:true, exact:true, admin:false },
    { path:'/client/edit-job/:id', component:EditJobComponent, isPrivate:true, exact:true, admin:false },
    { path:'/client/profile', component:ProfileComponent, isPrivate:true, exact:true, admin:false },
    { path:'/client/review/:jobid', component:ReviewFreelancer, isPrivate:true, exact:true, admin:false },
    { path:'/client/deposit/:jobid', component:Deposit, isPrivate:true, exact:true, admin:false },
    { path:'/client/refund/:jobid', component:RefundRequest, isPrivate:true, exact:true, admin:false },
    
    // freelancer routes
    { path:'/freelancer/professional-info', component:ProfessionalInfoComponent, exact:true, isPrivate:true, admin:false },
    { path:'/freelancer/jobs', component:JobListingComponent, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/my-jobs', component:MyJobsComponent, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/contract/:cid', component:FreelancerContractDetails, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/all-contracts', component:AllContractsComponent, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/proposal/:id', component:ProposalComponent, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/profile', component:ProfileFreelancerComponent, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/profile/exam', component:ExamSkills, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/profile/meeting', component:RescheduleMeeting, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/my-proposals', component:MyProposalsComponent, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/saved-jobs', component:SavedJobs, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/change-password', component:ChangeFreelancerPassword, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/settings', component:Settings, isPrivate:true, exact:false, admin:false },
    { path:'/freelancer/offers', component:OffersComponent, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/offers/:id', component:OfferDetails, isPrivate:true, exact:true, admin:false },
    { path:'/freelancer/review/:jobid', component:ReviewComponent, isPrivate:true, exact:true, admin:false },
    
    // chat
    { path:'/message', component:MessageComponent, isPrivate:true, exact:true, admin:false },
    { path:'/payment/transactions', component:TransactionsHistory, isPrivate:true, exact:true, admin:false },
    
    // admin routes
    // { path:'/admin', component:AdminLoginComponent, exact:true, isPrivate:false, admin:false },
    // { path:'/admin/dashboard', component:Dashboard, exact:true, isPrivate:false, name:'Dashboard', admin:true },
    // { path:'/admin/freelancer', component:Freelancer, exact:true, isPrivate:false, name:'Freelancer', admin:true }
    
]

export const adminRoutes = [
    { path:'/admin', component:AdminLoginComponent, exact:true, isPrivate:false, admin:false },
    { path:'/admin/dashboard', component:Dashboard, exact:true, isPrivate:true},
    { path:'/admin/freelancer', component:FreelancerList, exact:true, isPrivate:true},
    { path:'/admin/client', component:ClientList, exact:true, isPrivate:true},
    { path:'/admin/job-post', component:JobPostList, exact:true, isPrivate:true},
    { path:'/admin/job-detail/:id', component:JobDetails, exact:true, isPrivate:true},
    { path:'/admin/questions', component:Questions, exact:true, isPrivate:true},
    { path:'/admin/services', component:Categories, exact:true, isPrivate:true},
    { path:'/admin/meetings', component:Meetings, exact:true, isPrivate:true},
    { path:'/admin/profile', component:AdminProfile, exact:true, isPrivate:true},
    { path:'/admin/super-admin', component:SuperAdmin, exact:true, isPrivate:true},
    { path:'/admin/contracts', component:AllContract, exact:true, isPrivate:true},
    { path:'/admin/contract/:cid', component:ContractDetails, exact:true, isPrivate:true},
    { path:'/admin/withdrawal', component:Withdrawal, exact:true, isPrivate:true},
    { path:'/admin/refund-request', component:AdminRefundRequest, exact:true, isPrivate:true},
    { path:'**', component:Page404, exact:true, isPrivate:true},
]