import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { jwtDecode } from '../../helper/jwt.helper';
import * as ACTION_TYPES from '../../redux/actions/type';

export class ResultComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFail: 0,
            result: props.examResult,
            userName: '',
            email: ''
        }
    }

    componentDidMount() {
        const { examResult } = this.props;
        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode && decode.email) {
                let count = 0;
                if (Array.isArray(examResult) && examResult.length > 0) {
                    examResult.forEach(info => {
                        if (info.Result > 0) {
                            count = count + 1;
                        }
                    })
                }
                this.setState({
                    result: examResult,
                    isFail: count,
                    userName:decode.name,
                    email:decode.email,
                })
            }
        }
    }

    prevFunction = () => {
        this.props.prevClick();
    }

    nextHandler = () => {
        this.props.isUpdateProfile(true);
        this.props.nextClick();
    }

    tryAgain = () => {
        this.props.tryClick();
    }

    render() {

        const { isFail, result, email } = this.state;
        const { name } = this.props;

        return (
            <div className="d-block p-5 h-50">
                {/* <div className="tab_header">
                    <h1 className={'tabs_title'}>{`Test Report`} </h1>
                </div> */}
                <div className={'tab_body'}>
                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            <h1 className="fs-1 fw-bold text-center">{name}</h1>
                            <p className="text-center">{email}</p>
                            {/* <span className=""><strong>Submi</strong></span>  */}
                        </div>
                        <div className="col-sm-12">
                            <ul className="list-group">
                                {result && result.length > 0 ?
                                    result.map((info, i) =>
                                        <li className="list-group-item pt-3 pb-3" key={i}>
                                            <div className="row align-items-center">
                                                <div className="col-sm-6 col-xs-12">
                                                    <h2 className="fs-4 fw-bold mb-0" style={{ opacity: '0.7' }}>{info.categoryName}</h2>
                                                </div>
                                                <div className="col-sm-2 col-xs-4">
                                                    <p className="mb-0 text-center"><strong className="fs-4">{info.totalTrueAnswer}</strong><span style={{ fontSize: '13px', opacity: '0.8' }}>/{info.totalQuestion}</span></p>
                                                    <p className="mb-0 text-center fs-5" style={{ fontSize: '12px', opacity: "0.8" }}>Total Question</p>
                                                </div>
                                                <div className="col-sm-2 col-xs-4">
                                                    <p className="mb-0 text-center"><strong className="fs-4">{Math.round(info.percentage)}</strong><span style={{ fontSize: '13px', opacity: '0.8' }}>/100</span></p>
                                                    <p className="mb-0 text-center" style={{ fontSize: '12px', opacity: "0.8" }}>Percentage</p>
                                                </div>
                                                <div className="col-sm-2 col-xs-4">
                                                    <p className="mb-0 text-center"><strong className={`fs-4 ${info.Result > 0 ? 'text-success' : 'text-danger'}`}>{info.Result > 0 ? "Pass" : 'Fail'}</strong></p>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                    :
                                    <li className="list-group-item text-center">No result available</li>
                                }
                            </ul>
                        </div>
                    </div>

                </div>
                <div className={'tab_footer'}>
                    {isFail === 0 ?
                        <div>
                            {/* <button type="button" className={'btn btn-dark'} style={{ minWidth: '100px' }} onClick={this.nextHandler}>Skip</button> */}
                            <button type="button" className={'btn btn-outline-danger ms-3'} style={{ minWidth: '100px' }} onClick={this.tryAgain}>Try again</button>
                        </div>
                        :
                        <button type="button" className={'btn btn-success'} style={{ minWidth: '100px' }} onClick={this.nextHandler}>{'Next'}</button>
                    }
                    <button type="button" className="btn btn-outline-dark" style={{ minWidth: '100px' }} onClick={this.prevFunction}>Prev</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) =>({
    name:state.pinfo.userData.name
});

const mapDispatchToProps = dispatch =>({
    isUpdateProfile: (bool) => dispatch({ type: ACTION_TYPES.UPDATE_FREELANCER_PROFILE, payload: bool }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultComponent));
