import React, { Component } from 'react';
// import Link from 'next/link';
import { MdSearch, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFavoriteBorder, MdFavorite, MdRemoveRedEye, MdCreate } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import ReactStars from "react-rating-stars-component";
import { getFreelanceJobList, getSingleJobdetails, jobSaveUnsave } from '../../../controller/jobs.controller';
import moment from 'moment';
import '../../../assets/css/jobs.css';
import { mainCategories } from '../../../controller/categories.controller';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { notifyError } from '../../../helper/Alert';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import { jwtDecode } from '../../../helper/jwt.helper';
import { connect } from 'react-redux';
import review from '../../../controller/review.controller';

class JobListingComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isPageChange: false,
            activeTab: 0,
            isLoader: false,
            isSmallWindow: false,
            filterOpen: false,
            expertise: '',
            expertiseOption: 0,
            search: '',
            jobList: [],
            pageCount: 0,
            page: null,
            jobId: null,
            openDetailsPage: false,
            jobDetails: null,
            reviewList: [],
        }
    }

    componentDidMount() {
        this._unmounted = true;
        if (this._unmounted) {
            this.setState({
                page: 0,
                search: ''
            })
        }
        this.getExpertise();
        this.getJobsList('', 0, '');
        this.checkWindowWidth();
        window.addEventListener('resize', this.checkWindowWidth())
    }

    componentWillUnmount() {
        this._unmounted = false;
        window.removeEventListener('resize', this.checkWindowWidth())
    }
    getClientReview = async (id) => {
        const res = await review.getAllClientReviewById(id);
        console.log(res);
        if (res.status) {
            this.setState({
                reviewList: res.data
            })
        } else {
            this.setState({
                reviewList: []
            })
        }
    }

    getJobsList = async (categoryID, pageNo, searchText) => {
        const pageNumber = (Number(pageNo) + 1);
        // console.log(pageNo)
        if (this._unmounted) {
            this.setState({
                isLoader: true
            })
        }
        const data = {
            categoryID,
            pageNo: pageNumber,
            searchText,
            tab: 'ALL'
            // tab: activeTab === 2 ? 'SAVED' : 'ALL'
        };

        const jobListRes = await getFreelanceJobList(data);
        const pagecount = Math.round(jobListRes.jobCount / 10)
        // console.log(pagecount);
        if (this._unmounted) {
            this.setState({
                isLoader: false,
                jobList: jobListRes.data,
                pageCount: pagecount === 0 ? 1 : pagecount
            })
        }

    }

    tabHandler = (tab) => {
        if (this._unmounted) {
            this.setState({
                activeTab: tab
            })
        }
    }

    searchHandler = (e) => {

        const searchVal = e.target.value;
        if (this._unmounted) {
            this.setState({
                search: searchVal
            })
        }

    }

    searchSubmit = (e) => {
        e.preventDefault();
        this.setState({
            expertise: 0
        })
        this.getJobsList('', 0, this.state.search)
    }

    filterToggleHandler = () => {
        this.setState(state => ({
            filterOpen: !state.filterOpen
        }))
        // setFilterOpen(!filterOpen)
    }

    expertiseClickHandler = (id) => {
        let selected = this.state.expertise;
        // console.log(id);
        if (selected === id) {
            selected = 0;
            //     selected = selected.filter(info => info !== id);
        } else {
            selected = id
            // selected = [...selected, id]
        }

        this.setState({
            expertise: selected,
            search: '',
        })

        this.getJobsList(selected === 0 ? '' : selected, 0, '')
    }

    pageHandler = (pageNo) => {
        // console.log(pageNo);
        if (pageNo.selected) {
            this.setState({
                page: pageNo.selected
            })
            this.getJobsList(this.state.expertise, pageNo.selected, this.state.search)
        } else {
            this.setState({
                page: 0
            })
            this.getJobsList(this.state.expertise, 0, this.state.search)
        }

    }

    pageHandler1 = (pageNo) => {
        // console.log(pageNo);


    }

    getExpertise = async () => {

        const categories = await mainCategories();
        if (categories.length) {
            const options = categories.map((info) => ({ label: info.categoryName, value: info.id }));
            if (this._unmounted) {
                this.setState({
                    expertiseOption: options
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    expertiseOption: []
                })
            }
        }
        // console.log(res);
    }

    checkWindowWidth = () => {
        // console.log(window.innerWidth < 767);
        if (this._unmounted) {
            this.setState({
                isSmallWindow: window.innerWidth < 767
            })
        }
    }

    savejobsFun = async (id) => {
        const res = await jobSaveUnsave(id)
        if (res.status) {
            if (this.state.jobDetails) {
                if (this.state.jobDetails.saved !== null) {
                    this.setState({
                        jobDetails: {
                            ...this.state.jobDetails,
                            saved: this.state.jobDetails.saved === 1 ? 0 : 1
                        }
                    })
                }
            }

            if (this.state.jobList && this.state.jobList.length > 0) {
                const updateArray = this.state.jobList.map((job) => {
                    if (job.id === id) {
                        job.saved = job.saved === 1 ? 0 : 1
                    }
                    return job
                });
                this.setState({
                    jobList: updateArray
                })
            }

            this.getJobsList(this.state.expertise, this.state.page, this.state.search)
        } else {
            notifyError(res.message)
        }
    }

    getJobdetailsApi = async (id) => {
        const res = await getSingleJobdetails(id);
        if (res) {
            this.getClientReview(res?.profileID)
        }
        this.setState({
            jobDetails: res
        })
    }

    viewJobDetails = (id) => {
        this.getJobdetailsApi(id)
        this.setState({
            jobId: id,
            openDetailsPage: true
        })
    }

    closedJobDetails = () => {
        this.setState({
            jobId: null,
            openDetailsPage: false
        })
    }

    submitProposal = () => {
        this.props.history.push(`/freelancer/proposal/${this.state.jobId}`);
    }

    renderJobList = (info, index) => {
        // console.log(info);
        return <li key={index}>
            {/* <button type="button" onClick={() => viewJobDetails(info.jps_id)}> */}
            <div className={'projectList_div'}>
                <div className={'left_wrap'} onClick={() => this.viewJobDetails(info.id)}>
                    <div className="job_title_wrap">
                        <h5 className={'job_title'} onClick={() => this.viewJobDetails(info.id)}>{info.title}</h5>
                        {/* {info.saved && info.saved === 1 ?
                            <button type="button" className="btn-save-btn" onClick={() => this.savejobsFun(info.id)}><MdFavorite /></button>
                            :
                            <button type="button" className="btn-save-btn" onClick={() => this.savejobsFun(info.id)}><MdFavoriteBorder /></button>
                        } */}
                    </div>
                    <div className={'details_text'}>
                        <p className={'job_description'}>{info.description}</p>
                    </div>
                    <ul className={'listing'}>
                        <li>${info.budget}</li>
                        <li>{moment(info.createdAt).format('Do MMM YYYY')}</li>
                        <li>{info.bids_count} bids</li>
                        <li>Job ID: {info.id}</li>
                    </ul>
                    {/* <div className={status_wrap}>
                        <button type="button" className={statusClass}>{info.name}</button>
                    </div> */}
                </div>
                <div className={'right_wrap'}>
                    <div className="job_title_wrap">
                        {/* <h5 className={'job_title'} onClick={() => this.viewJobDetails(info.id)}>{info.title}</h5> */}
                        {info.saved && info.saved === 1 ?
                            <button type="button" className="btn-save-btn" onClick={() => this.savejobsFun(info.id)}><MdFavorite /></button>
                            :
                            <button type="button" className="btn-save-btn" onClick={() => this.savejobsFun(info.id)}><MdFavoriteBorder /></button>
                        }
                    </div>
                </div>
            </div>
            {/* </button> */}
        </li>
    }

    render() {

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 2) {
                return <Redirect to="/client/my-jobs" />
            }
        }

        const { isSmallWindow, isLoader, filterOpen, expertiseOption, expertise, search, pageCount, jobDetails, jobId, jobList, page, openDetailsPage, reviewList } = this.state;

        return (
            <>
                <section className={`jobs_wrap`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                <div className={'filter_wrap'}>
                                    <div className={'main_filter_div'}>
                                        <h3>Find Work</h3>
                                        {isSmallWindow ?
                                            <div className={'filter_icon'} onClick={this.filterToggleHandler}>
                                                <svg width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="filter_menu" transform="translate(-596 -221)">
                                                        <rect id="Rectangle_10456" data-name="Rectangle 248" width="24" height="24" transform="translate(596 221)" fill="#fff" opacity="0"></rect>
                                                        <g id="filter-results-button" transform="translate(596 148.5)">
                                                            <g id="filter" transform="translate(0 76.5)">
                                                                <path id="Path_1079" data-name="Path 1079" d="M9.333,92.5h5.333V89.833H9.333ZM0,76.5v2.667H24V76.5Zm4,9.333H20V83.167H4Z" transform="translate(0 -76.5)" fill="#1a62f5"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className={filterOpen ? `collapse_filter active` : 'collapse_filter'}>
                                        {/* <div className={`${filter_radio} ${division} ${posting}`}>
                                        <button type="button" onClick={() => filterByHandler(1)} className={filterBy === 1 ? active : ''}>Total Jobs</button>
                                        <button type="button" onClick={() => filterByHandler(2)} className={filterBy === 2 ? active : ''}>Total Rating</button>
                                    </div> */}
                                        {expertiseOption.length > 0 ?
                                            <div className={`main_area`}>
                                                <h5>Expertise</h5>
                                                <ul className={`radio_group posting`}>
                                                    {expertiseOption.map((info, i) =>
                                                        <li key={i}>
                                                            <label className={expertise === info.value ? 'active' : ''} onClick={() => this.expertiseClickHandler(info.value)}>{info.label}</label>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                <div className={`jobs_div`}>
                                    {/* shadow-none */}
                                    {/* <ul className={'tabs_ul tab_UL_nav'}>
                                    <li><button type="button" onClick={() => tabHandler(0)} className={activeTab === 0 ? 'active' : ''}>All</button></li>
                                    {/* <li><button type="button" onClick={() => tabHandler(1)} className={activeTab === 1 ? pageStyle.active : ''}>My Hires</button></li> *
                                    <li><button type="button" onClick={() => tabHandler(2)} className={activeTab === 2 ? 'active' : ''}>Saved</button></li>
                                </ul> */}
                                    <div className={'search_jobs'}>
                                        <form action="/" method="POST" onSubmit={this.searchSubmit}>
                                            <div className="form-group">
                                                <input type="text" className={`form-control search_input`} name="search" value={search} onChange={this.searchHandler} placeholder="Search" />
                                                <button type="submit" className={'btn_search'}><MdSearch /></button>
                                            </div>
                                        </form>
                                    </div>
                                    <ul className={`jobsList ${isLoader ? 'p-3' : ''}`}>
                                        {isLoader ?
                                            <MainLoaderComponent color="black" />
                                            :
                                            Array.isArray(jobList) && jobList.length > 0 ?
                                                jobList.map((info, i) => this.renderJobList(info, i))
                                                :
                                                <li className="text-center list-nofound">No Jobs Found.</li>
                                        }
                                    </ul>
                                    {pageCount > 0 ?
                                        <div className={'pagination_wrap_div'}>
                                            <ReactPaginate
                                                initialPage={page}
                                                // forcePage={page}
                                                pageCount={pageCount}
                                                pageRangeDisplayed={4}
                                                breakLabel={'...'}
                                                onPageChange={this.pageHandler}
                                                containerClassName={'pagination_wrap'}
                                                activeClassName={'active'}
                                                previousLabel={<MdKeyboardArrowLeft />}
                                                previousClassName={'previous'}
                                                nextLabel={<MdKeyboardArrowRight />}
                                                nextClassName={'next'}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                <div className="d-block mb-3">
                                    <div className="user_ino d-flex align-items-start mb-0">
                                        {this.props.userImg ?
                                            <img src={this.props.userImg} style={{ width: '30px', height: '30px', borderRadius: '50%' }} alt="user" />
                                            :
                                            null
                                        }
                                        <h5 className="fs-4 fw-bold ms-2 mb-0"> My Profile</h5>
                                    </div>
                                    <p className="lik-job-view fs-5 mb-0" style={{ fontWeight: '500', color: '#072AC8' }}>
                                        <i className="icon-i me-2 fs-3"><MdRemoveRedEye /></i>
                                        <Link to="/freelancer/profile">View Profile</Link>
                                    </p>
                                </div>
                                <div className="d-block mb-3">
                                    <p className="lik-job-view fs-5" style={{ fontWeight: '500', color: '#072AC8' }}>
                                        <Link to="/freelancer/offers">Offers ({this.props.OfferCount})</Link>
                                    </p>
                                </div>
                                {/* <div className="d-block mb-3">
                                    <h5 className="fs-5 fw-bold ms-2 mb-0">Visibility <button type="button" className="btn-edit-btn ms-2"><MdCreate /></button></h5>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section >
                {/* {openDetailsPage ? */}
                <>
                    <div className={openDetailsPage ? `${'bg_overlay'} ${'show'}` : `${'bg_overlay'}`} onClick={this.closedJobDetails}></div>
                    <div className={openDetailsPage ? `${'details_wrap'} ${'show'}` : `${'details_wrap'}`}>

                        <div className={'header_wrap'}>
                            <div className="row">
                                <div className="col-sm-12 d-flex align-items-center">
                                    <button type="button" onClick={this.closedJobDetails} className={'back_wrap'}><MdKeyboardArrowLeft /></button>
                                    {jobDetails && jobDetails.jobPostBidID > 0 ?
                                        <p className={'bid_label text-dark'}>You have already submitted proposals on this job <Link to={`/freelancer/proposal/${jobId}`}><span>View Proposal</span></Link></p>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`${'details_wrap_body'}`}>
                            <div className={`${'wrap_detail'} card border-0`}>
                                <div className="row">
                                    <div className={`${'border_end'} col-sm-12 col-md-12 col-lg-9`}>
                                        <div className={'title_wrap_details'}>
                                            <h3>{jobDetails && jobDetails.title}</h3>
                                        </div>
                                        {/* <div className={category_wrap}>
                                            <h4>Back-End Development</h4>
                                            <p>Posted 33 minutes ago</p>
                                        </div> */}
                                        <div className={'description_wraps'}>
                                            <p>{jobDetails && jobDetails.description}</p>
                                        </div>
                                        <div className={'price_wrap'}>
                                            <p>${jobDetails && jobDetails.budget} <span>Fixed-Price</span></p>
                                        </div>
                                        {/* <div className={type_project}>
                                            <p>Project Type: <span>Ongoing project</span></p>
                                        </div> */}
                                        <div className={'skills_wrap_detail'}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h5>Expertise</h5>
                                                    {/* <p>Back-End Development Deliverables</p> */}
                                                    <ul className={'budges_skills'}>
                                                        {jobDetails && jobDetails.services.length > 0 ?
                                                            jobDetails.services.map((info, i) => <li key={i} className="text-dark">{info.categoryName}</li>)
                                                            :
                                                            null
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="col-sm-6">
                                                    <h5>Skills</h5>
                                                    <ul className={'budges_skills'}>
                                                        {jobDetails && jobDetails.skills.length > 0 ?
                                                            jobDetails.skills.map((info, i) => <li key={i} className="text-dark">{info.categoryName}</li>)
                                                            :
                                                            null
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-3">
                                        <div className={`${'button_wrap'} border-bottom-0`}>
                                            {jobDetails && jobDetails.jobPostBidID === 0 ? <button type="button" className="btn btn-dark" style={{ minWidth: '175px', marginBottom: '15px' }} onClick={this.submitProposal}>Submit a Proposal</button> : null}
                                            <button
                                                type="button"
                                                onClick={() => this.savejobsFun(jobDetails.id)}
                                                className={(jobDetails && jobDetails.saved) && Number(jobDetails.saved) === 1 ? "btn btn-dark" : "btn btn-outline-dark"}
                                                style={{ minWidth: '175px' }}
                                            >
                                                {(jobDetails && jobDetails.saved !== null) && Number(jobDetails.saved) === 1 ?
                                                    <>
                                                        <i style={{ marginRight: '10px' }}><MdFavorite /></i>
                                                        Saved Job
                                                    </>
                                                    :
                                                    <>
                                                        <i style={{ marginRight: '10px' }}><MdFavoriteBorder /></i>
                                                        Save Job
                                                    </>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-12 mt-5">
                                        <div className="card border-0">
                                            <h2 className={title_card_reviw}>Client's recent history (4)</h2>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {reviewList && reviewList.length > 0 ?
                                <div className={`${'wrap_detail'} card border-0 mt-3`}>
                                    <div className="row">
                                        <div className={`${'border_end'} col-sm-12`}>
                                            <div className={`${'title_wrap_details'} mb-0`}>
                                                <h3>Client's recent history</h3>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                {reviewList.map((info, i)=>
                                                <li className="list-group-item pt-4 pb-4">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex flex-column">
                                                            <h5 className="h5">{info?.jobTitle}</h5>
                                                            <div className="d-flex align-items-center">
                                                                <p className="mb-0">{info.rate}</p>
                                                                <div style={{ marginLeft: '10px' }} className={'rating_bl'}>
                                                                    <ReactStars count={5} isHalf={true} value={info.rate} edit={false} size={20} activeColor="#ffc12d" />
                                                                </div>
                                                            </div>
                                                            <p className="mb-0">{info.description}</p>
                                                            {/* <p className="mb-0">To freelancer: <b className="text-primary">John Doe</b></p> */}
                                                        </div>
                                                        <div className="d-flex flex-column w-25">
                                                            <p className="fs-6 mb-1 text-end" style={{ opacity: '0.7' }}>{moment(info.createdAt).format('DD MMM, YYYY')}</p>
                                                            {/* <p className="fs-6 text-end">Fixed-price $300.00</p> */}
                                                        </div>
                                                    </div>
                                                </li>
                                                )}
                                                
                                            </ul>
                                            {/* <div className={`${'pagination_wrap'} border-top pt-4 pb-4`}>
                                                <ReactPaginate
                                                    initialPage={this.state.page}
                                                    // forcePage={this.state.page}
                                                    pageCount={1}
                                                    pageRangeDisplayed={4}
                                                    breakLabel={'...'}
                                                    onPageChange={this.pageHandler1}
                                                    activeClassName={'active'}
                                                    previousLabel={<MdKeyboardArrowLeft />}
                                                    previousClassName={'previous'}
                                                    nextLabel={<MdKeyboardArrowRight />}
                                                    nextClassName={'next'}
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                        {/* <div className="col-sm-12">
                                <div className={footer_wrap}>
                                    <button type="button" className="btn btn-dark">Submit a Proposal</button>
                                    <button type="button" className="btn btn-outline-dark border-0">cancel</button>
                                </div>
                            </div> */}
                    </div>
                </>
                {/* :
                null
            } */}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    userImg: state.user.userImage,
    OfferCount: state.pinfo?.userData?.OfferCount
})

export default withRouter(connect(mapStateToProps)(JobListingComponent));
