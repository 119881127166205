import axios from 'axios';


const url = `https://api.vedain.com`
// const url = `http://13.235.143.120`
// const url = `http://ec2-13-232-194-38.ap-south-1.compute.amazonaws.com:3000`

export const apiHelper = (endPoint, method, data, headers) => {
    return new Promise((resolve, reject) => {
        return axios({
            url:`${url}/${endPoint}`,
            method,
            data,
            headers
        })
        .then((res)=>resolve(res))
        .catch((err)=>reject(err));
    })
}