import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { jwtDecode } from '../helper/jwt.helper';
import localStorage from 'local-storage';

const PrivateRoute = ({component:Component, ...rest}) => {
    const token = localStorage.get('token');
    let tokenDecode = token?jwtDecode(token):null;
    return <Route 
        {...rest}
        render={props=>{
            // console.log(props);
            return token?
                tokenDecode && tokenDecode.email?
                    <Component {...props} />
                    :
                    <Redirect to="/login" />
            :
                <Redirect to="/login" />
        }}
    />
}

export default PrivateRoute;
