import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import DataTable from '../../components/DataTable';
import { JobDetail } from '../../controller/jobs.controller';
import { FiEye } from 'react-icons/fi';
import { Button } from 'react-bootstrap';
import DummyUser from '../../../assets/images/dummy-user.jpg';

class JobDetails extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            profilePath: '',
            bidList: [],
            bidDetails: null,
            details: null,
            descModal: false
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0)
        this.getJobDetails()
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getJobDetails = async () => {
        const { match: { params: { id } } } = this.props;
        const res = await JobDetail(id);
        // console.log(res);
        if (res === null) {
            return this.props.history.goBack();
        }
        if (this._unmounted) {
            this.setState({
                details: res,
                bidList: res.bids,
                profilePath: res.profileURL
            })
        }
    }

    pageHandler = (page, sizePerPage) => {
        console.log(page);
    }

    sizePerPageChange = (sizePerPage, page) => {
        console.log(sizePerPage);
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Bid not found.</p>
        </div>
    }

    headerCenterFormatter = (column) => {
        return <div className="d-flex justify-content-center">{column.text}</div>
    }

    openModalDescription = (row) => {
        if (this._unmounted) {
            this.setState({
                bidDetails: row,
                descModal: true
            })
        }
    }

    closeModalDescription = () => {
        if (this._unmounted) {
            this.setState({
                bidDetails: null,
                descModal: false
            })
        }
    }

    userFormatter = (cell, row) => {
        return <div className="d-flex align-items-center">
            <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }} className="me-2">
                {row.profilePic ?
                    <img style={{width:'100%', objectFit:'cover'}} src={`${this.state.profilePath}${row.profilePic}`} alt="freelancer" />
                    :
                    <img style={{width:'100%', objectFit:'cover'}} src={DummyUser} alt="freelancer" />
                }
            </div>
            <span>{row.userName}</span>

        </div>
    }

    descriptionFormatter = (cell, row) => {
        return <div style={{ maxWidth: '280px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{cell}</div>
    }

    budgetFormatter = (cell) => {
        return <div className="d-flex justify-content-center">${cell}</div>
    }

    DeadlineFormatter = (cell, row) => {
        return `${row.jpbDeadlineValue} ${row.jpbDeadlineType}`
    }

    actionFormatter = (cell, row) => {
        return <div className="d-flex justify-content-center">
            <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.openModalDescription(row)}><FiEye /></button>
        </div>
    }

    statusFormatter = (cell, row) => {
        // console.log(row);
        let classsName = '';
        // let statusName = '';
        switch (row.jpbJobPostStateName) {
            case "Bidding":
                classsName = 'warning';
                // statusName = 'Pending';
                break;
            case "Done":
                classsName = 'success';
                // statusName = 'Active';
                break;
            case "In Progress":
                classsName = 'primary';
                // statusName = 'Pending';
                break;
            default:
                classsName = 'secondary';
                // statusName = 'none'
                break;
        }

        return <div className="d-flex justify-content-center">
            <Button variant={classsName} className={`rounded-pill`} style={{ fontSize: '.775rem', minWidth: '100px' }}>{cell}</Button>
        </div>
    }

    render() {

        const columns = [
            {
                dataField: 'userName',
                text: 'Freelancer',
                sort: true,
                formatter: this.userFormatter,
            },
            {
                dataField: 'jpbDescription',
                text: 'Description',
                sort: true,
                formatter: this.descriptionFormatter,
            },
            {
                dataField: 'jpbDeadlineValue',
                text: 'Deadline',
                sort: true,
                formatter: this.DeadlineFormatter,
            },
            {
                dataField: 'jpbBidAmount',
                text: 'Bid Amount',
                sort: true,
                formatter: this.budgetFormatter,
                headerFormatter: this.headerCenterFormatter
            },
            {
                dataField: 'jpbJobPostStateName',
                text: 'Status',
                sort: true,
                formatter: this.statusFormatter,
                headerFormatter: this.headerCenterFormatter
            },
            {
                dataField: 'action',
                text: 'Action',
                sort: false,
                formatter: this.actionFormatter,
                headerFormatter: this.headerCenterFormatter
            }
        ];

        const { details, bidList } = this.state;
        // console.log(details)

        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3"><strong>Job Details</strong></h1>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Details</h5>
                            </div>
                            <div className="card-body">
                                {details ?
                                    <>
                                        <h6 className="fs-3 mb-3"><b>{details.title}</b></h6>
                                        <p className="fs-5">{details.description}</p>
                                        <p className="fs-5">Post By: <b>{details.name}</b></p>
                                        <p className="fs-5">Budget: <b>${details.budget}</b></p>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Job Bids</h5>
                            </div>
                            <DataTable
                                keyField="jpbID"
                                data={bidList}
                                columns={columns}
                                noDataIndication={this.emptyRender}
                            />

                        </div>
                    </div>
                </div>
                {this.state.descModal ?
                    <Modal show={this.state.descModal} onHide={this.closeModalDescription} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                        <Modal.Header>
                            <Modal.Title>Bid Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            {/* <h6 className="fs-3 mb-3"><b>{this.state.bidDetails.title}</b></h6> */}
                            <p className="fs-5">{this.state.bidDetails.jpbDescription}</p>
                            <p className="fs-5">Bid By: <b>{this.state.bidDetails.userName}</b></p>
                            <p className="fs-5">Deadline: <b>{this.state.bidDetails.jpbDeadlineValue} {this.state.bidDetails.jpbDeadlineType}</b></p>
                            <p className="fs-5">Bid Amount: <b>${this.state.bidDetails.jpbBidAmount}</b></p>
                            <p className="fs-5">Status: <b>{this.state.bidDetails.jpbJobPostStateName}</b></p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary border-0" onClick={this.closeModalDescription}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    :
                    null
                }
            </div>
        )
    }
}

export default withRouter(connect(null)(JobDetails))
