import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
// import IntlTelInput from 'react-intl-tel-input';
// import 'react-intl-tel-input/dist/main.css';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
// import flags from 'react-phone-number-input/flags'
import 'react-phone-number-input/style.css'
import Select from 'react-select';
import { emailValidation, nameValidation } from '../../../helper/formValidation.helper';
import { getCountryList, getStateList } from '../../../controller/common.controller';
import { updateUserProfile } from '../../../controller/profile.controller';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class ProfileModal extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            countryLoading: false,
            stateLoading: false,
            countryOption: [],
            stateOption: [],
            countrySelect: null,
            stateSelect: null,
            form: {
                fname: '',
                email: '',
                phone: '',
                countryCode: null,
                phoneErr: '',
                phoneValid: '',
                countrySelect: null,
                stateSelect: null,
                // citySelect: null,
                city: '',
                errors: {
                    fname: '',
                    email: '',
                    city: ''
                },
                valid: {
                    fname: false,
                    email: false,
                    city: false
                }
            }
        }
    }

    componentDidMount() {
        this.getCountries();
        if (this.props.data.userData) {
            const userData = this.props.data.userData;
            console.log(userData)
            this.setState({
                countrySelect: userData.countryID ? { value: userData.countryID, label: userData.countryName } : null,
                stateSelect: userData.stateID ? { value: userData.stateID, label: userData.statesName } : null,
                form: {
                    ...this.state.form,
                    fname: userData.name,
                    email: userData.email,
                    phone: userData.mobile ? userData.mobile : '',
                    countryCode: userData.mobilePrefix ? userData.mobilePrefix : '',
                    countrySelect: userData.countryID ? { value: userData.countryID, label: userData.countryName } : null,
                    stateSelect: userData.stateID ? { value: userData.stateID, label: userData.statesName } : null,
                    phoneValid: userData.mobile ? true : false,
                    city: userData.city ? userData.city : '',
                    valid: {
                        ...this.state.form.valid,
                        fname: userData.name ? true : false,
                        email: userData.email ? true : false,
                        city: userData.city ? true : false
                    }
                }
            })
        }
    }

    getCountries = async () => {
        const countries = await getCountryList();
        // console.log(countries)
        this.setState({
            countryLoading: true
        })
        if (countries.length > 0) {

            const options = countries.map((country) => ({ value: country.id, label: country.name }))

            this.setState({
                countryLoading: false,
                countryOption: options
            });

        } else {
            this.setState({
                countryOption: [],
                countryLoading: false
            })
        }
    }

    getStates = async (countryId) => {
        const states = await getStateList(countryId);
        // console.log(states)
        this.setState({
            stateLoading: true
        })
        if (states.length > 0) {

            const options = states.map((state) => ({ value: state.id, label: state.name }))

            this.setState({
                stateLoading: false,
                stateOption: options
            });

        } else {
            this.setState({
                stateOption: [],
                stateLoading: false
            })
        }
    }

    inputhandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        }, () => this.formValidation(name, value))
    }

    formValidation = (name, value) => {
        let error = this.state.form.errors;
        let valid = this.state.form.valid;
        switch (name) {
            case 'fname':
                const fres = nameValidation(value);
                valid.fname = fres.status;
                error.fname = fres.message;
                break;
            case 'email':
                const eres = emailValidation(value);
                valid.email = eres.status;
                error.email = eres.message;
                break;
            case 'city':
                const res = new RegExp(/^([a-zA-Z_$][a-zA-Z\\d_$]*)$/);
                if (value.length > 0) {
                    if (res.test(value)) {
                        error.city = '';
                        valid.city = true;
                    } else {
                        error.city = 'City must be string.';
                        valid.city = false;
                    }
                } else {
                    error.city = 'City must be required.';
                    valid.city = false;
                }
                break;
            default:
                break;
        }

        this.setState({
            form: {
                ...this.state.form,
                errors: error,
                valid: valid
            }
        })
    }

    phoneHandler = (number) => {
        // console.log(isValid)
        // console.log(countryObj)
        let error = '';
        let isValid = false;
        let countryCode = '';
        if (number) {
            
            if (isValidPhoneNumber(number) === false) {
                error = 'Invalid phone number';
                isValid = false;
                countryCode = ''
            } else {
                const coun = parsePhoneNumber(number);
                const code = getCountryCallingCode(coun.country);
                // console.log(code) 
                error = '';
                isValid = true;
                countryCode = code
            }
            
        } else {
            error = "Phone number required";
            isValid = false;
            countryCode = ''
        }
        // console.log(number);
        this.setState({
            form: {
                ...this.state.form,
                phone: number,
                phoneErr: error,
                phoneValid: isValid,
                countryCode: countryCode
            }
        });

    }

    countryHandler = (selectedOption) => {
        // console.log(selectedOption);
        this.setState({
            countrySelect: selectedOption,
            stateSelect: null,
            stateOption: [],
            form: {
                ...this.state.form,
                countrySelect: selectedOption,
                stateSelect: null,
            }
        })

        if (selectedOption) {
            this.getStates(selectedOption.value)
        }
    }

    stateHandler = (selectedOption) => {
        // console.log(selectedOption);
        this.setState({
            stateSelect: selectedOption,
            form: {
                ...this.state.form,
                stateSelect: selectedOption
            }
        })
    }

    userSubmit = async (e) => {
        e.preventDefault();
        const { countrySelect, stateSelect, form: { fname, phone, countryCode, city, valid } } = this.state;
        if (countrySelect === null) {
            return notifyError('Please select Country')
        }

        if (stateSelect === null) {
            return notifyError('Please select State')
        }

        if (valid.fname === false) {
            return notifyError('Full Name must be required!')
        }

        if (phone === '') {
            return notifyError('Phone number must be required!')
        }

        if (city === '') {
            return notifyError('City must be required!')
        }

        this.setState({
            loader: true
        });

        const data = {
            name: fname,
            mobilePrefix: countryCode,
            mobile: phone,
            countryID: countrySelect.value,
            stateID: stateSelect.value,
            city: city
        }

        const res = await updateUserProfile(data);
        if (res.status) {
            this.setState({
                loader: false
            });
            this.props.data.updateFunction();
            this.props.data.onHide();
            notifySuccess(res.message);
        } else {
            this.setState({
                loader: false
            });
            notifyError(res.message)
        }
    }

    render() {
        const { show, onHide } = this.props.data;
        const { loader, countryLoading, stateLoading, countryOption, stateOption, form: { fname, email, phone, city, errors, phoneErr }, countrySelect, stateSelect } = this.state;
        // console.log(countrySelect)
        return (
            <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <h6 className="h5 pb-3 border-bottom">Update Profile</h6>
                    <form action="/" method="POST" onSubmit={this.userSubmit}>
                        <div className="row pb-4 pt-4">
                            <div className="col-sm-6">
                                <div className="form-group mb-4">
                                    <input type="text" name="fname" className={errors.fname !== "" ? "form-control error" : "form-control"} placeholder="Full Name" onChange={this.inputhandleChange} value={fname} />
                                    {errors.fname !== "" ? <p className="error-text">{errors.fname}</p> : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-4">
                                    <input type="email" name="email" className={errors.email !== '' ? "form-control error" : "form-control"} placeholder="Email" onChange={this.inputhandleChange} value={email} disabled />
                                    {errors.email !== '' ? <p className="error-text">{errors.email}</p> : null}
                                </div>
                            </div>
                            {/* <div className="col-sm-6">
                                                        <div className="form-group mb-4">
                                                            <input type="email" name="email" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder="Email" onChange={this.handleChange} onBlur={this.handleBlur} value={values.email} />
                                                            {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                                                        </div>
                                                    </div> */}
                            <div className="col-sm-6">
                                <div className="form-group mb-4">
                                    <PhoneInput
                                        // flags={flags}
                                        // containerClassName={`intl-tel-input phone_container`}
                                        // inputClassName="form-control"
                                        placeholder="Enter phone number"
                                        // withCountryCallingCode={true}
                                        onChange={this.phoneHandler}
                                        // onPhoneNumberChange={this.phoneHandler}
                                        value={phone}
                                    />
                                    {/* <IntlTelInput
                                        containerClassName={`intl-tel-input phone_container`}
                                        inputClassName="form-control"
                                        placeholder="Enter phone number"
                                        onPhoneNumberChange={this.phoneHandler}
                                        value={phone}
                                    /> */}
                                    {phoneErr !== '' ? <p className="error-text">{phoneErr}</p> : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-4">
                                    <Select instanceId="countrySelect" options={countryOption} isLoading={countryLoading} name="countrySelect" value={countrySelect} onChange={this.countryHandler} placeholder="Country" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-4">
                                    <Select instanceId="stateSelect" options={stateOption} isLoading={stateLoading} name="stateSelect" value={stateSelect} onChange={this.stateHandler} placeholder="State" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-4">
                                    <input type="text" name="city" className={errors.city !== '' ? "form-control error" : "form-control"} placeholder="City" onChange={this.inputhandleChange} value={city} />
                                    {errors.city !== '' ? <p className="error-text">{errors.city}</p> : null}
                                </div>
                                {/* <div className="form-group mb-4">
                                                        <Select instanceId="citySelect" options={options} name="citySelect" defaultValue={citySelect} onChange={this.countryHandler} placeholder="City" />
                                                    </div> */}
                            </div>
                        </div>
                        <div className="form-group pt-3 border-top d-flex justify-content-between">
                            <button type="button" className="btn_cancle btn btn-outline-dark" onClick={onHide}>Cancel</button>
                            <button type="submit" aria-label="Update" className="btn btn-primary min-200 max-200" disabled={loader}>{loader ? <SmallLoaderComponent /> : 'Update'}</button>
                        </div>
                    </form>
                </Modal.Body>
                {/* <Modal.Footer>
                    <button type="button" className="btn_cancle btn btn-outline-dark" onClick={onHide}>Cancel</button>
                    <button type="button" className="btn btn-primary min-200 max-200 display-block ml-auto" disabled={loader} >{loader ? <SmallLoaderComponent /> : 'Update'}</button>
                </Modal.Footer> */}
            </Modal>
        )
    }
}

export default withRouter(connect(null)(ProfileModal))