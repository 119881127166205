import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import IntroComponent from '../../../components/ExamComponents/IntroComponent';
import ExamComponent from '../../../components/ExamComponents/ExamComponent';
import ResultComponent from '../../../components/ExamComponents/ResultComponent';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { notifyError } from '../../../helper/Alert';
import { getExam, getExamResult } from '../../../controller/exam.controller';

const TestComponent = (props) => {

    const [isStart, setIsStart] = useState(false);
    const [isFinishTest, setIsFinishTest] = useState(false)
    const [examList, setExamList] = useState([]);
    const [examOver, setExamOver] = useState('');
    // const [examWithAnswer, setExamWithAnswer] = useState([]);
    const [examResult, setExamResult] = useState([]);
    // const [notExam, setNotExam] = useState(false);
    // const [examError, setExamError] = useState('');

    const getExamList = async () => {

        const { expertise } = props;
        let ids = []
        if (expertise && expertise.skillsSelected && expertise.skillsSelected.length > 0) {
            ids = expertise.skillsSelected.map(info => info.value);
        }
        if (Array.isArray(ids) && ids.length > 0) {
            const res = await getExam(ids)
            // console.log(res);
            if (res.status) {
                setExamList(res.data)
                setIsStart(true)
            } else {
                setExamOver('Exam try is over. you are not able to doing again.')
                setExamList([])
                notifyError(res.message)
            }
        }
    }

    const startExam = () => {
        getExamList();
    }

    const tryAgainFunction = () => {
        getExamList();
        setIsFinishTest(false);
    }

    const prevHandler = () => {
        const { activeStep, stepHandler } = props;
        stepHandler(activeStep - 1)
    }

    const nextHandler = () => {
        const { activeStep, stepHandler } = props;
        stepHandler(activeStep + 1)
    }

    const submitExam = async (data) => {
        const { expertise } = props;
        let ids = []
        if (expertise && expertise.skillsSelected && expertise.skillsSelected.length > 0) {
            ids = expertise.skillsSelected.map(info => info.value);
        }
        data.categoryID = ids
        const res = await getExamResult(data);
        // console.log(res)
        if (res.status) {
            setExamResult(res.data);
            setIsFinishTest(true);
        } else {
            setIsFinishTest(true);
            setExamResult([])
            notifyError(res.message)
        }
    }

    useEffect(() => {
        const userData = props.data;
        // console.log(userData);
        if(userData && userData.examTried && userData.examTried === 2){
            setExamOver('Exam try is over. you are not able to doing again.')
        }
    }, [props])

    return (
        <div className={'tabs_wrap p-0'}>
            {examOver !== ''?
                <div className="d-block p-5 h-50">
                    <div className={'tab_body'}>
                        <h1 className="fs-4 fw-normal lh-lg text-center">{examOver}</h1>
                    </div>
                </div>
            :
            !isStart ?
                <IntroComponent mins={30} mainCategory={props.categorySelected.label} skillIds={props.skillsSelected} onContinue={startExam} onPrev={prevHandler} />
                :
                !isFinishTest ?
                    <ExamComponent mins={30} questionList={examList} submitExam={submitExam} />
                    :
                    <ResultComponent
                        examResult={examResult}
                        prevClick={prevHandler}
                        nextClick={nextHandler} tryClick={tryAgainFunction}
                    />
            }
        </div>
    )
}

// class TestComponent extends Component {

//     _unmounted = false;

//     interValTimer = null

//     constructor(props) {
//         super(props);
//         this.state = {
//             isStart: false,
//             examList: [],
//             notExam: false,
//             examError: ''
//         }
//     }

//     componentDidMount() {
//         this._unmounted = true;
//         window.scrollTo(0, 0)
//         this.getExamList();
//     }

//     componentWillUnmount() {
//         this._unmounted = false;
//     }

//     // componentDidUpdate(prevProps){

//     //     const { isGetProfile, isCallProfile } = this.props;

//     //     if(prevProps.isGetProfile !== isGetProfile){
//     //         if(isGetProfile){
//     //             isCallProfile(false)
//     //         }
//     //     }
//     // }

//     getExamList = async () => {

//         const { expertise } = this.props;
//         let ids = []
//         if (expertise && expertise.skillsSelected && expertise.skillsSelected.length > 0) {
//             ids = expertise.skillsSelected.map(info => info.value);
//         }
//         if (Array.isArray(ids) && ids.length > 0) {
//             const res = await getExam(ids)
//             console.log(res);
//             if (res.status) {
//                 if (this._unmounted) {
//                     this.setState({
//                         examList: res.data,
//                         notExam: false,
//                         examError: ''
//                     })
//                 }
//             } else {
//                 if (this._unmounted) {
//                     this.setState({
//                         examList: [],
//                         notExam: true,
//                         examError: res.message
//                     })
//                 }
//             }
//         }
//         console.log('else');
//     }

//     startExam = () => {
//         if (this._unmounted) {
//             this.setState({
//                 isStart: true
//             })
//         }
//     }

//     prevHandler = () => {
//         const { activeStep, stepHandler } = this.props;
//         stepHandler(activeStep - 1)
//     }



//     render() {

//         const { isStart, examList } = this.state;

//         // console.log(this.state.examList);
//         return (
//             <div className={'tabs_wrap p-0'}>
//                 {!isStart ?
//                     <IntroComponent mins={0} noOfQuestion={examList.length} onContinue={this.startExam} onPrev={this.prevHandler} />
//                     :
//                     null
//                 }
//             </div>
//         )
//     }
// }

const mapStateToProps = state => ({
    expertise: state.pinfo.expertise,
    activeStep: state.pinfo.activeStep,
    isGetProfile: state.pinfo.isGetProfile,
    categorySelected: state.pinfo.expertise.categorieSelected,
    skillsSelected: state.pinfo.expertise.skillsSelected,
});

const mapDispatchToProps = dispatch => ({
    stepHandler: (val) => dispatch({ type: ACTION_TYPES.STEP_INFO_FREELANCER, payload: val }),
    isCallProfile: (bool) => dispatch({ type: ACTION_TYPES.PROFILE_SETP_API_GET, payload: bool })
    // expertiseSubmit:(obj)=>dispatch({type:ACTION_TYPES.EXPERTISE_MODULE, payload:obj})
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestComponent));