import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFavoriteBorder, MdFavorite } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import ReactStars from "react-rating-stars-component";
import { getFreelanceJobList, getSingleJobdetails, jobSaveUnsave } from '../../../controller/jobs.controller';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';
import { notifyError } from '../../../helper/Alert';
import { jwtDecode } from '../../../helper/jwt.helper';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';

import '../../../assets/css/jobs.css';

const SavedJobs = () => {

    const router = useHistory();

    const [isLoader, setIsLoader] = useState(false);
    const [jobList, setJobList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(null);
    const [jobId, setJobId] = useState(null);
    const [openDetailsPage, setOpenDetailsPage] = useState(false);
    const [jobDetails, setJobDetails] = useState(null)

    const getJobsList = async (categoryID, pageNo, searchText) => {
        const pageNumber = (Number(pageNo) + 1);
        // console.log(pageNo)
        setIsLoader(true);
        const data = {
            categoryID: '',
            pageNo: pageNumber,
            searchText: '',
            tab: 'SAVED'
        };

        const jobListRes = await getFreelanceJobList(data);
        const pagecount = Math.round(jobListRes.jobCount / 10)
        // console.log(pagecount);
        setIsLoader(false);
        setJobList(jobListRes.data);
        setPageCount(pagecount === 0 ? 1 : pagecount);

    }

    const pageHandler = (pageNo) => {
        // console.log(pageNo);
        if (pageNo.selected) {
            setPage(pageNo.selected)
            getJobsList('', pageNo.selected, '')
        } else {
            setPage(0)
            getJobsList('', 0, '')
        }

    }

    const savejobsFun = async (id) => {
        const res = await jobSaveUnsave(id)
        if (res.status) {
            if (jobDetails) {
                if (jobDetails.saved !== null) {
                    setJobDetails({
                        ...jobDetails,
                        saved: jobDetails.saved === 1 ? 0 : 1
                    })
                }
            }

            if (jobList && jobList.length > 0) {
                const updateArray = jobList.map((job) => {
                    if (job.id === id) {
                        job.saved = job.saved === 1 ? 0 : 1
                    }
                    return job
                });
                setJobList(updateArray);
            }

            getJobsList('', page, '')
        } else {
            notifyError(res.message)
        }
    }

    const getJobdetailsApi = async (id) => {
        const res = await getSingleJobdetails(id);
        setJobDetails(res);
    }

    useEffect(() => {

        if (!localStorage.getItem('token')) {
            return router.push('/login')
        }

        if (localStorage.getItem('token')) {
            const decode = jwtDecode(localStorage.getItem('token'))
            if (decode && Number(decode.profileType) === 2) {
                return router.push('/client/jobs');
            }

            // if (decode && Number(decode.profileType) === 1) {
            //     return router.push('/freelancer/jobs');
            // }
        }

        // window.addEventListener('resize', checkWindowWidth)
        // setIsSmallWindow(window.innerWidth < 767);

        setPage(0);
        // getJobsList('', 0, '')

        // return () => {
        //     window.removeEventListener('resize', checkWindowWidth)
        // }

    }, [])

    const viewJobDetails = (id) => {
        setJobId(id);
        getJobdetailsApi(id)
        setOpenDetailsPage(true);
    }

    const closedJobDetails = () => {
        setJobId(null);
        setOpenDetailsPage(false);
    }

    const submitProposal = () => {
        router.push(`/freelancer/proposal/${jobId}`);
    }

    const renderJobList = (info, index) => {
        // console.log(info);
        return <li key={index}>
            {/* <button type="button" onClick={() => viewJobDetails(info.jps_id)}> */}
            <div className={'projectList_div'} >
                <div className={'left_wrap'} onClick={() => viewJobDetails(info.id)}>
                    <div className="job_title_wrap">
                        <h5 className={'job_title'}>{info.title}</h5>

                    </div>
                    <div className={'details_text'}>
                        <p className={'job_description'}>{info.description}</p>
                    </div>
                    <ul className={'listing'}>
                        <li>${info.budget}</li>
                        <li>{moment(info.createdAt).format('Do MMM YYYY')}</li>
                        <li>{info.bids_count} bids</li>
                        <li>Job ID: {info.id}</li>
                    </ul>
                    {/* <div className={status_wrap}>
                        <button type="button" className={statusClass}>{info.name}</button>
                    </div> */}
                </div>
                <div className={'right_wrap'}>
                    <div className="job_title_wrap">
                        {info.saved && info.saved === 1 ?
                            <button type="button" className="btn-save-btn" onClick={() => savejobsFun(info.id)}><MdFavorite /></button>
                            :
                            <button type="button" className="btn-save-btn" onClick={() => savejobsFun(info.id)}><MdFavoriteBorder /></button>
                        }
                    </div>
                </div>
            </div>
            {/* </button> */}
        </li>
    }

    return (
        <>
            <section className={`jobs_wrap`}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h1 className={'pageTitle'}>Saved jobs</h1>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
                            <div className={`jobs_div`}>
                                <ul className={`jobsList ${isLoader ? 'p-3' : ''}`}>
                                    {isLoader ?
                                        <MainLoaderComponent color="black" />
                                        :
                                        Array.isArray(jobList) && jobList.length > 0 ?
                                            jobList.map((info, i) => renderJobList(info, i))
                                            :
                                            <li className="text-center list-nofound">No Jobs Found.</li>
                                    }
                                </ul>
                                {pageCount > 0 ?
                                    <div className={'pagination_wrap_div'}>
                                        <ReactPaginate
                                            initialPage={page}
                                            // forcePage={page}
                                            pageCount={pageCount}
                                            pageRangeDisplayed={4}
                                            breakLabel={'...'}
                                            onPageChange={pageHandler}
                                            containerClassName={'pagination_wrap'}
                                            activeClassName={'active'}
                                            previousLabel={<MdKeyboardArrowLeft />}
                                            previousClassName={'previous'}
                                            nextLabel={<MdKeyboardArrowRight />}
                                            nextClassName={'next'}
                                        />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <>
                <div className={openDetailsPage ? `${'bg_overlay'} ${'show'}` : `${'bg_overlay'}`} onClick={closedJobDetails}></div>
                <div className={openDetailsPage ? `${'details_wrap'} ${'show'}` : `${'details_wrap'}`}>

                    <div className={'header_wrap'}>
                        <div className="row">
                            <div className="col-sm-12 d-flex align-items-center">
                                <button type="button" onClick={closedJobDetails} className={'back_wrap'}><MdKeyboardArrowLeft /></button>
                                {jobDetails && jobDetails.jobPostBidID > 0 ?
                                    <p className={'bid_label text-dark'}>You have already submitted proposals on this job <Link to={`/freelancer/proposal/${jobId}`}><span>View Proposal</span></Link></p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`${'details_wrap_body'}`}>
                        <div className={`${'wrap_detail'} card border-0`}>
                            <div className="row">
                                <div className={`${'border_end'} col-sm-12 col-md-12 col-lg-9`}>
                                    <div className={'title_wrap_details'}>
                                        <h3>{jobDetails && jobDetails.title}</h3>
                                    </div>
                                    {/* <div className={category_wrap}>
                                            <h4>Back-End Development</h4>
                                            <p>Posted 33 minutes ago</p>
                                        </div> */}
                                    <div className={'description_wraps'}>
                                        <p>{jobDetails && jobDetails.description}</p>
                                    </div>
                                    <div className={'price_wrap'}>
                                        <p>${jobDetails && jobDetails.budget} <span>Fixed-Price</span></p>
                                    </div>
                                    {/* <div className={type_project}>
                                            <p>Project Type: <span>Ongoing project</span></p>
                                        </div> */}
                                    <div className={'skills_wrap_detail'}>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <h5>Expertise</h5>
                                                {/* <p>Back-End Development Deliverables</p> */}
                                                <ul className={'budges_skills'}>
                                                    {jobDetails && jobDetails.services.length > 0 ?
                                                        jobDetails.services.map((info, i) => <li key={i} className="text-dark">{info.categoryName}</li>)
                                                        :
                                                        null
                                                    }
                                                </ul>
                                            </div>
                                            <div className="col-sm-6">
                                                <h5>Skills</h5>
                                                <ul className={'budges_skills'}>
                                                    {jobDetails && jobDetails.skills.length > 0 ?
                                                        jobDetails.skills.map((info, i) => <li key={i} className="text-dark">{info.categoryName}</li>)
                                                        :
                                                        null
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-3">
                                    <div className={`${'button_wrap'} border-bottom-0`}>
                                        {jobDetails && jobDetails.jobPostBidID === 0 ? <button type="button" className="btn btn-dark" style={{ minWidth: '175px', marginBottom: '15px' }} onClick={submitProposal}>Submit a Proposal</button> : null}
                                        <button
                                            type="button"
                                            onClick={() => savejobsFun(jobDetails.id)}
                                            className={(jobDetails && jobDetails.saved) && Number(jobDetails.saved) === 1 ? "btn btn-dark" : "btn btn-outline-dark"}
                                            style={{ minWidth: '175px' }}
                                        >
                                            {(jobDetails && jobDetails.saved !== null) && Number(jobDetails.saved) === 1 ?
                                                <>
                                                    <i style={{ marginRight: '10px' }}><MdFavorite /></i>
                                                    Saved Job
                                                </>
                                                :
                                                <>
                                                    <i style={{ marginRight: '10px' }}><MdFavoriteBorder /></i>
                                                    Save Job
                                                </>
                                            }
                                        </button>
                                    </div>
                                </div>
                                {/* <div className="col-sm-12 mt-5">
                                        <div className="card border-0">
                                            <h2 className={title_card_reviw}>Client's recent history (4)</h2>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                        <div className={`${'wrap_detail'} card border-0 mt-3`}>
                            <div className="row">
                                <div className={`${'border_end'} col-sm-12`}>
                                    <div className={`${'title_wrap_details'} mb-0`}>
                                        <h3>Client's recent history (4)</h3>
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item pt-4 pb-4">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <h5 className="h5">QA</h5>
                                                    <div className="d-flex align-items-center">
                                                        <p className="mb-0">4.5</p>
                                                        <div style={{ marginLeft: '10px' }} className={'rating_bl'}>
                                                            <ReactStars count={5} value={3} edit={false} size={20} activeColor="#ffc12d" />
                                                        </div>
                                                    </div>
                                                    <p className="mb-0">Very good client, and he know what he need, this make the project veary easy. Would like to work with him in next projects. Thanks!</p>
                                                    {/* <p className="mb-0">To freelancer: <b className="text-primary">John Doe</b></p> */}
                                                </div>
                                                <div className="d-flex flex-column w-25">
                                                    <p className="fs-6 mb-1 text-end" style={{ opacity: '0.5' }}>25 Feb 2021</p>
                                                    <p className="fs-6 text-end">Fixed-price $300.00</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item pt-4 pb-4">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <h5 className="h5">QA</h5>
                                                    <div className="d-flex align-items-center">
                                                        <p className="mb-0">4.5</p>
                                                        <div style={{ marginLeft: '10px' }} className={'rating_bl'}>
                                                            <ReactStars count={5} value={3} edit={false} size={20} activeColor="#ffc12d" />
                                                        </div>
                                                    </div>
                                                    <p className="mb-0">Very good client, and he know what he need, this make the project veary easy. Would like to work with him in next projects. Thanks!</p>
                                                    {/* <p className="mb-0">To freelancer: <b className="text-primary">John Doe</b></p> */}
                                                </div>
                                                <div className="d-flex flex-column w-25">
                                                    <p className="fs-6 mb-1 text-end" style={{ opacity: '0.5' }}>25 Feb 2021</p>
                                                    <p className="fs-6 text-end">Fixed-price $300.00</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className={`${'pagination_wrap'} border-top pt-4 pb-4`}>
                                        <ReactPaginate
                                            initialPage={page}
                                            // forcePage={this.state.page}
                                            pageCount={1}
                                            pageRangeDisplayed={4}
                                            breakLabel={'...'}
                                            onPageChange={pageHandler}
                                            activeClassName={'active'}
                                            previousLabel={<MdKeyboardArrowLeft />}
                                            previousClassName={'previous'}
                                            nextLabel={<MdKeyboardArrowRight />}
                                            nextClassName={'next'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-sm-12">
                                <div className={footer_wrap}>
                                    <button type="button" className="btn btn-dark">Submit a Proposal</button>
                                    <button type="button" className="btn btn-outline-dark border-0">cancel</button>
                                </div>
                            </div> */}
                </div>
            </>
        </>
    )
}

export default SavedJobs
