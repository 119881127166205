import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { yearCount, monthOption } from '../../helper/array.helper';
import Select from 'react-select';
import { notifyError, notifySuccess } from '../../helper/Alert';
import { upadteExistEmployment } from '../../controller/profile.controller';

class EditEducationModal extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            years: [],
            months: [],
            isPresent: false,
            title: '',
            company: '',
            description: '',
            startMonth: null,
            startYear: null,
            endMonth: null,
            endYear: null,
            startMonthError: '',
            startYearError: '',
            endMonthError: '',
            endYearError: '',
            startMonthValid: false,
            startYearValid: false,
            endYearValid: false,
            endMonthValid: false,
            errors: {
                title: '',
                company: '',
                description:''
            },
            validation: {
                title: false,
                company: false
            }
        }
    }

    componentDidMount() {
        this._unmounted = true;
        const years = yearCount(1978);
        this.updateData();
        if (this._unmounted) {

            this.setState({
                years: years,
                months: monthOption,
            })
        }

    }

    componentWillUnmount() {
        this._unmounted = false
    }

    updateData = () => {
        const data = this.props.data;
        if (data) {
            const monthObj = monthOption.find(month => month.value === data.startMonth)
            const endMonthObj = monthOption.find(month => month.value === data.endMonth)
            this.setState({
                isPresent: data.present > 0,
                title: data.title ? data.title : '',
                company: data.company ? data.company : '',
                description: data.description ? data.description : '',
                startMonth: monthObj,
                startYear: {label:data.startYear, value:data.startYear},
                endMonth: endMonthObj,
                endYear: {label:data.endYear, value:data.endYear},
                startMonthError: '',
                startYearError: '',
                endMonthError: '',
                endYearError: '',
                startMonthValid: monthObj?true:false,
                startYearValid: data.startYear > 0?true:false,
                endYearValid: data.endYear > 0?true:false,
                endMonthValid: endMonthObj?true:false,
                errors: {
                    title: '',
                    company: '',
                    description:''
                },
                validation: {
                    title: data.title ? true : false,
                    company: data.company ? true : false
                }
            })
        }
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.validationForm(name, value))
    }

    validationForm = (name, value) => {
        let error = this.state.errors;
        let valid = this.state.validation;
        switch (name) {
            case 'title':
                valid.title = value.length > 0;
                error.title = valid.title ? '' : 'Title must be required!'
                break;
            case 'company':
                valid.company = value.length > 0;
                error.company = valid.company ? '' : 'Company name must be required!'
                break;
            default:
                break;
        }

        this.setState({
            errors: error,
            validation: valid
        })
    }

    startMonthHandler = (selectedOption) => {
        this.setState({
            startMonth: selectedOption,
            startMonthError: '',
            startMonthValid: true
        })
    }

    startYearHandler = (selectedOption) => {
        // console.log(date);
        this.setState({
            startYear: selectedOption,
            startYearError: '',
            startYearValid: true
        })
    }

    endMonthHandler = (selectedOption) => {
        // console.log(date);
        this.setState({
            endMonth: selectedOption,
            endMonthError: '',
            endMonthValid: true
        })
    }

    endYearHandler = (selectedOption) => {
        // console.log(date);
        this.setState({
            endYear: selectedOption,
            endYearError: '',
            endYearValid: true
        })
    }

    currentWorkHandler = (e) => {
        this.setState((state, props) => ({
            isPresent: !state.isPresent
        }))
    }

    formSubmit = async (e) => {
        e.preventDefault();
        const { title, company, isPresent, description, startYear, startMonth, endYear, endMonth, startYearValid, startMonthValid, endYearValid, endMonthValid, validation } = this.state;
        // const diffDate = moment(endYear).diff(moment(startYear));

        if (validation.company === false) {
            return notifyError('Company name must be required!')
        }

        if (validation.title === false) {
            return notifyError('title name must be required!')
        }

        if (startMonthValid === false) {
            return notifyError('Period must be required!')
        }
        if (startYearValid === false) {
            return notifyError('Period must be required!')
        }

        if (!isPresent) {

            if (endMonthValid === false) {
                return notifyError('Period must be required!')
            }

            if (endYearValid === false) {
                return notifyError('Period must be required!')
            }
        }

        const data = {
            id: this.props.data.id,
            title: title,
            startYear: startYear.value,
            startMonth: startMonth.value,
            endYear: endYear?endYear.value:'',
            endMonth: endMonth?endMonth.value:'',
            company: company,
            description: description,
            isPresent: isPresent
        }

        const res = await upadteExistEmployment(data);

        if (!res.status) {
            return notifyError(res.message);
        }
        this.props.onSubmit(true);
        notifySuccess(res.message)

        this.setState({
            isPresent: false,
            title: '',
            company: '',
            study: '',
            description: '',
            startMonth: null,
            startYear: null,
            endMonth: null,
            endYear: null,
            startMonthError: '',
            startYearError: '',
            endMonthError: '',
            endYearError: '',
            startMonthValid: false,
            startYearValid: false,
            endYearValid: false,
            endMonthValid: false,
            errors: {
                title: '',
                company: '',
                description: '',
            },
            validation: {
                title: false,
                company: false,
                description: false,
            }
        })
        this.props.onHide();
    }

    render() {

        const { title, company, isPresent, description, startMonth, startYear, endMonth, endYear, startMonthError, startYearError, endMonthError, endYearError, errors, years, months } = this.state;

        return (
            <Modal show={this.props.show} onHide={this.props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" className="ps-3 mb-0">
                        Update Employment
                    </Modal.Title>
                </Modal.Header>
                <form action="/" method="POST" onSubmit={this.formSubmit}>
                    <Modal.Body>
                        <div className="p-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group mb-4">
                                        <label>Company</label>
                                        <input type="text" name="company" className={errors.company !== '' ? "form-control error" : "form-control"} placeholder="Global Logic" onChange={this.inputHandler} value={company} />
                                        {errors.company !== '' ? <p className="error-text">{errors.company}</p> : null}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group mb-4">
                                        <label>Title</label>
                                        <input type="text" name="title" className={errors.title !== '' ? "form-control error" : "form-control"} placeholder="title Name" onChange={this.inputHandler} value={title} />
                                        {errors.title !== '' ? <p className="error-text">{errors.title}</p> : null}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <label>Period</label>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12">
                                            <div className={`form-group ${isPresent ? 'mb-md-4' : 'mb-md-0'} mb-xs-4`}>
                                                <Select value={startMonth} onChange={this.startMonthHandler} options={months} />
                                                {startMonthError !== '' ? <p className="error-text">{startMonthError}</p> : null}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-12">
                                            <div className={`form-group ${isPresent ? 'mb-md-4' : 'mb-md-0'} mb-xs-4`}>
                                                <Select value={startYear} onChange={this.startYearHandler} options={years} />
                                                {startYearError !== '' ? <p className="error-text">{startYearError}</p> : null}
                                            </div>
                                        </div>
                                        {!isPresent ?
                                            <>
                                                <div className="col-sm-12">
                                                    <p className="text-center mt-2 mb-2" style={{ fontSize: '12px', opacity: '0.7' }}>through</p>
                                                </div>
                                                <div className="col-sm-6 col-xs-12">
                                                    <div className="form-group mb-4">
                                                        <Select value={endMonth} onChange={this.endMonthHandler} options={months} />
                                                        {endMonthError !== '' ? <p className="error-text">{endMonthError}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-xs-12">
                                                    <div className="form-group mb-4">
                                                        <Select value={endYear} onChange={this.endYearHandler} options={years} />
                                                        {endYearError !== '' ? <p className="error-text">{endYearError}</p> : null}
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group mb-4">
                                        <label>Description (Optional)</label>
                                        <textarea rows="5" name="description" className={errors.description !== '' ? "form-control error" : "form-control"} onChange={this.inputHandler} value={description}></textarea>
                                        {errors.description !== '' ? <p className="error-text">{errors.description}</p> : null}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-check mb-4">
                                        <input className="form-check-input" type="checkbox" checked={isPresent} id="gridCheck" onChange={this.currentWorkHandler} />
                                        <label className="form-check-label" htmlFor="gridCheck">
                                            I Currently work here
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-dark" onClick={this.props.onHide}>Close</button>
                        <button type="submit" className="btn btn-success">Update</button>
                    </Modal.Footer>
                </form>

            </Modal>
        )
    }
}

export default withRouter(connect(null)(EditEducationModal));
