import React, { Component } from 'react';
import { routes } from '../routes';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class TheContent extends Component {
    render() {
        // console.log(this.props);
        return (
            <main className="marT_56">
                <Switch>
                    {routes.map((route, idx) => {
                        // console.log(route);
                        return (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                // component={<route.component />}
                                component={props => (
                                    <route.component {...props} />
                                )}
                            />
                        )
                    })}
                    <Redirect from="/" to="/" />
                </Switch>
            </main>
        )
    }
}

export default withRouter(connect(null)(TheContent))
