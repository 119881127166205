import { apiHelper } from "../../helper/api.helper";
import { jwtDecode } from "../../helper/jwt.helper";

export const getQuestionAnswer = async () => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const response = await apiHelper('admin/question/answer/list', 'GET', null, header);
    const res =  response.data;

    if(res.status === false){
        return []
    }
    
    return jwtDecode(res.data);
}


export const addQuestion = async (data) => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const { skillID, question, answerArray } = data;

    let formData = new FormData();
    formData.append('categoryID', skillID)
    formData.append('question', question)
    formData.append('answer', JSON.stringify(answerArray))

    const response = await apiHelper('admin/question/answer/save', 'POST', formData, header);
    // const res =  response.data;
    
    return response.data;
}

export const updateQuestion = async (data) => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const { id, skillID, question, answerArray } = data;

    let formData = new FormData();
    formData.append('questionID', id)
    formData.append('categoryID', skillID)
    formData.append('question', question)
    formData.append('answer', JSON.stringify(answerArray))

    const response = await apiHelper('admin/question/answer/update', 'PUT', formData, header);
    // const res =  response.data;
    
    return response.data;
}

export const deleteSingleQuestion = async (id) => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const response = await apiHelper(`admin/question/answer/delete/${id}`, 'DELETE', null, header);
    // const res =  response.data;
    
    return response.data;
}