import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { jwtDecode } from '../helper/jwt.helper';
import HeaderCommon from './headers/CommonHeader';
import HeaderClient from './headers/ClientHeader';
import FreelancerHeader from './headers/FreelancerHeader';

class HeaderComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            isAuth: false,
            isClient: false,
            isFreelancer: false
        }
    }

    componentDidMount() {
        this._unmounted = true;

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.email) {
                if (this._unmounted) {
                    this.setState({
                        user: decode,
                        isAuth: true
                    })
                }
                if (Number(decode.profileType) === 1) {
                    if (this._unmounted) {
                        this.setState({
                            isClient: true,
                            isFreelancer: false
                        })
                    }
                };
                if (Number(decode.profileType) === 2) {
                    if (this._unmounted) {
                        this.setState({
                            isClient: false,
                            isFreelancer: true
                        })
                    }
                };
                // console.log(this.props);
                if(this.props.location.pathname !== '/profile/email-verify'){
                    if (parseInt(decode.status) === 0) return this.props.history.push('/profile/email-verify');
                }
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    isAuth: false,
                    isClient: false,
                    isFreelancer: false
                })
            }
        }
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    render() {

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.email) {
                if (parseInt(decode.profileType) === 1) {
                    // console.log('freelancer');
                    return <FreelancerHeader />
                };
                if (parseInt(decode.profileType) === 2) {
                    // console.log('client');
                    return <HeaderClient />
                };
            }
        } else {
            return <HeaderCommon />
        }
    }
}

export default withRouter(connect(null)(HeaderComponent))
