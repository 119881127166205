import React, { Component } from 'react';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import DummyImage from '../../assets/img/dummy-userImg.jpg';
// import { FiEye } from 'react-icons/fi';
import DataTable from '../../components/DataTable';
import { Button } from 'react-bootstrap';
import { getContractByID } from '../../controller/contract.controller'
import moment from 'moment';

class ContractDetails extends Component {
    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            milestoneList: [],
            details: null,
            loader: false,
            path: ''
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getContractDetails();
    }
    componentWillUnmount() {
        this._unmounted = false
    }

    getContractDetails = async () => {
        if (this._unmounted) {
            this.setState({
                loader: true
            })
        }
        const { match: { params: { cid } } } = this.props;

        const res = await getContractByID(cid);
        console.log(res);
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    details: res.data.data,
                    milestoneList: res.data.milestoneList,
                    loader: false,
                    path: res.data.profileURL
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    details: null,
                    milestoneList: [],
                    loader: false
                })
            }
        }
    }

    headerCenterFormatter = (column) => {
        return <div className="d-flex justify-content-center">{column.text}</div>
    }

    statusFormatter = (cell, row) => {
        // console.log(row);
        let classsName = '';
        let statusName = '';
        switch (row.status) {
            case 1:
                classsName = 'success';
                statusName = 'Active';
                break;
            case 2:
                classsName = 'success';
                statusName = 'Paid';
                break;
            case 3:
                classsName = 'warning';
                statusName = 'Requested';
                break;
            case 4:
                classsName = 'danger';
                statusName = 'Refund';
                break;
            default:
                classsName = 'secondary';
                statusName = 'none'
                break;
        }

        return <div className="d-flex justify-content-center">
            <Button variant={classsName} className={`rounded-pill`} style={{ fontSize: '.775rem', minWidth: '100px' }}>{statusName}</Button>
        </div>
    }

    detailMilestoneFormatter = (cell, row) => {
        return row.clientMessage ? row.clientMessage : row.freelancerMessage ? row.freelancerMessage : '--'
    }

    amountFormatter = (cell, row) => {
        let dollarUSLocale = Intl.NumberFormat('en-US')
        return `$ ${dollarUSLocale.format(cell)}`
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Table is Empty</p>
        </div>
    }

    dateFormatter = (cell, row) => {
        return <div className="d-flex justify-content-center">{cell ? moment(cell).format('DD MMM, YYYY') : '--'}</div>
    }

    render() {

        const columns = [
            {
                dataField: 'clientMessage',
                text: 'Detail',
                sort: true,
                formatter: this.detailMilestoneFormatter

            },
            {
                dataField: 'createdAt',
                text: 'Created',
                sort: true,
                formatter: this.dateFormatter,
                headerFormatter: this.headerCenterFormatter
            },
            {
                dataField: 'completedAt',
                text: 'Completed',
                sort: true,
                formatter: this.dateFormatter,
                headerFormatter: this.headerCenterFormatter
            },
            {
                dataField: 'amount',
                text: 'Amount',
                sort: true,
                formatter: this.amountFormatter
            },
            {
                dataField: 'status',
                text: 'Status',
                sort: true,
                formatter: this.statusFormatter,
                headerFormatter: this.headerCenterFormatter
            }
        ];

        const data = [
            { id: 1, jobTitle: 'I needed web developer', amount: 500, status: 1 }
        ]

        const { details, loader } = this.state;
        const { match: { params: { cid } } } = this.props;
        if (!cid) {
            return <Redirect to="/admin/contracts" />
        }

        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3"><strong>Contract details</strong></h1>
                <div className="row">
                    {loader ?
                        <div className="min-vh-100 d-flex align-items-center justify-content-center">
                            <MainLoaderComponent />
                        </div>
                        :
                        details ?
                            <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                <div className="card flex-fill">
                                    <div className="p-4 border-bottom d-flex justify-content-between">
                                        <h1 className={'pageTitle fs-3'}>{details?.title}</h1>
                                        <p className="mb-0"><b className="fs-4">${details?.amount}</b><span className="d-block"><small>Fixed-Price</small></span></p>
                                    </div>
                                    <div className="d-flex align-items-start justify-content-between p-4 border-bottom">
                                        <div>
                                            <div className="project-des d-flex">
                                                <div className="profile-user">
                                                    {details?.freelancerPic ?
                                                        <img src={this.state.path + details?.freelancerPic} alt={details?.freelancerName} />
                                                        :
                                                        <span className="img-shape">{details?.freelancerName?.charAt(0)}</span>
                                                    }
                                                </div>
                                                <div className="ms-2">
                                                    <h2 className="fs-4 fw-bold mb-0">{details?.freelancerName}</h2>
                                                    <p className="mb-0 d-flex">

                                                        <span className="me-2">Freelancer</span>
                                                        {/* <span className="me-2">
                                                        {details?.completedAt && details?.completedAt !== '0000-00-00 00:00:00' ?
                                                            `Compeleted on ${moment(details?.completedAt).format('MMM DD, YYYY')}`
                                                            :
                                                            details?.startedAt ? `Started on ${moment(details?.startedAt).format('MMM DD, YYYY')}` : ''}</span> */}

                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div>
                                            <div className="project-des d-flex">
                                                <div className="profile-user">
                                                    {details?.clientPic ?
                                                        <img src={this.state.path + details?.clientPic} alt={details?.clientName} />
                                                        :
                                                        <span className="img-shape">{details?.clientName?.charAt(0)}</span>
                                                    }
                                                </div>
                                                <div className="ms-2">
                                                    <h2 className="fs-4 fw-bold mb-0">{details?.clientName}</h2>
                                                    <p className="mb-0 d-flex">
                                                        <span className="me-2">Client</span>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="ps-4 pe-4 pt-4">
                                        <h4 className="mb-3">Milestones</h4>
                                        <DataTable
                                            keyField="id"
                                            // data={data}
                                            data={this.state.milestoneList}
                                            columns={columns}
                                            // onSizePerPageChange={this.sizePerPageChange} 
                                            // onPageChange={this.pageHandler} 
                                            noDataIndication={this.emptyRender} />

                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null)(ContractDetails))
