import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { addQuestion } from '../../controller/questions.controller';
import { mainCategories, subCategories } from '../../controller/categories.controller';
import * as ACTION_TYPES from '../../../redux/actions/admin/type';
import Modal from 'react-bootstrap/Modal';
import { FiTrash2, FiPlus } from 'react-icons/fi';
import Select from 'react-select';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';

class AddQuestionModal extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            qLoader: false,
            mainServices: [],
            skillsArray: [],
            selectedService: null,
            selectedSkills: null,
            question: '',
            answerOptions: [
                { answer: '', status: 0 },
                { answer: '', status: 0 },
            ]
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getMainCategories();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getMainCategories = async () => {
        const res = await mainCategories();
        if (Array.isArray(res) && res.length > 0) {
            // console.log(res);
            const options = res.map(info => ({ label: info.categoryName, value: info.id }))
            if (this._unmounted) {
                this.setState({
                    mainServices: options
                })
            }
        } else {
            this.setState({
                mainServices: []
            })
        }
    }

    getSubCategories = async (id) => {
        const res = await subCategories(id);
        if (Array.isArray(res) && res.length > 0) {
            // console.log(res);
            const options = res.map(info => ({ label: info.categoryName, value: info.id }))
            if (this._unmounted) {
                this.setState({
                    skillsArray: options
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    skillsArray: []
                })
            }
        }
    }

    answerAddMore = () => {
        if (this._unmounted) {
            this.setState({
                answerOptions: [
                    ...this.state.answerOptions,
                    { answer: '', status: 0 }
                ]
            })
        }
    }

    answerFieldRemove = index => {
        const list = [...this.state.answerOptions];
        list.splice(index, 1);
        // setInputList(list);
        if (this._unmounted) {
            this.setState({
                answerOptions: list
            })
        }
    };

    serviceHandler = (selectedOption) => {
        this.getSubCategories(selectedOption.value)
        if (this._unmounted) {
            this.setState({
                selectedService: selectedOption,
                selectedSkills: []
            })
        }
    }

    skillsHandler = (selectedOption) => {
        // console.log(selectedOption);
        if (this._unmounted) {
            this.setState({
                selectedSkills: selectedOption
            })
        }
    }

    qusInputHandler = (e) => {
        const { value } = e.target;
        if (this._unmounted) {
            this.setState({
                question: value
            })
        }
    }

    answInputHandler = (e, i) => {
        const { name, value } = e.target;
        const list = [...this.state.answerOptions];
        list[i][name] = value;
        if (this._unmounted) {
            this.setState({
                answerOptions: list
            })
        }

        // console.log(list);
    }

    optionCheckboxHandler = (e, i) => {
        const { checked } = e.target;
        const list = [...this.state.answerOptions];
        const list2 = list.map((info) => ({ ...info, status: 0 }))
        if (checked) {
            list2[i].status = 1;
        } else {
            list2[i].status = 0;
        }
        // console.log(list2)
        if (this._unmounted) {
            this.setState({
                answerOptions: list2
            })
        }
    }

    formQuestionSubmit = async (e) => {
        // console.log(e);
        e.preventDefault();

        const { selectedService, selectedSkills, question, answerOptions } = this.state;
        if (selectedService === null) {
            return notifyError('Main Service must be required!')
        }

        if (selectedSkills === null) {
            return notifyError('Skills must be required!')
        }

        if (question === '') {
            return notifyError('Question must be required!')
        }

        for (let i = 0; i < answerOptions.length; i++) {
            if (answerOptions[i].answer === '') {
                return notifyError(`Answer ${i + 1} must be required!`);
            }
        }

        const answerAr = answerOptions.filter(info => info.status === 1);
        if (answerAr.length === 0) {
            return notifyError(`Please select One answer`);
        }

        if (this._unmounted) {
            this.setState({
                qLoader: true
            });
        }

        const answerArray = answerOptions.map(info => ({ answerText: info.answer, answer: info.status }))
        const res = await addQuestion({ skillID: selectedSkills.value, question, answerArray })
        if (res.status) {
            this.props.reloadApi(true);
            this.props.onHide();
            notifySuccess(res.message);
            if (this._unmounted) {
                this.setState({
                    qLoader: false,
                    selectedService: null,
                    selectedSkills: null,
                    question: '',
                    answerOptions: [
                        { answer: '', status: 0 },
                        { answer: '', status: 0 },
                    ]

                });
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    qLoader: false
                });
            }
            notifyError(res.message);
        }

        // console.log('submit question...')
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header>
                    <Modal.Title>Add Questions</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form action="/" method="POST" onSubmit={this.formQuestionSubmit}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Main Services</label>
                                    <Select name="service" placeholder="Select service" options={this.state.mainServices} onChange={this.serviceHandler} value={this.state.selectedService} />

                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Skills</label>
                                    <Select name="skills" placeholder="Select Skills" options={this.state.skillsArray} onChange={this.skillsHandler} value={this.state.selectedSkills} />

                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Question</label>
                                    <input type="text" name="question" className="form-control" placeholder="Enter Question" onChange={this.qusInputHandler} value={this.state.question} />

                                </div>
                            </div>
                            {this.state.answerOptions.length > 0 ?
                                this.state.answerOptions.map((info, i) => <div className="col-sm-6 col-xs-12" key={i}>
                                    <div className="form-group mb-4">
                                        <label className="form-label">Answer {i + 1}</label>
                                        <div className="d-flex align-items-center">
                                            <input type="text" name={`answer`} className={"form-control"} placeholder={`Enter Answer ${i + 1}`} onChange={(e) => this.answInputHandler(e, i)} value={info.answer} />
                                            <input type="radio" className="ms-2" name={`answer`} defaultChecked={info.status} onChange={(e) => this.optionCheckboxHandler(e, i)} />

                                            {this.state.answerOptions.length > 2 ?
                                                i > 1 ?
                                                    <button type="button" className="bg-transparent border-0 text-danger ms-2" onClick={() => this.answerFieldRemove(i)}><FiTrash2 /></button>
                                                    :
                                                    null
                                                :
                                                null
                                            }
                                        </div>

                                    </div>
                                </div>
                                )
                                :
                                null
                            }

                            <div className="col-sm-6 col-xs-12">
                                <div className="form-group mb-4">
                                    <div className="d-flex align-items-end" style={{ minHeight: '64px' }}>
                                        <button type="button" className="btn btn-outline-success" onClick={this.answerAddMore}><i className="me-1"><FiPlus /></i> Add More Option</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="form-group d-flex justify-content-end border-top pt-4">
                            <button type="button" className="btn btn-secondary border-0 me-2" onClick={this.props.onHide}>Close</button>
                            <button type="submit" aria-label="Log in" className="btn btn-success" disabled={this.state.qLoader}>{this.state.qLoader ? <SmallLoaderComponent /> : 'Submit'}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    reloadApi: (bool) => dispatch({ type: ACTION_TYPES.RELOAD_GET_QUESTIONS, payload: bool })
})

export default withRouter(connect(null, mapDispatchToProps)(AddQuestionModal))
