import React, { Component } from 'react';
import { connect } from 'react-redux'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { mainCategories, subCategories } from '../../../controller/categories.controller';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { addSkillsStep } from '../../../controller/profile.controller';

const animatedComponents = makeAnimated();

class ExpertiseComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            skillLoader: false,
            categories: [],
            selectedCategories: null,
            skills: [],
            selectedSkills: [],
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0)
        this.fillData()
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps){

        const { isGetProfile, isCallProfile } = this.props;

        if(prevProps.isGetProfile !== isGetProfile){
            if(isGetProfile){
                isCallProfile(false)
                this.fillData()
            }
        }
    }

    fillData = () =>{
        const { expertise } = this.props;
        if (expertise.categoriesArray.length === 0) {
            this.getMainCategories();
        }

        if (expertise.categorieSelected !== null) {
            this.getSkills(expertise.categorieSelected.value)
        }

        if (this._unmounted) {
            this.setState({
                selectedCategories: expertise.categorieSelected,
                selectedSkills:expertise.skillsSelected
            })
        }
    }

    getMainCategories = async () => {

        const categoriesArray = await mainCategories();
        if (categoriesArray.length > 0) {
            const options = categoriesArray.map((info) => ({ value: info.id, label: info.categoryName }))
            // console.log(options);
            if (this._unmounted) {
                this.setState({
                    categories: options
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    categories: []
                })
            }
        }

    }

    getSkills = async (id) => {
        this.setState({
            skillLoader: true
        })
        const skillsArray = await subCategories([id]);
        // console.log(skillsArray);
        if (skillsArray.length > 0) {
            const options = skillsArray.map((info) => ({ value: info.id, label: info.categoryName, ...info }))
            if (this._unmounted) {
                this.setState({
                    skills: options,
                    skillLoader: false
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    skills: [],
                    skillLoader: false
                })
            }
        }

    }

    categoryHandler = (selectedOption) => {

        if (selectedOption !== null) {
            this.getSkills(selectedOption.value)
        }
        this.props.clearSkills();
        this.setState({
            selectedCategories: selectedOption,
            selectedSkills:[]
        })
    }

    skillsHandler = (selectedOption) => {
        this.setState({
            selectedSkills: selectedOption
        })
    }

    prevHandler = () => {
        const { activeStep, stepHandler } = this.props;
        stepHandler(activeStep - 1)
    }

    nextHandler = async () => {

        const { activeStep, stepHandler, expertiseSubmit, isUpdateProfile } = this.props;
        const { selectedCategories, selectedSkills, categories, skills } = this.state;

        if (selectedCategories === null) {
            return notifyError('Select Category')
        }

        if (selectedSkills.length === 0) {
            return notifyError('Select at least 1 skill')
        }

        const obj = {
            categoriesArray: categories,
            categorieSelected: selectedCategories,
            skillsArray: skills,
            skillsSelected: selectedSkills
        }

        const data = {
            categoryID: selectedCategories.value,
            skillIDs: selectedSkills.map((info) => info.value)
        }

        const res = await addSkillsStep(data);
        // console.log(res);
        if (res.status) {
            notifySuccess('Expertise updated successfully.');
            expertiseSubmit(obj);
            stepHandler(activeStep + 1)
            isUpdateProfile(true)
        } else {
            notifyError(res.message);
        }
    }

    render() {

        const { skillLoader, categories, selectedCategories, skills, selectedSkills } = this.state;
        // console.log(categories);

        return (
            <div className={'tabs_wrap'}>
                <div className={'tab_header'}>
                    <h1 className={'tabs_title'}>Expertise</h1>
                </div>
                <div className={'tab_body'}>
                    <h2 className={'body_title'}>Tell us about the work you do</h2>
                    <div className={`form_group max_500`}>
                        <label>What is the main service you offer</label>
                        <Select components={animatedComponents} instanceId="category" name="category" value={selectedCategories} onChange={this.categoryHandler} options={categories} isSearchable={true} placeholder="Category" />
                    </div>
                    <div className={`form_group max_500`}>
                        <label>What skills do you offer clients?</label>
                        <Select components={animatedComponents} isMulti instanceId="skills" name="skills" value={selectedSkills} onChange={this.skillsHandler} options={skills} isDisabled={selectedCategories === null || skillLoader} isLoading={skillLoader} isSearchable={true} placeholder="Skills" />
                        <p className={'info_textbox_text'}>select at least 1 skill</p>
                    </div>
                </div>
                <div className={'tab_footer'}>
                    <button type="button" className={'btn btn-dark'} style={{ minWidth: '100px' }} onClick={this.nextHandler}>Next</button>
                    {this.props.activeStep > 0 ? <button type="button" className="btn btn-outline-dark" style={{ minWidth: '100px' }} onClick={this.prevHandler}>Back</button> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    expertise: state.pinfo.expertise,
    activeStep: state.pinfo.activeStep,
    isGetProfile: state.pinfo.isGetProfile
});

const mapDispatchToProps = dispatch => ({
    stepHandler: (val) => dispatch({ type: ACTION_TYPES.STEP_INFO_FREELANCER, payload: val }),
    expertiseSubmit: (obj) => dispatch({ type: ACTION_TYPES.EXPERTISE_MODULE, payload: obj }),
    isUpdateProfile: (bool) => dispatch({ type: ACTION_TYPES.UPDATE_FREELANCER_PROFILE, payload: bool }),
    clearSkills: () => dispatch({ type: ACTION_TYPES.CLEAR_SKILLS }),
    isCallProfile: (bool) => dispatch({ type: ACTION_TYPES.PROFILE_SETP_API_GET, payload: bool })
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpertiseComponent);
