import * as ACTION_TYPES from '../actions/type';

const initialState = {
    currentStep: 0,
    jobTitle: '',
    categoryArray: [],
    categoryVal: [],
    skillsArray: [],
    skillsVal: [],
    budgetVal: '',
    deadlineVal: new Date(),
    description: '',
    uploadFiles: [],
    isBackReview:false
}

const PostJobReducers = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.STEP_POST_JOBS:
            return {
                ...state,
                currentStep: action.payload
            }
        case ACTION_TYPES.TITLE_POST_JOB:
            return {
                ...state,
                jobTitle: action.payload
            }
        case ACTION_TYPES.CATEGORYS_ARRAY:
            return {
                ...state,
                categoryArray: action.payload
            }
        case ACTION_TYPES.CATEGORYS_POST_JOB:
            return {
                ...state,
                categoryVal: action.payload
            }
        case ACTION_TYPES.SKILLS_ARRAY:
            return {
                ...state,
                skillsArray: action.payload
            }
        case ACTION_TYPES.SKILLS_POST_JOB:
            return {
                ...state,
                skillsVal: action.payload
            }
        case ACTION_TYPES.BUDGET_POST_JOB:
            return {
                ...state,
                budgetVal: action.payload
            }
        case ACTION_TYPES.DEADLINE_POST_JOB:
            return {
                ...state,
                deadlineVal: action.payload
            }
        case ACTION_TYPES.DESCRIPTION_POST_JOB:
            return {
                ...state,
                description: action.payload
            }
        case ACTION_TYPES.FILES_POST_JOB:
            return {
                ...state,
                uploadFiles: action.payload
            }
        case ACTION_TYPES.BACK_REVIEW_POSTJOB_STEPS:
            return{
                ...state,
                isBackReview:action.payload
            }
        default:
            return state;
    }
}

export default PostJobReducers;