import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { mainCategories, subCategories } from '../../../controller/categories.controller';
import { connect } from 'react-redux';
import { addSkillsStep } from '../../../controller/profile.controller';

const animatedComponents = makeAnimated();

class ServiceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            skillLoader: false,
            categoryArr: [],
            selectedCategories: null,
            skillsArr: [],
            selectedSkills: [],
        }
    }

    componentDidMount() {
        const { userData } = this.props.data;
        if (userData && userData.serviceID) {
            this.setState({
                selectedCategories: { value: userData.serviceID, label: userData.serviceName }
            });
            this.getSkills(userData.serviceID)
        }
        if (userData && userData.skills && userData.skills.length > 0) {
            const skil = userData.skills.map(info => ({ value: info.skillID, label: info.skillName }))
            this.setState({
                selectedSkills: skil
            })
        }

        this.getMainCategories();
    }

    getMainCategories = async () => {
        const categoriesArray = await mainCategories();
        if (categoriesArray.length > 0) {
            const options = categoriesArray.map((info) => ({ value: info.id, label: info.categoryName }))
            // console.log(options);
            // if (this._unmounted) {
            this.setState({
                categoryArr: options
            })
            // }
        } else {
            // if (this._unmounted) {
            this.setState({
                categoryArr: []
            })
            // }
        }

    }

    getSkills = async (id) => {
        this.setState({
            skillLoader: true
        })
        const skillsArray = await subCategories([id]);
        // console.log(skillsArray);
        if (skillsArray.length > 0) {
            const options = skillsArray.map((info) => ({ value: info.id, label: info.categoryName, ...info }))
            // if (this._unmounted) {
            this.setState({
                skillsArr: options,
                skillLoader: false
            })
            // }
        } else {
            // if (this._unmounted) {
            this.setState({
                skillsArr: [],
                skillLoader: false
            })
            // }
        }

    }

    categoryHandler = (selectedOption) => {

        if (selectedOption !== null) {
            this.getSkills(selectedOption.value)
        }

        this.setState({
            selectedCategories: selectedOption,
            selectedSkills: null
        })
    }

    skillsHandler = (selectedOption) => {
        this.setState({
            selectedSkills: selectedOption
        })
    }

    userSubmit = async (e) => {
        e.preventDefault();
        const { selectedSkills } = this.state;
        const { userData } = this.props.data;
        if (userData && userData.skills && userData.skills.length > 0) {
            const oldSkills = userData.skills.map(info => info.skillID)
            const allSkills = selectedSkills.map((info) => info.value);
            // let newSkills = array1.concat(array2);
            const newSkills = allSkills.filter(info => {
                return !oldSkills.includes(info);
            })
            // console.log(newSkills.join(','))
            if (newSkills.length > 0) {
                this.props.history.push(`/freelancer/profile/exam?id=${newSkills.join(',')}`)
            }
        }

        // if (selectedCategories === null) {
        //     return notifyError('Select Category')
        // }

        // if (selectedSkills.length === 0) {
        //     return notifyError('Select at least 1 skill')
        // }

        // const data = {
        //     categoryID: selectedCategories.value,
        //     skillIDs: selectedSkills.map((info) => info.value)
        // }

        // const res = await addSkillsStep(data);
        // if(res.status){
        //     notifySuccess(res.message)
        //     this.props.data.onHide()
        //     this.props.data.updateFunction()
        // }else{
        //     notifyError(res.message)
        // }
    }

    render() {

        const { show, onHide } = this.props.data

        const { loader, skillLoader, selectedCategories, selectedSkills, categoryArr, skillsArr } = this.state;
        // console.log(categoryArr);

        return (
            <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <h6 className="h5 pb-3 border-bottom">Expertise and Skills</h6>
                    <form action="/" method="POST" onSubmit={this.userSubmit}>
                        <div className="row pb-4 pt-4">
                            <div className="col-sm-12">
                                <div className={`${'form_group'} ${'max_500'}`}>
                                    <label>What is the main service you offer</label>
                                    <Select components={animatedComponents} instanceId="category" name="category" value={selectedCategories} onChange={this.categoryHandler} options={categoryArr} isSearchable={true} placeholder="Category" />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className={`${'form_group'} ${'max_500'}`}>
                                    <label>What skills do you offer clients?</label>
                                    <Select components={animatedComponents} isMulti instanceId="skills" name="skills" value={selectedSkills} onChange={this.skillsHandler} options={skillsArr} isDisabled={selectedCategories === null || skillLoader} isLoading={skillLoader} isSearchable={true} placeholder="Skills" />
                                    <p className={'info_textbox_text'}>select at least 1 skill</p>
                                </div>
                            </div>
                        </div>
                        <div className="form-group pt-3 border-top d-flex justify-content-between">
                            <button type="button" className="btn_cancle btn btn-outline-dark" onClick={onHide}>Cancel</button>
                            <button type="submit" aria-label="Update" className="btn btn-primary min-200 max-200" disabled={loader}>{loader ? <SmallLoaderComponent /> : 'Update'}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(connect(null)(ServiceModal));