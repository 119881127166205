import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class IntroComponent extends Component {

    render() {

        const { onPrev, onContinue, userData, mainCategory, categorySelected, skillsSelected, skillIds, mins } = this.props;
        const noOfQuestion = skillIds && Array.isArray(skillIds) && skillIds.length? (skillIds.length * 10):0
        let category = '';
        let name = '';

        if (mainCategory) {
            category = mainCategory
            // console.log(mainCategory);
        }

        if (userData) {
            name = userData.name
        }

        return (

            <div className="d-block d-md-flex h-50">
                <div className="w-50 w-xs-100 p-4 pt-5 pb-5">
                    <p className="mb-3 fs-4">Hey {name},</p>
                    <h1 className="fw-bold mb-5 pb-4 lh-base">Welcome to<br /> {category} Test</h1>
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <p className="fs-5">Test durations<span className="d-block fw-bold fs-4">{mins} mins</span></p>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <p className="fs-5">No. of questions<span className="d-block fw-bold fs-4">{noOfQuestion} questions</span></p>
                        </div>
                    </div>
                </div>
                <div className="w-50 w-xs-100 p-4 pt-5 pb-5" style={{ background: '#f7f7f7' }}>
                    <h1 className="fw-bold mb-3">Instructions</h1>
                    <ol type="1" className="mb-5">
                        <li className="fs-6 mb-3">This is a timed test. Please make sure you are not interrupted during the test, as the timer cannot be paused once started.</li>
                        <li className="fs-6 mb-3">Please ensure you have a stable internet connection.</li>
                        <li className="fs-6">Please do not refresh this page during the test.</li>
                    </ol>
                    {noOfQuestion > 0 ? <button type="button" className="btn btn-success" onClick={onContinue}>Continue</button> : null}
                    <button type="button" className={`btn btn-dark ${noOfQuestion > 0 ? 'ms-2' : ''}`} onClick={onPrev}>Prev</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    categorySelected: state.pinfo.expertise.categorieSelected,
    skillsSelected: state.pinfo.expertise.skillsSelected,
    userData: state.pinfo.userData
})

export default withRouter(connect(mapStateToProps)(IntroComponent))
