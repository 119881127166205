import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { jwtDecode } from '../../../helper/jwt.helper';
import IntroComponent from '../../../components/ExamComponents/IntroComponent';
import ExamComponent from '../../../components/ExamComponents/ExamComponent';
import ResultComponent from '../../../components/ExamComponents/ResultComponent';

import { notifyError } from '../../../helper/Alert';
import { getExam, getNewSkillsExamResult } from '../../../controller/exam.controller';

const ExamSkills = (props) => {
    const [isStart, setIsStart] = useState(false);
    const [isFinishTest, setIsFinishTest] = useState(false)
    const [examList, setExamList] = useState([]);
    const [examOver, setExamOver] = useState('');
    const [skillIds, setSkillIds] = useState([])
    // const [examWithAnswer, setExamWithAnswer] = useState([]);
    const [examResult, setExamResult] = useState([]);
    // const [notExam, setNotExam] = useState(false);
    // const [examError, setExamError] = useState('');

    const getExamList = async () => {

        if (Array.isArray(skillIds) && skillIds.length > 0) {
            const ids = skillIds.map(x => parseInt(x))
            const res = await getExam(ids)
            // console.log(res);
            if (res.status) {
                setExamList(res.data)
                setIsStart(true)
            } else {
                setExamOver('Exam try is over. you are not able to doing again.')
                setExamList([])
                notifyError(res.message)
            }
        }
    }

    const startExam = () => {
        getExamList();
    }

    const tryAgainFunction = () => {
        getExamList();
        setIsFinishTest(false);
    }

    const prevHandler = () => {
        props.history.push('/freelancer/profile')
    }

    const nextHandler = () => {
        props.history.push('/freelancer/profile')
    }

    const submitExam = async (data) => {
        // const { expertise } = props;
        // let ids = []
        // if (skillIds && skillIds.length > 0) {
        //     ids = expertise.skillsSelected.map(info => info.value);
        // }
        const ids = skillIds.map(x => parseInt(x))
        // data.categoryID = [10]
        data.categoryID = ids
        const res = await getNewSkillsExamResult(data);
        // console.log(res)
        if (res.status) {
            setExamResult(res.data);
            setIsFinishTest(true);
        } else {
            setIsFinishTest(true);
            setExamResult([])
            notifyError(res.message)
        }
    }

    useEffect(() => {

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 2) {
                return props.history.push("/client/my-jobs")
            }
        }

        // const userData = props.data;
        const querySearch = new URLSearchParams(props.location.search)
        const ids = querySearch.get('id')
        if (ids === "") return props.history.push('/freelancer/profile')
        setSkillIds(ids.split(','))
    }, [props])

    return (
        <div className="container">
            <div className="d-block pt-5">
                <div className={'tabs_wrap d-block p-0'}>
                    {examOver !== '' ?
                        <div className="d-block p-5 h-50">
                            <div className={'tab_body text-center'}>
                                <h1 className="fs-4 fw-normal lh-lg text-center mb-5">{examOver}</h1>
                                <button type="button" className="btn btn-dark mt-5" onClick={prevHandler}>Go Back</button>
                            </div>
                        </div>
                        :
                        !isStart ?
                            <IntroComponent mins={30} mainCategory={props.userData?.serviceName} skillIds={skillIds} onContinue={startExam} onPrev={prevHandler} />
                            :
                            !isFinishTest ?
                                <ExamComponent mins={30} questionList={examList} submitExam={submitExam} />
                                :
                                <ResultComponent
                                    examResult={examResult}
                                    prevClick={prevHandler}
                                    nextClick={nextHandler} tryClick={tryAgainFunction}
                                />
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userData: state.pinfo.userData
})

export default withRouter(connect(mapStateToProps)(ExamSkills))
