import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { overviewAndTitle } from '../../../controller/profile.controller';

class TitleAndOverviewComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            overview: '',
            errors: {
                title: '',
                overview: ''
            },
            valid: {
                title: false,
                overview: false
            }
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);
        const { titleOverview } = this.props;

        // titleAndOverviewSubmit({ title: data.bio, overview: data.overview })
        if (this._unmounted) {
            this.setState({
                title: titleOverview.title,
                overview: titleOverview.overview,
                valid: {
                    title: titleOverview.bio && titleOverview.bio !== '' ? true : false,
                    overview: titleOverview.overview && titleOverview.overview !== '' ? true : false
                }
            })
        }
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let errors = this.state.errors;
        let valid = this.state.valid;

        switch (name) {
            case 'title':
                valid.title = value.length > 0;
                errors.title = valid.title ? '' : 'Title must be required!'
                break;
            case 'overview':
                if (value.length > 0) {
                    if (value.length > 50 && value.length <= 5000) {
                        errors.overview = '';
                        valid.overview = true;
                    } else {
                        errors.overview = 'Overview must be between 50 to 5000 characters';
                        valid.overview = false;
                    }
                } else {
                    errors.overview = 'Overview must be required!';
                    valid = false
                }
                break;
            default:
                break;
        }

        this.setState({
            errors,
            valid
        })
    }

    prevHandler = () => {
        const { activeStep, stepHandler } = this.props;
        stepHandler(activeStep - 1)
    }

    nextHandler = async () => {
        const { activeStep, stepHandler, isUpdateProfile } = this.props;
        const { valid, title, overview } = this.state;
        if (valid.title === false) {
            return notifyError('Title must be required!')
        }

        if (valid.overview === false) {
            return notifyError('Overview must be required!')
        }

        const res = await overviewAndTitle({ title, overview });

        if (res.status) {
            isUpdateProfile(true)
            notifySuccess('Title and Overview updated successfully.');
            stepHandler(activeStep + 1)
            this.props.titleAndOverviewSubmit({ title, overview })

        } else {
            notifySuccess(res.message);
        }

    }

    render() {

        const { title, overview, errors } = this.state;

        return (
            <div className={'tabs_wrap'}>
                <div className={'tab_header'}>
                    <h1 className={'tabs_title'}>{`Title & Overview`}</h1>
                </div>
                <div className={'tab_body'}>
                    {/* <h2 className={body_title}>Tell us about the work you do</h2> */}
                    <div className={`form_group`}>
                        <label>Title</label>
                        <input type="text" name="title" className={errors.title !== "" ? "form-control error" : "form-control"} placeholder="Example: Web Developer" value={title} onChange={this.inputHandler} />
                        {errors.title !== '' ? <p className="error-text">{errors.title}</p> : null}
                    </div>
                    <div className={`form_group`}>
                        <label>Professional Overview</label>
                        <textarea className={errors.overview !== "" ? "form-control error" : "form-control"} rows="5" name="overview" value={overview} onChange={this.inputHandler} placeholder="Highlight your top skills, experince, and interests. This is one of the first things clients will see on your profile."></textarea>
                        <p className={'info_textbox_text'}>{overview && overview.length > 0?`${overview.length}/`:null}5000 characters (minimum 50)</p>
                        {errors.overview !== '' ? <p className="error-text">{errors.overview}</p> : null}
                    </div>
                </div>
                <div className={'tab_footer'}>
                    <button type="button" className={'btn btn-dark'} style={{ minWidth: '100px' }} onClick={this.nextHandler}>Next</button>
                    {this.props.activeStep > 0 ? <button type="button" className="btn btn-outline-dark" style={{ minWidth: '100px' }} onClick={this.prevHandler}>Back</button> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    titleOverview: state.pinfo.titleOverview,
    activeStep: state.pinfo.activeStep
});

const mapDispatchToProps = dispatch => ({
    stepHandler: (val) => dispatch({ type: ACTION_TYPES.STEP_INFO_FREELANCER, payload: val }),
    titleAndOverviewSubmit: (obj) => dispatch({ type: ACTION_TYPES.TITLE_AND_OVERVIEW, payload: obj }),
    isUpdateProfile: (bool) => dispatch({ type: ACTION_TYPES.UPDATE_FREELANCER_PROFILE, payload: bool })
});

export default connect(mapStateToProps, mapDispatchToProps)(TitleAndOverviewComponent);