import React, { Component } from 'react'
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import contractService from '../../../controller/contract.controller';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import ConfirmModal from '../../../components/confirmModal';
import ReactStars from "react-rating-stars-component";
import moment from 'moment';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import Modal from 'react-bootstrap/Modal';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import payment from '../../../controller/payment.controller';
import Select from 'react-select';
import MilestoneCardComponent from '../../../components/MilestoneCardComponent';

class ClientContractDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            loaderModal: false,
            loader: false,
            id: 0,
            details: null,
            path: '',
            endContractConfrim: false,
            cancelContractConfrim: false,
            endDetails: null,
            milestoneModal: false,
            milestoneLoader: false,
            hireFreelancer: [],
            selectedFreelancer: null,
            isEndContractModal: false,
            amount: '',
            description: '',
            modalError: {
                amount: '',
                description: ''
            },
            modalValid: {
                amount: false,
                description: false
            }
        }
    }

    componentDidMount() {
        this.setState({
            loader: true
        })
        this.getContractDetails();
    }

    getContractDetails = async () => {
        const { match: { params: { contractID } } } = this.props;


        const res = await contractService.getClientContractDetails(contractID);
        // console.log(res);
        // console.log(this.props);

        if (res.status) {

            let newArray = [];
            if (res?.data?.hireFreelancer) {
                newArray = res?.data?.hireFreelancer.map((info) => ({ id: info.id, label: info.name }))
            }

            this.setState({
                loader: false,
                details: res.data,
                hireFreelancer: newArray
                // path: res.data.path
            })
        } else {
            this.setState({
                loader: false,
                details: null,
                path: ''
            })

        }


    }


    tabHandler = (val) => {
        this.setState({
            tab: val
        })
    }

    endContract = (info) => {
        this.setState({
            endContractConfrim: true,
            endDetails: info
        })
    }

    closeCancelConfrim = () => {
        this.setState({
            cancelContractConfrim: false
        })
    }
    closeEndConfrim = () => {
        this.setState({
            // endContractConfrim: false,
            // endDetails: null,
            isEndContractModal: false
        })
    }

    findContractEnd = async () => {
        const { match: { params: { contractID } } } = this.props;
        const data = {
            jobPostID: this.state.details?.jobPostID,
            freelancerID: this.state.details?.freelancerProfileID,
            contractID: contractID
        }

        const res = await contractService.clientEndContract(data)
        if (!res.status) {
            return notifyError(res.message)
        }
        this.props.history.push(`/client/review/${this.state.details?.jobPostID}?fid=${this.state.details?.freelancerProfileID}`)
        // this.setState({

        // })

    }

    inputModalHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    addReview = () => {
        this.props.history.push(`/client/review/${this.state.details?.jobPostID}?fid=${this.state.details?.freelancerProfileID}`)
    }

    closeOffer = async () => {

    }

    refundAmount = (info) => {
        this.props.history.push(`/client/refund/${this.state.details.jobPostID}?ml_id=${info.id}`)
    }

    createMilestoneModal = () => {
        this.setState({
            milestoneModal: true
        })
    }

    closeMilestoneModal = () => {
        this.setState({
            milestoneModal: false
        })
    }

    selectFreelancerHandler = (selectedOption) => {
        this.setState({
            selectedFreelancer: selectedOption
        })
    }

    submitMilestonePayment = async (data) => {
        console.log(data);
        this.setState({
            milestoneLoader: true
        });
        const formData = {
            contractID: this.state.details?.id,
            amount: data.milestoneAmount,
            number: data.number,
            exp_month: data.exp_month,
            exp_year: data.exp_year,
            cvc: data.cvc,
            name: data.name,
            milestoneDescription: data.milestoneDescription
        }
        const res = await payment.createMilestone(formData);
        if (res.status) {
            this.getContractDetails();
            this.setState({
                milestoneLoader: false
            })
            this.closeMilestoneModal();
            notifySuccess(res.message)
        } else {
            this.setState({
                milestoneLoader: false
            })
            notifyError(res.message)
        }
    }

    openEndContractModal = () => {
        this.setState({
            isEndContractModal: true
        })
    }

    releaseMilestone = async (info) => {
        const data = {
            contractID: info.contractID,
            milestoneID: info.id
        }
        const res = await payment.clientMilestoneReleased(data);
        if (res.status) {
            const { details: { milestone } } = this.state;
            notifySuccess(res.message);
            let newArray = milestone && milestone.length > 0 ?
                milestone.map((item => {
                    if (info.id === item.id) {
                        item.status = 2
                    }
                    return item
                })) : [];
            this.setState({
                details: {
                    ...this.state.details,
                    milestone: newArray
                }
            })
            // notifySuccess(res.message)

        } else {
            notifyError(res.message)
        }
    }

    render() {

        const { match: { params: { contractID } } } = this.props;
        if (!contractID) {
            return <Redirect to="/client/my-contracts" />
        }

        const { loader, tab, details, loaderModal, isEndContractModal } = this.state;


        return (
            <section className={`proposals_section pt-5`}>
                {loader ?
                    <div className="bg-overlay-endContact">
                        <MainLoaderComponent />
                    </div>
                    :
                    details ?
                        <>
                            <div className="container">
                                <div className="row justify-content-sm-center">
                                    <div className="col-sm-10 d-flex justify-content-between align-items-start">
                                        {/* <h1 className={'pageTitle'} style={{ cursor: 'pointer' }} onClick={() => this.props.history.goBack()}><MdArrowBack /></h1> */}
                                        <h1 className={'pageTitle'}>{details?.title}</h1>
                                        {details?.jobPostStateID !== 4 && details?.jobPostStateID !== 5 ?
                                            <div>
                                                <button type="button" className="btn btn-success rounded-pill" style={{ minWidth: '120px' }} onClick={this.openEndContractModal}>End Contract</button>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="col-sm-10 mt-4">
                                        <div className="card border-0 p-0">
                                            <div className="card-body ps-0 pe-0 pt-0 pb-0">
                                                <div className="d-flex align-items-start justify-content-between p-4">
                                                    <div className="project-des d-flex">
                                                        <div className="profile-user">
                                                            {details?.profilePic ?
                                                                <img src={details?.profileURL + details?.profilePic} alt={details?.name} />
                                                                :
                                                                <span className="img-shape">{details?.name?.charAt(0)}</span>
                                                            }
                                                        </div>
                                                        <div className="ms-2">
                                                            <h2 className="fs-3 fw-bold mb-0">{details?.name}</h2>
                                                            <p className="mb-0 d-flex"><span className="me-2">
                                                                {details?.completedAt && details?.completedAt !== '0000-00-00 00:00:00' ?
                                                                    `Compeleted on ${moment(details?.completedAt).format('MMM DD, YYYY')}`
                                                                    :
                                                                    details?.startedAt ? `Started on ${moment(details?.startedAt).format('MMM DD, YYYY')}` : ''}</span>

                                                            </p>
                                                        </div>
                                                    </div>
                                                    {details?.jobPostStateID !== 4 && details?.jobPostStateID !== 5 ?
                                                        <div>
                                                            <button type="button" className="btn btn-outline-success rounded-pill" onClick={this.createMilestoneModal} style={{ minWidth: '150px' }}>Create New Milestone</button>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <ul className="small-tabs p-4 pt-0 pb-0 border-bottom">
                                                    {/* <li className={tab === 0 ? 'active' : ''} onClick={() => this.tabHandler(0)}>Hire Freelancer</li> */}
                                                    <li className={tab === 0 ? 'active' : ''} onClick={() => this.tabHandler(0)}>Milestone</li>
                                                    {details?.jobPostStateID === 4 ? <li className={tab === 1 ? 'active' : ''} onClick={() => this.tabHandler(1)}>FeedBack</li> : null}
                                                </ul>
                                                <div className="tab-content">

                                                    {tab === 0 ?
                                                        <div className="">
                                                            <div className="mt-2 p-4">
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-hovered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>Detail</th>
                                                                                <th className="text-start">Amount</th>
                                                                                <th className="text-center">Status</th>
                                                                                <th className="text-center">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {details?.milestone && details.milestone.length > 0 ?
                                                                                details.milestone.map((info, i) =>
                                                                                    <tr key={i}>
                                                                                        <td className="text-start" style={{ verticalAlign: 'baseline' }}>{i + 1}</td>
                                                                                        <td>
                                                                                            <p>
                                                                                                {info.clientMessage}
                                                                                                {/* {info.status === 1 && info.clientMessage}
                                                                                                {info.status === 3 && info.freelancerMessage} */}
                                                                                                <span className="d-block">
                                                                                                    {info.completedAt ?
                                                                                                        `Completed ${moment(info.completedAt).format('MMM DD, YYYY')}`
                                                                                                        :
                                                                                                        info.createdAt ?
                                                                                                            `Started on ${moment(info.createdAt).format('MMM DD, YYYY')}`
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                </span>
                                                                                            </p>
                                                                                        </td>
                                                                                        <td>${info.amount}</td>
                                                                                        <td className="text-center">
                                                                                            {info.status === 4 ?
                                                                                                <span className="milestone-based danger rounded-pill">Refunded</span>
                                                                                                :
                                                                                                info.status === 3 ?
                                                                                                    <span className="milestone-based danger rounded-pill">Requested</span>
                                                                                                    :
                                                                                                    info.status === 2 ?
                                                                                                        <span className="milestone-based success rounded-pill">Paid</span>
                                                                                                        :
                                                                                                        info.status === 1 ?
                                                                                                            <span className="milestone-based success rounded-pill">Active</span>
                                                                                                            :
                                                                                                            null
                                                                                            }
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            {info.status === 4 ?
                                                                                                <button type="button" className="btn btn-outline-danger rounded-pill" disabled>Refunded</button>
                                                                                                :

                                                                                                info.status === 3 ?
                                                                                                    <button type="button" className="btn btn-success rounded-pill" onClick={() => this.releaseMilestone(info)}>Release</button>
                                                                                                    :
                                                                                                    info.status === 1 ?
                                                                                                        <button type="button" className="btn btn-success rounded-pill" onClick={() => this.releaseMilestone(info)}>Release</button>
                                                                                                        :
                                                                                                        details?.jobPostStateID && details.jobPostStateID === 4 || details.jobPostStateID === 5 ?
                                                                                                            <button type="button" className="btn btn-success rounded-pill" disabled>Paid</button>
                                                                                                            :
                                                                                                            info.status === 2 && 0 === i ?
                                                                                                                <button type="button" className="btn btn-outline-danger rounded-pill" onClick={() => this.refundAmount(info)}>Refund Amount</button>
                                                                                                                :
                                                                                                                <button type="button" className="btn btn-success rounded-pill" disabled>Paid</button>


                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                                :
                                                                                <tr>
                                                                                    <td colSpan="5">No Milestone found.</td>
                                                                                </tr>
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {tab === 1 ?
                                                        <div className="d-flex align-items-start justify-content-between p-4 row">
                                                            <div className="col-xs-12 col-sm-6 mb-xs-3">
                                                                <div className="row">
                                                                    <h4 className="fs-4 fw-bold">Freelancer Feedback to You</h4>
                                                                    {details?.freelancerReview && details.freelancerReview.length > 0 ?
                                                                        details.freelancerReview.map((info, i) =>
                                                                            <div className="col-sm-12" key={i}>
                                                                                <ReactStars count={5} isHalf={true} value={info.rate} edit={false} size={18} activeColor="#ffc12d" />
                                                                                <p className="mb-2">{info.description}</p>
                                                                                <p className="mb-0">By {info.freelancerName}</p>

                                                                            </div>
                                                                        )
                                                                        :
                                                                        <div className="col-sm-12">

                                                                            <p className="mb-2">{'No Feedback available'}</p>

                                                                        </div>
                                                                    }
                                                                </div>

                                                            </div>
                                                            <div className="col-xs-12 col-sm-6">

                                                                <div className="row">
                                                                    <h4 className="fs-4 fw-bold">Your Feedback to Freelancer</h4>
                                                                    {details?.clientReview && details.clientReview.length > 0 ?
                                                                        details.clientReview.map((info, i) =>
                                                                            <div className="col-sm-12" key={i}>
                                                                                <ReactStars isHalf={true} count={5} value={info.rate} edit={false} size={18} activeColor="#ffc12d" />
                                                                                <p className="mb-2">{info.description}</p>
                                                                                <p className="mb-0">To: {info.freelancerName}</p>

                                                                            </div>
                                                                        )
                                                                        : details?.jobPostStateID === 4 ?
                                                                            <div>
                                                                                <button type="button" className="btn btn-outline-success rounded-pill" onClick={this.addReview} style={{ minWidth: '150px' }}>Add your feedback</button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.cancelContractConfrim ?
                                    <Modal show={this.state.cancelContractConfrim} onHide={this.closeCancelConfrim} size="sm" className="confirm-modal" aria-labelledby="contained-modal-title-vcenter" centered >
                                        <div className="confirm-wrap p-4">
                                            <h3 className="fw-bold fs-4 mt-4 mb-5 text-center lh-lg">Are you sure you want to cancel this contract?</h3>

                                            <div className="d-flex justify-content-between">
                                                <button type="button" className="btn btn-transparent fw-bold rounded-pill" style={{ minWidth: '75px' }} onClick={this.closeCancelConfrim}>Cancel</button>
                                                <button type="button" className="btn btn-danger rounded-pill" style={{ minWidth: '75px' }} onClick={this.props.onSubmit}>Ok</button>
                                            </div>
                                        </div>
                                    </Modal>
                                    :
                                    null
                            }
                            {
                                isEndContractModal ?
                                    <Modal show={isEndContractModal} onHide={this.closeEndConfrim} size="md" className="confirm-modal" aria-labelledby="contained-modal-title-vcenter" centered >
                                        <div className="confirm-wrap p-4">
                                            <h3 className="fw-bold fs-4 mt-4 mb-2 text-center lh-lg">Are you sure you want to cancel this contract?</h3>
                                            <p className="fw-normal fs-4 mt-0 mb-5 text-center lh-lg">You'll be prompted to provide feedback and make any final payments in following steps.</p>
                                            <div className="d-flex justify-content-between">
                                                <button type="button" className="btn btn-transparent fw-bold rounded-pill" style={{ minWidth: '75px' }} onClick={this.closeEndConfrim}>Cancel</button>
                                                <button type="button" className="btn btn-danger rounded-pill" style={{ minWidth: '75px' }} onClick={this.findContractEnd}>Ok</button>
                                            </div>
                                        </div>
                                    </Modal>

                                    :
                                    null
                            }

                            {this.state.milestoneModal ?
                                <Modal show={this.state.milestoneModal} onHide={this.closeMilestoneModal} centered>
                                    <Modal.Body className="p-5">
                                        <Modal.Title>Create Milestone</Modal.Title>
                                        <MilestoneCardComponent isLoader={this.state.milestoneLoader} onCancel={this.closeMilestoneModal} onSubmit={this.submitMilestonePayment} />
                                    </Modal.Body>
                                </Modal>
                                :
                                null
                            }
                        </>
                        :
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="p-4">No Record Found.</div>
                                </div>
                            </div>
                        </div>
                }
            </section >
        )
    }
}

export default withRouter(connect(null)(ClientContractDetails));
