import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { MdPhotoCamera } from "react-icons/md";
// import IntlTelInput from 'react-intl-tel-input';
// import 'react-intl-tel-input/dist/main.css';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
// import flags from 'react-phone-number-input/flags'
import DummyImg from '../../../assets/images/dummy-userImg.jpg';
import 'react-phone-number-input/style.css'
import Select from 'react-select';
import { getCountryList, getStateList } from '../../../controller/common.controller';
import { jwtDecode } from '../../../helper/jwt.helper';
import { updateUserProfile } from '../../../controller/profile.controller';

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 2,
    })
}

class ContactDetailsComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isApiCalling: false,
            loader: false,
            countryLoading: false,
            stateLoading: false,
            countryOption: [],
            stateOption: [],
            countrySelected: null,
            stateSelected: null,
            email: '',
            fname: '',
            city: '',
            errors: {
                fname: '',
                city: '',
            },
            valid: {
                fname: false,
                city: false,
            },
            phone: '',
            mobilePrefix: null,
            phoneError: '',
            phoneValid: false,
            file: '',
            imgUrl: '',
            fileError: '',
            filevalid: false
            // zipCode:'',
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0)
        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);

            if (this._unmounted) {

                this.setState({
                    email: decode.email
                });

            }
        }
        this.fillData()
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps) {
        // console.log(prevProps);
        const { isGetProfile, isCallProfile } = this.props;

        if (prevProps.isGetProfile !== isGetProfile) {
            if (isGetProfile) {
                this.fillData()
                isCallProfile(false);

            }
        }
    }

    fillData = () => {
        const { contactDetails } = this.props;

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);

            if (this._unmounted) {

                this.setState({
                    email: decode.email,
                });

            }
        }

        // if (contactDetails && contactDetails.countryArray && contactDetails.countryArray.length > 0) {

        // } else {
        if (contactDetails.countryArray.length === 0) {

            this.getCountries();
        }

        if (contactDetails.country !== null) {
            this.getStates(contactDetails.country.value);
        }
        // }

        // if (this._unmounted) {
        this.setState({
            countryOption: contactDetails.countryArray,
            stateOption: contactDetails.stateArray,
            countrySelected: contactDetails.country,
            stateSelected: contactDetails.state,
            fname: contactDetails.name ? contactDetails.name : '',
            city: contactDetails.city ? contactDetails.city : '',
            errors: {
                fname: '',
                city: '',
            },
            valid: {
                fname: (contactDetails.name !== '' && contactDetails.name !== null) ? true : false,
                city: (contactDetails.city !== '' && contactDetails.city !== null) ? true : false,
            },
            phone: contactDetails.mobile ? contactDetails.mobile : '',
            mobilePrefix: contactDetails.mobilePrefix ? contactDetails.mobilePrefix : '',
            phoneValid: (contactDetails.mobile !== '' && contactDetails.mobile !== null) ? true : false,
            file:''
        })
        if(this.props.userImage && this.props.userImage !== ''){
            // this.imageFileTOUrl(contactDetails.userImage)
            this.setState({
                imgUrl:this.props.userImage
            })
        }
        // }
        this.props.isCallProfile(false);
    }

    getCountries = async () => {
        const countries = await getCountryList();
        // console.log(countries)
        if (this._unmounted) {
            this.setState({
                countryLoading: true
            })
        }
        if (countries.length > 0) {

            const options = countries.map((country) => ({ value: country.id, label: country.name }))
            if (this._unmounted) {
                this.setState({
                    countryLoading: false,
                    countryOption: options
                });
            }

        } else {
            if (this._unmounted) {
                this.setState({
                    countryOption: [],
                    countryLoading: false
                })
            }
        }
    }

    getStates = async (countryId) => {
        const states = await getStateList(countryId);
        // console.log(states)
        if (this._unmounted) {
            this.setState({
                stateLoading: true
            })
        }
        if (states.length > 0) {

            const options = states.map((state) => ({ value: state.id, label: state.name }))
            if (this._unmounted) {
                this.setState({
                    stateLoading: false,
                    stateOption: options
                });
            }

        } else {
            if (this._unmounted) {
                this.setState({
                    stateOption: [],
                    stateLoading: false
                })
            }
        }
    }

    inputhandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.formValidation(name, value))
    }

    formValidation = (name, value) => {
        let error = this.state.errors;
        let valid = this.state.valid;
        const reg = new RegExp(/^[A-Za-z.\s_-]+$/);
        switch (name) {
            case 'fname':
                if (value.length > 0) {
                    if (reg.test(value)) {
                        error.fname = '';
                        valid.fname = true;
                    } else {
                        error.fname = 'Full Name must be string.';
                        valid.fname = false;
                    }
                } else {
                    error.fname = 'Full Name must be required.';
                    valid.fname = false;
                }
                break;
            case 'city':
                if (value.length > 0) {
                    if (reg.test(value)) {
                        error.city = '';
                        valid.city = true;
                    } else {
                        error.city = 'City must be string.';
                        valid.city = false;
                    }
                } else {
                    error.city = 'City must be required.';
                    valid.city = false;
                }
                break;
            default:
                break;
        }

        this.setState({
            errors: error,
            valid: valid
        })
    }

    phoneHandler = (number) => {
        let error = '';
        let isValid = false;
        let countryCode = '';
        if (number) {

            if (isValidPhoneNumber(number) === false) {
                error = 'Invalid phone number';
                isValid = false;
                countryCode = ''
            } else {
                const coun = parsePhoneNumber(number);
                const code = getCountryCallingCode(coun.country);
                // console.log(code.country) 
                error = '';
                isValid = true;
                countryCode = code
            }

        } else {
            error = "Phone number required";
            isValid = false;
            countryCode = ''
        }

        this.setState({
            phone: number,
            phoneError: error,
            phoneValid: isValid,
            mobilePrefix: countryCode
        });

    }

    countryHandler = (selectedOption) => {
        // console.log(selectedOption);
        this.setState({
            countrySelected: selectedOption,
            stateSelected: null,
            states: [],
        })

        if (selectedOption) {
            this.getStates(selectedOption.value)
        }
    }

    stateHandler = (selectedOption) => {
        // console.log(selectedOption);
        this.setState({
            stateSelected: selectedOption,
        })
    }

    imageFileTOUrl = (file) =>{
        const url = URL.createObjectURL(file);
        this.setState({
            imgUrl: url,
        })
    }

    fileHandler = (e) => {
        const { files } = e.target;
        if (files.length > 0) {
            var t = files[0].type.split('/').pop().toLowerCase();
            if (t !== "jpeg" && t !== "jpg" && t !== "png") {
                this.setState({
                    file: '',
                    imgUrl: '',
                    filevalid: false,
                    fileError: 'Please select a valid image file'
                })
                // alert('Please select a valid image file');
                // document.getElementById(id).value = '';
                return notifyError('Please select a valid image file');
            }

            // const url = URL.createObjectURL(files[0]);
            this.imageFileTOUrl(files[0]);
            this.setState({
                file: files[0],
                filevalid: true,
                fileError: ''
            })
        }
    }

    deletePhoto = () => {
        this.setState({
            file: '',
            imgUrl: '',
            filevalid: false,
            fileError: ''
        })
    }

    prevHandler = () => {
        const { activeStep, stepHandler } = this.props;
        stepHandler(activeStep - 1)
    }

    // userSubmit = async (e) => {
    //     e.preventDefault();

    //     const res = await updateUserProfile(data);
    //     if (res.status) {
    //         this.setState({
    //             loader: false
    //         });
    //         this.props.data.updateFunction();
    //         this.props.data.onHide();
    //         notifySuccess(res.message);
    //     } else {
    //         this.setState({
    //             loader: false
    //         });
    //         notifyError(res.message)
    //     }
    // }

    nextHandler = async () => {
        const { activeStep, stepHandler, isUpdateProfile } = this.props;

        const { fname, city, valid, countrySelected, stateSelected, phoneValid, phone, mobilePrefix, file } = this.state;

        if (valid.fname === false) {
            return notifyError('First Name must be required!')
        }

        if (countrySelected === null) {
            return notifyError('Please select Country')
        }

        if (stateSelected === null) {
            return notifyError('Please select State')
        }

        if (phoneValid === false) {
            return notifyError('Phone number must be required!')
        }

        if (valid.city === false) {
            return notifyError('City must be required!')
        }


        const data = {
            name: fname,
            mobilePrefix: mobilePrefix,
            mobile: phone,
            countryID: countrySelected.value,
            stateID: stateSelected.value,
            city: city,
            profilePic:file
        }

        const res = await updateUserProfile(data);
        if (res.status) {
            notifySuccess('Contact details updated successfully.');
            stepHandler(activeStep + 1);
            isUpdateProfile(true)
            this.props.contactDetailFunc({
                userImage:file,
                countries: this.state.countryOption,
                country: countrySelected,
                states: this.state.stateOption,
                state: stateSelected,
                city: city,
                name: fname,
                mobile: phone,
                mobilePrefix: mobilePrefix
            });
        } else {
            notifyError(res.message);
        }

    }

    render() {

        const { email, countryLoading, stateLoading, countryOption, stateOption, countrySelected, stateSelected, fname, city, errors, phone, phoneError, imgUrl } = this.state;
        // console.log(fname);
        return (
            <div className={'tabs_wrap'}>
                <div className={'tab_header'}>
                    <h1 className={'tabs_title'}>{`Contact details`}</h1>
                </div>
                <div className={'tab_body'}>
                    {/* <h2 className={`body_title fs-6`}>Verify your identity to create a trusted marketplace</h2> */}
                    {/* <form action="/" method="POST" onSubmit={this.userSubmit}> */}
                    <div className="row pb-4 pt-4">
                        <div className="col-sm-12">
                            <div className={`form_group`}>
                                <div className={'new-image-wrap'}>
                                    <div className={'img_block'}>
                                        {imgUrl !== '' ?
                                            <img src={imgUrl} alt="User" />
                                            :
                                            <img src={DummyImg} alt="User" />
                                        }
                                    </div>
                                    <div className={'new-file-wrap'}>
                                        <input id="profile" type="file" name="profile" onChange={this.fileHandler} />
                                        <label className={'file-label'} htmlFor="profile">
                                            <i className="user-icon"><MdPhotoCamera /></i>
                                        </label>
                                    </div>
                                    {/* <button type="button" className={'btn_fileUpload'} onClick={this.openPhotoModal}><MdPhotoCamera /></button> */}
                                </div>
                                {/* {imgUrl === '' ?
                                    <>
                                        <div className={'new-file-wrap'}>
                                            <input id="profile" type="file" name="profile" onChange={this.fileHandler} />
                                            <label className={'file_label'} htmlFor="profile">
                                                <i className="user-icon"><MdAccountCircle /></i>
                                            </label>
                                        </div>
                                        {fileError !== '' ? <p className="error-text mt-4">{fileError}</p> : null}
                                    </>
                                    :
                                    <div className={'image_wap'}>
                                        <div className={'image'}>
                                            <img src={imgUrl} alt="user" />
                                            {/* : */}
                                {/* <img src={'/assets/images/dummy-userImg.jpg'} alt="user" /> *
                                        </div>
                                        <button type="delete" className={'delete_btn'} onClick={this.deletePhoto}>Delete Photo</button>
                                    </div>
                                } */}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group mb-4">
                                <label className={'contact_label'}>Full Name</label>
                                <input type="text" name="fname" className={"form-control"} placeholder="Full Name" onChange={this.inputhandleChange} value={fname} />
                                {errors.fname !== '' ? <p className="error-text">{errors.fname}</p> : null}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group mb-4">
                                <label className={'contact_label'}>Email</label>
                                <input type="email" name="email" className={"form-control"} placeholder="Email" defaultValue={email} disabled />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group mb-4">
                                <label className={'contact_label'}>Country</label>
                                <Select styles={customStyles} instanceId="countrySelect" options={countryOption} isLoading={countryLoading} name="countrySelect" value={countrySelected} onChange={this.countryHandler} placeholder="Country" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group mb-4">
                                <label className={'contact_label'}>State</label>
                                <Select styles={customStyles} instanceId="stateSelect" options={stateOption} isLoading={stateLoading} name="stateSelect" value={stateSelected} onChange={this.stateHandler} placeholder="State" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group mb-4">
                                <label className={'contact_label'}>City</label>
                                <input type="text" name="city" className={"form-control"} placeholder="City" onChange={this.inputhandleChange} value={city} />
                                {errors.city !== '' ? <p className="error-text">{errors.city}</p> : null}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group mb-4">
                                <label className={'contact_label'}>Phone Number</label>
                                <PhoneInput
                                    // flags={flags}
                                    // containerClassName={`intl-tel-input phone_container`}
                                    // inputClassName="form-control"
                                    placeholder="Enter phone number"
                                    onChange={this.phoneHandler}
                                    // onPhoneNumberChange={this.phoneHandler}
                                    value={phone}
                                />
                                {/* <IntlTelInput
                                    containerClassName={`intl-tel-input phone_container`}
                                    inputClassName="form-control"
                                    placeholder="Enter phone number"
                                    onPhoneNumberChange={this.phoneHandler}
                                    value={phone}
                                /> */}
                                {phoneError !== '' ? <p className="error-text">{phoneError}</p> : null}
                            </div>
                        </div>

                    </div>

                </div>
                <div className={'tab_footer'}>
                    <button type="button" className={'btn btn-dark'} style={{ minWidth: '100px' }} onClick={this.nextHandler}>Next</button>
                    {this.props.activeStep > 0 ? <button type="button" className="btn btn-outline-dark" style={{ minWidth: '100px' }} onClick={this.prevHandler}>Back</button> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    contactDetails: state.pinfo.contactDetails,
    activeStep: state.pinfo.activeStep,
    isGetProfile: state.pinfo.isGetProfile,
    userImage: state.user.userImage
});

const mapDispatchToProps = dispatch => ({
    stepHandler: (val) => dispatch({ type: ACTION_TYPES.STEP_INFO_FREELANCER, payload: val }),
    contactDetailFunc: (obj) => dispatch({ type: ACTION_TYPES.CONTACT_DETAILS, payload: obj }),
    isUpdateProfile: (bool) => dispatch({ type: ACTION_TYPES.UPDATE_FREELANCER_PROFILE, payload: bool }),
    isCallProfile: (bool) => dispatch({ type: ACTION_TYPES.PROFILE_SETP_API_GET, payload: bool })
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsComponent);