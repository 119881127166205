import { apiHelper } from "../helper/api.helper"
import { jwtDecode } from "../helper/jwt.helper"

// client module
export const createJob = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const { title, description, budget, categoryIDs, skillIDs, file } = data;

    let formData = new FormData();
    formData.append('title', title)
    formData.append('description', description)
    formData.append('offered', 0)
    formData.append('budget', budget)
    formData.append('categoryIDs', categoryIDs)
    formData.append('skillIDs', skillIDs)
    for (var i = 0; i < file.length; i++) {

        formData.append('file', file[i])
    }

    const response = await apiHelper('client/jobposts/add', 'POST', formData, header);

    return response.data;


}

export const updateJob = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const { jobPostID, title, description, budget, categoryIDs, skillIDs, file, to_delete } = data;
    console.log(file);
    let formData = new FormData();
    formData.append('jobPostID', jobPostID)
    formData.append('title', title)
    formData.append('description', description)
    formData.append('offered', 0)
    formData.append('to_delete', to_delete)
    formData.append('budget', budget)
    formData.append('categoryIDs', categoryIDs)
    formData.append('skillIDs', skillIDs)
    for (var i = 0; i < file.length; i++) {
        if (file[i].id) {

        } else {
            formData.append('file', file[i])
        }
    }


    const response = await apiHelper('client/jobposts/update', 'PUT', formData, header);

    return response.data;


}

export const getJobList = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const { jobPostType, pageNo, searchText } = data;

    let formData = new FormData();
    formData.append('jobPostType', jobPostType)
    formData.append('pageNo', pageNo)
    formData.append('searchText', searchText)

    const response = await apiHelper('client/jobposts/get', 'POST', formData, header);
    const res = response.data;

    if (!res.status) {
        return { status: false, data: [], message: res.message }
    }

    return { status: true, ...jwtDecode(res.data), message: res.message };

}

export const getJobDetail = async (id) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await apiHelper(`client/jobposts/get/${id}`, 'GET', null, header);
    const res = response.data;
    // console.log(res);
    if (!res.status) {
        return null
    }

    return jwtDecode(res.data);

}

export const closedJob = async (id) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('jobPostID', id)

    const response = await apiHelper(`client/jobposts/delete`, 'DELETE', formData, header);
    const res = response.data;
    // console.log(res);
    return res;

}

export const getJobPostBids = async (id, page) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('jobPostID', id)
    formData.append('pageNo', page)

    const response = await apiHelper(`client/bids/get`, 'POST', formData, header);
    const res = response.data;
    // console.log(res);
    if (!res.status) {
        return []
    }

    return jwtDecode(res.data);
}

export const singleBid = async (bid_id) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await apiHelper(`client/bid/get/${bid_id}`, 'GET', null, header);
    const res = response.data;
    // console.log(res);
    if (!res.status) {
        return null
    }

    return jwtDecode(res.data);
}


// client module over

// freelancer module
export const getFreelanceJobList = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const { categoryID, pageNo, searchText, tab } = data;

    let formData = new FormData();
    formData.append('servicesID', categoryID)
    formData.append('pageNo', pageNo)
    formData.append('searchText', searchText)
    formData.append('tab', tab)

    const response = await apiHelper('freelancer/jobposts/get', 'POST', formData, header);
    const res = response.data;

    if (!res.status) {
        return []
    }

    return jwtDecode(res.data);

}

export const addBids = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const { jobPostID, description, bidAmount, bidCharge, deadlineValue, deadlineType, file } = data;

    let formData = new FormData();
    formData.append('jobPostID', jobPostID)
    formData.append('description', description)
    formData.append('bidAmount', bidAmount)
    formData.append('bidCharge', bidCharge)
    formData.append('deadlineValue', deadlineValue)
    formData.append('deadlineType', deadlineType)
    if (file && file.length > 0) {
        for (var i = 0; i < file.length; i++) {

            formData.append('file', file[i])
        }
    }

    const response = await apiHelper('freelancer/bid/add', 'POST', formData, header);
    const res = response.data;

    return res;

}

export const updateBids = async (data) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const { to_delete, jobPostID, jobPostBidID, description, bidAmount, bidCharge, deadlineValue, deadlineType, file } = data;

    let formData = new FormData();
    formData.append('jobPostID', jobPostID)
    formData.append('description', description)
    formData.append('bidAmount', bidAmount)
    formData.append('bidCharge', bidCharge)
    formData.append('deadlineValue', deadlineValue)
    formData.append('deadlineType', deadlineType)
    formData.append('jobPostBidID', jobPostBidID)
    formData.append('to_delete', to_delete)
    if (file && file.length > 0) {
        for (var i = 0; i < file.length; i++) {
            if (!file.id) {
                formData.append('file', file[i])
            }
        }
    }

    const response = await apiHelper('freelancer/bid/edit', 'PUT', formData, header);
    const res = response.data;

    return res;

}

export const getSingleJobdetails = async (id) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await apiHelper(`freelancer/jobposts/get/${id}`, 'GET', null, header);
    const res = response.data;

    if (!res.status) {
        return null
    }

    return jwtDecode(res.data);

}

export const getSingleBid = async (id) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await apiHelper(`freelancer/bid/get/${id}`, 'GET', null, header);
    const res = response.data;

    if (!res.status) {
        return null
    }

    return jwtDecode(res.data);

}

export const jobSaveUnsave = async (id, page) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('jobPostID', id)

    const response = await apiHelper(`freelancer/jobposts/saveunsave`, 'POST', formData, header);
    // const res = response.data;
    // // console.log(res);
    // if (!res.status) {
    //     return { status:false,  }
    // }

    return response.data
}

// get my Proposals list
export const myProposalsList = async (id) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await apiHelper(`freelancer/proposal/get`, 'GET', null, header);
    const res = response.data;
    // console.log(res);

    if (!res.status) {
        return { status: false, data: [], message: res.message }
    }

    return { status: true, data: jwtDecode(res.data), message: res.message };

}

// freelancer module over