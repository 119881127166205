import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DummyImage from '../../assets/img/dummy-userImg.jpg';
import { FiEye } from 'react-icons/fi';
import DataTable from '../../components/DataTable';
import { Button } from 'react-bootstrap';
import { getContractList } from '../../controller/contract.controller'
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';

class AllContract extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            contractList: [],
            path: '',
            loader: false
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0)
        this.getContractArray();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getContractArray = async () => {
        if (this._unmounted) {
            this.setState({
                loader: true
            })
        }
        const res = await getContractList();
        // console.log(res);
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    loader: false,
                    contractList: res.data.data,
                    path: res.data.profileURL
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    loader: false,
                    contractList: []
                })
            }
        }
    }

    pageHandler = (page, sizePerPage) => {
        console.log(page);
    }

    sizePerPageChange = (sizePerPage, page) => {
        console.log(sizePerPage);
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Table is Empty</p>
        </div>
    }

    loaderTd = () => {
        return <div className="d-flex justify-content-center align-items-center">
            <SmallLoaderComponent color="black" />
        </div>
    }

    headerCenterFormatter = (column) => {
        return <div className="d-flex justify-content-center">{column.text}</div>
    }

    statusFormatter = (cell, row) => {
        // console.log(row);
        let classsName = '';
        let statusName = '';
        switch (row.status) {
            case 0:
                classsName = 'warning';
                statusName = 'Pending';
                break;
            case 1:
                classsName = 'success';
                statusName = 'Active';
                break;
            case 2:
                classsName = 'warning';
                statusName = 'Pending';
                break;
            default:
                classsName = 'secondary';
                statusName = 'none'
                break;
        }

        return <div className="d-flex justify-content-center">
            <Button variant={classsName} className={`rounded-pill`} style={{ fontSize: '.775rem', minWidth: '100px' }}>{statusName}</Button>
        </div>
    }

    errorImage = (e) => {
        e.target.src = DummyImage
    }

    nameFreelancerFormatter = (cell, row) => {
        return <div className="d-flex align-items-center">
            {row.freelancerPic ?
                <img className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} src={`${this.state.path}${row.freelancerPic}`} onError={this.errorImage} alt="freelancer" />
                :
                <img className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} src={DummyImage} alt="freelancer" />
            }
            <span>{row.freelancerName}</span>
        </div>
    }
    nameClientFormatter = (cell, row) => {
        return <div className="d-flex align-items-center">
            {row.clientPic ?
                <img className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} src={`${this.state.path}${row.clientPic}`} onError={this.errorImage} alt="freelancer" />
                :
                <img className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} src={DummyImage} alt="freelancer" />
            }
            <span>{row.clientName}</span>
        </div>
    }

    amountFormatter = (cell, row) => {
        let dollarUSLocale = Intl.NumberFormat('en-US')
        return `$ ${dollarUSLocale.format(cell)}`
    }

    actionFormatter = (cell, row) => {
        return <div className="d-flex justify-content-center">
            <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/admin/contract/${row.id}`)}><FiEye /></button>
        </div>
    }

    render() {

        const columns = [
            {
                dataField: 'title',
                text: 'Job',
                sort: true

            },
            {
                dataField: 'fname',
                text: 'Freelancer',
                sort: true,
                formatter: this.nameFreelancerFormatter
            },
            {
                dataField: 'cname',
                text: 'Client',
                sort: true,
                formatter: this.nameClientFormatter
            },
            {
                dataField: 'amount',
                text: 'Amount',
                sort: true,
                formatter: this.amountFormatter
            },
            {
                dataField: 'action',
                text: 'Action',
                sort: false,
                formatter: this.actionFormatter,
                headerFormatter: this.headerCenterFormatter
            }
        ];

        // const data = [
        //     { id: 1, jobTitle: 'I needed web developer', fname: 'Maherabbas Momin', cname: 'Nilesh Radadiya', amount: 5000 }
        // ]

        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3"><strong>Contracts</strong></h1>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <DataTable
                                keyField="id"
                                // data={data}
                                data={this.state.contractList}
                                columns={columns}
                                loading={this.state.loader}
                                // onSizePerPageChange={this.sizePerPageChange} 
                                // onPageChange={this.pageHandler} 
                                noDataIndication={this.state.loader?this.loaderTd:this.emptyRender} />

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null)(AllContract))
