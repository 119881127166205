import { apiHelper } from "../../helper/api.helper";
import { jwtDecode } from "../../helper/jwt.helper";

export const getContractList = async () => {

    const header = {
        'Authorization': localStorage.getItem('adminToken')
    }


    const response = await apiHelper('admin/contract/list', 'GET', null, header);
    const res = response.data;
    if (res.status === false) {
        return {
            status: false, data: [], message: res.message
        }
    }

    return {
        status: true, data: jwtDecode(res.data), message: res.message
    }
}

export const getContractByID = async (id) => {

    const header = {
        'Authorization': localStorage.getItem('adminToken')
    }


    const response = await apiHelper(`admin/contract/details/${id}`, 'GET', null, header);
    const res = response.data;
    if (res.status === false) {
        return { status: false, data: null, message: res.message }
    }

    return { status: true, data: jwtDecode(res.data), message: res.message }
}

export const getWithdrawalList = async () => {

    const header = {
        'Authorization': localStorage.getItem('adminToken')
    }


    const response = await apiHelper('admin/withdraw/request-list', 'GET', null, header);
    const res = response.data;
    if (res.status === false) {
        return {
            status: false, data: [], message: res.message
        }
    }

    return {
        status: true, data: jwtDecode(res.data), message: res.message
    }
}

export const updateWithdrawalStatus = async (id) => {

    const header = {
        'Authorization': localStorage.getItem('adminToken')
    }

    let formData = new FormData();
    formData.append('id', id)

    const response = await apiHelper('admin/getpaid/complete', 'POST', formData, header);
    const res = response.data;
    if (res.status === false) {
        return {
            status: false, data: [], message: res.message
        }
    }

    return {
        status: true, data: [], message: res.message
    }
}

export const getRefundList = async () => {

    const header = {
        'Authorization': localStorage.getItem('adminToken')
    }


    const response = await apiHelper('admin/refund/list', 'GET', null, header);
    const res = response.data;
    if (res.status === false) {
        return {
            status: false, data: [], message: res.message
        }
    }

    return {
        status: true, data: jwtDecode(res.data), message: res.message
    }
}
export const updateRefundStatus = async (id, status, milestoneID) => {

    const header = {
        'Authorization': localStorage.getItem('adminToken')
    }

    let formData = new FormData();
    formData.append('id', id)
    formData.append('status', status)
    formData.append('milestoneID', milestoneID)

    const response = await apiHelper('admin/refund/accept-cancel', 'POST', formData, header);
    const res = response.data;
    if (res.status === false) {
        return {
            status: false, data: null, message: res.message
        }
    }

    return {
        status: true, data: null, message: res.message
    }
}