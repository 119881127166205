import React, { Component } from 'react';
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import { getExistMeetings, updateMeeting, createAvailability, getAvailability, updateAvailability, deleteAvailability } from '../../controller/meeting.controller';
import { apiHelper } from '../../../helper/api.helper';
import { NEW_API_KEY, NEW_CALENDER_ID } from '../../../config';
import { FiUser, FiMail, FiX, FiEdit2, FiTrash2 } from 'react-icons/fi';
import Modal from 'react-bootstrap/Modal'
import moment from 'moment';
import TimezoneSelect from 'react-timezone-select';
import AddAvailabilityModal from './AddAvailabilityModal'
import EditAvailabilityModal from './EditAvailabilityModal'
import ConfirmModal from '../../components/confirmModal'

// import axios from 'axios';
import { notifyError, notifySuccess } from '../../../helper/Alert';

// const url = 'http://localhost:3005'

// const apiHelper = (endPoint, method, data) => {
//     return new Promise((resolve, reject) => {
//         return axios({
//             url: `${url}/${endPoint}`,
//             method,
//             data
//         })
//             .then((res) => resolve(res))
//             .catch((err) => reject(err));
//     })
// }

class Meetings extends Component {

    calendarRef = React.createRef()

    gapi;

    constructor(props) {
        super(props);
        this.state = {
            eventList: [],
            viewData: null,
            viewDetailModal: false,
            selectedTimezone: '',
            meetingLink: '',
            availablityData: null,
            addAvailablityModal: false,
            editAvailablityModal: false,
            deleteAvailablityModal: false,
            isCollapse: false
        }
    }

    componentDidMount = async () => {
        // if (this.props.data) {
        this.getAdminAviblity();
        this.getAllEvents();
        this.setState({
            selectedTimezone: {
                "value": "GMT",
                "label": "(GMT+0:00) UTC",
                "offset": 0,
                "abbrev": "GMT",
                "altName": "GMT"
            }
        })
        this.calendarFunction();
        // }
    }

    getAdminAviblity = async () => {
        const res = await getAvailability();
        if (res.status) {
            const data = res.data;
            this.setState({
                availablityData: res.data
            });
            this.gapi.setOption('businessHours', { daysOfWeek: data.day, startTime: data.startTime, endTime: data.endTime })
            // return res.data
        }

        // return {};
    }

    getAllEvents = async () => {
        try {

            const res = await getExistMeetings();
            // const response = await apiHelper('list', 'GET', null);
            // const res = response.data
            // console.log(res);
            if (res.status) {
                this.setState({
                    eventList: res.data,
                    callApi: false
                })
            } else {
                this.setState({
                    eventList: []
                })
            }

        } catch (err) {
            console.log(err);
        }
    }

    calendarFunction = async (user) => {
        // const { email } = user;
        // const businessHours = await this.getAdminAviblity();
        // console.log(businessHours)
        var calendarEl = document.getElementById('calendar');

        let calendar = new Calendar(calendarEl, {
            timeZone: 'UTC',
            // plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
            plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, googleCalendarPlugin],
            googleCalendarApiKey: NEW_API_KEY,
            allDaySlot: false,
            slotLabelInterval: "00:30",
            events: {
                googleCalendarId: NEW_CALENDER_ID
            },
            // events: this.state.eventList,
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            businessHours: {
                daysOfWeek: [1, 2, 3, 4, 5],
                startTime: '10:00',
                endTime: "18:00"
            },
            selectConstraint: "businessHours",
            eventDisplay: 'auto',
            eventSourceSuccess: this.successCallbackFunc,
            // progressiveEventRendering: false,
            eventOverlap: false,
            slotEventOverlap: false,
            displayEventTime: true,
            initialView: 'timeGridWeek',
            selectable: false,
            showNonCurrentDates: false,
            navLinks: true,
            unselectAuto: false,
            editable: false,
            durationEditable: false,
            eventResizableFromStart: false,
            eventStartEditable: false,
            // selectAllow: this.selectAllow,
            eventClick: this.eventClick,
            classNames: ['event-click'],
            // eventContent: function (info) {
            //     // console.log(info)
            //     const token = localStorage.getItem('token');
            //     if (token) {
            //         const decode = jwtDecode(token);
            //         // console.log(decode);
            //         if (decode && decode.email && info.event._def.extendedProps.description === decode.email) {

            //             return 'booked by You'
            //         }
            //         return 'Already booked'
            //     }
            // },
            selectMirror: false,
            // select: this.select,
            eventDragStop: false,
            eventDragStart: false,
            // dateClick: this.dateClick,
            selectOverlap: this.selectOverlap
        });
        calendar.render();
        this.gapi = calendar
    }


    successCallbackFunc = (content, xhr) => {
        // console.log(content);
        // const token = localStorage.getItem('token');
        if (Array.isArray(content) && content.length > 0) {
            // const decode = jwtDecode(token);
            const eventData = content.map(p => {
                if (p.url) {
                    delete p.url;
                }
                // p.display = 'background'
                // if (decode && decode.email && p.description === decode.email) {
                //     p.backgroundColor = "#198754"
                //     p.borderColor = "#198754"
                // } else {
                //     p.backgroundColor = "#6c757d"
                //     p.borderColor = "#6c757d"
                // }

                return p
            });
            return eventData;
        }

    }

    eventClick = (info) => {
        // console.log(info)
        const descriptionArray = info?.event?._def?.extendedProps?.description.split(',');
        // const descriptionJoin = descriptionArray && descriptionArray.length > 0 ? `${descriptionArray[0]}${descriptionArray[1] ? `,${descriptionArray[1]}}` : ''}` : ''
        const list = this.state.eventList;
        const getSingleEvent = list.find(item => {
            const sparateEmail = item.description.split(',');
            return sparateEmail[0] === descriptionArray?.[0]
        });
        // console.log(getSingleEvent);
        const data = {
            eventId: info?.event?._def?.publicId,
            title: info?.event?._def?.title,
            description: descriptionArray[0],
            startDate: getSingleEvent?.start?.dateTime,
            endDate: getSingleEvent?.end?.dateTime
        }
        this.setState({
            viewDetailModal: true,
            viewData: data,
            meetingLink: descriptionArray && descriptionArray.length > 0 ? descriptionArray?.[1] : ''
        })
    }

    select = (date, end) => {
        // const current = new Date();
        // console.log(date);

        this.setState({
            viewDetailModal: true
        })
        return false

    }

    selectAllow = (selectInfo) => {

        return false;
    }

    selectOverlap = (event) => {
        // if (moment(event.start).isBefore(moment())) {
        //     return false;
        // }
        return false;
    }

    closeModal = () => {
        this.setState({
            viewDetailModal: false,
            viewData: null,
            meetingLink: ''
        })
    }

    updateTimeZone = (selectedOptions) => {
        // console.log(selectedOptions);
        this.setState({
            selectedTimezone: selectedOptions
        });
        this.gapi.setOption('timeZone', selectedOptions.value);
    }

    inputHandler = (e) => {
        const value = e.target.value;
        this.setState({
            meetingLink: value
        })
    }

    saveLink = async () => {
        const { meetingLink, viewData } = this.state;
        console.log(viewData);
        if (meetingLink.length > 0) {
            const email = viewData.description.split(',')
            const newDescription = [email[0], meetingLink]
            const data = {
                eventId: viewData.eventId,
                summary: viewData.title,
                description: newDescription.join(','),
                startDate: viewData.startDate,
                endDate: viewData.endDate
            }
            // console.log(viewData.eventId);
            // const response = await apiHelper('update', 'PUT', data);
            // const res = response.data
            const res = await updateMeeting(data);
            // console.log(res);
            if (res.status) {
                notifySuccess(res.message)
                this.gapi.refetchEvents();
                this.getAllEvents();
                this.closeModal()
            } else {
                notifyError(res.message)
            }
        } else {
            this.closeModal()
        }
    }

    openAddAvailablityModal = () => {
        this.setState({
            addAvailablityModal: true
        })
    }
    closeAddAvailablityModal = () => {
        this.setState({
            addAvailablityModal: false
        })
    }

    addAvailablitySubmit = async (data) => {
        // console.log(data);

        if (data) {
            const res = await createAvailability(data);
            if (res.status) {
                notifySuccess(res.message)
                this.gapi.setOption('businessHours', { daysOfWeek: data.day, startTime: data.startTime, endTime: data.endTime })
                // this.getAdminAviblity()
                // this.gapi.refetchEvents();
                this.closeAddAvailablityModal()
            } else {
                notifyError(res.message)
            }
        }
    }

    openEditAvailablityModal = () => {
        this.setState({
            editAvailablityModal: true
        })
    }
    closeEditAvailablityModal = () => {
        this.setState({
            editAvailablityModal: false
        })
    }

    editAvailablitySubmit = async (data) => {
        // console.log(data);
        let dataObj = this.state.availablityData
        if (data) {
            const res = await updateAvailability(data);
            if (res.status) {
                dataObj.day = data.day.join(',')
                dataObj.startTime = data.startTime
                dataObj.endTime = data.endTime
                this.gapi.setOption('businessHours', { daysOfWeek: data.day, startTime: data.startTime, endTime: data.endTime })
                this.setState({
                    availablityData: dataObj
                })
                this.getAdminAviblity()
                this.gapi.refetchEvents();
                notifySuccess(res.message)
                this.closeEditAvailablityModal()
            } else {
                notifyError(res.message)
            }
        }
    }

    openDeleteAvailablityModal = () => {
        this.setState({
            deleteAvailablityModal: true
        })
    }
    closeDeleteAvailablityModal = () => {
        this.setState({
            deleteAvailablityModal: false
        })
    }

    deleteAvailablitySubmit = async (data) => {
        // console.log(data);
        const res = await deleteAvailability();
        if (res.status) {
            this.gapi.setOption('businessHours', {})
            notifySuccess(res.message)
            this.setState({
                availablityData: null
            })
            this.closeDeleteAvailablityModal()
        } else {
            notifyError(res.message)
        }
    }

    collapseToggle = () => {
        this.setState(state => ({
            isCollapse: !state.isCollapse
        }))
    }

    render() {

        const { availablityData, isCollapse } = this.state;
        const dayList = [
            { label: 'Sun', value: 0 },
            { label: 'Mon', value: 1 },
            { label: 'Tue', value: 2 },
            { label: 'Wed', value: 3 },
            { label: 'Thu', value: 4 },
            { label: 'Fri', value: 5 },
            { label: 'Sat', value: 6 },

        ]

        let dayNames = [];
        if (availablityData) {
            // console.log(typeof availablityData.day)
            const days = availablityData?.day.split(',')
            if (days && days.length > 0) {
                days.forEach(element => {
                    dayList.forEach(dayObj => {
                        if (parseInt(element) === dayObj.value) {
                            dayNames.push(dayObj.label)
                        }
                    })
                });
            }
        }

        return (
            <div className="container-fluid p-0">
                <div className="d-flex w-100 mb-3">
                    <h1 className="h3 mb-3 w-25"><strong>Meetings</strong></h1>
                    <div className="d-flex w-75 justify-content-end">
                        <div className="d-flex align-items-center pe-2" style={{ width: 'calc(100% - 180px' }}>
                            <p className="mb-0 me-2">TimeZone:</p>
                            <div className="custom-timezone">
                                <TimezoneSelect
                                    value={this.state.selectedTimezone}
                                    onChange={this.updateTimeZone}
                                />
                            </div>
                        </div>
                        {availablityData ?
                            <button type="button" className="btn btn-primary d-inline-block" onClick={this.collapseToggle}>{`${isCollapse ? 'Hide' : 'View'} Working Days`}</button>
                            :
                            <button type="button" className="btn btn-primary d-inline-block" onClick={this.openAddAvailablityModal}>Add Working Days</button>
                        }
                    </div>
                </div>
                <div className={`table-responsive avaibility-table ${isCollapse ? 'active' : ''}`}>
                    <table className="table table-borderd mb-4">
                        <thead>
                            <tr>
                                <th>Days</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {availablityData ?
                                <tr>
                                    <td>{dayNames.join(',')}</td>
                                    <td>{availablityData.startTime}</td>
                                    <td>{availablityData.endTime}</td>
                                    <td className="text-center">
                                        <button type="button" className="p-0 border-0 me-2 bg-transparent text-secondary" onClick={this.openEditAvailablityModal}><FiEdit2 /></button>
                                        <button type="button" className="p-0 border-0 bg-transparent text-danger" onClick={this.openDeleteAvailablityModal}><FiTrash2 /></button>
                                    </td>
                                </tr>
                                :
                                <tr>
                                    <td colSpan="4">No record found.</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill p-4">
                            <div id="calendar" ref={this.calendarRef}></div>
                        </div>
                    </div>
                </div>
                {this.state.addAvailablityModal ? <AddAvailabilityModal show={this.state.addAvailablityModal} onHide={this.closeAddAvailablityModal} onSubmit={this.addAvailablitySubmit} /> : null}
                {this.state.editAvailablityModal ? <EditAvailabilityModal show={this.state.editAvailablityModal} onHide={this.closeEditAvailablityModal} data={this.state.availablityData} onSubmit={this.editAvailablitySubmit} /> : null}
                {this.state.deleteAvailablityModal ? <ConfirmModal show={this.state.deleteAvailablityModal} onHide={this.closeDeleteAvailablityModal} text={'Are you sure delete working availability'} onSubmit={this.deleteAvailablitySubmit} /> : null}
                {this.state.viewDetailModal ?
                    <Modal show={this.state.viewDetailModal} size="md" onHide={this.closeModal} centered>
                        <Modal.Body className="p-5 position-relative">
                            <button type="button" className="bg-transparent border-0 position-absolute fs-4" style={{ top: '8px', right: '8px' }} onClick={this.closeModal}><FiX /></button>
                            {this.state.viewData ?
                                <>
                                    <div className="row align-items-start mb-3">
                                        <div className="col-sm-1">
                                            <span className="fs-2 lh-0" style={{ top: '-9px', position: 'relative' }}><FiUser /></span>
                                        </div>
                                        <div className="col-sm-11">
                                            <h2 className="fs-4 mb-0"><b>{this.state.viewData.title}</b>
                                                <span className="w-100 d-block" style={{ fontSize: '12px' }}>{moment.utc(this.state.viewData.startDate).format('dddd, MMMM DD YYYY')} . {moment.utc(this.state.viewData.startDate).format('h:mm')} - {moment.utc(this.state.viewData.endDate).format('h:mm A')}</span>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="row align-items-start">
                                        <div className="col-sm-1">
                                            <span className="fs-2 lh-0" style={{ top: '-9px', position: 'relative' }}><FiMail /></span>
                                        </div>
                                        <div className="col-sm-11">
                                            <p className="fs-6 mb-0" style={{ lineHeight: '2' }}><strong>{this.state.viewData.description}</strong></p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-sm-12">
                                            <input type="text" className="form-control" placeholder="Meeting URL" name="meetingLink" value={this.state.meetingLink} onChange={this.inputHandler} />
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-sm-12 d-sm-block d-md-flex justify-content-start">
                                            <button className="btn btn-success" onClick={this.saveLink}>Save</button>
                                            <button className="btn bg-transparent" onClick={this.closeModal}>Canel</button>
                                        </div>
                                    </div>
                                </>
                                :
                                <p className="text-center w-100 mb-0">Record not found.</p>
                            }
                        </Modal.Body>
                    </Modal>
                    :
                    null
                }
            </div>
        )
    }
}

export default Meetings
