import * as ACTION_TYPES from '../actions/type';

const initialState = {
    userImage:'',
    userName:''
}

const UserReducers = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.USER_IMAGE_UPDATE:
            return{
                ...state,
                userImage:action.payload
            }
        case ACTION_TYPES.UPDATE_USER_NAME:
            return{
                ...state,
                userName:action.payload
            }
        default:
            return state;
    }
}

export default UserReducers;