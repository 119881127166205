import * as ACTION_TYPES from '../actions/type';

const initialState = {
    currentStep: 0,
    jobID:null,
    jobTitle: '',
    categoryArray: [],
    categoryVal: [],
    skillsArray: [],
    skillsVal: [],
    budgetVal: '',
    deadlineVal: new Date(),
    description: '',
    uploadFiles: [],
    imageDeleteID:[],
    isBackReview:false
}

const EditJobReducers = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.EDIT_JOB_ID:
            return{
                ...state,
                jobID:action.payload
            }
        case ACTION_TYPES.STEP_EDIT_JOBS:
            return {
                ...state,
                currentStep: action.payload
            }
        case ACTION_TYPES.TITLE_EDIT_JOB:
            return {
                ...state,
                jobTitle: action.payload
            }
        case ACTION_TYPES.CATEGORYS_ARRAY:
            return {
                ...state,
                categoryArray: action.payload
            }
        case ACTION_TYPES.CATEGORYS_EDIT_JOB:
            return {
                ...state,
                categoryVal: action.payload
            }
        case ACTION_TYPES.SKILLS_ARRAY:
            return {
                ...state,
                skillsArray: action.payload
            }
        case ACTION_TYPES.SKILLS_EDIT_JOB:
            return {
                ...state,
                skillsVal: action.payload
            }
        case ACTION_TYPES.BUDGET_EDIT_JOB:
            return {
                ...state,
                budgetVal: action.payload
            }
        case ACTION_TYPES.DEADLINE_EDIT_JOB:
            return {
                ...state,
                deadlineVal: action.payload
            }
        case ACTION_TYPES.DESCRIPTION_EDIT_JOB:
            return {
                ...state,
                description: action.payload
            }
        case ACTION_TYPES.FILES_EDIT_JOB:
            return {
                ...state,
                uploadFiles: action.payload
            }
        case ACTION_TYPES.FILES_DELETE_ID_EDIT_JOB:
            return{
                ...state,
                imageDeleteID:action.payload
            }
        case ACTION_TYPES.BACK_REVIEW_EDITJOB_STEPS:
            return{
                ...state,
                isBackReview:action.payload
            }
        default:
            return state;
    }
}

export default EditJobReducers;