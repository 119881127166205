import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { jwtDecode } from '../../helper/jwt.helper';
import { MdArrowForward } from 'react-icons/md';
import MicrosoftIcon from '../../assets/images/microsoft-icon.svg';
import AirbnbIcon from '../../assets/images/airbnb-icon.svg';
import BissellIcon from '../../assets/images/bissell-icon.svg';
import GoDaddyIcon from '../../assets/images/goDaddy-icon.svg';
import Customer1 from '../../assets/images/customer-1.jpg';
import Customer2 from '../../assets/images/customer-2.jpg';
import { topSkills, trandingSkills, topSkillsUs, projectCatalog } from '../../datas';
// import '../../assets/css/home.css';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            skillActive: 0,
        }
    }

    componentDidMount() {
        const token = localStorage.getItem('token');
        if (token) {
            const tokenDecode = jwtDecode(token);
            if (Number(tokenDecode.profileType) === 2) return this.props.history.push('/client/my-jobs');
            if (Number(tokenDecode.profileType) === 1) {
                if (Number(tokenDecode.status) === 1 || Number(tokenDecode.status) === 3 || Number(tokenDecode.status) === 4) {
                    return this.props.history.push('/freelancer/jobs')
                }
            };
        }
    }

    skillsHandler = (tab) => {
        this.setState({
            skillActive: tab
        })
    }

    skillsMobileHandler = (e, tab) => {
        const targetPosition = e.target.offsetLeft;
        // console.log(targetPosition);
        const divID = document.getElementById('mobile-skill-nav');
        divID.scrollLeft = targetPosition - 15;
        this.setState({
            skillActive: tab
        })

    }

    render() {

        const { skillActive } = this.state;



        return (
            <>
                {/* // <div className="conatiner_home"> */}
                <section className="hero_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="banner-headline">
                                    <h1>Join the world's work marketplace</h1>
                                    <p>Find great talent. Build your business.<br className="d-none d-md-block" /> Take your career to the next level.</p>
                                    {/* <h1>Hire experts or be hired for any job, any time.</h1>
                                    <p>Thousands of small businesses use <span>Vedain</span> to turn their ideas into reality.</p> */}
                                    <div className="d-flex">
                                        <Link to="/signup" className="btn btn-primary mr-15 ps-4 pe-4 rounded-pill">Find Talent</Link>
                                        <Link to="/signup" className="btn btn-outline-primary ps-4 pe-4 rounded-pill">Find Work</Link>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-sm-12">
                                <div className="banner-headline trust">
                                    <h3 className="logobar-title">Trusted by</h3>
                                    <ul className="d-flex trust-ul align-items-center justify-content-start" style={{ listStyle: 'none', paddingLeft: '0', marginBottom: '0' }}>
                                        <li><img src={MicrosoftIcon} alt="Microsoft" /></li>
                                        <li><img src={AirbnbIcon} alt="Airbab" /></li>
                                        <li><img src={BissellIcon} alt="Bissell" /></li>
                                        <li><img src={GoDaddyIcon} alt="GoDaddy" /></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                        <div className="hero_image_wrap"></div>
                    </div>
                </section>
                <section className="trusted-section">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-8 col-xs-12">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-md-2 col-xs-12">
                                        <h3 className="logobar-title">Trusted by</h3>
                                    </div>
                                    <div className="col-md-8 col-xs-12">
                                        <div className="banner-headline trust">
                                            {/* <h3 className="logobar-title">Trusted by</h3> */}
                                            <ul className="d-flex trust-ul justify-content-between" style={{ listStyle: 'none', paddingLeft: '0', marginBottom: '0' }}>
                                                <li><img src={MicrosoftIcon} alt="Microsoft" /></li>
                                                <li><img src={AirbnbIcon} alt="Airbab" /></li>
                                                <li><img src={BissellIcon} alt="Bissell" /></li>
                                                <li><img src={GoDaddyIcon} alt="GoDaddy" /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="for-client-section bg-client">
                    <div className="container position-relative pt-3 pb-3 pt-md-4 pb-md-4">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-8 col-xs-12">
                                <p className="headline text-center mb-3">For clients</p>
                                <h2 className="section-headding text-center">Find talent your way</h2>
                                <p className="section-subtitle text-center">Work with the largest network of independent professionals and get things done—from quick turnarounds to big transformations.</p>
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-md-5">
                                        <Link to="/" className="btnn_primary2">
                                            <span className="title">Post a Job and hire a pro <i><MdArrowForward /></i></span>
                                        </Link>
                                    </div>
                                    <div className="col-md-5">
                                        <Link to="/" className="btnn_primary2">
                                            <span className="title">Browse and buy projects <i><MdArrowForward /></i></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-7 col-lg-6 col-xl-5"> */}
                            {/* <div className="col-md-8 col-lg-8 col-xl-12">
                                {/* <p className="headline">For clients</p> *
                                <h2 className="section-headding">Find talent your way</h2>
                                <p className="section-subtitle">Work with the largest network of independent professionals and get things done—from quick turnarounds to big transformations.</p>
                            </div>
                            <div className="col-12 col-xs-12 align-items-center">
                                <div className="row">
                                    <div className="col-md-4">
                                        <Link to="/" className="btnn_primary">
                                            <span className="title">Post a Job <br /> and hire a pro</span>
                                            <span className="name"><span>Talent Marketplace</span><i><MdArrowForward /></i></span>
                                        </Link>
                                    </div>
                                    <div className="col-md-4">
                                        <Link to="/" className="btnn_primary">
                                            <span className="title">Browse and <br /> buy projects</span>
                                            <span className="name"><span>Project Catalog</span><i><MdArrowForward /></i></span>
                                        </Link>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="bg-client-section"></div> */}
                    </div>
                </section>
                <section className="for-freelancer-section">
                    <div className="container position-relative pt-3 pb-3 pt-md-4 pb-md-4">
                        <div className="row">
                            <div className="col-md-6 bg-for-talent d-md-none"></div>
                            {/*  offset-md-6 */}
                            <div className="col-md-6 ps-4 pe-4">
                                <div className="pd-100 mb-60">
                                    <p className="headline dark mb-4">For talent</p>
                                    <h2 className="section-headding dark">Find great <br /> work</h2>
                                    <p className="section-subtitle dark">Meet clients you’re excited to work with and take<br className="d-none d-lg-block" />your career or business to new heights.</p>
                                </div>
                                <hr className="hr-work" />
                                <div className="row">
                                    <div className="col-6 col-lg-4 pb-3">
                                        <span className="tag-title dark">Find opportunities for every stage of your freelance career</span>
                                    </div>
                                    <div className="col-6 col-lg-4 pb-3">
                                        <span className="tag-title dark">Control when, where, and how you work</span>
                                    </div>
                                    <div className="col-6 col-lg-4 pb-3">
                                        <span className="tag-title dark">Explore different ways to earn</span>
                                    </div>
                                    <div className="col">
                                        <Link to="/" className="btn-opportunities rounded-pill">Find Opportunities</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-freelancer-wrap">
                            <div className="bg-freelancer-section-blue"></div>
                            <div className="bg-freelancer-section"></div>
                        </div>
                    </div>
                </section>
                <section className="customer-stories-section">
                    <div className="container">
                        <div className="row">
                            <h2 className="title-rebrand text-center">What they’re saying</h2>
                            <div className="col-md-6">
                                <div className="customer-reviews-wrap">
                                    <p className="cutomer-review-title mt-0 new-ti">
                                        <span className="quote">“</span>
                                        The freelance talent we work with are more productive than we ever thought possible.
                                    </p>
                                    <hr className="review-hr" />
                                    <div className="review-by">
                                        <div className="image_wrpa">
                                            <img src={Customer1} alt="customer" />
                                        </div>
                                        <div className="review-by-user">
                                            <strong className="review-user-name">Cara Bedford</strong>
                                            <span className="review-user-role">Director of Strategic Marketing, CompuVision</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="customer-wrapper"> */}
                                {/* <div className="customer-content m-mobile-0">
                                        <img src={Customer1} alt="customer" />
                                    </div>
                                    <p className="cutomer-review-title">
                                        <span className="quote">“</span>
                                        The freelance talent we work with are more productive than we ever thought possible.”
                                    </p>
                                    <div className="customer-content ">
                                        <hr className="review-hr" />
                                        <strong className="review-user-name">Cara Bedford</strong>
                                        <span className="review-user-role">Director of Strategic Marketing, CompuVision</span>
                                    </div> */}
                                {/* </div> */}

                            </div>
                            <div className="col-md-6">
                                <div className="customer-reviews-wrap">
                                    <p className="cutomer-review-title mt-0 new-ti">
                                        <span className="quote">“</span>
                                        {`My relationship with Cara & CompuVision keeps on growing. The projects get larger and more technical every year.`}
                                    </p>
                                    <hr className="review-hr" />
                                    <div className="review-by">
                                        <div className="image_wrpa">
                                            <img src={Customer2} alt="customer" />
                                        </div>
                                        <div className="review-by-user">
                                            <strong className="review-user-name">Sam Crockett</strong>
                                            <span className="review-user-role">Independent Web Developer</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="customer-content m-mobile-0">
                                    <img src={Customer2} alt="customer" />
                                </div>
                                <p className="cutomer-review-title">
                                    <span className="quote">“</span>
                                    {`My relationship with Cara & CompuVision keeps on growing. The projects get larger and more technical every year.”`}
                                </p>
                                <div className="customer-content">
                                    <hr className="review-hr" />
                                    <strong className="review-user-name">Sam Crockett</strong>
                                    <span className="review-user-role">Independent Web Developer</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="skills-section">
                    <div className="skills-list-titles--mobile nowrap d-md-none" id="mobile-skill-nav">
                        <h2 className={`skill-title ${skillActive === 0 ? 'active' : ''}`} onClick={(e) => this.skillsMobileHandler(e, 0)}>Top skills</h2>
                        <h2 className={`skill-title ${skillActive === 1 ? 'active' : ''}`} onClick={(e) => this.skillsMobileHandler(e, 1)}>Trending skills</h2>
                        <h2 className={`skill-title ${skillActive === 2 ? 'active' : ''}`} onClick={(e) => this.skillsMobileHandler(e, 2)}>Top skills in US</h2>
                        <h2 className={`skill-title ${skillActive === 3 ? 'active' : ''}`} onClick={(e) => this.skillsMobileHandler(e, 3)}>Project Catalog</h2>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 d-none d-md-block">
                                <h2 className={`skill-title ${skillActive === 0 ? 'active' : ''}`} onClick={() => this.skillsHandler(0)}>Top skills</h2>
                                <h2 className={`skill-title ${skillActive === 1 ? 'active' : ''}`} onClick={() => this.skillsHandler(1)}>Trending skills</h2>
                                <h2 className={`skill-title ${skillActive === 2 ? 'active' : ''}`} onClick={() => this.skillsHandler(2)}>Top skills in US</h2>
                                <h2 className={`skill-title ${skillActive === 3 ? 'active' : ''}`} onClick={() => this.skillsHandler(3)}>Project Catalog</h2>
                            </div>
                            <div className="col-md-8">
                                <div className={skillActive === 0 ? 'd-block' : 'd-none'}>
                                    <ul className="list-skills">
                                        {topSkills.length > 0 ?
                                            topSkills.map((info, i) => <li key={i}><Link to="/">{info.label}</Link></li>)
                                            :
                                            null
                                        }
                                    </ul>
                                </div>
                                <div className={skillActive === 1 ? 'd-block' : 'd-none'}>
                                    <ul className="list-skills">
                                        {trandingSkills.length > 0 ?
                                            trandingSkills.map((info, i) => <li key={i}><Link to="/">{info.label}</Link></li>)
                                            :
                                            null
                                        }
                                    </ul>
                                </div>
                                <div className={skillActive === 2 ? 'd-block' : 'd-none'}>
                                    <ul className="list-skills">
                                        {topSkillsUs.length > 0 ?
                                            topSkillsUs.map((info, i) => <li key={i}><Link to="/">{info.label}</Link></li>)
                                            :
                                            null
                                        }
                                    </ul>
                                </div>
                                <div className={skillActive === 3 ? 'd-block' : 'd-none'}>
                                    <ul className="list-skills">
                                        {projectCatalog.length > 0 ?
                                            projectCatalog.map((info, i) => <li key={i}><Link to="/">{info.label}</Link></li>)
                                            :
                                            null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(connect(null)(Home))
