import React, { Component } from 'react'
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import offerService from '../../../controller/offers.controller';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import moment from 'moment';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import { notifyError, notifySuccess } from '../../../helper/Alert';

class OfferDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            id: 0,
            details: null,
            path: '',
            acceptOfferLoader: false,
            cancelOfferLoader: false
        }
    }

    componentDidMount() {
        this.getOfferDetails();
    }

    getOfferDetails = async () => {
        const { match: { params: { id } } } = this.props;
        this.setState({
            loader: true
        })

        const res = await offerService.getFreelancerOfferDetails(id);
        // console.log(res);
        if (res.status) {
            this.setState({
                loader: false,
                details: res.data.data,
                path: res.data.path
            })
        } else {
            this.setState({
                loader: false,
                details: null,
                path: ''
            })

        }


    }

    acceptOffer = async () => {
        this.setState({
            acceptOfferLoader: true
        })
        if (this.state.details && this.state.details.jobPostID) {
            const data = {
                jobPostID: this.state.details.jobPostID,
            }
            const res = await offerService.offerAccept(data);
            if (res.status) {
                this.setState({
                    acceptOfferLoader: false
                })
                notifySuccess(res.message)
                this.props.history.push('/freelancer/my-jobs')
            } else {
                notifyError(res.message);
                this.setState({
                    acceptOfferLoader: false
                })
            }
        } else {
            this.setState({
                acceptOfferLoader: false
            })
        }

    }

    closeOffer = async () => {
        this.setState({
            cancelOfferLoader: true
        })
        if (this.state.details && this.state.details.jobPostID) {
            const data = {
                jobPostID: this.state.details.jobPostID,
            }
            const res = await offerService.offerDeclien(data);
            if (res.status) {
                this.setState({
                    cancelOfferLoader: false
                })
                notifySuccess(res.message)
                this.props.history.push('/freelancer/my-jobs')
            } else {
                this.setState({
                    cancelOfferLoader: false
                })
                notifyError(res.message);
            }
        } else {
            this.setState({
                cancelOfferLoader: false
            })
        }
    }

    render() {

        const { match: { params: { id } } } = this.props;
        if (!id) {
            return <Redirect to="/freelancer/offers" />
        }

        const { path, loader, details } = this.state;
        let status = '';
        let fess_per = 10;
        let fess_amount = 0;
        let total_amount = 0
        if (details) {
            fess_amount = (details.amount * fess_per) / 100;
            total_amount = details.amount - fess_amount;
            switch (details.jobPostStateID) {
                case 1:
                    status = 'Bidding'
                    break;
                case 2:
                    status = 'In Progress'
                    break;
                case 3:
                    status = 'Pending'
                    break;
                case 4:
                    status = 'Completed'
                    break;
                case 5:
                    status = 'Cancelled'
                    break;
                case 6:
                    status = 'Refunded'
                    break;
                default:
                    break;
            }
        }

        return (
            <section className={`proposals_section pt-5`}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 d-flex justify-content-between">
                            <h1 className={'pageTitle'} style={{ cursor: 'pointer' }} onClick={() => this.props.history.goBack()}><MdArrowBack /></h1>
                        </div>
                        <div className="col-sm-12 mt-4">
                            <div className="card border-0 p-0">
                                <div className="card-body ps-0 pe-0 pt-0 pb-0 row">
                                    {loader ?
                                        <div className="col-sm-12 text-center p-4">
                                            <MainLoaderComponent />
                                        </div>
                                        :
                                        details ?
                                            <>
                                                <div className="col-sm-9 col-xs-12 border-end pe-0">
                                                    <div className="p-5 pt-4 pb-4 border-bottom">
                                                        <table className="table w-100 border-0 td-border-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td><strong>Status</strong></td>
                                                                    <td>{status}</td>
                                                                    {/* <td>Pending - expires on June 30,2021</td> */}
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Contract Title</strong></td>
                                                                    <td>{details.title}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Job Category</strong></td>
                                                                    <td>{details.categoryName}</td>
                                                                </tr>
                                                                {details.completedAt ?
                                                                    <tr>
                                                                        <td><strong>Completed Date</strong></td>
                                                                        <td>{moment(details.completedAt).format('MMM DD, YYYY')}</td>
                                                                    </tr>
                                                                    :
                                                                    <tr>
                                                                        <td><strong>Offer Date</strong></td>
                                                                        <td>{moment(details.createdAt).format('MMM DD, YYYY')}</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="p-5 pt-2 pb-2 border-bottom">
                                                        <table className="table w-100 border-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pt-3 pb-4">
                                                                        <strong>Fixed Rate</strong>
                                                                        <p className="mb-0">Total amount the client will see</p>
                                                                    </td>
                                                                    <td className="pt-3 pb-4"><span className="fs-4">$</span></td>
                                                                    <td className="w-25 text-end pt-3 pb-4"><span>{details.amount}</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="pt-3 pb-4">
                                                                        <strong>10% Vedain Service Fee</strong>
                                                                    </td>
                                                                    <td className="pt-3 pb-4"><span className="fs-4">$</span></td>
                                                                    <td className="w-25 text-end pt-3 pb-4"><span>-{fess_amount}</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="pt-3 pb-4 border-bottom-0">
                                                                        <strong>You'll Receive</strong>
                                                                        <p className="mb-0">The estimated amount you'll receive after service fees</p>
                                                                    </td>
                                                                    <td className="pt-3 pb-4 border-bottom-0"><span className="fs-4">$</span></td>
                                                                    <td className="w-25 text-end pt-3 pb-4 border-bottom-0"><span>{total_amount}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="p-5 pt-3 pb-2">
                                                        <table className="table w-100 border-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="border-bottom-0" style={{ verticalAlign: 'baseline' }}><strong>Work Description</strong></td>
                                                                    <td className="border-bottom-0">{details.description}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 col-xs-12 ps-0">
                                                    <div className={`button_wrap2 border-bottom p-4 pt-5 pb-5`}>
                                                        <button type="button" className="btn btn-success mb-3 w-100" disabled={this.state.acceptOfferLoader} onClick={this.acceptOffer} style={{ minWidth: '175px' }}>
                                                            {this.state.acceptOfferLoader ?
                                                                <SmallLoaderComponent color="white" />
                                                                :
                                                                `Accept Offer`
                                                            }
                                                        </button>
                                                        {/* <button type="button" className="btn btn-outline-success mb-3 w-100" onClick={this.closedJobDetails} style={{ minWidth: '175px' }}>Message</button> */}
                                                        <button type="button" className="btn btn-outline-danger w-100" disabled={this.state.cancelOfferLoader} onClick={this.closeOffer} style={{ minWidth: '175px' }}>
                                                        {this.state.cancelOfferLoader ?
                                                                <SmallLoaderComponent color="black" />
                                                                :
                                                                `Decline Offer`
                                                            }
                                                        </button>
                                                        {/* <button type="button" className="btn btn-outline-dark" onClick={this.closedJobDetails} style={{ minWidth: '175px' }}>Close</button> */}
                                                    </div>
                                                    <div className="d-block w-100 p-4">
                                                        <h6 className="h4 mb-3 fw-bold">About the client</h6>
                                                        <div className="offer-client-wrap">
                                                            <div className="person-client-wrap">
                                                                <div className="img_wra">
                                                                    {details.profilePic ?
                                                                        <img src={`${path}${details.profilePic}`} alt="client" />
                                                                        :
                                                                        <img src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80" alt="client" />
                                                                    }
                                                                </div>
                                                                <p className="mb-0 name-wra">
                                                                    <strong >{details.name}</strong>
                                                                    <span className="d-block" style={{ opacity: '0.7', fontSize: '12px' }}>{details.countryName}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div className="col-sm-12 text-center p-4">
                                                <p>No record found.</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(connect(null)(OfferDetails));
