// post job
export const STEP_POST_JOBS = 'STEP_POST_JOBS';
export const TITLE_POST_JOB = 'TITLE_POST_JOB';
export const CATEGORYS_ARRAY = 'CATEGORYS_ARRAY';
export const CATEGORYS_POST_JOB = 'CATEGORYS_POST_JOB';
export const SKILLS_ARRAY = 'SKILLS_ARRAY';
export const SKILLS_POST_JOB = 'SKILLS_POST_JOB';
export const BUDGET_POST_JOB = 'BUDGET_POST_JOB';
export const DEADLINE_POST_JOB = 'DEADLINE_POST_JOB';
export const DESCRIPTION_POST_JOB = 'DESCRIPTION_POST_JOB';
export const FILES_POST_JOB = 'FILES_POST_JOB';
export const BACK_REVIEW_POSTJOB_STEPS = 'BACK_REVIEW_POSTJOB_STEPS';

// common user images
export const USER_IMAGE_UPDATE = 'USER_IMAGE_UPDATE';
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';

// EDIT job
export const STEP_EDIT_JOBS = 'STEP_EDIT_JOBS';
export const EDIT_JOB_ID = 'EDIT_JOB_ID';
export const TITLE_EDIT_JOB = 'TITLE_EDIT_JOB';
export const CATEGORYS_EDIT_ARRAY = 'CATEGORYS_ARRAY';
export const CATEGORYS_EDIT_JOB = 'CATEGORYS_EDIT_JOB';
export const SKILLS_EDIT_ARRAY = 'SKILLS_ARRAY';
export const SKILLS_EDIT_JOB = 'SKILLS_EDIT_JOB';
export const BUDGET_EDIT_JOB = 'BUDGET_EDIT_JOB';
export const DEADLINE_EDIT_JOB = 'DEADLINE_EDIT_JOB';
export const DESCRIPTION_EDIT_JOB = 'DESCRIPTION_EDIT_JOB';
export const FILES_EDIT_JOB = 'FILES_EDIT_JOB';
export const FILES_DELETE_ID_EDIT_JOB = 'FILES_DELETE_ID_EDIT_JOB';
export const BACK_REVIEW_EDITJOB_STEPS = 'BACK_REVIEW_EDITJOB_STEPS';
export const PROFILE_SETP_API_GET = 'PROFILE_SETP_API_GET';

// freelamcer info
// export const ADD_CATEGORYS_ARRAY = 'ADD_CATEGORYS_ARRAY';
// export const SELECTED_CATEGORY = 'SELECTED_CATEGORY';
// export const ADD_SKILLS_ARRAY = 'ADD_SKILLS_ARRAY';
// export const SELECTED_SKILLS = 'SELECTED_SKILLS';
export const EXPERTISE_MODULE = 'EXPERTISE_MODULE';
export const TITLE_AND_OVERVIEW = 'TITLE_AND_OVERVIEW';
export const PROFILE_IMAGE_UPLOAD = 'PROFILE_IMAGE_UPLOAD';
export const CONTACT_DETAILS = 'CONTACT_DETAILS';
export const STEP_INFO_FREELANCER = 'STEP_INFO_FREELANCER';
export const FREELANCER_STEPS_DATA = 'FREELANCER_STEPS_DATA';
export const UPDATE_FREELANCER_PROFILE = 'UPDATE_FREELANCER_PROFILE';
export const CLEAR_SKILLS = 'CLEAR_SKILLS';
// export const COUNTRY_ARRAY = 'COUNTRY_ARRAY';
// export const STATE_ARRAY = 'STATE_ARRAY';


// chat action types
export const HEADER_NOTIFICATION_COUNT = 'HEADER_NOTIFICATION_COUNT';
export const HEADER_NOTIFICATION_BOOL = 'HEADER_NOTIFICATION_BOOL';
export const URL_PATH_HTTPS = 'URL_PATH_HTTPS';