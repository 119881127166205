import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { MdSearch } from 'react-icons/md';
import { subCategories } from '../../../controller/categories.controller';

class SkillsComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            skillSelected:[],
            skillsArray: [],
            searchArray: [],
            search: '',
            limite: 6,
            expanded: false,
            error: '',
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scroll(0, 0);
        // if(this.props.skillsList && this.props.skillsList.length > 0){
        //     if(this._unmounted){
        //         this.setState({
        //             skillsArray: this.props.skillsList,
        //             searchArray: this.props.skillsList,
        //             skillSelected:this.props.skillsVal
        //         })
        //     }
        // }else{
            if(this._unmounted){
                this.setState({
                    skillSelected:this.props.skillsVal
                })
            }

            this.getSubCategories();
        // }
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getSubCategories = async () =>{
        try{

            const { skillsArrayFun, mainCategoryVal } = this.props;
            const mcIds = mainCategoryVal.map((info)=>info.id);
            const skillsList = await subCategories(mcIds);
            // console.log(skillsList);
            skillsArrayFun(skillsList)
            if(this._unmounted){
                this.setState({
                    skillsArray:skillsList,
                    searchArray:skillsList
                })
            }
            
        }catch(err){
            if(this._unmounted){
                this.setState({
                    searchArray:[],
                    skillsArray:[]
                });
            }
        }
    }

    skillsHandler = (obj) => {
        let newArray = this.state.skillSelected;
        const isExsit = newArray.filter(info=>info.id === obj.id);
        
        if (isExsit.length > 0) {
            newArray = newArray.filter(info => info.id !== obj.id);
        } else {
            newArray.push(obj); 
        }

        this.setState({
            skillSelected: newArray,
            error: ''
        })
    }

    showMore = () => {
        if (this.state.limite === 6) {
            this.setState({
                limite: this.state.skillsArray.length,
                expanded: true
            })
        } else {
            this.setState({
                limite: 6,
                expanded: false
            })
        }
    }

    nextStepFun = () => {
        if (this.state.skillSelected.length > 0) {
            if (this.props.isBackReview) {
                this.props.skillsFun(this.state.skillSelected);
                this.props.stepFun(5);
                this.props.backReviewFun(false);
            } else {
                const step = this.props.currentStep + 1;
                this.props.skillsFun(this.state.skillSelected);
                this.props.stepFun(step);
                this.props.backReviewFun(false);
            }

        } else {
            this.setState({
                error: 'Please select atleast one skill'
            })
        }
    }

    prevStepFun = () => {
        const step = this.props.currentStep - 1;
        this.props.stepFun(step);
    }

    searchHandler = (e) => {
        const search = e.target.value;
        let filterArray = [];

        if (search !== '') {
            filterArray = this.state.skillsArray.filter(skill => skill.categoryName.toLowerCase().indexOf(search.toLowerCase()) !== -1)
        } else {
            filterArray = this.state.skillsArray;
        }

        this.setState({
            search,
            searchArray: filterArray
        })
    }

    render() {

        const { search, skillSelected, searchArray, error } = this.state;



        return (
            <div className={'content_steps_wrap'}>
                <div className={'step_body'}>
                    <div className={'header_step'}>
                        <h2 className={'Header_Step_title'}>Skills</h2>
                        <div className={'search_box'}>
                            <input type="text" className={`form-control search_input`} name="search" value={search} onChange={this.searchHandler} placeholder="Search" />
                            <span className={'input_icons'}><MdSearch /></span>
                        </div>
                    </div>
                    <div className={'wrap_steps'}>
                        <ul className={`category_list ${this.state.expanded ? 'more' : null}`}>
                            {searchArray.length > 0 ?
                                searchArray.slice(0, this.state.limite).map((info, i) =>
                                    <li key={i}>
                                        <input id={`skill${info.id}`} type="checkbox" name="skills" value={info.id} defaultChecked={skillSelected.map(item=>item.id).includes(info.id)} onChange={() => this.skillsHandler(info)}/>
                                        <label htmlFor={`skill${info.id}`} className={`liDiv_list`}>{info.categoryName}</label>
                                    </li>
                                )
                                :
                                <li>No record found.</li>
                            }
                        </ul>
                        <div className="d-flex justify-content-end">
                            <button type="button" style={{ background: 'transparent', border: '0', color: '#0d6efd' }} onClick={this.showMore}>{this.state.expanded ? 'Show less' : 'Show more'}</button>
                        </div>
                        {error !== "" ?
                            <div className="d-flex">
                                <p className="error-text">{error}</p>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div className={'step_footer'}>
                    <button type="button" className="btn btn-primary min-200" onClick={this.nextStepFun}>Next</button>
                    <button type="button" className="btn btn-outline-dark min-200" onClick={this.prevStepFun}>Prev</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentStep: state.editJob.currentStep,
    skillsVal: state.editJob.skillsVal,
    skillsList: state.editJob.skillsArray,
    mainCategoryVal: state.editJob.categoryVal,
    isBackReview: state.editJob.isBackReview
})

const mapDispatchToProps = dispatch => ({
    stepFun: (step) => dispatch({ type: ACTION_TYPES.STEP_EDIT_JOBS, payload: step }),
    skillsFun: (array) => dispatch({ type: ACTION_TYPES.SKILLS_EDIT_JOB, payload: array }),
    skillsArrayFun: (array) => dispatch({ type: ACTION_TYPES.SKILLS_EDIT_ARRAY, payload: array }),
    backReviewFun: (bool) => dispatch({ type: ACTION_TYPES.BACK_REVIEW_EDITJOB_STEPS, payload: bool })
})

export default connect(mapStateToProps, mapDispatchToProps)(SkillsComponent);
