import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import { withRouter } from 'react-router-dom'
import DataTable from '../../components/DataTable';
import { FiEye, FiCheck, FiEdit2, FiTrash } from 'react-icons/fi';
import { Button } from 'react-bootstrap';
import AddQuestionModal from './AddQuestionModal';
import UpdateQuestionModal from './UpdateQuestionModal';
import { deleteSingleQuestion, getQuestionAnswer } from '../../controller/questions.controller';
import * as ACTION_TYPES from '../../../redux/actions/admin/type';
import { notifyError, notifySuccess } from '../../../helper/Alert';


class Questions extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            questionList: [],
            jobsList: [],
            details: null,
            descModal: false,
            addQModal: false,
            updateQModal: false,
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0)
        this.getQuestionAnswerList();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps) {
        const { isReloadGetApi } = this.props;

        if (prevProps.isReloadGetApi !== isReloadGetApi) {
            if (isReloadGetApi) {
                this.getQuestionAnswerList();
            }
        }
    }

    getQuestionAnswerList = async () => {
        const res = await getQuestionAnswer();
        // console.log(res);
        this.props.reloadApi(false);
        if (this._unmounted) {
            this.setState({
                questionList: res
            })
        }
    }


    pageHandler = (page, sizePerPage) => {
        console.log(page);
    }

    sizePerPageChange = (sizePerPage, page) => {
        console.log(sizePerPage);
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Table is Empty</p>
        </div>
    }

    headerCenterFormatter = (column) => {
        return <div className="d-flex justify-content-center">{column.text}</div>
    }

    openModalDescription = (row) => {
        if (this._unmounted) {
            this.setState({
                details: row,
                descModal: true
            })
        }
    }

    closeModalDescription = () => {
        if (this._unmounted) {
            this.setState({
                details: null,
                descModal: false
            })
        }
    }

    openModalQAdd = () => {
        if (this._unmounted) {
            this.setState({
                addQModal: true
            })
        }
    }

    closeModalQAdd = () => {
        if (this._unmounted) {
            this.setState({
                addQModal: false
            })
        }
    }

    openModalQUpdate = (info) => {
        if (this._unmounted) {
            this.setState({
                updateQModal: true,
                details:info
            })
        }
    }

    closeModalQUpdate = () => {
        if (this._unmounted) {
            this.setState({
                updateQModal: false,
                details:null
            })
        }
    }

    currectAnswerFormatter = (cell, row) => {
        const { answers } = row;
        if (Array.isArray(answers) && answers.length > 0) {
            const currAnsw = answers.filter(info => info.answer === 1);
            if (currAnsw.length > 0) {
                return currAnsw[0].answerText
            }
        }

        return '--'
    }

    deleteQuestion = async (info) =>{
        const res = await deleteSingleQuestion(info.id);
        if(res.status){
            notifySuccess(res.message);
            this.props.reloadApi(true);
        }else{
            notifyError(res.message)
        }
    }

    actionFormatter = (cell, row) => {
        return <div className="d-flex justify-content-center">
            <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.openModalDescription(row)}><FiEye /></button>
            <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.openModalQUpdate(row)}><FiEdit2 /></button>
            <button type="button" className="border-0 bg-transparent text-danger" style={{ cursor: 'pointer' }} onClick={() => this.deleteQuestion(row)}><FiTrash /></button>
        </div>
    }

    statusFormatter = (cell, row) => {
        // console.log(row);
        let classsName = '';
        // let statusName = '';
        switch (row.statesName) {
            case "Bidding":
                classsName = 'warning';
                // statusName = 'Pending';
                break;
            case "Done":
                classsName = 'success';
                // statusName = 'Active';
                break;
            case "In Progress":
                classsName = 'primary';
                // statusName = 'Pending';
                break;
            default:
                classsName = 'secondary';
                // statusName = 'none'
                break;
        }

        return <div className="d-flex justify-content-center">
            <Button variant={classsName} className={`rounded-pill`} style={{ fontSize: '.775rem', minWidth: '100px' }}>{cell}</Button>
        </div>
    }

    render() {

        const columns = [
            {
                dataField: 'question',
                text: 'Question',
                sort: true
            },
            {
                dataField: 'Currect Answer',
                text: 'Currect Answer',
                sort: true,
                formatter: this.currectAnswerFormatter,
            },
            {
                dataField: 'categoryName',
                text: 'Skills',
                sort: true
            },

            {
                dataField: 'action',
                text: 'Action',
                sort: false,
                formatter: this.actionFormatter,
                headerFormatter: this.headerCenterFormatter
            }
        ];

        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3 d-flex align-items-start justify-content-between">
                    <strong>Job Post List</strong>
                    <button type="button" className="btn btn-dark" onClick={this.openModalQAdd}>Add Questions</button>
                </h1>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <DataTable
                                keyField="id"
                                data={this.state.questionList}
                                columns={columns}
                                // onSizePerPageChange={this.sizePerPageChange} 
                                // onPageChange={this.pageHandler} 
                                noDataIndication={this.emptyRender}
                            />

                        </div>
                    </div>
                </div>
                {this.state.descModal ?
                    <Modal show={this.state.descModal} onHide={this.closeModalDescription} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >

                        <Modal.Body className="p-4">
                            <h6 className="fs-3 mb-3"><b>Q. {this.state.details.question}</b></h6>
                            <h6 className="fs-6 mb-0"><b>Answer:</b></h6>
                            {Array.isArray(this.state.details.answers) && this.state.details.answers.length > 0 ?
                                <ul className="list-group list-group-numbered list-group-flush">
                                    {this.state.details.answers.map((info, i) =>
                                        <li className="list-group-item" key={i}>
                                            {info.answerText}
                                            {info.answer === 1 ? <i className="text-success ms-2 fs-4"><FiCheck /></i> : null}
                                        </li>
                                    )}
                                </ul>
                                :
                                null
                            }
                            {this.state.details.categoryName ?
                                <div className="border-top pt-3 mt-3">
                                    <p className="fs-5">Skills: <span className="badge bg-secondary p-2 rounded-pill ms-3">{this.state.details.categoryName}</span></p>
                                </div>
                                :
                                null
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary border-0" onClick={this.closeModalDescription}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    :
                    null
                }

                {this.state.addQModal ?
                    <AddQuestionModal show={this.state.addQModal} onHide={this.closeModalQAdd} />
                    :
                    null
                }
                {this.state.updateQModal ?
                    <UpdateQuestionModal show={this.state.updateQModal} onHide={this.closeModalQUpdate} data={this.state.details} />
                :
                    null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isReloadGetApi: state.questions.isReloadGetApi
})

const mapDispatchToProps = dispatch => ({
    reloadApi: (bool) => dispatch({ type: ACTION_TYPES.RELOAD_GET_QUESTIONS, payload: bool })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Questions))
