import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { notifyError } from '../../../helper/Alert';
import { jwtDecode } from '../../../helper/jwt.helper';
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import { NEW_API_KEY, NEW_CALENDER_ID } from '../../../config';
import { FiLink, FiMail, FiUser, FiX } from 'react-icons/fi';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { getExistMeetings, insertMeetings, deleteMeeting, getFreelancerAvailability } from '../../../controller/profile.controller';
import ConfirmModal from '../../../components/confirmModal';
// import { FiTrash2 } from "react-icons/fi";
// import axios from 'axios';

// const url = 'http://localhost:3005'

// const apiHelper = (endPoint, method, data) => {
//     return new Promise((resolve, reject) => {
//         return axios({
//             url: `${url}/${endPoint}`,
//             method,
//             data
//         })
//             .then((res) => resolve(res))
//             .catch((err) => reject(err));
//     })
// }

class RescheduleMeeting extends Component {

    calendarRef = React.createRef()

    gapi;

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            callApi: false,
            eventList: [],
            isDateSelected: false,
            startDate: null,
            endDate: null,
            isConfirmModal: false,
            title: '',
            modalTitle: '',
            isExist: false,
            existEventData: null,
            viewData: null,
            viewDetailModal: false
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (props.data !== state.user) {
            state.user = props.data;
            state.callApi = true;
            return true
        }

        return null; // No change to state
    }

    componentDidMount = async () => {
        // if (this.props.data) {
        this.getAllEvents();
        this.calendarFunction();
        this.getAdminAviblity();
        // }
    }

    getAdminAviblity = async () => {
        const res = await getFreelancerAvailability();
        if (res.status) {
            // console.log(res.data);
            const data = {
                daysOfWeek: res.data?.day?.split(',').map(x => parseInt(x)),
                startTime: res.data?.startTime,
                endTime: res.data?.endTime
            }

            this.gapi.setOption('businessHours', data)
        }
    }

    getAllEvents = async () => {
        try {

            const res = await getExistMeetings();
            // const response = await apiHelper('list', 'GET', null);
            // const res = response.data
            // console.log(res);
            const token = localStorage.getItem('token');
            if (res.status) {
                const data = res.data;
                // let isMyMeeting = false;
                if (data && data.length > 0 && token) {
                    const decode = jwtDecode(token);
                    const meetingBooked = data.some(info => {
                        const email = info.description.split(',');
                        return email[0] === decode.email
                    });

                    this.setState({
                        isDateSelected: meetingBooked
                    })
                }

                this.setState({
                    eventList: res.data,
                    callApi: false
                })
            } else {
                this.setState({
                    eventList: []
                })
            }

        } catch (err) {
            console.log(err);
        }
    }

    calendarFunction = async (user) => {
        // const { email } = user;
        // const businessHoursObj = await this.getAdminAviblity();
        // console.log(businessHoursObj)
        var calendarEl = document.getElementById('calendar');

        let calendar = new Calendar(calendarEl, {
            timeZone: 'local',
            // plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
            plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, googleCalendarPlugin],
            googleCalendarApiKey: NEW_API_KEY,
            allDaySlot: false,
            slotLabelInterval: "00:30",
            events: {
                googleCalendarId: NEW_CALENDER_ID
            },
            // events: this.state.eventList,
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            businessHours: {
                daysOfWeek: [1, 2, 3, 4, 5],
                startTime: '10:00',
                endTime: "20:00"
            },
            selectConstraint: "businessHours",
            eventDisplay: 'auto',
            eventSourceSuccess: this.successCallbackFunc,
            progressiveEventRendering: false,
            eventOverlap: false,
            slotEventOverlap: false,
            displayEventTime: false,
            initialView: 'timeGridWeek',
            selectable: true,
            showNonCurrentDates: false,
            navLinks: true,
            selectMinDistance: 0,
            unselectAuto: true,
            editable: false,
            // durationEditable: false,
            eventResizableFromStart: true,
            eventStartEditable: false,
            classNames: ['event-click'],
            selectAllow: this.selectAllow,
            eventClick: this.eventClick,
            eventContent: function (info) {
                // console.log(info)
                const token = localStorage.getItem('token');
                if (token) {
                    const decode = jwtDecode(token);
                    // console.log(decode);
                    const descriptionArray = info.event._def.extendedProps.description.split(',')
                    if (decode && decode.email && descriptionArray[0] === decode.email) {

                        return 'booked by You'
                    }
                    return 'Already booked'
                }
            },
            selectMirror: false,
            select: this.select,
            eventDragStop: false,
            eventDragStart: false,
            selectOverlap: this.selectOverlap
        });
        this.gapi = calendar
        calendar.render();
        this.getAdminAviblity();
    }

    successCallbackFunc = (content, xhr) => {
        // console.log(content);
        const token = localStorage.getItem('token');
        if (token && Array.isArray(content) && content.length > 0) {
            const decode = jwtDecode(token);
            const eventData = content.map(p => {
                if (p.url) {
                    delete p.url;
                }
                const descriptionArray = p.description.split(',');
                if (decode && decode.email && descriptionArray[0] === decode.email) {
                    p.backgroundColor = "#198754"
                    p.borderColor = "#198754"
                } else {
                    p.backgroundColor = "#6c757d"
                    p.borderColor = "#6c757d"
                    p.className = "navLink-disable"
                }


                return p
            });
            return eventData;
        }

    }

    select = (date, end) => {
        // const current = new Date();
        // console.log(date);
        // this.gapi.setOption('selectAllow', false)
        if (moment(new Date()).isSameOrAfter(date.start)) {
            // console.log('if');
            notifyError('This slot are not available')
            return false
        } else {
            // console.log('else');
            let titleModal = '';
            let dataEvent = null;
            const list = this.state.eventList;
            const token = localStorage.getItem('token');
            if (token && list && list.length > 0) {
                const decode = jwtDecode(token);
                const isExist = list.find(info => {
                    const descriptionArray = info.description.split(',')
                    return descriptionArray[0] === decode.email
                });
                if (isExist) {
                    titleModal = `Your previous meeting delete and Booked Meeting on ${moment(date.start).format('DD MMM, YYYY')} at ${moment(date.start).format('h:mm a')}`
                    dataEvent = isExist
                } else {
                    dataEvent = null
                    titleModal = `Booked Meeting on ${moment(date.start).format('DD MMM, YYYY')} at ${moment(date.start).format('h:mm a')}`
                }
            } else {
                titleModal = `Booked Meeting on ${moment(date.start).format('DD MMM, YYYY')} at ${moment(date.start).format('h:mm a')}`
            }
            this.setState({
                // startDate: date.start,
                // endDate: date.end,
                startDate: date.startStr,
                endDate: date.endStr,
                isConfirmModal: true,
                modalTitle: titleModal,
                existEventData: dataEvent,
                isExist: dataEvent ? true : false
            })
        }

    }

    selectAllow = (selectInfo) => {

        // console.log(selectInfo)
        // if (moment().isBefore(selectInfo.start) && moment().isBefore(selectInfo.end)) {
        //     if (this.state.eventList && this.state.eventList.length > 0 && this.props.userData && this.props.userData.email) {
        //         var isExist = this.state.eventList.filter((info) => info.description === this.props.userData.email);
        //         // console.log(evts)
        //         if (isExist && isExist.length > 0) {
        //             notifyError('You have already slot booked')
        //             return false
        //         }

        //     }
        // }

        var duration = moment.duration(moment(selectInfo.end).diff(selectInfo.start));
        var hours = duration.asHours();
        if (hours > 0.5) {
            notifyError('Please select only one time slot')
            // console.log('Please select only one time slot')
            return false
        }
        const start = new Date(selectInfo.start)
        const end = new Date(selectInfo.end)
        if (moment(new Date()).isBefore(start) || moment(new Date()).isBefore(end)) {
            if (this.state.eventList && this.state.eventList.length > 0) {
                var evts = this.state.eventList.filter((info) => moment(info.start.dateTime).isSame(selectInfo.startStr) && moment(info.end.dateTime).isSame(selectInfo.endStr))
                // console.log(evts)
                if (evts && evts.length > 0) {

                    // notifyError('This slot already booked')
                    return false
                } else {
                    return true
                }

            }
        }

        return moment().diff(selectInfo.start) <= 0
    }

    eventClick = (info) => {
        // console.log(info)
        const eventsList = this.state.eventList;
        const descriptionArray = info?.event?._def?.extendedProps?.description.split(',');
        const email = descriptionArray && descriptionArray.length > 0 ? descriptionArray[0] : '';
        const getSingleEvent = eventsList.find(info => {
            const sparateEmail = info.description.split(',');
            return sparateEmail[0] === email
        });
        const data = {
            eventId: info?.event?._def?.publicId,
            title: info?.event?._def?.title,
            description: email,
            startDate: new Date(getSingleEvent?.start?.dateTime),
            endDate: new Date(getSingleEvent?.end?.dateTime),
            meetingLink: descriptionArray && descriptionArray.length > 0 ? descriptionArray[1] : ''
        }
        this.setState({
            viewDetailModal: true,
            viewData: data
        })
    }

    selectOverlap = (event) => {
        // console.log(moment(event.start).isBefore(moment()));
        if (moment(event.start).isBefore(moment())) {
            return false;
        }
        return true;
    }

    closeModal = () => {
        this.setState({
            startDate: null,
            endDate: null,
            isAddModal: false,
            modalTitle: ''
        })
    }

    closeDetailsModal = () => {
        this.setState({
            viewDetailModal: false,
            viewData: null,
            meetingLink: ''
        })
    }

    insertEvent = async () => {
        try {

            const { existEventData, isExist } = this.state;
            if (isExist && existEventData) {
                // const response = await apiHelper(`delete/${existEventData.id}`, 'DELETE', null);
                // const res = response.data;
                const res = await deleteMeeting(existEventData.id)
                // console.log(res);
                if (!res.status) {
                    notifyError(res.message)
                    return true
                }
            }

            const data = {
                'summary': this.props.userData.name,
                'description': this.props.userData.email,
                'startDate': this.state.startDate,
                'endDate': this.state.endDate
            }
            const res = await insertMeetings(data);
            // const response = await apiHelper('insert', 'POST', data);
            // const res = response.data;
            // console.log(res.data);
            if (res.status) {
                this.setState({
                    isConfirmModal: false,
                    isDateSelected: true
                })
                this.gapi.refetchEvents();
                this.getAllEvents();
            } else {
                notifyError('This slot are not available')
            }
        } catch (err) {
            console.log(err)
            notifyError(err.message);
        }
    }


    deleteEvent = (info) => {
        console.log(info);
    }

    closeConfirmModal = () => {
        this.gapi.refetchEvents();
        this.setState({
            startDate: '',
            endDate: '',
            isConfirmModal: false,
        });
    }

    prevHandler = () => {
        this.props.history.push('/freelancer/profile')
    }


    render() {
        return (
            <div className="container">
                <div className="d-block pt-5 pb-5">
                    <div className={'tabs_wrap'}>
                        <div className={'tab_header'}>
                            <h1 className={'tabs_title'}>{`Schedule a Call with US`}</h1>
                        </div>
                        <div className={'tab_body'}>
                            <div id="calendar" ref={this.calendarRef}></div>
                        </div>

                        <div className={'tab_footer'}>
                            {/* <button type="button" className={'btn btn-dark'} style={{ minWidth: '100px' }} onClick={this.nextHandler}>Next</button> */}
                            <button type="button" className="btn btn-outline-dark" style={{ minWidth: '100px' }} onClick={this.prevHandler}>Back</button>
                        </div>
                        {this.state.isConfirmModal ? <ConfirmModal show={this.state.isConfirmModal} onHide={this.closeConfirmModal} text={this.state.modalTitle} onSubmit={this.insertEvent} /> : null}
                        {this.state.viewDetailModal ?
                            <Modal show={this.state.viewDetailModal} size="md" onHide={this.closeDetailsModal} centered>
                                <Modal.Body className="p-5 position-relative">
                                    <button type="button" className="bg-transparent border-0 position-absolute fs-4" style={{ top: '8px', right: '8px' }} onClick={this.closeDetailsModal}><FiX /></button>
                                    {this.state.viewData ?
                                        <>

                                            {this.state.viewData.meetingLink ?
                                                <>
                                                    <p>Hi {this.state.viewData.title},
                                                        <br /><br />
                                                        Your meeting on <b>{moment(this.state.viewData.startDate).format('dddd, MMMM DD YYYY')} . {moment(this.state.viewData.startDate).format('h:mm')} - {moment(this.state.viewData.endDate).format('h:mm A')}</b> on below link.
                                                    </p>
                                                    <div className="row align-items-start ">
                                                        <div className="col-sm-1">
                                                            <span className="fs-2 lh-0" style={{ top: '-9px', position: 'relative' }}><FiLink /></span>
                                                        </div>
                                                        <div className="col-sm-11">
                                                            <a href={this.state.viewData.meetingLink} target="_blank" className="fs-6 mb-0" style={{ lineHeight: '2' }}><strong>{this.state.viewData.meetingLink}</strong></a>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="row align-items-start mb-3">
                                                        <div className="col-sm-1">
                                                            <span className="fs-2 lh-0" style={{ top: '-9px', position: 'relative' }}><FiUser /></span>
                                                        </div>
                                                        <div className="col-sm-11">
                                                            <h2 className="fs-4 mb-0"><b>{this.state.viewData.title}</b>
                                                                <span className="w-100 d-block" style={{ fontSize: '12px' }}>{moment.utc(this.state.viewData.startDate).format('dddd, MMMM DD YYYY')} . {moment.utc(this.state.viewData.startDate).format('h:mm')} - {moment.utc(this.state.viewData.endDate).format('h:mm A')}</span>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-start">
                                                        <div className="col-sm-1">
                                                            <span className="fs-2 lh-0" style={{ top: '-9px', position: 'relative' }}><FiMail /></span>
                                                        </div>
                                                        <div className="col-sm-11">
                                                            <p className="fs-6 mb-0" style={{ lineHeight: '2' }}><strong>{this.state.viewData.description}</strong></p>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                        :
                                        <p className="text-center w-100 mb-0">Record not found.</p>
                                    }
                                </Modal.Body>
                            </Modal>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userData: state.pinfo.userData
});


export default connect(mapStateToProps)(RescheduleMeeting)
