import { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './../../assets/css/common.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { notifyError, notifySuccess } from '../../helper/Alert';
import SmallLoaderComponent from '../../helper/SmallLoaderComponent';
// import { jwtDecode } from '../../helper/jwt.helper';
import Logo from '../../assets/images/vedain-logo.png'
import { adminLogin } from '../controller/auth.controller';

class AdminLoginComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            form: {
                email: '',
                password: ''
            }
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);

    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    formSubmit = async (e, actions) => {
        try {
            const { email, password } = e;
            if (this._unmounted) {
                this.setState({
                    loader: true
                });
            }

            const res = await adminLogin({ email, password });

            // console.log(res);
            if (res.status) {

                const { history } = this.props;

                localStorage.setItem('adminToken', res.data);
                // const decode = jwtDecode(res.data);
                history.push('/admin/dashboard')
                if (this._unmounted) {
                    actions.resetForm({
                        form: {
                            email: '',
                            password: ''
                        }
                    })
                }

                notifySuccess(res.message);
                if (this._unmounted) {
                    this.setState({
                        loader: false,

                    });
                }

            } else {
                if (this._unmounted) {
                    this.setState({
                        loader: false
                    });
                }
                notifyError(res.message);
            }

        } catch (err) {
            if (this._unmounted) {
                this.setState({
                    loader: false
                });
            }
            console.error(err.message);
            notifyError(err.message);
        }
    }

    render() {

        const LoginSchema = Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Email must be required'),
            password: Yup.string().required('Password must be required')
        });

        if (localStorage.getItem('adminToken')) {
            return <Redirect to="/admin/dashboard" />
        }

        return (
            <>
                {/* <HeaderComponent /> */}
                <section className={`authWrapLog marT_56`}>
                    <div className="container">
                        <div className="row">
                            <div className={`col-sm-5 m-auto reset_bg`}>
                                <div className={'header_login'}>
                                    <h1 className={'title text-center mb-5'}><img src={Logo} alt="logo" /></h1>
                                </div>
                                <div className="login_wrap">

                                    <Formik initialValues={this.state.form} validationSchema={LoginSchema} onSubmit={(values, actions) => this.formSubmit(values, actions)}>
                                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (

                                            <form action="/" method="POST" onSubmit={handleSubmit}>

                                                <div className="form-group mb-4">
                                                    <label className="mb-1">Email</label>
                                                    <input type="email" name="email" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                                    {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                                                </div>
                                                <div className="form-group d-block mb-5">
                                                    <label className="mb-1">Password</label>
                                                    <input type="password" name="password" className={errors.password && touched.password ? "form-control error" : "form-control"} placeholder="Password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                                    {errors.password && touched.password ? <p className="error-text">{errors.password}</p> : null}
                                                </div>
                                                {/* <div className="form-group mb-4">
                                                    <div className="d-flex flex-row-reverse">
                                                        <Link to="/forgot-password">Forgot Passowrd?</Link>
                                                    </div>
                                                </div> */}
                                                <div className="form-group d-block mt-4">
                                                    <button type="submit" aria-label="Log in" className="btn btn-primary btn-block" disabled={this.state.loader}>{this.state.loader ? <SmallLoaderComponent /> : 'Log in'}</button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(connect(null)(AdminLoginComponent));