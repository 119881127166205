import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// import postcssCustomProperties from 'postcss-custom-properties';
// import '../assets/css/app.css';
import '../assets/css/style.css';
import TheContent from './TheContent';
import TheHeader from './TheHeader';
import TheSidebar from './TheSidebar';
// postcssCustomProperties({
//     importFrom: [
//         '../assets/css/app.css',
//         {

//         }
//     ]
// })


export default class TheLayout extends Component {

    componentDidMount() {

    }

    render() {

        if (!localStorage.getItem('adminToken')) {
            return <Redirect to="/admin" />
        }

        return (
            <div className="wrapper">
                <TheSidebar />
                <div className="main">
                    <TheHeader />
                    <TheContent />
                </div>
            </div>
        )
    }
}
