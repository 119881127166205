import * as ACTION_TYPES from '../actions/type';

const initialState = {
    notifyCount: 0,
    isHeaderNotify:false,
    urlPath:''
}

const ChatReducers = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.HEADER_NOTIFICATION_COUNT:
            return{
                ...state,
                notifyCount:action.payload,
                
            }
        case ACTION_TYPES.HEADER_NOTIFICATION_BOOL:
            return{
                ...state,
                isHeaderNotify:action.payload,
            }
        case ACTION_TYPES.URL_PATH_HTTPS:
            return{
                ...state,
                urlPath:action.payload,
            }
        default:
            return state;
    }
}

export default ChatReducers;