import React, { useState, useEffect } from 'react';
import '../../../assets/css/proposal.css';
import { MdStars, MdAttachFile, MdFileDownload, MdPhotoSizeSelectActual, MdClear } from 'react-icons/md';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent'
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { addBids, getSingleJobdetails, getSingleBid, updateBids } from '../../../controller/jobs.controller';
import { jwtDecode } from '../../../helper/jwt.helper';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// class ProposalComponent extends Component {

//     _unmounted = false;
//     constructor(props) {
//         super(props);
//         this.state = {
//             jobDetails: null,
//             rate: '',
//             rateError: '',
//             rateValid: false,
//             feeRate: '',
//             totalRate: '',
//             deadline: '',
//             deadlineError: '',
//             deadlineValid: false,
//             coverLetter: '',
//             coverLetterCount: 0,
//             coverLetterError: '',
//             coverLetterValid: false,
//             uploadFile: [],
//             loader: false,
//             jobID: null,
//             bidID: null,
//             imageArray: [],
//             toDelete: []
//         }
//     }

//     deadlineHandler = (e) => {
//         const { value } = e.target;
//         let error = '';
//         let valid = false;
//         if (value > 0) {
//             error = ''
//             valid = true
//         } else {
//             error = 'Invalid delivery days'
//             valid = false;
//         }

//         if (this._unmounted) {
//             this.setState({
//                 deadline: value,
//                 deadlineError: error,
//                 deadlineValid: valid
//             })
//         }
//     }

//     downloadImage = (imageUrl) => {
//         const fileName = imageUrl.split('/').pop();
//         fetch(imageUrl).then(function (t) {
//             t.blob().then(b => {
//                 const link = document.createElement('a')
//                 link.href = URL.createObjectURL(b);
//                 link.download = fileName;
//                 document.body.appendChild(link)
//                 link.click()
//                 document.body.removeChild(link)
//             })
//         })

//     }

//     totalMoneyHandler = (price) => {
//         const priceVal = Number(price);
//         const percentage = 10;
//         const fee = (priceVal * percentage) / 100;
//         const total = priceVal - fee;

//         if (this._unmounted) {
//             this.setState({
//                 rate: priceVal,
//                 feeRate: fee,
//                 totalRate: total
//             })
//         }
//     }

//     rateHandler = (e) => {
//         const { value } = e.target;
//         let valid = false;
//         let error = '';

//         if (value > 3) {
//             this.totalMoneyHandler(value);
//             valid = true
//             error = '';
//         } else {
//             valid = false
//             error = 'Amount greater than 3 dollers';
//             if(this._unmounted){
//                 this.setState({
//                     feeRate:0,
//                     totalRate:0
//                 })
//             }
//         }

//         if(this._unmounted){
//             this.setState({
//                 rate:value,
//                 rateError:error,
//                 rateValid:valid
//             })
//         }
//     }

//     render() {
//         return (
//             <>
//             </>
//         )
//     }
// }

function ProposalComponent(props) {

    const router = useHistory()

    const [jobDetails, setJobDetails] = useState(null)
    const [rate, setRate] = useState('');
    const [rateError, setRateError] = useState('');
    const [rateValid, setRateValid] = useState(false);
    const [feeRate, setFeeRate] = useState('');
    const [totalRate, setTotalRate] = useState('');
    const [deadline, setDeadline] = useState('');
    const [deadlineError, setDeadlineError] = useState('');
    const [deadlineValid, setDeadlineValid] = useState(false);
    const [coverLetter, setCoverLetter] = useState('');
    const [coverLetterCount, setCoverLetterCount] = useState(0);
    const [coverLetterError, setCoverLetterError] = useState('');
    const [coverLetterValid, setCoverLetterValid] = useState(false);
    const [uploadFile, setUploadFile] = useState([]);
    const [loader, setLoader] = useState(false);
    const [jobID, setJobID] = useState(null);
    const [bidID, setBidID] = useState(null);
    const [imageArray, setImageArray] = useState([]);
    const [toDelete, setToDelete] = useState([]);

    const deadlineHandler = (e) => {
        const { value } = e.target;
        if (value > 0) {
            setDeadlineError('');
            setDeadlineValid(true)
        } else {
            setDeadlineError('Invalid delivery days');
            setDeadlineValid(false)
        }
        setDeadline(value)
    }

    const downloadImage = (imageUrl) => {
        const fileName = imageUrl.split('/').pop();
        fetch(imageUrl).then(function (t) {
            t.blob().then(b => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(b);
                link.download = fileName;
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
        })

    }

    const totalMoneyHandler = (price) => {
        const priceVal = parseInt(price);
        const percentage = 10;
        const fee = (priceVal * percentage) / 100;
        // console.log(fee);
        const total = priceVal - fee;
        setRate(priceVal)
        setFeeRate(fee)
        setTotalRate(total)
    }

    const rateHandler = (e) => {
        const { value } = e.target;
        if (value > 3) {
            totalMoneyHandler(value);
            setRateError('')
            setRateValid(true)
        } else {
            setRate(value);
            setRateValid(false)
            setFeeRate(0)
            setTotalRate(0)
            setRateError('Amount greater than 3 dollers')
        }
    }

    const coverHandler = (e) => {
        // const regWord = new RegExp(/(\w+)/g);
        const { value } = e.target;
        if (value !== '') {
            // const count = value.match(regWord).length
            const count = value.length
            if (count >= 50) {
                if (count <= 5000) {
                    setCoverLetterError('')
                    setCoverLetterValid(true)
                } else {
                    setCoverLetterError('Please enter maximum 5000 characters')
                    setCoverLetterValid(false)
                }

            } else {
                setCoverLetterError('Please enter minimum 50 characters');
                setCoverLetterValid(false)
            }
            setCoverLetterCount(count);
        } else {
            setCoverLetterError('Cover letter must be required');
            setCoverLetterValid(false)
        }

        setCoverLetter(value);
    }

    const fileHandler = (e) => {
        const { files } = e.target;
        let newArray = uploadFile;
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                if (uploadFile.length > 0) {
                    const filesUploaded = newArray.filter(info => files[i].name === info.name);
                    if (filesUploaded.length === 0) {
                        newArray.push(files[i])
                    }
                } else {
                    newArray.push(files[i])
                }
            }
        }
        setUploadFile(newArray);
        displayImageCreate(newArray, true);
    }

    const displayImageCreate = (files, isInput) => {
        let image = [];
        if (isInput) {
            if (files) {
                for (var h = 0; h < files.length; h++) {
                    if (files[h].id) {
                        if (!files[h].name) {
                            image.push({ ...files[h], name: files[h].name })
                        } else {
                            image.push(files[h])
                        }
                    } else {
                        const url = URL.createObjectURL(files[h]);
                        image.push({ url, name: files[h].name })
                    }
                }
                setImageArray(image);
            }

        } else {
            // console.log(files)
            if (files && files.length > 0) {

                setImageArray(files.map(info => ({ ...info, name: info.fileName })))
            } else {
                setImageArray([])
            }
        }

    }

    const deleteImg = (file, index) => {
        let files = uploadFile;
        console.log(files)
        const newArray = file.id ? files.filter(info => info.fileName.toLowerCase() !== file.fileName.toLowerCase()) : files.filter(info => info.name.toLowerCase() !== file.name.toLowerCase());
        // const newArray = files.filter(info => info.name.toLowerCase() !== file.name.toLowerCase());
        // console.log(newArray);
        // let errorFile = this.state.error.files;
        if (!file.id) {
            for (let i = 0; i < newArray.length; i++) {
                var allowedExtensions = new RegExp(/(jpg|jpeg|png|doc|xls|pdf|csv)$/i);
                var fileType = files[i].type.split('/').pop().toLowerCase();
                if (!allowedExtensions.exec(fileType)) {
                    // errorFile = "You have uploaded an jpg, jpeg, png, doc, xls, pdf and csv file type"
                } else {
                    // errorFile = '';
                }
            }
        }

        if (file.id) {
            setToDelete([...toDelete, file.id]);
        }

        displayImageCreate(newArray, file.id ? false : true);
        setUploadFile(newArray)
    }

    const submitProposal = async () => {
        if (!coverLetterValid) {
            setCoverLetterError(coverLetterError !== '' ? coverLetterError : 'Cover letter must be required');
            setCoverLetterValid(false);
            return notifyError(coverLetterError !== '' ? coverLetterError : 'Cover letter must be required')
        }

        if (!deadlineValid) {
            setDeadlineValid(false)
            setDeadlineError(deadlineError !== '' ? deadlineError : 'Delivered days must be required')
            return notifyError(deadlineError !== '' ? deadlineError : 'Delivered days must be required')
        }

        if (!rateValid) {
            setRateError(rateError !== '' ? rateError : 'Rate must be required')
            setRateValid(false);
            return notifyError(rateError !== '' ? rateError : 'Rate must be required')
        }

        setLoader(true);

        const data = {
            jobPostID: jobID,
            description: coverLetter,
            bidAmount: rate,
            bidCharge: feeRate,
            deadlineValue: deadline,
            deadlineType: 'days',
            file: uploadFile
        }

        const res = await addBids(data);
        if (res.status) {
            notifySuccess(res.message)
            setLoader(false);
            router.push('/freelancer/jobs');
        } else {
            notifyError(res.message)
            setLoader(false);
        }
    }

    const updateProposal = async () => {
        if (!coverLetterValid) {
            setCoverLetterError(coverLetterError !== '' ? coverLetterError : 'Cover letter must be required');
            setCoverLetterValid(false);
            return notifyError(coverLetterError !== '' ? coverLetterError : 'Cover letter must be required')
        }

        if (!deadlineValid) {
            setDeadlineValid(false)
            setDeadlineError(deadlineError !== '' ? deadlineError : 'Delivered days must be required')
            return notifyError(deadlineError !== '' ? deadlineError : 'Delivered days must be required')
        }

        if (!rateValid) {
            setRateError(rateError !== '' ? rateError : 'Rate must be required')
            setRateValid(false);
            return notifyError(rateError !== '' ? rateError : 'Rate must be required')
        }

        setLoader(true);

        const data = {
            jobPostID: jobID,
            description: coverLetter,
            bidAmount: rate,
            bidCharge: feeRate,
            deadlineValue: deadline,
            deadlineType: 'days',
            file: uploadFile,
            jobPostBidID: bidID,
            to_delete: toDelete
        }

        const res = await updateBids(data);
        if (res.status) {
            notifySuccess(res.message)
            setLoader(false);
            router.push('/freelancer/jobs');
        } else {
            notifyError(res.message)
            setLoader(false);
        }
    }

    useEffect(() => {

        if (!localStorage.getItem('token')) {
            return router.push('/login')
        }

        if (localStorage.getItem('token')) {
            const decode = jwtDecode(localStorage.getItem('token'))
            if (decode && Number(decode.profileType) === 2) {
                return router.push('/client/jobs');
            }

            // if (decode && Number(decode.profileType) === 1) {
            //     return router.push('/freelancer/jobs');
            // }
        }

        const getjobDetails = async () => {
            // console.log(props);
            const id = props.match.params.id;
            // const id = window.location.href.split('/').pop();

            // console.log(id);
            // console.log(router)
            const res = await getSingleJobdetails(id);
            // console.log(res);
            if (res) {
                setJobDetails(res);
                setJobID(res.id);
                totalMoneyHandler(res.budget);
                setRateValid(true);
                if (res.jobPostBidID > 0) {
                    setBidID(res.jobPostBidID)
                    getSingleBidApi(res.jobPostBidID)
                }
            } else {
                router.push('/freelancer/jobs');
                return true;
            }

        }

        const getSingleBidApi = async (id) => {
            const res = await getSingleBid(id);
            if (res.status) {
                // console.log(res);
                totalMoneyHandler(res.bidAmount)
                setRateError('');
                setRateValid(true);
                setDeadline(res.deadlineValue);
                setDeadlineError('');
                setDeadlineValid(true);
                setCoverLetter(res.description);
                setCoverLetterCount(res.description.length);
                setCoverLetterError('');
                setCoverLetterValid(true);
                displayImageCreate(res.attachments.data, false);
                setUploadFile(res.attachments.data);
            }
        }

        getjobDetails();
        setRateError('')
        setDeadline(7)
        setDeadlineError('');
        setDeadlineValid(true);
        setRateValid('');

    }, [router, props])

    // const feeHandler = ()=>{}

    // console.log(feeRate);
    return (
        <>
            {/* <Head>
                <title>Proposal | Vedain</title>
            </Head> */}
            <section className={`${'proposal_section'}`}>
                <div className="container">
                    <div className="row">
                        {(jobDetails && jobDetails.jobPostBidID > 0) ?
                            <div className="col-sm-12 mb-5">
                                <div className="card border-0 bg-white p-3 mb-0">
                                    <div className="card-header bg-transparent border-0">
                                        <h4 className="card-title mb-0 text-dark"><i className="me-3 fs-1"><MdStars /></i> You have already submitted proposals on this job</h4>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className="col-sm-12 mb-5">
                            <div className="card border-0 bg-white p-3 mb-0">
                                <div className="card-header bg-transparent">
                                    <h4 className="card-title text-dark">Job Details</h4>
                                </div>
                                <div className="card-body">
                                    <div className={`${'detail_wrap'} pt-3 pb-3`}>
                                        <h5 className="mb-3 fs-4"><b>{(jobDetails && jobDetails.title) ? jobDetails.title : ''}</b></h5>
                                        <div className={'description'}>
                                            <p className="text-dark">{jobDetails && jobDetails.description}</p>
                                        </div>
                                    </div>
                                    <div className={`${'expertise_area'} border-top pt-3`}>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <h5 className="mb-3"><b>Expertise</b></h5>
                                                <ul className={'budges_skills'}>
                                                    {jobDetails && jobDetails.services.length > 0 ?
                                                        jobDetails.services.map((info, i) => <li key={i} className="text-dark">{info.categoryName}</li>)
                                                        :
                                                        null
                                                    }
                                                </ul>
                                            </div>
                                            <div className="col-sm-6">
                                                <h5 className="mb-3"><b>Skills</b></h5>
                                                <ul className={'budges_skills'}>
                                                    {jobDetails && jobDetails.skills.length > 0 ?
                                                        jobDetails.skills.map((info, i) => <li key={i} className="text-dark">{info.categoryName}</li>)
                                                        :
                                                        null
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {jobDetails && jobDetails.attachments.length > 0 ?
                                        <div className={`${'expertise_area'} border-top pt-3`}>
                                            <div className="col-sm-12">
                                                <h5 className="mb-3"><b>Attachments</b></h5>
                                                <ul className={'file_name'}>
                                                    {jobDetails.attachments.map((info, i) =>
                                                        <li key={i}>
                                                            <span className={'attchment_icon'}><MdAttachFile /></span>
                                                            <span>{info.fileName}</span>
                                                            {/* <a className={view_filebtn} href={`${jobDetails.attachmentsPath}${info.fileName}`} target="_blank" rel="noreferrer"><MdRemoveRedEye /></a> */}
                                                            <button type="button" className={`${'view_filebtn'} ${'btn_downlo'}`} onClick={() => downloadImage(`${jobDetails.attachmentsPath}${info.fileName}`)}><MdFileDownload /></button>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 mb-5">
                            <div className="card border-0 bg-white p-3 mb-0">
                                <div className="card-header bg-transparent">
                                    <h4 className="card-title text-dark">Terms</h4>
                                </div>
                                <div className="card-body w-75">
                                    <p className="h5 opacity-75 mb-5 mt-4"><b>What is the rate you'd like to bid for this job?</b></p>
                                    <div className="row align-items-end border-bottom pb-5">
                                        <div className="col-sm-6">
                                            <p className="h5 fs-6 mb-0"><b>Fixed Rate</b><span className="d-block" style={{ opacity: '0.75' }}>Total amount the client will see on your proposal</span></p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p className="h5 fs-6 mb-3" style={{ opacity: '0.65' }}>Client's budget: ${jobDetails && jobDetails.budget}</p>
                                            <div className="position-relative mw-50">
                                                <input type="number" className={rateError !== '' ? "form-control ps-5 text-end w-50 error" : "form-control ps-5 text-end w-50"} value={rate} onChange={rateHandler} />
                                                <span className="fs-5 position-absolute top-50 translate-middle-y text-dark" style={{ left: '15px' }}><b>$</b></span>
                                            </div>
                                            {rateError !== '' ? <p className="error-text mb-0" style={{ fontSize: '12px' }}>{rateError}</p> : null}
                                        </div>
                                    </div>
                                    <div className="row align-items-end border-bottom pt-5 pb-5">
                                        <div className="col-sm-6">
                                            <p className="h5 fs-6 mb-0"><b>10% Vedain Service Fee</b><span className="d-block" style={{ opacity: '0.75' }}>Total amount the client will see on your proposal</span></p>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="position-relative mw-50">
                                                {/* {console.log(feeRate)} */}
                                                <input type="number" disabled className="form-control ps-5 text-end w-50 bg-transparent border-0" defaultValue={feeRate} />
                                                <span className="fs-5 position-absolute top-50 translate-middle-y text-dark" style={{ left: '15px' }}><b>$</b></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-start pt-5 pb-4">
                                        <div className="col-sm-6">
                                            <p className="h5 fs-6 mb-0" >
                                                <b>You'll Receive</b>
                                                <span className="d-block" style={{ opacity: '0.75' }}>The estimated amount you'll recevie after service fees</span>
                                            </p>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="position-relative mw-50">
                                                <input type="number" disabled className="form-control ps-5 text-end w-50 bg-transparent" defaultValue={totalRate} />
                                                <span className="fs-5 position-absolute top-50 translate-middle-y text-dark" style={{ left: '15px' }}><b>$</b></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 mb-5">
                            <div className="card border-0 bg-white p-3 mb-0">
                                <div className="card-body">
                                    <div className="form-group mb-0">
                                        <label htmlFor="exampleFormControlTextarea1" className="text-dark"><b>This project will be delivered in</b></label>
                                        <div className="input-group mt-2 w-25">
                                            <input type="number" className={deadlineError !== '' ? `form-control number_input error` : `form-control number_input`} placeholder="Days" aria-label="Days" value={deadline} onChange={deadlineHandler} aria-describedby="basic-addon2" />
                                            <span className="input-group-text" id="basic-addon2">Days</span>
                                        </div>
                                        {deadlineError !== '' ? <p className="error-text">{deadlineError}</p> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 mb-5">
                            <div className="card border-0 bg-white p-3">
                                <div className="card-header bg-transparent">
                                    <h4 className="card-title text-dark">Additional details</h4>
                                </div>
                                <div className="card-body">
                                    <div className="form-group mb-4">
                                        <label htmlFor="exampleFormControlTextarea1" className="text-dark"><b>Cover Letter</b></label>
                                        <p style={{ fontSize: '14px', opacity: '0.8', marginBottom: '10px' }} className="text-dark">Introduce yourself and explain why you're a strong candidate for this job. Feel free to suggest any changes to the job details or ask to schedule a video call.</p>
                                        <textarea className={coverLetterError !== '' ? "form-control error" : "form-control"} id="exampleFormControlTextarea1" rows="5" placeholder="Add cover letter" defaultValue={coverLetter} onChange={coverHandler}></textarea>
                                        <p className={'small_text'}><span>{coverLetterCount}/5000 characters (minimum 50)</span> {coverLetterError !== '' ? <span className="error-text mb-0">{coverLetterError}</span> : null} </p>

                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="text-dark"><b>Attachments</b></label>
                                        <div className={'file_input_wrap'}>
                                            <input id="attachments" multiple type="file" onChange={fileHandler} />
                                            <label className={'file_label3'} htmlFor="attachments"><span>Drag or <b>upload</b> project files</span></label>
                                        </div>
                                        {imageArray.length > 0 ?
                                            <ul className={'ul_files'}>
                                                {imageArray.map((file, i) => <li key={i}>
                                                    <span><MdPhotoSizeSelectActual /></span>
                                                    <p className="text-dark">{file.name}</p>
                                                    <button type="button" style={{ background: 'transparent', border: 0 }} onClick={() => deleteImg(file, i)}><MdClear /></button>
                                                </li>)}
                                            </ul>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div className="card-footer bg-transparent pt-4 pb-3">
                                    {jobDetails && jobDetails.jobPostBidID > 0 ?
                                        <button type="button" className="btn btn-dark me-3" onClick={updateProposal} disabled={loader}>{loader ? <SmallLoaderComponent /> : 'Update a Proposal'}</button>
                                        :
                                        <button type="button" className="btn btn-dark me-3" onClick={submitProposal} disabled={loader}>{loader ? <SmallLoaderComponent /> : 'Submit a Proposal'}</button>
                                    }
                                    <button type="button" className="btn btn-outline-dark border-0" onClick={() => router.push('/freelancer/jobs')}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default withRouter(connect(null)(ProposalComponent))
