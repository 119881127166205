import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';

class TitleComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.jobTitle,
            error: '',
            valid: props.jobTitle !== ''
        }
    }

    componentDidMount(){
        window.scroll(0, 0)
    }

    inputHandler = (e) => {
        const { value } = e.target;
        let error = '';
        let valid = false;
        if (value.length > 0) {
            error = '';
            valid = true;
        } else {
            error = 'Required';
            valid = false;
        }

        this.setState({
            title: value,
            error,
            valid
        })
    }

    nextStepFun = () => {
        if (this.state.title.length > 0) {
            if (this.props.isBackReview) {
                this.props.titleFunc(this.state.title);
                this.props.nextStep(5);
                this.props.backReviewFun(false);
            } else {
                const step = this.props.currentStep + 1;
                this.props.titleFunc(this.state.title);
                this.props.nextStep(step);
                this.props.backReviewFun(false);
            }

        } else {
            this.setState({
                error: 'Job title is required',
                valid: false
            })
        }
    }

    render() {

        return (
            <div className={'content_steps_wrap'}>
                <div className={'step_body'}>
                    <div className={'header_step'}>
                        <h2 className={'Header_Step_title'}>Job Title</h2>
                    </div>
                    <div className={'wrap_steps'}>
                        <div className="form-group">
                            <label className="label_control">Enter the Name of your Job Post</label>
                            <input type="text" name="title" className={this.state.error !== '' ? "form-control error" : "form-control"} onChange={this.inputHandler} value={this.state.title} />
                            {this.state.error !== '' ? <p className="error-text">{this.state.error}</p> : null}
                        </div>
                        <div className={'tip_wrap'}>
                            <h4 className={'tip_title'}>Here are some good examples:</h4>
                            <ul className={'tip_ul'}>
                                <li>Developer needed for creating a responsive WordPress Theme</li>
                                <li>Need a design for a new company logo</li>
                                <li>CAD designer to create a 3D model of a residential building</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={'step_footer'}>
                    <button type="button" className="btn btn-primary min-200" onClick={this.nextStepFun}>Next</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentStep: state.postJob.currentStep,
    jobTitle: state.postJob.jobTitle,
    isBackReview: state.postJob.isBackReview
})

const mapDispatchToProps = dispatch => ({
    nextStep: (step) => dispatch({ type: ACTION_TYPES.STEP_POST_JOBS, payload: step }),
    titleFunc: (val) => dispatch({ type: ACTION_TYPES.TITLE_POST_JOB, payload: val }),
    backReviewFun: (bool) => dispatch({ type: ACTION_TYPES.BACK_REVIEW_POSTJOB_STEPS, payload: bool })
})

export default connect(mapStateToProps, mapDispatchToProps)(TitleComponent);
