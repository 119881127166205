import { apiHelper } from "../helper/api.helper"
import { jwtDecode } from "../helper/jwt.helper"

// freelancer offer list
const getFreelancerOffers = async () => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper('freelancer/offer/list', 'GET', null, header);
        const res = response.data;
        if(res.status){
            return {status:true, data:jwtDecode(res.data), message:res.message}
        }else{
            return {status:false, data:[], message:res.message}
        }

    }catch(err){
        return {status:false, message:err.message}
    }

}

// freelancer offer detail
const getFreelancerOfferDetails = async (id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`freelancer/offer/details/${id}`, 'GET', null, header);
        const res = response.data;
        if(res.status){
            return {status:true, data:jwtDecode(res.data), message:res.message}
        }else{
            return {status:false, data:[], message:res.message}
        }

    }catch(err){
        return {status:false, message:err.message}
    }

}

// freelancer offer detail
const offerAccept = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        let formData = new FormData()
        formData.append('jobPostID', data.jobPostID)
        // formData.append('freelancerProfileID', data.freelancerProfileID)

        const response = await apiHelper(`freelancer/offer/accept`, 'POST', formData, header);
        const res = response.data;
        if(res.status){
            return {status:true, data:jwtDecode(res.data), message:res.message}
        }else{
            return {status:false, data:[], message:res.message}
        }

    }catch(err){
        return {status:false, message:err.message}
    }

}

// freelancer offer detail
const offerDeclien = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        let formData = new FormData()
        formData.append('jobPostID', data.jobPostID)
        // formData.append('freelancerProfileID', data.freelancerProfileID)

        const response = await apiHelper(`freelancer/offer/cancel`, 'POST', formData, header);
        const res = response.data;
        if(res.status){
            return {status:true, data:jwtDecode(res.data), message:res.message}
        }else{
            return {status:false, data:[], message:res.message}
        }

    }catch(err){
        return {status:false, message:err.message}
    }

}

const offerService = {
    getFreelancerOffers,
    getFreelancerOfferDetails,
    offerAccept,
    offerDeclien
}

export default offerService;