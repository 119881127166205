import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import { MdArrowBack } from 'react-icons/md';
import payment from '../../../controller/payment.controller';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { notifyError } from '../../../helper/Alert';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';

class RefundRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader:false,
            jobId: 0,
            milestoneId: 0
        }
    }

    componentDidMount() {
        const { match: { params: { jobid } }, location:{ search } } = this.props;
        const paramsUrl = new URLSearchParams(search);
        const id_milestone = paramsUrl.get('ml_id')
        if(!id_milestone){
            return this.props.history.goBack()
        }

        if(!jobid){
            return this.props.history.goBack()
        }

        this.setState({
            jobId: jobid,
            milestoneId: id_milestone,
        })
    }

    resonSubmit = async (values, actions) => {
        // e.preventDefault();
        // console.log(values);
        this.setState({
            loader:true
        })
        const data = {
            jobPostID:this.state.jobId, 
            reason:values.reason, 
            milestoneID:this.state.milestoneId
        }
        const res = await payment.refundApi(data)
        if(!res.status){
            this.setState({
                loader:false
            })
            return notifyError(res.message)
        }
        this.setState({
            loader:false
        })
        this.props.history.push(`/client/my-contracts/${this.state.jobId}`);
        actions.resetForm({
            values:{
                reason:''
            }
        })

    }

    render() {

        const resonSchema = Yup.object().shape({
            reason: Yup.string().required('Refund resaon must be required')
        });

        const { loader } = this.state;

        return (
            <section className={`proposals_section pt-5`}>
                <div className="container">
                    <div className="row justify-content-sm-center">
                        <div className="col-sm-8 d-flex justify-content-between align-items-start">
                            <h1 className={'pageTitle'} >Refund Request</h1>
                            {/* <button type="button" className="btn btn-link" onClick={() => this.props.history.goBack()}><MdArrowBack /> Back</button> */}
                        </div>
                        <div className="col-sm-8 mt-4">
                            <div className="card border-0 p-0">
                                <div className="card-body p-3">
                                    <Formik initialValues={{ reason: '' }} validationSchema={resonSchema} onSubmit={(values, actions) => this.resonSubmit(values, actions)}>
                                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                            <form action="/" method="POST" onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label className="form-label required">Reason for refund milestone</label>
                                                    <textarea name="reason" onChange={handleChange} onBlur={handleBlur} className={`form-control ${errors.reason && touched.reason ? 'error' : ''}`} rows="8"></textarea>
                                                    {errors.reason && touched.reason ? <p className="error-text">{errors.reason}</p> : null}
                                                </div>
                                                <div className="form-group mt-4">
                                                    <button className="btn btn-success me-2" type="submit" style={{minWidth:'100px'}}>{loader?<SmallLoaderComponent color="white" />:'Submit'}</button>
                                                    <button type="button" className="btn bg-transparent border-0" onClick={() => this.props.history.goBack()}>{'Cancel'}</button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(connect(null)(RefundRequest))
