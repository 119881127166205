import { apiHelper } from "../../helper/api.helper";
import { jwtDecode } from "../../helper/jwt.helper";

export const getAllAdmins = async () => {
    try {

        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const response = await apiHelper('admin/getAllAdmin', 'GET', null, header);
        const res = response.data;
        // console.log(res);
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

export const newAdminCreate = async (data) => {
    try {

        const { firstName, lastName, email, password } = data;

        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        let formData = new FormData();
        formData.append('firstName', firstName)
        formData.append('lastName', lastName)
        formData.append('email', email)
        formData.append('password', password)

        const response = await apiHelper('admin/register', 'POST', formData, header);
        const res = response.data;
        // console.log(res);
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

export const updateExitsAdmin = async (data) => {
    try {

        const { id, firstName, lastName, password } = data;

        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        let formData = new FormData();
        formData.append('id', id)
        formData.append('firstName', firstName)
        formData.append('lastName', lastName)
        if (password !== "") {
            formData.append('password', password)
        }

        const response = await apiHelper('superadmin/update/profile', 'PUT', formData, header);
        const res = response.data;
        // console.log(res);
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}
export const deleteExitAdmin = async (id) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const response = await apiHelper(`admin/delete/bysuperadmin/${id}`, 'DELETE', null, header);
        const res = response.data;
        // console.log(res);
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}