import React, { Component } from 'react';
import { io } from "socket.io-client";
import Modal from 'react-bootstrap/Modal'
import '../../assets/css/message.css';
import { jwtDecode } from '../../helper/jwt.helper';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IoChatbubbles, IoPersonAddOutline } from 'react-icons/io5';
import { FiEdit2 } from 'react-icons/fi';
import ChannelList from './ChannelList';
import MessageBox from './MessageBox';
import MessagePanel from './MessagePanel';
import { DeleteSingleFile, getChannels, CHAT_URL, getHistoryMessage, memberForAll, fileUploading, getSingleChat } from '../../controller/chat.controller';
// DeleteSingleMessage, memberForAdd, createGroupForMembers, removeMemberForGroup
import { reverseArr } from '../../helper/array.helper';
import { MdClose, MdDone, MdSearch } from 'react-icons/md';
import { notifyError, notifySuccess } from '../../helper/Alert';
import MainLoaderComponent from '../../helper/MainLoaderComponent';
import * as ACTION_TYPES from '../../redux/actions/type';

const SERVER = CHAT_URL;

class MessageComponent extends Component {

    socket;
    typingTime = 3000;
    // socket = socketClient(SERVER);

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isAddMemberLoader: false,
            isLoader: false,
            isMessageLoader: false,
            hasMore: false,
            messageOver: false,
            isFreelancer: false,
            isClient: false,
            titleGroupModal: false,
            groupTitle: '',
            itsDeteled: false,
            isAdded: false,
            isSelected: false,
            channelList: [],
            activeChannelID: 0,
            user: null,
            singleConverstion: null,
            messageList: [],
            isTyping: false,
            isModalAdd: false,
            memberList: [],
            newMember: null,
            filePath: '',
            memberSearchArray: [],
            searchMember: '',
            selectedMember: [],
            selectedMembersID: [],
            deleteMember: [],
            imagePath: '',
            profilePath: '',
            selectedChannelData: null,
            typingID: 0,
            removeMemberGroups: null,
            youRemoveGroup: null,
            profileURL: ''
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);
        this.getAllChannel();
        // this.configureSocket();
        // this.getMemberListForAdded();

        const token = localStorage.getItem('token');

        if (!token) {
            return this.props.history.push('/login')
        } else {

            const tokenDecode = jwtDecode(token);
            if (tokenDecode === null) {
                return this.props.history.push('/login')
            }
            this.configureSocket(tokenDecode);
            // this.socket.io.on("close", this.tryReconnect());
            this.getAllChannel();
            const chatId = localStorage.getItem('chatID');
            if (chatId) {
                if (this._unmounted) {
                    this.setState({
                        activeChannelID: parseInt(chatId)
                    })
                    this.getMessages(chatId);
                }
            }

            if (parseInt(tokenDecode.profileType) === 2) {
                this.getMemberListForAdded();
            }

            if (this._unmounted) {
                this.setState({
                    user: tokenDecode
                })
            }

            if (Number(tokenDecode.profileType) === 1) {
                if (this._unmounted) {
                    this.setState({
                        isFreelancer: true
                    })
                }
            } else {
                if (this._unmounted) {
                    this.setState({
                        isFreelancer: false
                    })
                }
            }

            if (Number(tokenDecode.profileType) === 2) {
                if (this._unmounted) {
                    this.setState({
                        isClient: true
                    })
                }
            } else {
                if (this._unmounted) {
                    this.setState({
                        isClient: false
                    })
                }
            }
        }
    }

    componentWillUnmounted() {
        this._unmounted = false;
    }

    getSingleConverstion = async (id) => {
        const res = await getSingleChat(id);
        // console.log(res);
        if (res.status) {
            let members = [];
            if (Array.isArray(res.data.conversationMember) && res.data.conversationMember.length > 0) {
                members = res.data.conversationMember.filter((info) => info.id !== this.state.user.id);
            }

            if (this._unmounted) {
                this.setState({
                    singleConverstion: res.data,
                    selectedMember: members,
                    selectedMembersID: members.length > 0 ? members.map(info => info.id) : []
                })
            }
        }
    }

    onTypingFuntion = (bool) => {
        if (this._unmounted) {
            this.setState({
                isTyping: bool
            })
        }
    }

    configureSocket = (tokenDecode) => {
        var socket = io(SERVER, {
            secure: true,
            rememberUpgrade: true
        });
        // , {
        //     reconnection: false,
        //     transports: ["websocket", "polling"]
        // }
        socket.on('connection', () => {
            // console.log(socket);
            // console.log('socket is connected!')
            // if (this.state.channel) {
            //     this.handleChannelSelect(this.state.channel);
            // }
        });



        socket.emit('channelList', { profileID: tokenDecode.id, profileType: tokenDecode.profileType })

        // socket.on('getChannelList', res => {
        //     if (res.status) {
        //         if (Array.isArray(res.data) && res.data.length > 0) {
        //             const info = res.data[0];
        //             const data = res.data;
        //             const chatId = localStorage.getItem('chatID') ? localStorage.getItem('chatID') : info.id;
        //             if (!localStorage.getItem('chatID')) {
        //                 localStorage.setItem('chatID', info.id);
        //                 if (this._unmounted) {
        //                     this.setState({
        //                         activeChannelID: info.id
        //                     });
        //                 }
        //             }
        //             if (this._unmounted) {
        //                 this.setState({
        //                     channelList: data,
        //                     activeChannelID: parseInt(chatId),
        //                     selectedChannelData: info,
        //                 });
        //             }
        //         }
        //     }
        // })

        socket.on("typingReceiveEmit", (data) => {
            // console.log(data);
            // console.log(this.state);
            if (data.conversationId === parseInt(this.state.activeChannelID)) {
                if (this._unmounted) {
                    this.setState({
                        isTyping: true,
                        typingID: data.sender_id
                    })

                    this.typingTime += 2000;
                }


            }
            setTimeout(() => {
                if (this._unmounted) {
                    this.setState({
                        isTyping: false
                    })
                }
            }, this.typingTime);
        })

        socket.on('privateConversationCreated', data => {
            // console.log('privateConversationCreated');
            // console.log(data);
            if (data) {
                if (this.state.user) {
                    if (Array.isArray(data.chat_member_names) && data.chat_member_names.length > 0) {
                        const findUser = data.chat_member_names.find(info => info.id === this.state.user.id);
                        if (findUser) {
                            const findLit = this.state.channelList.find(info => info.id === data.id)
                            if (!findLit) {
                                if (this._unmounted) {
                                    this.setState({
                                        channelList: [data, ...this.state.channelList],
                                        messageList: []
                                    })
                                }

                                const jrData = {
                                    userId: this.state.user.id,
                                    conversationId: data.id
                                }
                                socket.emit("joinInConversation", jrData)

                                this.getMessages(data.id);
                                this.getSingleConverstion(data.id);
                                localStorage.setItem('chatID', data.id)
                            }
                        }

                    }
                }


                // console.log(jrData)


            }
        })


        socket.on('errorWhileCreateGroupConversation', (error) => {
            // console.log('errorWhileCreateGroupConversation');
            // console.log(error);
            if (error) {
                notifyError(error)
            }
        })


        socket.on('groupConversationCreated', (data) => {
            // console.log(data);
            if (data) {
                const findArr = this.state.channelList.find(info => info.id === data.id);
                if (!findArr) {
                    if (this._unmounted) {
                        this.setState({
                            activeChannelID: data.id,
                            channelList: [
                                data,
                                ...this.state.channelList
                            ],
                            messageList: [],
                            isSelected: false,
                            isAdded: true
                        })
                        this.getMessages(data.id);
                        this.getSingleConverstion(data.id);
                    }
                    const jrData = {
                        userId: this.state.user.id,
                        conversationId: data.id
                    }
                    socket.emit("joinInConversation", jrData)
                    localStorage.setItem('chatID', data.id)
                    // notifySuccess();
                    this.closeModalAdd();
                }
            }
        })

        socket.on('errorWhileAddMemberInGroup', error => {
            // console.log(error);
            if (error) {
                notifyError(error);
            }
        })

        socket.on('memberAddedInGroup', data => {
            // console.log('memberAddedInGroup');
            // console.log(data);
            let selectedData = this.state.channelList;
            if (Array.isArray(selectedData) && selectedData.length > 0) {
                if (this.state.activeChannelID === data.id) {
                    const index = selectedData.findIndex(info => info.id === data.id);
                    selectedData[index] = data;
                    // if (Array.isArray(data.newAddedMember) && data.newAddedMember.length > 0) {
                    // const isNewmember = data.newAddedMember.find(info=>info )
                    // if (this.state.user.id !== ) {
                    notifySuccess('New Member added');
                    if (this._unmounted) {
                        this.setState({
                            channelList: selectedData,
                            isAdded: true,
                            newMember: data,
                            isAddMemberLoader: false
                            // messageList:[],
                        })
                    }
                    // }
                    // }
                    // console.log(selectedData[index]);
                }
                this.closeModalAdd();
                if (this._unmounted) {
                    this.setState({
                        isAddMemberLoader: false,
                        selectedMember: [],
                        selectedMembersID: []
                    })
                }

            }
        })

        socket.on('youAddedInGroup', data => {
            // console.log('youAddedInGroup');
            // console.log(data);
            if (data) {
                if (this.state.user && data.newAddedMember && data.newAddedMember.length > 0) {
                    const findUser = data.newAddedMember.find(info => info === this.state.user.id)
                    if (findUser && findUser > 0) {
                        if (this._unmounted) {
                            this.setState({
                                channelList: [data, ...this.state.channelList],
                                messageList: [],
                                isAddMemberLoader: false,
                                selectedMember: [],
                                selectedMembersID: []
                            })
                        }
                        this.getMessages(data.id);
                        this.getSingleConverstion(data.id);

                        const jrData = {
                            userId: this.state.user.id,
                            conversationId: data.id
                        }
                        socket.emit("joinInConversation", jrData)
                        localStorage.setItem('chatID', data.id)
                        // notifySuccess();
                        this.closeModalAdd();
                    }
                }
            }
        })

        socket.on('memberRemovedFromGroup', data => {
            // console.log('memberRemovedFromGroup');
            // console.log(data);
            let selectedData = this.state.channelList;
            let selectedMember = this.state.selectedMember;
            if (Array.isArray(selectedData) && selectedData.length > 0) {
                const index = selectedData.findIndex(info => info.id === data.id);
                selectedData[index] = data;
                if (data.removedMember) {
                    if (this.state.user.id !== data.removedMember.id) {
                        notifySuccess(data.removedMember.name + ' Removed');
                    }

                    if (this.state.user.id === data.removedMember.id) {
                        selectedData = selectedData.filter(info => info.id !== data.id)
                    }
                }
                const filter = selectedMember.filter(info => info.id !== data.removedMember.id);
                const ids = this.state.selectedMembersID.filter(info => info !== data.removedMember.id)
                // console.log(selectedData);
                if (this._unmounted) {
                    this.setState({
                        channelList: selectedData,
                        removeMemberGroups: data,
                        itsDeteled: true,
                        isSelected: false,
                        isAdded: false,
                        selectedMember: filter,
                        selectedMembersID: ids
                    })
                }
            }

        })

        socket.on('errorWhileremoveMember', error => {
            // console.log(error);
            if (error) {
                notifyError(error)
            }
        })

        socket.on('youHaveRemovedFromGroup', data => {
            // console.log('youHaveRemovedFromGroup');
            // console.log(this.state.user);
            // console.log(data);
            let selectedData = this.state.channelList
            if (Array.isArray(selectedData) && selectedData.length > 0) {
                if (this.state.user.id === data.removedMember.id) {
                    selectedData = selectedData.filter(info => info.id !== data.id)
                    if (this.state.user.id === data.removedMember.id) {
                        notifySuccess('You are removed from group');
                    }
                    if (this._unmounted) {
                        this.setState({
                            channelList: selectedData,
                            messageList: [],
                            youRemoveGroup: data,
                            itsDeteled: true,
                        })
                    }
                }

            }
        })

        socket.on('messageReceiveEmit', message => {
            // console.log(message);
            // console.log(tokenDecode.id !== message.sender_id);
            // console.log(this.props.urlPath);
            // // if (message) {
            if (tokenDecode.id !== message.sender_id) {
                // if (this.props.urlPath !== '/message') {
                this.props.updateNotifyBool(true);
                // }
                // else{
                //     this.props.updateNotifyBool(false);
                // }
            }
            // }

            if (message) {
                if (this._unmounted) {
                    this.setState({
                        isAdded: true
                    })
                }
                let list = this.state.channelList;

                if (Array.isArray(list) && list.length > 0) {
                    list.map(info => {
                        if (info.id === message.conversation_id) {
                            info.last_message_at = message.created_at
                        }
                        return info;
                    })
                    list.sort((a, b) => new Date(b.last_message_at) - new Date(a.last_message_at))
                }

                if (this._unmounted) {
                    this.setState({
                        channelList: list,
                        isAdded: false
                    })
                }
            }

            if (this.state.activeChannelID === message.conversation_id) {


                // console.log(list);

                if (this._unmounted) {
                    this.setState({
                        messageList: [
                            ...this.state.messageList,
                            message
                        ],
                        isTyping: false
                    })
                }
                // message seen event when message append
                if (parseInt(message.sender_id) !== parseInt(tokenDecode.id)) {
                    // console.log("-------messageSeenSingleEmit");
                    socket.emit("messageSeenSingleEmit", { profile_id: tokenDecode.id, message_id: message.id })
                }
            }

            if (this.state.activeChannelID !== message.conversation_id) {
                if (this._unmounted) {
                    this.setState({
                        isAdded: true
                    })
                }
                let list = this.state.channelList;
                if (Array.isArray(list) && list.length > 0) {
                    list.map(info => {
                        if (info.id === message.conversation_id) {
                            info.unread_messages = info.unread_messages + 1
                        }
                        return info
                    })
                    // const index = list.findIndex(info => info.id === message.conversation_id);
                    // // console.log(index);
                    // if (index > -1) {
                    //     list[index].unread_messages = list[index].unread_messages + 1;
                    // }

                    if (this._unmounted) {
                        this.setState({
                            channelList: list,
                            isAdded: false
                        })
                    }
                }

            }

        });

        socket.on('errorWhileMessageSeenEvent', data => {
            // console.log('errorWhileMessageSeenEvent');
            // console.log(data);
        })

        socket.on('messageSeenNotifyEmit', data => {
            // console.log('messageSeenNotifyEmit');
            // console.log(data);
            if (data && data.length > 0) {
                data.forEach(element => {
                    // console.log(element.profile_id);
                    // console.log(tokenDecode.id);
                    if (Number(element.profile_id) === tokenDecode.id) {
                        // console.log('id Match');
                        // this.props.updateNotifyCount(0);
                        // if (element.message_seen_status === 1) {
                        this.props.updateNotifyBool(false);
                        // }
                    }
                    // console.log('not Match');
                })
            }
        })
        socket.on('messageSeenMultyEmit ', data => {
            // console.log('messageSeenMultyEmit ');
            // console.log(data);
        })

        if (tokenDecode) {
            const dataMess = {
                profile_id: tokenDecode.id
            }

            // console.log(dataMess)

            socket.emit('getunseenMessageCount', dataMess);
        }

        socket.on('errorWhilegetCountOfUser', data => {

            // console.log('errorWhilegetCountOfUser');
            // console.log(data);
        })

        // socket.on('countOfUnreadMessageOfUser', data => {

        //     console.log('countOfUnreadMessageOfUser');
        //     console.log(data);
        // })
        // socket.on('countOfUnreadMessageOfUser', data => {
        //     console.log(data);
        //     if (data.count && data.count > 0) {
        //         this.props.updateNotifyCount(data.count);
        //         this.props.updateNotifyBool(true);
        //     } else {
        //         this.props.updateNotifyBool(false);
        //         this.props.updateNotifyCount(0);
        //     }
        // })



        socket.on('conversationNameChangeNotifyEmit', data => {
            // console.log(data);
            if (this._unmounted) {
                this.setState({
                    isAdded: true
                })
            }
            if (this.state.activeChannelID === data.conversation_id) {
                let selectedData = this.state.channelList;
                if (Array.isArray(selectedData) && selectedData.length > 0) {
                    // const findArray = selectedData.find(info => info.id === data.conversation_id)
                    const index = selectedData.findIndex(info => info.id === data.conversation_id)
                    // console.log(index);
                    // console.log(this.state.channelList);
                    selectedData[index].name = data.conversation_name;
                    selectedData[index].name_updateBy = data.updatedBy;
                    if (this._unmounted) {
                        this.setState({
                            isAdded: false,
                            // selectedChannelData: selectedData,
                            channelList: selectedData
                        })
                    }
                }
                // console.log(selectedData);


            }
        });

        // socket.on('newGroupCreated', data => {
        //     // console.log(data);
        //     if (this._unmounted) {
        //         this.setState({
        //             channelList: [
        //                 data,
        //                 ...this.state.channelList
        //             ]
        //         })
        //     }

        // });

        socket.on('deleteMessageReceiveEmit', message => {
            // console.log(message)
            const messageList = this.state.messageList;
            if (this.state.activeChannelID === message.conversation_id) {
                const index = messageList.map(element => {
                    if (element.id === message.message_id) {
                        element.is_deleted = 1
                    }
                    return element;
                })

                if (this._unmounted) {
                    this.setState({
                        messageList: index
                    })
                }
            }
        })

        socket.on('errorWhileSendmessage', message => {
            // console.log(message);
            if (message) {
                notifyError(message)
            }
        });
        socket.on('errorWhileGetConversation', listChannel => {
            // console.log(listChannel);
            if (listChannel) {
                notifyError(listChannel)
            }
        });

        socket.on('errorWhileMessageSeenMultyEvent', message => {
            console.log('errorWhileMessageSeenMultyEvent');
            console.log(message);

        });
        socket.on('messageSeenSelfMultyNotifyEmit', data => {
            // console.log(data);
            if (data && data.length > 0) {
                // let countMessage = 0;
                if (this._unmounted) {
                    this.setState({
                        isAdded: true
                    })
                }
                let conversation_id = 0;

                data.forEach(element => {
                    conversation_id = element.conversation_id
                })

                let list = this.state.channelList;
                if (Array.isArray(list) && list.length > 0) {
                    const index = list.findIndex(info => info.id === conversation_id);
                    // console.log(index);
                    if (index > -1) {
                        list[index].unread_messages = list[index].unread_messages - data.length;
                    }
                }

                // console.log(list);
                if (this._unmounted) {
                    this.setState({
                        channelList: list,
                        isAdded: false
                    })
                }
            }

        });
        socket.on('messageSeenMultyNotifyEmit', data => {
            // console.log('messageSeenMultyNotifyEmit');
            // console.log(data);
            // if (data && data.length > 0) {
            // let countMessage = 0;
            // let conversation_id = 0;
            // let list = this.state.channelList;
            // data.forEach(element => {
            // if (Number(element.profile_id) === tokenDecode.id) {
            // console.log(data);
            // console.log('id Match');
            // this.props.updateNotifyCount(0);
            // if (element.message_seen_status === 1) {
            //     // countMessage++;
            //     if (Array.isArray(list) && list.length > 0) {
            //         const index = list.findIndex(info => info.id === element.conversation_id);
            //         console.log(index);
            //         if (index > -1) {
            //             // list[index].unread_messages - 1;
            //         }
            //     }
            // }
            // }
            // console.log('not Match');
            // })

            // console.log(list);
            // }
            // if (listChannel) {
            //     notifyError(listChannel)
            // }
        });

        this.socket = socket;
    }

    // tryReconnect = () => {
    //     setTimeout(() => {
    //         this.socket.io.open((err) => {
    //             if (err) {
    //                 this.tryReconnect();
    //             }
    //         });
    //     }, 2000);
    // }

    getAllChannel = async () => {
        if (this._unmounted) {
            this.setState({
                isLoader: true
            })
        }
        const res = await getChannels();
        // console.log(res);
        if (res.status) {
            if (Array.isArray(res.data.data) && res.data.data.length > 0) {
                if (this._unmounted) {
                    const data = res.data.data;
                    let chatID = 0;
                    let selectedData = null;
                    if (localStorage.getItem('chatID')) {
                        chatID = parseInt(localStorage.getItem('chatID'));
                        const selected = data.filter(item => item.id === chatID);
                        selectedData = selected[0];

                    } else {
                        const info = res.data.data[0];
                        localStorage.setItem('chatID', info.id);
                        chatID = info.id;
                        selectedData = info;
                    }

                    this.setState({
                        channelList: data,
                        activeChannelID: parseInt(chatID),
                        selectedChannelData: selectedData,
                        profileURL: res.data.profileURL,
                        isLoader: false
                    });

                    this.getSingleConverstion(chatID);
                    this.getMessages(parseInt(chatID))
                    if (Array.isArray(data) && data.length > 0) {
                        data.forEach(element => {
                            const jrData = {
                                userId: this.state.user.id,
                                conversationId: element.id
                            }

                            // console.log(jrData)

                            this.socket.emit("joinInConversation", jrData)
                        });
                    }
                }
            } else {
                if (this._unmounted) {
                    this.setState({
                        channelList: [],
                        profileURL: '',
                        isLoader: false
                    })
                }
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    channelList: [],
                    profileURL: '',
                    isLoader: false
                })
            }
        }
    }

    onscrollMessage = (e) => {
        // console.log(e);
        const divId = document.getElementById(e.target.id);
        if (this.state.messageOver) {
            if (!this.state.isMessageLoader) {
                if (this.state.messageList.length > 0 && divId.scrollTop === 0) {
                    if (this._unmounted) {
                        this.setState({
                            hasMore: true
                        }, () => this.getMessages(this.state.activeChannelID))
                    }
                } else {
                    if (this._unmounted) {
                        this.setState({
                            hasMore: false
                        })
                    }
                }
            }
        }
        // const hei = e.target.scrollTop
        // console.log();

    }

    getMessages = async (id) => {
        // console.log(id)
        let data = null;

        if (this._unmounted) {
            this.setState({
                isMessageLoader: true
            })
        }

        if (this.state.messageList.length > 0 && this.state.hasMore) {
            const lastid = this.state.messageList[0];
            data = {
                id: id,
                lastChatId: lastid.id
            }
        } else {
            data = {
                // lastChatId:317,
                id: id
            }
        }

        const res = await getHistoryMessage(data);
        // console.log(res.data);
        if (res.status) {
            const array = reverseArr(res.data.data)
            if (this._unmounted) {
                if (this.state.hasMore) {

                    // const margeArr = [...this.state.messageList, ...res.data.data];
                    this.setState({
                        messageList: [...array, ...this.state.messageList],
                        filePath: res.data.path,
                        profilePath: res.data.profilePath,
                        messageOver: true,
                        isMessageLoader: false
                    })
                } else {
                    this.setState({
                        messageList: array,
                        filePath: res.data.path,
                        profilePath: res.data.profilePath,
                        messageOver: true,
                        isMessageLoader: false
                    })
                    this.socket.emit('messageSeenMultyEmit', { profile_id: this.state.user.id, conversation_id: id })
                }
                this.props.updateNotifyBool(false);

            }
        } else {
            if (this._unmounted) {
                this.setState({
                    messageList: this.state.messageList,
                    hasMore: false,
                    messageOver: false,
                    isMessageLoader: false
                })
            }
        }

    }

    handleChannelSelect = (info) => {
        // console.log(id);
        if (info.id !== this.state.activeChannelID) {
            this.getSingleConverstion(info.id);
            localStorage.setItem('chatID', info.id)
            if (this._unmounted) {
                this.setState({
                    activeChannelID: info.id,
                    selectedChannelData: info,
                    isTyping: false,
                    hasMore: false,
                    messageList: []
                }, () => this.getMessages(info.id))

            }
        }
    }

    handleSendMessage = async (channel_id, text, files) => {
        // console.log(text);
        // this.state.messageList
        if (text !== '' || files.length > 0) {
            if (files.length > 0) {
                await fileUploading({
                    message: text,
                    conversationId: this.state.activeChannelID,
                    from: this.state.user.id,
                    file: files
                });

                // console.log(res);
            }
            this.socket.emit('messageSendEmit', {
                message: text,
                from: this.state.user.id,
                to: { id: this.state.activeChannelID }
            });
        }

        // this.getMessages(this.state.activeChannelID);
    }

    getMemberListForAdded = async () => {

        const res = await memberForAll();
        // console.log(res);
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    memberList: res.data.profileList,
                    memberSearchArray: res.data.profileList,
                    imagePath: res.data.path
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    memberList: [],
                    memberSearchArray: []
                })
            }
        }
    }

    openModalAdd = () => {
        if (this._unmounted) {
            this.setState({
                isModalAdd: true
            })
        }
    }

    closeModalAdd = () => {
        if (this._unmounted) {
            this.setState({
                isModalAdd: false,
                isAdded: false,
                selectedMembersID: [],
                selectedMember: [],
                isAddMemberLoader: false
            })
        }
    }

    inputSearchMember = (e) => {
        const search = e.target.value;
        const list = this.state.memberList;
        let searchArr = []
        if (search !== '') {
            searchArr = list.length > 0 ? list.filter(info => info.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) : list
        } else {
            searchArr = list
        }

        if (this._unmounted) {
            this.setState({
                searchMember: search,
                memberSearchArray: searchArr
            })
        }
    }

    selectMemberHandler = (info) => {
        // const selectedMembers = ;
        // let deleteId = this.state.deleteMember
        let newArray = this.state.selectedMember
        if (newArray.length > 0) {
            if (!newArray.some(item => item.id === info.id)) {
                newArray.push(info);
                // console.log(info);
                if (this._unmounted) {
                    this.setState({
                        isSelected: true,
                        itsDeteled: false,
                        isAdded: false
                    })
                }
            }
        } else {
            newArray.push(info);
            if (this._unmounted) {
                this.setState({
                    isSelected: true,
                    itsDeteled: false,
                    isAdded: false,
                })
            }
        }
        // console.log(newArray)

        if (this._unmounted) {
            this.setState({
                selectedMember: newArray,
                selectedMembersID: newArray.length > 0 ? newArray.map(item => item.id) : []
            })
        }
    }

    deleteFile = async (info, files) => {
        let messages = this.state.messageList;
        const data = {
            fileID: files.id,
            messageID: info.id,
            senderID: info.sender_id
        }
        const res = await DeleteSingleFile(data);
        // console.log(res);
        if (res.status) {
            if (Array.isArray(messages) && messages.length > 0) {
                const messageIndex = messages.findIndex(m => m.id === info.id);
                if (messageIndex > -1) {
                    if (Array.isArray(messages[messageIndex].fileArray) && messages[messageIndex].fileArray.length > 0) {
                        messages[messageIndex].fileArray = messages[messageIndex].fileArray.filter(f => f.id !== files.id)
                    }
                    this.getMessages(this.state.activeChannelID)
                }
                // console.log(messages);

                this.setState({
                    messageList: messages
                })
            }
        }
    }

    deleteSelectedMember = async (info) => {
        let memberSelected = this.state.selectedMember;
        if (memberSelected.length > 0) {
            if (this.state.isSelected) {
                memberSelected = memberSelected.filter(m => m.id !== info.id)
                this.setState({
                    selectedMember: memberSelected,
                    selectedMembersID: memberSelected.length > 0 ? memberSelected.map(info => info.id) : []
                })
            } else {
                const data = {
                    profileId: this.state.user?.id,
                    conversationId: this.state.activeChannelID,
                    deleteProfileId: info.id
                }
                this.socket.emit('remveMemberFromConversation', data);
            }
        }
        // const { singleConverstion, user, selectedMember } = this.state;
        // let singleConverstionObject = singleConverstion;
        // if (singleConverstionObject.type !== "SINGLE") {
        //     let memberSelected = selectedMember;
        //     let members = [];
        //     if (Array.isArray(singleConverstionObject.conversationMember) && singleConverstionObject.conversationMember) {
        //         members = singleConverstionObject.conversationMember.filter(us => us.id !== user.id);

        //     }

        //     if (memberSelected.length > 0) {
        //         if (this.state.isSelected) {
        //             memberSelected = memberSelected.filter(m => m.id !== info.id)
        //         } else {
        //             const data = {
        //                 profileId: this.state.user?.id,
        //                 conversationId: this.state.activeChannelID,
        //                 deleteProfileId: info.id
        //             }
        //             this.socket.emit('remveMemberFromConversation', data);
        //             const res = await removeMemberForGroup({ id: info.id, conversationId: this.state.activeChannelID });
        //             if (res.status) {
        //                 memberSelected = memberSelected.filter(m => m.id !== info.id)
        //                 singleConverstionObject.conversationMember = singleConverstionObject.conversationMember.filter(m => m.id !== info.id)
        //                 if (this._unmounted) {
        //                     this.setState({
        //                         itsDeteled: true,
        //                         isSelected: false,
        //                         isAdded: false,
        //                     })
        //                 }
        //             }
        //         }
        //     }

        //     if (this._unmounted) {
        //         this.setState({
        //             selectedMember: memberSelected,
        //             singleConverstion: singleConverstionObject,
        //             deleteMember: [...this.state.deleteMember, info],
        //             selectedMembersID: memberSelected.length > 0 ? memberSelected.map(info => info.id) : []
        //         })
        //     }
        // }
    }

    addMember = async () => {
        const { singleConverstion } = this.state;
        if (!this.state.itsDeteled) {
            if (singleConverstion) {
                if (this.state.selectedMember.length > 0) {
                    if (singleConverstion.type !== 'SINGLE') {
                        const singleConverstion = this.state.singleConverstion;
                        // console.log(singleConverstion);
                        const selectedMember = this.state.selectedMember;
                        let newIds = []
                        if (singleConverstion) {
                            if (Array.isArray(singleConverstion.conversationMember) && singleConverstion.conversationMember.length > 0) {

                                const conversationMembers = singleConverstion.conversationMember.filter(item => item.id !== this.state.user.id);
                                if (Array.isArray(conversationMembers) && conversationMembers.length > 0) {
                                    newIds = selectedMember.filter(a => !conversationMembers.find(b => b.id === a.id))
                                }
                            }
                        }
                        // console.log(newIds);
                        if (newIds.length > 0) {
                            if (this._unmounted) {
                                this.setState({
                                    isAddMemberLoader: true
                                })
                            }
                            const data = {
                                profileId: this.state.user?.id,
                                conversationId: this.state.activeChannelID,
                                profileIdForAdd: newIds.map(m => m.id).join(',')
                            }
                            this.socket.emit('conversationAddMember', data);
                            // const res = await memberForAdd(data);
                            // // console.log(res);
                            // if (res.status) {
                            //     if (this._unmounted) {
                            //         this.setState({
                            //             isSelected: false,
                            //             isAdded: true
                            //         })
                            //     }
                            //     // notifySuccess(res.message);
                            //     this.closeModalAdd();
                            // } else {
                            //     notifyError(res.message)
                            // }
                        } else {
                            this.closeModalAdd();
                        }

                    } else {
                        if (Array.isArray(this.state.selectedMember) && this.state.selectedMember.length > 0) {
                            const ids = this.state.selectedMember.map(info => info.id)
                            if (this._unmounted) {
                                this.setState({
                                    isAddMemberLoader: true
                                })
                            }
                            const data = {
                                profileId: this.state.user ? this.state.user.id : '',
                                otherprofileIds: ids.join(',')
                            }
                            // console.log(data);
                            this.socket.emit('createGroupConversation', data);
                            // const res = await createGroupForMembers(ids);
                            // if (res.status) {
                            //     this.setState({
                            //         channelList: [
                            //             ...this.state.channelList,
                            //             res.data
                            //         ],
                            //         isSelected: false,
                            //         isAdded: true
                            //     })
                            //     // notifySuccess(res.message);
                            //     this.closeModalAdd();
                            // } else {
                            //     notifyError(res.message)
                            // }
                            // console.log(res);
                        }
                    }
                } else {
                    notifyError('Please select at least one Member')
                }
            }
        } else {
            this.closeModalAdd();
            if (this._unmounted) {
                this.setState({
                    itsDeteled: false,
                    isSelected: false,
                    isAdded: false
                })
            }
        }
    }

    deleteMessage = async (info) => {
        // console.log(info);
        const data = {
            conversationID: info.conversation_id,
            messageID: info.id,
            senderID: info.sender_id
        }
        this.socket.emit("deleteMessageEmit", data);
        // const res = await DeleteSingleMessage(data)
        // if(res.status){
        //     notifySuccess(res.message);
        //     this.getMessages(this.state.activeChannelID);
        // }
    }

    titleModalToggle = (bool, name) => {
        if (this._unmounted) {
            this.setState({
                titleGroupModal: bool,
                groupTitle: name
            })
        }
    }

    titleGroupHandler = (e) => {
        const { value } = e.target;
        if (this._unmounted) {
            this.setState({
                groupTitle: value
            })
        }
    }

    saveGroupTitle = (e) => {
        e.preventDefault();
        const { groupTitle, user } = this.state
        const data = {
            conversationID: this.state.activeChannelID,
            conversationName: groupTitle,
            profile_id: user.id
        }
        if (groupTitle !== '') {
            this.socket.emit('updateConversationNameEmit', data)
            this.titleModalToggle(false);
        } else {
            notifyError('Name is required!');
        }
    }

    render() {

        // console.log(this.state.selectedMember);
        // selectedChannelData
        const { channelList, user, isLoader, isMessageLoader, searchMember, memberSearchArray } = this.state;
        let membersName = '';
        let isSingle = false;
        if (Array.isArray(channelList) && channelList.length > 0) {
            const findArray = channelList.find(info => info.id === parseInt(localStorage.getItem('chatID')));
            if (findArray) {
                const members = findArray.chat_member_names;
                // console.log(members);
                membersName = Array.isArray(members) && members.length > 0 ? members.filter(info => info.id !== user.id) : '';
                if (findArray.type !== 'SINGLE') {
                    // console.log(selectedChannelData);
                    isSingle = false;
                    if (findArray.name !== null && findArray.name !== '') {
                        membersName = findArray.name
                    } else {
                        membersName = membersName.map((info) => info.name).join(' | ')
                    }
                } else {
                    isSingle = true;
                    membersName = membersName.map((info) => info.name).join(' | ')
                }
            }
        }
        // if (selectedChannelData && localStorage.getItem('chatID') && selectedChannelData.id === parseInt(localStorage.getItem('chatID'))) {
        //     if (selectedChannelData && selectedChannelData.chat_member_names) {
        //         const members = selectedChannelData.chat_member_names;
        //         // console.log(members);
        //         membersName = Array.isArray(members) && members.length > 0 ? members.filter(info => info.id !== user.id) : '';
        //         if (selectedChannelData.type !== 'SINGLE') {
        //             // console.log(selectedChannelData);
        //             isSingle = false;
        //             if (selectedChannelData.name !== null && selectedChannelData.name !== '') {
        //                 membersName = selectedChannelData.name
        //             } else {
        //                 membersName = membersName.map((info) => info.name).join(' | ')
        //             }
        //         } else {
        //             isSingle = true;
        //             membersName = membersName.map((info) => info.name).join(' | ')
        //         }
        //     }
        // }
        // console.log(channelList);
        return (
            <>
                <section className={`${'message_section'}`}>
                    {isLoader ?
                        <div className="no-chat-wrap">
                            <div className="text-chatmessage">
                                <MainLoaderComponent color="black" />
                            </div>
                        </div>
                        :
                        channelList.length > 0 ?
                            <div className={'messaging'}>
                                <div className={'inbox_msg'}>
                                    <ChannelList isAdded={this.state.isAdded} itsDeteled={this.state.itsDeteled} channelList={channelList} activeChannelID={this.state.activeChannelID} onselectchannel={this.handleChannelSelect} />
                                    <div className={'mesgs'}>
                                        <div className="message-header">

                                            <div className="title-header-wrap">
                                                {this.state.titleGroupModal ?
                                                    <div className="input-form">
                                                        <input type="text" className="form-control" name="groupName" value={this.state.groupTitle} onChange={this.titleGroupHandler} placeholder="Enter Group Name" />
                                                    </div>
                                                    :
                                                    (user && user.profileType && parseInt(user.profileType) === 2) ?
                                                        <h2 style={{ cursor: 'pointer' }}>{membersName}</h2>
                                                        :
                                                        <h2>{membersName}</h2>
                                                }
                                            </div>
                                            {user && user.profileType && parseInt(user.profileType) === 2 ?
                                                this.state.titleGroupModal ?
                                                    <div className="group-btns">
                                                        <button type="button" className="btn-save" onClick={this.saveGroupTitle}>Save</button>
                                                        <button type="button" className="btn-cancel" onClick={() => this.titleModalToggle(true, '')}>Cancel</button>
                                                    </div>
                                                    :
                                                    <div className="group-btns">
                                                        {!isSingle ?
                                                            <button type="button" onClick={() => this.titleModalToggle(true, membersName)}><FiEdit2 /></button>
                                                            :
                                                            null
                                                        }
                                                        < button type="button" onClick={this.openModalAdd}><IoPersonAddOutline /></button>
                                                    </div>
                                                :
                                                null
                                            }
                                        </div>
                                        <MessageBox isMessageLoader={isMessageLoader} hasMore={this.state.hasMore} onFetch={this.onscrollMessage} deleteMessage={this.deleteMessage} deleteFile={this.deleteFile} filePath={this.state.filePath} imagePath={this.state.profilePath} typingID={this.state.typingID} messages={this.state.messageList} user={this.state.user} isTyping={this.state.isTyping} />
                                        <MessagePanel typingID={this.state.typingID} activedId={this.state.activeChannelID} user={this.state.user} onTyping={this.onTypingFuntion} onsendmessage={this.handleSendMessage} />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="container">
                                <div className="no-chat-wrap">
                                    <div className="text-chatmessage">
                                        <span className="icon"><IoChatbubbles /></span>
                                        <p>No Chat available</p>
                                    </div>
                                </div>
                            </div>
                    }
                </section>
                {/* {
                    this.state.titleGroupModal ?
                        <Modal
                            show={this.state.titleGroupModal}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className="addmember-modal"
                            onHide={() => this.titleModalToggle(false, '')}
                        >
                            {/* <Modal.Header>
                                <button type="button" onClick={() => this.titleModalToggle(false)} className="btn-modal-close"><MdClose /></button>
                                <Modal.Title id="contained-modal-title-vcenter" style={{ textAlign: "center", width: "calc(100% - 30px)" }}>
                                    Group Name
                                </Modal.Title>
                            </Modal.Header> 
                            <Modal.Body>
                                <div className="form_block">
                                    <div className="form-group">
                                        {/* <label>Name</label> 
                                        <input type="text" className="form-control" name="groupName" value={this.state.groupTitle} onChange={this.titleGroupHandler} placeholder="Enter Group Name" />
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between mt-5">
                                        <button type="button" className="btn btn-outline-dark border-0" onClick={() => this.titleModalToggle(false, '')}>Close</button>
                                        <button type="button" className="btn btn-outline-dark border-0" onClick={this.saveGroupTitle}>Save</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        :
                        null
                } */}
                {
                    this.state.isModalAdd ?
                        <Modal
                            show={this.state.isModalAdd}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className="addmember-modal"
                            onHide={this.closeModalAdd}
                        >
                            {this.state.isAddMemberLoader ? <div className="overlay_loader"><MainLoaderComponent /></div> : null}
                            <Modal.Header>
                                <button type="button" onClick={this.closeModalAdd} className="btn-modal-close"><MdClose /></button>
                                <Modal.Title id="contained-modal-title-vcenter" style={{ textAlign: "center", width: "calc(100% - 30px)" }}>
                                    Add to Group
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="block-members">
                                    <div className="search-wrp">
                                        <input type="text" name="search" placeholder="Search Member" value={searchMember} onChange={this.inputSearchMember} />
                                        <i className="icon"><MdSearch /></i>
                                    </div>
                                </div>
                                {Array.isArray(this.state.selectedMember) && this.state.selectedMember.length > 0 ?
                                    <ul className="selected-member">
                                        {this.state.selectedMember.map((info, i) =>
                                            <li key={i}>
                                                <div className="member-select">
                                                    <div className="image-with-button">
                                                        <div className="image-wraps">
                                                            {this.state.profileURL && info.profilePic ?
                                                                <img className="img-member" src={`${this.state.profileURL}${info.profilePic}`} alt="member" />
                                                                :
                                                                <span className="shape">{info.name.charAt(0).toUpperCase()}</span>
                                                            }
                                                        </div>
                                                        <button type="button" className="btn-delete-member" onClick={() => this.deleteSelectedMember(info)}><MdClose /></button>
                                                    </div>
                                                    <p>{info.name}</p>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                    :
                                    null
                                }
                                <ul className="list-member">
                                    {memberSearchArray.length > 0 ?
                                        memberSearchArray.map((info, i) =>
                                            <li className={this.state.selectedMembersID.includes(info.id) ? 'active' : ''} onClick={() => this.selectMemberHandler(info)} key={i}>
                                                <div className="member-wrap">
                                                    <div className="member-image">
                                                        {this.state.profileURL && info.profilePic ?
                                                            <img className="img-member" src={`${this.state.profileURL}${info.profilePic}`} alt="member" />
                                                            :
                                                            <span className="shape">{info.name.charAt(0).toUpperCase()}</span>
                                                        }
                                                    </div>
                                                    <div className="member-title">
                                                        <h3>{info.name}</h3>
                                                        <p>{info.bio}</p>
                                                    </div>
                                                </div>
                                                <div className="checkbox-shape">
                                                    <MdDone />
                                                </div>
                                            </li>
                                        )
                                        :
                                        <li>No Memeber found</li>
                                    }
                                </ul>
                            </Modal.Body>
                            <Modal.Footer className="d-flex align-items-center justify-content-between">
                                <button type="button" className="btn btn-outline-dark" onClick={this.closeModalAdd}>Close</button>
                                <button type="button" className="btn btn-dark" onClick={this.addMember} disabled={this.state.isAddMemberLoader}>Done</button>
                            </Modal.Footer>
                        </Modal>
                        :
                        null
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    urlPath: state.chat.urlPath
})

const mapDispatchToProps = (dispatch) => ({
    updateNotifyCount: (count) => dispatch({ type: ACTION_TYPES.HEADER_NOTIFICATION_COUNT, payload: count }),
    updateNotifyBool: (bool) => dispatch({ type: ACTION_TYPES.HEADER_NOTIFICATION_BOOL, payload: bool }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageComponent))