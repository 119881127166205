import * as ACTION_TYPES from '../../actions/admin/type';

const initialState = {
    isReloadGetApi:false
}

const categoriesAdminReducers = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.RELOAD_GET_CATEGORIES:
            return{
                ...state,
                isReloadGetApi:action.payload
            }
        default:
            return state;
    }
}

export default categoriesAdminReducers;