import React, { Component } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import ReactStars from "react-rating-stars-component";
// import InfiniteScroll from 'react-infinite-scroll-component';
import ReactPaginate from 'react-paginate';
import BGImage from '../../../assets/images/client-p-banner.png'
import DummyUser from '../../../assets/images/dummy-user.jpg'
import '../../../assets/css/profile.css';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { freelancerDetails } from '../../../controller/findfreelancer.controller';
import { createChat } from '../../../controller/chat.controller';
import moment from 'moment';
import { notifyError } from '../../../helper/Alert';
import { jwtDecode } from '../../../helper/jwt.helper';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import review from '../../../controller/review.controller';

class FreelancerProfileComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            hasMore: false,
            isSkillSMore: false,
            page: 0,
            details: null,
            profileUrl: '',
            portfolioUrl: '',
            loader: false,
            reviewList: []
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);
        this.getFreelancerDetails();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getFreelancerDetails = async () => {
        const { match: { params: { id } } } = this.props;
        if (this._unmounted) {
            this.setState({
                loader: true
            })
        }
        const res = await freelancerDetails(id);
        if (res.status) {
            // console.log(res);
            this.getFreelancerReview();
            if (this._unmounted) {
                this.setState({
                    details: res.data,
                    profileUrl: res.data.profileURL,
                    portfolioUrl: res.data.portfolioURL,
                    loader: false
                })
            }
        } else {
            // if (res.status === false) {
            return this.props.history.push("/client/find-freelancer")
            // }
        }
        // console.log(res);
    }

    getFreelancerReview = async () => {
        const { match: { params: { id } } } = this.props;
        const res = await review.getAllFreelancerReviewById(id);
        if (res.status) {
            this.setState({
                reviewList: res.data
            })
        } else {
            this.setState({
                reviewList: res.data
            })
        }

    }

    pageHandler = (pageNo) => {
        let pageNumber = 0;
        if (pageNo.selected) {
            pageNumber = pageNo.selected
        }

        if (this._unmounted) {
            this.setState({
                page: pageNumber
            })
        }
    }

    fetchMorePortfolio = () => {
        if (this._unmounted) {
            this.setState({
                hasMore: false
            })
        }
    }

    toggleSkillMore = () => {
        if (this._unmounted) {
            this.setState(prevState => ({
                isSkillSMore: !prevState.isSkillSMore
            }))
        }
    }

    imgProfileError = (e) => {
        e.target.src = DummyUser
    }

    redireactToPost = async (info) => {
        const res = await createChat(info.id);
        // console.log(res);
        if (res.status) {
            localStorage.setItem('chatID', res.data.id)
            this.props.history.push(`/message`)
            // props.history.push(`/message?chat=${id}`)
        } else {
            // localStorage.setItem('chatID', res.id)
            // props.history.push(`/message`)
            notifyError(res.message);
        }
        // this.props.history.push(`/client/post-job`)
        // this.props.history.push(`/client/post-job?freelancer=${data.id}`)
    }

    render() {

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 1) {
                return <Redirect to="/freelancer/jobs" />
            }
        }

        const { loader, page, details, profileUrl, portfolioUrl, reviewList } = this.state;
        // console.log(details);
        return (
            <>
                <section className={`profile_wrap12`}>
                    <div className={'profile_banner'}>
                        <img src={BGImage} alt="banner" />
                    </div>
                    <div className="container">
                        {loader ?
                            <MainLoaderComponent color="black" />
                            :
                            <div className="row">
                                <div className="col-sm-12 col-md-4">
                                    <div className={`card_wrap mt_150`}>
                                        <div className={'img_wrap2'}>
                                            {details && details.profilePic ?
                                                <img src={`${profileUrl}${details.profilePic}`} onError={this.imgProfileError} alt="freelancer" />
                                                :
                                                <img src={DummyUser} alt="freelancer" />
                                            }
                                            {/* <img src={DummyUser} alt="User" /> */}
                                        </div>
                                        <div className={'user_content'}>
                                            <h3>{details && details.name ? details.name : ''}</h3>
                                            <p className={'nick_nm'}>{details && details.email ? details.email : ''}</p>
                                            <div className={'rating_block'}>
                                                <p>0.0</p>
                                                {/* <StarRatings starRatedColor="#ffc12d" rating={3} numberOfStars={5} name={'userRating'} /> */}
                                                <div style={{ marginLeft: '10px' }} className={'rating_bl'}>
                                                    <ReactStars count={5} value={details && details.totalRate?details.totalRate:0} edit={false} size={24} activeColor="#ffc12d" />
                                                </div>
                                                <span className={'review_text'}>({details && details.totalReview?details.totalReview:0} reviews)</span>
                                            </div>
                                            {/* <p className={'location'}>
                                        <img src="https://s3.us-east-2.amazonaws.com/static.24task.com/images/flags/flags-medium/in.png" alt="India" />
                                        <span>Akasahebpet, Andhra Pradesh, India</span>
                                    </p> */}
                                            <ul className={'activeted_ids'}>
                                                <li className={details && details.email ? 'active' : ''}>
                                                    <div className={'icon'}>
                                                        <svg id="email" xmlns="http://www.w3.org/2000/svg" width="17.684" height="12" viewBox="0 0 17.684 12.6">
                                                            <path id="Path_49" data-name="Path 49" d="M165.313,48.866c0-.02.013-.04.013-.06l-5.4,5.2,5.39,5.034c0-.036-.006-.072-.006-.109Z" transform="translate(-147.642 -47.598)" fill="#fff"></path>
                                                            <path id="Path_50" data-name="Path 50" d="M21.628,126.9l-2.2,2.12a.577.577,0,0,1-.792.006l-2.2-2.047-5.425,5.229a1.254,1.254,0,0,0,.422.084H26.618a1.248,1.248,0,0,0,.606-.167Z" transform="translate(-10.164 -119.693)" fill="#fff"></path>
                                                            <path id="Path_51" data-name="Path 51" d="M14.514,41.2l8.236-7.934a1.248,1.248,0,0,0-.636-.185H6.927a1.252,1.252,0,0,0-.8.3Z" transform="translate(-5.659 -33.085)" fill="#fff"></path>
                                                            <path id="Path_52" data-name="Path 52" d="M0,52.1v9.871a1.254,1.254,0,0,0,.054.327l5.358-5.16Z" transform="translate(0 -50.639)" fill="#fff"></path>
                                                        </svg>
                                                    </div>
                                                    <span>Email</span>
                                                </li>
                                                <li className={details && details.mobile ? 'active' : ''}>
                                                    <div className={'icon'}>
                                                        <svg id="phone-call" xmlns="http://www.w3.org/2000/svg" width="12.574" height="12.6" viewBox="0 0 12.574 12.6"><g id="Group_16" data-name="Group 16">
                                                            <path id="Path_61" data-name="Path 61" d="M12.783,9.247,11.025,7.489a1.17,1.17,0,0,0-1.947.44A1.2,1.2,0,0,1,7.7,8.682,5.422,5.422,0,0,1,4.431,5.416a1.138,1.138,0,0,1,.754-1.382,1.17,1.17,0,0,0,.44-1.947L3.866.33a1.254,1.254,0,0,0-1.7,0L.977,1.523C-.216,2.779,1.1,6.107,4.054,9.059s6.28,4.333,7.536,3.077l1.193-1.193A1.254,1.254,0,0,0,12.783,9.247Z" transform="translate(-0.539 0)" fill="#fff"></path> </g>
                                                        </svg>
                                                    </div>
                                                    <span>Mobile Number</span>
                                                </li>
                                            </ul>
                                            {/* <p className={'job_ratio'}><b>36.05%</b> Jobs Completed</p> */}
                                            <div className={'date_time'}>
                                                <p className={'time'}>It’s Currently {moment().format('hh:mm A')} Here</p>
                                                <p className={'date'}>Joined {details && details.createdAt ? moment(details.createdAt).format('MMM DD, YYYY') : null}</p>
                                                {details && details.email ? <p className={'email'}>{details.email}</p> : null}
                                                {/* <p className={'website'}>hdjdkkdkd</p> */}
                                                {details && details.mobile ? <p className={'mobile'}>{`${details.mobile}`}</p> : null}
                                                <p className={'address'}>{details && details.city ? `${details.city},` : null}{details && details.statesName ? ` ${details.statesName}, ` : null} {details && details.countryName ? ` ${details.countryName}` : null}</p>
                                            </div>
                                            <div className={'earning'}>
                                                <div>
                                                    <h5>$108.6K</h5>
                                                    <span>Total Earnings</span>
                                                </div>
                                                <div>
                                                    <h5>491</h5>
                                                    <span>Total Jobs</span>
                                                </div>
                                                {/* <div>
                                            <h5>0</h5>
                                            <span>Total Hours</span>
                                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-8">
                                    <div className={`right_sec mt_150`}>
                                        <div className={'partition'}>
                                            <div className={'cap_hire_div'}>
                                                <h1 className={'title_cap'}>{details && details.bio}</h1>
                                                <button type="button" className="btn btn-dark" style={{ minWidth: '120px' }} onClick={() => this.redireactToPost(details)}>Chat</button>
                                            </div>
                                            <div className={'description_ti'}>
                                                {details && details.overview}
                                            </div>
                                        </div>
                                        <div className={'partition'}>
                                            <h5>Main Experties</h5>
                                            <ul id="skillDiv" className={`rounded skill`}>
                                                {details && details.serviceID ?
                                                    <li>{details.serviceName}</li>
                                                    :
                                                    <li>no found</li>
                                                }
                                            </ul>
                                        </div>
                                        <div className={'partition'}>
                                            <h5>All Skills</h5>
                                            <ul className={`rounded skill more`}>
                                                {(details && details.skills) && details.skills.length > 0 ?
                                                    details.skills.map((info, i) => <li key={i}>{info.skillName}</li>)
                                                    :
                                                    <li>no found</li>
                                                }
                                            </ul>
                                            {/* <button type="button" aria-label={`View ${isSkillSMore ? 'less' : 'more'}`} className={`btn_viewMore skills_more`} onClick={this.toggleSkillMore}>View {isSkillSMore ? 'Less' : 'more'}</button> */}
                                        </div>
                                        <div className={'partition'}>
                                            <h5>Portfolio</h5>
                                            <ul className={'portfolio_listing'} id="scrollableDiv">
                                                {details && Array.isArray(details.portfolio) && details.portfolio.length > 0 ?
                                                    // <InfiniteScroll
                                                    //     dataLength={details && details.portfolio.length}
                                                    //     next={this.fetchMorePortfolio}
                                                    //     // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                                                    //     // inverse={true}
                                                    //     hasMore={hasMore}
                                                    //     loader={hasMore ? <h4 className={'loader_text'}>Loading...</h4> : null}
                                                    //     scrollableTarget="scrollableDiv"
                                                    // >
                                                    details.portfolio.map((info, i) =>
                                                        <li className={'box'} key={i}>
                                                            <div className={'effect'}>
                                                                {info.portfolio_Attechment && info.portfolio_Attechment.length > 0 ?
                                                                    info.portfolio_Attechment[0].fileName ?
                                                                        <img src={`${portfolioUrl}${info.portfolio_Attechment[0].fileName}`} alt="Portfolio" />
                                                                        :
                                                                        <img src="https://s3.us-east-2.amazonaws.com/cdn.24task.com/agentApi/webroot/portfolios_files/5f7ee59c063cf.jpg" alt="test" />
                                                                    :
                                                                    <img src="https://s3.us-east-2.amazonaws.com/cdn.24task.com/agentApi/webroot/portfolios_files/5f7ee59c063cf.jpg" alt="test" />
                                                                }
                                                                <div className={'contentPart'}>
                                                                    <span className={'title'}>{info.title}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )

                                                    // {/* </InfiniteScroll> */}
                                                    :
                                                    <li className={'noPortfolio'}>No Portfolio found</li>
                                                }
                                            </ul>
                                            {/* <button type="button" aria-label="View more" className={btn_viewMore}>View more</button> */}
                                        </div>

                                        {/* <div className={partition}>
                                <h5>Languages</h5>
                                <ul className={`${rounded} ${language}`}>
                                    <li>American English: <span>Fluent</span></li>
                                    <li>Hindi: <span>Native</span></li>
                                    <li>Gujarati: <span>Native</span></li>
                                </ul>
                            </div> */}
                                    </div>
                                    {reviewList && reviewList.length > 0 ?
                                        <div className={`right_sec mt_0 review_wrap`}>
                                            <h4>Reviews</h4>
                                            <ul className={'review_ul'}>
                                                {reviewList.map((info, i) =>
                                                    <li className={`review_div bl`} key={i}>
                                                        <div className={'caption_wrap'}>
                                                            <h5 className={'review_title'}>{info?.jobTitle}</h5>
                                                            <div className={`rating rvi mb-0`}>
                                                                <p className={'rating_badge mb-0'}>{info.rate}</p>
                                                                <ReactStars count={5} isHalf={true} value={info.rate} edit={false} size={20} activeColor="#ffc12d" />
                                                            </div>
                                                            <span className={'time w-25 text-end'}>{moment(info.createdAt).format('DD MMM, YYYY')}</span>
                                                        </div>
                                                        <div className={'detail mb-0'}>
                                                            {/* <div className={`rating rvi`}>
                                                                <p className={'rating_badge mb-0'}>4.8</p>
                                                                <ReactStars count={5} isHalf={true} value={info.rate} edit={false} size={18} activeColor="#ffc12d" />
                                                            </div> */}
                                                            <p>{info?.description}</p>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                            {/* <div className={'pagination_wrap'}>
                                            <ReactPaginate
                                                initialPage={page}
                                                // forcePage={this.state.page}
                                                pageCount={1}
                                                pageRangeDisplayed={4}
                                                breakLabel={'...'}
                                                onPageChange={this.pageHandler}
                                                activeClassName={'active'}
                                                previousLabel={<MdKeyboardArrowLeft />}
                                                previousClassName={'previous'}
                                                nextLabel={<MdKeyboardArrowRight />}
                                                nextClassName={'next'}
                                            />
                                        </div> */}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(connect(null)(FreelancerProfileComponent))
