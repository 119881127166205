import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { notifyError } from '../../helper/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { upadteExistEducation } from '../../controller/profile.controller';

class EditEducationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            school: '',
            degree: '',
            study: '',
            description: '',
            startYear: new Date(),
            endYear: new Date(),
            startYearError: '',
            endYearError: '',
            startYearValid: true,
            endYearValid: true,
            errors: {
                school: '',
                degree: '',
                study: '',
                description: '',
            },
            validation: {
                school: false,
                degree: false,
                study: false,
                description: false,
            },
            form: {
                school: '',
                degree: '',
                study: '',
                description: ''
            }
        }
    }

    componentDidMount(){
        this.updateData();
    }

    updateData = () => {
        const data = this.props.data;
        if (data) {
            this.setState({
                school: data.schoolName?data.schoolName:'',
                degree: data.degree?data.degree:'',
                study: data.aeraOfStudy?data.aeraOfStudy:'',
                description: data.description?data.description:'',
                startYear: new Date(`1-1-${data.schoolStartYear}`),
                endYear: new Date(`1-1-${data.schoolEndYear}`),
                startYearError: '',
                endYearError: '',
                startYearValid: true,
                endYearValid: true,
                errors: {
                    school: '',
                    degree: '',
                    study: '',
                    description: '',
                },
                validation: {
                    school: data.schoolName?true:false,
                    degree: data.degree?true:false,
                    study: data.aeraOfStudy?true:false,
                    description: data.description?true:false,
                },
            })
        }
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.validationForm(name, value))
    }

    validationForm = (name, value) => {
        let error = this.state.errors;
        let valid = this.state.validation;
        switch (name) {
            case 'school':
                valid.school = value.length > 0;
                error.school = valid.school ? '' : 'School name must be required!'
                break;
            default:
                break;
        }

        this.setState({
            errors: error,
            validation: valid
        })
    }

    startYearHandler = (date) => {
        // console.log(date);
        this.setState({
            startYear: date,
            startYearError: '',
            startYearValid: true
        })
    }

    endYearHandler = (date) => {
        // console.log(date);
        this.setState({
            endYear: date,
            endYearError: '',
            endYearValid: true
        })
    }

    formSubmit = async (e) => {
        e.preventDefault();
        const { school, degree, study, description, startYear, endYear, startYearValid, endYearValid, validation } = this.state;
        const diffDate = moment(endYear).diff(moment(startYear));

        if (validation.school === false) {
            return notifyError('School name must be required!')
        }

        if (startYearValid === false) {
            return notifyError('Dates Attended must be required!')
        }

        if (endYearValid === false) {
            return notifyError('Dates Attended must be required!')
        }

        if (diffDate <= 0) {
            return notifyError('Please enter a valid dates Attended')
        }

        const data = {
            id:this.props.data.id,
            schoolName: school,
            schoolStartYear: moment(startYear).format('yyyy'),
            schoolEndYear: moment(endYear).format('yyyy'),
            degree: degree,
            aeraOfStudy: study,
            description: description
        }

        const res = await upadteExistEducation(data);

        if (!res.status) {
            return notifyError(res.message);
        }

        this.props.onSubmit(true);

        this.setState({
            school: '',
            degree: '',
            study: '',
            description: '',
            startYear: new Date(),
            endYear: new Date(),
            startYearError: '',
            endYearError: '',
            startYearValid: true,
            endYearValid: true,
            errors: {
                school: '',
                degree: '',
                study: '',
                description: ''

            },
            validation: {
                school: false,
                degree: false,
                study: false,
                description: false
            }
        })
        this.props.onHide();
    }

    render() {

        const { school, degree, study, description, startYear, endYear, startYearError, endYearError, errors } = this.state;

        return (
            <Modal show={this.props.show} onHide={this.props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" className="ps-3 mb-0">
                        Update Education
                    </Modal.Title>
                </Modal.Header>
                <form action="/" method="POST" onSubmit={this.formSubmit}>
                    <Modal.Body>
                        <div className="p-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group mb-4">
                                        <label>School</label>
                                        <input type="text" name="school" className={errors.school !== '' ? "form-control error" : "form-control"} placeholder="School Name" onChange={this.inputHandler} value={school} />
                                        {errors.school !== '' ? <p className="error-text">{errors.school}</p> : null}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <label>Dates Attended</label>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12">
                                            <div className="form-group mb-4">
                                                <DatePicker
                                                    className={`form-control ${startYearError !== '' ? 'error' : ''}`}
                                                    popperClassName="calender-education"
                                                    selected={startYear}
                                                    onChange={this.startYearHandler}
                                                    value={startYear}
                                                    maxDate={new Date()}
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                    placeholderText="Start Education Year"
                                                />
                                                {startYearError !== '' ? <p className="error-text">{startYearError}</p> : null}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-12">
                                            <div className="form-group mb-4">
                                                <DatePicker
                                                    className={`form-control ${endYearError !== '' ? 'error' : ''}`}
                                                    popperClassName="calender-education"
                                                    selected={endYear}
                                                    maxDate={new Date()}
                                                    onChange={this.endYearHandler}
                                                    value={endYear}
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                    placeholderText="End Education Year"
                                                />
                                                {endYearError !== '' ? <p className="error-text">{endYearError}</p> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group mb-4">
                                        <label>Degree (Optional)</label>
                                        <input type="text" name="degree" className={errors.degree !== '' ? "form-control error" : "form-control"} placeholder="Bachelor of Arts(B.A)" onChange={this.inputHandler} value={degree} />
                                        {errors.degree !== '' ? <p className="error-text">{errors.degree}</p> : null}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group mb-4">
                                        <label>Area of Study (Optional)</label>
                                        <input type="text" name="study" className={errors.study !== '' ? "form-control error" : "form-control"} placeholder="Animation" onChange={this.inputHandler} value={study} />
                                        {errors.study !== '' ? <p className="error-text">{errors.study}</p> : null}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group mb-4">
                                        <label>Description (Optional)</label>
                                        <textarea rows="5" name="description" className={errors.description !== '' ? "form-control error" : "form-control"} onChange={this.inputHandler} value={description}></textarea>
                                        {errors.description !== '' ? <p className="error-text">{errors.description}</p> : null}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-dark" onClick={this.props.onHide}>Close</button>
                        <button type="submit" className="btn btn-success">Update</button>
                    </Modal.Footer>
                </form>

            </Modal>
        )
    }
}

export default withRouter(connect(null)(EditEducationModal));
