import { apiHelper } from "../helper/api.helper";
import { jwtDecode } from "../helper/jwt.helper";

export const getCountryList = async () => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await apiHelper('country/get', 'GET', null, header);
    const res = response.data;

    if (!res.status) {
        return []
    }

    return jwtDecode(res.data);
}

export const getStateList = async (countryId) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await apiHelper(`states/get/${countryId}`, 'GET', null, header);
    const res = response.data;

    if (!res.status) {
        return []
    }

    return jwtDecode(res.data);
}