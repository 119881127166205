import React, { Component } from 'react';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import { MdDone } from 'react-icons/md';
// import { useSelector } from 'react-redux';
// import 'rc-steps/assets/iconfont';
import { connect } from 'react-redux';
import TitleComponent from './TitleComponent';
import CategoryComponent from './CategoryComponent';
import SkillsComponent from './SkillsComponent';
import BudegtComponent from './BudegtComponent';
// import DeadlineComponent from './DeadlineComponent';
import DescriptionComponent from './DescriptionComponent';
import ReviewComponent from './ReviewComponent';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { mainCategories, subCategories } from '../../../controller/categories.controller';
import { getJobDetail } from '../../../controller/jobs.controller';
import BGImage from '../../../assets/images/job-post-background.png'
import '../../../assets/css/postJob.css';
import { withRouter, Redirect } from 'react-router-dom'
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import { jwtDecode } from '../../../helper/jwt.helper';

class EditJobComponent extends Component {

    // const router = useHistory();

    // const activeStep = useSelector((state)=>state.editJob.currentStep)
    constructor(props) {
        super(props);
        this.state = {
            loader: false
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params
        this.detailsEditPost(id);
    }

    detailsEditPost = async (id) => {

        this.setState({
            loader: true
        })

        const res = await getJobDetail(id);

        if (res) {
            const cateIDs = res.services.map((info) => info.id)
            const categoryList = await mainCategories();
            const skillsList = await subCategories(cateIDs)
            if (categoryList.length > 0 && skillsList.length > 0) {

                const { categoriesArray, skillsArray, titleFunc, jobIDFun, categoryFun, skillsFun, budgetFun, descriptionFun, filesFun, stepFun } = this.props;

                categoriesArray(categoryList)
                skillsArray(skillsList);
                titleFunc(res.title);
                jobIDFun(id);
                categoryFun(res.services);
                skillsFun(res.skills);
                budgetFun(res.budget);
                descriptionFun(res.description);
                filesFun(res.attachments);
                stepFun(5);

                this.setState({
                    loader: false
                })


            }
        } else {
            this.setState({
                loader: false
            })
            const path = this.props.location.pathname.split('/')
            if (Array.isArray(path) && path.length === 4) {
                if (path[2] === 'my-jobs') {

                    return this.props.history.push(`/client/my-jobs/${id}`);
                }

                if (path[2] === 'all-jobs') {

                    return this.props.history.push(`/client/all-jobs/${id}`);
                }
            }
        }
    }


    render() {

        // console.log(this.props.stepCurrent);

        const { loader } = this.state;

        const token = localStorage.getItem('token');
        if(token){
            const decode = jwtDecode(token);
            if(decode.profileType === 1){
                return <Redirect to="/freelancer/jobs" />
            }
        }

        return (
            <>
                <section className={`post_job_wrap`}>
                    <div className={'profile_banner'}>
                        <img src={BGImage} alt="banner" />
                    </div>
                    <div className="container">
                        <div className="row">
                            {loader?
                                <MainLoaderComponent color="black" />
                            :
                            <div className="col-sm-12">
                                <div className={`card_wrap p_30 mt_65`}>
                                    <div className={'steps_wrap'}>
                                        {/* <Steps current={props.stepCurrent} labelPlacement="vertical" icons={{ finish: <MdDone /> }} > */}
                                        <Steps current={this.props.stepCurrent} labelPlacement="vertical" icons={{ finish: <MdDone /> }} >
                                            <Step title={'Title'} />
                                            <Step title={'Category'} />
                                            <Step title={'Skills'} />
                                            <Step title={'Budget'} />
                                            {/* <Step title={'Budget & Deadline'} /> */}
                                            {/* <Step title={'Deadline'} /> */}
                                            <Step title={'Description'} />
                                            <Step title={'Review'} />
                                        </Steps>
                                        {/* <p>active Step: {this.props.stepCurrent}</p> */}

                                        {/* {this.state.currentStep > 0?<button type="button" onClick={this.prevSteps}>Prev</button>:null}
                                    {this.state.currentStep < 6?<button type="button" onClick={this.nextSteps}>Next</button>:null} */}
                                    </div>
                                </div>
                                {this.props.stepCurrent === 0 ? <TitleComponent /> : null}
                                {this.props.stepCurrent === 1 ? <CategoryComponent /> : null}
                                {this.props.stepCurrent === 2 ? <SkillsComponent /> : null}
                                {this.props.stepCurrent === 3 ? <BudegtComponent /> : null}
                                {/* {this.props.stepCurrent === 4 ? <DeadlineComponent /> : null} */}
                                {this.props.stepCurrent === 4 ? <DescriptionComponent /> : null}
                                {this.props.stepCurrent === 5 ? <ReviewComponent /> : null}
                            </div>
                            }
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => ({
    stepCurrent: state.editJob.currentStep
});

const mapDispatchToProps = dispatch => ({
    stepFun: (step) => dispatch({ type: ACTION_TYPES.STEP_EDIT_JOBS, payload: step }),
    jobIDFun: (id) => dispatch({ type: ACTION_TYPES.EDIT_JOB_ID, payload: id }),
    titleFunc: (val) => dispatch({ type: ACTION_TYPES.TITLE_EDIT_JOB, payload: val }),
    categoriesArray: (array) => dispatch({ type: ACTION_TYPES.CATEGORYS_EDIT_ARRAY, payload: array }),
    categoryFun: (array) => dispatch({ type: ACTION_TYPES.CATEGORYS_EDIT_JOB, payload: array }),
    skillsArray: (array) => dispatch({ type: ACTION_TYPES.SKILLS_EDIT_ARRAY, payload: array }),
    skillsFun: (array) => dispatch({ type: ACTION_TYPES.SKILLS_EDIT_JOB, payload: array }),
    budgetFun: (val) => dispatch({ type: ACTION_TYPES.BUDGET_EDIT_JOB, payload: val }),
    descriptionFun: (val) => dispatch({ type: ACTION_TYPES.DESCRIPTION_EDIT_JOB, payload: val }),
    filesFun: (files) => dispatch({ type: ACTION_TYPES.FILES_EDIT_JOB, payload: files }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditJobComponent));
