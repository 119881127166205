import { apiHelper } from "../../helper/api.helper";

export const adminLogin = async (data) => {

    const { email, password } = data;

    let formData = new FormData();
    formData.append('username', email)
    formData.append('password', password)

    const response = await apiHelper('admin/auth/login', 'POST', formData, null);
    return response.data;
}

export const adminChangePassword = async (data) => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const { currentPassword, newPassword, confirmPassword } = data;

    let formData = new FormData();
    formData.append('currentPassword', currentPassword)
    formData.append('newPassword', newPassword)
    formData.append('confirmPassword', confirmPassword)

    const response = await apiHelper('admin/auth/changepassword', 'POST', formData, header);
    return response.data;
}

export const updateProfile = async (data) => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const { fname, lname } = data;

    let formData = new FormData();
    formData.append('firstName', fname)
    formData.append('lastName', lname)

    const response = await apiHelper('admin/profile/update', 'PUT', formData, header);
    return response.data;
}