import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
// import Layout from '../../components/Layout';

class Dashboard extends Component {

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    render() {

        return (
            <div className="container-fluid p-0">
                {/* <h1 className="h3 mb-3"><strong>Dashboard</strong> </h1> */}

                
            </div>
        )
    }
}
export default withRouter(connect(null)(Dashboard))