import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { withRouter } from 'react-router-dom'
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import { updateJob } from '../../../controller/jobs.controller';
import { notifyError, notifySuccess } from '../../../helper/Alert';
// import { DateTime } from 'react-datetime-bootstrap';

class ReviewComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader:false,
            budget: '',
            error: '',
            valid: false
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
    }

    goSteps = (step) => {
        // console.log(step);
        if (step !== undefined) {
            this.props.stepFun(step);
            this.props.backReviewFun(true);
        }
    }

    prevStepFun = () => {
        const step = this.props.currentStep - 1;
        this.props.stepFun(step);
    }

    submitJob = async () =>{
        const { jobIDFun, titleFunc, categoryFun, skillsFun, budgetFun, descriptionFun, filesFun, stepFun } = this.props;
        const { jobID, jobTitle, selectedCategory, selectedSkills, budgetVal, description, uploadFiles } = this.props;

        const data = {
            jobPostID:jobID,
            title: jobTitle,
            description: description,
            budget: budgetVal,
            categoryIDs: selectedCategory.map(info => info.id),
            skillIDs: selectedSkills.map(info => info.id),
            file: uploadFiles,
            to_delete:this.props.imageDeleteID
        }

        this.setState({
            loader: true
        })

        const res = await updateJob(data);
        if (res.status) {
            this.setState({
                loader: false
            })
            // localStorage.removeItem('files');
            this.props.history.push(`/client/my-jobs/${jobID}`);
            jobIDFun(null);
            titleFunc('');
            categoryFun([]);
            skillsFun([]);
            budgetFun('');
            descriptionFun('');
            filesFun([]);
            stepFun(0);
            notifySuccess(res.message);

        }else{
            this.setState({
                loader: false
            })
            notifyError(res.message);
        }

        
        // titleFunc('');
        // categoryFun([]);
        // skillsFun([]);
        // budgetFun('');
        // descriptionFun('');
        // filesFun([]);
        // router.push('/client/jobs');
    }

    render() {

        // const { error } = this.state;

        return (
            <div className={'content_steps_wrap'}>
                <div className={'step_body'}>
                    <div className={'header_step'}>
                        <h2 className={'Header_Step_title'}>Review and Post</h2>
                        <button type="button" aria-label="Post Job" className="btn btn-primary min-200" onClick={this.submitJob} disabled={this.state.loader}>{this.state.loader?<SmallLoaderComponent />:'Update Job'}</button>
                    </div>
                    <div className={'wrap_steps'}>
                        <div className={'wrap_review'}>
                            <h5>Job Title</h5>
                            <p className={'steps_answers'}>{this.props.jobTitle}</p>
                            <button type="button" className={'btn_Edits_step'} onClick={() => this.goSteps(0)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g transform="translate(-0.001)"><g transform="translate(4.429 4.608)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(12.373 0)"><path className="edit-btn" d="M356.633.9a3.084,3.084,0,0,0-4.362,0l-.363.363,4.362,4.362.363-.363a3.084,3.084,0,0,0,0-4.362Z" transform="translate(-351.908 0)"></path></g><g transform="translate(1.861 2.041)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(0.001 12.716)"><path className="edit-btn" d="M1.251,361.685.02,366.28a.547.547,0,0,0,.67.67l4.6-1.231Z" transform="translate(-0.001 -361.685)"></path></g></g></svg>
                            </button>
                        </div>
                        <div className={'wrap_review'}>
                            <h5>Category</h5>
                            <p className={'steps_answers'}>
                                {this.props.selectedCategory.length > 0 ?
                                    this.props.selectedCategory.map((info, i) => <span key={i} className={'tag_label'}>{info.categoryName}</span>)
                                    :
                                    null
                                }
                            </p>
                            <button type="button" className={'btn_Edits_step'} onClick={() => this.goSteps(1)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g transform="translate(-0.001)"><g transform="translate(4.429 4.608)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(12.373 0)"><path className="edit-btn" d="M356.633.9a3.084,3.084,0,0,0-4.362,0l-.363.363,4.362,4.362.363-.363a3.084,3.084,0,0,0,0-4.362Z" transform="translate(-351.908 0)"></path></g><g transform="translate(1.861 2.041)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(0.001 12.716)"><path className="edit-btn" d="M1.251,361.685.02,366.28a.547.547,0,0,0,.67.67l4.6-1.231Z" transform="translate(-0.001 -361.685)"></path></g></g></svg>
                            </button>
                        </div>
                        <div className={'wrap_review'}>
                            <h5>Skills</h5>
                            <p className={'steps_answers'}>
                                {this.props.selectedSkills.length > 0 ?
                                    this.props.selectedSkills.map((info, i) => <span key={i} className={'tag_label'}>{info.categoryName}</span>)
                                    :
                                    null
                                }
                            </p>
                            <button type="button" className={'btn_Edits_step'} onClick={() => this.goSteps(2)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g transform="translate(-0.001)"><g transform="translate(4.429 4.608)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(12.373 0)"><path className="edit-btn" d="M356.633.9a3.084,3.084,0,0,0-4.362,0l-.363.363,4.362,4.362.363-.363a3.084,3.084,0,0,0,0-4.362Z" transform="translate(-351.908 0)"></path></g><g transform="translate(1.861 2.041)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(0.001 12.716)"><path className="edit-btn" d="M1.251,361.685.02,366.28a.547.547,0,0,0,.67.67l4.6-1.231Z" transform="translate(-0.001 -361.685)"></path></g></g></svg>
                            </button>
                        </div>
                        <div className={'wrap_review'}>
                            <h5>Budget</h5>
                            <p className={'steps_answers'}>${this.props.budgetVal}</p>
                            <button type="button" className={'btn_Edits_step'} onClick={() => this.goSteps(3)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g transform="translate(-0.001)"><g transform="translate(4.429 4.608)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(12.373 0)"><path className="edit-btn" d="M356.633.9a3.084,3.084,0,0,0-4.362,0l-.363.363,4.362,4.362.363-.363a3.084,3.084,0,0,0,0-4.362Z" transform="translate(-351.908 0)"></path></g><g transform="translate(1.861 2.041)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(0.001 12.716)"><path className="edit-btn" d="M1.251,361.685.02,366.28a.547.547,0,0,0,.67.67l4.6-1.231Z" transform="translate(-0.001 -361.685)"></path></g></g></svg>
                            </button>
                        </div>
                        {/* <div className={wrap_review}>
                            <h5>Deadline</h5>
                            <p className={steps_answers}>{this.props.deadlineVal}</p>
                            <button type="button" className={btn_Edits_step} onClick={() => this.goSteps(3)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g transform="translate(-0.001)"><g transform="translate(4.429 4.608)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(12.373 0)"><path className="edit-btn" d="M356.633.9a3.084,3.084,0,0,0-4.362,0l-.363.363,4.362,4.362.363-.363a3.084,3.084,0,0,0,0-4.362Z" transform="translate(-351.908 0)"></path></g><g transform="translate(1.861 2.041)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(0.001 12.716)"><path className="edit-btn" d="M1.251,361.685.02,366.28a.547.547,0,0,0,.67.67l4.6-1.231Z" transform="translate(-0.001 -361.685)"></path></g></g></svg>
                            </button>
                        </div> */}
                        <div className={'wrap_review'}>
                            <h5>Description</h5>
                            <p className={'steps_answers'}>{this.props.description}</p>
                            <button type="button" className={'btn_Edits_step'} onClick={() => this.goSteps(4)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g transform="translate(-0.001)"><g transform="translate(4.429 4.608)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(12.373 0)"><path className="edit-btn" d="M356.633.9a3.084,3.084,0,0,0-4.362,0l-.363.363,4.362,4.362.363-.363a3.084,3.084,0,0,0,0-4.362Z" transform="translate(-351.908 0)"></path></g><g transform="translate(1.861 2.041)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(0.001 12.716)"><path className="edit-btn" d="M1.251,361.685.02,366.28a.547.547,0,0,0,.67.67l4.6-1.231Z" transform="translate(-0.001 -361.685)"></path></g></g></svg>
                            </button>
                        </div>
                        {/* <div className={wrap_review}>
                            <h5>Files</h5>
                            <p className={steps_answers}>{this.props.description}</p>
                            <button type="button" className={btn_Edits_step} onClick={() => this.goSteps(4)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g transform="translate(-0.001)"><g transform="translate(4.429 4.608)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(12.373 0)"><path className="edit-btn" d="M356.633.9a3.084,3.084,0,0,0-4.362,0l-.363.363,4.362,4.362.363-.363a3.084,3.084,0,0,0,0-4.362Z" transform="translate(-351.908 0)"></path></g><g transform="translate(1.861 2.041)"><g transform="translate(0 0)"><rect className="edit-btn" width="13.77" height="2.537" transform="translate(0 9.737) rotate(-45)"></rect></g></g><g transform="translate(0.001 12.716)"><path className="edit-btn" d="M1.251,361.685.02,366.28a.547.547,0,0,0,.67.67l4.6-1.231Z" transform="translate(-0.001 -361.685)"></path></g></g></svg>
                            </button>
                        </div> */}
                    </div>
                </div>
                <div className={'step_footer'}>
                    <button type="button" className="btn btn-primary min-200" onClick={this.submitJob} disabled={this.state.loader}>{this.state.loader?<SmallLoaderComponent />:'Update Job'}</button>
                    <button type="button" className="btn btn-outline-dark min-200" onClick={this.prevStepFun}>Prev</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentStep: state.editJob.currentStep,
    jobTitle: state.editJob.jobTitle,
    categoryArray: state.editJob.categoryArray,
    selectedCategory: state.editJob.categoryVal,
    skillsArray: state.editJob.skillsArray,
    selectedSkills: state.editJob.skillsVal,
    budgetVal: state.editJob.budgetVal,
    deadlineVal: state.editJob.deadlineVal,
    description: state.editJob.description,
    uploadFiles: state.editJob.uploadFiles,
    jobID:state.editJob.jobID,
    imageDeleteID:state.editJob.imageDeleteID
})

const mapDispatchToProps = dispatch => ({
    stepFun: (step) => dispatch({ type: ACTION_TYPES.STEP_EDIT_JOBS, payload: step }),
    jobIDFun: (id) => dispatch({ type: ACTION_TYPES.EDIT_JOB_ID, payload: id }),
    backReviewFun: (step) => dispatch({ type: ACTION_TYPES.BACK_REVIEW_EDITJOB_STEPS, payload: step }),
    titleFunc: (val) => dispatch({ type: ACTION_TYPES.TITLE_EDIT_JOB, payload: val }),
    categoryFun: (array) => dispatch({ type: ACTION_TYPES.CATEGORYS_EDIT_JOB, payload: array }),
    skillsFun: (array) => dispatch({ type: ACTION_TYPES.SKILLS_EDIT_JOB, payload: array }),
    budgetFun: (val) => dispatch({ type: ACTION_TYPES.BUDGET_EDIT_JOB, payload: val }),
    descriptionFun: (val) => dispatch({ type: ACTION_TYPES.DESCRIPTION_EDIT_JOB, payload: val }),
    filesFun: (files) => dispatch({ type: ACTION_TYPES.FILES_EDIT_JOB, payload: files }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewComponent));