import React, { useState, useEffect } from 'react'
// import Head from 'next/head';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SmallLoaderComponent from '../../helper/SmallLoaderComponent';
import { changePassword } from '../../controller/auth.controller';
import { notifyError, notifySuccess } from '../../helper/Alert';
import { jwtDecode } from '../../helper/jwt.helper';

function ChangeFreelancerPassword() {

    const router = useHistory();

    const [loader, setLoader] = useState(false)
    const [passForm, setPassForm] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    useEffect(()=>{
        if (!localStorage.getItem('token')) {
            return router.push('/login')
        }

        if (localStorage.getItem('token')) {
            const decode = jwtDecode(localStorage.getItem('token'))
            if (decode && Number(decode.profileType) === 2) {
                return router.push('/client/jobs');
            }
        }
    })

    const updatePasswordSchema = Yup.object().shape({
        currentPassword: Yup.string().required('Current Password must be required'),
        newPassword: Yup.string().required('New Password must be required'),
        confirmPassword: Yup.string().oneOf([Yup.ref("newPassword")], "Both password need to be the same").required('Confirm Password must be required')
    });

    const formSubmit = async (e, actions) => {

        setLoader(true);

        const res = await changePassword(e);
        if (res.status) {

            setLoader(true);
            actions.resetForm({
                e: {
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                }
            })
            setPassForm({
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            })
            setLoader(false);

            notifySuccess(res.message)
        } else {
            setLoader(false);
            notifyError(res.message);
        }
    }

    return (
        <>
            {/* <Head>
                <title>Change Password | Vedain</title>
            </Head> */}
            <section className={`profile_wrap pt-5 pb-5`}>
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-sm-6 col-md-6 mt-5">
                            <div className="card border-0 p-4">
                                <h4>Change Password</h4>
                                <Formik initialValues={passForm} validationSchema={updatePasswordSchema} onSubmit={(values, actions) => formSubmit(values, actions)}>
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (

                                        <form action="/" className="mt-4" method="POST" onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group mb-4">
                                                        <input type="password" name="currentPassword" className={errors.currentPassword && touched.currentPassword ? "form-control error" : "form-control"} placeholder="Current Password" onChange={handleChange} onBlur={handleBlur} value={values.currentPassword} />
                                                        {errors.currentPassword && touched.currentPassword ? <p className="error-text">{errors.currentPassword}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-group mb-4">
                                                        <input type="password" name="newPassword" className={errors.newPassword && touched.newPassword ? "form-control error" : "form-control"} placeholder="New Password" onChange={handleChange} onBlur={handleBlur} value={values.newPassword} />
                                                        {errors.newPassword && touched.newPassword ? <p className="error-text">{errors.newPassword}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-group mb-4">
                                                        <input type="password" name="confirmPassword" className={errors.confirmPassword && touched.confirmPassword ? "form-control error" : "form-control"} placeholder="Confirm Password" onChange={handleChange} onBlur={handleBlur} value={values.confirmPassword} />
                                                        {errors.confirmPassword && touched.confirmPassword ? <p className="error-text">{errors.confirmPassword}</p> : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" aria-label="Save" className="btn btn-primary min-200 max-200 display-block ml-auto" disabled={loader}>{loader ? <SmallLoaderComponent /> : 'Save'}</button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ChangeFreelancerPassword;
