import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import ReactStars from "react-rating-stars-component";
import { MdClose, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdLocationOn } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import Modal from 'react-bootstrap/Modal';
import '../../../assets/css/jobDetails.css';
import { getJobPostBids } from '../../../controller/jobs.controller';
import DummyUser from '../../../assets/images/dummy-user.jpg'
import { connect } from 'react-redux';
// import { createChat } from '../../../controller/chat.controller';
import { CHAT_URL } from '../../../controller/chat.controller';
import { notifyError } from '../../../helper/Alert';
import { io } from "socket.io-client";
import { jwtDecode } from '../../../helper/jwt.helper';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';

const SERVER = CHAT_URL;

function ProposalsSection(props) {

    // const router = useHistory()

    const [page, setPage] = useState(0);
    const [jobID, setJobID] = useState(null);
    const [loader, setLoader] = useState(false);
    const [modalHire, setModalHire] = useState(false);
    const [priceVal, setPriceVal] = useState('');
    const [priceError, setPriceError] = useState('');
    const [freelancerList, setfreelancerList] = useState([])
    const [pageCounts, setPageCounts] = useState(0)
    const [profilePath, setProfilePath] = useState(null)
    // const [userData, setUserData] = useState(null)
    const [bidDetails, setBidDetails] = useState(null)
    const [isBidDetails, setIsBidDetails] = useState(false)
    const [user, setUser] = useState(null)
    const [socket, setSocket] = useState(null)

    const pageHandler = (pageNo) => {
        if (pageNo.selected) {
            setPage(pageNo.selected)
            // getProposalList(jobID, pageNo.selected)
        } else {
            setPage(0)
            // getProposalList(jobID, 0)
        }
        // setPage(pageNo)
    }

    const getinputPrice = (e) => {
        const { value } = e.target;
        let error = '';
        if (value !== '') {
            const reg = new RegExp(/^\d+$/);
            if (!reg.test(value)) {
                error = 'Fixed price must be in numerical'
            }
        } else {
            error = 'Fixed price must be required.'
        }

        setPriceError(error)
        setPriceVal(value);
    }

    const chatHandler = async (info) => {
        const data = {
            profileId: user ? user.id : '',
            otherprofileId: info.profileID
        }
        // console.log(info)

        socket.emit('createConversation', data);

        // const res = await createChat(info.profileID);
        // // console.log(res);
        // if (res.status) {
        //     localStorage.setItem('chatID', res.data.id)
        //     props.history.push(`/message`)
        //     // props.history.push(`/message?chat=${id}`)
        // } else {
        //     // localStorage.setItem('chatID', res.id)
        //     // props.history.push(`/message`)
        //     notifyError(res.message);
        // }
    }

    const openHireModal = (data) => {
        // console.log(data);
        props.history.push(`/client/hire/${data.profileID}?bid_id=${data.id}`)
        // setModalHire(true);
        // setIsBidDetails(false)
        // setUserData(data)
    }
    const closeHireModal = () => {
        setModalHire(false);
    }

    const viewbidDetails = (data) => {
        setBidDetails(data)
        setIsBidDetails(true)
    }
    const closebidDetails = (data) => {
        setBidDetails(null)
        setIsBidDetails(false)
    }



    useEffect(() => {
        const id = props.match.params.id;

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token)
            setUser(decode)
        }

        const configureSocket = () => {
            var socket2 = io(SERVER, {
                secure: true
            });
            // , {
            //     reconnection: false,
            //     transports: ["websocket", "polling"]
            // }
            socket2.on('connection', () => {
                // console.log(socket);
                // console.log('socket is connected!')
            });

            socket2.on("errorwhileCreateConversation", err => {
                console.log(err);
                if (err) {
                    notifyError(err);
                }
            })

            socket2.on('privateConversationCreated', data => {
                // console.log(data);
                if (data) {
                    localStorage.setItem('chatID', data.id)
                    props.history.push(`/message`)
                }
            })

            setSocket(socket2);
        }
        configureSocket();
        // const id = window.location.href.split('/').pop();
        // const { id } = router.query;
        // console.log(jobID);
        // console.log(userData);
        const getProposalList = async (id, pageNo) => {
            // const id = window.location.href.split('/').pop();
            setLoader(true)
            const res = await getJobPostBids(id, (pageNo + 1));
            // console.log(res);
            const totalPage = Math.round(res.bidCount / 10)
            // console.log(totalPage);
            setfreelancerList(res.data);
            setLoader(false)
            if (totalPage === 0) {
                setPageCounts(1);
            } else {
                setPageCounts(totalPage);
            }
            setProfilePath(res.profilePath);
        }
        setJobID(id)
        getProposalList(id, page);
    }, [page, props.match.params.id, jobID, props])

    // if (true) {
    //     return (
    //         <div className={pageStyle.tab_content}>
    //             <h3 className={pageStyle.title_listing}>Job Status</h3>
    //             <div>This Job has been Closed.</div>
    //         </div>
    //     )
    // }
    // console.log(freelancerList);
    return (
        <>
            {/* <div className={'tab_content'}> */}
            {props?.details?.jobPostStateID !== 5 ?
                <>
                    <div style={{ padding: '25px 15px 0' }}>
                        {/* <h3 className={'title_listing'}>Hire freelancer</h3> */}
                    </div>
                    <ul className={'freelancer_list_wrap'}>
                        {loader ?
                            <MainLoaderComponent />
                            :
                            Array.isArray(freelancerList) && freelancerList.length > 0 ?
                                freelancerList.map((info, i) =>
                                    <li key={i} className="shadow-none">
                                        <div className={'user_prof'}>
                                            {info.profilePic ?
                                                <img src={`${profilePath}${info.profilePic}`} alt="freelancer" />
                                                :
                                                <img src={DummyUser} alt="freelancer" />
                                            }
                                        </div>
                                        <div className={'right_detail'}>
                                            {/* <Link href='/'>
                                    <a> */}
                                            <div className={'profile_wrap'} onClick={() => viewbidDetails(info)}>
                                                <div className={'caption'}>
                                                    <h5 className={'review_title'}>{info.name}</h5>
                                                    <div className={'user_review'}>
                                                        <div className={`rating cust`}>
                                                            <p className={'rating_badge'}>1.78</p>
                                                            <ReactStars count={5} value={3} edit={false} size={15} activeColor="#ffc12d" />
                                                            <span>(22 reviews)</span>
                                                        </div>
                                                    </div>
                                                    <div className={'user_r_detail mb-2'}>
                                                        <i className={'location_icon me-1 text-dark fs-4'}><MdLocationOn /></i>
                                                        {/* <img className={pageStyle.flag} src="/assets/images/dummy-user.jpg" alt="India" /> */}
                                                        <p>{info.city ? `${info.city}, ` : null} {info.stateName ? `${info.stateName}, ` : null} {info.countryName ? info.countryName : null}</p>
                                                    </div>
                                                </div>
                                                <p className={'price'}>
                                                    <span className={'pric'}>${info.bidAmount} / Project</span>
                                                    <span className={'deadline'}>{info.deadlineValue} Days</span>
                                                </p>
                                            </div>
                                            {/* </a>
                                </Link> */}
                                            <div className={`detail limit`}>
                                                <p>{info.description}</p>
                                            </div>
                                            <div className={'bottom_freela'}>
                                                <div className={'btns'}>
                                                    {info.hiredStatus === 1 ?
                                                        <button type="button" className={`btns1 btn_default`} aria-label="Hired" disabled>Hired</button>
                                                        :
                                                        info.offerStatus === 1 ?
                                                            <button type="button" className={`btns1 btn_default`} style={{maxWidth:'150px'}} aria-label="Waitng for agent accepted" disabled>Waitng accepted</button>
                                                            :
                                                            <button type="button" className={`btns1 btn_default`} aria-label="Hire" onClick={() => openHireModal(info)}>Hire</button>
                                                    }
                                                    <button type="button" className={`btns1 btn_blue`} aria-label="Chat" onClick={() => chatHandler(info)}>Chat</button>
                                                </div>
                                                {/* <p class="file-name">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.246" height="20.4" viewBox="0 0 11.246 20.4">
                                            <path class="a" d="M118.563,0a5.429,5.429,0,0,0-5.423,5.423V15.69a.663.663,0,1,0,1.326,0V5.423a4.1,4.1,0,0,1,8.194,0V16.008a2.669,2.669,0,0,1-2.666,2.665c-.011,0-.021.006-.032.006s-.021-.006-.032-.006a2.669,2.669,0,0,1-2.666-2.665V9.659a1.3,1.3,0,1,1,2.6,0V15.69a.663.663,0,1,0,1.326,0V9.659a2.624,2.624,0,1,0-5.248,0v6.35A4,4,0,0,0,119.931,20c.012,0,.021-.006.032-.007s.021.007.032.007a4,4,0,0,0,3.992-3.992V5.423A5.429,5.429,0,0,0,118.563,0Z" transform="translate(-112.94 0.2)"></path>
                                        </svg>
                                        <span>12345468748434_654.svg (130KB)</span>
                                    </p> */}
                                            </div>
                                        </div>
                                    </li>
                                )
                                :
                                <li>No bids available</li>
                        }
                    </ul>
                    <div className={'pagination_wrap'}>
                        <ReactPaginate
                            initialPage={page}
                            // forcePage={this.state.page}
                            pageCount={pageCounts}
                            pageRangeDisplayed={4}
                            breakLabel={'...'}
                            onPageChange={pageHandler}
                            activeClassName={'active'}
                            previousLabel={<MdKeyboardArrowLeft />}
                            previousClassName={'previous'}
                            nextLabel={<MdKeyboardArrowRight />}
                            nextClassName={'next'}
                        />
                    </div>

                    <Modal show={modalHire} style={{ fontFamily: 'Noto Sans JP, sans-serif' }} dialogClassName={'modal_hire'} onHide={closeHireModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header>
                            <Modal.Title style={{ fontSize: '20px', fontFamily: 'Noto Sans JP' }}>Hire Freelancer</Modal.Title>
                            <button type="button" onClick={closeHireModal} style={{ background: 'transparent', border: '0', padding: '0', fontSize: '22px' }}><i><MdClose /></i></button>
                        </Modal.Header>
                        <Modal.Body className={'hire_modal_body'}>
                            <ul className={`freelancer_list_wrap single_wrap`}>
                                <li>
                                    <div className={'user_prof'}>
                                        <img src={DummyUser} alt="freelancer" />
                                    </div>
                                    <div className={'right_detail'}>
                                        <div className={'profile_wrap'}>
                                            <div className={'caption'}>
                                                <h5 className={'review_title'}>deep123</h5>
                                                <div className={'user_review'}>
                                                    <div className={`rating cust`}>
                                                        <p className={'rating_badge'}>1.78</p>
                                                        <ReactStars count={5} value={3} edit={false} size={15} activeColor="#ffc12d" />
                                                        <span>(22 reviews)</span>
                                                    </div>
                                                </div>
                                                <div className={'user_r_detail'}>
                                                    <i className={'location_icon'}><MdLocationOn /></i>
                                                    {/* <img className={pageStyle.flag} src="/assets/images/dummy-user.jpg" alt="India" /> */}
                                                    <p>India</p>
                                                </div>
                                            </div>
                                            <p className={'price'}>
                                                <span className={'pric'}>$1,254.00 / Project</span>
                                                <span className={'deadline'}>2 Days</span>
                                            </p>
                                        </div>
                                        <div className={'bottom_freela'}>
                                            <div className={'pricing_block'}>
                                                <label>Fixed Price</label>
                                                <div className={'input_di'}>
                                                    <span>$</span>
                                                    <input type="text" name="price" value={priceVal} onChange={getinputPrice} />
                                                </div>
                                                {priceError !== '' ? <p className={`errorText error-text`}>{priceError}</p> : null}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                            <button type="button" className={"btn btn-outline-dark"} style={{ minWidth: '100px' }} onClick={closeHireModal}>Cancel</button>
                            <button type="button" className={"btn btn-primary"} style={{ minWidth: '160px' }}>Confirm and Hire</button>
                        </Modal.Footer>
                    </Modal>
                </>
                :
                <>
                    <h3 className={'title_listing'}>Job Status</h3>
                    <p className="mt-3 mb-0">This Job has been closed.</p>
                </>
            }
            {/* </div > */}

            <div className={isBidDetails ? `bg_overlay show` : `bg_overlay`} onClick={closebidDetails}></div>
            <div className={isBidDetails ? `details_wrap show` : `details_wrap`}>
                {/* {console.log(bidDetails)} */}
                <div className={'header_wrap'}>
                    <div className="row">
                        <div className="col-sm-12">
                            <button type="button" onClick={closebidDetails} className={'back_wrap'}><MdKeyboardArrowLeft /></button>
                        </div>
                    </div>
                </div>
                <div className={`details_wrap_body`}>
                    <div className={`wrap_detail card border-0`}>
                        <div className="row">
                            <div className={`border_end col-sm-12 col-md-12 col-lg-9 pt-5`}>
                                <ul className={`freelancer_list_wrap single_wrap`}>
                                    <li>
                                        <div className={'user_prof'}>
                                            {bidDetails && bidDetails.profilePic ?
                                                <img src={`${profilePath}${bidDetails.profilePic}`} alt="freelancer" />
                                                :
                                                <img src={DummyUser} alt="freelancer" />
                                            }
                                        </div>
                                        <div className={'right_detail'}>
                                            <div className={'profile_wrap'}>
                                                <div className={'caption'}>
                                                    <h5 className={'review_title'}>{bidDetails && bidDetails.name}</h5>
                                                    <div className={'user_review'}>
                                                        <div className={`rating cust`}>
                                                            <p className={'rating_badge'}>1.78</p>
                                                            <ReactStars count={5} value={3} edit={false} size={15} activeColor="#ffc12d" />
                                                            <span>(22 reviews)</span>
                                                        </div>
                                                    </div>
                                                    <div className={'user_r_detail'}>
                                                        <i className={'location_icon'}><MdLocationOn /></i>
                                                        {/* <img className={pageStyle.flag} src="/assets/images/dummy-user.jpg" alt="India" /> */}
                                                        <p>{bidDetails && bidDetails.city ? `${bidDetails.city}, ` : null} {bidDetails && bidDetails.stateName ? `${bidDetails.stateName}, ` : null} {bidDetails && bidDetails.countryName ? `${bidDetails.countryName}` : null}</p>
                                                    </div>
                                                </div>
                                                <p className={'price'}>
                                                    <span className={'pric'}>${bidDetails && bidDetails.bidAmount} / Project</span>
                                                    <span className={'deadline'}>{bidDetails && bidDetails.deadlineValue} Days</span>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                {/* <div className={pageStyle.title_wrap_details}>
                                    <h3>{bidDetails && bidDetails.title}</h3>
                                </div> */}
                                {/* <div className={pageStyle.category_wrap}>
                            <h4>Back-End Development</h4>
                            <p>Posted 33 minutes ago</p>
                        </div> */}
                                <div className={'description_wraps border-top mt-3'}>
                                    <p>{bidDetails && bidDetails.description}</p>
                                </div>
                                <div className={'price_wrap'}>
                                    <p>${bidDetails && bidDetails.bidAmount} <span>Fixed-Price</span></p>
                                </div>
                                {/* <div className={pageStyle.type_project}>
                            <p>Project Type: <span>Ongoing project</span></p>
                        </div> */}
                                {/* <div className={pageStyle.skills_wrap_detail}>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h5>Expertise</h5>
                                            <ul className={pageStyle.budges_skills}>
                                                {bidDetails && bidDetails.services.length > 0 ?
                                                    bidDetails.services.map((info, i) => <li key={i}>{info.categoryName}</li>)
                                                    :
                                                    null
                                                }
                                            </ul>
                                        </div>
                                        <div className="col-sm-6">
                                            <h5>Skills</h5>
                                            <ul className={pageStyle.budges_skills}>
                                                {bidDetails && bidDetails.skills.length > 0 ?
                                                    bidDetails.skills.map((info, i) => <li key={i}>{info.categoryName}</li>)
                                                    :
                                                    null
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-3">
                                <div className={`button_wrap border-bottom-0`}>
                                    {bidDetails && bidDetails.hiredStatus === 1 ?
                                        <button type="button" className="btn btn-dark" style={{ minWidth: '175px', marginBottom: '15px' }} disabled>Hired</button>
                                        :
                                        bidDetails && bidDetails.offerStatus === 1 ?
                                            <button type="button" className="btn btn-dark" style={{ minWidth: '175px', marginBottom: '15px' }} disabled>Waitng accepted</button>
                                            :
                                            <button type="button" className="btn btn-dark" style={{ minWidth: '175px', marginBottom: '15px' }} onClick={() => openHireModal(bidDetails)}>Hire</button>
                                    }
                                    {bidDetails && bidDetails.profileID ? <button type="button" className="btn btn-outline-dark" style={{ minWidth: '175px' }} onClick={() => props.history.push(`/client/freelancer-profile/${bidDetails.profileID}`)} >View Profile</button> : null}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

    )
}

export default withRouter(connect(null)(ProposalsSection))
