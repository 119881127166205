import * as ACTION_TYPES from '../actions/type';

const initialState = {
    isGetProfile:false,
    isUpdate:false,
    activeStep: 0,
    userData:null,
    expertise: {
        categoriesArray: [],
        categorieSelected: null,
        skillsArray: [],
        skillsSelected: []
    },
    titleOverview: {
        title: '',
        overview: ''
    },
    profileImg: '',
    contactDetails: {
        name:'',
        countryArray: [],
        country: null,
        stateArray: [],
        state: null,
        city: '',
        mobile:'',
        mobilePrefix:'',
        userImage:'',
    }
}

const ProfessionalInfoReducers = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.STEP_INFO_FREELANCER:
            return {
                ...state,
                activeStep: action.payload
            }
        case ACTION_TYPES.UPDATE_FREELANCER_PROFILE:
            return {
                ...state,
                isUpdate: action.payload
            }
        case ACTION_TYPES.EXPERTISE_MODULE:
            return {
                ...state,
                expertise: {
                    ...state.expertise,
                    categoriesArray: action.payload.categoriesArray,
                    categorieSelected: action.payload.categorieSelected,
                    skillsArray: action.payload.skillsArray,
                    skillsSelected: action.payload.selectedSkills
                }
            }
        case ACTION_TYPES.CLEAR_SKILLS:
            return {
                ...state,
                expertise: {
                    ...state.expertise,
                    skillsSelected: []
                }
            }
        case ACTION_TYPES.TITLE_AND_OVERVIEW:
            return {
                ...state,
                titleOverview: {
                    ...state.titleOverview,
                    title: action.payload.title,
                    overview: action.payload.overview
                }
            }
        case ACTION_TYPES.PROFILE_IMAGE_UPLOAD:
            return {
                ...state,
                profileImg:action.payload
            }
        case ACTION_TYPES.CONTACT_DETAILS:
            return {
                ...state,
                contactDetails: {
                    ...state.contactDetails,
                    countryArray: action.payload.countries,
                    country: action.payload.country,
                    stateArray: action.payload.states,
                    state: action.payload.state,
                    city: action.payload.city,
                    mobile: action.payload.mobile,
                    name: action.payload.name,
                    mobilePrefix:action.payload.mobilePrefix,
                    userImage:action.payload.userImage
                }
            }
        case ACTION_TYPES.FREELANCER_STEPS_DATA:
            return{
                ...state,
                userData:action.payload
            }
        case ACTION_TYPES.PROFILE_SETP_API_GET:
            return{
                ...state,
                isGetProfile:action.payload
            }
        default:
            return state;
    }
}

export default ProfessionalInfoReducers;