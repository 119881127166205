import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DataTable from '../../components/DataTable';
import { Button } from 'react-bootstrap';
import { getClients } from '../../controller/client.controller';
import DummyImage from '../../assets/img/dummy-userImg.jpg';
import { FiEye } from 'react-icons/fi';
import Modal from 'react-bootstrap/Modal';

class ClientList extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            clientList: [],
            path:'',
            details: null,
            descModal: false
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0)
        this.getClientList();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getClientList = async () => {
        const res = await getClients();
        // console.log(res);
        if (this._unmounted) {
            this.setState({
                clientList: res.users,
                path:res.path
            })
        }
    }

    pageHandler = (page, sizePerPage) => {
        console.log(page);
    }

    sizePerPageChange = (sizePerPage, page) => {
        console.log(sizePerPage);
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Table is Empty</p>
        </div>
    }

    openModalDescription = (row) => {
        if (this._unmounted) {
            this.setState({
                details: row,
                descModal: true
            })
        }
    }

    closeModalDescription = () => {
        if (this._unmounted) {
            this.setState({
                details: null,
                descModal: false
            })
        }
    }

    headerCenterFormatter = (column) => {
        return <div className="d-flex justify-content-center">{column.text}</div>
    }

    statusFormatter = (cell, row) => {
        // console.log(row);
        let classsName = '';
        let statusName = '';
        switch (row.status) {
            case 0:
                classsName = 'warning';
                statusName = 'Pending';
                break;
            case 1:
                classsName = 'success';
                statusName = 'Active';
                break;
            case 2:
                classsName = 'warning';
                statusName = 'Pending';
                break;
            default:
                classsName = 'secondary';
                statusName = 'none'
                break;
        }

        return <div className="d-flex justify-content-center">
            <Button variant={classsName} className={`rounded-pill`} style={{ fontSize: '.775rem', minWidth: '100px' }}>{statusName}</Button>
        </div>
    }

    errorImage = (e) => {
        e.target.src = DummyImage
    }

    nameFormatter = (cell, row) => {
        return <div className="d-flex align-items-center">
            {row.profilePic ?
                <img className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} src={`${this.state.path}${row.profilePic}`} onError={this.errorImage} alt="freelancer" />
                :
                <img className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} src={DummyImage} alt="freelancer" />
            }
            <span>{row.name}</span>
        </div>
    }

    actionFormatter = (cell, row) => {
        return <div className="d-flex justify-content-center">
            <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.openModalDescription(row)}><FiEye /></button>
        </div>
    }

    render() {

        const columns = [
            {
                dataField: 'name',
                text: 'Full Name',
                sort: true,
                formatter: this.nameFormatter
            },
            {
                dataField: 'email',
                text: 'Email',
                sort: true
            },
            {
                dataField: 'mobile',
                text: 'Mobile Number',
                sort: true
            },
            {
                dataField: 'countryName',
                text: 'Country',
                sort: true
            },
            {
                dataField: 'status',
                text: 'Status',
                sort: true,
                formatter: this.statusFormatter,
                headerFormatter: this.headerCenterFormatter
            },
            // {
            //     dataField: 'action',
            //     text: 'Action',
            //     sort: false,
            //     formatter: this.actionFormatter,
            //     headerFormatter: this.headerCenterFormatter
            // }
        ];


        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3"><strong>Client List</strong></h1>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <DataTable
                                keyField="profileID"
                                data={this.state.clientList}
                                columns={columns}
                                // onSizePerPageChange={this.sizePerPageChange} 
                                // onPageChange={this.pageHandler} 
                                noDataIndication={this.emptyRender} />

                        </div>
                    </div>
                </div>
                {this.state.descModal ?
                    <Modal show={this.state.descModal} onHide={this.closeModalDescription} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >

                        <Modal.Body className="p-4">
                            <div className="d-flex align-items-center">
                                {this.state.details.profilePic ?
                                    <img className="rounded-circle me-2" style={{ width: '50px', height: '50px' }} src={`${this.state.path}${this.state.details.profilePic}`} onError={this.errorImage} alt="freelancer" />
                                    :
                                    <img className="rounded-circle me-2" style={{ width: '50px', height: '50px' }} src={DummyImage} alt="freelancer" />
                                }
                                <span>
                                    <p className="fs-5 mb-0"><b>{this.state.details.name}</b></p>
                                    <p className="fs-5 mb-0"><small className="fs-6">{this.state.details.email}</small></p>
                                </span>
                            </div>
                            {this.state.details.city ? <p className="fs-5 mt-3"><b> Address: </b> {this.state.details.city ? `${this.state.details.city},` : ''}{this.state.details.stateName ? ` ${this.state.details.stateName},` : ''} {this.state.details.countryName ? ` ${this.state.details.countryName}` : ''}</p> : null}
                            {this.state.details.mobile ? <p className="fs-5 mt-3"><b> Phone: </b> {this.state.details.mobile}</p> : null}

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary border-0" onClick={this.closeModalDescription}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    :
                    null
                }
            </div>
        )
    }
}

export default withRouter(connect(null)(ClientList))
