import { createStore, applyMiddleware, compose } from 'redux';
// import { HYDRATE, createWrapper } from "next-redux-wrapper";
import { rootReducers } from '../reducers';
import thunk from 'redux-thunk';

const composeEnhancer = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// without redux devtools
// export default createStore(RootReducers, compose(applyMiddleware(thunk)))

// with redux devtools
//  composeEnhancer(applyMiddleware(thunk))
// const initStore = () => createStore(rootReducers, composeEnhancer(applyMiddleware(thunk)));
// export const store = createWrapper(initStore);
export const store = createStore(rootReducers, composeEnhancer(applyMiddleware(thunk)));