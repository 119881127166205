import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { MdArrowBack } from 'react-icons/md';
import ReactStars from "react-rating-stars-component";
import ConfirmModal from '../../../components/confirmModal';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import contractService from '../../../controller/contract.controller';
import moment from 'moment';
import payment from '../../../controller/payment.controller';
import { notifyError, notifySuccess } from '../../../helper/Alert';

class FreelancerContractDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contractId: 0,
            tab: 0,
            details: null,
            loader: false,
            btnLoader: false,
            isEndContractModal: false,
            endContractLoader: false,
            milestoneRequestModal: false,
            milestoneArray: []
        }
    }

    componentDidMount() {
        const { match: { params: { cid } } } = this.props;
        if (cid) {
            this.getContractDetails();
            this.setState({
                contractId: cid
            })
        }

    }

    getContractDetails = async () => {
        const { match: { params: { cid } } } = this.props;
        this.setState({
            loader: true
        })
        const res = await contractService.getFreelancerContractDetails(cid);
        console.log(res);
        if (res.status) {
            this.setState({
                details: res.data,
                milestoneArray: res.data.milestoneDetail,
                loader: false
            })
        } else {
            this.setState({
                details: null,
                loader: false
            })
        }
    }

    tabHandler = (val) => {
        this.setState({
            tab: val
        })
    }

    closeEndContractModal = () => {
        this.setState({
            isEndContractModal: false
        })
    }

    openEndContractModal = () => {
        this.setState({
            isEndContractModal: true
        })
    }

    requestMilestoneModal = async (info) => {
        const { match: { params: { cid } } } = this.props;
        const res = await payment.freelancerMilestoneRequest(info.id, cid)
        if (res.status) {
            const { milestoneArray } = this.state;
            const newArray = milestoneArray && milestoneArray.length > 0 ? milestoneArray.map(item => {
                if (info.id === item.id) {
                    item.status = 3
                }
                return item
            }) : [];
            notifySuccess(res.message)
            this.setState({
                milestoneArray: newArray
            })
        } else {
            notifyError(res.message);
        }
        // this.setState({
        //     milestoneRequestModal: true
        // })
    }

    closeRequestMilestoneModal = () => {
        this.setState({
            milestoneRequestModal: true
        })
    }

    endContractSubmit = async () => {
        this.setState({
            endContractLoader: true
        });
        // const { match: { params: { cid } } } = this.props;
        const jobid = this.state.details?.jobPostID;
        const data = {
            jobPostID: jobid,
            contractID:this.state.details?.id
        }
        const res = await contractService.freelancerEndContract(data);
        if (res.status) {
            this.setState({
                endContractLoader: false,
                isEndContractModal: false,
            });
            this.props.history.push(`/freelancer/review/${jobid}?cid=${this.state.details?.clientProfileID}`)
        } else {
            notifyError(res.message)
            this.setState({
                endContractLoader: false,
                isEndContractModal: false,
            });
        }
        // this.props.history.push('/freelancer/my-jobs');
    }

    goFeedBack = () => {
        const jobid = this.state.details?.jobPostID;
        this.props.history.push(`/freelancer/review/${jobid}?cid=${this.state.details?.clientProfileID}`)
    }

    render() {

        const { match: { params: { cid } } } = this.props;
        if (!cid) {
            return this.props.history.goBack();
        }

        const { tab, isEndContractModal, endContractLoader, milestoneArray, milestoneRequestModal, details } = this.state;
        // console.log(details);

        return (
            <section className={`proposals_section pt-5`}>
                {endContractLoader ?
                    <div className="bg-overlay-endContact">
                        <MainLoaderComponent />
                    </div>
                    :
                    null
                }
                {details ?
                    <div className="container">
                        <div className="row justify-content-sm-center">
                            <div className="col-sm-10 d-flex justify-content-between align-items-start">
                                <h1 className={'pageTitle'} style={{ cursor: 'pointer' }} onClick={() => this.props.history.goBack()}><MdArrowBack /></h1>
                                <div>
                                    {details?.jobPostStateID === 2 ?
                                        <button type="button" className="btn btn-success rounded-pill" style={{ minWidth: '120px' }} onClick={this.openEndContractModal}>End Project</button>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="col-sm-10 mt-4">
                                {/* <div className="d-flex align-items-center justify-content-end mb-4">
                                <button type="button" className="btn btn-success rounded-pill" style={{ minWidth: '120px' }}>End Project</button>
                            </div> */}
                                <div className="card border-0 p-0">
                                    <div className="card-body ps-0 pe-0 pt-0 pb-0">
                                        <div className="d-flex align-items-start justify-content-between p-4">
                                            <div className="project-des">
                                                <h2>{details?.title}</h2>
                                                <p className="mb-0 d-flex"><span className="me-2">
                                                    {details?.completedAt && details?.completedAt !== '0000-00-00 00:00:00' ?
                                                        `Compeleted on ${moment(details?.completedAt).format('MMM DD, YYYY')}`
                                                        :
                                                        details?.startedAt ? `Started on ${moment(details?.startedAt).format('MMM DD, YYYY')}` : ''}</span>

                                                </p>
                                            </div>
                                            <div className="profile-wr d-flex align-items-center">
                                                <div className="ima_wrap">
                                                    {details?.profilePic ?
                                                        <img src={`${details?.profileURL}${details.profilePic}`} alt="client" />
                                                        :
                                                        <img src="https://vedain.s3.ap-south-1.amazonaws.com/profilepic/1627982252901.deposit-screen.png" alt="client" />
                                                    }
                                                </div>
                                                <p className="mb-0">
                                                    {details?.name}
                                                    {/* <span className="d-block mt-2"></span> */}
                                                </p>
                                            </div>
                                        </div>
                                        <ul className="small-tabs p-4 pt-0 pb-0 border-bottom">
                                            <li className={tab === 0 ? 'active' : ''} onClick={() => this.tabHandler(0)}>Milestone</li>
                                            {details?.jobPostStateID === 4 && <li className={tab === 1 ? 'active' : ''} onClick={() => this.tabHandler(1)}>FeedBack</li>}
                                        </ul>
                                        <div className="tab-content">
                                            {tab === 0 ?
                                                <div className="">
                                                    <div className="d-flex align-items-center justify-content-between p-4 border-bottom">
                                                        <div className="">
                                                            <p className="mb-0">
                                                                <b>Budget</b>
                                                                <span className="fs-3 d-block">${details.amount}</span>
                                                            </p>
                                                        </div>
                                                        <div className="">
                                                            <p className="mb-0">
                                                                <b>In Escrow</b>
                                                                <span className="fs-3 d-block">${details?.getMilestoneSum?.milestoneEscrow ? details?.getMilestoneSum?.milestoneEscrow : 0}</span>
                                                            </p>
                                                        </div>
                                                        <div className="">
                                                            <p className="mb-0">
                                                                <b>Milestones Paid</b>
                                                                <span className="fs-3 d-block">${details?.getMilestoneSum?.milestonePaid ? details?.getMilestoneSum?.milestonePaid : 0}</span>
                                                            </p>
                                                        </div>
                                                        <div className="border-start ps-3">
                                                            <p className="mb-0">
                                                                <b>Total Earnings</b>
                                                                <span className="fs-3 d-block">${details?.getMilestoneSum?.milestonePaid ? details?.getMilestoneSum?.milestonePaid : 0}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 p-4">
                                                        <div className="d-flex justify-content-between">
                                                            <h5 className="fw-bold mb-4">Milestones</h5>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table table-striped table-hovered">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th>Detail</th>
                                                                        <th className="text-start">Amount</th>
                                                                        <th className="text-center">Status</th>
                                                                        <th className="text-center">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {milestoneArray && milestoneArray.length > 0 ?
                                                                        milestoneArray.map((info, i) =>
                                                                            <tr key={i}>
                                                                                <td className="text-start" style={{ verticalAlign: 'baseline' }}>{i + 1}</td>
                                                                                <td>
                                                                                    <p>
                                                                                        {info.clientMessage}
                                                                                        {/* {info.status === 3 && info.freelancerMessage} */}
                                                                                        <span className="d-block">
                                                                                            {info.completedAt ?
                                                                                                `Completed ${moment(info.completedAt).format('MMM DD, YYYY')}`
                                                                                                :
                                                                                                info.createdAt ?
                                                                                                    `Started on ${moment(info.createdAt).format('MMM DD, YYYY')}`
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </span>
                                                                                    </p>
                                                                                </td>
                                                                                <td>${info.amount}</td>
                                                                                <td className="text-center">
                                                                                    {info.status === 4 ?
                                                                                        <span className="milestone-based danger rounded-pill">Refunded</span>
                                                                                        :
                                                                                        info.status === 3 ?
                                                                                            <span className="milestone-based bg-warning rounded-pill">Requested</span>
                                                                                            :
                                                                                            info.status === 2 ?
                                                                                                <span className="milestone-based success rounded-pill">Paid</span>
                                                                                                :
                                                                                                info.status === 1 ?
                                                                                                    <span className="milestone-based success rounded-pill">Active</span>
                                                                                                    :
                                                                                                    null
                                                                                    }
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {info.status === 3 ?
                                                                                        <button type="button" className="btn btn-warning" disabled>Requested</button>
                                                                                        :
                                                                                        info.status === 2 ?
                                                                                            <button type="button" className="btn btn-success rounded-pill" disabled>Paid</button>
                                                                                            :
                                                                                            info.status === 4 ?
                                                                                                <button type="button" className="btn btn-outline-danger rounded-pill" disabled>Refunded</button>
                                                                                                :
                                                                                                info.status === 1 ?
                                                                                                    <button type="button" className="btn btn-success rounded-pill me-2" style={{ minWidth: '120px' }} onClick={() => this.requestMilestoneModal(info)}>Release Request</button>
                                                                                                    :
                                                                                                    null
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                        :
                                                                        <tr>
                                                                            <td colSpan="5" className="text-center">No Milestone found.</td>
                                                                        </tr>
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            {tab === 1 ?
                                                <div className="d-flex align-items-start justify-content-between p-4 row">
                                                    <div className="col-xs-12 col-sm-6 mb-xs-3">
                                                        <div className="row">
                                                            <h4 className="fs-4 fw-bold">Client's Feedback to You</h4>
                                                            {details?.clientReview && details.clientReview.length > 0 ?

                                                                details.clientReview.map((info, i) =>
                                                                    <div className="col-sm-12" key={i}>
                                                                        <ReactStars count={5} isHalf={true} value={info.rate} edit={false} size={18} activeColor="#ffc12d" />
                                                                        <p className="mb-2">{info.description}</p>
                                                                        <p className="mb-0">By you</p>

                                                                    </div>
                                                                )
                                                                :
                                                                <div className="col-sm-12">
                                                                    <p className="mb-2">No Feedback available</p>
                                                                </div>
                                                            }
                                                        </div>

                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="row">
                                                            <h4 className="fs-4 fw-bold">Your Feedback to Client</h4>
                                                            {details?.freelancerReview && details.freelancerReview.length > 0 ?
                                                                details.freelancerReview.map((info, i) =>
                                                                    <div className="col-sm-12" key={i}>
                                                                        <ReactStars count={5} isHalf={true} value={info.rate} edit={false} size={18} activeColor="#ffc12d" />
                                                                        <p className="mb-2">{info.description}</p>
                                                                        <p className="mb-0">to {info.clientName}</p>

                                                                    </div>
                                                                )
                                                                :
                                                                <div>
                                                                    <button type={"button"} className="btn btn-outline-success rounded-pill" onClick={this.goFeedBack}>Add your feedback</button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isEndContractModal ?
                                <ConfirmModal
                                    show={isEndContractModal}
                                    onHide={this.closeEndContractModal}
                                    text="Are you sure you want to end this contract?"
                                    onSubmit={this.endContractSubmit}
                                />
                                :
                                null
                            }
                            {/* {milestoneRequestModal} */}
                        </div>
                    </div>
                    :
                    null
                }
            </section>
        )
    }
}

export default withRouter(connect(null)(FreelancerContractDetails));