import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { MdSearch, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import { jwtDecode } from '../../../helper/jwt.helper';
// import moment from 'moment';
import '../../../assets/css/jobs.css';

class AllJobs extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            mainArray: [],
            page: 0,
            pageCount: 0,
            loader: false,
        }
    }

    componentDidMount() {
        this._unmounted = true;
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    pageHandler = (page) => {
        if (page.selected) {

            this.setState({
                page: page.selected
            })

        } else {
            this.setState({
                page: page.selected
            })
        }
    }

    searchHandler = (e) => {

        const search = e.target.value;

        this.setState({
            search
        })

    }

    searchSubmit = (e) => {
        e.preventDefault();
        // this.getJobsList(this.state.activeTab, this.state.page, this.state.search)
    }

    renderJobList = (info, index) => {
        // console.log(info);
        // let statusClass = `btn_status purple`;
        // switch(info.jobTypeID){
        //     case 
        //     default:
        //         break;
        // }

        return <li>
            <Link to={`/client/all-jobs/${1}`}>
                <div className={'projectList_div d-flex flex-sm-column align-items-start justify-content-lg-between'}>
                    <div className={'left_wrap'}>
                        <h5 className={'job_title'}>Expert in Server admin(AWS)</h5>
                        <div className={'details_text'}>
                            <p className={'job_description'}>Created 7 months ago by You</p>
                            <p className={'job_description'}>Closed - Dec 22, 2020</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <p>0</p>
                            <p>Proposals</p>
                        </div>
                        <div>
                            <p>0</p>
                            <p>Messaged</p>
                        </div>
                        <div>
                            <p>0</p>
                            <p>Hired</p>
                        </div>
                    </div>
                    {/* <div className={right_wrap}></div> */}
                </div>
            </Link>
        </li>
    }

    render() {

        const { search } = this.state;

        const token = localStorage.getItem('token');
        if(token){
            const decode = jwtDecode(token);
            if(decode.profileType === 1){
                return <Redirect to="/freelancer/jobs" />
            }
        }

        return (
            <section className={`jobs_wrap`}>
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-sm-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/client/my-jobs">My jobs</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Job postings</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-sm-6">
                            <div className="d-flex justify-content-lg-end">
                                <Link to="/client/post-job" className="btn btn-primary rounded-pill ps-4 pe-4">Post a New Job</Link>
                            </div>
                        </div>
                    </div>
                    <div className={'jobs_div allJobs'}>
                        <div className={'search_jobs'}>
                            <form action="/" method="POST" onSubmit={this.searchSubmit}>
                                <div className="form-group">
                                    <input type="text" className={`form-control search_input`} name="search" value={search} onChange={this.searchHandler} placeholder="Search job postings" />
                                    <button type="submit" className={'btn_search'}><MdSearch /></button>
                                </div>
                            </form>
                        </div>

                        <ul className={'jobsList'}>
                            {/* {mainArray.length > 0 ?
                                mainArray.map((info, i) => this.renderJobList(info, i))
                                :
                                <li className="text-center">No Jobs Found.</li>
                            } */}
                            <li>
                                <Link to={`/client/all-jobs/${1}`}>
                                    <div className={'projectList_div div-flex'}>
                                        <div className={'left_wrap'}>
                                            <h5 className={'job_title'}>Expert in Server admin(AWS)</h5>
                                            <div className={'details_text'}>
                                                <p className={'job_description mb-1'}>Created 7 months ago by You</p>
                                                <p className={'job_description mb-0'}>Closed - Dec 22, 2020</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="me-5 div-views">
                                                <p className="mb-0">0</p>
                                                <p className="mb-0">Proposals</p>
                                            </div>
                                            {/* <div className="me-5 div-views">
                                                <p className="mb-0">0</p>
                                                <p className="mb-0">Messaged</p>
                                            </div> */}
                                            <div className="div-views">
                                                <p className="mb-0">0</p>
                                                <p className="mb-0">Hired</p>
                                            </div>
                                        </div>
                                        {/* <div className={right_wrap}></div> */}
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/client/all-jobs/${1}`}>
                                    <div className={'projectList_div div-flex'}>
                                        <div className={'left_wrap'}>
                                            <h5 className={'job_title'}>Expert in Server admin(AWS)</h5>
                                            <div className={'details_text'}>
                                                <p className={'job_description mb-1'}>Created 7 months ago by You</p>
                                                <p className={'job_description mb-0'}>Closed - Dec 22, 2020</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="me-5 div-views">
                                                <p className="mb-0">0</p>
                                                <p className="mb-0">Proposals</p>
                                            </div>
                                            {/* <div className="me-5 div-views">
                                                <p className="mb-0">0</p>
                                                <p className="mb-0">Messaged</p>
                                            </div> */}
                                            <div className="div-views">
                                                <p className="mb-0">0</p>
                                                <p className="mb-0">Hired</p>
                                            </div>
                                        </div>
                                        {/* <div className={right_wrap}></div> */}
                                    </div>
                                </Link>
                            </li>
                        </ul>

                        {this.state.pageCount > 1 ?
                            <div className={'pagination_wrap'}>
                                <ReactPaginate
                                    initialPage={this.state.page}
                                    // forcePage={this.state.page}
                                    pageCount={this.state.pageCount}
                                    pageRangeDisplayed={4}
                                    breakLabel={'...'}
                                    onPageChange={this.pageHandler}
                                    containerClassName={'pagination_ul'}
                                    activeClassName={'active'}
                                    previousLabel={<MdKeyboardArrowLeft />}
                                    previousClassName={'previous'}
                                    nextLabel={<MdKeyboardArrowRight />}
                                    nextClassName={'next'}
                                />
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(connect(null)(AllJobs))
