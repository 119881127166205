import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { io } from "socket.io-client";
import { MdTurnedInNot, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdLocationOn, MdTurnedIn } from 'react-icons/md'
import ReactStars from "react-rating-stars-component";
import ReactPaginate from 'react-paginate';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';
import '../../../assets/css/findFreelancer.css';
import { mainCategories } from '../../../controller/categories.controller';
import { CHAT_URL } from '../../../controller/chat.controller';
import DummyUser from '../../../assets/images/dummy-user.jpg'
import { findFreelancerList, freelancerSaveUnsave } from '../../../controller/findfreelancer.controller';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import { jwtDecode } from '../../../helper/jwt.helper';

// const animatedComponents = makeAnimated();

const SERVER = CHAT_URL;

class FindFreelancerComponent extends Component {
    socket;
    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            user: null,
            isSmallWindow: false,
            filterOpen: false,
            expertise: [],
            expertiseOption: [],
            tabActive: 0,
            search: '',
            isSearch: false,
            page: 0,
            pageCount: 1,
            freelancerList: [],
            imagePath: '',
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);
        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (this._unmounted) {
                this.setState({
                    user: decode
                })
            }
            this.configureSocket();
        }

        window.addEventListener('resize', this.checkWindowWidth)

        this.getExpertise();
        this.getFreelancerList(this.state.expertise, this.state.page, this.state.search);
        this.checkWindowWidth();
    }

    componentWillUnmount() {
        this._unmounted = false;
        window.removeEventListener('resize', this.checkWindowWidth)
    }

    configureSocket = () => {
        var socket = io(SERVER, {
            secure: true,
            forceNew: true,
            transports: ["polling"]
        });
        // , {
        //     reconnection: false,
        //     transports: ["websocket", "polling"]
        // }
        socket.on('connection', () => {
            // console.log(socket);
            // console.log('socket is connected!')
        });

        socket.on("errorwhileCreateConversation", err => {
            console.log(err);
            if (err) {
                notifyError(err);
            }
        })

        socket.on('privateConversationCreated', data => {
            // console.log(data);
            if (data) {
                localStorage.setItem('chatID', data.id)
                this.props.history.push(`/message`)
            }
        })

        this.socket = socket;
    }

    getExpertise = async () => {

        const categories = await mainCategories();
        if (categories.length) {
            const options = categories.map((info) => ({ label: info.categoryName, value: info.id }));
            if (this._unmounted) {
                this.setState({
                    expertiseOption: options
                })
            }

        } else {
            if (this._unmounted) {
                this.setState({
                    expertiseOption: []
                })
            }
        }
        // console.log(res);
    }

    getFreelancerList = async (expertise, page, search) => {
        if (this._unmounted) {
            this.setState({
                loader: true
            })
        }
        const data = {
            categoryID: expertise,
            pageNo: page,
            searchText: search,
            tab: this.state.tabActive === 2 ? 'SAVED' : 'ALL'
        }
        const res = await findFreelancerList(data);
        // console.log(res);
        if (res.status) {
            const count = Math.round(res.totalCount / 10);
            if (this._unmounted) {
                this.setState({
                    freelancerList: res.freelancers,
                    imagePath: res.path,
                    pageCount: count,
                    loader: false
                })
            }
            // setFreelancerList(res.freelancers)
            // setImagePath(res.path)
        } else {
            if (this._unmounted) {
                this.setState({
                    freelancerList: [],
                    loader: false
                })
            }
        }
    }

    checkWindowWidth = () => {
        if (this._unmounted) {
            this.setState({
                isSmallWindow: window.innerWidth < 767
            })
        }
        // console.log(window.innerWidth < 767);
        // setIsSmallWindow(window.innerWidth < 767)
    }

    saveFreelancer = async (id) => {
        console.log(id);
        const res = await freelancerSaveUnsave(id);
        if (res.status) {
            notifySuccess(res.message)
            this.getFreelancerList(this.state.expertise, this.state.page, this.state.search)
        } else {
            notifyError(res.message)
        }
    }

    pageHandler = (pageNo) => {
        // console.log(pageNo);
        let pageNumber = 0;
        if (pageNo.selected) {
            pageNumber = pageNo.selected
        } else {
            pageNumber = 0
        }

        if (this._unmounted) {
            this.setState({
                page: pageNumber
            })
        }
        this.getFreelancerList(this.state.expertise, pageNumber, this.state.search)
    }

    tabHandler = (tab) => {
        if (this._unmounted) {
            this.setState({
                tabActive: tab
            }, () => this.getFreelancerList(this.state.expertise, this.state.page, this.state.search))
        }
    }

    searchHandler = (e) => {
        const { value } = e.target;
        if (this._unmounted) {
            this.setState({
                search: value
            })
        }
    }

    searchSubmit = (e) => {
        e.preventDefault();
        this.getJobsList(this.state.expertise, 0, this.state.search)
        // setIsSearch(!isSearch);
        // console.log('working...')
    }

    filterToggleHandler = () => {
        if (this._unmounted) {
            this.setState(prevState => ({
                filterOpen: !prevState.filterOpen
            }))
        }
        // setFilterOpen(!filterOpen)
    }

    expertiseClickHandler = (val) => {
        let selected = this.state.expertise;

        if (selected.includes(val)) {
            selected = selected.filter(info => info !== val);
        } else {
            selected = [...selected, val]
        }
        if (this._unmounted) {
            this.setState({
                expertise: selected
            }, () => this.getFreelancerList(this.state.expertise, 0, this.state.search))
        }
    }

    viewProfileHandler = (id) => {
        this.props.history.push(`/client/freelancer-profile/${id}`)
    }

    redireactToPost = async (info) => {
        const data = {
            profileId: this.state.user ? this.state.user.id : '',
            otherprofileId: info.id
        }
        this.socket.emit('createConversation', data);
        // console.log(res);
        // const res = await createChat(info.id);
        // // console.log(res);
        // if (res.status) {
        //     localStorage.setItem('chatID', res.data.id)
        //     this.props.history.push(`/message`)
        //     // props.history.push(`/message?chat=${id}`)
        // } else {
        //     // localStorage.setItem('chatID', res.id)
        //     // props.history.push(`/message`)
        //     notifyError(res.message);
        // }
        // this.props.history.push(`/client/post-job`)
        // this.props.history.push(`/client/post-job?freelancer=${data.id}`)
    }

    render() {

        const token = localStorage.getItem('token');
        if(token){
            const decode = jwtDecode(token);
            if(decode.profileType === 1){
                return <Redirect to="/freelancer/jobs" />
            }
        }

        const { loader, isSmallWindow, filterOpen, expertiseOption, expertise, page, tabActive, search, freelancerList, imagePath } = this.state;

        return (
            <>
                <section className={'freelancer_section'}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                <div className={'filter_wrap'}>
                                    <div className={'main_filter_div'}>
                                        <h3>Filter By</h3>
                                        {isSmallWindow ?
                                            <div className={'filter_icon'} onClick={this.filterToggleHandler}>
                                                <svg width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="filter_menu" transform="translate(-596 -221)">
                                                        <rect id="Rectangle_10456" data-name="Rectangle 248" width="24" height="24" transform="translate(596 221)" fill="#fff" opacity="0"></rect>
                                                        <g id="filter-results-button" transform="translate(596 148.5)">
                                                            <g id="filter" transform="translate(0 76.5)">
                                                                <path id="Path_1079" data-name="Path 1079" d="M9.333,92.5h5.333V89.833H9.333ZM0,76.5v2.667H24V76.5Zm4,9.333H20V83.167H4Z" transform="translate(0 -76.5)" fill="#1a62f5"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className={filterOpen ? `collapse_filter active` : 'collapse_filter'}>
                                        {/* <div className={`filter_radio division posting`}>
                                        <button type="button" onClick={() => filterByHandler(1)} className={filterBy === 1 ? 'active' : ''}>Total Jobs</button>
                                        <button type="button" onClick={() => filterByHandler(2)} className={filterBy === 2 ? 'active' : ''}>Total Rating</button>
                                    </div> */}
                                        {(expertiseOption.length > 0) ?
                                            <div className={`main_area`}>
                                                <h5>Expertise</h5>
                                                <ul className={`radio_group posting`}>
                                                    {expertiseOption.map((info, i) =>
                                                        <li key={i}>
                                                            <label className={expertise.includes(info.value) ? 'active' : ''} onClick={() => this.expertiseClickHandler(info.value)}>{info.label}</label>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-9 col-sm-12 col-xs-12">
                                <div className={'result_wrap pb-0 ms-0'}>
                                    <ul className={'tabs_ul'}>
                                        <li><button type="button" onClick={() => this.tabHandler(0)} className={tabActive === 0 ? 'active' : ''}>All</button></li>
                                        {/* <li><button type="button" onClick={() => tabHandler(1)} className={tabActive === 1 ? pageStyle.active : ''}>My Hires</button></li> */}
                                        <li><button type="button" onClick={() => this.tabHandler(2)} className={tabActive === 2 ? 'active' : ''}>Saved</button></li>
                                    </ul>
                                    <div className={'listing_section'}>
                                        <form action="/" method="POST" onSubmit={this.searchSubmit}>
                                            <div className={'form_search'}>
                                                <input type="text" name="search" placeholder="Search for Freelancers" autoComplete="off" value={search} onChange={this.searchHandler} />
                                                <button type="submit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.4 12.4"><path className={'w_search'} d="M11.863,11.2,8.935,8.275a5.058,5.058,0,1,0-.66.66L11.2,11.863a.467.467,0,1,0,.66-.66ZM.933,5.051A4.117,4.117,0,1,1,5.051,9.168,4.122,4.122,0,0,1,.933,5.051Z" transform="translate(0.2 0.2)"></path></svg>
                                                </button>
                                            </div>
                                        </form>
                                        <ul className={`freelancers_ul ${loader?'pb-4':''}`}>
                                            {loader ?
                                                <MainLoaderComponent color="black" />
                                                :
                                                freelancerList.length > 0 ?
                                                    freelancerList.map((info, i) =>
                                                        <li key={i}>
                                                            <div className={`review_div freeList`}>
                                                                <div className={'user_prof'}>
                                                                    {info.profilePic ?
                                                                        <img src={`${imagePath}${info.profilePic}`} alt="user" />
                                                                        :
                                                                        <img src={DummyUser} alt="user" />
                                                                    }
                                                                </div>
                                                                <div className={'right_details'}>
                                                                    <div className={'caption_wrap'}>
                                                                        <h5 className={'review_title'}>{info.name}</h5>
                                                                        <div className={'btns'}>
                                                                            <button type="button" className={`save_btn me-3 ${info.saved === 1 ? 'active' : null}`} onClick={() => this.saveFreelancer(info.id)}>{info.saved === 1 ? <MdTurnedIn /> : <MdTurnedInNot />}</button>
                                                                            <button type="button" className={'btn btn-outline-dark me-3'} onClick={() => this.viewProfileHandler(info.id)}>View Profile</button>
                                                                            <button type="button" className={'btn btn-dark'} style={{ minWidth: '130px' }} onClick={() => this.redireactToPost(info)}>Chat</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className={'detail'}>
                                                                        {/* <p className={'price'}>$5<span> USD/Hour</span></p> */}
                                                                        <p className={'summary'}>{info.bio}</p>
                                                                        <div className={'user_review'}>
                                                                            <div className={'rating_find'}>
                                                                                <p className={'rating_badge'}>{info.rate?info.rate:0}</p>
                                                                                <ReactStars count={5} value={info.rate?info.rate:0} edit={false} size={15} activeColor="#ffc12d" />
                                                                                <span>({info.review_count} reviews)</span>
                                                                            </div>
                                                                            {info.countryName ?
                                                                                <div className={'user_r_detail'}>
                                                                                    <i className="me-1"><MdLocationOn /></i>
                                                                                    {/* <img className={'flag'} src={DummyUser} alt="india" /> */}
                                                                                    <p className="p">{info.countryName}</p>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                        {info.skills.length > 0 ?
                                                                            <ul className={'s_listing'}>
                                                                                {info.skills.map((skill, i) =>
                                                                                    <li key={i}>{skill.skillName}</li>
                                                                                )}
                                                                            </ul>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>)
                                                    :
                                                    <li>No Found.</li>
                                            }
                                        </ul>
                                        {this.state.pageCount > 0 ?
                                            <div className={'pagination_wrap_div'}>
                                                <ReactPaginate
                                                    initialPage={page}
                                                    // forcePage={this.state.page}
                                                    pageCount={this.state.pageCount}
                                                    pageRangeDisplayed={4}
                                                    containerClassName={'pagination_wrap'}
                                                    breakLabel={'...'}
                                                    onPageChange={this.pageHandler}
                                                    activeClassName={'active'}
                                                    previousLabel={<MdKeyboardArrowLeft />}
                                                    previousClassName={'previous'}
                                                    nextLabel={<MdKeyboardArrowRight />}
                                                    nextClassName={'next'}
                                                />
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(connect(null)(FindFreelancerComponent))

// function FindFreelancerComponent() {

//     const router = useHistory();

//     const [isSmallWindow, setIsSmallWindow] = useState(false);
//     const [filterOpen, setFilterOpen] = useState(false);
//     // const [filterBy, setFilterBy] = useState(1)
//     const [expertise, setExpertise] = useState([])
//     const [expertiseOption, setExpertiseOption] = useState([])
//     const [tabActive, setTabActive] = useState(0);
//     const [search, setSearch] = useState('');
//     const [isSearch, setIsSearch] = useState(false);
//     const [page, setPage] = useState(0);
//     const [freelancerList, setFreelancerList] = useState([]);
//     const [imagePath, setImagePath] = useState('');

//     const pageHandler = (pageNo) => {
//         // console.log(pageNo);
//         if (pageNo.selected) {
//             setPage(pageNo.selected)
//             // getJobsList(expertise, pageNo.selected, search)
//         } else {
//             setPage(0)
//             // getJobsList(expertise, 0, search)
//         }
//         // setPage(page)
//     }

//     const tabHandler = (tab) => {
//         setTabActive(tab)
//     }

//     const searchHandler = (e) => {
//         const { value } = e.target;
//         setSearch(value);
//     }

//     const searchSubmit = (e) => {
//         e.preventDefault();
//         setIsSearch(!isSearch);
//         // console.log('working...')
//     }

//     // const filterByHandler = (val) => {
//     //     setFilterBy(val)
//     // }

//     const filterToggleHandler = () => {
//         setFilterOpen(!filterOpen)
//     }

//     const expertiseClickHandler = (val) => {
//         let selected = expertise;

//         if (selected.includes(val)) {
//             selected = selected.filter(info => info !== val);
//         } else {
//             selected = [...selected, val]
//         }

//         setExpertise(selected);
//     }

//     const viewProfileHandler = (id) => {
//         router.push(`/client/freelancer-profile/${id}`)
//     }

//     const getExpertise = async () => {

//         const categories = await mainCategories();
//         if (categories.length) {
//             const options = categories.map((info) => ({ label: info.categoryName, value: info.id }));
//             setExpertiseOption(options);
//         } else {
//             setExpertiseOption([]);
//         }
//         // console.log(res);
//     }

//     const checkWindowWidth = () => {
//         // console.log(window.innerWidth < 767);
//         setIsSmallWindow(window.innerWidth < 767)
//     }

//     useEffect(() => {
//         window.addEventListener('resize', checkWindowWidth)
//         getExpertise();
//         setIsSmallWindow(window.innerWidth < 767);
//         // setExpertiseOption(expertoptiosn)

//         const getFreelancerList = async () => {
//             const data = {
//                 categoryID: expertise,
//                 pageNo: page,
//                 searchText: search,
//                 tab: tabActive === 2 ? 'SAVED' : 'ALL'
//             }
//             const res = await findFreelancerList(data);
//             // console.log(res);
//             if (res.status) {
//                 setFreelancerList(res.freelancers)
//                 setImagePath(res.path)
//             } else {
//                 setFreelancerList([])
//             }
//         }

//         getFreelancerList();

//         return () => {
//             window.removeEventListener('resize', checkWindowWidth)
//         }

//     }, [expertise, isSearch, tabActive, page])

//     // console.log(page)

//     return (
//         <>
//             <HeaderComponent />
//             <section className={'freelancer_section'}>
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
//                             <div className={'filter_wrap'}>
//                                 <div className={'main_filter_div'}>
//                                     <h3>Filter By</h3>
//                                     {isSmallWindow ?
//                                         <div className={'filter_icon'} onClick={filterToggleHandler}>
//                                             <svg width="24" height="24" viewBox="0 0 24 24">
//                                                 <g id="filter_menu" transform="translate(-596 -221)">
//                                                     <rect id="Rectangle_10456" data-name="Rectangle 248" width="24" height="24" transform="translate(596 221)" fill="#fff" opacity="0"></rect>
//                                                     <g id="filter-results-button" transform="translate(596 148.5)">
//                                                         <g id="filter" transform="translate(0 76.5)">
//                                                             <path id="Path_1079" data-name="Path 1079" d="M9.333,92.5h5.333V89.833H9.333ZM0,76.5v2.667H24V76.5Zm4,9.333H20V83.167H4Z" transform="translate(0 -76.5)" fill="#1a62f5"></path>
//                                                         </g>
//                                                     </g>
//                                                 </g>
//                                             </svg>
//                                         </div>
//                                         :
//                                         null
//                                     }
//                                 </div>
//                                 <div className={filterOpen ? `collapse_filter active` : 'collapse_filter'}>
//                                     {/* <div className={`filter_radio division posting`}>
//                                         <button type="button" onClick={() => filterByHandler(1)} className={filterBy === 1 ? 'active' : ''}>Total Jobs</button>
//                                         <button type="button" onClick={() => filterByHandler(2)} className={filterBy === 2 ? 'active' : ''}>Total Rating</button>
//                                     </div> */}
//                                     {(expertiseOption.length > 0) ?
//                                         <div className={`main_area`}>
//                                             <h5>Expertise</h5>
//                                             <ul className={`radio_group posting`}>
//                                                 {expertiseOption.map((info, i) =>
//                                                     <li key={i}>
//                                                         <label className={expertise.includes(info.value) ? 'active' : ''} onClick={() => expertiseClickHandler(info.value)}>{info.label}</label>
//                                                     </li>
//                                                 )}
//                                             </ul>
//                                         </div>
//                                         :
//                                         null
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12">
//                             <div className={'result_wrap'}>
//                                 <ul className={'tabs_ul'}>
//                                     <li><button type="button" onClick={() => tabHandler(0)} className={tabActive === 0 ? 'active' : ''}>All</button></li>
//                                     {/* <li><button type="button" onClick={() => tabHandler(1)} className={tabActive === 1 ? pageStyle.active : ''}>My Hires</button></li> */}
//                                     <li><button type="button" onClick={() => tabHandler(2)} className={tabActive === 2 ? 'active' : ''}>Saved</button></li>
//                                 </ul>
//                                 <div className={'listing_section'}>
//                                     <form action="/" method="POST" onSubmit={searchSubmit}>
//                                         <div className={'form_search'}>
//                                             <input type="text" name="search" placeholder="Search for Freelancers" autoComplete="off" value={search} onChange={searchHandler} />
//                                             <button type="submit">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.4 12.4"><path className={'w_search'} d="M11.863,11.2,8.935,8.275a5.058,5.058,0,1,0-.66.66L11.2,11.863a.467.467,0,1,0,.66-.66ZM.933,5.051A4.117,4.117,0,1,1,5.051,9.168,4.122,4.122,0,0,1,.933,5.051Z" transform="translate(0.2 0.2)"></path></svg>
//                                             </button>
//                                         </div>
//                                     </form>
//                                     <ul className={'freelancers_ul'}>
//                                         {freelancerList.length > 0 ?
//                                             freelancerList.map((info, i) =>
//                                                 <li key={i}>
//                                                     <div className={`review_div freeList`}>
//                                                         <div className={'user_prof'}>
//                                                             {info.profilePic ?
//                                                                 <img src={`${imagePath}${info.profilePic}`} alt="user" />
//                                                                 :
//                                                                 <img src={DummyUser} alt="user" />
//                                                             }
//                                                         </div>
//                                                         <div className={'right_details'}>
//                                                             <div className={'caption_wrap'}>
//                                                                 <h5 className={'review_title'}>{info.name}</h5>
//                                                                 <div className={'btns'}>
//                                                                     <button type="button" className={`save_btn me-3`}><MdTurnedInNot /></button>
//                                                                     <button type="button" className={'btn btn-outline-dark me-3'} onClick={() => viewProfileHandler(info.id)}>View Profile</button>
//                                                                     <button type="button" className={'btn btn-dark'} style={{ minWidth: '130px' }}>Chat</button>
//                                                                 </div>
//                                                             </div>
//                                                             <div className={'detail'}>
//                                                                 {/* <p className={'price'}>$5<span> USD/Hour</span></p> */}
//                                                                 <p className={'summary'}>{info.bio}</p>
//                                                                 <div className={'user_review'}>
//                                                                     <div className={'rating_find'}>
//                                                                         <p className={'rating_badge'}>4.75</p>
//                                                                         <ReactStars count={5} value={3} edit={false} size={15} activeColor="#ffc12d" />
//                                                                         <span>(105 reviews)</span>
//                                                                     </div>
//                                                                     {info.countryName ?
//                                                                         <div className={'user_r_detail'}>
//                                                                             <i className="me-1"><MdLocationOn /></i>
//                                                                             {/* <img className={'flag'} src={DummyUser} alt="india" /> */}
//                                                                             <p className="p">{info.countryName}</p>
//                                                                         </div>
//                                                                         :
//                                                                         null
//                                                                     }
//                                                                 </div>
//                                                                 {info.skills.length > 0 ?
//                                                                     <ul className={'s_listing'}>
//                                                                         {info.skills.map((skill, i) =>
//                                                                             <li key={i}>{skill.skillName}</li>
//                                                                         )}
//                                                                     </ul>
//                                                                     :
//                                                                     null
//                                                                 }
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </li>)
//                                             :
//                                             <li>No Found.</li>
//                                         }
//                                     </ul>
//                                     <div className={'pagination_wrap'}>
//                                         <ReactPaginate
//                                             initialPage={page}
//                                             // forcePage={this.state.page}
//                                             pageCount={1}
//                                             pageRangeDisplayed={4}
//                                             breakLabel={'...'}
//                                             onPageChange={pageHandler}
//                                             activeClassName={'active'}
//                                             previousLabel={<MdKeyboardArrowLeft />}
//                                             previousClassName={'previous'}
//                                             nextLabel={<MdKeyboardArrowRight />}
//                                             nextClassName={'next'}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     )
// }

// export default withRouter(connect(null)(FindFreelancerComponent))
