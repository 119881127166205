import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import '../../assets/css/common.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { notifyError, notifySuccess } from '../../helper/Alert';
import SmallLoaderComponent from '../../helper/SmallLoaderComponent';
import { newPassword } from '../../controller/auth.controller';

class ChangePassComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            form: {
                email: '',
                password: '',
                cpassword: ''
            }
        }
    }

    formSubmit = async (values, e) => {
        try {

            const { email, password, cpassword } = values;
            
            const passwordToken = this.props.match.params.id;
            // const passwordToken = window.location.href.split('/').pop();

            this.setState({
                loader: true
            });

            const res = await newPassword({ email, password, cpassword, passwordToken });

            if (res.status) {

                const { history } = this.props;
                notifySuccess(res.message);

                this.setState({
                    loader: false,
                    form: {
                        ...this.state.form,
                        email: '',
                        password: '',
                        cpassword: ''
                    }
                });

                history.push('/login')

            } else {
                this.setState({
                    loader: false
                });
                notifyError(res.message);
            }

        } catch (err) {
            console.error(err.message);
        }
    }

    render() {

        const LoginSchema = Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Required'),
            password: Yup.string().required('Required'),
            cpassword: Yup.string().oneOf([Yup.ref("password")],"Both password need to be the same").required('Required')
            // cpassword: Yup.string().when("password", {
            //     is: val => (val && val.length > 0 ? true : false),
            //     then: Yup.string().oneOf(
            //         [Yup.ref("password")],
            //         "Both password need to be the same"
            //     )
            // }).required('Required')
        });

        return (
            <>
                <section className={`authWrapLog`}>
                    <div className="container">
                        <div className="row">
                            <div className={`col-sm-5 m-auto reset_bg`}>
                                <div className={'header_login'}>
                                    <h1 className={'title2'}>New password</h1>
                                    {/* <p className={commonStyle.subTi}>Enter your email address below and we will send you a link where you can enter a new password.</p> */}
                                </div>
                                <div className="login_wrap">
                                    <Formik initialValues={this.state.form} validationSchema={LoginSchema} onSubmit={(values) => this.formSubmit(values)}>
                                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (

                                            <form action="/" method="POST" onSubmit={handleSubmit}>
                                                <div className="form-group mb-4">
                                                    <input type="email" name="email" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                                    {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="password" name="password" className={errors.password && touched.password ? "form-control error" : "form-control"} placeholder="New password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                                    {errors.password && touched.password ? <p className="error-text">{errors.password}</p> : null}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="password" name="cpassword" className={errors.cpassword && touched.cpassword ? "form-control error" : "form-control"} placeholder="Confirm password" onChange={handleChange} onBlur={handleBlur} value={values.cpassword} />
                                                    {errors.cpassword && touched.cpassword ? <p className="error-text">{errors.cpassword}</p> : null}
                                                </div>

                                                <div className="form-group">
                                                    <button type="submit" aria-label="Submit" className="btn btn-primary btn-block" disabled={this.state.loader}>{this.state.loader?<SmallLoaderComponent />:'Submit'}</button>
                                                </div>
                                                <div className={'content_account'}>
                                                    <p>No_account? Join Freelancing as a <Link to="/signup">freelancer</Link> or <Link to="/signup">company</Link><span className={'copyRigt'}>© Copyright 2010 - 2021 Freelancing, LLC</span></p>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(connect(null)(ChangePassComponent));