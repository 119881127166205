import React, { useState, useEffect } from 'react'
import SmallLoaderComponent from '../../helper/SmallLoaderComponent';
import { apiHelper } from '../../helper/api.helper';
import { jwtDecode } from '../../helper/jwt.helper';
import { notifyError, notifySuccess } from '../../helper/Alert';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

function EmailVerify(props) {

    const [loader, setLoader] = useState(false)
    const resendEmailLink = async () => {
        const header = {
            'Authorization': localStorage.getItem('token')
        }
        setLoader(true);

        const token = localStorage.getItem('token');
        const decode = jwtDecode(token);

        if (decode) {
            let formData = new FormData();
            formData.append('name', decode.name)
            formData.append('email', decode.email)
            const response = await apiHelper('user/verify/resend', 'POST', formData, header);
            const res = response.data;
            if (res.status) {
                setLoader(false);
                notifySuccess(res.message)
            } else {
                setLoader(false);
                notifyError(res.message)
            }
        }
    }

    useEffect(() => {
        // console.log(router)
        const token = localStorage.getItem('token');
        const decode = jwtDecode(token);
        // console.log(dec)
        if (token) {
            if (parseInt(decode.status) === 1) {
                if (Number(decode.profileType) === 2) return props.history.push('/client/jobs');
                if (Number(decode.profileType) === 1) return props.history.push('/freelancer/jobs');
            }
        } else {
            props.history.push('/login');
        }

    }, [props])

    return (
        <>
            {/* <Head>
                <title>Vedain</title>
            </Head> */}
            <section className="emailVerify_section">
                <div className="emailVerify_wrap">
                    <h1>Verify your Email</h1>
                    <p>You’re almost there! We just sent an email to verify your account. You should receive it soon - be on the lookout!</p>
                    <button type="button" aria-label="Resend Verification Email" onClick={resendEmailLink} disabled={loader}>{loader ? <SmallLoaderComponent color="black" /> : 'Resend Verification Email'}</button>
                </div>
            </section>
        </>
    )
}

export default withRouter(connect(null)(EmailVerify))
