import React, { Component } from 'react';
import { notifyError } from '../../helper/Alert';

class ExamComponent extends Component {

    _unmounted = false;

    interValTimer = null

    constructor(props) {
        super(props);
        this.state = {
            questionList: [],
            currentQuestion: 0,
            examList: [],
            answerID: 0,
            questionID: 0,
            qsLoader: false,
            mins: props.mins,
            secs: 0,
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.setState({
            examList: [],
            questionList: this.props.questionList
        })
        this.interValTimer = setInterval(this.tick, 1000)
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    tick = (minutes, seconds) => {

        let mins = this.state.mins;
        let secs = this.state.secs

        if (mins === 0 && secs === 0) {
            clearInterval(this.interValTimer)
            const ReminingSecond = (mins * 60) + secs;
            // console.log(ReminingSecond);
            const data = {
                examArray: this.state.examList,
                time: ReminingSecond
            }
            this.props.submitExam(data);
            // mins = 1;
            // secs = 0;

        } else if (secs === 0) {
            mins = mins - 1;
            secs = 59;

        } else {
            mins = this.state.mins;
            secs = secs - 1;
        }

        // return { minutes: mins, seconds: secs }

        if (this._unmounted) {
            this.setState({
                mins: parseInt(mins),
                secs: parseInt(secs)
            })
        }
    };

    nextQuesFun = async (qid) => {
        const listQuestion = this.state.questionList;
        let examArray = this.state.examList;

        if (this.state.answerID > 0) {

            const foundIndex = examArray.findIndex(x => x.questionID === qid);
            // console.log(foundIndex);
            if (foundIndex > -1) {
                examArray[foundIndex].answerID = this.state.answerID
            } else {
                examArray.push({ questionID: qid, answerID: this.state.answerID })
            }

            const nextQuestion = this.state.currentQuestion + 1;

            if (nextQuestion !== listQuestion.length) {
                // console.log(examArray);
                if (this._unmounted) {

                    this.setState({
                        currentQuestion: nextQuestion,
                        examList: examArray,
                        answerID: 0,
                    })

                }

            } else {
                // console.log(examArray);
                clearInterval(this.interValTimer)
                if (this._unmounted) {
                    this.setState({
                        currentQuestion: 0,
                        examList: examArray,
                        answerID: 0,
                    })
                }

                const { mins, secs } = this.state;
                const ReminingSecond = (mins * 60) + secs;
                // console.log(ReminingSecond);
                const data = {
                    examArray: examArray,
                    time: ReminingSecond
                }

                this.props.submitExam(data)

            }

            // console.log(nextQuestion)
        } else {
            notifyError('Please select at least one answer')
        }
    }

    prevQuesFun = () => {
        const prevQuestion = this.state.currentQuestion - 1;
        const { examList, questionList } = this.state;
        const QuestionID = questionList[prevQuestion].id;
        const anserID = examList.find(x => x.questionID === QuestionID);
        // console.log(anserID);
        this.setState({
            currentQuestion: prevQuestion,
            answerID: anserID ? anserID.answerID : 0
        })
    }

    answerHandler = (aid, qid) => {
        this.setState({
            answerID: aid,
        })
    }

    render() {

        const { questionList, qsLoader, currentQuestion, answerID, mins, secs } = this.state;

        return (
            <div className="d-block p-5 h-50">
                <div className="tab_header">
                    <p className="d-flex align-items-center justify-content-between mb-0 " style={{ fontSize: '13px', opacity: '0.7' }}>
                        <span>Question {currentQuestion + 1} of {questionList.length}</span>
                        <span>Time Remaining: {mins}:{secs}</span>
                    </p>
                </div>
                {questionList.length > 0 ?
                    <div className={'tab_body'}>
                        <p><b className="fw-bold me-2">Q{currentQuestion + 1}.</b>{questionList[currentQuestion].question}</p>
                        <ul className="list-group list-group-flush">
                            {questionList[currentQuestion].answers.length > 0 ?
                                questionList[currentQuestion].answers.map((info, i) =>
                                    <li key={i} onClick={() => this.answerHandler(info.id)} className="list-group-item border-0 ps-0 d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                        <span className={answerID === info.id ? "check_box active" : "check_box"}></span>{info.answerText}
                                    </li>)
                                :
                                null
                            }
                        </ul>
                    </div>
                    :
                    null
                }
                <div className={'tab_footer'}>
                    {/* {this.state.isFinishTest ? */}
                    {/* // <button type="button" className={'btn btn-dark'} style={{ minWidth: '100px' }} onClick={nextHandler}>Next</button>
                    // : */}
                    <button type="button" className={'btn btn-success'} style={{ minWidth: '100px' }} onClick={() => this.nextQuesFun(questionList[currentQuestion].id)} disabled={qsLoader}>{qsLoader ? 'Sending...' : 'Submit'}</button>
                    {/* } */}
                    {currentQuestion > 0 ? <button type="button" className="btn btn-outline-dark" style={{ minWidth: '100px' }} onClick={this.prevQuesFun}>Prev</button> : null}
                </div>
            </div>
        )
    }
}

export default ExamComponent

// export default function ExamComponent(props) {

//     const [questionList, setQuestionList] = useState([])
//     const [examList, setExamList] = useState([])
//     const [stopTimer, setStopTimer] = useState(false);
//     const [qsLoader, setQsLoader] = useState(false);
//     const [secs, setSecs] = useState(0)
//     const [mins, setMins] = useState(0)
//     const [currentQuestion, setCurrentQuestion] = useState(0);
//     const [answerID, setAnswerID] = useState(0);
//     // const [totalTime, setTotalTime] = useState(1800)

//     const nextQuesFun = async (qid) => {
//         const listQuestion = questionList;
//         let examArray = examList;

//         if (answerID > 0) {

//             const foundIndex = examArray.findIndex(x => x.questionID === qid);
//             // console.log(foundIndex);
//             if (foundIndex > -1) {
//                 examArray[foundIndex].answerID = answerID
//             } else {
//                 examArray.push({ questionID: qid, answerID: answerID })
//             }

//             const nextQuestion = currentQuestion + 1;

//             if (nextQuestion !== listQuestion.length) {
//                 setCurrentQuestion(nextQuestion);
//                 setExamList(examArray);
//                 setAnswerID(0);

//             } else {
//                 // console.log(examArray);
//                 // clearInterval(tick);
//                 setStopTimer(true);
//                 setCurrentQuestion(0);
//                 setExamList(examArray)
//                 setAnswerID(0);

//                 const ReminingSecond = (mins * 60) + secs;
//                 // console.log(ReminingSecond);
//                 const data = {
//                     examArray: examArray,
//                     time: ReminingSecond
//                 }
//                 // props.finishHandler(true);
//                 props.submitExam(data)
//             }

//         } else {
//             notifyError('Please select at least one answer')
//         }
//     }

//     const prevQuesFun = () => {
//         const prevQuestion = currentQuestion - 1;
//         // const { examList, questionList } = this.state;
//         const QuestionID = questionList[prevQuestion].id;
//         const anserID = examList.find(x => x.questionID === QuestionID);
//         // console.log(anserID);
//         setCurrentQuestion(prevQuestion);
//         setAnswerID(anserID ? anserID.answerID : 0)
//     }

//     const answerHandler = (aid, qid) => {
//         setAnswerID(aid);
//     }

//     const tick = () => {

//         let mintues = mins;
//         let seconds = secs

//         if (mintues === 0 && seconds === 0) {

//             mintues = 30;
//             seconds = 0;

//         } else if (seconds === 0) {
//             mintues = mintues - 1;
//             seconds = 59;

//         } else {
//             mintues = mins;
//             seconds = seconds - 1;
//         }

//         // return { minutes: mins, seconds: secs }
//         setMins(mintues)
//         setSecs(seconds)
//     };


//     useEffect(() => {
//         setQuestionList(props.questionList)
//         // setMins(props.mins);



//         if (!stopTimer) {
//             setInterval(tick, 1000)
//         }
//         if (stopTimer) {
//             return clearInterval(tick);
//         }

//     }, [props.questionList, props])

//     return (
//         <div className="d-block p-5 h-50">
//             <div className="tab_header">
//                 <p className="d-flex align-items-center justify-content-between mb-0 mt-2" style={{ fontSize: '13px', opacity: '0.7' }}>
//                     <span>Question {currentQuestion + 1} of {questionList.length}</span>
//                     <span>Time Remaining: {mins}:{secs}</span>
//                 </p>
//             </div>
//             {questionList.length > 0 ?
//                 <div className={'tab_body'}>
//                     <p><b className="fw-bold me-2">Q{currentQuestion + 1}.</b>{questionList[currentQuestion].question}</p>
//                     <ul className="list-group list-group-flush">
//                         {questionList[currentQuestion].answers.length > 0 ?
//                             questionList[currentQuestion].answers.map((info, i) =>
//                                 <li key={i} onClick={() => answerHandler(info.id)} className="list-group-item border-0 ps-0 d-flex align-items-center" style={{ cursor: 'pointer' }}>
//                                     <span className={answerID === info.id ? "check_box active" : "check_box"}></span>{info.answerText}
//                                 </li>)
//                             :
//                             null
//                         }
//                     </ul>
//                 </div>
//                 :
//                 null
//             }
//             <div className={'tab_footer'}>
//                 {/* {this.state.isFinishTest ? */}
//                 {/* // <button type="button" className={'btn btn-dark'} style={{ minWidth: '100px' }} onClick={nextHandler}>Next</button>
//                     // : */}
//                 <button type="button" className={'btn btn-dark'} style={{ minWidth: '100px' }} onClick={() => nextQuesFun(questionList[currentQuestion].id)} disabled={qsLoader}>{qsLoader ? 'Sending...' : 'Submit'}</button>
//                 {/* } */}
//                 {currentQuestion > 0 ? <button type="button" className="btn btn-outline-dark" style={{ minWidth: '100px' }} onClick={prevQuesFun}>Prev</button> : null}
//             </div>
//         </div>
//     )
// }
