import { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../assets/css/common.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { login } from '../../controller/auth.controller';
import { notifyError, notifySuccess } from '../../helper/Alert';
import SmallLoaderComponent from '../../helper/SmallLoaderComponent';
import { jwtDecode } from '../../helper/jwt.helper';
// import localStorage from 'localStorage';

class LoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            form: {
                email: '',
                password: ''
            }
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        const { history } = this.props;
        const token = localStorage.getItem('token');
        if(token){
            const decode = jwtDecode(token);

            if (parseInt(decode.profileType) === 1) {
                    
                history.push('/freelancer/jobs')
            } else if (parseInt(decode.profileType) === 2) {
                
                history.push('/client/my-jobs')
            }
        }
    }

    formSubmit = async (e) => {
        try {
            const { email, password } = e;
            this.setState({
                loader: true
            });

            const res = await login({ email, password });

            // console.log(res);
            if (res.status) {

                const { history } = this.props;

                localStorage.setItem('token', res.data);
                const decode = jwtDecode(res.data);
                // console.log(decode);
                this.setState({
                    loader: false,

                });
                
                if (parseInt(decode.profileType) === 1) {
                    
                    history.push('/freelancer/jobs')
                } else if (parseInt(decode.profileType) === 2) {
                    
                    history.push('/client/my-jobs')
                }
                
                notifySuccess(res.message);

                

            } else {
                this.setState({
                    loader: false
                });
                notifyError(res.message);
            }

        } catch (err) {
            console.error(err.message);
        }
    }

    render() {

        const LoginSchema = Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Email must be required'),
            password: Yup.string().required('Password must be required')
        });


        return (
            <>
                <section className={`authWrapLog`}>
                    <div className="container">
                        <div className="row">
                            <div className={`col-sm-5 m-auto reset_bg`}>
                                <div className={'header_login'}>
                                    <h1 className={'title'}>Log in</h1>
                                </div>
                                <div className="login_wrap">

                                    <Formik initialValues={this.state.form} validationSchema={LoginSchema} onSubmit={(values) => this.formSubmit(values)}>
                                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (

                                            <form action="/" method="POST" onSubmit={handleSubmit}>

                                                <div className="form-group mb-4">
                                                    <input type="email" name="email" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                                    {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="password" name="password" className={errors.password && touched.password ? "form-control error" : "form-control"} placeholder="Password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                                    {errors.password && touched.password ? <p className="error-text">{errors.password}</p> : null}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <div className="d-flex flex-row-reverse">
                                                        <Link to="/forgot-password">Forgot Passowrd?</Link>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" aria-label="Log in" className="btn btn-primary btn-block" disabled={this.state.loader}>{this.state.loader ? <SmallLoaderComponent /> : 'Log in'}</button>
                                                </div>
                                                <div className={'content_account'}>
                                                    <p>No_account? Join Freelancing as a <Link to="/signup">freelancer</Link> or <Link to="/signup">company</Link><span className={'copyRigt'}>© Copyright 2010 - 2021 Vedain LLC</span></p>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(connect(null)(LoginComponent));

// class LoginComponent extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             email: '',
//             password: '',
//             error: {
//                 email: '',
//                 password: ''
//             },
//             valid: {
//                 email: false,
//                 password: false
//             }
//         }
//     }

//     inputHandler = (e) => {
//         const { name, value } = e.target;
//         // let email = '';
//         // let password = '';
//         // if (name === 'email') email = value
//         // if (name === 'password') password = value
//         this.setState({
//             [name]: value
//         },()=>this.checkValidation(name, value))

//     }

//     checkValidation = (name, value) => {

//         let error = this.state.error;
//         let valid = this.state.valid;

//         const reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

//         switch (name) {
//             case 'password':
//                 valid.password = value.length > 0;
//                 error.password = valid.password ? '' : 'Required'
//                 break;
//             case 'email':
//                 if (value.length > 0) {
//                     if (reg.test(value)) {
//                         valid.email = true;
//                         error.email = ''
//                     } else {
//                         valid.email = false;
//                         error.email = 'Email not valid'
//                     }
//                 } else {
//                     valid.email = false;
//                     error.email = 'Required';
//                 }
//                 break;

//             default:
//                 break;
//         }

//         this.setState({
//             error,
//             valid
//         })

//         // setFormError({ ...formError, error })

//         // setFormValid({ ...formValid, valid })
//     }


//     submitForm = async (event) => {
//         // try {
//         const { email, password } = this.state;
//         event.preventDefault();
//         // setLoader(true);

//         const res = await login({email, password});
//         console.log(res);

//         if (res.status) {

//             localStorage.setItem('token', res.data);
//             const decode = jwtDecode(res.data);
//             console.log(decode);
//             // if (Number(decode.profileType) === 1) {
//             //     router.push('/')
//             // } else if (Number(decode.profileType) === 2) {
//             //     router.push('/client/jobs')
//             // }

//             notifySuccess(res.message);
//             this.setState({
//                 loader:false,
//                 email:'',
//                 password:'',
//                 error:{
//                     email:'',
//                     password:''
//                 },
//                 valid:{
//                     email:false,
//                     password:false
//                 }
//             });
//             // setFormLogin({ email: '', password: '' })

//         } else {
//             // setLoader(false);
//             this.setState({
//                 loader:false
//             });
//             notifyError(res.message);
//         }

//         // } catch (err) {
//         //     console.error(err.message);
//         //     setLoader(false);
//         //     notifyError(err.message);
//         // }
//     }

//     // const LoginSchema = Yup.object().shape({
//     //     email: Yup.string().email('Invalid email').required('Email must be required'),
//     //     password: Yup.string().required('Password must be required')
//     // });
//     render() {

//         const { error, email, password, loader } = this.state;

//         return (
//             <>
//                 <section className={`${commonStyle.authWrapLog}`}>
//                     <div className="container">
//                         <div className="row">
//                             <div className={`col-sm-5 m-auto ${commonStyle.reset_bg}`}>
//                                 <div className={commonStyle.header_login}>
//                                     <h1 className={commonStyle.title}>Log in</h1>
//                                 </div>
//                                 <div className="login_wrap">
//                                     {/* <Formik initialValues={form} validationSchema={LoginSchema} onSubmit={(values, e, f) => formSubmit(values, e,f)}>
//                                     {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => ( */}

//                                     <form action="#/" method="POST" onSubmit={(e)=>{e.preventDefault(); this.submitForm(e)}}>

//                                         <div className="form-group mb-4">
//                                             <input type="email" name="email" className={error.email !== '' ? "form-control error" : "form-control"} placeholder="Email" onChange={this.inputHandler} value={email} />
//                                             {error.email !== '' ? <p className="error-text">{error.email}</p> : null}
//                                         </div>
//                                         <div className="form-group mb-4">
//                                             <input type="password" name="password" className={error.password !== '' ? "form-control error" : "form-control"} placeholder="Password" onChange={this.inputHandler} value={password} />
//                                             {error.password !== '' ? <p className="error-text">{error.password}</p> : null}
//                                         </div>
//                                         <div className="form-group mb-4">
//                                             <div className="d-flex flex-row-reverse">
//                                                 <Link href="/forgot-password"><a>Forgot Passowrd?</a></Link>
//                                             </div>
//                                         </div>
//                                         <div className="form-group">
//                                             <button type="submit" aria-label="Log in" className="btn btn-primary btn-block" disabled={loader}>{loader ? <SmallLoaderComponent /> : 'Log in'}</button>
//                                         </div>
//                                         <div className={commonStyle.content_account}>
//                                             <p>No_account? Join Freelancing as a <Link href="/signup"><a>freelancer</a></Link> or <Link href="/signup"><a>company</a></Link><span className={commonStyle.copyRigt}>© Copyright 2010 - 2021 Vedain LLC</span></p>
//                                         </div>
//                                     </form>
//                                     {/* )}
//                                 </Formik> */}

//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//             </>
//         )
//     }
// }
// export default withRouter(LoginComponent);
