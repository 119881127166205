import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { notifyError } from '../../../helper/Alert';
import { apiHelper } from '../../../helper/api.helper';
import { submitProfile } from '../../../controller/profile.controller';
import * as ACTION_TYPES from '../../../redux/actions/type';

class PreviewProfileComponent extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    componentDidUpdate(prevProps) {

        const { isGetProfile, isCallProfile } = this.props;

        if (prevProps.isGetProfile !== isGetProfile) {
            if (isGetProfile) {
                isCallProfile(false)
            }
        }
    }

    prevHandler = () => {
        const { activeStep, stepHandler } = this.props;
        stepHandler(activeStep - 1)
    }

    nextHandler = async () => {

        const res = await submitProfile();
        if (res.status) {
            if (parseInt(res.status) === 1 || parseInt(res.status) === 3 || parseInt(res.status) === 4) {
                this.props.history.push('/freelancer/jobs');
                const { activeStep, stepHandler } = this.props;
                stepHandler(activeStep + 1)
                return true;
            }
        } else {
            notifyError(res.message);
        }
    }

    render() {

        return (
            <div className={'tabs_wrap'}>
                <div className={'tab_header'}>
                    <h1 className={'tabs_title'}>{`Preview profile`}</h1>
                </div>
                <div className={'tab_body'}>
                    <h2 className={`${'body_title'} fs-6`}>Looking good, Harry!</h2>
                    <p>Make any necessary edits and then submit your profile. You can still edit it after you submit it.</p>

                </div>
                <div className={'tab_footer'}>
                    <button type="button" className={'btn btn-dark'} style={{ minWidth: '100px' }} onClick={this.nextHandler}>Submit Profile</button>
                    {this.props.activeStep > 0 ? <button type="button" className="btn btn-outline-dark" style={{ minWidth: '100px' }} onClick={this.prevHandler}>Back</button> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    // expertise:state.pinfo.expertise,
    activeStep: state.pinfo.activeStep,
    isGetProfile: state.pinfo.isGetProfile
});

const mapDispatchToProps = dispatch => ({
    stepHandler: (val) => dispatch({ type: ACTION_TYPES.STEP_INFO_FREELANCER, payload: val }),
    isCallProfile: (bool) => dispatch({ type: ACTION_TYPES.PROFILE_SETP_API_GET, payload: bool })
    // expertiseSubmit:(obj)=>dispatch({type:ACTION_TYPES.EXPERTISE_MODULE, payload:obj})
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewProfileComponent));