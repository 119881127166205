import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import payment from '../../../controller/payment.controller';
import { notifyError, notifySuccess } from '../../../helper/Alert';
// import { FiTrash2 } from 'react-icons/fi';
// import ConfirmModal from '../../../components/confirmModal';

class GetPaid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountList: [],
            loaderModal: false,
            isGetPaidModal: false,
            details: null,
            selectedPaymentMethod: null,
            amount: 0,
            amountError: '',
            amountValid: false
        }
    }

    componentDidMount() {
        this.getBalaceApi();
        this.getAccountList();
    }

    getBalaceApi = async () => {
        const res = await payment.getFreelanceBalance();
        // console.log(res)
        if (res.status) {
            this.setState({
                details: res.data
            })
        } else {
            this.setState({
                details: null
            })
        }
    }

    getAccountList = async () => {
        const res = await payment.getPaymentAccountList();
        if (res.status) {
            let data = [];
            if (res.data && res.data.length > 0) {
                data = res.data.map(info => ({ id: info.id, label: info.account }))
            }
            this.setState({
                accountList: data
            })
        } else {
            this.setState({
                accountList: []
            })
        }
    }

    closeGetPaidModal = () => {
        this.setState({
            isGetPaidModal: false,
            selectedPaymentMethod: null,
            amount: 0,
            amountError: '',
            amountValid: false
        })
    }

    openGetPaidModal = () => {
        this.setState({
            isGetPaidModal: true
        })
    }

    inputModalHandler = (e) => {
        const { value } = e.target;
        let error = '';
        let valid = false;
        if (value > 0) {
            error = '';
            valid = true;
        } else {
            error = 'Amount not valid';
            valid = true;
        }

        this.setState({
            amount: value,
            amountError: error,
            amountValid: valid
        })
    }

    paymentAccountHandler = (selectedOption) => {
        this.setState({
            selectedPaymentMethod: selectedOption
        })
    }

    submitAddPayment = async (e) => {
        e.preventDefault();
        if (!this.state.selectedPaymentMethod) {
            return notifyError('Please select payment method!')
        }

        if (!this.state.amountValid) {
            return notifyError('Amount is required!')
        }

        this.setState({
            loaderModal: true
        });
        const data = {
            requestedAmount: this.state.amount,
            accountId: this.state?.selectedPaymentMethod?.id
        }

        const res = await payment.sendWithdrawRequest(data);
        if (!res.status) {
            this.setState({
                loaderModal:false
            })
            return notifyError(res.message)
        }
        this.getBalaceApi();
        notifySuccess(res.message)
        this.setState({
            loaderModal: false,
            isGetPaidModal: false,
            selectedPaymentMethod: null,
            amount: 0,
            amountError: '',
            amountValid: false
        })


    }

    render() {

        const { loaderModal, accountList, details } = this.state;

        return (
            <>
                <h1 className="fs-3 fw-bold mb-4">Get Paid</h1>
                <div className="card mb-5">
                    <div className="card-header d-flex justify-content-between align-items-center border-bottom pt-4 pb-4">
                        <h2 className="fs-4 fw-bold mb-0">Balance</h2>
                        {/* <button type="button" className="btn btn-outline-dark rounded-pill" onClick={this.openAddModal}>Add Method</button> */}
                    </div>
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0">Your balance is <b>${details && details.totalAmount ? details.totalAmount : 0}</b></p>
                            <button type="button" className="btn btn-success" onClick={this.openGetPaidModal}>Get Paid Now</button>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center border-bottom pt-4 pb-4">
                        <h2 className="fs-4 fw-bold mb-0">Payment method</h2>
                        {/* <button type="button" className="btn btn-outline-dark rounded-pill" onClick={this.openAddModal}>Add Method</button> */}
                    </div>
                    <div className="card-body">
                        {accountList && accountList.length > 0 ?
                            <div className="table-responsive">
                                <table className="table-getpaid table table-hovered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Account</th>
                                            {/* <th className="text-center">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accountList.map((info, i) =>
                                            <tr key={i}>
                                                <td>PayPal - {info?.label}</td>
                                                {/* <td className="text-center"><button type="button" className="border-0 bg-transparent text-danger" onClick={() => this.openConfirmModal(info)}><FiTrash2 /></button></td> */}
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div>
                                <h6 className="fs-5 fw-bold mb-4">You have not set up any payment methods yet.</h6>
                                <p className="mb-0 fs-6 fw-normal">Tell us how you want to receive your funds. It may take up to 3 days to activate your payment method.</p>
                            </div>
                        }
                    </div>
                </div>
                {this.state.isGetPaidModal ?
                    <Modal show={this.state.isGetPaidModal} onHide={this.closeGetPaidModal} centered>
                        {/* <Modal.Header>
                    </Modal.Header> */}
                        <Modal.Body className="p-5">
                            <Modal.Title>Get Paid Now</Modal.Title>
                            <form action="/" method="POST" className="mt-5" onSubmit={this.submitAddPayment}>
                                <div className="form-group mb-4">
                                    <label className="form-label">Account</label>
                                    <Select
                                        options={this.state.accountList}
                                        value={this.state.selectedPaymentMethod}
                                        onChange={this.paymentAccountHandler}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Withdrawal amount</label>
                                    <input type="number" className="form-control" name="amount" value={this.state.amount} onChange={this.inputModalHandler} />
                                </div>
                                <div className="form-group mt-5">
                                    <button type="submit" className="btn btn-success" style={{ minWidth: '100px' }} disabled={loaderModal}>{loaderModal ? <SmallLoaderComponent color="white" /> : 'Submit'}</button>
                                    <button type="button" className="btn border-0 bg-transparent" onClick={this.closeGetPaidModal}>Close</button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                    :
                    null
                }
            </>
        )
    }
}

export default GetPaid