import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MdSearch } from 'react-icons/md'
import { jwtDecode } from '../../helper/jwt.helper';

import { io } from "socket.io-client";
import { CHAT_URL } from '../../controller/chat.controller';

const SERVER = CHAT_URL;

class ChannelList extends Component {

    _unmounted = false;

    socket;

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            channelList: [],
            searchList: [],
            profileType: 0,
            userID: 0,
        }
    }

    componentDidMount() {
        this._unmounted = true;
        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode && decode.email) {
                this.channelListUpdateFunction();
                if (this._unmounted) {
                    this.setState({
                        profileType: decode.profileType,
                        userID: decode.id,
                        // channelList: this.props.channelList,
                        // searchList: this.props.channelList,
                    })
                }

            }
        }
        this.configureSocket();

    }

    componentWillUnmounted() {
        this._unmounted = false;
    }

    channelListUpdateFunction = () => {
        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token)

            let listChannel = this.props.channelList;
            // console.log(listChannel);
            let list = [];
            // let names = "";
            if (Array.isArray(listChannel) && listChannel.length > 0) {

                list = listChannel.map(info => {
                    if (!info.name) {
                        const chatMembers = info ? info.chat_member_names : []
                        if (Array.isArray(chatMembers) && chatMembers.length > 0) {
                            const users = chatMembers.filter(user => user.id !== decode.id)
                            info.name = users.length > 0 ? users.map(user => user.name).join(',') : "";
                            // list = users[0];
                        }
                    }
                    // console.log(info);
                    return info;
                    // const chatMembers = JSON.parse(info.chat_member_names)

                })
                // console.log(list);
                

            }else{
                list = []
            }

            if(this._unmounted){
                this.setState({
                    channelList:list,
                    searchList:list
                })
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { channelList, isAdded, itsDeteled } = this.props;
        if (prevProps.itsDeteled !== itsDeteled) {
            if (itsDeteled) {
                this.channelListUpdateFunction();
                // if (this._unmounted) {
                //     this.setState({
                //         channelList: channelList,
                //         searchList: channelList,
                //     })
                // }
            }
        }

        if (prevProps.isAdded !== isAdded) {
            if (isAdded) {
                if (this._unmounted) {
                    this.setState({
                        channelList: channelList,
                        searchList: channelList,
                    })
                }
            }
        }

        if (prevProps.channelList.length !== channelList.length) {

            if (this._unmounted) {
                this.setState({
                    channelList: channelList,
                    searchList: channelList,
                })
            }
        }
    }

    configureSocket = () => {
        var socket = io(SERVER, { secure: true, rememberUpgrade: true });
        
        socket.on('connection', () => {
            // console.log('socket is connected!')
        });

        socket.on('memberRemovedFromGroup', data => {
            // console.log('memberRemovedFromGroup');
            // console.log(data);
            let selectedData = this.state.channelList;
            if (Array.isArray(selectedData) && selectedData.length > 0) {
                const index = selectedData.findIndex(info => info.id === data.id);
                selectedData[index] = data;
                // console.log(selectedData);
                if (this._unmounted) {
                    this.setState({
                        channelList: selectedData,
                        removeMemberGroups: data
                    })
                }
            }

        })

        socket.on('userOnlineNotifyEmit', user => {
            // console.log(user)
            const list = this.props.channelList;
            if (Array.isArray(list) && list.length > 0) {
                list.forEach(element => {
                    element.chat_member_names.forEach(item => {
                        if (item.id === user.id) {
                            item.onlineStatus = user.onlineStatus
                        }
                    })
                });
                if (this._unmounted) {
                    this.setState({
                        channelList: list,
                        searchList: list,
                    })
                }
                // console.log(list);
            }
        })

        socket.on('conversationNameChangeNotifyEmit', data => {
            // console.log(data);
            // if (this.props.activeChannelID === data.conversation_id) {
            let selectedData = this.props.channelList;
            if (Array.isArray(selectedData) && selectedData.length > 0) {

                selectedData.forEach(info => {
                    if (info.id === data.conversation_id) {
                        info.name = data.conversation_name
                    }
                })
                if (this._unmounted) {
                    this.setState({
                        channelList: selectedData,
                        searchList: selectedData
                    })
                }
            }
            // console.log(selectedData);


            // }
        });


        socket.on('userOfflineNotifyEmit', (message, user) => {
            // console.log(user)
            const list = this.props.channelList;
            if (Array.isArray(list) && list.length > 0) {
                list.forEach(element => {
                    element.chat_member_names.forEach(item => {
                        if (item.id === user.id) {
                            item.onlineStatus = user.onlineStatus
                        }
                    })
                });
                // console.log(list);
                if (this._unmounted) {
                    this.setState({
                        channelList: list,
                        searchList: list,
                    })
                }
            }
        })

        this.socket = socket;
    }

    searchHandler = (e) => {
        const { value } = e.target;
        const channelList = this.props.channelList;

        let searchArray = [];
        if (value !== '') {
            if (Array.isArray(channelList) && channelList.length > 0) {
                searchArray = channelList.filter((info) => info.name.toLowerCase().includes(value.toLowerCase()))
                // console.log(searchArray);
                
            }
        } else {
            searchArray = this.props.channelList;
        }


        if (this._unmounted) {
            this.setState({
                search: value,
                searchList:searchArray
            })
        }
        // setSearch(value);
    }

    searchSubmit = (e) => {
        e.preventDefault();
    }

    renderChannelList = (info, i) => {
        // console.log(info);
        let list = null;
        let names = "";
        // const chatMembers = JSON.parse(info.chat_member_names)
        const chatMembers = info ? info.chat_member_names : []
        if (Array.isArray(chatMembers) && chatMembers.length > 0) {
            const users = chatMembers.filter(user => user.id !== this.state.userID)
            if (info.type !== 'SINGLE' && (info.name !== null && info.name !== "")) {
                names = info.name;
            } else {
                names = users.length > 0 ? users.map(user => user.name).join(',') : "";
            }
            list = users[0];
        }
        return (
            <div className={`${'chat_list'} ${parseInt(this.props.activeChannelID) === info.id ? 'active_chat' : ''}`} key={i} onClick={() => this.props.onselectchannel(info)}>
                <div className={'chat_people'}>
                    <div className="user-profil">
                        <div className={`chat_img_wrap ${list && list.onlineStatus === 1 ? 'active' : ''}`}>
                            <div className={'chat_img'}>
                                {info.type !== 'SINGLE' ?
                                    <span className="shape">{names !== null && names !== "" ? names.charAt(0).toUpperCase() : ""}</span>
                                    :
                                    info.profileURL && list && list.profile_pic ?
                                        <img className={'img'} src={`${info.profileURL}${list.profile_pic}`} alt="user" />
                                        :
                                        <span className="shape">{names !== "" ? names.charAt(0).toUpperCase() : ""}</span>

                                }
                            </div>
                        </div>
                        <div className={'chat_ib'}>
                            <h5>{names}
                                {/* <span className={'chat_date'}>Dec 25</span> */}
                            </h5>
                            {/* <p>Test, which is a new approach to have all solutions
                            astrology under one roof.</p> */}
                        </div>
                    </div>
                    {info.unread_messages > 0 ?
                        <div className="badge rounded-pill notification-icon">{info.unread_messages}</div>
                        :
                        null
                    }
                </div>
            </div>
        )
    }

    render() {

        const { search, searchList } = this.state;
        // console.log(searchList);

        return (
            <div className={'inbox_people'}>
                <div className={'headind_srch'}>
                    {/* <div className={'recent_heading'}>
                                    <h4>Message</h4>
                                </div> */}
                    <div className={'srch_bar'}>
                        <div className={'stylish_input_group'}>
                            <input type="text" className={'search_bar'} placeholder="Search" value={search} onChange={this.searchHandler} />
                            <span className={'input_group_addon'}>
                                <button type="button" onClick={this.searchSubmit}> <i><MdSearch /></i> </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'inbox_chat'}>
                    {Array.isArray(searchList) && searchList.length > 0 ?
                        searchList.map((info, i) => this.renderChannelList(info, i))
                        :
                        null
                    }

                </div>
            </div >
        )
    }
}

export default withRouter(connect(null)(ChannelList))
