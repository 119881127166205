import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { MdLocationOn, MdSearch, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import DummyUser from '../../../assets/images/dummy-user.jpg'
import { jwtDecode } from '../../../helper/jwt.helper';
import ReactStars from "react-rating-stars-component";
import contractService from '../../../controller/contract.controller';
import '../../../assets/css/jobs.css'
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import moment from 'moment';

class AllContracts extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            mainArray: [],
            page: 0,
            pageCount: 0,
            isLoader: false,
            path: ''
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getContractList();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getContractList = async () => {
        if (this._unmounted) {
            this.setState({
                isLoader: true
            })
        }

        const res = await contractService.getClientContract();
        // console.log(res);
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    isLoader: false,
                    mainArray: res.data.data,
                    path: res.data.paht
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    isLoader: false
                })
            }
        }
    }

    pageHandler = (page) => {
        if (page.selected) {

            this.setState({
                page: page.selected
            })

        } else {
            this.setState({
                page: page.selected
            })
        }
    }

    searchHandler = (e) => {

        const search = e.target.value;

        this.setState({
            search
        })

    }

    searchSubmit = (e) => {
        e.preventDefault();
        // this.getJobsList(this.state.activeTab, this.state.page, this.state.search)
    }

    viewDetails = (info) => {
        // console.log(info);
        this.props.history.push(`/client/my-contracts/${info.id}`)
        // this.props.history.push(`/client/my-contracts/${info.jobPostID}`)
    }

    renderJobList = (info, i) => {
        // console.log(info);
        // let statusClass = `btn_status purple`;
        // switch(info.jobTypeID){
        //     case 
        //     default:
        //         break;
        // }

        return <li key={i}>
            {/* <Link to={`/client/all-jobs/${1}`}> */}
            <div className={'projectList_div d-flex flex-sm-column align-items-start justify-content-lg-between'}>
                <div className={'left_wrap'}>
                    <h5 className={'job_title'}>Expert in Server admin(AWS)</h5>
                    <div className={'details_text'}>
                        <p className={'job_description'}>Created 7 months ago by You</p>
                        <p className={'job_description'}>Closed - Dec 22, 2020</p>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <p>0</p>
                        <p>Proposals</p>
                    </div>
                    <div>
                        <p>0</p>
                        <p>Messaged</p>
                    </div>
                    <div>
                        <p>0</p>
                        <p>Hired</p>
                    </div>
                </div>
                {/* <div className={right_wrap}></div> */}
            </div>
            {/* </Link> */}
        </li>
    }

    contractTrRendering = (info, i) => {

        var status = '';

        switch (info.jobPostStateID) {
            case 1:
                status = 'Bidding'
                break;
            case 2:
                status = 'In Progress'
                break;
            case 3:
                status = 'Pending'
                break;
            case 4:
                status = `Completed ${info?.completedAt ? moment(info.completedAt).format('DD MMM, YYYY') : ''}`
                break;
            case 5:
                status = 'Cancelled'
                break;
            case 6:
                status = 'Refunded'
                break;
            case 7:
                status = 'Accepted'
                break;
            default:
                break;
        }

        return <tr key={i}>
            <td>
                <div className="user-wrap-block d-flex">
                    <div className="image_wrap">
                        {info?.profilePic ?
                            <img src={`${this.state.path}${info.profilePic}`} alt="user" style={{ width: '100%' }} />
                            :
                            <img src={DummyUser} alt="user" style={{ width: '100%' }} />
                        }
                    </div>
                    <div>
                        <h5 className={'job_title title'}>{info.title}</h5>
                        <div className={'details_text'}>
                            <p className={'flag_text'}><span>{info?.freelancerName}</span> <span className="country"> <MdLocationOn />{info.countryName}</span></p>
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <p className="mb-0">
                    <strong>${info?.amount}</strong> Budget
                    <span className="d-block">{status}</span>
                    {/* {info?.jobPostStateID === 4 ?

                        <span className="d-block">
                            <ReactStars count={5} value={3} edit={false} size={20} activeColor="#ffc12d" />
                        </span>

                        :
                        null
                    } */}

                </p>
            </td>
            <td className="text-end">
                <button type="button" className="btn btn-success" onClick={() => this.viewDetails(info)}>View Details</button>
            </td>
        </tr>
    }

    render() {

        const { isLoader, mainArray } = this.state;

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 1) {
                return <Redirect to="/freelancer/jobs" />
            }
        }

        return (
            <section className={`jobs_wrap`}>
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <h1 className="page-title">Contracts</h1>
                        </div>
                    </div>

                    <div className={'jobs_div all-contract'}>
                        {/* <div className={'search_jobs'}>
                            <form action="/" method="POST" onSubmit={this.searchSubmit}>
                                <div className="form-group">
                                    <input type="text" className={`form-control search_input`} name="search" value={search} onChange={this.searchHandler} placeholder="Search job postings" />
                                    <button type="submit" className={'btn_search'}><MdSearch /></button>
                                </div>
                            </form>
                        </div> */}
                        <div className="table-responsive">
                            <table className="table table-striped table-hover contract-table mb-0">
                                <tbody>
                                    {isLoader ?
                                        <tr>
                                            <td colSpan="2" className="pt-3 pb-3 text-center">
                                                <MainLoaderComponent />
                                            </td>
                                        </tr>
                                        :
                                        mainArray && mainArray.length > 0 ?
                                            mainArray.map((info, i) =>
                                                this.contractTrRendering(info, i)
                                            )
                                            :
                                            <tr>
                                                <td colSpan="2" className="p-3">
                                                    No record found
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* <ul className={'jobsList'}>
                            {/* {mainArray.length > 0 ?
                                mainArray.map((info, i) => this.renderJobList(info, i))
                                :
                                <li className="text-center">No Jobs Found.</li>
                            } *
                            <li>
                                {/* <Link to={`/client/freelancer-profile/${1}`}> *
                                <div className={'projectList_div div-flex'}>
                                    <div className={'left_wrap'}>
                                        <div className="d-flex">
                                            <div className="image_wrap">
                                                <img src={DummyUser} alt="user" />
                                            </div>
                                            <div>
                                                <h5 className={'job_title'}>Expert in Server admin(AWS)</h5>
                                                <div className={'details_text'}>
                                                    <p className={'flag_text'}><span>Anoop V</span> <span className="country"> <MdLocationOn />India</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className={'job_description mb-0'}>Dec 4, 2020 - May 16, 2021</p>
                                    </div>
                                    <div className={'button_group text-end'} style={{ minWidth: '10rem' }}>
                                        <button type="button" className="btn btn-success" style={{ minWidth: '8rem' }}>View Contract</button>
                                        {/* <Dropdown>
                                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                                Dropdown Button
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu variant="dark" align={'end'}>
                                                <Dropdown.Item href="#/action-1" active>
                                                    Action
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> *
                                    </div>
                                </div>
                                {/* </Link> *
                            </li>

                        </ul> */}

                        {/* {this.state.pageCount > 1 ?
                            <div className={'pagination_wrap'}>
                                <ReactPaginate
                                    initialPage={this.state.page}
                                    // forcePage={this.state.page}
                                    pageCount={this.state.pageCount}
                                    pageRangeDisplayed={4}
                                    breakLabel={'...'}
                                    onPageChange={this.pageHandler}
                                    containerClassName={'pagination_ul'}
                                    activeClassName={'active'}
                                    previousLabel={<MdKeyboardArrowLeft />}
                                    previousClassName={'previous'}
                                    nextLabel={<MdKeyboardArrowRight />}
                                    nextClassName={'next'}
                                />
                            </div>
                            :
                            null
                        } */}
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(connect(null)(AllContracts))
