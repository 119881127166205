import { apiHelper } from "../../helper/api.helper";
import { jwtDecode } from "../../helper/jwt.helper";

// meeting list
export const getExistMeetings = async () => {
    try {

        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const response = await apiHelper(`freelancer/meeting/list`, 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }
        // const data = jwtDecode(res.data)
        // return { status: true, data: data?.items, message: res.message }
        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}


// update meeting date list
export const updateMeeting = async (data) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const { eventId, summary, description, startDate, endDate } = data;

        let formData = new FormData();
        formData.append('eventId', eventId)
        formData.append('summary', summary)
        formData.append('description', description)
        formData.append('startDate', startDate)
        formData.append('endDate', endDate)

        const response = await apiHelper(`freelancer/meeting/update`, 'PUT', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// get admin availability list
export const getAvailability = async (id) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const response = await apiHelper(`admin/availablity/get`, 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// add admin availability 
export const createAvailability = async (data) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const { day, startTime, endTime } = data;
        let formData = new FormData();
        formData.append('day', day.join(','))
        formData.append('startTime', startTime)
        formData.append('endTime', endTime)

        const response = await apiHelper(`admin/availablity/add`, 'POST', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// update admin availability
export const updateAvailability = async (data) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const { day, startTime, endTime } = data;

        let formData = new FormData();
        // formData.append('id', id)
        formData.append('day', day.join(','))
        formData.append('startTime', startTime)
        formData.append('endTime', endTime)

        const response = await apiHelper(`admin/availablity/update`, 'PUT', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// delete admin availability 
export const deleteAvailability = async (id) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const response = await apiHelper(`admin/availablity/delete`, 'DELETE', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}