import React, { Component } from 'react';
import * as ACTION_TYPES from '../../../redux/actions/type';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import { MdDone } from 'react-icons/md';
import ExpertiseComponent from './ExpertiseComponent';
import { connect } from 'react-redux';
import TitleAndOverviewComponent from './TitleAndOverviewComponent';
// import PhotoComponent from './PhotoComponent';
import ContactDetailsComponent from './ContactDetailsComponent';
import PreviewProfileComponent from './PreviewProfileComponent';
import '../../../assets/css/professionalInfo.css';
import TestComponent from './TestComponent';
import { withRouter, Redirect } from 'react-router-dom';
import { getFreelancerProfile } from '../../../controller/profile.controller';
import GettingStarted from './GettingStarted';
import MeetingScheduleComponent from './MeetingScheduleComponent';
import Education from './Education';
import Employment from './Employment';
import { notifyError } from '../../../helper/Alert';
import { jwtDecode } from '../../../helper/jwt.helper';

class ProfessionalInfoComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            stepCurrent: props.activeStep,
            userData: null
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0)

        this.getProfileDetails();

    }

    componentDidUpdate(prevProps) {
        const { isUpdate } = this.props;
        if (prevProps.isUpdate !== isUpdate) {
            if (isUpdate) {
                this.getProfileDetails();
            }
        }
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getProfileDetails = async () => {

        const { stepHandler, activeStep, isCallProfile, userDataHandler, isUpdateProfile, contactDetailFunc, expertiseSubmit, titleAndOverviewSubmit } = this.props;
        // stepHandler,
        const response = await getFreelancerProfile();
        // console.log(res);
        if (response.status) {
            const res = jwtDecode(response.data);
            if (res) {
                if (activeStep > 8) {
                    if (parseInt(res.status) === 1 || parseInt(res.status) === 3 || parseInt(res.status) === 4){
                        return this.props.history.push('/freelancer/jobs')
                    }
                }
                // if (parseInt(res.status) === 1) return this.props.history.push('/freelancer/jobs');
                // if (Number(res.status) === 3) return stepHandler(4);
                isCallProfile(true);
                isUpdateProfile(false)
                userDataHandler(res);
                // localStorage.setItem('token', response.data);
                this.props.updateUserName(res.name);
                if (res.profilePic) {
                    const imageUrl = `${res.profileURL}${res.profilePic}`
                    this.props.updateUserImage(imageUrl);
                }
                contactDetailFunc({
                    countries: [],
                    country: res.countryID ? { value: res.countryID, label: res.countryName } : null,
                    states: [],
                    state: res.stateID ? { value: res.stateID, label: res.statesName } : null,
                    city: res.city ? res.city : '',
                    name: res.name ? res.name : '',
                    mobile: res.mobile ? res.mobile : '',
                    mobilePrefix: res.mobilePrefix ? res.mobilePrefix : ''
                });


                expertiseSubmit({
                    categoriesArray: [],
                    categorieSelected: res.serviceID ? { value: res.serviceID, label: res.serviceName } : null,
                    skillsArray: [],
                    selectedSkills: Array.isArray(res.skills) && res.skills.length > 0 ? res.skills.map(info => ({ value: info.skillID, label: info.skillName })) : []
                })
                titleAndOverviewSubmit({
                    title: res.bio && res.bio !== '' ? res.bio : '',
                    overview: res.overview && res.overview !== '' ? res.overview : ''
                })


                if (this._unmounted) {
                    this.setState({
                        userData: res
                    })
                }
            }
        } else {
            notifyError(response.message);
            // return history.push('/freelancer/jobs');
        }
    }

    render() {

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 2) {
                return <Redirect to="/client/my-jobs" />
            }
        }

        const { activeStep, userData } = this.props;
        // const { userData } = this.state;

        return (
            <>
                <section className={'info_section'}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-3">
                                <div className={`steps_wrap agent_steps`}>
                                    <Steps current={activeStep} direction='vertical' labelPlacement="vertical" icons={{ finish: <MdDone /> }} >
                                        <Step title={'Getting Started'} />
                                        <Step title={'Contact Details'} />
                                        <Step title={'Expertise'} />
                                        <Step title={'Exam'} />
                                        <Step title={'Meeting'} />
                                        <Step title={'Education'} />
                                        <Step title={'Employment'} />
                                        <Step title={'Title & Overview'} />
                                        {/* <Step title={'Profile Photo'} /> */}
                                        <Step title={'Review'} />
                                    </Steps>
                                </div>

                            </div>
                            <div className="col-sm-9">
                                {activeStep === 0 ? <GettingStarted data={userData} /> : null}
                                {activeStep === 1 ? <ContactDetailsComponent data={userData} /> : null}
                                {activeStep === 2 ? <ExpertiseComponent data={userData} /> : null}
                                {activeStep === 3 ? <TestComponent data={userData} /> : null}
                                {activeStep === 4 ? <MeetingScheduleComponent data={userData} /> : null}
                                {activeStep === 5 ? <Education data={userData} /> : null}
                                {activeStep === 6 ? <Employment data={userData} /> : null}
                                {activeStep === 7 ? <TitleAndOverviewComponent data={userData} /> : null}
                                {/* {activeStep === 4 ? <PhotoComponent data={userData} /> : null} */}
                                {activeStep === 8 ? <PreviewProfileComponent data={userData} /> : null}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => ({
    activeStep: state.pinfo.activeStep,
    isUpdate: state.pinfo.isUpdate,
    userData: state.pinfo.userData
});

const mapDispatchToProps = (dispatch) => ({
    stepHandler: (step) => dispatch({ type: ACTION_TYPES.STEP_INFO_FREELANCER, payload: step }),
    userDataHandler: (obj) => dispatch({ type: ACTION_TYPES.FREELANCER_STEPS_DATA, payload: obj }),
    isUpdateProfile: (bool) => dispatch({ type: ACTION_TYPES.UPDATE_FREELANCER_PROFILE, payload: bool }),
    contactDetailFunc: (obj) => dispatch({ type: ACTION_TYPES.CONTACT_DETAILS, payload: obj }),
    expertiseSubmit: (obj) => dispatch({ type: ACTION_TYPES.EXPERTISE_MODULE, payload: obj }),
    titleAndOverviewSubmit: (obj) => dispatch({ type: ACTION_TYPES.TITLE_AND_OVERVIEW, payload: obj }),
    updateUserName: (name) => dispatch({ type: ACTION_TYPES.UPDATE_USER_NAME, payload: name }),
    updateUserImage: (name) => dispatch({ type: ACTION_TYPES.USER_IMAGE_UPDATE, payload: name }),
    isCallProfile: (bool) => dispatch({ type: ACTION_TYPES.PROFILE_SETP_API_GET, payload: bool })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfessionalInfoComponent))
