import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import DataTable from '../../components/DataTable';
import { FiEdit2, FiTrash } from 'react-icons/fi';
import * as ACTION_TYPES from '../../../redux/actions/admin/type';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { allCategories, deleteCategories } from '../../controller/categories.controller';
import AddServiceModal from './AddServiceModal';
import UpdateServiceModal from './UpdateServiceModal';

export class Categories extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            servicesList: [],
            details: null,
            addServiModal: false,
            updateServiModal: false,
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0)
        this.getServicesList();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps) {
        const { isReloadGetApi } = this.props;

        if (prevProps.isReloadGetApi !== isReloadGetApi) {
            if (isReloadGetApi) {
                this.getServicesList();
            }
        }
    }

    getServicesList = async () => {
        const res = await allCategories();
        // console.log(res);
        this.props.reloadApi(false);
        if (this._unmounted) {
            this.setState({
                servicesList: res
            })
        }
    }


    pageHandler = (page, sizePerPage) => {
        console.log(page);
    }

    sizePerPageChange = (sizePerPage, page) => {
        console.log(sizePerPage);
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Table is Empty</p>
        </div>
    }

    headerCenterFormatter = (column) => {
        return <div className="d-flex justify-content-center">{column.text}</div>
    }

    addServiceModal = () => {
        if (this._unmounted) {
            this.setState({
                addServiModal: true
            })
        }
    }

    closeServiceModal = () => {
        if (this._unmounted) {
            this.setState({
                addServiModal: false
            })
        }
    }

    openModalServiUpdate = (info) => {
        if (this._unmounted) {
            this.setState({
                updateServiModal: true,
                details: info
            })
        }
    }

    closeModalServiUpdate = () => {
        if (this._unmounted) {
            this.setState({
                updateServiModal: false,
                details: null
            })
        }
    }

    mainServicesFormatter = (cell, row) => {
        const { parentCategoryID } = row;
        if (parentCategoryID > 0) {
            if (this.state.servicesList.length > 0) {
                const currAnsw = this.state.servicesList.filter(info => info.id === parentCategoryID);
                if (currAnsw.length > 0) {
                    return currAnsw[0].categoryName
                }
            }
        }

        return '--'
    }

    deleteService = async (info) => {
        const res = await deleteCategories(info.id);
        if(res.status){
            notifySuccess(res.message);
            this.props.reloadApi(true);
        }else{
            notifyError(res.message)
        }
    }

    actionFormatter = (cell, row) => {
        return <div className="d-flex justify-content-center">
            {/* <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.openModalDescription(row)}><FiEye /></button> */}
            <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.openModalServiUpdate(row)}><FiEdit2 /></button>
            <button type="button" className="border-0 bg-transparent text-danger" style={{ cursor: 'pointer' }} onClick={() => this.deleteService(row)}><FiTrash /></button>
        </div>
    }

    render() {

        const columns = [
            {
                dataField: 'categoryName',
                text: 'Service Name',
                sort: true
            },
            {
                dataField: 'parentCategoryID',
                text: 'Main Service',
                sort: true,
                formatter: this.mainServicesFormatter,
            },
            {
                dataField: 'action',
                text: 'Action',
                sort: false,
                formatter: this.actionFormatter,
                headerFormatter: this.headerCenterFormatter
            }
        ];

        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3 d-flex align-items-start justify-content-between">
                    <strong>Services</strong>
                    <button type="button" className="btn btn-dark" onClick={this.addServiceModal}>Add Services</button>
                </h1>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <DataTable
                                keyField="id"
                                data={this.state.servicesList}
                                columns={columns}
                                // onSizePerPageChange={this.sizePerPageChange} 
                                // onPageChange={this.pageHandler} 
                                noDataIndication={this.emptyRender}
                            />

                        </div>
                    </div>
                </div>
                {this.state.addServiModal?<AddServiceModal show={this.state.addServiModal} onHide={this.closeServiceModal} />:null}
                {this.state.updateServiModal?<UpdateServiceModal show={this.state.updateServiModal} onHide={this.closeModalServiUpdate} data={this.state.details} />:null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isReloadGetApi: state.categoriesAdmin.isReloadGetApi
})

const mapDispatchToProps = dispatch => ({
    reloadApi: (bool) => dispatch({ type: ACTION_TYPES.RELOAD_GET_CATEGORIES, payload: bool })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Categories));
