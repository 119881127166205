import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import { FiTrash2 } from 'react-icons/fi';
import ConfirmModal from '../../../components/confirmModal';
import payment from '../../../controller/payment.controller';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { notifyError, notifySuccess } from '../../../helper/Alert';

class PaymentMethod extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            accountList: [],
            addModal: false,
            addAccountLoader: false,
            email: '',
            isConfirmModal: false,
            details: null
        }
    }

    componentDidMount() {
        this.getAccountList();
    }

    getAccountList = async () => {
        this.setState({
            loader: true
        })
        const res = await payment.getPaymentAccountList();
        if (res.status) {
            this.setState({
                loader: false,
                accountList: res.data
            })
        } else {
            this.setState({
                loader: false,
                accountList: []
            })
        }
    }

    closeAddModal = () => {
        this.setState({
            addModal: false,
            email: ''
        })
    }
    openAddModal = () => {
        this.setState({
            addModal: true
        })
    }

    openConfirmModal = (info) => {
        this.setState({
            isConfirmModal: true,
            details: info
        })
    }

    closeConfirmModal = () => {
        this.setState({
            isConfirmModal: false
        })
    }

    deleteAccount = async () => {

        const { details } = this.state;
        const res = await payment.deletePaymentAccount(details?.id);
        if (!res.status) {
            this.setState({
                isConfirmModal: false
            })
            return notifyError(res.message);
        }
        this.getAccountList();
        notifySuccess(res.message)
        this.setState({
            isConfirmModal: false,
            details: null
        })
    }

    inputModalHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    submitAddPayment = async (values, actions) => {
        this.setState({
            addAccountLoader: true
        });
        const res = await payment.addPaymentAccount(values.email);
        if (!res.status) {
            return notifyError(res.message)
        }
        notifySuccess(res.message);
        this.getAccountList();
        actions.resetForm({
            values: { email: '' }
        })

        this.setState({
            addAccountLoader: false,
            addModal: false
        })

    }

    render() {

        // console.log('working');
        const { loader, accountList, addAccountLoader } = this.state;

        const paymentSchema = Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Email must be required')
        });

        return (
            <>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center border-bottom pt-4 pb-4">
                        <h2 className="fs-3 fw-bold mb-0">Payment details</h2>
                        <button type="button" className="btn btn-outline-dark rounded-pill" onClick={this.openAddModal}>Add Method</button>
                    </div>
                    <div className="card-body">
                        {loader ?
                            <MainLoaderComponent />
                            :
                            accountList && accountList.length > 0 ?
                                <div className="table-responsive">
                                    <table className="table table-hovered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Account</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {accountList.map((info, i) =>
                                                <tr key={i}>
                                                    <td>PayPal - {info?.account}</td>
                                                    <td className="text-center"><button type="button" className="border-0 bg-transparent text-danger" onClick={() => this.openConfirmModal(info)}><FiTrash2 /></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div>
                                    <h6 className="fs-5 fw-bold mb-4">You have not set up any payment methods yet.</h6>
                                    <p className="mb-0 fs-6 fw-normal">Tell us how you want to receive your funds. It may take up to 3 days to activate your payment method.</p>
                                </div>
                        }
                    </div>
                </div>
                {this.state.addModal ?
                    <Modal show={this.state.addModal} onHide={this.closeAddModal} centered>
                        {/* <Modal.Header>
                    </Modal.Header> */}
                        <Modal.Body className="p-5">
                            <Modal.Title>Add Payment Method</Modal.Title>
                            <Formik initialValues={{ email: '' }} validationSchema={paymentSchema} onSubmit={(values, actions) => this.submitAddPayment(values, actions)}>
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                    <form action="/" method="POST" className="mt-5" onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label className="form-label">Paypal Account</label>
                                            <input type="email" name="email" className={`form-control ${errors.email && touched.email ? 'error' : ''}`} placeholder="Ex.paypal@gmail.com" value={values.email} onChange={handleChange} onBlur={handleBlur} required />
                                            {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                                        </div>
                                        <div className="form-group mt-5">
                                            <button type="submit" className="btn btn-success" style={{ minWidth: '100px' }} disabled={addAccountLoader}>{addAccountLoader ? <SmallLoaderComponent color="white" /> : 'Submit'}</button>
                                            <button type="button" className="btn border-0 bg-transparent" onClick={this.closeAddModal}>Close</button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </Modal.Body>
                    </Modal>
                    :
                    null
                }
                {this.state.isConfirmModal ?
                    <ConfirmModal
                        show={this.state.isConfirmModal}
                        onHide={this.closeConfirmModal}
                        onSubmit={this.deleteAccount}
                        text="Are you sure you want to delete this account?"
                    />
                    :
                    null
                }
            </>
        )
    }
}

export default PaymentMethod