import React, { Component } from 'react';
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import TheContent from './TheContent';

class Layout extends Component {
    render() {

        const token = localStorage.getItem('token');

        return (
            <>
                <HeaderComponent />
                <TheContent />
                {!token?<FooterComponent />:null}
            </>
        )
    }
}

export default Layout
