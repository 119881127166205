import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { Form, Modal } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import payment from '../../../controller/payment.controller';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
// import { loadStripe } from '@stripe/stripe-js';
// import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

// const InjectedCheckoutForm = () => (
//     <ElementsConsumer>
//         {({ stripe, elements }) => (
//             <CheckoutForm stripe={stripe} elements={elements} />
//         )}
//     </ElementsConsumer>
// );

// const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

const CardComponent = (props) => {

    const [paymentModal, setPaymentModal] = useState(false);
    const [activeCard, setActiveCard] = useState(0);
    const [isCard, setIsCard] = useState(true);
    const [isLoader, setIsLoader] = useState(false);


    const {
        meta,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
        wrapperProps
    } = usePaymentInputs();

    const openPaymentModal = () => {
        setPaymentModal(true)
    }

    const closePaymentModal = () => {
        setPaymentModal(false)
    }

    const onCardHandler = (card) => {
        if (card === 'addCard') {
            setActiveCard(0)
            setIsCard(true)
        } else {
            setActiveCard(1)
            setIsCard(false)
        }
    }

    const paymentSubmit = async (values, actions) => {
        const expiryDate = values.expiryDate.split('/')
        // const date = new Date();
        const year = new Date().getFullYear().toString();
        const year2_digit = year.slice(0, -2);
        const card_number = values.cardNumber.toString().trim();
        // console.log(card_number)
        const exp_year = `${year2_digit}${expiryDate[1].trim()}`


        const formData = {
            jobPostID: parseInt(props.jobid),
            freelancerProfileID: parseInt(props.fid),
            ContractAmount: parseInt(props.price),
            MilestonesAmount: parseInt(props.milestonAmount),
            number: card_number,
            cvc: parseInt(values.cvc),
            exp_month: parseInt(expiryDate[0].trim()),
            exp_year: parseInt(exp_year),
            name: values.holderName
        }
        // console.log(formData);
        setIsLoader(true)
        const res = await payment.addPayment(formData);
        // console.log(res);
        if (res.status) {
            notifySuccess(res.message);
            actions.resetForm({
                values: {
                    cardNumber: '',
                    expiryDate: '',
                    cvc: '',
                    holderName: ''
                }
            })
            setIsLoader(false)
            props.history.push('/client/my-jobs')
        } else {
            setIsLoader(false)
            notifyError(res.message)
        }

    }

    return (
        <div className="payment-history">
            <div className="card-history">
                {/* <div className={`card-detail ${activeCard === 1 ? 'active' : ''}`} onClick={() => this.onCardHandler('card')}>
                        <div className="checkbox">
                            <span></span>
                        </div>
                        <div className="name-wrap">
                            <span>Heni</span>
                            <p>**** **** **** 4242</p>
                        </div>
                    </div> */}
                <div className={`card-detail payment-method info ${activeCard === 0 ? 'active' : ''}`} onClick={() => onCardHandler('addCard')}>
                    <div className="checkbox">
                        <span></span>
                    </div>
                    <div className="cards">
                        <span>Credit Or Debit Card</span>
                        {isCard ?
                            <Formik
                                initialValues={{
                                    cardNumber: '',
                                    expiryDate: '',
                                    cvc: '',
                                    holderName: ''
                                }}
                                onSubmit={(values, actions) => paymentSubmit(values, actions)}
                                validate={(values) => {
                                    // console.log(values)
                                    let errors = {};
                                    if (meta.erroredInputs.cardNumber) {
                                        errors.cardNumber = meta.erroredInputs.cardNumber;
                                    }
                                    if (meta.erroredInputs.expiryDate) {
                                        errors.expiryDate = meta.erroredInputs.expiryDate;
                                    }
                                    if (meta.erroredInputs.cvc) {
                                        errors.cvc = meta.erroredInputs.cvc;
                                    }
                                    if (values.holderName === '') {
                                        errors.holderName = 'Card holder name must be required!'
                                    }
                                    return errors;
                                }}
                            >
                                {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (

                                    <form action="/" method="POST" onSubmit={handleSubmit}>
                                        {/* <Elements stripe={stripePromise}>
                                        <InjectedCheckoutForm />
                                    </Elements> */}
                                        <div className="row mt-3">
                                            {/* <PaymentInputsWrapper {...wrapperProps}> */}
                                            {/* {({ meta, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps }) => ( */}
                                            <>
                                                {/* {console.log(errors)} */}
                                                {/* {meta.isTouched && meta.error ? <Alert variant="danger"><Alert.Heading className="mb-0 h6">{meta.error}</Alert.Heading></Alert> : null} */}
                                                <div className="col-sm-12">
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Card Number</label>
                                                        <div className="number-with-image">
                                                            <svg className="icon" {...getCardImageProps({ images })} />
                                                            <Field name="cardNumber">
                                                                {({ field, form, meta }) => (
                                                                    <>
                                                                        <input
                                                                            className={`form-control ps-5 ${errors.cardNumber && touched.cardNumber ? 'error' : ''}`}
                                                                            {...getCardNumberProps({ onBlur: field.onBlur, onChange: field.onChange })}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        {errors.cardNumber && touched.cardNumber && <p className="error-text">{errors.cardNumber}</p>}
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Expiry Date</label>
                                                        <Field name="expiryDate">
                                                            {({ field, form, meta }) => (
                                                                <>
                                                                    <input
                                                                        className={`form-control ${errors.expiryDate && touched.expiryDate ? 'error' : ''}`}
                                                                        {...getExpiryDateProps({ onBlur: field.onBlur, onChange: field.onChange })} />
                                                                </>
                                                            )}
                                                        </Field>
                                                        {errors.expiryDate && touched.expiryDate && <p className="error-text">{errors.expiryDate}</p>}
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">CVV</label>
                                                        <Field name="cvc">
                                                            {({ field, form, meta }) => (
                                                                <>
                                                                    <input
                                                                        className={`form-control ${errors.cvc && touched.cvc ? 'error' : ''}`}
                                                                        {...getCVCProps({ onBlur: field.onBlur, onChange: field.onChange })} />
                                                                </>
                                                            )}
                                                        </Field>
                                                        {errors.cvc && touched.cvc && <p className="error-text">{errors.cvc}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-group mt-4">
                                                        <label className="form-label">Card Holder Name</label>
                                                        <Field name="holderName">
                                                            {({ field, form, meta }) => (
                                                                <>
                                                                    <input
                                                                        name='holderName'
                                                                        className={`form-control ${errors.holderName && touched.holderName ? 'error' : ''}`}
                                                                        placeholder="Card Holder Name"
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    // value={holderName} 
                                                                    />
                                                                </>
                                                            )}
                                                        </Field>
                                                        {errors.holderName && touched.holderName && <p className="error-text">{errors.holderName}</p>}
                                                    </div>
                                                </div>





                                                {/* <p>{meta.isTouched && meta.error && <span>Error: {meta.error}</span>}</p> */}
                                            </>

                                            {/* )} */}
                                            {/* </PaymentInputsWrapper> */}
                                            <div className="col-sm-12 mt-5">
                                                <button type="submit" className="btn btn-success" style={{minWidth:'80px'}}>{isLoader?<SmallLoaderComponent color="white"/>:`$${props.milestonAmount} Deposit`}</button>
                                                {/* <button type="button" className="btn btn-primary">$121.2 Deposit</button> */}
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            {paymentModal ?
                <Modal show={paymentModal} onHide={closePaymentModal} centered contentClassName="rounded-border">
                    <Modal.Body className="p-5">
                        <div className="d-block text-center mb-4 processing complete">
                            {/* <div className="loading-bar" dataProgress="0"><span></span></div> */}
                            <svg className="checkmark checkmark-success" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                <circle className="checkmark-circle" cx="25" cy="25" r="25" fill="none" /><path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </div>
                        <h2 className="h4 text-center">Payment SuccessFully Completed.</h2>
                        <div className="pt-5 d-block text-center">
                            <Link to="/client/my-jobs" className="btn btn-primary rounded-pill">Go To Dashboard</Link>
                        </div>
                    </Modal.Body>
                </Modal>
                :
                null
            }
        </div>
    )
}

// class CardComponent extends Component {

//     _unmounted = false;

//     constructor(props) {
//         super(props);
//         this.state = {
//             paymentModal: false,
//             holderName: '',
//             nameError: '',
//             cardNumber: '',
//             expiryDate: '',
//             cvc: '',
//             cardError: {
//                 cardNumber: '',
//                 expiryDate: '',
//                 cvc: '',
//                 holderName: '',
//             },
//             cardValid: {
//                 cardNumber: false,
//                 expiryDate: false,
//                 cvc: false,
//                 holderName: false
//             },
//             isCard: true,
//             activeCard: 0,
//             price: props.price
//         }
//     }

//     componentDidMount() {
//         this._unmounted = true;
//     }

//     componentWillUnmount() {
//         this._unmounted = false;
//     }

//     openPaymentModal = () => {
//         this.setState({
//             paymentModal: true
//         })
//     }

//     closePaymentModal = () => {
//         this.setState({
//             paymentModal: false
//         })
//     }

//     onCardHandler = (card) => {
//         if (card === 'addCard') {
//             if (this._unmounted) {
//                 this.setState({
//                     activeCard: 0,
//                     isCard: true
//                 })
//             }
//         } else {
//             if (this._unmounted) {
//                 this.setState({
//                     activeCard: 1,
//                     isCard: false
//                 })
//             }
//         }
//     }

//     handleChangeHolderName = (e) => {
//         const { value } = e.target;

//         let error = '';
//         let valid = false;
//         if (value === '') {
//             error = 'Holder Name must be required!'
//             valid = false
//         } else {
//             error = ''
//             valid = true
//         }

//         if (this._unmounted) {
//             this.setState({
//                 holderName: value,
//                 cardError: {
//                     ...this.state.cardError,
//                     holderName: error
//                 },
//                 cardValid: {
//                     ...this.state.cardValid,
//                     holderName: valid
//                 }
//             })
//         }
//     }

//     handleChangeCardNumber = (e, errors) => {
//         // console.log(e);
//         const { value } = e.target;
//         let error = '';
//         let valid = false;
//         if (value === '') {
//             error = 'Card Number is required!'
//             valid = false
//         } else {
//             if (errors && errors === 'Card number is invalid') {
//                 error = errors
//                 valid = false
//             } else {
//                 error = ''
//                 valid = true
//             }
//         }

//         if (this._unmounted) {
//             this.setState({
//                 cardNumber: value,
//                 // cardError: {
//                 //     ...this.state.cardError,
//                 //     cardNumber: error
//                 // },
//                 // cardValid: {
//                 //     ...this.state.cardValid,
//                 //     cardNumber: valid
//                 // }
//             })
//         }
//     }

//     handleChangeExpiryDate = (e, errors) => {
//         // console.log(e);
//         const { value } = e.target;

//         let error = '';
//         let valid = false;
//         if (value === '') {
//             error = 'Expiry Date is required!';
//             valid = false
//         } else {
//             if ((errors && errors === 'Expiry date is invalid') || errors === 'Expiry date cannot be in the past' || errors === 'Expiry year cannot be in the past') {
//                 error = errors
//                 valid = false
//             } else {
//                 error = ''
//                 valid = true
//             }

//         }

//         if (this._unmounted) {
//             this.setState({
//                 expiryDate: value,
//                 // cardError: {
//                 //     ...this.state.cardError,
//                 //     expiryDate: error
//                 // },
//                 // cardValid: {
//                 //     ...this.state.cardValid,
//                 //     expiryDate: valid
//                 // }
//             })
//         }
//     }
//     handleChangeCVC = (e, errors) => {
//         // console.log(e);
//         const { value } = e.target;

//         let error = '';
//         let valid = false;
//         if (value === '') {
//             error = 'CVC Number is required!';
//             valid = false
//         } else {
//             if (errors && errors === 'CVC is invalid') {
//                 error = errors
//                 valid = false
//             } else {
//                 error = ''
//                 valid = true
//             }

//         }

//         if (this._unmounted) {
//             this.setState({
//                 cvc: value,
//                 // cardError: {
//                 //     ...this.state.cardError,
//                 //     cvc: error
//                 // },
//                 // cardValid: {
//                 //     ...this.state.cardValid,
//                 //     cvc: valid
//                 // }
//             })
//         }
//     }

//     inputNumberBlur = (meta) => {
//         const { erroredInputs, error } = meta;
//         let errors = this.state.cardError
//         let valid = this.state.cardValid

//         if ((erroredInputs.cardNumber && error && erroredInputs.cardNumber === error) || errors.cardNumber !== '') {
//             errors.cardNumber = erroredInputs.cardNumber !== undefined ? erroredInputs.cardNumber : '';
//             valid.cardNumber = false;
//         } else {
//             errors.cardNumber = '';
//             valid.cardNumber = true;
//         }
//         console.log(meta);
//         this.setState({
//             cardError: {
//                 ...this.state.cardError,
//                 cardNumber: errors.cardNumber,
//                 expiryDate: erroredInputs.expiryDate === undefined ? '' : this.state.cardError.expiryDate,
//                 cvc: erroredInputs.cvc === undefined ? '' : this.state.cardError.cvc
//             },
//             cardValid: {
//                 ...this.state.cardValid,
//                 cardNumber: valid.cardNumber,
//                 expiryDate: erroredInputs.expiryDate === undefined ? true : this.state.cardValid.expiryDate,
//                 cvc: erroredInputs.cvc === undefined ? true : this.state.cardValid.cvc
//             }
//         })
//     }

//     inputDateBlur = (meta) => {
//         const { erroredInputs, error } = meta;
//         let errors = this.state.cardError
//         let valid = this.state.cardValid

//         if ((erroredInputs.expiryDate && error && erroredInputs.expiryDate === error) || errors.expiryDate !== '') {
//             errors.expiryDate = erroredInputs.expiryDate !== undefined ? erroredInputs.expiryDate : '';
//             valid.expiryDate = false;
//         } else {
//             errors.expiryDate = '';
//             valid.expiryDate = true;
//         }

//         this.setState({
//             cardError: {
//                 ...this.state.cardError,
//                 cardNumber: erroredInputs.cardNumber === undefined ? '' : this.state.cardError.cardNumber,
//                 expiryDate: errors.expiryDate,
//                 cvc: erroredInputs.cvc === undefined ? '' : this.state.cardError.cvc
//             },
//             cardValid: {
//                 ...this.state.cardValid,
//                 cardNumber: erroredInputs.cardNumber === undefined ? true : this.state.cardValid.cardNumber,
//                 expiryDate: valid.expiryDate,
//                 cvc: erroredInputs.cvc === undefined ? true : this.state.cardValid.cvc
//             }
//         })
//     }

//     inputCVCBlur = (meta) => {
//         const { erroredInputs, error } = meta;
//         let errors = this.state.cardError
//         let valid = this.state.cardValid

//         if ((erroredInputs.cvc && error && erroredInputs.cvc === error) || errors.cvc !== '') {
//             errors.cvc = erroredInputs.cvc !== undefined ? erroredInputs.cvc : '';
//             valid.cvc = erroredInputs.cvc === undefined ? true : false;
//         } else {
//             errors.cvc = '';
//             valid.cvc = true;
//         }

//         this.setState({
//             cardError: {
//                 ...this.state.cardError,
//                 cardNumber: erroredInputs.cardNumber === undefined ? '' : this.state.cardError.cardNumber,
//                 expiryDate: erroredInputs.expiryDate === undefined ? '' : this.state.cardError.expiryDate,
//                 cvc: errors.cvc
//             },
//             cardValid: {
//                 ...this.state.cardValid,
//                 cardNumber: erroredInputs.cardNumber === undefined ? true : this.state.cardValid.cardNumber,
//                 expiryDate: erroredInputs.expiryDate === undefined ? true : this.state.cardValid.expiryDate,
//                 cvc: valid.cvc
//             }
//         })
//     }

//     paymentSubmit = (e) => {
//         e.preventDefault();
//         console.log('submit');
//     }

//     render() {

//         const { holderName, cardNumber, expiryDate, cvc, cardValid, cardError, activeCard, isCard, paymentModal } = this.state;
//         console.log(cardValid);
//         const disabled = !cardValid.cardNumber || !cardValid.expiryDate || !cardValid.cvc || !cardValid.holderName;


//         return (
//             <div className="payment-history">
//                 <div className="card-history">
//                     {/* <div className={`card-detail ${activeCard === 1 ? 'active' : ''}`} onClick={() => this.onCardHandler('card')}>
//                         <div className="checkbox">
//                             <span></span>
//                         </div>
//                         <div className="name-wrap">
//                             <span>Heni</span>
//                             <p>**** **** **** 4242</p>
//                         </div>
//                     </div> */}
//                     <div className={`card-detail payment-method info ${activeCard === 0 ? 'active' : ''}`} onClick={() => this.onCardHandler('addCard')}>
//                         <div className="checkbox">
//                             <span></span>
//                         </div>
//                         <div className="cards">
//                             <span>Credit Or Debit Card</span>
//                             {isCard ?
//                                 <form action="/" method="POST" onSubmit={this.paymentSubmit}>
//                                     <div className="row mt-3">
//                                         {/* <Elements stripe={stripePromise}>
//                                         <InjectedCheckoutForm />
//                                     </Elements> */}
//                                         <PaymentInputsContainer>
//                                             {({ meta, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
//                                                 <>
//                                                     {/* {console.log(meta)} */}
//                                                     {/* {meta.isTouched && meta.error ? <Alert variant="danger"><Alert.Heading className="mb-0 h6">{meta.error}</Alert.Heading></Alert> : null} */}
//                                                     <div className="col-sm-12">
//                                                         <div className="form-group mb-4">
//                                                             <label className="form-label">Card Number</label>
//                                                             <div className="number-with-image">
//                                                                 <svg className="icon" {...getCardImageProps({ images })} />
//                                                                 <input
//                                                                     className={`form-control ps-5 ${cardError.cardNumber !== '' ? 'error' : ''}`}
//                                                                     {...getCardNumberProps({ onChange: (e) => this.handleChangeCardNumber(e, meta.error), onBlur: () => this.inputNumberBlur(meta) })}
//                                                                     value={cardNumber}
//                                                                 />
//                                                             </div>
//                                                             {cardError.cardNumber !== '' ? <p className="error-text">{cardError.cardNumber}</p> : null}
//                                                         </div>
//                                                     </div>

//                                                     <div className="col-sm-6">
//                                                         <div className="form-group">
//                                                             <label className="form-label">Expiry Date</label>
//                                                             <input className={`form-control ${cardError.expiryDate !== '' ? 'error' : ''}`} {...getExpiryDateProps({ onChange: (e) => this.handleChangeExpiryDate(e, meta.error), onBlur: () => this.inputDateBlur(meta) })} value={expiryDate} />
//                                                             {cardError.expiryDate !== '' ? <p className="error-text">{cardError.expiryDate}</p> : null}
//                                                         </div>
//                                                     </div>

//                                                     <div className="col-sm-6">
//                                                         <div className="form-group">
//                                                             <label className="form-label">CVV</label>
//                                                             <input className={`form-control ${cardError.cvc !== '' ? 'error' : ''}`} {...getCVCProps({ onChange: (e) => this.handleChangeCVC(e, meta.error), onBlur: () => this.inputCVCBlur(meta) })} value={cvc} />
//                                                             {cardError.cvc !== '' ? <p className="error-text">{cardError.cvc}</p> : null}
//                                                         </div>
//                                                     </div>
//                                                     <div className="col-sm-12">
//                                                         <div className="form-group mt-4">
//                                                             <label className="form-label">Card Holder Name</label>
//                                                             <input className={`form-control ${cardError.holderName !== '' ? 'error' : ''}`} placeholder="Card Holder Name" onChange={this.handleChangeHolderName} value={holderName} />
//                                                             {cardError.holderName !== '' ? <p className="error-text">{cardError.holderName}</p> : null}
//                                                         </div>
//                                                     </div>


//                                                     <div className="col-sm-12 mt-5">
//                                                         <button type="submit" className="btn btn-success" disabled={disabled}>{`$${this.state.price} Deposit`}</button>
//                                                         {/* <button type="button" className="btn btn-primary">$121.2 Deposit</button> */}
//                                                     </div>


//                                                     {/* <p>{meta.isTouched && meta.error && <span>Error: {meta.error}</span>}</p> */}
//                                                 </>

//                                             )}
//                                         </PaymentInputsContainer>
//                                     </div>
//                                 </form>
//                                 :
//                                 null
//                             }
//                         </div>
//                     </div>
//                 </div>
//                 {paymentModal ?
//                     <Modal show={paymentModal} onHide={this.closePaymentModal} centered contentClassName="rounded-border">
//                         <Modal.Body className="p-5">
//                             <div className="d-block text-center mb-4 processing complete">
//                                 {/* <div className="loading-bar" dataProgress="0"><span></span></div> */}
//                                 <svg className="checkmark checkmark-success" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
//                                     <circle className="checkmark-circle" cx="25" cy="25" r="25" fill="none" /><path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
//                                 </svg>
//                             </div>
//                             <h2 className="h4 text-center">Payment SuccessFully Completed.</h2>
//                             <div className="pt-5 d-block text-center">
//                                 <Link to="/client/my-jobs" className="btn btn-primary rounded-pill">Go To Dashboard</Link>
//                             </div>
//                         </Modal.Body>
//                     </Modal>
//                     :
//                     null
//                 }
//             </div>
//         )
//     }
// }


export default withRouter(connect(null)(CardComponent));
