import { apiHelper } from "../helper/api.helper"
import { jwtDecode } from "../helper/jwt.helper"

// freelancer contract list
const getFreelancerContract = async () => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper('freelancer/contract/list', 'GET', null, header);
        const res = response.data;
        if (res.status) {
            return { status: true, data: jwtDecode(res.data), message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}
// freelancer active contract list
const getFreelancerActiveContract = async () => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper('freelancer/activeContract/list', 'GET', null, header);
        const res = response.data;
        if (res.status) {
            return { status: true, data: jwtDecode(res.data), message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// freelancer contract details
const getFreelancerContractDetails = async (id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`freelancer/contract/details/${id}`, 'GET', null, header);
        const res = response.data;
        if (res.status) {
            return { status: true, data: jwtDecode(res.data), message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// freelancer complete contract 
const freelancerEndContract = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { jobPostID, contractID } = data;

        let formData = new FormData();
        formData.append('jobPostID', jobPostID)
        formData.append('contractID', contractID)

        const response = await apiHelper(`freelancer/contract/complet`, 'POST', formData, header);
        const res = response.data;
        if (res.status) {
            return { status: true, message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// client contract list
const getClientContract = async () => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper('client/contract/list', 'GET', null, header);
        const res = response.data;
        if (res.status) {
            return { status: true, data: jwtDecode(res.data), message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// client contract details
const getClientContractDetails = async (id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`client/contract/details/${id}`, 'GET', null, header);
        const res = response.data;
        if (res.status) {
            return { status: true, data: jwtDecode(res.data), message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// client complete contract 
const clientEndContract = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { jobPostID, freelancerID, contractID } = data;

        let formData = new FormData();
        formData.append('jobPostID', jobPostID)
        formData.append('freelancerID', freelancerID)
        formData.append('contractID', contractID)

        const response = await apiHelper(`client/contract/complet`, 'POST', formData, header);
        const res = response.data;
        if (res.status) {
            return { status: true, data: [], message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

const contractService = {
    getFreelancerContract,
    getFreelancerActiveContract,
    getFreelancerContractDetails,
    freelancerEndContract,
    getClientContract,
    getClientContractDetails,
    clientEndContract
}

export default contractService;