import React, { Component } from 'react'
import SettingsNav from '../../../components/SettingsNav';
import { Switch, Route, Redirect } from 'react-router-dom';
import PaymentMethod from './PaymentMethod';
import GetPaid from './GetPaid';
import { jwtDecode } from '../../../helper/jwt.helper';

class Settings extends Component {
    render() {
        if (!localStorage.getItem('token')) {
            return <Redirect to='/login' />
        }

        if (localStorage.getItem('token')) {
            const decode = jwtDecode(localStorage.getItem('token'))
            if (decode && Number(decode.profileType) === 2) {
                return <Redirect to='/client/jobs' />;
            }

            // if (decode && Number(decode.profileType) === 1) {
            //     return router.push('/freelancer/jobs');
            // }
        }
        return (
            <section className={`proposals_section pt-5`}>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-3">
                            <SettingsNav />
                        </div>
                        <div className="col-xs-12 col-sm-9">
                            <Switch>
                                <Route path="/freelancer/settings" exact component={GetPaid} />
                                <Route path="/freelancer/settings/payment" exact component={PaymentMethod} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Settings
