import { apiHelper } from "../helper/api.helper";
import { jwtDecode } from "../helper/jwt.helper";


export const getUserProfile = async () => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await apiHelper('client/profile/get', 'GET', null, header);
    const res = response.data;

    if (!res.status) {
        return null
    }

    return jwtDecode(res.data);
}

export const getFreelancerProfile = async () => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await apiHelper('freelancer/profile/get', 'GET', null, header);
    const res = response.data;

    // if (!res.status) {
    //     return null
    // }

    return res;
}

export const updateUserProfile = async (data) => {

    const { name, mobilePrefix, mobile, countryID, stateID, city, profilePic } = data;

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('name', name)
    formData.append('mobilePrefix', mobilePrefix)
    formData.append('mobile', mobile)
    formData.append('countryID', countryID)
    formData.append('stateID', stateID)
    formData.append('city', city)
    if (profilePic) {
        formData.append('profilePic', profilePic)
    }

    const response = await apiHelper('users/profile/update', 'PUT', formData, header);

    return response.data;
}

export const updateUserProfilePicture = async (file) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('profilePic', file)

    const response = await apiHelper('users/profile/picture/update', 'PUT', formData, header);

    return response.data;
}


// add skills api for professional info page
export const addSkillsStep = async ({ categoryID, skillIDs }) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('categoryID', categoryID)
    formData.append('skillIDs', JSON.stringify(skillIDs))

    const response = await apiHelper('freelancer/skills/add', 'POST', formData, header);

    return response.data;
}

// add title and overview api for professional info page
export const overviewAndTitle = async ({ title, overview }) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('bio', title)
    formData.append('overview', overview)

    const response = await apiHelper('freelancer/profile/update/bio', 'PUT', formData, header);

    return response.data;
}

// get portfolio list
export const getPortfolio = async () => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await apiHelper('freelancer/portfolio/get', 'GET', null, header);

    const res = response.data;
    if (res.status === false) {
        return []
    }

    return jwtDecode(res.data)
}

// add portfolio
export const addPortFolio = async ({ title, url, files }) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('title', title)
    formData.append('url', url)
    for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i])
    }

    const response = await apiHelper('freelancer/portfolio/add', 'POST', formData, header);

    return response.data;
}

// edit portfolio
export const editPortFolio = async ({ title, url, files, to_delete, portfolioID }) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    let formData = new FormData();
    formData.append('title', title)
    formData.append('url', url)
    formData.append('to_delete', to_delete)
    formData.append('portfolioID', portfolioID)
    for (let i = 0; i < files.length; i++) {
        if (!files[i].id) {
            formData.append('file', files[i])
        }
    }

    const response = await apiHelper('freelancer/portfolio/edit', 'PUT', formData, header);

    return response.data;
}

// delete portfolio
export const deletPortFolio = async (id) => {

    const header = {
        'Authorization': localStorage.getItem('token')
    }

    const response = await apiHelper(`freelancer/portfolio/delete/${id}`, 'DELETE', null, header);

    return response.data;
}

// get Education list
export const getEducation = async () => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`freelancer/education/get`, 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// add Education
export const addNewEducation = async (data) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { schoolName, schoolStartYear, schoolEndYear, degree, aeraOfStudy, description } = data;

        let formData = new FormData();
        formData.append('schoolName', schoolName)
        formData.append('schoolStartYear', parseInt(schoolStartYear))
        formData.append('schoolEndYear', parseInt(schoolEndYear))
        if (degree !== '') {
            formData.append('degree', degree)
        }
        if (aeraOfStudy !== '') {
            formData.append('aeraOfStudy', aeraOfStudy)
        }
        if (description !== '') {
            formData.append('description', description)
        }

        const response = await apiHelper(`freelancer/education/add`, 'POST', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// update Education
export const upadteExistEducation = async (data) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { id, schoolName, schoolStartYear, schoolEndYear, degree, aeraOfStudy, description } = data;

        let formData = new FormData();
        formData.append('id', id)
        formData.append('schoolName', schoolName)
        formData.append('schoolStartYear', parseInt(schoolStartYear))
        formData.append('schoolEndYear', parseInt(schoolEndYear))
        if (degree !== '') {
            formData.append('degree', degree)
        }
        if (aeraOfStudy !== '') {
            formData.append('aeraOfStudy', aeraOfStudy)
        }
        if (description !== '') {
            formData.append('description', description)
        }

        const response = await apiHelper(`freelancer/education/update`, 'PUT', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// delete Education
export const deleteExistEducation = async (id) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`freelancer/education/delete/${id}`, 'DELETE', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// get Employment list
export const getEmployment = async () => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`freelancer/employee/get`, 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// add Employment
export const addNewEmployment = async (data) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { company, title, startYear, startMonth, endMonth, endYear, description, isPresent } = data;

        let formData = new FormData();
        formData.append('company', company)
        formData.append('title', title)
        formData.append('startMonth', parseInt(startMonth))
        formData.append('startYear', parseInt(startYear))
        if (!isPresent) {
            formData.append('endMonth', parseInt(endMonth))
            formData.append('endYear', parseInt(endYear))
        }
        if (description !== '') {
            formData.append('description', description)
        }

        const response = await apiHelper(`freelancer/employee/add`, 'POST', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// update Employment
export const upadteExistEmployment = async (data) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { id, company, title, startYear, startMonth, endMonth, endYear, description, isPresent } = data;

        let formData = new FormData();
        formData.append('id', id)
        formData.append('company', company)
        formData.append('title', title)
        formData.append('startMonth', parseInt(startMonth))
        formData.append('startYear', parseInt(startYear))
        if (!isPresent) {
            formData.append('endMonth', parseInt(endMonth))
            formData.append('endYear', parseInt(endYear))
        }
        if (description !== '') {
            formData.append('description', description)
        }

        const response = await apiHelper(`freelancer/employee/update`, 'PUT', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// delete Employment
export const deleteExistEmployment = async (id) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`freelancer/employee/delete/${id}`, 'DELETE', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// meeting list
export const getExistMeetings = async () => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`freelancer/meeting/list`, 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        // const data = jwtDecode(res.data);
        // console.log(data.items)

        // return { status: true, data: data?.items, message: res.message }
        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// insert meeting date list
export const insertMeetings = async (data) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { summary, startDate, endDate, description } = data;

        let formData = new FormData();
        formData.append('summary', summary)
        formData.append('description', description)
        formData.append('startDate', startDate)
        formData.append('endDate', endDate)

        const response = await apiHelper(`freelancer/meeting/book`, 'POST', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: res.data, message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// delete meeting date list
export const deleteMeeting = async (id) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`freelancer/meeting/cancel/${id}`, 'DELETE', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

// get admin availability list
export const getAvailability = async (id) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`admin/availablity/get`, 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        console.log(res)

        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}
// get admin availability for freelancer
export const getFreelancerAvailability = async (id) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`admin/availablity/list`, 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        // console.log(res)

        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}
// get admin availability for freelancer
export const submitProfile = async (id) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`freelancer/profile/submit`, 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message }
        }

        // console.log(res)
        localStorage.setItem('token', res.data);
        return { status: true, data: jwtDecode(res.data), message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}