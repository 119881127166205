import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { FiSearch } from 'react-icons/fi'
// import { FiSearch, FiChevronRight, FiChevronLeft, FiChevronsRight, FiChevronsLeft } from 'react-icons/fi'

const MySearch = (props) => {
    let input;
    const handleClick = (e) => {
        e.preventDefault();
        props.onSearch(input.value);
    };
    return (
        <form action="/" className="search-form" method="POST" onSubmit={handleClick}>
            <input
                className="form-control"
                ref={n => input = n}
                type="text"
                placeholder="Search"
            />
            <button type="submit"><FiSearch /></button>
        </form>
    );
};

class DataTable extends Component {

    customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    render() {

        const { columns, data, keyField, noDataIndication, onPageChange, onSizePerPageChange } = this.props;

        const paginationOptions = {
            custom: true,
            pageStartIndex: 1,
            totalSize: data.length,
            sizePerPage: 10,
            showTotal: true,
            paginationTotalRenderer: this.customTotal,
            disablePageTitle: false,
            onPageChange: onPageChange,
            onSizePerPageChange: onSizePerPageChange,
            // nextPageText:'',
            // prePageText:<FiChevronLeft />,
            // lastPageText:<FiChevronsRight />,
            // firstPageText:<FiChevronsLeft />
            // nextPageText:<FiChevronRight />,
            // prePageText:<FiChevronLeft />,
            // lastPageText:<FiChevronsRight />,
            // firstPageText:<FiChevronsLeft />
        };

        return (
            <ToolkitProvider
                keyField={keyField}
                data={data}
                columns={columns}
                search
            >
                {
                    toolkitprops => (

                        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                            {
                                ({
                                    paginationProps,
                                    paginationTableProps
                                }) => (
                                    <>
                                        <div className="table-wrap-header">
                                            <SizePerPageDropdownStandalone
                                                {...paginationProps}
                                            />
                                            <div className="table-search">
                                                <MySearch {...toolkitprops.searchProps} />
                                            </div>
                                        </div>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            classes={'table table-hover my-0'}
                                            // remote
                                            bordered={false}
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                            noDataIndication={noDataIndication}
                                        // onTableChange={onTableChange}
                                        />
                                        <div className="table-wrap-footer">
                                            <PaginationTotalStandalone {...paginationProps} />
                                            <PaginationListStandalone {...paginationProps} />
                                        </div>
                                    </>
                                )
                            }
                        </PaginationProvider>
                    )}
            </ToolkitProvider>
        )
    }
}

export default DataTable
