import React, { Component } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { MdKeyboardBackspace } from 'react-icons/md';
import ReactStars from "react-rating-stars-component";
import { jwtDecode } from '../../helper/jwt.helper';
import '../../assets/css/review.css';
import ConfirmModal from '../../components/confirmModal';
import SmallLoaderComponent from '../../helper/SmallLoaderComponent';
import { Formik } from 'formik';
import * as Yup from 'yup'
import review from '../../controller/review.controller';
import { notifyError, notifySuccess } from '../../helper/Alert';

class ReviewFreelancer extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            jobid: 0,
            fid: 0,
            // quality: 1,
            // communication: 1,
            // expertise: 1,
            // professionalism: 1,
            loader: false,
            formAction: null,
            workAgain: 1,
            comment: '',
            confirmModal: false
        }
    }

    componentDidMount() {
        this._unmounted = true;
        const { match: { params: { jobid } }, location: { search } } = this.props;
        const queryParams = new URLSearchParams(search)
        const fid = queryParams.get('fid')
        this.setState({
            formAction: null,
            workAgain: 1,
            comment: '',
            confirmModal: false,
            jobid: jobid,
            fid: fid
        })
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    backUrl = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    }

    closeConfirmModal = () => {
        this.setState({
            confirmModal: false
        })
    }

    openConfirmModal = (values, actions) => {
        this.setState({
            confirmModal: true,
            comment: values.comment,
            formAction: actions
        })
    }

    submitReview = async () => {
        this.setState({
            loader: true
        })

        const data = {
            descripction:this.state.comment,
            freelancerID:this.state.fid, 
            jobPostId:this.state.jobid, 
            rate:this.state.workAgain,
        }

        const res = await review.clientAddReview(data);
        if(!res.status){
           
            return notifyError(res.message)
        }
        this.state.formAction.resetForm({
            values:{
                comment:''
            }
        })
        notifySuccess(res.message)
        this.setState({
            loader:false,
            confirmModal: false,
            comment: '',
            formAction: null
        });
        this.props.history.goBack()
    }

    // qualityStarHandler = (rating) => {
    //     if (this._unmounted) {
    //         this.setState({
    //             quality: rating
    //         })
    //     }
    // }

    // communicationStarHandler = (rating) => {
    //     if (this._unmounted) {
    //         this.setState({
    //             communication: rating
    //         })
    //     }
    // }
    // expertiseStarHandler = (rating) => {
    //     if (this._unmounted) {
    //         this.setState({
    //             expertise: rating
    //         })
    //     }
    // }
    // ProfessStarHandler = (rating) => {
    //     if (this._unmounted) {
    //         this.setState({
    //             professionalism: rating
    //         })
    //     }
    // }
    againStarHandler = (rating) => {
        if (this._unmounted) {
            this.setState({
                workAgain: rating
            })
        }
    }

    render() {

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 1) {
                return <Redirect to="/freelancer/jobs" />
            }
        }

        const { match: { params: { jobid } } } = this.props;
        if (!jobid) {
            return <Redirect to="/client/my-jobs" />
        }

        const resonSchema = Yup.object().shape({
            comment: Yup.string().required('Comment must be required')
        });

        return (
            <section className={`section_job_details`}>
                <div className="container">
                    <div className={"d-flex justify-content-between mb-4"}>
                        <Link to={'/'} onClick={this.backUrl} className={'icon_back'}><MdKeyboardBackspace /></Link>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card shadow bg-white rounded border-0">
                                <div className="card-body p-4">
                                    <h1 className="mb-5"><strong>Leave a Review</strong></h1>
                                    {/* <div className="form-group mt-5 d-flex align-items-center justify-content-between">
                                        <label className="review-label h4 fw-bold">Quality</label>
                                        <ReactStars count={5} value={this.state.quality} edit={true} onChange={this.qualityStarHandler} size={25} activeColor="#ffc12d" />
                                    </div>
                                    <div className="form-group mt-3 d-flex align-items-center justify-content-between">
                                        <label className="review-label h4 fw-bold">Communication</label>
                                        <ReactStars count={5} value={this.state.communication} edit={true} onChange={this.communicationStarHandler} size={25} activeColor="#ffc12d" />
                                    </div>
                                    <div className="form-group mt-3 d-flex align-items-center justify-content-between">
                                        <label className="review-label h4 fw-bold">Expertise</label>
                                        <ReactStars count={5} value={this.state.expertise} edit={true} onChange={this.expertiseStarHandler} size={25} activeColor="#ffc12d" />
                                    </div>
                                    <div className="form-group mt-3 d-flex align-items-center justify-content-between">
                                        <label className="review-label h4 fw-bold">Professionalism</label>
                                        <ReactStars count={5} value={this.state.professionalism} edit={true} onChange={this.ProfessStarHandler} size={25} activeColor="#ffc12d" />
                                    </div> */}
                                    <div className="form-group mt-3 mb-3 d-flex align-items-center justify-content-between">
                                        <label className="review-label h4 fw-bold">How many rate to Freelancer</label>
                                        <ReactStars count={5} isHalf={true} value={this.state.workAgain} edit={true} onChange={this.againStarHandler} size={25} activeColor="#ffc12d" />
                                    </div>
                                    <Formik initialValues={{ comment: '' }} validationSchema={resonSchema} onSubmit={(values, actions) => this.openConfirmModal(values, actions)}>
                                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                            <form action="/" method="POST" onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <textarea className={`form-control ${errors.comment && touched.comment ? 'error' : ''}`} name="comment" rows="5" placeholder="Type your comment..." value={values.comment} onChange={handleChange} onBlur={handleBlur}></textarea>
                                                    {errors.comment && touched.comment ? <p className="error-text">{errors.comment}</p> : null}
                                                </div>
                                                <div className="form-group mt-5 d-flex align-items-center justify-content-between">
                                                    <button type="button" className="btn btn-outline-dark" onClick={() => this.props.history.goBack()}>Cancel</button>
                                                    <button type="submit" className="btn btn-success" style={{ minWidth: '100px' }} disabled={this.state.loader}>{this.state.loader ? <SmallLoaderComponent color="white" /> : 'Submit'}</button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        {this.state.confirmModal ?
                            <ConfirmModal
                                show={this.state.confirmModal}
                                onHide={this.closeConfirmModal}
                                text={'Are you sure you want to leave a review for Freelancer?'}
                                onSubmit={this.submitReview}
                            />
                            :
                            null
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(connect(null)(ReviewFreelancer))
