import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import '../../assets/css/common.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { notifyError, notifySuccess } from '../../helper/Alert';
import SmallLoaderComponent from '../../helper/SmallLoaderComponent';
import { forgotPassword } from '../../controller/auth.controller';

class ForgotComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader:false,
            form: {
                email: ''
            }
        }
    }

    formSubmit = async (e) => {
        try {

            const { email } = e;

            this.setState({
                loader: true
            });

            const res = await forgotPassword({email});

            if (res.status) {

                const { history } = this.props;
                notifySuccess(res.message);
                
                this.setState({
                    loader: false,
                    form: {
                        ...this.state.form,
                        email: '',
                    }
                });

                history.push('/login')

            } else {
                this.setState({
                    loader: false
                });
                notifyError(res.message);
            }

        } catch (err) {
            console.error(err.message);
        }
    }

    render() {

        const LoginSchema = Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Required')
        });

        return (
            <>
                <section className={`authWrapLog`}>
                    <div className="container">
                        <div className="row">
                            <div className={`col-sm-5 m-auto reset_bg`}>
                                <div className={'header_login'}>
                                    <h1 className={'title2'}>Forgot your password?</h1>
                                    <p className={'subTi'}>Enter your email address below and we will send you a link where you can enter a new password.</p>
                                </div>
                                <div className="login_wrap">
                                    <Formik initialValues={this.state.form} validationSchema={LoginSchema} onSubmit={(values) => this.formSubmit(values)}>
                                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (

                                            <form action="/" method="POST" onSubmit={handleSubmit}>

                                                <div className="form-group mb-4">
                                                    <input type="email" name="email" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                                    {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                                                </div>

                                                <div className="form-group">
                                                    <button type="submit" aria-label="Send" className="btn btn-primary btn-block" disabled={this.state.loader}>{this.state.loader?<SmallLoaderComponent />:'Send'}</button>
                                                </div>
                                                <div className={'content_account'}>
                                                    <p>No_account? Join Freelancing as a <Link to="/signup">freelancer</Link> or <Link to="/signup">company</Link><span className={'copyRigt'}>© Copyright 2010 - 2021 Freelancing, LLC</span></p>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(connect(null)(ForgotComponent));