import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { connect } from 'react-redux';
import { overviewAndTitle } from '../../../controller/profile.controller';

class TitleOverviewModal extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            title: '',
            overview: '',
            errors: {
                title: '',
                overview: ''
            },
            valid: {
                title: false,
                overview: false
            }
        }
    }

    componentDidMount() {
        this._unmounted = true;
        const { userData } = this.props.data;
        if (userData) {
            if (this._unmounted) {
                this.setState({
                    title: userData.bio ? userData.bio : '',
                    overview: userData.overview ? userData.overview : '',
                    errors: {
                        title: '',
                        overview: ''
                    },
                    valid: {
                        title: userData.bio ? true : false,
                        overview: userData.overview ? true : false
                    }
                })
            }
        }

    }
    componentWillUnmount() {
        this._unmounted = false;
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let errors = this.state.errors;
        let valid = this.state.valid;

        switch (name) {
            case 'title':
                valid.title = value.length > 0;
                errors.title = valid.title ? '' : 'Title must be required!'
                break;
            case 'overview':
                if (value.length > 0) {
                    if (value.length > 50 && value.length <= 5000) {
                        errors.overview = '';
                        valid.overview = true;
                    } else {
                        errors.overview = 'Overview must be between 50 to 5000 characters';
                        valid.overview = false;
                    }
                } else {
                    errors.overview = 'Overview must be required!';
                    valid = false
                }
                break;
            default:
                break;
        }

        this.setState({
            errors,
            valid
        })
    }

    userSubmit = async (e) => {
        e.preventDefault();

        const { valid, title, overview } = this.state;
        if (valid.title === false) {
            return notifyError('Title must be required!')
        }

        if (valid.overview === false) {
            return notifyError('Overview must be required!')
        }

        const res = await overviewAndTitle({title, overview});
        if(res.status){
            notifySuccess(res.message)
            this.props.data.onHide()
            this.props.data.updateFunction()
        }else{
            notifyError(res.message)
        }
    }

    render() {

        const { show, onHide } = this.props.data

        const { loader, title, overview, errors } = this.state;

        return (
            <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <h6 className="h5 pb-3 border-bottom">Title and Overview</h6>
                    <form action="/" method="POST" onSubmit={this.userSubmit}>
                        <div className="row pb-4 pt-4">
                            <div className="col-sm-12">
                                <div className={`${'form_group'} mb-4`}>
                                    <label className={'contact_label'}>Title</label>
                                    <input type="text" name="title" className={errors.title !== "" ? "form-control error" : "form-control"} placeholder="Example: Web Developer" value={title} onChange={this.inputHandler} />
                                    {errors.title !== '' ? <p className="error-text">{errors.title}</p> : null}
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className={`${'form_group'}`}>
                                    <label className={'contact_label'}>Professional Overview</label>
                                    <textarea className={errors.overview !== "" ? "form-control error" : "form-control"} rows="5" name="overview" value={overview} onChange={this.inputHandler} placeholder="Highlight your top skills, experince, and interests. This is one of the first things clients will see on your profile."></textarea>
                                    <p className={'info_textbox_text'}>{overview.length}/5000 characters (minimum 50)</p>
                                    {errors.overview !== '' ? <p className="error-text">{errors.overview}</p> : null}
                                </div>
                            </div>
                        </div>
                        <div className="form-group pt-3 border-top d-flex justify-content-between">
                            <button type="button" className="btn_cancle btn btn-outline-dark" onClick={onHide}>Cancel</button>
                            <button type="submit" aria-label="Update" className="btn btn-primary min-200 max-200" disabled={loader}>{loader ? <SmallLoaderComponent /> : 'Update'}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(connect(null)(TitleOverviewModal));