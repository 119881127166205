import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import { withRouter } from 'react-router-dom'
import DataTable from '../../components/DataTable';
import { getJobs } from '../../controller/jobs.controller';
import { FiEye } from 'react-icons/fi';
import { Button } from 'react-bootstrap';


class JobPostList extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            jobsList: [],
            details: null,
            descModal: false
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0)
        this.getJobsList()
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getJobsList = async () => {
        const res = await getJobs();
        // console.log(res);
        if (this._unmounted) {
            this.setState({
                jobsList: res
            })
        }
    }

    pageHandler = (page, sizePerPage) => {
        console.log(page);
    }

    sizePerPageChange = (sizePerPage, page) => {
        console.log(sizePerPage);
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Table is Empty</p>
        </div>
    }

    headerCenterFormatter = (column) => {
        return <div className="d-flex justify-content-center">{column.text}</div>
    }

    openModalDescription = (row) => {
        if (this._unmounted) {
            this.setState({
                details: row,
                descModal: true
            })
        }
    }

    closeModalDescription = () => {
        if (this._unmounted) {
            this.setState({
                details: null,
                descModal: false
            })
        }
    }

    descriptionFormatter = (cell, row) => {
        return <div style={{ maxWidth: '280px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{cell}</div>
    }

    budgetFormatter = (cell) => {
        return <div className="d-flex justify-content-center">${cell}</div>
    }

    actionFormatter = (cell, row) => {
        return <div className="d-flex justify-content-center">
            <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/admin/job-detail/${row.id}`)}><FiEye /></button>
        </div>
    }

    statusFormatter = (cell, row) => {
        // console.log(row);
        let classsName = '';
        // let statusName = '';
        switch (row.statesName) {
            case "Bidding":
                classsName = 'warning';
                // statusName = 'Pending';
                break;
            case "Done":
                classsName = 'success';
                // statusName = 'Active';
                break;
            case "In Progress":
                classsName = 'primary';
                // statusName = 'Pending';
                break;
            default:
                classsName = 'secondary';
                // statusName = 'none'
                break;
        }

        return <div className="d-flex justify-content-center">
            <Button variant={classsName} className={`rounded-pill`} style={{ fontSize: '.775rem', minWidth: '100px' }}>{cell}</Button>
        </div>
    }

    render() {

        const columns = [
            {
                dataField: 'title',
                text: 'Title',
                sort: true
            },
            {
                dataField: 'description',
                text: 'Description',
                sort: true,
                formatter: this.descriptionFormatter,
            },
            {
                dataField: 'name',
                text: 'Post by',
                sort: true
            },
            {
                dataField: 'budget',
                text: 'Budget',
                sort: true,
                formatter: this.budgetFormatter,
                headerFormatter: this.headerCenterFormatter
            },
            {
                dataField: 'statesName',
                text: 'Status',
                sort: true,
                formatter: this.statusFormatter,
                headerFormatter: this.headerCenterFormatter
            },
            {
                dataField: 'action',
                text: 'Action',
                sort: false,
                formatter: this.actionFormatter,
                headerFormatter: this.headerCenterFormatter
            }
        ];

        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3"><strong>Job Post List</strong></h1>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <DataTable
                                keyField="id"
                                data={this.state.jobsList}
                                columns={columns}
                                // onSizePerPageChange={this.sizePerPageChange} 
                                // onPageChange={this.pageHandler} 
                                noDataIndication={this.emptyRender}
                            />

                        </div>
                    </div>
                </div>
                {this.state.descModal ?
                    <Modal show={this.state.descModal} onHide={this.closeModalDescription} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                       
                        <Modal.Body className="p-4">
                            <h6 className="fs-3 mb-3"><b>{this.state.details.title}</b></h6>
                            <p className="fs-5">{this.state.details.description}</p>
                            <p className="fs-5">Post By: <b>{this.state.details.name}</b></p>
                            <p className="fs-5">Budget: <b>${this.state.details.budget}</b></p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary border-0" onClick={this.closeModalDescription}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    :
                    null
                }
            </div>
        )
    }
}

export default withRouter(connect(null)(JobPostList))
