import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import { adminChangePassword, updateProfile } from '../../controller/auth.controller';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { jwtDecode } from '../../../helper/jwt.helper';
// import { notifyError, notifySuccess } from '../../../helper/Alert';

class AdminProfile extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            passLoader: false,
            form: {
                firstname: '',
                lastname: '',
                email: ''
            },
            passForm: {
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            }
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);
        const adminToken = localStorage.getItem('adminToken');
        if (adminToken) {
            const decode = jwtDecode(adminToken);
            if (this._unmounted) {
                // console.log(decode)
                this.setState({
                    form: {
                        ...this.state.form,
                        firstname: decode.firstName,
                        lastname: decode.lastName,
                        email: decode.email
                    }
                })
            }
        }
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    formSubmit = async (values, actions) => {

        if (this._unmounted) {
            this.setState({
                loader: true
            })
        }

        const res = await updateProfile({ fname: values.firstname, lname: values.lastname });
        // console.log(res);
        if (res.status) {
            localStorage.setItem('adminToken', res.data)
            const decode = jwtDecode(res.data)
            // console.log(decode);
            if (this._unmounted) {
                this.setState({
                    loader: false,
                    form: {
                        firstname: decode.firstName,
                        lastname: decode.lastName,
                        email: decode.email
                    }
                })
            }
            notifySuccess(res.message);
        } else {
            if (this._unmounted) {
                this.setState({
                    loader: false
                })
            }
            notifyError(res.message)
        }

    }

    passwordSubmit = async (values, actions) => {
        if (this._unmounted) {
            this.setState({
                passLoader: true
            })
        }

        const res = await adminChangePassword(values);
        // console.log(res);
        if (res.status) {
            notifySuccess(res.message);
            if (this._unmounted) {
                this.setState({
                    passLoader: false
                })
                actions.resetForm({
                    values: {
                        currentPassword: '',
                        newPassword: '',
                        confirmPassword: ''
                    }
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    passLoader: false
                })
            }
            notifyError(res.message);
        }
    }

    render() {

        const profileSchema = Yup.object().shape({
            firstname: Yup.string().required('Required'),
            lastname: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email').required('Required')
        });

        const passwordSchema = Yup.object().shape({
            currentPassword: Yup.string().required('Required'),
            newPassword: Yup.string().required('Required'),
            confirmPassword: Yup.string().oneOf([Yup.ref("newPassword")], "Both password need to be the same").required('Required')
        });

        const { loader, passLoader } = this.state;

        let firstname = '';
        let lastname = '';
        let email = '';
        const adminToken = localStorage.getItem('adminToken');
        if (adminToken) {
            const decode = jwtDecode(adminToken);
            firstname = decode.firstName;
            lastname = decode.lastName;
            email = decode.email;
        }

        return (
            <div className="container-fluid p-0">
                <div className="row">
                    <h1 className="h3 mb-3"><strong>Profile</strong></h1>
                    <div className="col-12 col-xl-6">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Profile Details</h5>
                            </div>
                            <div className="card-body">
                                <Formik initialValues={{ firstname, lastname, email }} validationSchema={profileSchema} onSubmit={(valuess, actions) => this.formSubmit(valuess, actions)}>
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                        <form method="POST" onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="form-label">First Name</label>
                                                <input type="text" name="firstname" className={errors.firstname && touched.firstname ? "form-control error" : "form-control"} placeholder="First Name" onChange={handleChange} onBlur={handleBlur} value={values.firstname} />
                                                {errors.firstname && touched.firstname ? <p className="error-text">{errors.firstname}</p> : null}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Last Name</label>
                                                <input type="text" name="lastname" className={errors.lastname && touched.lastname ? "form-control error" : "form-control"} placeholder="Last Name" onChange={handleChange} onBlur={handleBlur} value={values.lastname} />
                                                {errors.lastname && touched.lastname ? <p className="error-text">{errors.lastname}</p> : null}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Email</label>
                                                <input type="email" name="email" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} disabled />
                                                {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                                            </div>

                                            <button type="submit" className="btn btn-primary mt-3" style={{ minWidth: '85px' }} disabled={loader}>{loader ? <SmallLoaderComponent /> : 'Save'}</button>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Change Password</h5>
                            </div>
                            <div className="card-body">
                                <Formik initialValues={this.state.passForm} validationSchema={passwordSchema} onSubmit={(values, actions) => this.passwordSubmit(values, actions)}>
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                        <form method="POST" onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="form-label">Current Password</label>
                                                <input type="password" name="currentPassword" className={errors.currentPassword && touched.currentPassword ? "form-control error" : "form-control"} placeholder="Current Password" onChange={handleChange} onBlur={handleBlur} value={values.currentPassword} />
                                                {errors.currentPassword && touched.currentPassword ? <p className="error-text">{errors.currentPassword}</p> : null}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">New Password</label>
                                                <input type="password" name="newPassword" className={errors.newPassword && touched.newPassword ? "form-control error" : "form-control"} placeholder="New Password" onChange={handleChange} onBlur={handleBlur} value={values.newPassword} />
                                                {errors.newPassword && touched.newPassword ? <p className="error-text">{errors.newPassword}</p> : null}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Confirm Password</label>
                                                <input type="password" name="confirmPassword" className={errors.confirmPassword && touched.confirmPassword ? "form-control error" : "form-control"} placeholder="Confirm Password" onChange={handleChange} onBlur={handleBlur} value={values.confirmPassword} />
                                                {errors.confirmPassword && touched.confirmPassword ? <p className="error-text">{errors.confirmPassword}</p> : null}
                                            </div>

                                            <button type="submit" className="btn btn-primary mt-3" style={{ minWidth: '85px' }} disabled={passLoader}>{passLoader ? <SmallLoaderComponent /> : 'Submit'}</button>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null)(AdminProfile))
