import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mainCategories } from '../../../controller/categories.controller';
import * as ACTION_TYPES from '../../../redux/actions/type';

function CategoryComponent(props) {

    const [categoryArray, setCategoryArray] = useState([])
    const [categorySelected, setCategorySelected] = useState([])
    const [error, setError] = useState('');

    useEffect(() => {
        const getMainCategoryList = async () => {
            try {

                const categoryList = await mainCategories();
                // console.log(categoryList);
                props.categoryArrayFun(categoryList);
                setCategoryArray(categoryList)

            } catch (err) {
                // console.log(err);
                setCategoryArray([])
            }
        }
        if (props.categoryList && props.categoryList.length > 0) {
            setCategoryArray(props.categoryList)
            setCategorySelected(props.categoryVal)
        } else {
            setCategorySelected(props.categoryVal)
            getMainCategoryList();
        }
    }, [props])
    // _unmounted = false;

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         categoryArray:[],
    //         categorySelected: [],
    //         error: ''
    //     }
    // }

    // componentDidMount() {
    //     this._unmounted = true;
    //     window.scroll(0, 0);
    //     if(this.props.categoryList && this.props.categoryList.length > 0){
    //         if (this._unmounted) {
    //             this.setState({
    //                 categoryArray:this.props.categoryList,
    //                 categorySelected: this.props.categoryVal
    //             })
    //         }
    //     }else{
    //         if (this._unmounted) {
    //             this.setState({
    //                 categorySelected: this.props.categoryVal
    //             })
    //         }
    //         this.getMainCategoryList();
    //     }
    // }

    // componentWillUnmount() {
    //     this._unmounted = false;
    // }



    const categoryHandler = (obj) => {
        let newArray = categorySelected;
        const isExsit = newArray.filter(info => info.id === obj.id);

        if (isExsit.length > 0) {
            newArray = newArray.filter(info => info.id !== obj.id);
        } else {
            newArray.push(obj);
        }
        setCategorySelected(newArray)
        setError('')
    }

    const nextStepFun = () => {
        if (categorySelected.length > 0) {
            if (props.isBackReview) {

                props.categoryFun(categorySelected);
                props.stepFun(5);
                props.backReviewFun(false);

            } else {

                const step = props.currentStep + 1;
                props.categoryFun(categorySelected);
                props.stepFun(step);
                props.backReviewFun(false);
            }
        } else {
            setError('Please select atleast one category')
        }
    }

    const prevStepFun = () => {
        const step = props.currentStep - 1;
        props.stepFun(step);
    }

    // render() {

    //     const { categorySelected, categoryArray, error } = this.state;


    // console.log(categorySelected);
    return (
        <div className={'content_steps_wrap'}>
            <div className={'step_body'}>
                <div className={'header_step'}>
                    <h2 className={'Header_Step_title'}>Category</h2>
                </div>
                <div className={'wrap_steps'}>
                    <ul className={'category_list'}>
                        {categoryArray.length > 0 ?
                            categoryArray.map((info, i) =>
                                <li key={i}>
                                    <input id={`category${info.id}`} type="checkbox" name="category" value={info.id} defaultChecked={categorySelected.map(item => item.id).includes(info.id)} onChange={() => categoryHandler(info)} />
                                    <label htmlFor={`category${info.id}`} className={`liDiv_list`} >{info.categoryName}</label>
                                </li>
                            )
                            :
                            <li>No record found.</li>
                        }
                    </ul>
                    {error !== "" ?
                        <div className="d-flex">
                            <p className="error-text">{error}</p>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            <div className={'step_footer'}>
                <button type="button" className="btn btn-primary min-200" onClick={nextStepFun}>Next</button>
                <button type="button" className="btn btn-outline-dark min-200" onClick={prevStepFun}>Prev</button>
            </div>
        </div>
    )
    // }
}

const mapStateToProps = state => ({
    currentStep: state.editJob.currentStep,
    categoryVal: state.editJob.categoryVal,
    isBackReview: state.editJob.isBackReview,
    categoryList: state.editJob.categoryArray
})

const mapDispatchToProps = dispatch => ({
    stepFun: (step) => dispatch({ type: ACTION_TYPES.STEP_EDIT_JOBS, payload: step }),
    categoryFun: (array) => dispatch({ type: ACTION_TYPES.CATEGORYS_EDIT_JOB, payload: array }),
    categoryArrayFun: (array) => dispatch({ type: ACTION_TYPES.CATEGORYS_EDIT_ARRAY, payload: array }),
    backReviewFun: (bool) => dispatch({ type: ACTION_TYPES.BACK_REVIEW_EDITJOB_STEPS, payload: bool })
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryComponent);
