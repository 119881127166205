import { apiHelper } from "../helper/api.helper"
import { jwtDecode } from "../helper/jwt.helper";

// client add Review 
const clientAddReview = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { descripction, freelancerID, jobPostId, rate } = data;

        let formData = new FormData();
        formData.append('descripction', descripction)
        formData.append('rate', rate)
        formData.append('jobPostId', jobPostId)
        formData.append('freelancerID', freelancerID)

        const response = await apiHelper(`client/review/add`, 'POST', formData, header);
        const res = response.data;
        if (res.status) {
            return { status: true, data: [], message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// freelancer add Review 
const freelancerAddReview = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const { descripction, clientID, jobPostId, rate } = data;

        let formData = new FormData();
        formData.append('descripction', descripction)
        formData.append('rate', rate)
        formData.append('jobPostId', jobPostId)
        formData.append('clientID', clientID)

        const response = await apiHelper(`freelancer/review/add`, 'POST', formData, header);
        const res = response.data;
        if (res.status) {
            return { status: true, data: [], message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// get freelancer all Review for he's profile
const getFreelancerAllReview = async (id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`freelancer/review/list`, 'GET', null, header);
        const res = response.data;
        if (res.status) {
            return { status: true, data: jwtDecode(res.data), message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// get Client all Review for he's profile
const getClientAllReview = async (id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`client/review/list`, 'GET', null, header);
        const res = response.data;
        if (res.status) {
            return { status: true, data: jwtDecode(res.data), message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

// get freelancer all Review for client side
const getAllFreelancerReviewById = async (id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`freelancer/review/list/${id}`, 'GET', null, header);
        const res = response.data;
        if (res.status) {
            return { status: true, data: jwtDecode(res.data), message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}
// get client all Review for freelancer side
const getAllClientReviewById = async (id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('token')
        }

        const response = await apiHelper(`client/review/list/${id}`, 'GET', null, header);
        const res = response.data;
        if (res.status) {
            return { status: true, data: jwtDecode(res.data), message: res.message }
        } else {
            return { status: false, data: [], message: res.message }
        }

    } catch (err) {
        return { status: false, message: err.message }
    }

}

const review = {
    clientAddReview,
    getAllFreelancerReviewById,
    getAllClientReviewById,
    freelancerAddReview,
    getFreelancerAllReview,
    getClientAllReview
}

export default review