import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { MdSearch, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import { getJobList } from '../../../controller/jobs.controller';
import moment from 'moment';
import { jwtDecode } from '../../../helper/jwt.helper';
// import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import '../../../assets/css/jobs.css';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';

class MyJobs extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'WORK_IN_PROGRESS',
            search: '',
            mainArray: [],
            page: 0,
            pageCount: 0,
            loader: false,
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getJobsList(this.state.activeTab, this.state.page, this.state.search)
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getJobsList = async (jobPostType, pageNo, searchText) => {

        const data = {
            jobPostType,
            pageNo: pageNo + 1,
            searchText
        };
        if (this._unmounted) {
            this.setState({
                loader: true
            })
        }

        const res = await getJobList(data);
        // console.log(jobList);
        if (res.status) {
            if (this._unmounted) {
                const count = Math.round(res.jobCount / 10);
                this.setState({
                    mainArray: res.data,
                    pageCount: count,
                    loader: false
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    mainArray: [],
                    loader: false
                })
            }
        }

    }

    tabHandler = (tabVal) => {
        this.setState({
            activeTab: tabVal
        });
        this.getJobsList(tabVal, this.state.page, this.state.search)
    }

    searchHandler = (e) => {

        const search = e.target.value;

        this.setState({
            search
        })

    }

    searchSubmit = (e) => {
        e.preventDefault();
        this.getJobsList(this.state.activeTab, this.state.page, this.state.search)
    }

    pageHandler = (page) => {
        if (page.selected) {

            this.setState({
                page: page.selected
            })

            this.getJobsList(this.state.activeTab, page.selected, this.state.search)
        } else {
            this.setState({
                page: page.selected
            })
            this.getJobsList(this.state.activeTab, 0, this.state.search)
        }
    }

    renderJobList = (info, i) => {
        // console.log(info);
        // let statusClass = `btn_status purple`;
        // switch(info.jobTypeID){
        //     case 
        //     default:
        //         break;
        // }

        return <li key={i}>
            <Link to={`/client/my-jobs/${info.jp_id}`}>
                <div className={'projectList_div d-flex flex-sm-column align-items-start justify-content-lg-between'}>
                    <div className={'left_wrap'}>
                        <h5 className={'job_title mb-3'}>{info.jp_title}</h5>

                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className={'details_text'}>
                            <p className={'job_description mb-1'}>Fixed-Price</p>
                            {info.jps_id === 4 ?
                                <p className={'job_description'}>Completed on {moment(info.completedAt).fromNow()} by You</p>
                                :
                                <p className={'job_description'}>Created on {moment(info.jp_timestamp).fromNow()}</p>
                            }
                            {/* <p className={'job_description'}>Closed - Dec 22, 2020</p> */}
                        </div>
                        <div className="d-flex align-items-center justify-content-between" style={{ width: '20%' }}>
                            <div>
                                <p className="mb-1">{info.bids_count}</p>
                                <p>Proposals</p>
                            </div>
                            {/* <div>
                            <p>0</p>
                            <p>Messaged</p>
                        </div> */}
                            <div>
                                <p className="mb-1">{info.hire_count}</p>
                                <p>Hired</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className={right_wrap}></div> */}
                </div>
            </Link>
        </li>
    }

    renderJobList1 = (info, index) => {
        // console.log(info);
        let statusClass = `btn_status purple`;
        // switch(info.jobTypeID){
        //     case 
        //     default:
        //         break;
        // }

        return <li key={index}>
            <Link to={`/client/my-jobs/${info.jp_id}`}>
                <div className={'projectList_div'}>
                    <div className={'left_wrap'}>
                        <h5 className={'job_title'}>{info.jp_title}</h5>
                        <div className={'details_text'}>
                            <p className={'job_description'}>{info.jp_description}</p>
                        </div>
                        <ul className={'listing'}>
                            <li>${info.budget}</li>
                            <li>{moment(info.jp_timestamp).format('Do MMM YYYY')}</li>
                            <li>{info.bids_count} bids</li>
                            <li>Job ID: {info.jp_id}</li>
                        </ul>
                        <div className={'status_wrap'}>
                            <button type="button" className={statusClass}>{info.name}</button>
                        </div>
                    </div>
                    {/* <div className={right_wrap}></div> */}
                </div>
            </Link>
        </li>
    }

    render() {

        const { loader, activeTab, search, mainArray } = this.state;

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 1) {
                return <Redirect to="/freelancer/jobs" />
            }
        }

        return (
            <>
                <section className={`jobs_wrap`}>
                    <div className="container">
                        <div className={'jobs_div'}>
                            <ul className={'nav_ul cus-nav-ul'}>
                                <li className={activeTab === 'WORK_IN_PROGRESS' ? 'active' : ''} onClick={() => this.tabHandler('WORK_IN_PROGRESS')}>Work in progress</li>
                                <li className={activeTab === 'PAST_PROJECT' ? 'active' : ''} onClick={() => this.tabHandler('PAST_PROJECT')}>Past projects</li>
                            </ul>
                            <div className={'search_jobs pt-0'}>
                                <form action="/" method="POST" onSubmit={this.searchSubmit}>
                                    <div className="form-group">
                                        <input type="text" className={`form-control search_input`} name="search" value={search} onChange={this.searchHandler} placeholder="Search" />
                                        <button type="submit" className={'btn_search'}><MdSearch /></button>
                                    </div>
                                </form>
                            </div>

                            <ul className={`jobsList ${loader ? 'p-3' : ''}`}>
                                {loader ?
                                    <MainLoaderComponent color="black" />
                                    :
                                    mainArray.length > 0 ?
                                        mainArray.map((info, i) => this.renderJobList(info, i))
                                        :
                                        <li className="text-center list-nofound">No Jobs Found.</li>
                                }
                            </ul>

                            {this.state.pageCount > 0 ?
                                <div className={'pagination_wrap_div'}>
                                    <ReactPaginate
                                        initialPage={this.state.page}
                                        // forcePage={this.state.page}
                                        pageCount={this.state.pageCount}
                                        pageRangeDisplayed={4}
                                        breakLabel={'...'}
                                        onPageChange={this.pageHandler}
                                        containerClassName={'pagination_wrap'}
                                        activeClassName={'active'}
                                        previousLabel={<MdKeyboardArrowLeft />}
                                        previousClassName={'previous'}
                                        nextLabel={<MdKeyboardArrowRight />}
                                        nextClassName={'next'}
                                    />
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(connect(null)(MyJobs))
