import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, withRouter } from 'react-router-dom'
import { MdKeyboardBackspace } from 'react-icons/md';
import DetailsSection from './DetailsSection';
import ProposalsSection from './ProposalsSection';
import PaySection from './PaySection';
// import FreelancerSection from './FreelancerSection';
import StatusSection from './StatusSection';
// import SubmitSection from './SubmitSection';
import { closedJob, getJobDetail } from '../../../controller/jobs.controller';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { jwtDecode } from '../../../helper/jwt.helper';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { mainCategories, subCategories } from '../../../controller/categories.controller';
import '../../../assets/css/jobDetails.css';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import FreelancerSection from './FreelancerSection';

function JobDetailsComponent(props) {

    // const router = useHistory();

    const [isLoader, setIsLoader] = useState(false);
    const [modalClosJob, setModalClosJob] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [jobID, setJobID] = useState(props.id);
    const [backUrl, setBackUrl] = useState('/');
    const [loaderCloseJob, setLoaderCloseJob] = useState(false);
    const [jobDetails, setJobDetails] = useState(null);
    const [hireFreelancer, setHireFreelancer] = useState([]);

    useEffect(() => {

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 1) {
                return props.history.push("/freelancer/jobs");
            }
        }

        const path = props.location.pathname.split('/')
        // console.log(path);
        if (Array.isArray(path) && path.length === 4) {
            setBackUrl(`/client/${path[2]}`)
        }

        const getJobDetailsApi = async () => {
            const id = props.match.params.id;
            // const id = window.location.href.split('/').pop();
            // const { id } = router.query;
            setIsLoader(true);
            const res = await getJobDetail(id);
            // console.log(id);
            if (res) {
                // console.log(res);
                setJobDetails(res);
                setHireFreelancer(res?.agentDetails)
                setIsLoader(false);

            } else {
                if (Array.isArray(path) && path.length === 4) {
                    if (path[2] === 'my-jobs') {

                        return props.history.push('/client/my-jobs');
                    }

                    if (path[2] === 'all-jobs') {

                        return props.history.push('/client/all-jobs');
                    }
                }
            }
        }
        setJobID(props.match.params.id)
        getJobDetailsApi();
    }, [jobID, props])

    const viewContract = () => {
        props.history.push(`/client/my-contracts/${jobID}`)
    }

    const openEditJob = async () => {
        if (jobDetails) {

            const { categoriesArray, skillsArray, jobIDFun, stepFun, titleFunc, categoryFun, skillsFun, budgetFun, descriptionFun, filesFun } = props;
            const cateIDs = jobDetails.services.map((info) => info.id);
            const categoryList = await mainCategories();
            const skillsList = await subCategories(cateIDs)

            if (categoryList.length > 0 && skillsList.length > 0) {

                categoriesArray(categoryList)
                skillsArray(skillsList);
                titleFunc(jobDetails.title);
                jobIDFun(jobID);
                categoryFun(jobDetails.services);
                skillsFun(jobDetails.skills);
                budgetFun(jobDetails.budget);
                descriptionFun(jobDetails.description);
                filesFun(jobDetails.attachments);
                stepFun(5);

                // router.push(`/client/edit-job/${jobID}`);
                props.history.push(`/client/edit-job/${jobID}`);
            }
        }
    }

    const tabFunction = (val) => {
        setActiveTab(val)
    }

    const openCloseJobModal = () => {
        setModalClosJob(true)
    }
    const closeJobModal = () => {
        setModalClosJob(false)
    }

    const closeJobHandler = async () => {
        // jobID
        setLoaderCloseJob(true);
        const res = await closedJob(jobID);
        if (res.status) {
            setLoaderCloseJob(false);
            notifySuccess(res.message);
            props.history.push('/client/my-jobs')
        } else {
            notifyError(res.message)
            setLoaderCloseJob(false);
        }
        // console.log(res);
    }
    // console.log(props);

    return (
        <>
            <section className={`section_job_details`}>
                <div className="container">
                    {/* <div className={"d-flex justify-content-between mb-4"}>
                        <Link to={backUrl} className={'icon_back'}><MdKeyboardBackspace /></Link> */}
                    {/* {jobDetails?.jobPostStateID === 1 ?
                            <div className="d-flex">
                                <button type="button" className={'btn btn-secondary rounded-5'} style={{ marginRight: '10px' }} onClick={openEditJob}>Edit Job</button>
                                {/* <Link href="/client/edit-job/[id]" as={`/client/edit-job/${jobID}`}><a className={'btn btn-secondary rounded-5'} style={{ marginRight: '10px' }}>Edit Job</a></Link> *
                                <button type="button" className={'btn btn-danger'} onClick={openCloseJobModal}>Close Job</button>
                                {/* <button type="button" className={pageStyle.btn_closeJobs} onClick={openCloseJobModal}>Close Job</button> *
                            </div>
                            :
                            null
                        } */}
                    {/* </div> */}
                    {/* <div className="row">
                        <div className="col-sm-12">
                            <div className="card shadow bg-white rounded border-0">
                                <div className="card-body p-4">
                                    {isLoader ?
                                        <MainLoaderComponent color="black" />
                                        :
                                        <>
                                            <ul className={`tabs_list md-4`}>
                                                {jobDetails?.jobPostStateID === 2 ?
                                                    <li className={activeTab === 0 ? 'active' : ''}><button type="button" onClick={() => tabFunction(0)}>Status</button></li>
                                                    :
                                                    null
                                                }
                                                {/* <li className={activeTab === 0? 'active':''}><button type="button" onClick={()=>tabFunction(0)}>Freelancer</button></li> *
                                                {jobDetails?.jobPostStateID === 1 || jobDetails?.jobPostStateID === 5 ?
                                                    <li className={activeTab === 0 ? 'active' : ''}><button type="button" onClick={() => tabFunction(0)}>Proposals</button></li> : null}
                                                {/* <li className={activeTab === 1? 'active':''}><button type="button" onClick={()=>tabFunction(1)}>Submit</button></li> *
                                                <li className={activeTab === 2 ? 'active' : ''}><button type="button" onClick={() => tabFunction(2)}>Details</button></li>
                                                <li className={activeTab === 3 ? 'active' : ''}><button type="button" onClick={() => tabFunction(3)} >Pay</button></li>
                                            </ul>
                                            {jobDetails?.jobPostStateID === 2 ? activeTab === 0 && <StatusSection details={jobDetails} /> : null}
                                            {/* {activeTab === 0 && <FreelancerSection />} *
                                            {jobDetails?.jobPostStateID === 1 || jobDetails?.jobPostStateID === 5 ? activeTab === 0 && <ProposalsSection details={jobDetails} /> : null}
                                            {/* {activeTab === 1 && <SubmitSection />} *
                                            {activeTab === 2 && <DetailsSection details={jobDetails} />}
                                            {activeTab === 3 && <PaySection details={jobDetails} />}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-sm-12">
                            {isLoader ?
                                <MainLoaderComponent color="black" />
                                :
                                <>
                                    <div className="d-flex p-4 justify-content-between">
                                        <h3 className="fw-bold fs-2 mb-0">{jobDetails && jobDetails.title}</h3>
                                        {jobDetails && jobDetails?.agentDetails && jobDetails.agentDetails.length === 0 ?
                                            <div className="d-flex">
                                                <button type="button" className={'btn btn-secondary rounded-5'} style={{ marginRight: '10px' }} onClick={openEditJob}>Edit Job</button>
                                                <button type="button" className={'btn btn-danger'} onClick={openCloseJobModal}>Close Job</button>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className={`card border-0`}>
                                        <div className="row">
                                            <div className={`col-sm-12 col-md-12 col-lg-12`}>
                                                <div className={'title_wrap_details border-bottom-0'}>

                                                    <ul className="small-tabs mt-3 ps-3 pe-3">
                                                        {/* {jobDetails?.jobPostStateID === 2 || jobDetails?.jobPostStateID === 3 ? <li className={activeTab === 0 ? 'active' : ''} onClick={() => tabFunction(0)}>Hire Freelancer</li> : null} */}
                                                        <li
                                                            className={`fs-4 ps-3 pe-3 ${activeTab === 0 ? 'active' : ''}`}
                                                            onClick={() => tabFunction(0)}
                                                        >
                                                            Job Details
                                                        </li>
                                                        <li className={`fs-4 ps-3 pe-3 ${activeTab === 1 ? 'active' : ''}`} onClick={() => tabFunction(1)}>Proposal</li>
                                                        {hireFreelancer.length > 0 ?
                                                            <li className={`fs-4 ps-3 pe-3 ${activeTab === 2 ? 'active ' : ''}`} onClick={() => tabFunction(2)}>Hire({hireFreelancer.length})</li>
                                                            :
                                                            null
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="tab-content">

                                                    {activeTab === 0 &&
                                                        <div className="p-4">

                                                            <div className={'description_wraps border-top-0'}>
                                                                <p className="mb-0">{jobDetails && jobDetails.description}</p>
                                                            </div>
                                                            <div className={'price_wrap ps-0 pe-0'}>
                                                                <p>${jobDetails && jobDetails.budget} <span>Fixed-Price</span></p>
                                                            </div>
                                                            <div className={'skills_wrap_detail ps-0 pe-0'}>
                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        <h5>Expertise</h5>
                                                                        {/* <p>Back-End Development Deliverables</p> */}
                                                                        <ul className={'budges_skills'}>
                                                                            {jobDetails && jobDetails.services.length > 0 ?
                                                                                jobDetails.services.map((info, i) => <li key={i} className="text-dark">{info.categoryName}</li>)
                                                                                :
                                                                                null
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <h5>Skills</h5>
                                                                        <ul className={'budges_skills'}>
                                                                            {jobDetails && jobDetails.skills.length > 0 ?
                                                                                jobDetails.skills.map((info, i) => <li key={i} className="text-dark">{info.categoryName}</li>)
                                                                                :
                                                                                null
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {activeTab === 1 && <ProposalsSection details={jobDetails} />}
                                                    {activeTab === 2 ?
                                                        <FreelancerSection profilePath={jobDetails && jobDetails.profilePath ? jobDetails.profilePath : ''} freelancers={jobDetails && jobDetails.agentDetails ? jobDetails.agentDetails : []} />
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className="col-sm-12 col-md-12 col-lg-3">
                                                <div className={`${'button_wrap'} border-bottom-0`}>
                                                    {jobDetails?.jobPostStateID === 1 ?
                                                        <div className="d-flex flex-column">
                                                            <button type="button" className={'btn btn-dark'} style={{ minWidth: '175px', marginBottom: '15px' }} onClick={openEditJob}>Edit Job</button>
                                                            {/* <Link href="/client/edit-job/[id]" as={`/client/edit-job/${jobID}`}><a className={'btn btn-secondary rounded-5'} style={{ marginRight: '10px' }}>Edit Job</a></Link> *
                                                            <button type="button" className={'btn btn-outline-danger'} style={{ minWidth: '175px', marginBottom: '15px' }} onClick={openCloseJobModal}>Close Job</button>
                                                            {/* <button type="button" className={pageStyle.btn_closeJobs} onClick={openCloseJobModal}>Close Job</button> *
                                                        </div>
                                                        :
                                                        <div className="d-flex flex-column">
                                                            <button type="button" className={'btn btn-dark'} style={{ minWidth: '175px', marginBottom: '15px' }} onClick={viewContract}>View Contract</button>
                                                        </div>
                                                    }
                                                    {/* {jobDetails?.jobPostStateID === 2 ?
                                                <button type="button" className={'btn btn-dark'} style={{ minWidth: '175px', marginBottom: '15px' }} onClick={openEditJob}>End Project</button>
                                                :
                                                null
                                            } *
                                                </div>
                                            </div> */}
                                            {/* <div className="col-sm-12 mt-5">
                                        <div className="card border-0">
                                            <h2 className={title_card_reviw}>Client's recent history (4)</h2>
                                        </div>
                                    </div> */}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <Modal show={modalClosJob} style={{ fontFamily: 'Noto Sans JP, sans-serif' }} dialogClassName={'modal_closeJob'} onHide={closeJobModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body style={{ textAlign: 'center' }}>
                        <h4 style={{ padding: '50px 0 50px', margin: '0', fontWeight: '600', fontSize: '20px' }}>Do You want to close this job?</h4>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                        <button type="button" className={"btn btn-outline-dark"} style={{ minWidth: '100px' }} onClick={closeJobModal}>Cancel</button>
                        <button type="button" className={"btn btn-primary"} style={{ minWidth: '100px' }} onClick={closeJobHandler} disabled={loaderCloseJob}>{loaderCloseJob ? <SmallLoaderComponent /> : 'Confirm'}</button>
                    </Modal.Footer>
                </Modal>
            </section >
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    stepFun: (step) => dispatch({ type: ACTION_TYPES.STEP_EDIT_JOBS, payload: step }),
    jobIDFun: (id) => dispatch({ type: ACTION_TYPES.EDIT_JOB_ID, payload: id }),
    titleFunc: (val) => dispatch({ type: ACTION_TYPES.TITLE_EDIT_JOB, payload: val }),
    categoriesArray: (array) => dispatch({ type: ACTION_TYPES.CATEGORYS_EDIT_ARRAY, payload: array }),
    categoryFun: (array) => dispatch({ type: ACTION_TYPES.CATEGORYS_EDIT_JOB, payload: array }),
    skillsArray: (array) => dispatch({ type: ACTION_TYPES.SKILLS_EDIT_ARRAY, payload: array }),
    skillsFun: (array) => dispatch({ type: ACTION_TYPES.SKILLS_EDIT_JOB, payload: array }),
    budgetFun: (val) => dispatch({ type: ACTION_TYPES.BUDGET_EDIT_JOB, payload: val }),
    descriptionFun: (val) => dispatch({ type: ACTION_TYPES.DESCRIPTION_EDIT_JOB, payload: val }),
    filesFun: (files) => dispatch({ type: ACTION_TYPES.FILES_EDIT_JOB, payload: files }),
})

export default withRouter(connect(null, mapDispatchToProps)(JobDetailsComponent))