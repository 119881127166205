import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import SmallLoaderComponent from '../../../helper/SmallLoaderComponent';
import { notifyError, notifySuccess } from '../../../helper/Alert';
import { MdAccountCircle } from "react-icons/md";
import { updateUserProfilePicture } from '../../../controller/profile.controller';
import { connect } from 'react-redux';


class PhotoModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            file: '',
            imgUrl: '',
            fileError: '',
            filevalid: false
        }
    }

    fileHandler = (e) => {
        const { files } = e.target;
        if (files.length > 0) {
            var t = files[0].type.split('/').pop().toLowerCase();
            if (t !== "jpeg" && t !== "jpg" && t !== "png") {
                this.setState({
                    file: '',
                    imgUrl: '',
                    filevalid: false,
                    fileError: 'Please select a valid image file'
                })
                // alert('Please select a valid image file');
                // document.getElementById(id).value = '';
                return notifyError('Please select a valid image file');
            }

            const url = URL.createObjectURL(files[0]);
            this.setState({
                file: files[0],
                imgUrl: url,
                filevalid: true,
                fileError: ''
            })
        }
    }

    deletePhoto = () => {
        this.setState({
            file: '',
            imgUrl: '',
            filevalid: false,
            fileError: ''
        })
    }

    userSubmit = async (e) => {
        e.preventDefault();

        const { filevalid } = this.state;
        if (filevalid === false) {
            return notifyError('Profile Photo must be required!')
        }

        const res = await updateUserProfilePicture(this.state.file);
        if (res.status) {
            const { onHide, updateFunction } = this.props.data
            this.setState({
                loader: false
            })
            onHide();
            updateFunction();
            notifySuccess(res.message)
        } else {
            this.setState({
                loader: false
            })
            notifyError(res.message)
        }
    }

    render() {

        const { show, onHide } = this.props.data

        const { loader, imgUrl, fileError } = this.state;

        return (
            <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <h6 className="h5 pb-3 border-bottom">Profile Photo</h6>
                    <form action="/" method="POST" onSubmit={this.userSubmit}>
                        <div className="row pb-4 pt-4">
                            <div className="col-sm-12">
                                <h2 className={`${'body_title'} fs-6`}>Please upload a professional portrait that clearly shows your face.</h2>
                                <div className={`${'form_group'}`}>
                                    {imgUrl === '' ?
                                        <>
                                            <div className={'file_wrap'}>
                                                <input id="profile" type="file" name="profile" onChange={this.fileHandler} />
                                                <label className={'file_label'} htmlFor="profile">
                                                    <i><MdAccountCircle /></i>
                                                    <p>Add Profile Photo</p>
                                                </label>
                                            </div>
                                            {fileError !== '' ? <p className="error-text mt-4">{fileError}</p> : null}
                                        </>
                                        :
                                        <div className={'image_wap'}>
                                            <div className={'image'}>
                                                <img src={imgUrl} alt="user" />
                                                {/* : */}
                                                {/* <img src={'/assets/images/dummy-userImg.jpg'} alt="user" /> */}
                                            </div>
                                            <button type="delete" className={'delete_btn'} onClick={this.deletePhoto}>Delete Photo</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-group pt-3 border-top d-flex justify-content-between">
                            <button type="button" className="btn_cancle btn btn-outline-dark" onClick={onHide}>Cancel</button>
                            <button type="submit" aria-label="Update" className="btn btn-primary min-200 max-200" disabled={loader}>{loader ? <SmallLoaderComponent /> : 'Update'}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(connect(null)(PhotoModal));