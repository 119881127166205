import React, { useState, useEffect } from 'react'
import '../../../assets/css/jobDetails.css';
import DummyUser from '../../../assets/images/dummy-user.jpg'
import { CHAT_URL } from '../../../controller/chat.controller';
import { io } from "socket.io-client";
import { jwtDecode } from '../../../helper/jwt.helper';
import { notifyError } from '../../../helper/Alert';

const SERVER = CHAT_URL;

function StatusSection(props) {

    const [socket, setSocket] = useState(null)

    const chatHandler = async (info) => {
        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            const data = {
                profileId: decode ? decode?.id : '',
                otherprofileId: info.id
            }
            socket.emit('createConversation', data);
        }
    }

    useEffect(() => {

        const configureSocket = () => {
            var socket2 = io(SERVER, {
                secure: true
            });
            // , {
            //     reconnection: false,
            //     transports: ["websocket", "polling"]
            // }
            socket2.on('connection', () => {
                // console.log(socket);
                // console.log('socket is connected!')
            });

            socket2.on("errorwhileCreateConversation", err => {
                console.log(err);
                if (err) {
                    notifyError(err);
                }
            })

            socket2.on('privateConversationCreated', data => {
                // console.log(data);
                if (data) {
                    localStorage.setItem('chatID', data.id)
                    props.history.push(`/message`)
                }
            })

            setSocket(socket2);
        }
        configureSocket();
    }, [])

    const { details } = props;

    return (
        <div className={'tab_content'}>
            <div className="row">
                <div className="col-sm-12">
                    <h3 className={'title_listing'}>Hire Freelancers</h3>
                </div>
                {details?.agentDetails && details.agentDetails.length > 0 ?
                    details.agentDetails.map((info, i) =>
                        <div className="col-sm-6 mb-5">
                            <div className={'freelancer_wr'} key={i}>
                                <div className={'img_wr'}>
                                    {info?.profilePic ?
                                        <img src={`${details?.profilePath}${info.profilePic}`} alt="users" />
                                        :
                                        <img src={DummyUser} alt="users" />
                                    }
                                </div>
                                <p className={'user_name'}>{info.name} <br /><span>${details?.budget}/<small>Project</small></span></p>
                                <div>
                                    <button type="button" aria-label="Chat" className={'btn_chat'} onClick={() => chatHandler(info)}>Chat</button>
                                    
                                </div>
                            </div>
                        </div>
                    )
                    :
                    null
                }

                {/* <div className="col-sm-12">
                    <h3 className={'title_listing'}>Job Process</h3>
                    <div className={'step_wrap'}>
                        <ul className={'steps_ul'}>
                            <li className={`stepper__step stepper__step_done`}>
                                <div className={'step'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={'hired'} width="38" height="31.667" viewBox="0 0 38 31.667">
                                        <g id="Group_1077" data-name="Group 1077" transform="translate(-538.453 -619.323)">
                                            <path id="Path_522" data-name="Path 522" d="M20.75,15.5h8.708v2.375H20.75Z" transform="translate(541.057 615.698)"></path>
                                            <path id="Path_523" data-name="Path 523" d="M20.75,19.5h8.708v2.375H20.75Z" transform="translate(541.057 618.031)"></path>
                                            <path id="Path_524" data-name="Path 524" d="M20.75,23.5h8.708v2.375H20.75Z" transform="translate(541.057 620.365)"></path>
                                            <path id="Path_528" data-name="Path 528" d="M39.646,38.5H10.354A4.358,4.358,0,0,1,6,34.146V14.354A4.358,4.358,0,0,1,10.354,10h9.5v2.375h-9.5a1.982,1.982,0,0,0-1.979,1.979V34.146a1.982,1.982,0,0,0,1.979,1.979H39.646a1.982,1.982,0,0,0,1.979-1.979V14.354a1.982,1.982,0,0,0-1.979-1.979h-9.5V10h9.5A4.358,4.358,0,0,1,44,14.354V34.146A4.358,4.358,0,0,1,39.646,38.5Z" transform="translate(532.453 612.49)" fill="#fff"></path>
                                            <path id="Path_529" data-name="Path 529" d="M23.9,16.708H16.771A2.774,2.774,0,0,1,14,13.937V10.771A2.774,2.774,0,0,1,16.771,8H23.9a2.774,2.774,0,0,1,2.771,2.771v3.167A2.774,2.774,0,0,1,23.9,16.708Zm-7.125-6.333a.4.4,0,0,0-.4.4v3.167a.4.4,0,0,0,.4.4H23.9a.4.4,0,0,0,.4-.4V10.771a.4.4,0,0,0-.4-.4Z" transform="translate(537.119 611.323)" fill="#fff"></path>
                                            <path id="Path_530" data-name="Path 530" d="M14.958,22.917a3.958,3.958,0,1,1,3.958-3.958A3.963,3.963,0,0,1,14.958,22.917Zm0-5.542a1.583,1.583,0,1,0,1.583,1.583A1.585,1.585,0,0,0,14.958,17.375Z" transform="translate(535.37 615.407)" fill="#fff"></path>
                                            <path id="Path_531" data-name="Path 531" d="M23.25,26.146H20.875v-.792A1.982,1.982,0,0,0,18.9,23.375H13.354a1.982,1.982,0,0,0-1.979,1.979v.792H9v-.792A4.358,4.358,0,0,1,13.354,21H18.9a4.358,4.358,0,0,1,4.354,4.354Z" transform="translate(534.203 618.906)" fill="#fff"></path>
                                            <path id="Path_532" data-name="Path 532" d="M20.75,15.5h8.708v2.375H20.75Z" transform="translate(541.057 615.698)" fill="#fff"></path> <path id="Path_533" data-name="Path 533" d="M20.75,19.5h8.708v2.375H20.75Z" transform="translate(541.057 618.031)" fill="#fff"></path>
                                            <path id="Path_534" data-name="Path 534" d="M20.75,23.5h8.708v2.375H20.75Z" transform="translate(541.057 620.365)" fill="#fff"></path>
                                        </g>
                                    </svg>
                                </div>
                                <p className={'step_title'}>Hired</p>
                            </li>
                            <li className={`stepper__step stepper__step_done`}>
                                <div className={'step'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={'deposit'} width="36" height="36" viewBox="0 0 36 36">
                                        <g id="Group_1075" data-name="Group 1075" transform="translate(1155.584 -3164.663)">
                                            <path id="Path_499" data-name="Path 499" d="M40.5,15.75V18H6.75V15.75l16.875-9Z" transform="translate(-1161.209 3159.038)"></path>
                                            <path id="Path_500" data-name="Path 500" d="M10.125,16.875,26.367,8.213,23.625,6.75l-16.875,9V18h3.375Z" transform="translate(-1161.209 3159.038)"></path>
                                            <path id="Path_501" data-name="Path 501" d="M9.75,14.25H34.5v22.5H9.75Z" transform="translate(-1159.709 3162.788)" fill="none"></path>
                                            <path id="Path_502" data-name="Path 502" d="M9.75,14.25h3.375v22.5H9.75Z" transform="translate(-1159.709 3162.788)" fill="none"></path>
                                            <path id="Path_507" data-name="Path 507" d="M40.875,19.5H7.125A1.125,1.125,0,0,1,6,18.375v-2.25a1.125,1.125,0,0,1,.6-.993l16.875-9a1.128,1.128,0,0,1,1.058,0l16.875,9a1.123,1.123,0,0,1,.6.993v2.25A1.125,1.125,0,0,1,40.875,19.5ZM8.25,17.25h31.5V16.8L24,8.4,8.25,16.8Z" transform="translate(-1161.584 3158.663)" fill="#fff"></path>
                                            <path id="Path_508" data-name="Path 508" d="M6.75,28.5H40.5v2.25H6.75Z" transform="translate(-1161.209 3169.913)" fill="#fff"></path>
                                            <path id="Path_509" data-name="Path 509" d="M19.82,28h-3.2V25.75h3.2a.938.938,0,0,0,0-1.875H18.68a3.188,3.188,0,0,1,0-6.375h3.2v2.25h-3.2a.938.938,0,0,0,0,1.875h1.14a3.188,3.188,0,0,1,0,6.375Z" transform="translate(-1156.834 3164.413)" fill="#fff"></path>
                                            <path id="Path_510" data-name="Path 510" d="M17.25,16.75H19.5V19H17.25Z" transform="translate(-1155.959 3164.038)" fill="#fff"></path> <path id="Path_511" data-name="Path 511" d="M17.25,23.75H19.5V26H17.25Z" transform="translate(-1155.959 3167.538)" fill="#fff"></path>
                                            <path id="Path_512" data-name="Path 512" d="M9,14.25h2.25v22.5H9Z" transform="translate(-1160.084 3162.788)" fill="#fff"></path> <path id="Path_513" data-name="Path 513" d="M25.5,14.25h2.25v22.5H25.5Z" transform="translate(-1151.834 3162.788)" fill="#fff"></path>
                                        </g>
                                    </svg>
                                </div>
                                <p className={'step_title'}>Deposit</p>
                            </li>
                            <li className={`stepper__step stepper__step_done`}>
                                <div className={'step'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={'submit'} width="40.4" height="30.4" viewBox="0 0 40.4 30.4">
                                        <g id="Group_1078" data-name="Group 1078" transform="translate(1024.636 -3165.434)">
                                            <path id="Path_564" data-name="Path 564" d="M17.25,11.75h1.875V26.125H17.25Z" transform="translate(-1022.623 3160.716)" stroke="#000" strokeWidth="0.4"></path>
                                            <path id="Path_565" data-name="Path 565" d="M22.345,14.662l-3.4-3.4-3.4,3.4L14.22,13.337l4.063-4.062a.938.938,0,0,1,1.326,0l4.063,4.062Z" transform="translate(-1023.381 3161.279)" stroke="#000" strokeWidth="0.4"></path>
                                            <path id="Path_566" data-name="Path 566" d="M26.312,29.75H13.187A2.19,2.19,0,0,1,11,27.562V18.187A2.19,2.19,0,0,1,13.187,16h3.125v1.875H13.187a.313.313,0,0,0-.313.313v9.375a.313.313,0,0,0,.313.313H26.312a.313.313,0,0,0,.313-.313V18.187a.313.313,0,0,0-.312-.312H23.187V16h3.125A2.19,2.19,0,0,1,28.5,18.187v9.375A2.19,2.19,0,0,1,26.312,29.75Z" transform="translate(-1024.186 3161.779)" stroke="#000" strokeWidth="0.4"></path>
                                            <path id="Path_567" data-name="Path 567" d="M32.563,36H9.438A3.44,3.44,0,0,1,6,32.563V9.438A3.44,3.44,0,0,1,9.438,6H32.563A3.44,3.44,0,0,1,36,9.438V32.563A3.44,3.44,0,0,1,32.563,36ZM9.438,7.875A1.565,1.565,0,0,0,7.875,9.438V32.563a1.565,1.565,0,0,0,1.563,1.563H32.563a1.565,1.565,0,0,0,1.563-1.562V9.438a1.565,1.565,0,0,0-1.562-1.562Z" transform="translate(-1025.436 3159.634)" stroke="#000" strokeWidth="0.4"></path>
                                            <path id="Path_568" data-name="Path 568" d="M2,28.5H42v1.875H2Z" transform="translate(-1026.436 3165.259)" stroke="#000" strokeWidth="0.4"></path>
                                        </g>
                                    </svg>
                                </div>
                                <p className={'step_title'}>Submit</p>
                            </li>
                            <li className={`stepper__step stepper__step_current`}>
                                <div className={'step'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={'paid'} width="31.304" height="30" viewBox="0 0 31.304 30">
                                        <g id="Group_1079" data-name="Group 1079" transform="translate(1065.547 -3167.525)">
                                            <path id="Path_542" data-name="Path 542" d="M9.963,27.345l-.7-.283.67-.687v-1l.7.283-.67.689Z" transform="translate(-1070.553 3166.118)" fill="#d5d5d5"></path>
                                            <path id="Path_543" data-name="Path 543" d="M21.75,25.09v-.013h1.957Z" transform="translate(-1066.754 3166.027)" fill="#d5d5d5"></path>
                                            <path id="Path_549" data-name="Path 549" d="M22.109,15.217a2.609,2.609,0,1,1,2.609-2.609A2.611,2.611,0,0,1,22.109,15.217Zm0-3.261a.652.652,0,1,0,.652.652A.653.653,0,0,0,22.109,11.957Z" transform="translate(-1067.438 3161.438)" fill="#d5d5d5"></path>
                                            <path id="Path_550" data-name="Path 550" d="M16.5,11.25h1.63v1.957H16.5Z" transform="translate(-1068.351 3161.818)" fill="#d5d5d5"></path> <path id="Path_551" data-name="Path 551" d="M25.25,11.25h1.63v1.957H25.25Z" transform="translate(-1065.688 3161.818)" fill="#d5d5d5"></path>
                                            <path id="Path_552" data-name="Path 552" d="M31.283,20.043h-15A2.285,2.285,0,0,1,14,17.761V9.283A2.285,2.285,0,0,1,16.283,7h15a2.285,2.285,0,0,1,2.283,2.283v8.478A2.285,2.285,0,0,1,31.283,20.043Zm-15-11.087a.326.326,0,0,0-.326.326v8.478a.326.326,0,0,0,.326.326h15a.326.326,0,0,0,.326-.326V9.283a.326.326,0,0,0-.326-.326Z" transform="translate(-1069.112 3160.525)" fill="#d5d5d5"></path>
                                            <path id="Path_553" data-name="Path 553" d="M10.565,31.043H6.978A.979.979,0,0,1,6,30.065V18.978A.979.979,0,0,1,6.978,18c4.572,0,6.7,1.587,6.789,1.655a.978.978,0,0,1,.351,1.033L11.51,30.322A.979.979,0,0,1,10.565,31.043ZM7.957,29.087h1.86l2.215-8.18a11.091,11.091,0,0,0-4.076-.922v9.1Z" transform="translate(-1071.547 3163.873)" fill="#d5d5d5"></path>
                                            <path id="Path_554" data-name="Path 554" d="M21.9,32.054c-3.142,0-9.145-2.609-12.443-4.162l.835-1.77C15.656,28.65,19.887,30.1,21.9,30.1c2.645,0,8.65-2.857,11.8-4.505a3.345,3.345,0,0,0-1.688-.386,13.822,13.822,0,0,0-3.93.929,15.13,15.13,0,0,1-4.548,1.028A23.456,23.456,0,0,1,17,25.8l.668-1.839a21.822,21.822,0,0,0,5.862,1.246,13.822,13.822,0,0,0,3.93-.929,15.13,15.13,0,0,1,4.548-1.028c3.233,0,4.132,2.153,4.169,2.246a.976.976,0,0,1-.436,1.22C35.351,26.933,26.021,32.054,21.9,32.054Z" transform="translate(-1070.494 3165.471)" fill="#d5d5d5"></path>
                                            <path id="Path_555" data-name="Path 555" d="M26.942,26.647H24.985V26.06a1.635,1.635,0,0,0-1.3-1.6L11.12,21.89l.393-1.916,12.561,2.571a3.6,3.6,0,0,1,2.87,3.514v.588Z" transform="translate(-1069.989 3164.474)" fill="#d5d5d5"></path>
                                        </g>
                                    </svg>
                                </div>
                                <p className={'step_title'}>Paid</p>
                            </li>
                        </ul>
                        <p className={'content_text'}><span>Please check the job and release the <span className={'payment_color'}>payment</span> </span><br /><span style={{ lineHeight: '70px' }}>Contact our live support if you need help. </span></p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default StatusSection
