import { apiHelper } from "../../helper/api.helper";
import { jwtDecode } from "../../helper/jwt.helper";

export const getJobs = async () => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }


    const response = await apiHelper('admin/jobpost/get', 'GET', null, header);
    const res =  response.data;
    if(res.status === false){
        return []
    }
    
    return jwtDecode(res.data);
}

export const JobDetail = async (id) => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }


    const response = await apiHelper(`admin/jobpostbid/get/${id}`, 'GET', null, header);
    const res =  response.data;
    if(res.status === false){
        return null
    }
    
    return jwtDecode(res.data);
}