import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter, Redirect } from 'react-router-dom';
import { MdKeyboardBackspace, MdLocationOn } from 'react-icons/md';
import DummyUser from '../../../assets/images/dummy-user.jpg';
import ReactStars from "react-rating-stars-component";
import '../../../assets/css/jobDetails.css';
import { singleBid } from '../../../controller/jobs.controller';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';
import { jwtDecode } from '../../../helper/jwt.helper';

export class HireFreelancer extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            backUrl: '',
            bid_id: '',
            fid: '',
            userData: null,
            bidDetails: null,
            profileURL: '',
            priceVal: '',
            priceError: '',
            milestonVal: 0,
            milestonValError: ''
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);
        const { history, location, match: { params: { fid } } } = this.props;
        if (!fid) {
            return history.goBack();
        }
        const searchQuery = new URLSearchParams(location.search);
        const bid_id = searchQuery.get('bid_id');
        if (!bid_id) {
            return history.goBack();
        }

        const backUrl = '/';
        // console.log(bid_id)
        this.getBid(bid_id);
        if (this._unmounted) {
            this.setState({
                fid,
                bid_id,
                backUrl
            })
        }
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getBid = async (bid_id) => {
        this.setState({
            loader: true
        })
        const res = await singleBid(bid_id)
        // console.log(res);

        if (res) {
            if (this._unmounted) {
                this.setState({
                    userData: res,
                    loader: false,
                    priceVal: res.bidAmount
                })
            }
        } else {
            this.setState({
                loader: false
            })
            this.props.history.goBack();
        }
    }

    getinputPrice = (e) => {
        const { value } = e.target;
        let error = '';
        if (value !== '') {
            if (value > 0) {
                const reg = new RegExp(/^\d+$/);
                if (!reg.test(value)) {
                    error = 'Fixed price must be in numerical'
                }
            } else {
                error = 'Fixed price up to 0'
            }
        } else {
            error = 'Fixed price must be required.'
        }

        if (this._unmounted) {
            this.setState({
                priceError: error,
                priceVal: value
            })
        }
    }
    getinputMilstone = (e) => {
        const { value } = e.target;
        let error = '';
        if (value !== '') {
            if (value > 0) {
                const reg = new RegExp(/^\d+$/);
                if (!reg.test(value)) {
                    error = 'Milestone amount must be in numerical'
                }
            } else {
                error = 'Milestone amount up to 0'
            }
        } else {
            error = 'Milestone amount must be required.'
        }

        if (this._unmounted) {
            this.setState({
                milestonValError: error,
                milestonVal: value
            })
        }
    }

    goBack = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    }

    imgError = (e) => {
        e.target.src = DummyUser
    }

    confirmHire = () => {
        const { history } = this.props;
        const { userData, milestonVal, milestonValError } = this.state;
        if(milestonVal <= 0){
            return this.setState({
                milestonValError:milestonValError !== ''?milestonValError:'Milestone amount not valid'
            })
        }
        localStorage.setItem('amount', JSON.stringify({FixedAmount:this.state.priceVal, milestonAmount:this.state.milestonVal}))
        history.push(`/client/deposit/${userData?.jobPostID}?f_id=${userData?.profileID}`)
    }

    render() {
        // console.log(this.props)
        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 1) {
                return <Redirect to="/freelancer/jobs" />
            }
        }

        const { loader, priceVal, backUrl, userData, priceError, milestonVal, milestonValError } = this.state;
        // && bidDetails !== null


        return (
            <section className={`section_job_details`}>
                <div className="container">
                    <div className={"d-flex justify-content-between mb-4"}>
                        <Link to={backUrl} onClick={this.goBack} className={'icon_back'}><MdKeyboardBackspace /></Link>

                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card shadow bg-white rounded border-0">
                                <div className={`card-body ${loader?'p-4':'p-0'}`}>
                                    {loader ?
                                        <MainLoaderComponent />
                                        :
                                        <ul className={`freelancer_list_wrap`}>
                                            {userData !== null ?
                                                <li className="mb-0">
                                                    <div className={'user_prof'}>
                                                        {userData.profilePic ?
                                                            <img src={`${userData.profileURL}${userData.profilePic}`} onError={this.imgError} alt="freelancer" />
                                                            :
                                                            <img src={DummyUser} alt="freelancer" />
                                                        }
                                                    </div>
                                                    <div className={'right_detail'}>
                                                        <div className={'profile_wrap'}>
                                                            <div className={'caption'}>
                                                                <h5 className={'review_title'}>{userData.name}</h5>
                                                                <div className={'user_review'}>
                                                                    <div className={`rating cust`}>
                                                                        <p className={'rating_badge'}>1.78</p>
                                                                        <ReactStars count={5} value={3} edit={false} size={15} activeColor="#ffc12d" />
                                                                        <span>(22 reviews)</span>
                                                                    </div>
                                                                </div>
                                                                <div className={'user_r_detail'}>
                                                                    <i className={'location_icon'}><MdLocationOn /></i>
                                                                    {/* <img className={pageStyle.flag} src="/assets/images/dummy-user.jpg" alt="India" /> */}
                                                                    <p>{userData.city ? `${userData.city}, ` : null} {userData.stateName ? `${userData.stateName}, ` : null} {userData.countryName ? `${userData.countryName}` : null}</p>
                                                                </div>
                                                            </div>
                                                            <p className={'price'}>
                                                                <span className={'pric'}>${userData.bidAmount}/ Project</span>
                                                                <span className={'deadline'}>{userData.deadlineValue} Days</span>
                                                            </p>
                                                        </div>
                                                        <div className={'bottom_freela'}>
                                                            <div className={'pricing_block'}>
                                                                <label>Milestone Amount</label>
                                                                <div className={'input_di'}>
                                                                    <span>$</span>
                                                                    <input type="text" name="milestone" value={milestonVal} onChange={this.getinputMilstone} />
                                                                </div>
                                                                {milestonValError !== '' ? <p className={`errorText error-text`}>{milestonValError}</p> : null}
                                                            </div>
                                                            <div className={'pricing_block'}>
                                                                <label>Fixed Amount</label>
                                                                <div className={'input_di'}>
                                                                    <span>$</span>
                                                                    <input type="text" name="price" value={priceVal} onChange={this.getinputPrice} />
                                                                </div>
                                                                {priceError !== '' ? <p className={`errorText error-text`}>{priceError}</p> : null}
                                                            </div>

                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="button" className={"btn btn-outline-dark me-3"} style={{ minWidth: '100px' }} onClick={() => this.props.history.goBack()}>Cancel</button>
                                                            <button type="button" className={"btn btn-primary"} style={{ minWidth: '160px' }} onClick={() => this.confirmHire()}>Confirm and Hire</button>
                                                        </div>
                                                    </div>
                                                </li>
                                                :
                                                <li>
                                                    No found.
                                                </li>
                                            }
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(connect(null)(HireFreelancer))
