import { combineReducers } from 'redux';
import categoriesAdminReducers from './admin/categoriesReducers';
import layoutReducers from './admin/layoutReducers';
import questionReducers from './admin/questionReducer';
import ChatReducers from './chatReducers';
import EditJobReducers from './editJobReducers';
import loginReducers from './loginReducers';
import PostJobReducers from './postJobReducers';
import ProfessionalInfoReducers from './professional_info';
import UserReducers from './userReducers';

export const rootReducers = combineReducers({
    login: loginReducers,
    postJob: PostJobReducers,
    editJob: EditJobReducers,
    pinfo: ProfessionalInfoReducers,
    user: UserReducers,
    // admin
    layout: layoutReducers,
    questions: questionReducers,
    categoriesAdmin: categoriesAdminReducers,
    chat:ChatReducers
});