import React, { Component } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
// import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFileDownload, MdAttachFile } from 'react-icons/md';
import '../../../assets/css/myProposals.css';
import { getSingleJobdetails, myProposalsList } from '../../../controller/jobs.controller';
import { notifyError } from '../../../helper/Alert';
import { jwtDecode } from '../../../helper/jwt.helper';
import moment from 'moment';
import MainLoaderComponent from '../../../helper/MainLoaderComponent';

class MyProposalsComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            tabActive: 0,
            pageCount: 0,
            page: 0,
            jobID: null,
            openDetailsPage: false,
            proposalDetails: null,
            allProposals: []
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0)
        this.getMyProposals();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getMyProposals = async () => {
        try {
            if (this._unmounted) {
                this.setState({
                    isLoader: true
                })
            }
            const res = await myProposalsList();
            // console.log(res);
            if (res.status) {
                if (this._unmounted) {
                    this.setState({
                        allProposals: res.data,
                        isLoader: false
                    })
                }
            } else {
                if (this._unmounted) {
                    this.setState({
                        allProposals: [],
                        isLoader: false
                    })
                }
            }

        } catch (err) {
            console.log(err);
            notifyError(err.message)
            if (this._unmounted) {
                this.setState({
                    isLoader: false
                })
            }
        }
    }

    tabHandler = (tab) => {
        this.setState({
            tabActive: tab
        })
    }

    pageHandler = (pageNo) => {
        // console.log(pageNo);
        if (pageNo.selected) {
            this.setState({
                page: pageNo.selected
            })
            // getJobsList(expertise, pageNo.selected, search)
        } else {
            this.setState({
                page: 0
            })
            // getJobsList(expertise, 0, search)
        }

    }

    viewJobDetails = async (info) => {
        const res = await getSingleJobdetails(info.id);
        this.setState({
            jobID: info.id,
            proposalDetails: res,
            openDetailsPage: true
        });
    }

    downloadImage = (imageUrl) => {
        const fileName = imageUrl.split('/').pop();
        fetch(imageUrl).then(function (t) {
            t.blob().then(b => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(b);
                link.download = fileName;
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
        })

    }

    closedJobDetails = () => {
        this.setState({
            jobID: null,
            proposalDetails: null,
            openDetailsPage: false
        });
    }

    updateProposal = (id) => {
        this.props.router.push(`/freelancer/proposal/${id}`)
    }

    render() {

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode.profileType === 2) {
                return <Redirect to="/client/my-jobs" />
            }
        }

        const { isLoader, openDetailsPage, proposalDetails, allProposals } = this.state;

        return (
            <>
                <section className={`proposals_section pt-5`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className={'pageTitle'}>My Proposals</h1>
                            </div>
                            <div className="col-sm-12 mt-5">
                                <div className="card border-0 p-4">
                                    {/* <ul className={`${tab_list} border-bottom`}>
                                    <li onClick={() => this.tabHandler(0)} className={tabActive === 0 ? active : ''}>Active</li>
                                    <li onClick={() => this.tabHandler(1)} className={tabActive === 1 ? active : ''}>Archived</li>
                                </ul> */}
                                    <div className="">
                                        <ul className="list-group list-group-flush" style={{ listStyle: 'none' }}>
                                            {isLoader ?
                                                <MainLoaderComponent color="black" />
                                                :
                                                allProposals.length > 0 ?
                                                    allProposals.map((info, i) =>
                                                        <li className="list-group-item mb-3" key={i}>
                                                            <div className="d-flex justify-content-between" style={{ cursor: 'pointer' }} onClick={() => this.viewJobDetails(info)}>
                                                                <div className="w-75">
                                                                    <h5 className="fs-3"><b>{info.title}</b></h5>
                                                                    <p className="mb-0 fs-5" style={{ whiteSpace: 'nowrap', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>{info.description}</p>
                                                                </div>
                                                                {/* <div className="col-sm-2"> */}
                                                                <span className="">{moment(info.createdAt).format('DD MMM, YYYY')}</span>
                                                            </div>
                                                        </li>
                                                    )
                                                    :
                                                    <li>No record found</li>
                                            }
                                        </ul>
                                        {allProposals.length > 0 ?
                                            <div className={`pagination_wrap border-top pt-4 pb-4`}>
                                                <ReactPaginate
                                                    initialPage={this.state.page}
                                                    // forcePage={this.state.page}
                                                    pageCount={1}
                                                    pageRangeDisplayed={4}
                                                    breakLabel={'...'}
                                                    onPageChange={this.pageHandler}
                                                    activeClassName={'active'}
                                                    previousLabel={<MdKeyboardArrowLeft />}
                                                    previousClassName={'previous'}
                                                    nextLabel={<MdKeyboardArrowRight />}
                                                    nextClassName={'next'}
                                                />
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={openDetailsPage ? `bg_overlay show` : `bg_overlay`} onClick={this.closedJobDetails}></div>
                <div className={openDetailsPage ? `details_wrap show` : `details_wrap`}>

                    <div className={'header_wrap'}>
                        <div className="row">
                            <div className="col-sm-12 d-flex align-items-center">
                                <button type="button" onClick={this.closedJobDetails} className={'back_wrap'}><MdKeyboardArrowLeft /></button>
                                {/* {proposalDetails && proposalDetails.jobPostBidID > 0 ? */}
                                <p className={'bid_label text-dark'}>You have already submitted proposals on this job {proposalDetails && proposalDetails.id && <span><Link to={`/freelancer/proposal/${proposalDetails.id}`}>View Proposal</Link></span>}</p>
                                {/* :
                                    null
                                } */}
                            </div>
                        </div>
                    </div>
                    <div className={`details_wrap_body`}>
                        <div className={`wrap_detail card border-0`}>
                            <div className="row">
                                <div className={`border_end col-sm-12 col-md-12 col-lg-9`}>
                                    <div className={'title_wrap_details'}>
                                        <h3>{proposalDetails && proposalDetails.title}</h3>
                                    </div>
                                    <div className={'description_wraps'}>
                                        <p>{proposalDetails && proposalDetails.description}</p>
                                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
                                    </div>
                                    <div className={'price_wrap'}>
                                        <p>${proposalDetails && proposalDetails.budget} <span>Fixed-Price</span></p>
                                    </div>
                                    <div className={'skills_wrap_detail'}>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <h5>Expertise</h5>
                                                {/* <p>Back-End Development Deliverables</p> */}
                                                <ul className={'budges_skills'}>
                                                    {proposalDetails && proposalDetails.services && proposalDetails.services.length > 0 ?
                                                        proposalDetails.services.map((info, i) => <li key={i} className="text-dark">{info.categoryName}</li>)
                                                        :
                                                        null
                                                    }
                                                </ul>
                                            </div>
                                            <div className="col-sm-6">
                                                <h5>Skills</h5>
                                                <ul className={'budges_skills'}>
                                                    {proposalDetails && proposalDetails.skills && proposalDetails.skills.length > 0 ?
                                                        proposalDetails.skills.map((info, i) => <li key={i} className="text-dark">{info.categoryName}</li>)
                                                        :
                                                        null
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className={'price_wrap'}>
                                        {/* <p>$700 <span>Fixed-Price</span></p> *
                                        <p>${proposalDetails && proposalDetails.budget} <span>Fixed-Price</span></p>
                                    </div>
                                    <div className={'price_wrap'}>
                                        <p>15 <span>Days</span></p>
                                        {/* <p>{proposalDetails && proposalDetails.budget} <span>Days</span></p> *
                                    </div> */}

                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-3">
                                    <div className={`button_wrap border-bottom-0`}>
                                        {proposalDetails && proposalDetails.jobPostBidID === 0 ? <button type="button" className="btn btn-dark" style={{ minWidth: '175px', marginBottom: '15px' }} onClick={this.updateProposal}>Update a Proposal</button> : null}
                                        <button type="button" className="btn btn-outline-dark" onClick={this.closedJobDetails} style={{ minWidth: '175px' }}>Close</button>
                                    </div>
                                </div>
                                {/* <div className="col-sm-12 mt-5">
                                    <div className="card border-0">
                                        <h2 className={title_card_reviw}>Client's recent history (4)</h2>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(MyProposalsComponent);