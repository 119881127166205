export const nameValidation = (value) => {
    if (value && value !== '') {
        return { status: true, message: "" }
    } else {
        return { status: false, message: "Required" }
    }
}

export const emailValidation = (value) => {
    const reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (value && value !== '') {
        if (reg.test(value)) {
            return { status: true, message: "" }
        } else {
            return { status: false, message: "Invalid email" }
        }
    } else {
        return { status: false, message: "Required" }
    }
}

export const confirmPasswordValidation = (confirmPassword, newPassword) => {
    if (confirmPassword && confirmPassword !== '') {
        if (newPassword && newPassword !== '') {
            if (newPassword === confirmPassword) {
                return { status: true, message: "" }
            } else {
                return { status: false, message: "Password not match" }
            }
        } else {
            return { status: false, message: "New Password Required" }
        }
    } else {
        return { status: false, message: "Required" }
    }
}

export const fileTypeCheck = (types, files) => {
    if (files.length === 0) {
        return { status: false, message: "File is required" }
    } else {
        var allowedExtensions = new RegExp(/(\.jpg|\.jpeg|\.png|\.doc|\.xls|\.pdf|\.csv)$/i);

        for (var i = 0; i < files.length; i++) {
            if (!allowedExtensions.exec(files[i])) {
                return { status:false, message: "You have uploaded an jpg, jpeg, png, doc, xls, pdf and csv file type"}
            }
            return { status:true, message: ""}
        }
    }
}