import React, { Component } from 'react';
import DataTable from '../../components/DataTable';
import payment from '../../controller/payment.controller';
import { jwtDecode } from '../../helper/jwt.helper';
import MainLoaderComponent from '../../helper/MainLoaderComponent';
import moment from 'moment'

const dateFormatter = (cell, row) => {
    return moment(cell).format('DD, MMM, YYYY')
}

const discriptionFormatter = (cell, row) => {

    if (row.status === 1) {
        return `${row.name ? row.name : ''} transfer in your Vedain account`
    }

    if (row.status === 3) {
        return 'transfer in your paypal account'
    }
    if (row.status !== 1 || row.status !== 3) {
        return '--'
    }
}

const discriptionClientFormatter = (cell, row) => {

    if (row.status === 1) {
        return `you added amount in your Vedain account`
    }

    if (row.status === 3) {
        return 'transfer in your paypal account'
    }
    if (row.status !== 1 || row.status !== 3) {
        return '--'
    }
}

const clientTableClumns = [
    {
        dataField: 'updatedAt',
        text: 'Date',
        sort: true,
        formatter: dateFormatter
    },
    // {
    //     dataField: 'type',
    //     text: 'Type',
    //     sort: true
    // },
    {
        dataField: 'description',
        text: 'Description',
        sort: true,
        formatter: discriptionClientFormatter
    },
    // {
    //     dataField: 'name',
    //     text: 'Freelancer',
    //     sort: true
    // },
    {
        dataField: 'transactionAmount',
        text: 'Amount',
        sort: true,
        formatter: (cell, row) => {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });

            return formatter.format(cell);
        }
    }
]

const freelancerTableClumns = [
    {
        dataField: 'updatedAt',
        text: 'Date',
        sort: true,
        formatter: dateFormatter
    },
    // {
    //     dataField: 'type',
    //     text: 'Type',
    //     sort: true
    // },
    {
        dataField: 'description',
        text: 'Description',
        sort: true,
        formatter: discriptionFormatter
    },
    // {
    //     dataField: 'name',
    //     text: 'Client',
    //     sort: true
    // },
    {
        dataField: 'transactionAmount',
        text: 'Amount',
        sort: true,
        formatter: (cell, row) => {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });

            return formatter.format(cell);
        }
    }
]

class TransactionsHistory extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            list: [],
            tableHeader: [],
            loader: true
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getTranscationList();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getTranscationList = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token)
            if (decode && decode.profileType === 2) {
                this.getClientTransaction();
            }

            if (decode && decode.profileType === 1) {
                this.getFreelancerTransaction();
            }
        }
    }

    getClientTransaction = async () => {
        if (this._unmounted) {
            this.setState({
                loader: true
            })
        }
        const res = await payment.getClientTransaction();
        if (res.status) {
            // console.log(res)
            if (this._unmounted) {
                this.setState({
                    tableHeader: clientTableClumns,
                    list: res.data,
                    loader: false
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    tableHeader: clientTableClumns,
                    list: [],
                    loader: false
                })
            }
        }
    }

    getFreelancerTransaction = async () => {

        if (this._unmounted) {
            this.setState({
                loader: true
            })
        }

        const res = await payment.getFreelancerTransaction();
        if (res.status) {
            // console.log(res)
            if (this._unmounted) {
                this.setState({
                    tableHeader: freelancerTableClumns,
                    list: res.data,
                    loader: false
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    tableHeader: freelancerTableClumns,
                    list: [],
                    loader: false
                })
            }
        }
    }

    tabHandler = (tab) => {
        if (this._unmounted) {
            this.setState({
                activeTab: tab
            })
        }
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Table is Empty</p>
        </div>
    }

    render() {

        // const { activeTab } = this.state;

        // console.log(this.state.list);
        return (
            <section className={`payment_wrap`}>
                <div className="container">
                    <h1 className="page-title">Transaction History</h1>
                    {/* <ul className={'tabs_ul'}>
                        <li className="bg-transparent"><button type="button" onClick={() => this.tabHandler(0)} className={activeTab === 0 ? 'active' : ''}>All</button></li>
                        <li className="bg-transparent"><button type="button" onClick={() => this.tabHandler(1)} className={activeTab === 1 ? 'active' : ''}>Milestones</button></li>
                        {/* <li className="bg-transparent"><button type="button" onClick={() => this.tabHandler(2)} className={activeTab === 2 ? 'active' : ''}>Pending Funds</button></li> *
                    </ul> */}
                    {/* {activeTab === 0 ? */}
                    <div className="jobs_div">
                        {this.state.loader ?
                            <div className="text-center p-3">
                                <MainLoaderComponent />
                            </div>
                            :
                            <DataTable
                                keyField="id"
                                data={this.state.list}
                                columns={this.state.tableHeader}
                                // onSizePerPageChange={this.sizePerPageChange} 
                                // onPageChange={this.pageHandler} 
                                noDataIndication={this.emptyRender} />
                        }
                    </div>
                    {/* :
                        null
                    } */}

                    {/* {activeTab === 1 ?
                        <div className="jobs_div">
                            <DataTable
                                keyField="id"
                                data={[]}
                                columns={milestoneColumns}
                                // onSizePerPageChange={this.sizePerPageChange} 
                                // onPageChange={this.pageHandler} 
                                noDataIndication={this.emptyRender} />
                        </div>
                        :
                        null
                    } */}
                    {/* {activeTab === 2 ?
                        <div className="jobs_div">
                            <DataTable
                                keyField="id"
                                data={[]}
                                columns={pendingColumns}
                                // onSizePerPageChange={this.sizePerPageChange} 
                                // onPageChange={this.pageHandler} 
                                noDataIndication={this.emptyRender} />
                        </div>
                        :
                        null
                    } */}
                </div>
            </section>
        )
    }
}

export default TransactionsHistory
