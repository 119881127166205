import { apiHelper } from "../../helper/api.helper"
import { jwtDecode } from "../../helper/jwt.helper"


export const mainCategories = async () => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const response = await apiHelper('admin/maincategory/get', 'GET', null, header);
    const res = response.data;

    if(!res.status){
        return []
    }

    return jwtDecode(res.data);
    
}

export const subCategories = async (id) => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const response = await apiHelper(`admin/subcategory/get/${id}`, 'GET', null, header);
    const res = response.data;

    if(!res.status){
        return []
    }

    return jwtDecode(res.data);
    
}

export const allCategories = async () => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const response = await apiHelper('admin/allCategory/get', 'GET', null, header);
    const res = response.data;

    if(!res.status){
        return []
    }

    return jwtDecode(res.data);
    
}

export const addCategories = async (data) => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const {  mainService, serviceName } = data;

    let formData = new FormData();
    if(mainService !== null){
        formData.append('parentCategoryID', mainService.value)
    }else{
        formData.append('parentCategoryID', 0)
    }

    formData.append('categoryName', serviceName)

    const response = await apiHelper('admin/category/add', 'POST', formData, header);
    return response.data;
    
}

export const updateCategories = async (data) => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const { categoryID, mainService, serviceName } = data;

    let formData = new FormData();
    if(mainService !== null){
        formData.append('parentCategoryID', mainService.value)
    }else{
        formData.append('parentCategoryID', 0)
    }
    
    formData.append('categoryID', categoryID)
    formData.append('categoryName', serviceName)

    const response = await apiHelper('admin/category/update', 'PUT', formData, header);
    return response.data;
    
}

export const deleteCategories = async (id) => {

    const header = {
        'Authorization':localStorage.getItem('adminToken')
    }

    const response = await apiHelper(`admin/category/delete/${id}`, 'DELETE', null, header);
    return response.data;
    
}