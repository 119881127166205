import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { MdClose, MdPerson, MdPowerSettingsNew } from "react-icons/md";
import socketClient from "socket.io-client";
// MdFormatListBulleted
import { NavLink, Link, withRouter } from 'react-router-dom';
import { jwtDecode } from '../../helper/jwt.helper';
import DummyUser from '../../assets/images/dummy-userImg.jpg';
import Logo from '../../assets/images/vedain-logo.png';
import '../../assets/css/header.css';
import { getUserProfile } from '../../controller/profile.controller';
import { CHAT_URL } from '../../controller/chat.controller';
import * as ACTION_TYPES from '../../redux/actions/type';

const imageChange = (e) => {
    e.target.src = DummyUser
}

const UserLink = ({ isImage, name, userImg, nameFirstCharacter }) => {
    return <>
        {isImage ?
            <span className={'user_img'}>
                <img src={userImg} onError={imageChange} alt="user" />
            </span>
            :
            <span className={`user_img character`}>
                {nameFirstCharacter}
            </span>
        }
        {name}
    </>
}

const SERVER = CHAT_URL;

class HeaderClient extends Component {

    _unmounted = false;

    socket;

    constructor(props) {
        super(props);
        this.state = {
            urlPath: '',
            isMessage: false,
            isSiderBar: false,
            isSmallWindow: false,
            isActiveClass: false,
            user: null,
            name: '',
            isImage: false,
            userImg: '',
            path: '',
            nameFirstCharacter: ''
        }

        this.configureSocket = this.configureSocket.bind(this);
    }

    componentDidMount() {
        this._unmounted = true;
        this.checkWindowWidth();
        window.addEventListener('resize', this.checkWindowWidth)

        const token = localStorage.getItem('token');
        if (token) {
            const decode = jwtDecode(token);
            if (decode) {
                if (this._unmounted) {
                    this.setState({
                        user: decode
                    })
                }

                if (parseInt(decode.profileType) === 1) {
                    if (this.props.location.pathname !== '/freelancer/jobs') {
                        return this.props.history.push('/freelancer/jobs');
                    }
                }

                const { location } = this.props;
                this.props.updateURLPath(location.pathname)

                this.configureSocket(decode);

                this.userProfile();

                // if (Number(decode.status) === 0) return this.props.history.push('/profile/email-verify');
            }
        } else {

            this.props.history.push('/login');
            return true
        }

        this.activeClassCheckForJobs();
    }

    componentWillUnmount() {
        this._unmounted = false;
        window.removeEventListener('resize', this.checkWindowWidth)
    }

    configureSocket = (tokenDecode) => {
        var socket = socketClient(SERVER, {
            secure: true
        });

        const { location } = this.props;
        this.props.updateURLPath(location.pathname)
        // console.log(this.props);

        socket.on('connection', () => {
            socket.emit("userOnlineEmit", { profileId: tokenDecode.id })
        });

        if (tokenDecode) {
            const data = {
                profile_id: tokenDecode.id
            }

            socket.emit('getunseenMessageCount', data);
        }

        if (location.pathname !== '/message') {
            socket.on('countOfUnreadMessageOfUser', data => {
                // console.log(data);
                if (data.count && data.count > 0) {
                    this.props.updateNotifyCount(data.count);
                    this.props.updateNotifyBool(true);
                } else {
                    this.props.updateNotifyBool(false);
                    this.props.updateNotifyCount(0);
                }
            })
        } else {

            // socket.on('messageSeenNotifyEmit', data => {
            //     console.log('messageSeenNotifyEmit');
            //     if (data && data.length > 0) {
            //         data.forEach(element => {
            //             if (element.profile_id === tokenDecode.id) {
            //                 this.props.updateNotifyBool(false);

            //             }
            //         })
            //         // if(tokenDecode.id === )
            //     }else{
            //         this.props.updateNotifyBool(false);
            //         this.props.updateNotifyCount(0);
            //     }
            // console.log(data);
            // })
        }

        this.socket = socket;
    }

    userProfile = async () => {
        const res = await getUserProfile();
        // console.log(res);
        if (res) {
            let isImageAvailable = false;
            let imageUrl = '';

            this.props.updateUserName(res.name)
            const firstCharacter = res.name.charAt(0)
            if (res.profilePic) {
                isImageAvailable = true;
                const imgUrl = `${res.profileURL}${res.profilePic}`;
                this.props.updateUserImage(imgUrl)
                imageUrl = this.props.userImage
            } else {
                isImageAvailable = false;
                imageUrl = ''
            }

            if (this._unmounted) {
                this.setState({
                    isSmallWindow: window.innerWidth < 991,
                    name: res.name,
                    isImage: isImageAvailable,
                    userImg: imageUrl,
                    nameFirstCharacter: firstCharacter,
                    path: res.profileURL
                })
            }
        }
    }

    checkWindowWidth = () => {
        // console.log(window.innerWidth < 767);
        if (this._unmounted) {
            this.setState({
                isSmallWindow: window.innerWidth < 991
            })
        }
    }

    openSideBar = () => {
        // console.log('working...')
        if (this._unmounted) {
            this.setState({
                isSiderBar: true
            })
        }
    }

    closeSideBar = () => {
        if (this._unmounted) {
            this.setState({
                isSiderBar: false
            })
        }
    }

    logout = (e) => {
        e.preventDefault();
        if (this.state.user) {
            this.socket.emit("userOfflineEmit", { profileId: this.state.user.id })
        }
        this.socket.disconnect();
        localStorage.removeItem('token');
        localStorage.removeItem('amount');
        localStorage.removeItem('chatID');
        this.props.history.push('/login');
    }

    dropLinkRedireact = (e, url) => {
        e.preventDefault();
        this.props.history.push(url);
    }

    activeClassCheckForJobs = () => {
        const pathname = this.props.location.pathname;
        // let isMenu = false;
        // if (pathname !== '/profile/email-verify') {
        //     isMenu = true;
        // }
        // if (this._unmounted) {
        //     this.setState({ isActiveClass: isMenu })

        // }
        if (this._unmounted) {
            switch (pathname) {
                case '/client/my-jobs':
                    return this.setState({ isActiveClass: true })
                case '/client/all-jobs':
                    return this.setState({ isActiveClass: true })
                case '/client/all-contracts':
                    return this.setState({ menuDisplay: false })
                // case '/profile/email-verify':
                //     return this.setState({ menuDisplay: false })
                default:
                    return this.setState({ isActiveClass: false })
            }
        }
    }

    render() {

        const { isSiderBar, isSmallWindow, isImage, isActiveClass, nameFirstCharacter } = this.state;
        // console.log(userImg);

        return (
            <>
                <header className="header_client">
                    <Navbar bg="light" expand="sm" fixed="top" className="client_header">
                        <div className="container">
                            <Link to="/client/my-jobs">
                                <Navbar.Brand className="logo-web">
                                    <img src={Logo} alt="Vedain" />
                                </Navbar.Brand>
                            </Link>
                            {/* <Link href="/client/dashboard"><Navbar.Brand href="/client/dashboard">Vedain</Navbar.Brand></Link> */}
                            {isSiderBar ?
                                <Navbar.Toggle aria-controls="basic-navbar-nav" className="close_icon" children={<MdClose />} onClick={this.closeSideBar} />
                                :
                                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.openSideBar} />
                            }
                            {isSmallWindow ?
                                isSiderBar ?
                                    <div className="navbar_collapse justify-lg-content-end navbar-collapse collapse show">
                                        <div className="justify-content-end navbar-nav">
                                            <NavDropdown title={<span className={isActiveClass ? 'active' : ''}>Jobs</span>} id="jobs-nav-dropdown" className="mobile-dropdown" >
                                                <NavLink to="/client/my-jobs" className="nav-item" activeClassName="active">My Jobs</NavLink>
                                                {/* <NavLink to="/client/all-jobs" className="nav-item" activeClassName="active">All Job Posts</NavLink> */}
                                                <NavLink to="/client/my-contracts" className="nav-item" activeClassName="active">My Contracts</NavLink>
                                                <NavLink to="/client/post-job" className="nav-item" activeClassName="active">Post a Job</NavLink>
                                                {/* <NavDropdown.Item href="/client/my-jobs" onClick={(e) => this.dropLinkRedireact(e, "/client/my-jobs")}>My Jobs</NavDropdown.Item> */}
                                                {/* <NavDropdown.Item href="/client/all-jobs" onClick={(e) => this.dropLinkRedireact(e, "/client/all-jobs")}>All Jobs Posts</NavDropdown.Item> */}
                                                {/* <NavDropdown.Item href="/client/all-contracts" onClick={(e) => this.dropLinkRedireact(e, "/client/all-contracts")}>All Contracts</NavDropdown.Item> */}
                                                {/* <NavDropdown.Item href="/client/post-job" onClick={(e) => this.dropLinkRedireact(e, "/client/post-job")}>Post a Job</NavDropdown.Item> */}
                                            </NavDropdown>
                                            {/* <NavLink exact to="/client/my-jobs" className="nav-link" activeClassName={'active'}>Jobs</NavLink > */}
                                            <NavLink exact to="/client/find-freelancer" className="nav-link" activeClassName={'active'}>Find Freelancer</NavLink >
                                            <NavLink exact to="/message" className="nav-link position-relative" activeClassName={'active'}>
                                                Message
                                                {this.props.isHeaderNotify ? <span className="alert_notification translate-middle bg-danger rounded-circle">
                                                    <span className="visually-hidden">New alerts</span>
                                                </span> : null}
                                            </NavLink >
                                            <NavLink exact to="/client/profile" className="nav-link" activeClassName={'active'}>My Profile</NavLink >
                                            <NavLink exact to="/payment/transactions" className="nav-link" activeClassName={'active'}>Reports</NavLink >
                                            {/* <NavLink exact to="/client/post-job" className="nav-link" activeClassName={'active'}>Post Job</NavLink > */}
                                            <Link to="/" className="nav-link" onClick={this.logout}>Logout</Link>
                                        </div>
                                    </div>
                                    :
                                    null
                                // <Navbar.Collapse id="basic-navbar-nav" className={`navbar_collapse justify-content-end`}>
                                //     <Nav className="mr-auto" className="justify-content-end" style={{ width: '100%' }}>
                                //         <NavLink to="/client/jobs" activeClassName={'active'}><NavLink href="/client/jobs">Jobs</NavLink></NavLink >
                                //         <NavLink to="/client/dashboard" activeClassName={'active'}><Nav.Link href="/client/dashboard">Find Freelancer</Nav.Link></NavLink >
                                //         <NavLink to="/client/profile" activeClassName={'active'}><Nav.Link href="/client/profile">My Profile</Nav.Link></NavLink >
                                //         <NavLink to="/client/post-job" activeClassName={'active'}><Nav.Link href="/client/post-job" >Post Job</Nav.Link></NavLink >
                                //         <Link href="/" onClick={logout}><Nav.Link href="#/" >Logout</Nav.Link></Link>
                                //     </Nav>
                                // </Navbar.Collapse>
                                :

                                <Navbar.Collapse id="basic-navbar-nav" className={`navbar_collapse justify-content-end`}>
                                    <Nav className="mr-auto justify-content-end" style={{ width: '100%' }}>
                                        <Nav.Item className={'dropdown_li'}>
                                            <Link to="/client/my-jobs" className={isActiveClass ? 'nav-link active' : 'nav-link'}>Jobs</Link>
                                            {/* <NavLink exact to="/freelancer/jobs" activeClassName={'active'}><NavLink href="/freelancer/jobs">Find jobs</NavLink></NavLink> */}
                                            <ul className={`${'dropdown_menu'} dropdown-menu`}>
                                                <li className="nav-item"><NavLink exact to="/client/my-jobs" activeClassName={'active'} className="nav-link">My Jobs</NavLink></li>
                                                {/* <li className="nav-item"><NavLink exact to="/client/all-jobs" activeClassName={'active'} className="nav-link">All Job Posts</NavLink></li> */}
                                                <li className="nav-item"><NavLink exact to="/client/my-contracts" activeClassName={'active'} className="nav-link">My Contracts</NavLink></li>
                                            </ul>
                                        </Nav.Item>
                                        {/* <NavLink exact to="/client/jobs" className="nav-link" activeClassName={'active'}>Jobs</NavLink > */}
                                        <NavLink exact to="/client/find-freelancer" className="nav-link" activeClassName={'active'}>Find Freelancer</NavLink >
                                        <NavLink exact to="/message" className="nav-link position-relative" activeClassName={'active'}>
                                            Message
                                            {this.props.isHeaderNotify ? <span className="alert_notification translate-middle bg-danger rounded-circle">
                                                <span className="visually-hidden">New alerts</span>
                                            </span> : null}
                                        </NavLink >
                                        <NavLink exact to="/payment/transactions" className="nav-link" activeClassName={'active'}>Reports</NavLink >
                                        <NavLink exact to="/client/post-job" className="nav-link btn_pJob" activeClassName={'active'}>Post Job</NavLink >
                                        <NavDropdown className={"user_pro"} title={
                                            <UserLink name={this.props.userName} isImage={isImage} userImg={this.props.userImage} nameFirstCharacter={nameFirstCharacter} />} id="basic-nav-dropdown"
                                        >
                                            <Link to="/client/profile" className="dropdown-item"><MdPerson /> Profile</Link>
                                            {/* <Link to="/payment/transactions" className="dropdown-item"><MdFormatListBulleted /> Transaction history</Link> */}
                                            {/* <NavDropdown.Item href="/client/profile" onClick={(e) => this.dropLinkRedireact(e, "/client/profile")}><MdPerson /> Profile</NavDropdown.Item>
                                            <NavDropdown.Item href="/payment/transactions" onClick={(e) => this.dropLinkRedireact(e, "/payment/transactions")}><MdFormatListBulleted /> Transaction history</NavDropdown.Item> */}
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item href="/logout" onClick={this.logout}><MdPowerSettingsNew /> Logout</NavDropdown.Item>
                                        </NavDropdown>

                                    </Nav>
                                </Navbar.Collapse>
                            }
                        </div>
                    </Navbar>

                </header>
                {isSiderBar ? <div className="overlay_sidebar" onClick={this.closeSideBar}></div> : null}
            </>
        )
    }
}

const mapStateToProps = state => ({
    userImage: state.user.userImage,
    userName: state.user.userName,
    isHeaderNotify: state.chat.isHeaderNotify
})

const mapDipatchToProps = dispatch => ({
    updateUserImage: (url) => dispatch({ type: ACTION_TYPES.USER_IMAGE_UPDATE, payload: url }),
    updateUserName: (name) => dispatch({ type: ACTION_TYPES.UPDATE_USER_NAME, payload: name }),
    updateNotifyCount: (count) => dispatch({ type: ACTION_TYPES.HEADER_NOTIFICATION_COUNT, payload: count }),
    updateNotifyBool: (bool) => dispatch({ type: ACTION_TYPES.HEADER_NOTIFICATION_BOOL, payload: bool }),
    updateURLPath: (path) => dispatch({ type: ACTION_TYPES.URL_PATH_HTTPS, payload: path })
})

export default withRouter(connect(mapStateToProps, mapDipatchToProps)(HeaderClient))