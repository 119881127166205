import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { mainCategories } from '../../../controller/categories.controller';


class CategoryComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            categoryArray: [],
            categorySelected: [],
            error: ''
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scroll(0, 0);
        if(this.props.categoryList && this.props.categoryList.length > 0){
            if (this._unmounted) {
                this.setState({
                    categoryArray:this.props.categoryList,
                    categorySelected: this.props.categoryVal
                })
            }
        }else{
            if (this._unmounted) {
                this.setState({
                    categorySelected: this.props.categoryVal
                })
            }
            this.getMainCategoryList();
        }

        
        
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getMainCategoryList = async () => {
        try {

            const categoryList = await mainCategories();
            // console.log(categoryList);
            this.props.categoryArrayFun(categoryList);
            if(this._unmounted){
                this.setState({
                    categoryArray:categoryList
                })
            }

        } catch (err) {
            // console.log(err);
            if (this._unmounted) {
                this.setState({
                    categoryArray: [],
                })
            }
        }
    }

    categoryHandler = (obj) => {
        let newArray = this.state.categorySelected;
        const isExsit = newArray.filter(info => info.id === obj.id);

        if (isExsit.length > 0) {
            newArray = newArray.filter(info => info.id !== obj.id);
        } else {
            newArray.push(obj);
        }

        this.setState({
            categorySelected: newArray,
            error: ''
        })
    }

    nextStepFun = () => {
        if (this.state.categorySelected.length > 0) {
            if (this.props.isBackReview) {

                this.props.categoryFun(this.state.categorySelected);
                this.props.stepFun(5);
                this.props.backReviewFun(false);

            } else {

                const step = this.props.currentStep + 1;
                this.props.categoryFun(this.state.categorySelected);
                this.props.stepFun(step);
                this.props.backReviewFun(false);
            }
        } else {
            this.setState({
                error: 'Please select atleast one category'
            })
        }
    }

    prevStepFun = () => {
        const step = this.props.currentStep - 1;
        this.props.stepFun(step);
    }

    render() {

        const { categorySelected, categoryArray, error } = this.state;



        return (
            <div className={'content_steps_wrap'}>
                <div className={'step_body'}>
                    <div className={'header_step'}>
                        <h2 className={'Header_Step_title'}>Category</h2>
                    </div>
                    <div className={'wrap_steps'}>
                        <ul className={'category_list'}>
                            {categoryArray.length > 0 ?
                                categoryArray.map((info, i) =>
                                    <li key={i}>
                                        <input id={`category${info.id}`} type="checkbox" name="category" value={info.id} checked={categorySelected.includes(info)} onChange={() => this.categoryHandler(info)} />
                                        <label htmlFor={`category${info.id}`} className={`liDiv_list `} >{info.categoryName}</label>
                                    </li>
                                )
                                :
                                <li>No record found.</li>
                            }
                        </ul>
                        {error !== "" ?
                            <div className="d-flex">
                                <p className="error-text">{error}</p>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div className={'step_footer'}>
                    <button type="button" className="btn btn-primary min-200" onClick={this.nextStepFun}>Next</button>
                    <button type="button" className="btn btn-outline-dark min-200" onClick={this.prevStepFun}>Prev</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentStep: state.postJob.currentStep,
    categoryList:state.postJob.categoryArray,
    categoryVal: state.postJob.categoryVal,
    isBackReview: state.postJob.isBackReview
})

const mapDispatchToProps = dispatch => ({
    stepFun: (step) => dispatch({ type: ACTION_TYPES.STEP_POST_JOBS, payload: step }),
    categoryFun: (array) => dispatch({ type: ACTION_TYPES.CATEGORYS_POST_JOB, payload: array }),
    categoryArrayFun: (array) => dispatch({ type: ACTION_TYPES.CATEGORYS_ARRAY, payload: array }),
    backReviewFun: (bool) => dispatch({ type: ACTION_TYPES.BACK_REVIEW_POSTJOB_STEPS, payload: bool })
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryComponent);
