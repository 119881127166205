import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class Page404 extends Component {

    componentDidMount(){
        window.scrollTo(0, 0)
    }
    
    render() {
        return (
            <div className="container-fluid p-0">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">

                            <div className="text-center font-manul">
                                <h1 className="display-1 font-weight-bold">404</h1>
                                <p className="h1">Page not found.</p>
                                <p className="h2 font-weight-normal mt-3 mb-4">The page you are looking for might have been removed.</p>
                                <Link to="/admin/dashboard" className="btn btn-primary btn-lg">Return to dashboard</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null)(Page404))
