import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';

class SettingsNav extends Component {
    render() {
        return (
            <ul className="side-nav-settings">
                <li><NavLink to="/freelancer/settings" exact activeClassName="active">Get Paid</NavLink></li>
                <li><NavLink to="/freelancer/settings/payment" exact activeClassName="active">Billing Method</NavLink></li>
                <li><Link to="/freelancer/profile">My Profile</Link></li>
                <li><Link to="/freelancer/change-password">Change Password</Link></li>
            </ul>
        )
    }
}

export default SettingsNav
